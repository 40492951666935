<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content d-flex gap-2 pt-0" style="flex-direction: column">
		<h2 class="primary">Importer une nouvelle déclaration</h2>
		<mat-tab-group (selectedIndexChange)="importTabIndex = $event">
			<mat-tab label="API">
				<app-operat-search-form [(importData)]="apiImportData"></app-operat-search-form>
			</mat-tab>
			<mat-tab label="CSV">
				<div class="custom-input-group">
					<label>SIREN de la structure</label>
					<app-siren-input [(siren)]="importIdentifier" appInputRestriction="alphanumeric"></app-siren-input>
				</div>
				<app-csv-drop-zone
					[(csvFile)]="csvFile"
					[isInvalid]="!!csvFile && !(csvFile | checkSirenForCsv: importIdentifier)"
				></app-csv-drop-zone>
				<span
					class="custom-input-error"
					[ngStyle]="{ display: !!csvFile && !(csvFile | checkSirenForCsv: importIdentifier) ? 'block' : 'none' }"
					>Le SIREN du fichier ne correspond pas à celui renseigné</span
				>
			</mat-tab>
		</mat-tab-group>
	</div>

	<div class="dialog-actions">
		<ng-container *ngIf="importTabIndex === ImportTypeEnum.CSV">
			<button
				*ngIf="csvFile"
				class="btn-primary-reverse"
				[disabled]="!(importIdentifier | apply: Siren.isValid) || !(csvFile | checkSirenForCsv: importIdentifier)"
				[class.btn-loading]="initialLoading.isLoading()"
				type="submit"
				(click)="close({ csvFile, siren: importIdentifier })"
			>
				Importer
			</button>
			<button
				*ngIf="!csvFile"
				class="btn-primary-reverse"
				[disabled]="true"
				[class.btn-loading]="initialLoading.isLoading()"
				type="submit"
			>
				Importer
			</button>
		</ng-container>
		<button
			*ngIf="importTabIndex === ImportTypeEnum.API"
			class="btn-primary-reverse"
			[disabled]="
				!apiImportData ||
				apiImportData.references.length === 0 ||
				apiImportData.userKey === '' ||
				apiImportData.structId === ''
			"
			[class.btn-loading]="initialLoading.isLoading()"
			type="submit"
			(click)="close(apiImportData)"
		>
			Importer
		</button>
	</div>
</div>
