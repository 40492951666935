import { Observable, of, zip } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AddressInfo } from "../models/address";
import { Siret } from "../models/identifier";
import { AddressSearchService } from "../services/address-search.service";
import { sequentialJoin } from "./sequentialJoin";

const EXPECTED_NUMBER_OF_HEADERS = 173;
const INDEX_DECLARATION_YEAR = 1;
const HEADER_DENOMINATION = 0;
const HEADER_ADDRESS = 3;

export type OperatCsvData = {
	[key: string]: {
		import_data: string[][];
		address?: AddressInfo;
		denomination: string;
	};
};

function isWithoutSiret(refOperat: string): boolean {
	const ref = refOperat.split("_");
	return !!ref[0] && ref[0].length < Siret.LENGTH;
}

export function groupCsvRefToObject(csv: string, addressSearch: AddressSearchService): Observable<OperatCsvData> {
	const csvData = csv.trim().split("\n").slice(1);
	const groupData: { [key: string]: { importData: string[][]; address: string; denomination: string } } = {};
	for (const row of csvData.map((row) => row.split(";"))) {
		const excessData = row.splice(0, 5);
		const onlyLettersRegex = /^[a-zA-Z]+$/;

		// Ignore lines if incorrect length, no reference, no SIREN and SIRET (only letters), or not for 2021
		if (
			row.length !== EXPECTED_NUMBER_OF_HEADERS ||
			row[0] === "" ||
			row[0].split("_")[0].match(onlyLettersRegex) ||
			row[INDEX_DECLARATION_YEAR] !== "2021"
		) {
			continue;
		}

		if (!Object.prototype.hasOwnProperty.call(groupData, row[0])) {
			groupData[row[0]] = {
				importData: [],
				address: excessData[HEADER_ADDRESS],
				denomination: excessData[HEADER_DENOMINATION],
			};
		}

		groupData[row[0]].importData.push(row.slice(1));
	}

	const toArray: Observable<{
		refOperat: string;
		importData: string[][];
		address?: AddressInfo;
		denomination: string;
	}>[] = [];
	for (const key of Object.keys(groupData)) {
		const data = groupData[key];

		// Only get address from search if EFA is without siret
		const efaData$ = zip(
			of(key),
			of(data.importData),
			isWithoutSiret(key)
				? addressSearch.getAddressInfo$(data.address).pipe(
						catchError(() => of([])),
						map((data) => data[0]),
					)
				: of(undefined),
			of(data.denomination),
		).pipe(
			map(([refOperat, importData, address, denomination]) => {
				return { refOperat, importData, address, denomination };
			}),
		);
		toArray.push(efaData$);
	}

	return sequentialJoin(toArray).pipe(
		map((data) => {
			const record: OperatCsvData = {};
			for (const row of data) {
				record[row.refOperat] = { import_data: row.importData, address: row.address, denomination: row.denomination };
			}
			return record;
		}),
	);
}

export function getAddressForEntitiesWithoutSiret(
	addressSearch: AddressSearchService,
	entity: { refOperat: string; address: string; denomination: string },
): Observable<{ refOperat: string; address?: AddressInfo; denomination: string }> {
	return zip(
		of(entity.refOperat),
		isWithoutSiret(entity.refOperat)
			? addressSearch.getAddressInfo$(entity.address).pipe(
					catchError(() => of([])),
					map((data) => data[0]),
				)
			: of(undefined),
		of(entity.denomination),
	).pipe(
		map(([refOperat, address, denomination]) => ({
			refOperat,
			address,
			denomination,
		})),
	);
}
