import { Component } from "@angular/core";
import { Immutable } from "immer";
import {
	CategorySurfaceTertiaire,
	cloneSurfaceTertiaire,
	SurfaceTertiaire,
} from "src/app/models/functionalDeclaration";
import { Modal } from "src/app/models/modal";

type Input = Immutable<{ title: string; surface: Immutable<SurfaceTertiaire> }>;
type State = { title: string; surface: SurfaceTertiaire };
type Output =
	| { type: OutputType.Save; surface: SurfaceTertiaire }
	| { type: OutputType.Cancel }
	| { type: OutputType.Delete };

enum OutputType {
	Save,
	Cancel,
	Delete,
}

@Component({
	selector: "app-surface-modal",
	templateUrl: "./surface-modal.component.html",
})
export class SurfaceModalComponent extends Modal<Input, State, Output> {
	readonly exemptedSum = CategorySurfaceTertiaire.surfaceSum;

	static OutputType = OutputType;

	inputToState(input: Input): State {
		return { ...input, surface: cloneSurfaceTertiaire(input.surface) };
	}

	cancel() {
		this.close({ type: OutputType.Cancel });
	}

	save() {
		const { tertiaireArea, categorySurfaceAreas } = this.state.surface;

		if (tertiaireArea === 0 || CategorySurfaceTertiaire.surfaceSum(categorySurfaceAreas) > tertiaireArea) {
			return;
		}

		this.close({ type: OutputType.Save, surface: this.state.surface });
	}

	delete() {
		this.close({ type: OutputType.Delete });
	}
}
