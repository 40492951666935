import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AssociationApi } from "../models/associationApi";
import { CustomError } from "../models/CustomError";
import { Rna } from "../models/identifier";

@Injectable({
	providedIn: "root",
})
export class RnaService {
	private cachedAssociations: Record<Rna, AssociationApi> = {};

	constructor(private http: HttpClient) {}

	getAssociation$(rna: Rna): Observable<AssociationApi> {
		const cached = this.cachedAssociations[rna];
		if (cached) {
			return of(cached);
		}

		return this.http.get<AssociationApi>(`${environment.baseApiUrl}/associations/rna/${rna}`).pipe(
			tap((association) => (this.cachedAssociations[rna] = association)),
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse && error.status === 404) {
					throw new CustomError(`RNA ${rna} introuvable.`);
				}
				throw error;
			}),
		);
	}
}
