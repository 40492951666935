import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { IdOther } from "../models/identifier";

@Pipe({
	name: "asOtherStructure",
})
export class AsOtherStructurePipe implements PipeTransform {
	transform(structure: Immutable<Declaration["structure"]>): Immutable<IdOther> | undefined {
		// Because IdOther is the only object with label
		return "label" in structure ? structure : undefined;
	}
}
