import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "numberUnitObject",
})
export class NumberUnitObjectPipe implements PipeTransform {
	// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
	transform(num: number): { value: number; symbol: string } {
		num = num < 0 ? -num : num;
		const lookup = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "k" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "G" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" },
		];
		const item = lookup
			.slice()
			.reverse()
			.find(function (item) {
				return num >= item.value;
			});
		return item ? { value: Math.round((num / item.value) * 10) / 10, symbol: item.symbol } : { value: 0, symbol: "" };
	}
}
