<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">Exporter vers la Green Solution</h3>
		<div class="dialog-small-text">
			<div>
				<div class="mb-3">
					Vous allez générer le fichier d'export de
					<ng-container *ngFor="let count of [state.resource | countEntities]">
						<ng-container *ngIf="count === 1">votre entité fonctionnelle</ng-container>
						<ng-container *ngIf="count > 1">vos {{ count }} entités fonctionnelles</ng-container>
					</ng-container>
					vers la Green Solution. Pour continuer, veuillez indiquer les informations du (futur) titulaire du compte dans
					la Green Solution.
				</div>

				<app-custom-form #form>
					<div class="d-flex-col gap-2">
						<div class="d-flex gap-2">
							<div class="custom-input-group flex-space">
								<label>Prénom</label>
								<input class="custom-input" [(ngModel)]="state.first_name" required type="text" />
							</div>
							<div class="custom-input-group flex-space">
								<label>Nom</label>
								<input class="custom-input" [(ngModel)]="state.last_name" required type="text" />
							</div>
						</div>
						<div class="custom-input-group">
							<label>Email</label>
							<input class="custom-input" [(ngModel)]="state.email" required type="email" #userEmail />
							<span class="custom-input-error">{{ userEmail.validationMessage }}</span>
						</div>
					</div>
				</app-custom-form>
				<div class="mt-3">
					Ce fichier pourra être transmis directement à votre référent sur la plateforme afin d'effectuer le paramétrage
					de votre environnement sur la Green Solution.
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray mr-auto" [mat-dialog-close]="">Fermer</button>
		<button class="btn-stroked-primary" [disabled]="!form.formValid" (click)="downloadCsv()">
			Télécharger le fichier au format CSV
		</button>
	</div>
</div>
