import { Component } from "@angular/core";
import { Nullable } from "../../../../../../helpers/nullable";
import { FunctionalDeclarationId } from "../../../../../../models/ids";
import { Modal } from "../../../../../../models/modal";
import { OperatApiErrorType } from "../../../../../../models/OperatApiErrorType";
import { RouteDealer } from "../../../../../../models/routes";

export type OperatErrorAndEntities = {
	code: string;
	concernedEntities: { ref: string; name: Nullable<string>; id: Nullable<FunctionalDeclarationId> }[];
};

type Input = {
	errors: OperatErrorAndEntities[];
	year: string;
};

type State = {
	errors: OperatErrorAndEntities[];
	year: string;
};

@Component({
	selector: "app-api-errors-modal",
	templateUrl: "./api-errors-modal.component.html",
	styleUrls: ["./api-errors-modal.component.scss"],
})
export class ApiErrorsModalComponent extends Modal<Input, State, undefined> {
	displayedColumns = ["refOperat", "name"];

	inputToState(input: Input): State {
		return {
			errors: input.errors,
			year: input.year,
		};
	}

	getGlobalErrors(errors: OperatErrorAndEntities[]): OperatErrorAndEntities[] {
		return errors.filter(
			(error) => error.code === OperatApiErrorType.NOT_AUTHORIZED || error.code === OperatApiErrorType.INVALID_CLIENT,
		);
	}

	getSpecificErrors(errors: OperatErrorAndEntities[]): OperatErrorAndEntities[] {
		return errors.filter(
			(error) => error.code !== OperatApiErrorType.NOT_AUTHORIZED && error.code !== OperatApiErrorType.INVALID_CLIENT,
		);
	}

	protected readonly OperatApiErrorType = OperatApiErrorType;
	protected readonly RouteDealer = RouteDealer;
}
