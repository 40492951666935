<div class="d-flex gap-3" style="flex-direction: column">
	<app-optional-building-data-select
		[(choice)]="data.table5.epoqueDeConstruction"
		[choices]="Table5.EpoqueDeConstruction.ALL"
		label="Époque de construction"
		[choiceToString]="Table5.EpoqueDeConstruction.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.typeDePatrimoine"
		[choices]="Table5.TypeDePatrimoine.ALL"
		label="Type de patrimoine"
		[choiceToString]="Table5.TypeDePatrimoine.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.formeEmpriseAuSol"
		[choices]="Table5.FormeEmpriseAusol.ALL"
		label="Forme (emprise au sol)"
		[choiceToString]="Table5.FormeEmpriseAusol.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.hauteurMoyenneDesNiveaux"
		[choices]="Table5.HauteurMoyenneDesNiveaux.ALL"
		label="Hauteur moyenne des niveaux"
		[choiceToString]="Table5.HauteurMoyenneDesNiveaux.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.mitoyennete"
		[choices]="Table5.Mitoyennete.ALL"
		label="Mitoyenneté"
		[choiceToString]="Table5.Mitoyennete.toLabel"
	></app-optional-building-data-select>

	<div>
		<div><strong>Co-activité non tertiaire dans le bâtiment</strong></div>
		<mat-checkbox [(ngModel)]="data.table5.coActiviteNonTertiaireDansLeBatiment">
			Présence de co-activité non tertiaire
		</mat-checkbox>
	</div>

	<app-optional-building-data-select
		[(choice)]="data.table5.facade"
		[choices]="Table5.Facade.ALL"
		label="Façade (Typologie)"
		[choiceToString]="Table5.Facade.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.contraintesSurFacade"
		[choices]="Table5.ContraintesSurFacade.ALL"
		label="Contraintes sur façade"
		[choiceToString]="Table5.ContraintesSurFacade.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.isolationThermiqueDesFacades"
		[choices]="Table5.IsolationThermique.ALL"
		label="Isolation thermique des façades"
		[choiceToString]="Table5.IsolationThermique.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.isolationThermiqueDesPignons"
		[choices]="Table5.IsolationThermique.ALL"
		label="Isolation thermique des pignons"
		[choiceToString]="Table5.IsolationThermique.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.modeDIsolationThermiquePrincipal"
		[choices]="Table5.IsolationThermiquePrincipal.ALL"
		label="Mode d'isolation thermique principal"
		[choiceToString]="Table5.IsolationThermiquePrincipal.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.traitementThermiqueDesMenuiseries"
		[choices]="Table5.IsolationThermiqueDesMenuiseries.ALL"
		label="Traitement thermique des menuiseries"
		[choiceToString]="Table5.IsolationThermiqueDesMenuiseries.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.toiture"
		[choices]="Table5.Toiture.ALL"
		label="Toiture (Typologie)"
		[choiceToString]="Table5.Toiture.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.traitementThermiqueDeLaToiture"
		[choices]="Table5.IsolationThermique2.ALL"
		label="Traitement thermique de la toiture"
		[choiceToString]="Table5.IsolationThermique2.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.plancherBas"
		[choices]="Table5.PlancherBas.ALL"
		label="Plancher bas (Typologie)"
		[choiceToString]="Table5.PlancherBas.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table5.isolationThermiqueDuPlancherBas"
		[choices]="Table5.IsolationThermique2.ALL"
		label="Isolation thermique du plancher bas"
		[choiceToString]="Table5.IsolationThermique2.toLabel"
	></app-optional-building-data-select>

	<app-optional-building-data-select
		[(choice)]="data.table6.generateurPrincipalDeChauffage"
		[choices]="Table6.GenerateurPrincipalDeChauffage.ALL"
		label="Générateur principal de chauffage"
		[choiceToString]="Table6.GenerateurPrincipalDeChauffage.toLabel"
	></app-optional-building-data-select>

	<div class="d-flex gap-2" style="flex-direction: column">
		<div>
			<strong>Type principal d'émetteur de chauffage</strong>
			<ng-select [(ngModel)]="data.table6.typePrincipalDEmetteurDeChauffage">
				<ng-option *ngFor="let variant of Table6.TypePrincipalDEmetteurDeChauffage.ALL" [value]="variant">
					<span class="option" [title]="variant | apply: Table6.TypePrincipalDEmetteurDeChauffage.toLabel">
						{{ variant | apply: Table6.TypePrincipalDEmetteurDeChauffage.toLabel }}
					</span>
				</ng-option>
				<ng-option [value]="autreTypePrincipalDEmetteurDeChauffage"> Autre (à compléter) </ng-option>
			</ng-select>
		</div>
		<div class="candy-form" *ngIf="data.table6.typePrincipalDEmetteurDeChauffage | asAutre as autre">
			<div class="custom-input-group">
				<label>Autre type principal d'émetteur de chauffage</label>
				<input class="custom-input" [(ngModel)]="autre.autre" />
			</div>
		</div>
	</div>

	<app-optional-building-data-select
		[(choice)]="data.table6.generateurPrincipalDeRafraichissement"
		[choices]="Table6.GenerateurPrincipalDeRafraichissement.ALL"
		label="Générateur principal de rafraîchissement"
		[choiceToString]="Table6.GenerateurPrincipalDeRafraichissement.toLabel"
	></app-optional-building-data-select>

	<div class="d-flex gap-2" style="flex-direction: column">
		<div>
			<strong>Type principal de ventilation</strong>
			<ng-select [(ngModel)]="data.table6.typePrincipalDeVentilation">
				<ng-option *ngFor="let variant of Table6.TypePrincipalDeVentilation.ALL" [value]="variant">
					<span class="option" [title]="variant | apply: Table6.TypePrincipalDeVentilation.toLabel">
						{{ variant | apply: Table6.TypePrincipalDeVentilation.toLabel }}
					</span>
				</ng-option>
				<ng-option [value]="autreTypePrincipalDeVentilation"> Autre (à compléter) </ng-option>
			</ng-select>
		</div>
		<div class="candy-form" *ngIf="data.table6.typePrincipalDeVentilation | asAutre as autre">
			<div class="custom-input-group">
				<label>Autre type principal de ventilation</label>
				<input class="custom-input" [(ngModel)]="autre.autre" />
			</div>
		</div>
	</div>

	<app-optional-building-data-select
		[(choice)]="data.table6.typeDeProductionDEauChaudeSanitaire"
		[choices]="Table6.TypeDeProductionDEauChaudeSanitaire.ALL"
		label="Type de production d'eau chaude sanitaire"
		[choiceToString]="Table6.TypeDeProductionDEauChaudeSanitaire.toLabel"
	></app-optional-building-data-select>

	<div>
		<strong>Eclairage naturel des locaux</strong>
		<ng-select [(ngModel)]="data.table6.eclairageNaturelDesLocaux">
			<ng-option *ngFor="let variant of Table6.EclairageNaturelDesLocaux.ALL" [value]="variant">
				<span class="option" [title]="variant | apply: Table6.EclairageNaturelDesLocaux.toLabel">
					{{ variant | apply: Table6.EclairageNaturelDesLocaux.toLabel }}
				</span>
			</ng-option>
			<ng-option *ngFor="let variant of Table6.EclairageZenithal.ALL" [value]="variant">
				<span class="option" [title]="variant | apply: Table6.EclairageZenithal.toLabel">
					{{ variant | apply: Table6.EclairageZenithal.toLabel }}
				</span>
			</ng-option>
		</ng-select>
	</div>

	<div>
		<strong>Eclairage naturel des circulations</strong>
		<ng-select [(ngModel)]="data.table6.eclairageNaturelDesCirculations">
			<ng-option *ngFor="let variant of Table6.EclairageNaturelDesCirculations.ALL" [value]="variant">
				<span class="option" [title]="variant | apply: Table6.EclairageNaturelDesCirculations.toLabel">
					{{ variant | apply: Table6.EclairageNaturelDesCirculations.toLabel }}
				</span>
			</ng-option>
			<ng-option *ngFor="let variant of Table6.EclairageZenithal.ALL" [value]="variant">
				<span class="option" [title]="variant | apply: Table6.EclairageZenithal.toLabel">
					{{ variant | apply: Table6.EclairageZenithal.toLabel }}
				</span>
			</ng-option>
		</ng-select>
	</div>

	<app-optional-building-data-select
		[(choice)]="data.table6.eclairageArtificielDesLocaux"
		[choices]="Table6.EclairageArtificielDesLocaux.ALL"
		label="Eclairage artificiel des locaux"
		[choiceToString]="Table6.EclairageArtificielDesLocaux.toLabel"
	></app-optional-building-data-select>

	<div class="d-flex gap-2" style="flex-direction: column">
		<div>
			<strong>Autres équipements immobiliers</strong>
			<ng-select [multiple]="true" [(ngModel)]="data.table6.autresEquipementsImmobiliers">
				<ng-option *ngFor="let choice of autreEquipementImmoibilerChoices" [value]="choice">
					<span class="option" [title]="choice.type | apply: Table6.AutresEquipementsImmobiliers.toLabel">
						{{ choice.type | apply: Table6.AutresEquipementsImmobiliers.toLabel }}
					</span>
				</ng-option>
			</ng-select>
		</div>
		<div class="candy-form" *ngFor="let autresEquipementsImmobiliers of data.table6.autresEquipementsImmobiliers">
			<ng-container *ngIf="autresEquipementsImmobiliers.type === Table6.AutresEquipementsImmobiliers.Ascenceurs">
				<strong>Ascenceurs</strong>
				<div class="custom-input-group">
					<label>Nombre de niveaux desservis</label>
					<input
						appInputRestriction="integer"
						type="number"
						min="1"
						class="custom-input"
						[(ngModel)]="autresEquipementsImmobiliers.nombreDeNiveauDesservis"
					/>
				</div>
				<div class="custom-input-group">
					<label>Nombre d'ascenseurs</label>
					<input
						appInputRestriction="integer"
						type="number"
						min="1"
						class="custom-input"
						[(ngModel)]="autresEquipementsImmobiliers.nombre"
					/>
				</div>
				<div class="custom-input-group">
					<label>Capacité individuelle (nombre de personnes)</label>
					<input
						appInputRestriction="integer"
						type="number"
						min="1"
						class="custom-input"
						[(ngModel)]="autresEquipementsImmobiliers.capaciteIndividuelle"
					/>
				</div>
			</ng-container>
			<ng-container *ngIf="autresEquipementsImmobiliers.type !== Table6.AutresEquipementsImmobiliers.Ascenceurs">
				<strong>
					{{
						autresEquipementsImmobiliers.type === Table6.AutresEquipementsImmobiliers.EscalierMecanique
							? "Escalier mécanique"
							: "Trottoir mécanique"
					}}
				</strong>
				<div class="custom-input-group">
					<label>
						{{
							autresEquipementsImmobiliers.type === Table6.AutresEquipementsImmobiliers.EscalierMecanique
								? "Nombre d'escaliers en fonctionnement continu"
								: "Nombre de trottoirs en fonctionnement continu"
						}}
					</label>
					<input
						appInputRestriction="integer"
						type="number"
						min="0"
						class="custom-input"
						[(ngModel)]="autresEquipementsImmobiliers.fonctionnementContinu"
					/>
				</div>
				<div class="custom-input-group">
					<label>
						{{
							autresEquipementsImmobiliers.type === Table6.AutresEquipementsImmobiliers.EscalierMecanique
								? "Nombre d'escaliers asservis à la détection"
								: "Nombre de trottoirs asservis à la détection"
						}}
					</label>
					<input
						appInputRestriction="integer"
						type="number"
						min="0"
						class="custom-input"
						[(ngModel)]="autresEquipementsImmobiliers.asserviALaDetection"
					/>
				</div>
			</ng-container>
		</div>
	</div>

	<app-optional-building-data-select
		[(choice)]="data.table6.systemeDAutomatisationEtDeControleDesSystemesTechniques"
		[choices]="Table6.SystemeDAutomatisationEtDeControlDesSystemesTechniques.ALL"
		label="Système d'automatisation et de contrôle des systèmes techniques (BACS-Building Automation Control System)"
		[choiceToString]="Table6.SystemeDAutomatisationEtDeControlDesSystemesTechniques.toLabel"
	></app-optional-building-data-select>
</div>
