import { Pipe, PipeTransform } from "@angular/core";
import { ZodType } from "zod";

/**
 * Parse a value and returns its first error message
 */
@Pipe({
	name: "zodSafeParse",
})
export class ZodSafeParsePipe implements PipeTransform {
	transform(value: unknown, zod: ZodType): string {
		const result = zod.safeParse(value);

		return !result.success ? result.error.format()._errors[0] : "";
	}
}
