<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content d-flex-col gap-2 pt-0">
		<h2 class="primary">Ajouter des déclarations</h2>
		<app-newly-liable-input
			#newlyLiableInput
			[(isNewlyLiable)]="isNewlyLiable"
			[(firstYearDeclaration)]="firstYearDeclaration"
		></app-newly-liable-input>
		<app-declarations-creation
			(choiceChange)="choice = $event"
			(isMandatedChange)="isMandated = $event"
			(errorsChange)="errors = $event"
		></app-declarations-creation>
	</div>

	<div class="dialog-actions">
		<button
			*ngIf="choice"
			class="btn-primary-reverse"
			[disabled]="!(choice | apply: isChoiceValid) || errors.size > 0 || !newlyLiableInput.isValid()"
			type="submit"
			(click)="submit(choice, isMandated)"
			[class.btn-loading]="initialLoading.isLoading()"
		>
			Valider
		</button>
		<button
			*ngIf="!choice"
			class="btn-primary-reverse"
			[disabled]="true"
			type="submit"
			[class.btn-loading]="initialLoading.isLoading()"
		>
			Valider
		</button>
	</div>
</div>
