import { AfterContentInit, Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as turf from "@turf/turf";
import { produce, Draft, Immutable } from "immer";
import { BehaviorSubject, EMPTY, Observable, of, zip } from "rxjs";
import { catchError, distinctUntilKeyChanged, first, map, switchMap, tap } from "rxjs/operators";
import { HelpSubject } from "src/app/components/help/help.component";
import { intToReason, Reason } from "src/app/helpers/hasDoneStep";
import * as immObject from "src/app/helpers/immutable";
import { unwrap } from "src/app/helpers/unwrap";
import { AgreementAsk, cloneAgreementAsk } from "src/app/models/agreementAsk";
import { ActivityCategoryEntry, CategoryCode, Irve, isSubCategoryCodeDefault } from "src/app/models/asset";
import { BaseComponent } from "src/app/models/base-component.directive";
import { Declaration } from "src/app/models/declaration";
import { ENERGY_TYPES, EnergyType, energyTypeLabel, getDeliverables } from "src/app/models/energyType";
import {
	EnergyConsumptions,
	entityCanAccessPremiumFeatures,
	EntityInfo,
	FunctionalDeclaration,
	isMultiOccupation,
} from "src/app/models/functionalDeclaration";
import { FunctionalDeclarationId, ParcelId } from "src/app/models/ids";
import { dialogOpen } from "src/app/models/modal";
import { getOldParcelId, getParcelId } from "src/app/models/Parcel";
import { RouteDealer } from "src/app/models/routes";
import { canNotReachStep } from "src/app/pipes/can-reach-step.pipe";
import { AddBlock } from "src/app/pipes/sort-activity-categories-per-start.pipe";
import { DeclarationFunctionalService } from "src/app/services/declaration-functional.service";
import { DeclarationGroupStateService } from "src/app/services/declaration-group-state.service";
import { ElevationService } from "src/app/services/elevation.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { RepresentativeStateService } from "src/app/services/representative-state.service";
import { TourStateService } from "src/app/services/tour-state.service";
import { getCategories } from "src/assets/data/categories";
import { getCounties, Station } from "src/assets/data/stations-meteo";
import { MinDateWithDateStartEntity } from "../../../../../helpers/min-date-with-date-start-entity";
import { toSnakeCase } from "../../../../../helpers/string";
import { EnergyCategory, getEnergyCategoriesToAdd } from "../../../../../models/energyCategory";
import { dateToTimestamp } from "../../../../../pipes/date-to-timestamp.pipe";
import { periodRange } from "../../../../../pipes/period-range.pipe";
import { YearForDateAndPeriodPipe } from "../../../../../pipes/year-for-date-and-period.pipe";
import { AgreementService } from "../../../../../services/agreement.service";
import { ASSET_PAGE } from "../../stepper.component";
import { ActivityCategoryModalComponent } from "./activity-category-modal/activity-category-modal.component";
import { AgreementModalComponent } from "./agreement-modal/agreement-modal.component";
import { ConsumeTokenDialogComponent } from "./consume-token-dialog";
import { ElectricCarDockModalComponent } from "./electric-car-dock-modal/electric-car-dock-modal.component";
import { translateHeatModifier } from "../../../../../pipes/heat-modifier-type-display.pipe";
import { HeatModifierType } from "../../../../../models/heatModifierType";
import { DeclarationStateService } from "../../../../../services/declaration-state.service";
import { Feature, FeatureCollection } from "geojson";

@Component({
	selector: "app-asset-declaration",
	templateUrl: "./asset-declaration.component.html",
	styleUrls: ["./asset-declaration.component.scss"],
})
export class AssetDeclarationComponent extends BaseComponent implements OnDestroy, AfterContentInit {
	STATIONNEMENT_CATEGORY = "CAP22" as CategoryCode;

	CATEGORIES = getCategories();

	readonly HelpSubject = HelpSubject;
	/**
	 * We have to check if something was modified because
	 * this component modify the entity while fetching it.
	 * So after the fetching we update the entity.
	 * We use `isModified` to avoid updating the entity if no modifications were made.
	 */
	readonly functionalDeclaration$ = new BehaviorSubject<{
		entity: Immutable<FunctionalDeclaration>;
		isModified: boolean;
	} | null>(null);
	readonly stations$: Observable<Immutable<Station[]>>;
	readonly prmsAndPces$: Observable<Immutable<{ prms: AgreementAsk[]; pces: AgreementAsk[] }>>;
	readonly centerMap$: Observable<[number, number]>;
	readonly assujettiSurface$: Observable<number>;
	readonly usedDefaultCategory$: Observable<string | undefined>;
	readonly canAddCategory$: Observable<boolean | undefined>;
	readonly categoryDatesConflicts$: Observable<Immutable<ActivityCategoryEntry>[]>;

	readonly mutableState$: Observable<{
		mainParcel: ParcelId | undefined;
		station: number | undefined;
		energyTypes: EnergyType[];
		mainCategory: string | undefined;
	}>;

	readonly ENERGY_TYPES = ENERGY_TYPES;
	readonly GreenKeys = GreenKeys;
	readonly EnergyType = EnergyType;
	readonly todayTimestamp = new Date().getTime() / 1000;
	stepError: Reason | undefined = undefined;

	tokenDialog: MatDialogRef<unknown> | undefined;

	@ViewChild("confirmDeleteEnergyTypeModal") confirmDeleteEnergyTypeModal!: TemplateRef<MatDialog>;
	@ViewChild("energyType") energyTypeInput: NgSelectComponent | undefined;

	constructor(
		private router: Router,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private elevationService: ElevationService,
		private functionalService: DeclarationFunctionalService,
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private tourState: TourStateService,
		private groupState: DeclarationGroupStateService,
		private activatedRoute: ActivatedRoute,
		private representativeState: RepresentativeStateService,
		private agreementService: AgreementService,
		declarationState: DeclarationStateService,
	) {
		super();

		this.sub(this.activatedRoute.queryParams, (params) => {
			if (params.error !== undefined) {
				this.stepError = intToReason(params.error);
				setTimeout(() => {
					const parcelErrorDiv = document.getElementById("stepErrorDiv");
					if (parcelErrorDiv) {
						parcelErrorDiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
						parcelErrorDiv.classList.add("zoom");
						setTimeout(() => {
							parcelErrorDiv.classList.remove("zoom");
						}, 2000);
					}
				});
			}
		});

		this.sub(
			functionalDeclarationState.get$.pipe(
				switchMap((entity) => (entity ? of(entity) : EMPTY)),
				switchMap(({ value, origin }) => {
					if (!value.is_token_used && !value.is_imported_from_operat && origin === "refresh") {
						// we prevent a refresh if no token were used yet
						return EMPTY;
					}

					return of({ entity: value, isModified: false });
				}),
			),
			this.functionalDeclaration$,
		);

		const notNull$ = this.functionalDeclaration$.pipe(
			switchMap((entityAndIsModified) => (entityAndIsModified ? of(entityAndIsModified.entity) : EMPTY)),
		);

		this.sub(
			notNull$.pipe(
				distinctUntilKeyChanged(GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID),
				switchMap((entity) => this.handleInitAssets(entity, declarationState)),
			),
			this.functionalDeclaration$,
		);

		this.sub(
			this.functionalDeclaration$.pipe(switchMap((nullable) => (nullable ? of(nullable) : EMPTY))),
			({ entity, isModified }) => {
				if (isModified) {
					this.functionalService.update(entity);
				}
			},
		);

		this.stations$ = notNull$.pipe(
			map(({ address: { postalCode } }) => postalCode),
			map(getStations),
			map((stations) => (stations.length === 0 ? getCounties().flatMap((county) => county.stations) : stations)),
		);

		const agreement_asks$ = notNull$.pipe(map(({ agreement_asks }) => agreement_asks));

		const prms$ = agreement_asks$.pipe(map((ask) => ask.filter(({ type }) => type === "PRM")));
		const pces$ = agreement_asks$.pipe(map((ask) => ask.filter(({ type }) => type === "PCE")));

		this.prmsAndPces$ = zip(prms$, pces$).pipe(map(([prms, pces]) => ({ prms, pces })));

		this.mutableState$ = notNull$.pipe(
			map(
				({
					infos: {
						asset: { mainParcel, station, energyType, mainCategory },
					},
				}) => ({ mainParcel, station, energyTypes: [...energyType], mainCategory }),
			),
		);

		this.centerMap$ = notNull$.pipe(
			switchMap((functionalDeclaration) => {
				if (functionalDeclaration.is_imported_from_operat) {
					return EMPTY;
				}
				return of(functionalDeclaration);
			}),
			map(centerFromFunctionalDeclaration),
		);
		this.assujettiSurface$ = notNull$.pipe(
			map(({ infos }) => infos),
			map(EntityInfo.assujettiArea),
			map(({ owned }) => owned),
		);
		this.usedDefaultCategory$ = notNull$.pipe(
			map(
				({
					infos: {
						asset: { categories },
					},
				}) => categories.find((category) => isSubCategoryCodeDefault(category.subCategoryCode))?.subCategoryCode,
			),
		);

		// If there is a default category going from 2009-01-01 to an undefined end date, returns false
		this.canAddCategory$ = notNull$.pipe(
			map(
				({
					infos: {
						asset: { categories },
					},
				}) => categories.filter((category) => isSubCategoryCodeDefault(category.subCategoryCode)),
			),
			map((categories: Immutable<ActivityCategoryEntry>[] | undefined) => {
				for (const category of categories ?? []) {
					if (category && !category.end && category.start === Declaration.MINIMUM_DATE_TIME) {
						return false;
					}
				}

				return true;
			}),
		);

		// For every default category, check if its interval overlaps with a 'normal' category, add it in the result if it's the case
		this.categoryDatesConflicts$ = notNull$.pipe(
			map(
				({
					infos: {
						asset: { categories },
						favoritePeriod,
					},
				}) => {
					const result = [];
					const defaultCategories = categories.filter((category) => isSubCategoryCodeDefault(category.subCategoryCode));
					for (const defaultCategory of defaultCategories) {
						for (const category of categories.filter(
							(category) => !isSubCategoryCodeDefault(category.subCategoryCode),
						)) {
							if (
								this.checkPeriodsConflict(
									{ start: defaultCategory.start, end: defaultCategory.end },
									{ start: category.start, end: category.end },
									favoritePeriod,
								)
							) {
								result.push(defaultCategory);
								break;
							}
						}
					}
					return result;
				},
			),
		);
	}

	private handleInitAssets(
		entity: Immutable<FunctionalDeclaration>,
		declarationState: DeclarationStateService,
	): Observable<{ entity: Immutable<FunctionalDeclaration>; isModified: boolean }> {
		return of(entity).pipe(
			switchMap((entity) => {
				const reason = canNotReachStep(entity, ASSET_PAGE);

				if (reason !== null) {
					// HOTFIX
					setTimeout(() => this.back(unwrap(entity.declaration_functional_id), reason), 0);
					return EMPTY;
				}

				return of(entity);
			}),
			switchMap((entity) => this.handleTokenCheck(entity, false)),
			tap(() => declarationState.saving$.next(true)),
			map(({ entity, isModified }) => this.handleMainParcel(entity, isModified)),
			switchMap(({ entity, isModified }) => this.handleAltitude(entity, isModified)),
			map(({ entity, isModified }) => this.handleWeatherStation(entity, isModified)),
			switchMap(({ entity, isModified }) =>
				isModified
					? this.functionalDeclarationState.updateSync$(entity).pipe(map(() => ({ entity, isModified })))
					: of({ entity, isModified }),
			),
			tap(() => declarationState.saving$.next(false)),
		);
	}

	private handleTokenCheck(
		entity: Immutable<FunctionalDeclaration>,
		isModified: boolean,
	): Observable<{ entity: Immutable<FunctionalDeclaration>; isModified: boolean }> {
		if (!entity.is_token_used && !entity.is_imported_from_operat) {
			return zip(this.representativeState.get$, this.groupState.get$).pipe(
				first(),
				switchMap(([representative, group]) => {
					const dialog = dialogOpen(
						this.dialog,
						ConsumeTokenDialogComponent,
						{
							functionalService: this.functionalService,
							functionalDeclaration: entity,
							representative: representative?.value,
							declarationGroup: group?.value,
						},
						{ panelClass: "p-0" },
					);
					this.tokenDialog = dialog;
					return dialog.afterClosed().pipe(
						switchMap((output) => {
							if (output) {
								switch (output.type) {
									case "cancel": {
										this.back(unwrap(entity.declaration_functional_id));
										return EMPTY;
									}
									case "cant buy": {
										this.snackBar.open(
											"Vous n'avez pas assez de déclarations pour aller jusqu'à cette étape. Pour continuer veuillez contacter votre référent",
											"OK",
											{ verticalPosition: "top" },
										);
										this.back(unwrap(entity.declaration_functional_id));
										return EMPTY;
									}
									case "updated":
										return of(output.entity).pipe(
											switchMap((entityWithTokenUsed) => {
												if (this.groupState.getId()) {
													return this.groupState.get$.pipe(
														first(),
														switchMap((group) => (group ? of(group.value) : EMPTY)),
														map((group) =>
															produce(group, (draft) => {
																draft.used_tokens++;
															}),
														),
														switchMap((group) => this.groupState.update$(group)),
														map(() => entityWithTokenUsed),
													);
												}
												return of(entityWithTokenUsed);
											}),
										);
								}
							}
							this.back(unwrap(entity.declaration_functional_id));
							return EMPTY;
						}),
					);
				}),
				map((entity) => ({ entity, isModified: true })),
			);
		}

		return of({ entity, isModified });
	}

	private handleMainParcel(
		entity: Immutable<FunctionalDeclaration>,
		isModified: boolean,
	): { entity: Immutable<FunctionalDeclaration>; isModified: boolean } {
		return entity.infos.asset.mainParcel || entity.is_imported_from_operat
			? { entity, isModified }
			: {
					entity: produce(entity, (draft) => {
						draft.infos.asset.mainParcel = getParcelId(entity.infos.parcelles[0]);
					}),
					isModified: true,
				};
	}

	private handleAltitude(
		entity: Immutable<FunctionalDeclaration>,
		isModified: boolean,
	): Observable<{ entity: Immutable<FunctionalDeclaration>; isModified: boolean }> {
		if (entity.infos.altitude === undefined && !entity.is_imported_from_operat) {
			const center = centerFromFunctionalDeclaration(entity);
			return this.elevationService.getHeight(center[0], center[1]).pipe(
				catchError(() => of(undefined)),
				map((altitude) => {
					return produce(entity, (draft) => {
						draft.infos.altitude = altitude;
					});
				}),
				map((entity) => ({ entity, isModified: true })),
			);
		}

		return of({ entity, isModified });
	}

	private handleWeatherStation(
		entity: Immutable<FunctionalDeclaration>,
		isModified: boolean,
	): { entity: Immutable<FunctionalDeclaration>; isModified: boolean } {
		// If entity imported, missing data necessary to handle default weather station
		if (entity.infos.asset.station) {
			return { entity, isModified };
		}

		const county = getCounties().find((county) =>
			entity.address.postalCode.startsWith(String(county.code).padStart(2, "0")),
		);
		if (!county) {
			return { entity, isModified };
		}

		const center = centerFromFunctionalDeclaration(entity);

		const updated = produce(entity, ({ infos: { asset } }) => {
			asset.station = county.getNearestStation(center).code;
		});

		return { entity: updated, isModified: true };
	}

	update(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		recipe: (draft: Draft<Immutable<FunctionalDeclaration>>) => void,
	) {
		this.functionalDeclaration$.next({
			entity: produce(functionalDeclaration, (draft) => {
				recipe(draft);
			}),
			isModified: true,
		});
	}

	toggleNotInCsv(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		this.update(functionalDeclaration, (draft) => {
			draft.not_in_csv = !draft.not_in_csv;
		});
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.tokenDialog?.close({ type: "cancel" });
		this.tourState.resetTour();
	}

	ngAfterContentInit(): void {
		this.tourState.initialize([
			{
				anchorId: "map-main-plot",
				content:
					"Confirmez que la parcelle sélectionnée est votre parcelle principale, vous pouvez la modifié en cliquant sur la carte interactive.",
				title: "Sélectionnez votre parcelle principale",
			},
			{
				anchorId: "asset-meteo-station",
				content:
					"Confirmez que la station météo pré-selectionnée est celle qui correspond le plus à la votre. Sélectionnez la station la plus proche dans votre tranche d'altitude et votre proximité au littoral.",
				title: "Sélectionnez votre station météo",
			},
			{
				anchorId: "asset-energy",
				content: "Sélectionnez l'ensemble des types d'énergies utilisés sur votre entité fonctionnelle.",
				title: "Sélectionnez vos énergies",
			},
			{
				anchorId: "asset-agreements",
				content:
					"Indiquez la référence d'acheminement, le titulaire du contrat ainsi qu'un référent. Ces informations permettront de récupérer automatiquement vos données de consommations.",
				title: "Décrivez vos points de livraison ENEDIS et GRDF",
			},
			{
				anchorId: "asset-surfaces",
				content:
					"Les catégories permettront de calculer vos objectifs. Renseignez toutes les informations demandées pour ajouter une ou plusieurs sous-catégories.",
				title: "Indiquez vos sous-catégories d'activité",
				placement: {
					horizontal: true,
				},
			},
		]);
	}

	back(id: FunctionalDeclarationId, error?: number) {
		let params = {};
		if (error !== undefined) {
			params = { error };
		}
		this.router.navigate(RouteDealer.qualification(id), { queryParams: params });
	}

	setMainParcel(functionalDeclaration: Immutable<FunctionalDeclaration>, parcelId: ParcelId): void {
		this.update(functionalDeclaration, (draft) => (draft.infos.asset.mainParcel = parcelId));
	}

	public addPrm(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		this.addAgreementAsk(functionalDeclaration, new AgreementAsk("PRM"));
	}

	public addPce(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		this.addAgreementAsk(functionalDeclaration, new AgreementAsk("PCE"));
	}

	public editAgreement(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		agreement: Immutable<AgreementAsk>,
		index: number,
		prms: Immutable<AgreementAsk[]>,
		pces: Immutable<AgreementAsk[]>,
	) {
		dialogOpen(
			this.dialog,
			AgreementModalComponent,
			{
				agreement: agreement,
				canDelete: true,
				functionalDeclaration: functionalDeclaration,
				adding: false,
				agreementService: this.agreementService,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((result?: AgreementAsk | boolean) => {
				if (result === undefined) {
					return;
				}
				this.update(functionalDeclaration, (draft) => {
					if (typeof result === "boolean") {
						if (agreement.type === "PCE") {
							pces = produce(pces, (draft) => {
								draft.splice(index, 1);
							});
						} else {
							prms = produce(prms, (draft) => {
								draft.splice(index, 1);
							});
						}
						const energyCategory = isMultiOccupation(functionalDeclaration)
							? agreement.energy_category
							: EnergyCategory.INDIVIDUAL;
						if (!Array.isArray(draft.consumptions) && draft.consumptions[energyCategory]) {
							Object.keys(draft.consumptions[energyCategory]).forEach((line) => {
								if (line.endsWith(agreement.reference)) {
									delete draft.consumptions[energyCategory][line];
								}
							});
						}
					} else {
						// If the email has been changed, reset the agreement ask mail
						if (result.email !== draft.agreement_asks[index].email) {
							result.is_email_sent = false;
							result.email_sent_at = undefined;
						}
						if (result.type === "PCE") {
							pces = produce(pces, (draft) => {
								draft[index] = result;
							});
						} else {
							prms = produce(prms, (draft) => {
								draft[index] = result;
							});
						}
					}

					draft.agreement_asks = [...pces.map(cloneAgreementAsk), ...prms.map(cloneAgreementAsk)];
				});
			});
	}

	editCategoryModal(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		activityCategoryEntry: Immutable<ActivityCategoryEntry>,
	) {
		const index = functionalDeclaration.infos.asset.categories.indexOf(activityCategoryEntry);
		dialogOpen(
			this.dialog,
			ActivityCategoryModalComponent,
			{
				functionalDeclarationService: this.functionalService,
				altitude: functionalDeclaration.infos.altitude,
				zipCode: functionalDeclaration.address.postalCode,
				activityCategoryEntry,
				adding: false,
				useDefaultCategory: false,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (!output) {
					return;
				}

				this.update(functionalDeclaration, (draft) => {
					if (output.type === ActivityCategoryModalComponent.OutputType.Delete) {
						draft.infos.asset.categories.splice(index, 1);
						return;
					}

					draft.infos.asset.categories[index] = output.activityCategoryEntry;
				});
			});
	}

	addCategoryModal(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		addBlock: Immutable<AddBlock> | undefined = undefined,
	) {
		// I use this function to do early returns
		const input = (() => {
			if (addBlock !== undefined && addBlock.template.type === "activityCategory") {
				return {
					functionalDeclarationService: this.functionalService,
					altitude: functionalDeclaration.infos.altitude,
					zipCode: functionalDeclaration.address.postalCode,
					adding: true,
					useDefaultCategory: this.usedDefaultSubCategory(functionalDeclaration) !== undefined,
					activityCategoryEntry: {
						...addBlock.template.object,
						start: addBlock.start,
						end: addBlock.end,
					},
					bounds: addBlock.template.object
						? { start: addBlock.template.object.end ?? Declaration.MINIMUM_DATE_TIME, end: undefined }
						: { start: Declaration.MINIMUM_DATE_TIME, end: undefined },
				} as const;
			}
			return {
				functionalDeclarationService: this.functionalService,
				altitude: functionalDeclaration.infos.altitude,
				zipCode: functionalDeclaration.address.postalCode,
				adding: true,
				useDefaultCategory: false,
				categoryCode: functionalDeclaration.infos.asset.mainCategory,
				// the bounds start just after the older activity entry if it exists
				bounds: { start: Declaration.MINIMUM_DATE_TIME, end: undefined },
			} as const;
		})();

		dialogOpen(this.dialog, ActivityCategoryModalComponent, input, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((output) => {
				if (output?.type !== ActivityCategoryModalComponent.OutputType.Save) {
					return;
				}

				this.update(functionalDeclaration, (draft) => draft.infos.asset.categories.push(output.activityCategoryEntry));
			});
	}

	editElectricCarDockModal(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		electricCarDockEntry: { start: number; end?: number; count: number },
	) {
		const index = functionalDeclaration.infos.asset.electricCarDock.indexOf(electricCarDockEntry);
		this.dialog
			.open(ElectricCarDockModalComponent, {
				data: { electricCarDockEntry: electricCarDockEntry, adding: false },
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((ret: { start: number; end?: number; count: number } | null | undefined) => {
				if (ret === undefined) {
					return;
				}

				this.update(functionalDeclaration, (draft) => {
					if (ret === null) {
						draft.infos.asset.electricCarDock.splice(index, 1);
					} else {
						draft.infos.asset.electricCarDock[index] = ret;
					}
					this.editIrveRange(draft);
				});
			});
	}

	addElectricCarDockModal(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		addBlock: AddBlock | undefined = undefined,
	) {
		let template = new Irve({ start: Declaration.MINIMUM_DATE_TIME, count: 1 });
		if (addBlock !== undefined) {
			template = new Irve({
				...addBlock,
				...addBlock.template.object,
			});
		}
		const electricCarDockEntry = { ...template };
		if (electricCarDockEntry.end !== undefined) {
			electricCarDockEntry.start = electricCarDockEntry.end;
			electricCarDockEntry.end = undefined;
		} else if (electricCarDockEntry.start !== Declaration.MINIMUM_DATE_TIME) {
			electricCarDockEntry.end = electricCarDockEntry.start;
			electricCarDockEntry.start = Declaration.MINIMUM_DATE_TIME;
		}
		this.dialog
			.open(ElectricCarDockModalComponent, {
				data: { electricCarDockEntry: electricCarDockEntry, adding: true },
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((ret: undefined | null | { start: number; end?: number; count: number }) => {
				if (ret) {
					this.update(functionalDeclaration, (draft) => {
						draft.infos.asset.electricCarDock.push(ret);
						this.editIrveRange(draft);
					});
				}
			});
	}

	editIrveRange(draft: Draft<FunctionalDeclaration>) {
		if (draft.infos.asset.electricCarDock.length === 0) {
			return;
		}
		let min: number = Infinity;
		let max: number | undefined = -Infinity;
		draft.infos.asset.electricCarDock.forEach((entry) => {
			min = Math.min(entry.start, min);
			max = max === undefined || entry.end === undefined ? undefined : Math.max(entry.end, max);
		});
		if (draft.consumptions[EnergyCategory.DEDUCT] === undefined) {
			draft.consumptions = { ...draft.consumptions, [EnergyCategory.DEDUCT]: {} };
		}

		if (draft.first_year_declaration) {
			const { start } = periodRange(draft.first_year_declaration, draft.infos.favoritePeriod);
			const startDateTime = dateToTimestamp(start);
			min = Math.max(min, startDateTime);
			if (max <= startDateTime) {
				return;
			}
		}

		if (draft.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"] === undefined) {
			draft.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"] = { label: "Borne(s) IRVE", values: {} };
		}
		if (
			draft.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"].values[EnergyType.ELECTRICITY_KWH] === undefined
		) {
			draft.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"].values[EnergyType.ELECTRICITY_KWH] =
				new EnergyConsumptions({});
		}
		draft.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"].values[EnergyType.ELECTRICITY_KWH].start = min;
		draft.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"].values[EnergyType.ELECTRICITY_KWH].end = max;
	}

	usedDefaultSubCategory(functionalDeclaration: Immutable<FunctionalDeclaration>): string | undefined {
		return functionalDeclaration.infos.asset.categories.find((category) =>
			isSubCategoryCodeDefault(category.subCategoryCode),
		)?.subCategoryCode;
	}

	setStation(functionalDeclaration: Immutable<FunctionalDeclaration>, code: number) {
		this.update(functionalDeclaration, (draft) => (draft.infos.asset.station = code));
	}

	private checkEnergyTypeInputs(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		energyTypes: EnergyType[],
	): boolean {
		let error = false;
		this.energyTypeInput?.searchInput.nativeElement.setCustomValidity("");
		if (
			!energyTypes.includes(EnergyType.ELECTRICITY_KWH) &&
			functionalDeclaration.agreement_asks.find((agreement) => agreement.type === "PRM")
		) {
			error = true;
			this.energyTypeInput?.searchInput.nativeElement.setCustomValidity(
				"Veuillez supprimer l'ensemble de vos PRM avant de supprimer ce type d'énergie.",
			);
		}
		if (
			!energyTypes.includes(EnergyType.GAZ_NAT_NETWORK_KWH) &&
			functionalDeclaration.agreement_asks.find((agreement) => agreement.type === "PCE")
		) {
			error = true;
			this.energyTypeInput?.searchInput.nativeElement.setCustomValidity(
				"Veuillez supprimer l'ensemble de vos PCE avant de supprimer ce type d'énergie.",
			);
		}

		return error;
	}

	addEnergyType(functionalDeclaration: Immutable<FunctionalDeclaration>, energyType: EnergyType) {
		// Init consumption entity for new type
		const energyTypeConsumptionEntity = new EnergyConsumptions({
			end: undefined,
			start: MinDateWithDateStartEntity.getMinDateForNewlyLiableEntity(
				functionalDeclaration,
				Declaration.MINIMUM_DATE_TIME,
			),
			byInvoice: getDeliverables().includes(energyType),
		});

		return produce(functionalDeclaration, (draft) => {
			// Add new conso lines for type in main categories
			getEnergyCategoriesToAdd(isMultiOccupation(functionalDeclaration)).forEach((category) => {
				if (draft.consumptions[category] === undefined) {
					draft.consumptions[category] = {};
				}
				let count = 0;
				let label = "";
				do {
					label = toSnakeCase(energyType) + (count === 0 ? "" : count);
					count++;
				} while (draft.consumptions[category][label] !== undefined);
				draft.consumptions[category][label] = {
					label: "Consommations " + energyTypeLabel(energyType),
					values: {},
				};

				// Copy to prevent duplicate with other categories
				draft.consumptions[category][label].values[energyType] = { ...energyTypeConsumptionEntity };
			});

			// Get EFA heat modifiers
			const heatModifiers = [
				...new Set(draft.infos.asset.categories.flatMap(({ temperatureModifiers }) => temperatureModifiers)),
			];
			// Init USAGE if doesn't exist
			if (draft.consumptions[EnergyCategory.USAGE] === undefined) {
				draft.consumptions[EnergyCategory.USAGE] = {};
			}
			for (const modifier of heatModifiers) {
				if (!draft.consumptions[EnergyCategory.USAGE][modifier]) {
					draft.consumptions[EnergyCategory.USAGE][modifier] = {
						label: translateHeatModifier(modifier as HeatModifierType),
						values: {},
					};
				}

				// Copy to prevent duplicate with other categories
				draft.consumptions[EnergyCategory.USAGE][modifier].values[energyType] = { ...energyTypeConsumptionEntity };
			}

			draft.infos.asset.energyType.push(energyType);
		});
	}

	deleteEnergyType$(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		energyType: EnergyType,
	): Observable<Immutable<FunctionalDeclaration>> {
		const energyCategories = getEnergyCategoriesToAdd(isMultiOccupation(functionalDeclaration));
		// I use the Set "add" method to be sure that there won't be duplicates if someone edits the getEnergyCategoriesToAdd function
		energyCategories.add(EnergyCategory.USAGE);

		// From energy categories, extract consumptions lines with energy type and feed to toDelete array
		const toDelete: { energyCategory: EnergyCategory; line: string }[] = Array.from(energyCategories)
			// Extract lines from energy category
			.flatMap((energyCategory) =>
				immObject
					.entries(functionalDeclaration.consumptions[energyCategory] ?? {})
					.map(([line, lineObj]) => ({ line, lineObj, energyCategory })),
			)
			// Map line to energy type, line key and category
			.flatMap(({ line, lineObj, energyCategory }) =>
				immObject.keys((lineObj ?? { values: {} }).values).map((energy) => ({ energy, line, energyCategory })),
			)
			.filter(({ energy }) => energy === energyType)
			// if energy type from conso in energyType array, keep it, otherwise add to delete array
			.map(({ line, energyCategory }) => ({ energyCategory, line }));

		const hasConsumption = toDelete.some(
			({ energyCategory, line }) =>
				functionalDeclaration.consumptions[energyCategory][line].values[energyType].values.length > 0,
		);
		const data = {
			typeLabel: energyTypeLabel(energyType),
			descriptions: toDelete.map(
				({ energyCategory, line }) =>
					functionalDeclaration.consumptions[energyCategory][line].label +
					(energyCategory === EnergyCategory.USAGE ? ` (${energyTypeLabel(energyType)})` : ""),
			),
		};

		const delete$: Observable<boolean> = hasConsumption
			? this.dialog.open(this.confirmDeleteEnergyTypeModal, { data, panelClass: "p-0" }).afterClosed()
			: of(true);

		return delete$.pipe(
			map((userDelete) => {
				if (userDelete) {
					return produce(functionalDeclaration, (draft) => {
						toDelete.forEach(({ energyCategory, line }) => {
							delete draft.consumptions[energyCategory][line].values[energyType];
							if (Object.keys(draft.consumptions[energyCategory][line].values).length === 0) {
								delete draft.consumptions[energyCategory][line];
							}
						});

						// Remove deleted type
						draft.infos.asset.energyType = draft.infos.asset.energyType.filter((type) => type !== energyType);
					});
				}
				return functionalDeclaration;
			}),
		);
	}

	setEnergyTypes(functionalDeclaration: Immutable<FunctionalDeclaration>, energyTypes: EnergyType[]) {
		// Fix no INDIVIDUAL consumptions
		functionalDeclaration = produce(functionalDeclaration, (draft) => {
			if (draft.consumptions[EnergyCategory.INDIVIDUAL] === undefined) {
				draft.consumptions = { ...draft.consumptions, [EnergyCategory.INDIVIDUAL]: {} };
			}
		});

		if (this.checkEnergyTypeInputs(functionalDeclaration, energyTypes)) {
			// Force energy type selector to reload
			this.functionalDeclaration$.next({ entity: functionalDeclaration, isModified: false });
			return;
		}

		// Modification will always be 1 type at a time, either delete or add
		const energyToDelete = functionalDeclaration.infos.asset.energyType.find((energy) => !energyTypes.includes(energy));
		const energyToAdd = energyTypes.find((energy) => !functionalDeclaration.infos.asset.energyType.includes(energy));

		const modifiedEfa$ = energyToDelete
			? this.deleteEnergyType$(functionalDeclaration, energyToDelete)
			: energyToAdd
				? of(this.addEnergyType(functionalDeclaration, energyToAdd))
				: EMPTY;

		modifiedEfa$.subscribe((modifiedEfa) => {
			this.functionalDeclaration$.next({ entity: modifiedEfa, isModified: true });
		});
	}

	setMainCategory(functionalDeclaration: Immutable<FunctionalDeclaration>, mainCategory: CategoryCode | undefined) {
		this.update(functionalDeclaration, (draft) => (draft.infos.asset.mainCategory = mainCategory));
	}

	setLocalArea(functionalDeclaration: Immutable<FunctionalDeclaration>, area: string) {
		this.update(functionalDeclaration, (draft) => (draft.infos.asset.localArea = parseFloat(area)));
	}

	private addAgreementAsk(functionalDeclaration: Immutable<FunctionalDeclaration>, agreement: AgreementAsk) {
		dialogOpen(
			this.dialog,
			AgreementModalComponent,
			{
				agreement: agreement,
				canDelete: false,
				functionalDeclaration: functionalDeclaration,
				adding: true,
				agreementService: this.agreementService,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((result: AgreementAsk | boolean | undefined | null) => {
				if (!result || typeof result === "boolean") {
					return;
				}

				// Si l'EFA est nouvellement assujetti, on refuse l'historique long
				result.year_data_received = !!functionalDeclaration.first_year_declaration;

				this.update(functionalDeclaration, (draft) => draft.agreement_asks.push(result));
			});
	}

	/**
	 * check if 2 intervals overlap
	 * Adapts to favorite period
	 * @param defaultCategoryPeriod
	 * @param categoryPeriod
	 * @param favoritePeriod
	 * @private
	 */
	private checkPeriodsConflict(
		defaultCategoryPeriod: { start: number; end: number | undefined },
		categoryPeriod: { start: number; end: number | undefined },
		favoritePeriod: number,
	): boolean {
		const yearForDateAndPeriod = new YearForDateAndPeriodPipe();
		const secondsInADay = 86400;

		const defaultCategoryPeriodStartingYear = yearForDateAndPeriod.transform(
			defaultCategoryPeriod.start,
			favoritePeriod,
		);
		// End date is excluded, so we remove one day
		const defaultCategoryPeriodEndingYear = defaultCategoryPeriod.end
			? yearForDateAndPeriod.transform(defaultCategoryPeriod.end - secondsInADay, favoritePeriod)
			: undefined;

		const categoryPeriodStartingYear = yearForDateAndPeriod.transform(categoryPeriod.start, favoritePeriod);
		const categoryPeriodEndingYear = categoryPeriod.end
			? yearForDateAndPeriod.transform(categoryPeriod.end - secondsInADay, favoritePeriod)
			: undefined;

		const mainIncludesSub = (
			mainPeriod: { start: number; end: number | undefined },
			subPeriod: { start: number; end: number | undefined },
		) => {
			// if subPeriod's start included in mainPeriod
			if (subPeriod.start >= mainPeriod.start && (!mainPeriod.end || subPeriod.start <= mainPeriod.end)) {
				return true;
			}

			//if subPerdiod's end included in mainPeriod
			return !!(
				subPeriod.end &&
				subPeriod.end > mainPeriod.start &&
				(!mainPeriod.end || subPeriod.end <= mainPeriod.end)
			);
		};

		return (
			mainIncludesSub(
				{ start: defaultCategoryPeriodStartingYear, end: defaultCategoryPeriodEndingYear },
				{ start: categoryPeriodStartingYear, end: categoryPeriodEndingYear },
			) ||
			mainIncludesSub(
				{ start: categoryPeriodStartingYear, end: categoryPeriodEndingYear },
				{ start: defaultCategoryPeriodStartingYear, end: defaultCategoryPeriodEndingYear },
			)
		);
	}

	protected entityCanAccessPremiumFeatures = entityCanAccessPremiumFeatures;
}

function getStations(postalCode: string): Station[] {
	const currentCounty = getCounties().find((county) => postalCode.startsWith(String(county.code).padStart(2, "0")));

	if (!currentCounty) {
		return [];
	}

	const stations = currentCounty.stations;
	currentCounty.bordering.forEach((borderingCode) => {
		const borderingCounty = getCounties().find((county) => county.code === borderingCode);

		if (borderingCounty) {
			borderingCounty.stations.forEach((newStation) => {
				if (!stations.find((station) => station.code === newStation.code)) {
					stations.push(newStation);
				}
			});
		}
	});

	return stations;
}

function centerFromFunctionalDeclaration({
	infos: {
		parcelles,
		asset: { mainParcel },
	},
}: Immutable<FunctionalDeclaration>) {
	const parcel = parcelles.find(
		(parcel) =>
			(parcel.type === "FeatureCollection" && getOldParcelId(parcel) === mainParcel) ||
			(parcel.type === "Feature" && parcel.properties.id === mainParcel),
	);
	// turf.center() throw if parcel undefined
	if (!parcel) {
		return [0, 0] as [number, number];
	}
	return turf.center(parcel as Feature | FeatureCollection).geometry.coordinates as [number, number];
}
