import { GeographicZone } from "../../app/models/geographicZone";
import * as turf from "@turf/turf";

export function getCounties(): County[] {
	return [
		new County(
			1,
			"Ain",
			GeographicZone.H1C,
			true,
			[
				new Station(1089001, 45.976, 5.329, 251, "Ambérieu-en-Bugey", ""),
				new Station(1414001, 45.916, 5.625, 878, "Sutrieu", "Altitude"),
			],
			[38, 73, 74, 39, 71, 69],
		),
		new County(
			2,
			"Aisne",
			GeographicZone.H1A,
			false,
			[new Station(2320001, 49.818, 3.206, 98, "Saint Quentin", "")],
			[59, 80, 60, 77, 51, 8],
		),
		new County(
			3,
			"Allier",
			GeographicZone.H1C,
			true,
			[
				new Station(3060001, 46.166, 3.398, 249, "Vichy-Charmeil", ""),
				new Station(3248001, 46.051, 3.805, 1022, "Sutrieu", "Altitude"),
			],
			[42, 71, 58, 18, 23, 63],
		),
		new County(
			4,
			"Alpes-de-Haute-Provence",
			GeographicZone.H2D,
			true,
			[
				new Station(4049001, 44.062, 5.989, 458, "Saint-Auban-sur-Durance", ""),
				new Station(4019001, 44.391, 6.67, 1155, "Barcelonnette", "Altitude"),
			],
			[83, 6, 5, 26, 84, 13],
		),
		new County(
			5,
			"Hautes-Alpes",
			GeographicZone.H1C,
			true,
			[
				new Station(5046001, 44.566, 6.502, 871, "Embrun", ""),
				new Station(5183001, 44.88, 6.64, 1310, "Villar-Saint-Pancrace", "Altitude"),
			],
			[4, 26, 38, 73],
		),
		new County(
			6,
			"Alpes-Maritimes",
			GeographicZone.H3,
			true,
			[
				new Station(6088001, 43.648, 7.209, 2, "Nice", ""),
				new Station(6163007, 44.043, 7.586, 636, "Tende_SAPC", "Altitude"),
				new Station(6077006, 43.929, 7.363, 1443, "Peira Cava", "Altitude"),
			],
			[83, 4, 20],
		),
		new County(
			7,
			"Ardèche",
			GeographicZone.H2D,
			true,
			[
				new Station(7131001, 44.538, 4.367, 280, "Lanas - Syn", ""),
				new Station(7068001, 45.015, 4.671, 566, "Colombier-le-Jeune", ""),
				new Station(7154005, 44.734, 4.084, 1240, "Mazan-l'Abbaye", "Altitude"),
			],
			[48, 30, 84, 26, 38, 69, 43],
		),
		new County(
			8,
			"Ardennes",
			GeographicZone.H1B,
			false,
			[new Station(8105005, 49.782, 4.643, 147, "Charleville-Mézières", "")],
			[55, 51, 2, 59],
		),
		new County(
			9,
			"Ariège",
			GeographicZone.H2C,
			true,
			[
				new Station(9289001, 43.005, 1.106, 414, "Saint-Girons - Antichan", ""),
				new Station(9024004, 42.724, 1.691, 1781, "Aston", "Altitude"),
			],
			[31, 11, 66],
		),
		new County(
			10,
			"Aube",
			GeographicZone.H1B,
			false,
			[new Station(10030001, 49.782, 4.643, 147, "Charleville-Mézières", "")],
			[89, 21, 52, 51, 77],
		),
		new County(
			11,
			"Aude",
			GeographicZone.H3,
			false,
			[
				new Station(11069001, 43.215, 2.295, 128, "Carcassonne - Salvaza", ""),
				new Station(11262005, 43.15, 2.956, 110, "Narbonne", "Littoral"),
			],
			[9, 31, 81, 34],
		),
		new County(
			12,
			"Aveyron",
			GeographicZone.H2C,
			true,
			[
				new Station(12145001, 44.118, 3.019, 712, "Millau - Soulobres", ""),
				new Station(12254001, 44.41, 2.483, 578, "Rodez", ""),
				new Station(12014001, 44.534, 3.008, 1076, "Aurelle-Verlac_SAPC", "Altitude"),
			],
			[34, 30, 48, 15, 46, 82, 81],
		),
		new County(
			13,
			"Bouches-du-Rhône",
			GeographicZone.H3,
			false,
			[
				new Station(13054001, 43.437, 5.216, 9, "Marseille - Marignane", "Ref - Littoral"),
				new Station(13103001, 43.603, 5.104, 58, "Salon-de-Provence ", "Arrière Pays"),
			],
			[30, 84, 83, 20],
		),
		new County(
			14,
			"Calvados",
			GeographicZone.H1A,
			false,
			[new Station(14137001, 49.18, -0.456, 67, "Caen - Carpiquet", "")],
			[27, 61, 50],
		),
		new County(
			15,
			"Cantal",
			GeographicZone.H1C,
			true,
			[
				new Station(15014004, 44.898, 2.421, 639, "Aurillac", ""),
				new Station(15053001, 45.075, 2.991, 979, "Coltines", "Altitude"),
				new Station(15101004, 45.082, 2.751, 1238, "Le Lioran_SAPC", "Altitude"),
			],
			[48, 43, 63, 19, 46, 12],
		),
		new County(
			16,
			"Charente",
			GeographicZone.H2B,
			false,
			[new Station(16089001, 45.665, -0.315, 30, "Cognac", "")],
			[17, 79, 86, 87, 24],
		),
		new County(
			17,
			"Charente-Maritime",
			GeographicZone.H2B,
			false,
			[
				new Station(17300009, 46.178, -1.193, 20, "La Rochelle - Ile de Ré", "Ref - Littoral"),
				new Station(17415003, 45.761, -0.652, 38, "Saintes", "Intérieur Terres"),
			],
			[85, 79, 16, 33],
		),
		new County(
			18,
			"Cher",
			GeographicZone.H2B,
			false,
			[new Station(18033001, 47.059, 2.359, 161, "Bourges", "")],
			[17, 79, 86, 87, 24],
		),
		new County(
			19,
			"Corrèze",
			GeographicZone.H1C,
			false,
			[new Station(19031008, 45.148, 1.474, 115, "Brive - La Roche", "")],
			[23, 87, 24, 46, 15, 63],
		),
		new County(
			20,
			"Corse",
			GeographicZone.H3,
			true,
			[
				new Station(20114002, 41.505, 9.103, 20, "Figari", "Littoral - Ref"),
				new Station(20268001, 41.943, 9.123, 837, "Sampolo", "Altitude"),
				new Station(20148001, 42.54, 9.485, 10, "Bastia", "Littoral - Ref"),
			],
			[13, 83, 6],
		),
		new County(
			21,
			"Côte-d'Or",
			GeographicZone.H1C,
			false,
			[new Station(21473001, 47.267, 5.088, 219, "Dijon - Longvic", "")],
			[10, 89, 58, 71, 39, 70, 52],
		),
		new County(
			22,
			"Côtes-d'Armor",
			GeographicZone.H2A,
			false,
			[new Station(22372001, 48.534, -2.852, 136, "Saint-Brieuc", "")],
			[29, 56, 35],
		),
		new County(
			23,
			"Creuse",
			GeographicZone.H1C,
			false,
			[new Station(23176001, 46.243, 1.452, 370, "La Souterraine", "")],
			[87, 19, 63, 3, 18, 36],
		),
		new County(
			24,
			"Dordogne",
			GeographicZone.H2C,
			false,
			[new Station(24138004, 45.16, 0.677, 213, "Coulounieix - Périgueux", "")],
			[39, 70, 90],
		),
		new County(
			25,
			"Doubs",
			GeographicZone.H1C,
			true,
			[
				new Station(25056001, 47.249, 5.988, 307, "Besançon", ""),
				new Station(25462001, 46.902, 6.341, 831, "Pontarlier", "Altitude"),
			],
			[39, 70, 90],
		),
		new County(
			26,
			"Drôme",
			GeographicZone.H2D,
			true,
			[
				new Station(26198001, 44.581, 4.733, 73, "Montélimar", ""),
				new Station(26168001, 44.673, 5.711, 1059, "Lus La Croix Haute", "Altitude"),
			],
			[84, 4, 5, 38, 69, 7],
		),
		new County(
			27,
			"Eure",
			GeographicZone.H1A,
			false,
			[new Station(27347001, 49.025, 1.221, 138, "Evreux - Huest", "")],
			[76, 60, 95, 78, 28, 61, 14],
		),
		new County(
			28,
			"Eure-et-Loir",
			GeographicZone.H1A,
			false,
			[new Station(28070001, 48.46, 1.501, 155, "Chartres", "")],
			[27, 78, 91, 45, 41, 72, 61],
		),
		new County(
			29,
			"Finistère",
			GeographicZone.H2A,
			false,
			[
				new Station(29075001, 48.444, -4.412, 94, "Brest - Guipavas ", "Littoral"),
				new Station(29216001, 47.973, -4.16, 82, "Quimper", "Référence (Terres)"),
			],
			[22, 56],
		),
		new County(
			30,
			"Gard",
			GeographicZone.H3,
			true,
			[
				new Station(30189001, 43.856, 4.406, 59, "Nîmes - Courbessac", ""),
				new Station(30003001, 43.537, 4.207, 1, "Aigues-Mortes", "Littoral"),
				new Station(30339001, 44.121, 3.582, 1567, "Val-d'Aigoual - Mont Aigoual", "Altitude"),
			],
			[34, 12, 48, 7, 26, 84, 13],
		),
		new County(
			31,
			"Haute-Garonne",
			GeographicZone.H2C,
			true,
			[
				new Station(31069001, 43.621, 1.378, 151, "Toulouse - Blagnac", ""),
				new Station(31042012, 42.802, 0.6, 618, "Bagnères-de-Luchon", "Altitude"),
			],
			[65, 32, 82, 81, 11, 9],
		),
		new County(
			32,
			"Gers",
			GeographicZone.H2C,
			false,
			[new Station(32013005, 43.689, 0.601, 122, "Auch", "")],
			[31, 65, 64, 40, 47, 82],
		),
		new County(
			33,
			"Gironde",
			GeographicZone.H2C,
			false,
			[
				new Station(33281001, 44.83, -0.691, 47, "Bordeaux - Mérignac", ""),
				new Station(33529001, 44.534, -1.132, 23, "La Teste-de-Buch - Cazaux", "Littoral"),
			],
			[17, 24, 47, 40],
		),
		new County(
			34,
			"Hérault",
			GeographicZone.H3,
			true,
			[
				new Station(34154001, 43.577, 3.963, 2, "Montpellier", ""),
				new Station(34205001, 43.786, 3.246, 846, "Les Plans", "Altitude"),
			],
			[11, 81, 12, 30],
		),
		new County(
			35,
			"Ille-et-Vilaine",
			GeographicZone.H2A,
			false,
			[
				new Station(35281001, 48.068, -1.734, 36, "Rennes - St Jacques", ""),
				new Station(35228001, 48.585, -2.076, 65, "Dinard", "Littoral"),
			],
			[50, 53, 49, 44, 56, 22],
		),
		new County(
			36,
			"Indre",
			GeographicZone.H2B,
			false,
			[new Station(36063001, 46.869, 1.741, 158, "Châteauroux - Déols", "")],
			[37, 41, 18, 23, 87, 86],
		),
		new County(
			37,
			"Indre-et-Loire",
			GeographicZone.H2B,
			false,
			[new Station(37179001, 47.444, 0.727, 384, "Tours", "")],
			[41, 36, 86, 49, 72],
		),
		new County(
			38,
			"Isère",
			GeographicZone.H1C,
			true,
			[
				new Station(38384001, 45.364, 5.313, 384, "Grenoble - Saint-Geoirs", ""),
				new Station(38548001, 45.078, 5.561, 1027, "Villard-de-Lans", "Altitude (8001200)"),
				new Station(38567002, 45.128, 5.878, 1730, "Chamrousse", "Altitude (>1200)"),
			],
			[5, 73, 1, 69, 7, 26, 5],
		),
		new County(
			39,
			"Jura",
			GeographicZone.H1C,
			true,
			[
				new Station(39362001, 46.413, 5.31, 298, "Lons-le-Saunier", ""),
				new Station(39413001, 46.303, 5.843, 1133, "La Pesse", "Altitude"),
			],
			[1, 71, 21, 70, 25],
		),
		new County(
			40,
			"Landes",
			GeographicZone.H2C,
			false,
			[
				new Station(40192001, 43.909, -0.5, 59, "Mont-de-Marsan", ""),
				new Station(40046001, 44.25, -1.14, 35, "Biscarrosse", "Littoral"),
			],
			[33, 47, 32, 64],
		),
		new County(
			41,
			"Loir-et-Cher",
			GeographicZone.H2B,
			false,
			[new Station(41097001, 47.319, 1.687, 83, "Romorantin", "")],
			[72, 37, 36, 18, 45, 28],
		),
		new County(
			42,
			"Loire",
			GeographicZone.H1C,
			true,
			[
				new Station(42005001, 45.533, 4.293, 400, "St Etienne - Bouthéon", ""),
				new Station(42039003, 45.699, 3.844, 990, "Chalmazel", "Altitude"),
			],
			[43, 69, 71, 3, 63],
		),
		new County(
			43,
			"Haute-Loire",
			GeographicZone.H1C,
			true,
			[
				new Station(43062001, 45.074, 3.764, 833, "Le Puy - Loudes", ""),
				new Station(43111002, 44.858, 3.844, 1148, "Landos-Charbon", "Altitude"),
			],
			[48, 7, 69, 42, 63, 15],
		),
		new County(
			44,
			"Loire-Atlantique",
			GeographicZone.H2B,
			false,
			[new Station(44020001, 47.15, -1.608, 83, "Nantes - Bouguenais", "")],
			[56, 35, 53, 49, 85],
		),
		new County(
			45,
			"Loiret",
			GeographicZone.H1B,
			false,
			[new Station(45055001, 47.99, 1.778, 123, "Orléans", "")],
			[91, 77, 28, 41, 18, 58, 89],
		),
		new County(
			46,
			"Lot",
			GeographicZone.H2C,
			false,
			[new Station(46127001, 44.745, 1.396, 260, "Gourdon", "")],
			[15, 19, 24, 47, 82, 12],
		),
		new County(
			47,
			"Lot-et-Garonne",
			GeographicZone.H2C,
			false,
			[new Station(47091001, 44.172, 0.594, 58, "Agen", "")],
			[33, 40, 32, 82, 46, 24],
		),
		new County(
			48,
			"Lozère",
			GeographicZone.H2D,
			true,
			[
				new Station(48095005, 44.534, 3.454, 932, "Mende - Chabrits", ""),
				new Station(48027003, 44.452, 3.74, 1418, "Mont Lozère et Goulet - Le Bleymard", "Altitude"),
			],
			[12, 30, 7, 43, 15],
		),
		new County(
			49,
			"Maine-et-Loire",
			GeographicZone.H2B,
			false,
			[new Station(49020001, 47.479, -0.614, 50, "Angers - Beaucouzé", "")],
			[44, 53, 72, 37, 86, 79, 85],
		),
		new County(
			50,
			"Manche",
			GeographicZone.H2A,
			false,
			[new Station(50209001, 49.65, -1.48, 135, "Cherbourg - Maupertus", "Littoral ")],
			[14, 61, 53, 35],
		),
		new County(
			51,
			"Marne",
			GeographicZone.H1B,
			false,
			[new Station(51595002, 48.777, 4.165, 179, "Bussy-Lettrée - aéroport ParisVatry", "")],
			[44, 53, 72, 37, 86, 79, 85],
		),
		new County(
			52,
			"Haute-Marne",
			GeographicZone.H1B,
			false,
			[
				new Station(52269001, 47.844, 5.337, 466, "Langres", ""),
				new Station(52448001, 48.631, 4.903, 139, "Saint-Dizier", ""),
			],
			[21, 70, 88, 55, 51, 10],
		),
		new County(
			53,
			"Mayenne",
			GeographicZone.H2B,
			false,
			[new Station(53110002, 47.891, -0.542, 93, "Grez-en-Brouère", "")],
			[49, 72, 61, 50, 35, 44],
		),
		new County(
			54,
			"Meurthe-et-Moselle",
			GeographicZone.H1B,
			false,
			[new Station(54526001, 48.687, 6.221, 212, "Nancy - Essey", "")],
			[57, 67, 88, 55],
		),
		new County(
			55,
			"Meuse",
			GeographicZone.H1B,
			false,
			[
				new Station(55386002, 48.934, 5.764, 230, "Nonsard", ""),
				new Station(55484001, 49.276, 5.155, 293, "Septsarges", ""),
			],
			[54, 88, 52, 51, 8],
		),
		new County(
			56,
			"Morbihan",
			GeographicZone.H2A,
			false,
			[
				new Station(56185001, 47.762, -3.435, 45, "Lorient - Lann Bihoue", ""),
				new Station(56243001, 47.605, -2.714, 3, "Vannes-Sene", ""),
			],
			[29, 22, 35, 44],
		),
		new County(
			57,
			"Moselle",
			GeographicZone.H1B,
			false,
			[new Station(57039001, 49.069, 6.125, 192, "Nancy - Essey", "")],
			[67, 88, 54],
		),
		new County(
			58,
			"Nièvre",
			GeographicZone.H1B,
			false,
			[new Station(58160001, 46.998, 3.112, 175, "Nevers - Marzy", "")],
			[3, 71, 21, 89, 45, 18],
		),
		new County(
			59,
			"Nord",
			GeographicZone.H1A,
			false,
			[
				new Station(59183001, 51.05, 2.34, 11, "Dunkerque", "Littoral"),
				new Station(59343001, 50.57, 3.097, 47, "Lille - Lesquin", "Intérieur"),
			],
			[2, 80, 62, 8],
		),
		new County(
			60,
			"Oise",
			GeographicZone.H1A,
			false,
			[new Station(60639001, 49.446, 2.127, 89, "Beauvais - Tillé", "")],
			[2, 80, 76, 27, 95, 77],
		),
		new County(
			61,
			"Orne",
			GeographicZone.H1A,
			false,
			[new Station(61001001, 50.73, 1.6, 143, "Alençon", "")],
			[14, 27, 28, 72, 53, 50],
		),
		new County(
			62,
			"Pas-de-Calais",
			GeographicZone.H1A,
			false,
			[
				new Station(62160001, 50.73, 1.6, 73, "Boulogne-sur-Mer", "Littoral"),
				new Station(62298001, 50.225, 3.163, 76, "Cambrai - Epinoy", "Intérieur"),
			],
			[80, 59, 2],
		),
		new County(
			63,
			"Puy-de-Dôme",
			GeographicZone.H1C,
			true,
			[
				new Station(63113001, 45.786, 3.149, 331, "Clermont-Ferrand", ""),
				new Station(63353003, 45.461, 3.533, 1070, "Saint-Germain-L’Herm", "Altitude"),
				new Station(63098001, 45.533, 2.775, 1385, "Chastreix", "Altitude"),
			],
			[15, 43, 42, 3, 23, 19],
		),
		new County(
			64,
			"Pyrénées-Atlantiques",
			GeographicZone.H2C,
			true,
			[
				new Station(64024001, 43.469, -1.534, 71, "Biarritz - Anglet", "Littoral"),
				new Station(64549001, 43.385, -0.416, 183, "Pau - Uzein", ""),
				new Station(64316003, 43.034, -1.034, 1427, "Larrau - Iraty", "Altitude"),
			],
			[15, 43, 42, 3, 23, 19],
		),
		new County(
			65,
			"Hautes-Pyrénées",
			GeographicZone.H2C,
			true,
			[
				new Station(65344001, 43.188, 0, 360, "Tarbes - Ossun", ""),
				new Station(65075001, 42.858, 0.349, 767, "Bazus-Aure", "Altitude  (800-1200)"),
				new Station(65283001, 42.797, 0.44, 1587, "Loudervielle", "Altitude (>1200)"),
			],
			[64, 32, 31],
		),
		new County(
			66,
			"Pyrénées-Orientales",
			GeographicZone.H3,
			false,
			[
				new Station(66136001, 42.737, 2.872, 42, "Perpignan", ""),
				new Station(66194002, 42.4, 2.558, 700, "Serralongue", "Intérieur"),
			],
			[9, 11],
		),
		new County(
			67,
			"Bas-Rhin",
			GeographicZone.H1B,
			false,
			[new Station(67124001, 48.549, 7.64, 150, "Strasbourg - Entzheim", "")],
			[68, 88, 54, 57],
		),
		new County(
			68,
			"Haut-Rhin",
			GeographicZone.H1B,
			true,
			[
				new Station(68205001, 47.928, 7.407, 207, "Colmar - Meyenheim", ""),
				new Station(68247003, 47.923, 7.032, 1184, "Markstein Crête", "Altitude"),
			],
			[90, 70, 88, 67],
		),
		new County(
			69,
			"Rhône",
			GeographicZone.H1C,
			false,
			[new Station(69029001, 45.726, 4.937, 197, "Lyon - Bron", "")],
			[1, 71, 42, 43, 7, 26, 38],
		),
		new County(
			70,
			"Haute-Saône",
			GeographicZone.H1B,
			false,
			[new Station(70473001, 47.787, 6.364, 272, "Luxeuil", "")],
			[21, 39, 25, 90, 88, 52],
		),
		new County(
			71,
			"Saône-et-Loire",
			GeographicZone.H1C,
			false,
			[new Station(71105001, 46.296, 4.798, 216, "Macon", "")],
			[3, 42, 69, 1, 39, 21, 58],
		),
		new County(
			72,
			"Sarthe",
			GeographicZone.H2B,
			false,
			[new Station(72181001, 47.94, 0.189, 48, "Le Mans", "")],
			[28, 61, 53, 49, 37, 41],
		),
		new County(
			73,
			"Savoie",
			GeographicZone.H1C,
			true,
			[
				new Station(73329001, 45.641, 5.878, 235, "Chambery - Aix-les-Bains ", "Vallée"),
				new Station(73054001, 45.612, 6.763, 865, "Bourg-Saint-Maurice", "Moyenne altitude"),
				new Station(73132003, 45.755, 6.535, 1614, "Hauteluce - Col des Saisies", "Altitude"),
			],
			[74, 1, 38, 5],
		),
		new County(
			74,
			"Haute-Savoie",
			GeographicZone.H1C,
			true,
			[
				new Station(74182001, 45.55, 6.05, 455, "Annecy - Meythet", "Vallée"),
				new Station(74119003, 46.382, 6.583, 725, "Evian", "Moyenne altitude"),
				new Station(74191003, 46.168, 6.693, 1515, "Le Plenay", "Altitude"),
			],
			[73, 1],
		),
		new County(
			75,
			"Paris",
			GeographicZone.H1A,
			false,
			[new Station(75114001, 48.821, 2.337, 75, "Paris - Montsouris", "")],
			[92, 93, 94],
		),
		new County(
			76,
			"Seine-Maritime",
			GeographicZone.H1A,
			false,
			[
				new Station(76481001, 49.533, 0.092, 94, "Le Havre - Octeville", "Littoral"),
				new Station(76116001, 49.383, 1.181, 151, "Rouen - Boos", ""),
			],
			[80, 60, 27],
		),
		new County(
			77,
			"Seine-et-Marne",
			GeographicZone.H1A,
			false,
			[new Station(77306001, 48.61, 2.679, 91, "Melun", "")],
			[89, 10, 51, 2, 60, 93, 91, 45],
		),
		new County(
			78,
			"Yvelines",
			GeographicZone.H1A,
			false,
			[new Station(78621001, 48.774, 2.01, 167, "Trappes", "")],
			[95, 27, 28, 91, 92],
		),
		new County(
			79,
			"Deux-Sèvres",
			GeographicZone.H2B,
			false,
			[new Station(79191005, 46.315, -0.4, 57, "Niort", "")],
			[17, 85, 49, 86, 16],
		),
		new County(
			80,
			"Somme",
			GeographicZone.H1A,
			false,
			[
				new Station(80001001, 50.136, 1.834, 69, "Abbeville", "Littoral"),
				new Station(80523001, 49.582, 2.421, 107, "Meaulte", ""),
			],
			[62, 59, 2, 60, 76],
		),
		new County(
			81,
			"Tarn",
			GeographicZone.H2C,
			true,
			[
				new Station(81284001, 43.914, 2.116, 172, "Albi", ""),
				new Station(81192005, 43.38, 2.493, 1022, "Murat-sur-Vèbre", "Altitude"),
			],
			[11, 34, 12, 82, 31],
		),
		new County(
			82,
			"Tarn-et-Garonne",
			GeographicZone.H2C,
			false,
			[new Station(82121002, 43.383, 1.376, 106, "Montauban", "")],
			[32, 31, 81, 12, 46, 47],
		),
		new County(
			83,
			"Var",
			GeographicZone.H3,
			true,
			[
				new Station(83031001, 43.383, 6.386, 80, "Le Luc", ""),
				new Station(83044003, 43.393, 6.281, 892, "Comps-sur-Artuby", "Altitude"),
				new Station(83137001, 43.103, 5.931, 23, "Toulon", "Littoral"),
			],
			[13, 84, 4, 6, 20],
		),
		new County(
			84,
			"Vaucluse",
			GeographicZone.H2D,
			true,
			[
				new Station(84031001, 44.05, 5.031, 99, "Carpentras", ""),
				new Station(84107002, 44.041, 5.493, 836, "Saint Christol", "Altitude"),
			],
			[13, 4, 26, 7, 30],
		),
		new County(
			85,
			"Vendée",
			GeographicZone.H2B,
			false,
			[
				new Station(85060002, 46.284, 1.433, 27, "Les Sables-d'Olonne - Château d'olonne", "Littoral"),
				new Station(85191003, 46.7, -1.381, 90, "La Roche-sur-Yon", ""),
			],
			[44, 49, 79, 17],
		),
		new County(
			86,
			"Vienne",
			GeographicZone.H2B,
			false,
			[new Station(86027001, 46.593, 0.314, 123, "Poitiers - Biard", "")],
			[36, 37, 49, 79, 16, 87],
		),
		new County(
			87,
			"Haute-Vienne",
			GeographicZone.H1C,
			false,
			[new Station(87085006, 45.861, 1.175, 402, "Limoges - Bellegarde", "")],
			[23, 19, 24, 16, 86],
		),
		new County(
			88,
			"Vosges",
			GeographicZone.H1B,
			false,
			[new Station(88136001, 48.21, 6.45, 317, "Epinal", "")],
			[70, 90, 68, 67, 57, 54, 55, 52],
		),
		new County(
			89,
			"Yonne",
			GeographicZone.H1B,
			false,
			[new Station(89387002, 48.168, 3.289, 317, "Sens", "")],
			[58, 21, 10, 77, 45],
		),
		new County(
			90,
			"Territoire de Belfort",
			GeographicZone.H1B,
			false,
			[new Station(90052002, 47.742, 6.835, 473, "Giromagny", "")],
			[25, 70, 88, 68],
		),
		new County(
			91,
			"Essonne",
			GeographicZone.H1A,
			false,
			[new Station(91027002, 48.716, 2.384, 86, "Orly - Athis-Mons", "")],
			[45, 28, 77, 78, 92, 94],
		),
		new County(
			92,
			"Hauts-de-Seine",
			GeographicZone.H1A,
			false,
			[new Station(75114001, 48.821, 2.337, 75, "Paris - Montsouris", "")],
			[75, 95, 78, 93],
		),
		new County(
			93,
			"Seine-Saint-Denis",
			GeographicZone.H1A,
			false,
			[new Station(95527001, 49.005, 2.32, 75, "Roissy", "")],
			[92, 95, 94, 77],
		),
		new County(
			94,
			"Val-de-Marne",
			GeographicZone.H1A,
			false,
			[new Station(91027002, 48.716, 2.384, 89, "Orly - Athis-Mons", "")],
			[77, 75, 92, 93, 91],
		),
		new County(
			95,
			"Val-d'Oise",
			GeographicZone.H1A,
			false,
			[new Station(95088001, 48.967, 2.427, 49, "Paris - Le Bourget", "")],
			[77, 75, 92, 93, 91],
		),
		new County(
			971,
			"Guadeloupe",
			GeographicZone.GUADELOUPE,
			true,
			[
				new Station(97101015, 16.27, -61.52, 11, "Les Abymes - Le Raizet", ""),
				new Station(97107002, 16.04, -61.57, 28, "Capesterre Belle-eau Neufchateau", ""),
				new Station(97117013, 16.33, -61.35, 6, "Le Moule", ""),
				new Station(97124006, 16.04, -61.7, 607, "Saint Claude Matouba", ""),
			],
			[],
		),
		new County(
			972,
			"Martinique",
			GeographicZone.MARTINIQUE,
			true,
			[
				new Station(97213004, 14.6, -61, 5, "Lamentin", ""),
				new Station(97208001, 14.735, -61.145, 493, "Fond-Denis-Cadet", "Altitude"),
				new Station(97224004, 14.659, -60.999, 65, "Saint-Joseph-Lézard", "Intérieur"),
			],
			[],
		),
		new County(
			973,
			"Guyane",
			GeographicZone.GUYANE,
			false,
			[
				new Station(97307001, 4.492, -52.215, 4, "Cayenne - Matoury", ""),
				new Station(97308001, 3.891, -51.805, 6, "Saint-Georges", ""),
				new Station(97353001, 3.382, -54.01, 106, "Maripasoula", ""),
			],
			[],
		),
		new County(
			974,
			"La Réunion",
			GeographicZone.REUNION,
			true,
			[
				new Station(97418110, -20.88, 55.52, 21, "Saint Denis - Gillot", ""),
				new Station(97410238, -21.05, 55.73, 43, "Saint-Benoît", ""),
				new Station(97414409, -21.2, 55.409, 980, "Plaine des Makes_SAPC", ""),
				new Station(97422440, -21.2, 55.58, 1570, "Le Tampon - Plaine des Caffres", ""),
			],
			[],
		),
		new County(
			976,
			"Mayotte",
			GeographicZone.MAYOTTE,
			false,
			[new Station(98508001, -12.8, 45.28, 8, "Pamandzi - Dzaoudzi", "")],
			[],
		),
	];
}

export class County {
	code: number;
	name: string;
	climaticZone: string;
	/**
	 * If true then the best station is the one with the closest altitude
	 * else the best station is the one with the closest position
	 */
	checkAltitude: boolean;
	stations: Station[];
	bordering: number[];

	constructor(
		code: number,
		name: string,
		climaticZone: string,
		checkAltitude: boolean,
		stations: Station[],
		bordering: number[],
	) {
		this.code = code;
		this.name = name;
		this.climaticZone = climaticZone;
		this.checkAltitude = checkAltitude;
		this.stations = stations;
		this.bordering = bordering;
	}

	getNearestStation(position: turf.Coord): Station {
		return this.stations.reduce((prev: Station, curr: Station) => {
			return turf.distance(position, [curr.lon, curr.lat]) < turf.distance(position, [prev.lon, prev.lat])
				? curr
				: prev;
		});
	}
}

export class Station {
	code: number;
	lat: number;
	lon: number;
	altitude: number;
	name: string;
	description: string;

	constructor(code: number, lat: number, lon: number, altitude: number, name: string, description: string) {
		this.code = code;
		this.lat = lat;
		this.lon = lon;
		this.altitude = altitude;
		this.name = name;
		this.description = description;
	}
}
