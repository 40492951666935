import { Pipe, PipeTransform } from "@angular/core";
import { EnergyType, energyTypeUnitDatabaseToDisplay } from "../models/energyType";

@Pipe({
	name: "energyTypeValueDisplay",
})
export class EnergyTypeValueDisplayPipe implements PipeTransform {
	transform = energyTypeValueDisplay;
}

export function energyTypeValueDisplay(value: number, energyType: EnergyType): number {
	return Number(energyTypeUnitDatabaseToDisplay(energyType, value).toFixed(2));
}
