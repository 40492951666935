export enum GeographicZone {
	H1A = "H1a",
	H1B = "H1b",
	H1C = "H1c",
	H2A = "H2a",
	H2B = "H2b",
	H2C = "H2c",
	H2D = "H2d",
	H3 = "H3",
	GUYANE = "Guyane",
	GUADELOUPE = "Guadeloupe",
	MARTINIQUE = "Martinique",
	MAYOTTE = "Mayotte",
	REUNION = "Réunion",
}
