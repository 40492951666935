import { Component, Input } from "@angular/core";
import { BaseComponent } from "../../../models/base-component.directive";

@Component({
	selector: "app-advancement-chip",
	templateUrl: "./advancement-chip.component.html",
	styleUrls: ["./advancement-chip.component.scss"],
})
export class AdvancementChipComponent extends BaseComponent {
	@Input() percent = 0;
}
