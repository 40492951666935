import { Immutable } from "immer";

// range(0, 3) gives [0, 1, 2]
export const range = (start: number, end: number) => Array.from({ length: end - start }, (_, k) => k + start);

export function maxByKey<T>(list: Immutable<T[]>, f: (value: Immutable<T>) => number): Immutable<T> | undefined {
	if (list.length === 0) {
		return undefined;
	}

	// eslint-disable-next-line prefer-const
	let [maxValue, ...rest] = list;

	let maxKey = f(maxValue);

	for (const value of rest) {
		const key = f(value);
		if (key > maxKey) {
			maxValue = value;
			maxKey = key;
		}
	}

	return maxValue;
}
