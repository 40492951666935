import { Component, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Modal } from "src/app/models/modal";

type Input = {
	maxValue: number;
	value$: Observable<number>;
};

type State = {
	maxValue: number;
	value$: Observable<number>;
};

@Component({
	selector: "app-standalone-progress-modal",
	templateUrl: "./progress-modal.component.html",
	styleUrls: ["./progress-modal.component.scss"],
})
export class StandaloneProgressModalComponent extends Modal<Input, State, undefined> implements OnDestroy {
	subscription: Subscription | undefined;

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	inputToState(input: Input): State {
		this.subscription = input.value$.subscribe((value) => {
			if (value === input.maxValue) {
				setTimeout(() => this.close(undefined), 1000);
			}
		});
		return { ...input };
	}
}
