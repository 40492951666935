import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ResourceId, UserId } from "src/app/models/ids";
import { ResourceType } from "src/app/models/resource";
import { User } from "src/app/models/user";
import { AuthenticationService } from "src/app/services/authentication.service";
import { z } from "zod";
import { Nullable } from "../../../helpers/nullable";

const zodMailValidation = z.string().email("Email invalide");

@Component({
	selector: "app-authorization-list-modal",
	templateUrl: "./authorization-list-modal.component.html",
	styleUrls: ["./authorization-list-modal.component.scss"],
})
export class AuthorizationListModalComponent {
	public users?: User[];
	public ownerId: UserId;
	public currentUserId?: UserId;
	public loading = false;
	public invitationSending = false;
	public invitationMessage?: string;
	public invitationError?: string;
	public forceTemporary = false;
	invitationModel: { email: string; name: string; isTemporary: boolean } = {
		email: "",
		name: "",
		isTemporary: false,
	};
	private resourceId: ResourceId;
	private resourceType: ResourceType;
	public parentResourceOwnerId: Nullable<UserId>;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			resourceId: ResourceId;
			resourceType: ResourceType;
			ownerId: UserId;
			forceTemporary: boolean;
			userId: UserId;
			parentResourceOwnerId?: UserId;
		},
		private authentication: AuthenticationService,
		public dialog: MatDialog,
	) {
		this.ownerId = data.ownerId;
		this.currentUserId = data.userId;
		this.resourceId = data.resourceId;
		this.resourceType = data.resourceType;
		this.forceTemporary = data.forceTemporary;
		authentication.getInvitations$(data.resourceId, data.resourceType).subscribe((users) => {
			this.users = users;
		});
		if (data.parentResourceOwnerId) {
			this.parentResourceOwnerId = data.parentResourceOwnerId;
		}
	}

	removeUser(user?: User) {
		if (!this.loading && user?.user_id) {
			this.loading = true;
			this.authentication.deleteInvitation$(user.user_id, this.resourceId, this.resourceType).subscribe(() => {
				this.loading = false;
				this.users = this.users?.filter((usr) => usr !== user);
			});
		}
	}

	resetValidation() {
		this.invitationMessage = undefined;
		this.invitationError = undefined;
	}

	invite() {
		this.resetValidation();
		const emailParse = zodMailValidation.safeParse(this.invitationModel.email);
		if (!emailParse.success) {
			this.invitationError = emailParse.error.format()._errors[0];
			return;
		}
		this.invitationSending = true;
		const isTemporary = this.forceTemporary ? true : this.invitationModel.isTemporary;
		this.authentication
			.inviteUserToResource$(
				this.invitationModel.name,
				this.invitationModel.email,
				isTemporary,
				this.resourceId,
				this.resourceType,
			)
			.subscribe({
				next: () => {
					this.invitationModel.name = "";
					this.invitationModel.email = "";
					this.invitationMessage = "Invitation envoyée";
					this.invitationSending = false;
				},
				error: () => {
					this.invitationModel.name = "";
					this.invitationModel.email = "";
					this.invitationError = "Une erreur est survenue lors de l'envoi de l'invitation.";
					this.invitationSending = false;
				},
			});
	}
}
