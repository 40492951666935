import { Component } from "@angular/core";
import { Modal } from "src/app/models/modal";
import { Immutable } from "immer";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { Lazy } from "src/app/models/lazy";
import { FunctionalDeclarationId } from "src/app/models/ids";
import { contains } from "src/app/helpers/string";
import { EfaStatus, getIdentifierStringFromEfaStatus } from "src/app/models/efaStatus";
import { AddressInfo } from "src/app/models/address";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Nullable } from "src/app/helpers/nullable";
import { RouteDealer } from "src/app/models/routes";

interface Item {
	id: FunctionalDeclarationId;
	address: Immutable<AddressInfo>;
	readonly not_in_csv: boolean;
	chosen: boolean;
	name: Nullable<string>;
	description: Nullable<string>;
	efa_status: Immutable<EfaStatus>;
}

type Input = {
	functionalEntities: Immutable<Lazy<FunctionalDeclaration>[]>;
	alreadyChosen: Immutable<Set<FunctionalDeclarationId>>; // empty means we haven't yet chosen so we must display everything
};
type State = {
	filter: BehaviorSubject<Set<FunctionalDeclarationId>>;
	filterCount: Observable<number>;
	items: Item[];
	trueCount: number;
	notInCsvCount: number;
};
type Output = Set<FunctionalDeclarationId> | undefined; // undefined means cancel

@Component({
	selector: "app-choice-entity-modal",
	templateUrl: "./choice-entity-modal.component.html",
	styleUrls: ["./choice-entity-modal.component.scss"],
})
export class ChoiceEntityModalComponent extends Modal<Input, State, Output> {
	readonly RouteDealer = RouteDealer;
	searchQuery = "";

	inputToState({ functionalEntities, alreadyChosen }: Input): State {
		const entities = functionalEntities
			.filter(({ is_token_used, is_imported_from_operat }) => is_token_used || is_imported_from_operat)
			.flatMap(({ not_in_csv, address, declaration_functional_id: id, name, description, efa_status }) =>
				id
					? [
							{
								not_in_csv: not_in_csv ?? false,
								address,
								name,
								id,
								chosen: alreadyChosen.size === 0 ? !(not_in_csv ?? false) : alreadyChosen.has(id),
								description,
								efa_status,
							},
						]
					: [],
			);

		const filter = new BehaviorSubject(new Set(entities.map(({ id }) => id)));

		return {
			items: entities,
			trueCount: entities.filter(({ chosen }) => chosen).length,
			filter,
			filterCount: filter.pipe(map((filter) => entities.filter(({ id }) => filter.has(id)).length)),
			notInCsvCount: entities.filter(({ not_in_csv }) => not_in_csv).length,
		};
	}

	updateTrueCount() {
		this.state.trueCount = this.state.items.filter(({ chosen }) => chosen).length;
	}

	setAll(checked: boolean) {
		this.state.items.filter(({ not_in_csv }) => !not_in_csv).forEach((item) => (item.chosen = checked));
		this.updateTrueCount();
	}

	close() {
		const notInCsvCount = this.state.items.filter(({ not_in_csv }) => not_in_csv).length;
		const inCsvIds = this.state.items.flatMap(({ chosen, id }) => (chosen ? [id] : []));
		super.close(this.state.items.length - notInCsvCount === inCsvIds.length ? new Set() : new Set(inCsvIds));
	}

	cancel() {
		super.close(undefined);
	}

	search(query: string) {
		this.state.filter.next(
			new Set(
				this.state.items.flatMap((item) => {
					if (
						contains(item.description, query) ||
						contains(item.name, query) ||
						contains(getIdentifierStringFromEfaStatus(item.efa_status), query) ||
						contains(AddressInfo.toString(item.address), query)
					) {
						return [item.id];
					}
					return [];
				}),
			),
		);
	}
}
