<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 *ngIf="state.adding" class="mt-0 mb-2">{{ state.adding ? "Ajouter une valeur" : "Modifier une valeur" }}</h3>
		<form [formGroup]="state.formGroup" class="d-flex-col">
			<ng-container *ngIf="toAddValues.length === 0">
				<div class="d-flex gap-1">
					<div class="w-100 custom-input-group" tourAnchor="consumption-add-start">
						<label>Date de début (incluse)</label>
						<input
							class="custom-input"
							#start
							required
							type="date"
							[min]="START | date: 'y-MM-dd'"
							[max]="TODAY | date: 'y-MM-dd'"
							formControlName="date_start"
						/>
						<span class="custom-input-error">{{ start.validationMessage }}</span>
					</div>
					<div class="w-100 custom-input-group" tourAnchor="consumption-add-end">
						<label>Date de fin (exclue)</label>
						<input
							class="custom-input"
							#end
							required
							type="date"
							[min]="state.formGroup.controls.date_start.value | followingDay"
							[max]="TODAY | date: 'y-MM-dd'"
							formControlName="date_end"
						/>
						<span class="custom-input-error">{{ end.validationMessage }}</span>
					</div>
				</div>
				<div>
					<div class="custom-input-group w-100" tourAnchor="consumption-add-value">
						<label>Valeur en {{ state.energy | energyTypeUnit }}</label>
						<div class="input-icon w-100">
							<input
								class="custom-input w-100 pr-5"
								required
								name="value"
								type="number"
								min="0"
								appInputRestriction="integer"
								formControlName="value"
							/>
							<span class="input-suffix">{{ state.energy | energyTypeUnit }}</span>
						</div>
					</div>
				</div>
				<div>
					<div class="w-100 custom-input-group" tourAnchor="consumption-add-commentary">
						<label>Commentaire</label>
						<input class="custom-input" name="commentary" type="text" formControlName="commentary" />
					</div>
				</div>
				<div>
					<mat-checkbox color="primary" formControlName="hasRepartitionKey">
						Les consommations sont réparties aux tantièmes
						<app-help [subject]="HelpSubject.Tantieme"></app-help>
					</mat-checkbox>
					<div *ngIf="state.formGroup.controls.hasRepartitionKey.value">
						<div class="d-flex gap-2">
							<div>
								<label>Tantièmes</label>
								<div class="d-flex gap-1">
									<div class="custom-input-group w-0" style="flex: 1 1 0">
										<input
											type="number"
											class="custom-input"
											[min]="0"
											[max]="state.formGroup.controls.totalUmpteenth.value"
											required
											formControlName="partUmpteenth"
										/>
									</div>
									<div style="font-size: 30px">/</div>
									<div class="custom-input-group w-0" style="flex: 1 1 0">
										<input
											type="number"
											class="custom-input"
											[min]="state.formGroup.controls.partUmpteenth.value"
											required
											formControlName="totalUmpteenth"
										/>
									</div>
								</div>
							</div>
							<div class="custom-input-group w-33">
								<label>Valeur corrigée</label>
								<input required class="custom-input" [disabled]="true" [value]="state.correctedValue$.value" />
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</form>
	</div>
	<div class="dialog-actions gap-1">
		<button
			class="btn-stroked-text-gray mr-auto"
			(click)="close(state.adding ? undefined : state.initialConsumptionEntry)"
		>
			Fermer
		</button>
		<button *ngIf="!state.adding" type="submit" class="btn-stroked-danger" (click)="delete()">
			Supprimer cette valeur
		</button>
		<button class="btn-stroked-primary-light" *ngIf="state.adding" (click)="openImportCSVModal()">
			Importer un fichier CSV
		</button>
		<button
			*ngIf="state.timestamps$ | async as timestamps"
			[disabled]="!((state.isFormValid$ | async) === true)"
			type="submit"
			class="btn-stroked-primary"
			[matTooltip]="
				timestamps.date_start > timestamps.date_end
					? 'La date de début ne peut pas être supérieure à la date de fin.'
					: ''
			"
			matTooltipPosition="above"
			(click)="this.returnConsumptionEntry()"
			tourAnchor="consumption-add-save"
		>
			{{ state.adding ? "Ajouter" : "Modifier" }}
		</button>
	</div>
</div>
