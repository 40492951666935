<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">Adresse</h3>
		<app-custom-form #form>
			<div class="d-flex-col gap-2">
				<div class="custom-input-group">
					<label>Numéro de voie</label>
					<input
						[(ngModel)]="state.address.houseNumber"
						appInputRestriction="alphanumeric"
						class="custom-input"
						placeholder="Numéro de voie"
					/>
				</div>

				<div>
					<label required>Type de voie</label>
					<ng-select [(ngModel)]="state.address.streetType" required>
						<ng-option *ngFor="let name of enumKeys" [value]="name | asStreetType" appInputRestriction="alphanumeric">
							{{ name | asStreetType }}
						</ng-option>
					</ng-select>
				</div>

				<div class="custom-input-group">
					<label required>Libellé voie</label>
					<input
						[(ngModel)]="state.address.streetName"
						appInputRestriction="alphanumeric"
						class="custom-input"
						placeholder="Libellé voie"
						required
					/>
				</div>

				<div class="custom-input-group">
					<label>Complément d'adresse</label>
					<input
						[(ngModel)]="state.address.details"
						appInputRestriction="alphanumeric"
						class="custom-input"
						placeholder="Complément d'adresse"
					/>
				</div>

				<div *ngIf="!state.selectedCity" class="custom-input-group">
					<label>Ville</label>
					<div class="input-icon w-100">
						<input
							#addressInput
							(input)="addressInput.value.length > 3 ? state.searchSubject.next(addressInput.value) : ''"
							[matAutocomplete]="customComplete"
							class="custom-input-light w-100"
							placeholder="Ville"
							type="search"
						/>
						<span class="input-suffix">
							<mat-icon>search</mat-icon>
						</span>
					</div>

					<mat-autocomplete #customComplete (optionSelected)="state.selectedCity = $event.option.value">
						<mat-option *ngFor="let option of state.cityStream$ | async" [value]="option">
							{{ option.label }} {{ option.postcode }}
						</mat-option>
					</mat-autocomplete>
				</div>

				<button
					(click)="state.selectedCity = undefined"
					*ngIf="state.selectedCity as city"
					class="btn-stroked-primary w-100 d-flex gap-1"
					style="align-items: center; justify-content: center"
				>
					<mat-icon>replay</mat-icon>
					{{ city.label }} {{ city.postcode }}
				</button>
			</div>
		</app-custom-form>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [mat-dialog-close]="undefined">Annuler</button>
		<button (click)="save()" [disabled]="!form.formValid || !state.selectedCity" class="btn-stroked-primary">
			Sauvegarder
		</button>
	</div>
</div>
