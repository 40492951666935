import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "objectKeys",
})
export class ObjectKeysPipe implements PipeTransform {
	transform(object: object): string[] {
		return object === undefined ? [] : Object.keys(object);
	}
}
