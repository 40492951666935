import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { ConsumptionEntry } from "../models/functionalDeclaration";

@Pipe({
	name: "displayableConsumptionEntry",
})
export class DisplayableConsumptionEntryPipe implements PipeTransform {
	transform(consumptionEntry: Immutable<ConsumptionEntry>, start: number, end: number): ConsumptionEntry | undefined {
		const consumptionStart = consumptionEntry.date_start;
		const consumptionEnd = consumptionEntry.date_end;

		// Convert timestamp to date and set time to 0, to prevent date approximation
		const dateStart = new Date(consumptionStart * 1000);
		dateStart.setHours(0, 0, 0);
		const dateEnd = new Date(consumptionEnd * 1000);
		dateEnd.setHours(0, 0, 0);

		const datePeriodStart = new Date(start * 1000);
		datePeriodStart.setHours(0, 0, 0);
		const datePeriodEnd = new Date(end * 1000);
		datePeriodEnd.setHours(0, 0, 0);

		// if the timestamp and the period are disjoint
		if (dateEnd <= datePeriodStart || dateStart >= datePeriodEnd) {
			return undefined;
		}

		const range = consumptionEnd - consumptionStart;

		let toIgnore = 0;

		if (consumptionStart < start) {
			toIgnore += start - consumptionStart;
		}
		if (end < consumptionEnd) {
			toIgnore += consumptionEnd - end;
		}
		return new ConsumptionEntry({
			...consumptionEntry,
			date_start: Math.max(consumptionStart, start),
			date_end: Math.min(consumptionEnd, end),
			value: range === 0 ? 0 : (consumptionEntry.value * (range - toIgnore)) / range,
		});
	}
}
