import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../models/ids";
import { Lazy } from "../models/lazy";

@Pipe({
	name: "getFunctionalDeclarationAroundCurrent",
})
export class GetFunctionalDeclarationAroundCurrentPipe implements PipeTransform {
	transform(
		declaration: Immutable<Lazy<Declaration>>,
		currentDeclarationFunctionalId: FunctionalDeclarationId | undefined | null,
		toShow: number = 7,
	): Immutable<Lazy<FunctionalDeclaration>>[] {
		const indexStart = Math.max(
			Math.min(
				Math.max(
					0,
					currentDeclarationFunctionalId
						? (declaration.declarations_functional?.findIndex(
								(fd) => fd.declaration_functional_id === currentDeclarationFunctionalId,
							) ?? -1) - Math.floor(toShow / 2)
						: -1,
				),
				(declaration.declarations_functional?.length ?? toShow) - toShow,
			),
			0,
		);

		return declaration.declarations_functional.slice(indexStart, indexStart + toShow);
	}
}
