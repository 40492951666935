<div>
	<img src="#" class="d-none" #previewImageComponent />
	<label class="custom-image-input" for="image-input-{{ id }}">
		<div class="custom-image-input-label">{{ label }}</div>
		<div class="custom-image-input-result">{{ result ?? placeholder }}</div>
		<input
			id="image-input-{{ id }}"
			#imageInput
			name="{{ name }}[]"
			(change)="previewImage(imageInput)"
			type="file"
			[accept]="accept"
		/>
	</label>
</div>
