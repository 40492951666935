import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as Papa from "papaparse";
import { EnergyType, energyTypeUnitToDatabaseStorage } from "src/app/models/energyType";
import { HelpSubject } from "../../../../../../help/help.component";
import { CsvDeliveryHelpModalComponent } from "../csv-delivery-help-modal/csv-delivery-help-modal.component";

@Component({
	selector: "app-import-csv-modal",
	templateUrl: "./import-delivery-csv-modal.component.html",
	styleUrls: ["./import-delivery-csv-modal.component.scss"],
})
export class ImportDeliveryCsvModalComponent {
	toAddValues: { value: number; date: number }[] = [];
	energy: EnergyType;
	HelpSubject = HelpSubject;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			invoiceTable: { value: number; date: number }[];
			energy: EnergyType;
		},
		private dialogRef: MatDialogRef<ImportDeliveryCsvModalComponent>,
		public dialog: MatDialog,
	) {
		this.energy = data.energy;
	}

	private static excelDateToDate(date: string): Date {
		if (date.match("^[0-9]+/[0-9]+/[0-9]+$")) {
			const dateExp = date.split("/");
			if (dateExp[2].length === 2) {
				dateExp[2] = `20${dateExp[2]}`;
			}
			date = `${dateExp[2]}-${dateExp[1]}-${dateExp[0]}`;
		}
		return new Date(date);
	}

	downloadFile(): void {
		const link = document.createElement("a");
		link.download = "declaration_consommation_livraison.csv";
		link.href = "assets/data/declaration_consommation_livraison.csv";
		link.click();
	}

	public parseCSV(files: FileList | null): void {
		if (files) {
			Papa.parse(files[0], {
				header: true,
				skipEmptyLines: true,
				complete: (result) => {
					for (const value of result.data) {
						const csvEntry = <CSVDeliveryEntry>value;
						if (this.verifyFormat(csvEntry)) {
							const value = Number(csvEntry.value.replace(",", ".").replace(" ", ""));
							this.toAddValues.push({
								date: ImportDeliveryCsvModalComponent.excelDateToDate(csvEntry.date).getTime() / 1000,
								value: energyTypeUnitToDatabaseStorage(this.energy, value),
							});
						}
					}
				},
			});
		}
	}

	verifyFormat(entry: { value: string; date: string }): boolean {
		return !(entry.date === "" || entry.value === "");
	}

	saveCsvImport(): void {
		this.dialogRef.close(this.toAddValues);
	}

	public openImportTutorial(): void {
		this.dialog.open(CsvDeliveryHelpModalComponent, { data: this.downloadFile });
	}
}

interface CSVDeliveryEntry {
	date: string;
	value: string;
}
