import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { DeclarationGroup } from "../models/declarationGroup";
import { DeclarationGroupId } from "../models/ids";
import { Lazy } from "../models/lazy";

@Injectable({
	providedIn: "root",
})
export class DeclarationGroupService {
	constructor(private http: HttpClient) {}

	create$(declarationGroup: Immutable<Lazy<DeclarationGroup.Api>>): Observable<Lazy<DeclarationGroup.Api>> {
		return this.http.post<Lazy<DeclarationGroup.Api>>(`${environment.baseApiUrl}/declaration_group`, declarationGroup);
	}

	search$(id: DeclarationGroupId, search: string): Observable<DeclarationGroup.Api> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append("search", search);
		return this.http.get<DeclarationGroup.Api>(`${environment.baseApiUrl}/declaration_group/${id}/search`, {
			params: httpParams,
		});
	}

	get$(id: DeclarationGroupId): Observable<Lazy<DeclarationGroup.Api>> {
		return this.http.get<Lazy<DeclarationGroup.Api>>(`${environment.baseApiUrl}/declaration_group/${id}`);
	}

	getNested$(id: DeclarationGroupId): Observable<DeclarationGroup.Api> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append("nested", true);
		return this.http.get<DeclarationGroup.Api>(`${environment.baseApiUrl}/declaration_group/${id}`, {
			params: httpParams,
		});
	}

	save$(declarationGroup: Immutable<Lazy<DeclarationGroup.Api>>): Observable<Lazy<DeclarationGroup.Api>> {
		return this.http.put<Lazy<DeclarationGroup.Api>>(
			`${environment.baseApiUrl}/declaration_group/${declarationGroup.declaration_group_id}`,
			declarationGroup,
		);
	}

	delete$(id: DeclarationGroupId): Observable<void> {
		return this.http.delete<void>(`${environment.baseApiUrl}/declaration_group/${id}`);
	}
}
