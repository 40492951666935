import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { produce, Immutable } from "immer";
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subject, zip } from "rxjs";
import { debounceTime, distinctUntilKeyChanged, map, switchMap, tap } from "rxjs/operators";
import { fullSirenToDeclaration$, rnaToDeclaration$, sirenToDeclaration$ } from "src/app/helpers/declaration-from-code";
import { contains } from "src/app/helpers/string";
import { unwrap } from "src/app/helpers/unwrap";
import { AddressInfo } from "src/app/models/address";
import { BaseComponent } from "src/app/models/base-component.directive";
import { Declaration } from "src/app/models/declaration";
import { DeclarationGroup } from "src/app/models/declarationGroup";
import { getIdentifierStringFromEfaStatus } from "src/app/models/efaStatus";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { Rna, Siren, Siret, wrapCode } from "src/app/models/identifier";
import { DeclarationGroupId, RepresentativeId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { Representative, RepresentativeCounts } from "src/app/models/representative";
import { ResourceInfo, ResourceType } from "src/app/models/resource";
import { RouteDealer } from "src/app/models/routes";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DeclarationFunctionalService } from "src/app/services/declaration-functional.service";
import { DeclarationGroupStateService } from "src/app/services/declaration-group-state.service";
import { DeclarationGroupService } from "src/app/services/declaration-group.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { DeclarationService } from "src/app/services/declaration.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { IsolationStateService } from "src/app/services/isolation-state.service";
import { RepresentativeStateService } from "src/app/services/representative-state.service";
import { RepresentativeService } from "src/app/services/representative.service";
import { RnaService } from "src/app/services/rna.service";
import { SirenService } from "src/app/services/siren.service";
import { TourStateService } from "src/app/services/tour-state.service";
import { UserStateService } from "src/app/services/user-state.service";
import { UserService } from "src/app/services/user.service";
import { VersionService } from "src/app/services/version.service";
import { environment } from "../../../../environments/environment";
import { Nullable } from "../../../helpers/nullable";
import { AuthorizationListModalComponent } from "../authorization-list-modal/authorization-list-modal.component";
import { FirstConnectionModalComponent } from "../first-connection-modal/first-connection-modal.component";
import { NewVersionModalComponent } from "../new-version-modal/new-version-modal.component";
import { DeclarationFunctionalListDialogComponent } from "../stepper/declaration-functional-list-dialog/declaration-functional-list-dialog.component";
import { FUNCTIONAL_PAGES, Page, PROGRESS_PAGES } from "../stepper/stepper.component";
import { IMdStepOption } from "ngx-ui-tour-md-menu/lib/step-option.interface";

type GroupView = Omit<Immutable<DeclarationGroup>, GreenKeys.KEY_DECLARATIONS> & {
	[GreenKeys.KEY_DECLARATIONS]: DeclarationView[];
};
type DeclarationView = Omit<Immutable<Declaration>, GreenKeys.KEY_DECLARATIONS_FUNCTIONAL> & {
	[GreenKeys.KEY_DECLARATIONS_FUNCTIONAL]: Immutable<FunctionalDeclaration>[];
};

@Component({
	selector: "app-my-declarations",
	templateUrl: "./my-declarations.component.html",
	styleUrls: ["./my-declarations.component.scss"],
})
export class MyDeclarationsComponent extends BaseComponent implements OnDestroy, AfterViewInit {
	readonly MASTER_ID = environment.masterId;
	readonly MIN_CHARS_FOR_SEARCH = 3;

	representativeId: RepresentativeId | undefined;
	representativeAndCounts$: ReplaySubject<
		Nullable<Immutable<{ representative: Lazy<Representative>; counts: RepresentativeCounts }>>
	>;

	groups: Immutable<DeclarationGroup>[] = [];
	archivedGroups: Immutable<DeclarationGroup>[] = [];
	groupIds$: BehaviorSubject<DeclarationGroupId[]> = new BehaviorSubject(new Array<DeclarationGroupId>());
	initialIds: DeclarationGroupId[] = [];
	searchSubject = new Subject<string>();

	developAll = false;

	// only useful when we are isolated on a declaration
	isolationDeclaration$: Observable<Declaration>;
	search: string = "";

	PROGRESS_PAGES = PROGRESS_PAGES;
	ResourceType = ResourceType;

	loading = false;
	loadingSearch = false;

	onPage = true;

	constructor(
		public authentication: AuthenticationService,
		private dialog: MatDialog,
		public representativeService: RepresentativeService,
		private router: Router,
		public representativeState: RepresentativeStateService,
		public groupState: DeclarationGroupStateService,
		public userState: UserStateService,
		private groupService: DeclarationGroupService,
		private declarationService: DeclarationService,
		private declarationState: DeclarationStateService,
		private sirenService: SirenService,
		private rnaService: RnaService,
		public isolation: IsolationStateService,
		functionalDeclarationState: FunctionalDeclarationStateService,
		private tourState: TourStateService,
		private userService: UserService,
		versionService: VersionService,
		functionalEntityService: DeclarationFunctionalService,
	) {
		super();

		this.sub(
			this.tourState.service.stepShow$.pipe(map(({ step }: { step: IMdStepOption }) => step)),
			(event: IMdStepOption) => {
				if (event.anchorId === "loading") {
					functionalEntityService.saving$.next(true);
				}
			},
		);
		this.sub(this.tourState.service.stepHide$, (event) => {
			if (event.step.anchorId === "loading") {
				functionalEntityService.saving$.next(false);
			}
		});
		if (this.userState.user && !this.userState.user.last_connection) {
			const user = this.userState.user;
			user.last_connection = Math.floor(Date.now() / 1000);
			this.userService.put$(user);
			this.userState.user = user;

			this.startTour();
		} else if (this.userState.user) {
			versionService.getChangelogs$(this.userState.user.last_connection).subscribe((changelogs) => {
				if (changelogs.length !== 0) {
					this.dialog.open(NewVersionModalComponent, {
						data: changelogs[0],
						panelClass: "first-connection-dialog",
					});
				}
			});
			const user = this.userState.user;
			user.last_connection = Math.floor(Date.now() / 1000);
			this.userService.put$(user);
			this.userState.user = user;
		}

		functionalDeclarationState.clear();
		this.representativeAndCounts$ = new ReplaySubject(1);
		this.sub(
			representativeState.get$.pipe(
				map((reprensentative) => reprensentative?.value),
				switchMap((representative) =>
					representative
						? zip(this.representativeService.getCounts$(representative.representative_id), of(representative))
						: of(null),
				),
				map((tuple) => {
					if (tuple) {
						return { counts: tuple[0], representative: tuple[1] };
					}
					return null;
				}),
			),
			this.representativeAndCounts$,
		);
		const representativeAndCounts$ = this.representativeAndCounts$;
		representativeAndCounts$
			.pipe(
				switchMap(
					// Maybe change how this works
					(representativeAndCounts) => {
						this.representativeId = representativeAndCounts?.representative.representative_id;
						if (representativeAndCounts) {
							return this.representativeService.search$(
								representativeAndCounts.representative.representative_id,
								this.search,
							);
						}
						// returns the group as an array
						return this.groupState.get$.pipe(map((group) => (group ? [unwrap(group.value.declaration_group_id)] : [])));
					},
				),
			)
			.subscribe((groupIds) => {
				this.groupIds$.next([...groupIds]);
				this.initialIds = [...groupIds];
				this.initGroups();
			});

		this.isolationDeclaration$ = declarationState.get$.pipe(
			map((declaration) => declaration?.value.declaration_id),
			switchMap((id) => (id ? declarationService.getNested$(id) : EMPTY)),
			map(Declaration.fromApi),
		);

		this.sub(this.searchSubject.pipe(debounceTime(400)), (value) => {
			if (this.representativeId) {
				const search = value.toLowerCase();
				if (search.length >= this.MIN_CHARS_FOR_SEARCH) {
					this.loadingSearch = true;
					this.representativeService.search$(this.representativeId, search).subscribe((groupIds) => {
						this.groups = [];
						this.archivedGroups = [];
						this.groupIds$.next(groupIds);
						this.initGroups();
						this.loadingSearch = false;
					});
				} else {
					if (this.groupIds$.value.length !== this.initialIds.length) {
						this.groups = [];
						this.archivedGroups = [];
						this.groupIds$.next([...this.initialIds]);
						this.initGroups();
					}
				}
			}
		});

		// clear selected group and declaration if it's not an isolated resource
		if (this.isolation.isolationInfo?.type === ResourceType.Representative) {
			this.groupState.clear(false);
		}
		if (
			this.isolation.isolationInfo?.type === ResourceType.Representative ||
			this.isolation.isolationInfo?.type === ResourceType.DeclarationGroup
		) {
			this.declarationState.clear();
		}

		// Reset search when representative entity change
		this.sub(
			this.representativeState.get$.pipe(
				switchMap((representative) => (representative ? of(representative.value) : EMPTY)),
				distinctUntilKeyChanged(GreenKeys.KEY_REPRESENTATIVE_ID),
			),
			() => {
				this.search = "";
				this.searchSubject.next("");
			},
		);
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.loadGroup();
		const element = document.getElementsByClassName("content")[0];
		element.addEventListener("scroll", () => {
			this.checkHeight();
		});
	}

	checkHeight() {
		if (this.onPage) {
			const element = document.getElementsByClassName("content")[0];
			const scrolled = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
			if ((scrolled > 80 || element.scrollHeight === element.clientHeight) && !this.loading) {
				this.loadGroup();
			}
		}
	}

	loadGroup() {
		this.groupIds$
			.pipe(
				debounceTime(100),
				switchMap((groupIds) => {
					return groupIds.length > this.groups.length + this.archivedGroups.length && !this.loading
						? of(groupIds)
						: EMPTY;
				}),
				tap(() => (this.loading = true)),
				switchMap((groupIds) => {
					const search = this.search.toLowerCase();
					return this.groupService
						.search$(
							groupIds[this.groups.length + this.archivedGroups.length],
							search.length >= this.MIN_CHARS_FOR_SEARCH ? search : "",
						)
						.pipe(map(DeclarationGroup.fromApi));
				}),
			)
			.subscribe((group) => {
				if (group[GreenKeys.KEY_IS_ARCHIVED]) {
					this.archivedGroups.push(group);
				} else {
					this.groups.push(group);
				}
				this.loading = false;
				this.checkHeight();
			});
	}

	startTour() {
		this.dialog
			.open(FirstConnectionModalComponent, { panelClass: "first-connection-dialog" })
			.afterClosed()
			.subscribe((returnValue) => {
				if (returnValue) {
					this.tourState.start([
						{
							anchorId: "navigation.add_tokens",
							content:
								"Afin de pouvoir créer vos déclarations, vous devez tout d'abord ajouter des crédits de création de déclaration à votre compte.",
							title: "Ajoutez des crédits",
						},
						{
							anchorId: "navigation.new_declaration",
							content:
								"Vous pourrez ensuite créer votre déclaration en renseignant son nom, ainsi que les siren/siret/rna des établissements concernés.",
							title: "Créer une déclaration",
							placement: {
								yPosition: "above",
								horizontal: true,
							},
						},
						{
							anchorId: "navigation.dashboard",
							content: "Vous trouverez l'ensemble de vos déclarations sur votre tableau de bord.",
							title: "Tableau de bord",
						},
						{
							anchorId: "loading",
							content:
								"Lorsque vous voyez cette icône, cela signifie qu'une sauvegarde est en cours, attendez la disparition de l'icône pour s'assurer que la sauvegarde à bien été effectué.",
							title: "Sauvegarde",
							placement: {
								xPosition: "before",
								yPosition: "below",
							},
							backdropConfig: {
								offset: 0,
							},
						},
						{
							anchorId: "my_declarations.share",
							content:
								"Afin de pouvoir collaborer, vous pouvez inviter d'autres utilisateurs en cliquant sur cette icône.",
							title: "Inviter d'autres utilisateurs",
						},
						{
							anchorId: "my_declarations.guide",
							content:
								"Vous pouvez relancer les guides interactifs à tout moment en appuyant sur les boutons avec ces icônes. Des info-bulles avec des points d'interrogations sont disposés dans le site afin d'offrir des aides supplémentaires.",
							title: "Guide interactif",
						},
						{
							anchorId: "navigation.faq",
							content: "Un lien vers la FAQ est présent ici à tout moment si vous avez des questions.",
							title: "Foire aux questions",
						},
					]);
				}
			});
	}

	navigateToRepresentativeSettings(id: RepresentativeId) {
		return this.router.navigate(RouteDealer.representativeSettings(id));
	}

	initGroups() {
		this.groups = [];
		this.archivedGroups = [];
		this.loadGroup();
	}

	trackByFn(index: number, item: GroupView) {
		return unwrap(item[GreenKeys.KEY_DECLARATION_GROUP_ID]);
	}

	searchInput() {
		this.searchSubject.next(this.search);
	}

	ngOnDestroy(): void {
		this.onPage = false;
		this.dialog.closeAll();
	}

	openRepresentativeAuthorizationListDialog(representative: Immutable<Lazy<Representative>>, userId: string): void {
		if (representative.representative_id && representative.owner_id) {
			this.dialog.open(AuthorizationListModalComponent, {
				data: {
					resourceId: representative.representative_id,
					resourceType: ResourceType.Representative,
					ownerId: representative.owner_id,
					userId,
				},
				panelClass: "p-0",
			});
		}
	}

	functionalEntitiesCount(declaration: Declaration): number {
		return declaration.declarations_functional.length;
	}

	stepAction(declaration: Immutable<Declaration>, page: Page) {
		if (FUNCTIONAL_PAGES.includes(page)) {
			if (declaration.declarations_functional.length === 1) {
				this.router.navigate([
					declaration.declarations_functional[0].declaration_functional_id,
					page.goToSegmentFunctional,
				]);
			} else if (declaration.declarations_functional.length > 1) {
				this.dialog.open(DeclarationFunctionalListDialogComponent, { data: { declaration } });
			}
		} else {
			this.router.navigate([declaration.declaration_id, page.goToSegment]);
		}
	}

	updateGroup(group: Immutable<DeclarationGroup>, isolationInfo: ResourceInfo) {
		this.groupService
			.save$(DeclarationGroup.toApi(group))
			.pipe(
				map(DeclarationGroup.fromLazyApi),
				switchMap((savedGroup) =>
					isolationInfo.type === ResourceType.Representative
						? this.representativeState.updateOwned$(savedGroup)
						: this.groupState.update$(savedGroup),
				),
			)
			.subscribe();
	}

	deleteGroup(groupId: DeclarationGroupId, representative: Immutable<Lazy<Representative>>) {
		const newRepresentative = produce(representative, (draft) => {
			draft.declaration_groups = draft.declaration_groups.filter((group) => group.declaration_group_id !== groupId);
		});

		this.groupService
			.delete$(groupId)
			.pipe(switchMap(() => this.representativeState.update$(newRepresentative)))
			.subscribe();
	}

	/**
	 * Creates a new declaration from a code. Not available at the declaration isolation level and
	 * below.
	 * @param group It can be a GroupView
	 * @param code SIREN/SIRET/RNA
	 * @param isolationInfo
	 */
	newDeclaration(group: Immutable<Lazy<DeclarationGroup>>, code: string, isolationInfo: ResourceInfo) {
		let observable: Observable<Declaration> = EMPTY;

		switch (code.length) {
			case Siren.LENGTH:
				observable = fullSirenToDeclaration$(this.sirenService, wrapCode(code), false, false);
				break;
			case Rna.LENGTH:
				observable = rnaToDeclaration$(this.rnaService, wrapCode(code), false);
				break;
			case Siret.LENGTH: {
				const siret: Siret = wrapCode(code);
				const sirets = new Set<Siret>();
				sirets.add(siret);
				observable = sirenToDeclaration$(this.sirenService, Siret.toSiren(siret), sirets, false, false);
			}
		}

		observable
			.pipe(
				switchMap((freshDeclaration) => {
					freshDeclaration.declaration_group_id = group.declaration_group_id;
					return this.declarationService.create$(Declaration.toApi(freshDeclaration));
				}),
				map(Declaration.fromLazyApi),
				map((freshDeclaration) =>
					produce(group, (draft) => {
						draft.declarations.push(freshDeclaration);
					}),
				),
				switchMap((updatedGroup) =>
					isolationInfo.type === ResourceType.DeclarationGroup
						? this.groupState.update$(updatedGroup)
						: this.representativeState.updateOwned$(updatedGroup),
				),
			)
			.subscribe();
	}

	private mapGroupToView(groups: Immutable<DeclarationGroup[]>): GroupView[] {
		return groups.map((group) => ({
			...group,
			[GreenKeys.KEY_DECLARATIONS]: group.declarations.map((declaration) => ({
				...declaration,
				[GreenKeys.KEY_DECLARATIONS_FUNCTIONAL]: [...declaration.declarations_functional],
			})),
		}));
	}

	private searchFunction(group: GroupView, searchString: string, archive: boolean) {
		if (group[GreenKeys.KEY_IS_ARCHIVED] !== archive) {
			return false;
		}
		if (
			contains(group[GreenKeys.KEY_NAME], searchString)
			// || contains(group[GreenKeys.KEY_SIREN], searchString)
		) {
			return true;
		}
		group[GreenKeys.KEY_DECLARATIONS] = group[GreenKeys.KEY_DECLARATIONS].filter((declaration) => {
			const { structure } = declaration;

			if (
				contains(declaration.name, searchString) ||
				("siren" in structure
					? contains(structure.siren, searchString) || contains(structure.maybeRna, searchString)
					: "rna" in structure
						? contains(structure.rna, searchString)
						: contains(structure.id, searchString))
			) {
				return true;
			}
			declaration[GreenKeys.KEY_DECLARATIONS_FUNCTIONAL] = declaration[GreenKeys.KEY_DECLARATIONS_FUNCTIONAL].filter(
				({ name, address, efa_status }) => {
					return (
						contains(name, searchString) ||
						contains(getIdentifierStringFromEfaStatus(efa_status), searchString) ||
						contains(AddressInfo.toString(address), searchString)
					);
				},
			);
			return declaration.declarations_functional.length !== 0;
		});
		return group[GreenKeys.KEY_DECLARATIONS].length !== 0;
	}

	goToStats(id: RepresentativeId | "all") {
		this.router.navigate(RouteDealer.stats({ id, type: ResourceType.Representative }));
	}
}
