<div class="not-found">
	<h3>
		404
		<span>Page introuvable</span>
	</h3>
	<a
		mat-button
		class="btn-primary"
		(click)="isolation.isolationInfo ? goToDashboard(isolation.isolationInfo) : router.navigate(['/'])"
		>Retourner à l'accueil</a
	>
</div>
