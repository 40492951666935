import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { DeclarationGroup } from "../models/declarationGroup";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { DeclarationId } from "../models/ids";
import { Lazy } from "../models/lazy";

@Injectable({
	providedIn: "root",
})
export class AgreementService {
	constructor(private http: HttpClient) {}

	sendAgreements(agreements: { [id: string]: { [agreement_key: string]: boolean } }) {
		return this.http.post<void>(`${environment.baseApiUrl}/agreement`, {
			agreement_asks: agreements,
		});
	}

	getAgreements(declarationId: DeclarationId) {
		return this.http.get<{ declarations_functional: FunctionalDeclaration[] }>(
			`${environment.baseApiUrl}/declaration/${declarationId}/agreement`,
		);
	}

	sendAgreementsInvites(declarationId: DeclarationId, body: string[]) {
		return this.http.post<Lazy<DeclarationGroup>[]>(
			`${environment.baseApiUrl}/declaration/${declarationId}/agreement/invite`,
			body,
		);
	}

	checkPrm$(reference: string): Observable<PrmCheckResponse> {
		return this.http.get<PrmCheckResponse>(`${environment.baseApiUrl}/prm-info/${reference}`);
	}

	sendRenewal$(declarationId: DeclarationId, body: string[]): Observable<Lazy<DeclarationGroup>[]> {
		return this.http.post<Lazy<DeclarationGroup>[]>(
			`${environment.baseApiUrl}/declaration/${declarationId}/agreement/renewal`,
			body,
		);
	}
}

export type PrmCheckResponse = {
	data: {
		address: {
			numeroEtNomVoie: string;
			codePostal: string;
			commune: {
				libelle: string;
				code: string;
			};
		};
		terminated: boolean;
		communicating: boolean;
	};
};
