import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { getCorrectionDataList } from "../helpers/conso";
import { Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

@Pipe({
	name: "getReferenceYearForFunctionalDeclaration",
})
export class GetReferenceYearForFunctionalDeclarationPipe implements PipeTransform {
	transform(
		fd: Immutable<FunctionalDeclaration>,
		djus: { [year: string]: number },
		absoluteObjectives: { [year: string]: number },
	): number | undefined {
		if (fd.infos.referenceYear) {
			return fd.infos.referenceYear;
		}

		const correctionList = getCorrectionDataList(fd, djus, absoluteObjectives);
		const annualDeclarationDatas = correctionList
			.filter(({ year, isIncomplete }) => year >= ANNUAL_DECLARATION_STARTING_YEAR && !isIncomplete)
			.sort((a, b) => a.year - b.year);

		if (annualDeclarationDatas.length > 0) {
			return annualDeclarationDatas[0].year;
		}

		return undefined;
	}
}
