import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OperatApiBody, OperatApiResponseObject } from "@grs/operat-micro-service-lib-ts";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AddressInfo } from "../models/address";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { DeclarationId, FunctionalDeclarationId } from "../models/ids";

@Injectable({
	providedIn: "root",
})
export class OperatApiService {
	constructor(private http: HttpClient) {}

	/**
	 * Tell the DT's API to send the declaration's consumptions of specified year to OPERAT's API.
	 * Expected response code: 201 or 404.
	 * If 404, return the error message.
	 * @param declarationId
	 * @param year
	 */
	uploadConsumptionsForDeclarationAndYear(declarationId: DeclarationId, year: string) {
		return this.http
			.post<string>(`${environment.baseApiUrl}/declaration/${declarationId}/operat-api/conso`, { year })
			.pipe(
				catchError((error: unknown) => {
					if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest) {
						return of(error.error.error);
					}
					throw error;
				}),
			);
	}

	searchEntities(
		inseeId: string,
		userKey: string,
	): Observable<Record<string, { address: string; denomination: string }>> {
		return this.http.post<Record<string, { address: string; denomination: string }>>(
			`${environment.baseApiUrl}/declaration/operat-api/efas`,
			{
				id: inseeId,
				operat_user_key: userKey,
			},
		);
	}

	importEFAByAPI(
		declarationId: DeclarationId,
		reference: { refOperat: string; address?: AddressInfo; denomination: string },
	): Observable<FunctionalDeclaration.Api> {
		return this.http.post<FunctionalDeclaration.Api>(
			`${environment.baseApiUrl}/declaration/${declarationId}/operat-api/efas`,
			{
				import_data: reference,
			},
		);
	}

	searchConsumptionDeclarationsForEntity(
		functionalDeclarationId: FunctionalDeclarationId,
	): Observable<Record<string, OperatApiBody>> {
		return this.http
			.get<
				Record<string, OperatApiResponseObject>
			>(`${environment.baseApiUrl}/declaration_functional/${functionalDeclarationId}/search-consumptions-declarations`)
			.pipe(
				map((data) => {
					// Convert response into OperatApiBody
					const operatData: Record<string, OperatApiBody> = {};
					const years = Object.keys(data);
					for (const year of years) {
						operatData[year] = new OperatApiBody(data[year]);
					}
					return operatData;
				}),
			);
	}

	fillEfa(
		id: FunctionalDeclarationId,
		dataToImport: { year: number; categories: boolean; consumptions: boolean; data: OperatApiBody }[],
	): Observable<FunctionalDeclaration.Api> {
		return this.http.post<FunctionalDeclaration.Api>(
			`${environment.baseApiUrl}/declaration_functional/${id}/import`,
			dataToImport.map((consumptionsData) => ({ ...consumptionsData, data: consumptionsData.data.serialize() })),
		);
	}
}
