<table [class.full-width]="fullWidth">
	<thead>
		<tr *ngIf="showAbsoluteObjectiveError">
			<td colspan="5">
				<div class="alert-danger mt-1" style="text-align: justify">
					Pour certaines entités fonctionnelles, les sous-catégories d'activité renseignées n'ont pas toutes été
					décrites dans les textes officiels. Il est donc
					<b>impossible de calculer l'objectif en valeur absolue</b>. <br />
					Les formules de corrections sont également impactées et le choix de
					<b>l'année de référence peut ne pas être optimal</b>
					(notamment si des années sont similaires avant les corrections).
				</div>
			</td>
		</tr>
		<tr #header>
			<th class="pr-2 pl-2">Site</th>
			<th class="pr-2 pl-2">Année de référence</th>
			<th class="pr-2 pl-2">Objectif relatif 2030</th>
			<th class="pr-2 pl-2">Objectif absolu 2030</th>
			<th>
				<button
					(click)="calculateObjectives.emit()"
					[class.btn-loading]="loading"
					[disabled]="loading"
					class="btn-primary btn-squared p-0 ml-2"
					matTooltip="Si des modifications ont été apportées dans les étapes précédentes, cliquer ici pour mettre à jour les objectifs / années de référence"
					matTooltipPosition="above"
				>
					<mat-icon>sync</mat-icon>
				</button>
			</th>
		</tr>
	</thead>
	<tbody tourAnchor="reference-year-summary-all">
		<tr
			*ngFor="let declarationFunctional of functionalDeclarations; index as i"
			(click)="visitReferenceYear(declarationFunctional.declaration_functional_id)"
			class="table-row"
			[tourAnchor]="i === 0 ? 'reference-year-summary-row' : ''"
			#entityRow
		>
			<td>
				<div class="address">
					<b class="primary">{{ declarationFunctional.name ?? declarationFunctional.description }}</b>
					<span>{{ declarationFunctional.address | addressToString }}</span>
					<i class="text-gray">{{ declarationFunctional | functionalDeclarationSubtitle }}</i>
				</div>
			</td>
			<ng-container *ngIf="declarationFunctional.infos.referenceYear; else noReferenceYear">
				<td [tourAnchor]="i === 0 ? 'reference-year-summary-year' : ''">
					{{ declarationFunctional.infos.referenceYear }}
				</td>
				<td [tourAnchor]="i === 0 ? 'reference-year-summary-relative' : ''">
					<div
						class="relative-objective"
						*ngIf="
							(declarationFunctional.is_token_used || declarationFunctional.is_imported_from_operat) &&
							declarationFunctional.infos.referenceYearConsumption &&
							declarationFunctional.infos.relativeObjective
						"
					>
						<span
							>{{ declarationFunctional.infos.referenceYearConsumption | numberUnit | replace: "." : "," }}Wh/m² -
							40%&nbsp;</span
						>
						<span>
							=
							<span class="primary"
								>{{ declarationFunctional.infos.relativeObjective | numberUnit | replace: "." : "," }}Wh/m²</span
							></span
						>
					</div>
				</td>
				<td [tourAnchor]="i === 0 ? 'reference-year-summary-absolute' : ''">
					<div
						class="absolute-objective"
						*ngIf="
							declarationFunctional.infos.absoluteObjective &&
							(declarationFunctional.is_token_used || declarationFunctional.is_imported_from_operat)
						"
					>
						<ng-container *ngIf="declarationFunctional.infos.absoluteObjective === WRONG_ABSOLUTE_OBJECTIVE">
							<span
								class="danger"
								matTooltip="Il est actuellement impossible de calculer l'objectif absolu car certaines sous-catégories d'activité choisies n'ont pas encore été décrites dans les textes officiels. Cet objectif sera calculé dès que les informations nécessaires paraîtront."
								matTooltipPosition="above"
								><mat-icon>warning</mat-icon></span
							>
						</ng-container>
						<ng-container *ngIf="declarationFunctional.infos.absoluteObjective > 0">
							<span class="absolute-objective"
								>{{ declarationFunctional.infos.absoluteObjective | numberUnit | replace: "." : "," }}Wh/m²</span
							>
						</ng-container>
					</div>
				</td>
			</ng-container>
			<ng-template #noReferenceYear>
				<ng-container *ngIf="declarationFunctional | apply: getFunctionalDeclarationRefAndObjectives as referenceData">
					<td [tourAnchor]="i === 0 ? 'reference-year-summary-year' : ''">{{ referenceData.reference }}</td>
					<td [tourAnchor]="i === 0 ? 'reference-year-summary-relative' : ''">
						<div
							class="relative-objective"
							*ngIf="
								(declarationFunctional.is_token_used || declarationFunctional.is_imported_from_operat) &&
								referenceData.correctedConsumption &&
								referenceData.relativeObjective
							"
						>
							<span>{{ referenceData.correctedConsumption | numberUnit | replace: "." : "," }}Wh/m² - 40%&nbsp;</span>
							<span>
								= <span class="primary">{{ referenceData.relativeObjective | numberUnit }}Wh/m²</span></span
							>
						</div>
					</td>
					<td [tourAnchor]="i === 0 ? 'reference-year-summary-absolute' : ''">
						<div
							class="absolute-objective"
							*ngIf="
								referenceData.absoluteObjective &&
								(declarationFunctional.is_token_used || declarationFunctional.is_imported_from_operat)
							"
						>
							<ng-container *ngIf="referenceData.absoluteObjective === WRONG_ABSOLUTE_OBJECTIVE">
								<span
									class="danger"
									matTooltip="Il est actuellement impossible de calculer l'objectif absolu car certaines sous-catégories d'activité choisies n'ont pas encore été décrites dans les textes officiels. Cet objectif sera calculé dès que les informations nécessaires paraîtront."
									matTooltipPosition="above"
									><mat-icon>warning</mat-icon></span
								>
							</ng-container>
							<span class="absolute-objective" *ngIf="referenceData.absoluteObjective > 0"
								>{{ referenceData.absoluteObjective | numberUnit }}Wh/m²</span
							>
						</div>
					</td>
				</ng-container>
			</ng-template>
			<td>
				<div class="d-flex-col" style="justify-content: center" *ngIf="!declarationFunctional.is_token_used">
					<mat-icon matTooltip="Crédit non dépensé"> production_quantity_limits </mat-icon>
				</div>
			</td>
		</tr>
	</tbody>
</table>
