import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { isHoleInConsumption, noCategoryForYear, noConsumptionCategoryForPeriod } from "../helpers/conso";
import * as immObject from "../helpers/immutable";
import { entityCanAccessPremiumFeatures, FunctionalDeclaration } from "../models/functionalDeclaration";
import { dateToTimestamp } from "./date-to-timestamp.pipe";
import { periodRange } from "./period-range.pipe";

@Pipe({
	name: "allConsumptionsIncomplete",
})
export class AllConsumptionsIncompletePipe implements PipeTransform {
	transform = allConsumptionsIncomplete;
}

export function allConsumptionsIncomplete(functionalDeclaration: Immutable<FunctionalDeclaration>) {
	// If there are 0 consumptions
	if (
		immObject
			.values(functionalDeclaration.consumptions)
			.flatMap((consoType) => immObject.values(consoType))
			.flatMap(({ values }) => immObject.values(values))
			.flatMap(({ values }) => values).length === 0
	) {
		return true;
	}
	if (entityCanAccessPremiumFeatures(functionalDeclaration)) {
		for (let year = 2010; year <= 2019; ++year) {
			const period = periodRange(year, functionalDeclaration.infos.favoritePeriod);
			if (
				noConsumptionCategoryForPeriod(
					dateToTimestamp(period.start),
					dateToTimestamp(period.end),
					functionalDeclaration,
				)
			) {
				continue;
			}
			if (
				!isHoleInConsumption(functionalDeclaration, dateToTimestamp(period.start), dateToTimestamp(period.end)) &&
				!noCategoryForYear(functionalDeclaration, year)
			) {
				return false;
			}
		}
	}
	return true;
}
