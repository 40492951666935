import { HeaderEnum } from "./HeaderEnum";
export class Activity {
  sousCategorieActivite;
  surfacePlancherM2;
  dateDebutActivite;
  dateFinActivite;
  chauffage;
  refroidissement;
  logistiqueDeFroid;
  froidCommercial;
  conservationDocCollections;
  constructor(responseBody) {
    this.sousCategorieActivite = responseBody[HeaderEnum.SOUS_CATEGORIE_ACTIVITE] ?? "";
    this.surfacePlancherM2 = parseFloat(responseBody[HeaderEnum.SURFACE_PLANCHER_M2] ?? "0");
    this.dateDebutActivite = responseBody[HeaderEnum.DATE_DEBUT_ACTIVITE] ?? "";
    this.dateFinActivite = responseBody[HeaderEnum.DATE_FIN_ACTIVITE] ?? "";
    this.chauffage = this.boolFromOperat(responseBody[HeaderEnum.CHAUFFAGE] ?? "");
    this.refroidissement = this.boolFromOperat(responseBody[HeaderEnum.REFROIDISSEMENT] ?? "");
    this.logistiqueDeFroid = this.boolFromOperat(responseBody[HeaderEnum.LOGISTIQUE_DE_FROID] ?? "");
    this.froidCommercial = this.boolFromOperat(responseBody[HeaderEnum.FROID_COMMERCIAL] ?? "");
    this.conservationDocCollections = this.boolFromOperat(responseBody[HeaderEnum.CONSERVATION_DOC_COLLECTIONS] ?? "");
  }
  serialize() {
    const result = {};
    result[HeaderEnum.SOUS_CATEGORIE_ACTIVITE] = this.sousCategorieActivite;
    result[HeaderEnum.SURFACE_PLANCHER_M2] = `${this.surfacePlancherM2}`;
    result[HeaderEnum.DATE_DEBUT_ACTIVITE] = this.dateDebutActivite;
    result[HeaderEnum.DATE_FIN_ACTIVITE] = this.dateFinActivite;
    result[HeaderEnum.CHAUFFAGE] = this.boolToOperat(this.chauffage);
    result[HeaderEnum.REFROIDISSEMENT] = this.boolToOperat(this.refroidissement);
    result[HeaderEnum.LOGISTIQUE_DE_FROID] = this.boolToOperat(this.logistiqueDeFroid);
    result[HeaderEnum.FROID_COMMERCIAL] = this.boolToOperat(this.froidCommercial);
    result[HeaderEnum.CONSERVATION_DOC_COLLECTIONS] = this.boolToOperat(this.conservationDocCollections);
    return result;
  }
  static dateToIso(date) {
    const parsedDate = date.replace("\\", "").split("/");
    return `${parsedDate[2]}-${parsedDate[1]}-${parsedDate[0]}`;
  }
  boolToOperat(value) {
    return value ? "OUI" : "NON";
  }
  boolFromOperat(value) {
    return value === "OUI";
  }
}
