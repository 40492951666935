import { TupleToUnion } from "./tupleToUnion";

const OBJECTIVE_YEAR = [2030, 2040, 2050] as const;
const WRONG_ABSOLUTE_OBJECTIVE = -1;

// les facteurs pour passer de l'objectif de 2030 à l'objectif d'une autre année
function getObjectiveRate(year: TupleToUnion<typeof OBJECTIVE_YEAR>) {
	switch (year) {
		case 2030:
			return 1;
		case 2040:
			return 5 / 6;
		case 2050:
			return 2 / 3; // (4 / 5) * (5 / 6);
	}
}

function getObjectiveRateFromReferenceYear(year: TupleToUnion<typeof OBJECTIVE_YEAR>): 0.6 | 0.5 | 0.4 {
	switch (year) {
		case 2030:
			return 0.6;
		case 2040:
			return 0.5;
		case 2050:
			return 0.4;
	}
}

/**
 * Depending on the asset categories, absolute objectives can be === 0 for CURRENT_COMPLETE_YEAR
 * @param absolutesObjectives
 * @param currentCompleteYear
 * @return the most recent absolute objective, either wrong (=== -1) or > 0, or -1 if no absolute objective
 */
function getCurrentAbsoluteObjective(
	absolutesObjectives: { [year: number]: number },
	currentCompleteYear: number,
): number {
	const minYear = Math.min(...Object.keys(absolutesObjectives).map((key) => parseInt(key)));
	while (!absolutesObjectives[currentCompleteYear] && currentCompleteYear >= minYear) {
		currentCompleteYear--;
	}

	return absolutesObjectives[currentCompleteYear] ?? ObjectivesHelper.WRONG_ABSOLUTE_OBJECTIVE;
}

export const ObjectivesHelper = {
	OBJECTIVE_YEAR,
	WRONG_ABSOLUTE_OBJECTIVE,
	getObjectiveRate,
	getObjectiveRateFromReferenceYear,
	getCurrentAbsoluteObjective,
};
