import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "checkErrorsEntityFormat",
})
export class CheckErrorsEntityFormatPipe implements PipeTransform {
	transform(errors: string[]): string {
		return errors.map((error) => " - " + error).join("\n");
	}
}
