import { Component, Input, OnChanges } from "@angular/core";
import { OptionalBuildingData, Table5, Table6 } from "src/app/models/optionalBuildingData";

@Component({
	selector: "app-optional-data-building",
	templateUrl: "./optional-data-building.component.html",
	styleUrls: ["./optional-data-building.component.scss"],
})
export class OptionalDataBuildingComponent implements OnChanges {
	readonly Table5 = Table5;
	readonly Table6 = Table6;

	@Input() data!: OptionalBuildingData;
	autreEquipementImmoibilerChoices!: ReturnType<typeof Table6.AutresEquipementsImmobiliers.getChoices>;
	autreTypePrincipalDeVentilation!: { autre: string };
	autreTypePrincipalDEmetteurDeChauffage!: { autre: string };

	ngOnChanges(): void {
		const autreEquipementImmoibilerChoices = Table6.AutresEquipementsImmobiliers.getChoices();

		if (this.data.table6.autresEquipementsImmobiliers) {
			for (let i = 0; i < autreEquipementImmoibilerChoices.length; ++i) {
				for (const choice of this.data.table6.autresEquipementsImmobiliers) {
					if (autreEquipementImmoibilerChoices[i].type === choice.type) {
						// I do this to have the reference equality
						autreEquipementImmoibilerChoices[i] = choice;
					}
				}
			}
		}

		const { typePrincipalDeVentilation, typePrincipalDEmetteurDeChauffage } = this.data.table6;

		this.autreEquipementImmoibilerChoices = autreEquipementImmoibilerChoices;
		this.autreTypePrincipalDeVentilation =
			typeof typePrincipalDeVentilation === "object" ? typePrincipalDeVentilation : { autre: "" };
		this.autreTypePrincipalDEmetteurDeChauffage =
			typeof typePrincipalDEmetteurDeChauffage === "object" ? typePrincipalDEmetteurDeChauffage : { autre: "" };
	}
}
