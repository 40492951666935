import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Page } from "../components/navigation/stepper/stepper.component";
import { Declaration } from "../models/declaration";
import { StepTodoPipe } from "./step-todo.pipe";

@Pipe({
	name: "stepTodoPercentage",
})
export class StepTodoPercentagePipe implements PipeTransform {
	transform(declaration: Immutable<Declaration>, page: Page): number {
		if (declaration.declarations_functional.length === 0) {
			return 0;
		}
		return (
			((declaration.declarations_functional.length - new StepTodoPipe().transform(declaration, page).length) /
				declaration.declarations_functional.length) *
			100
		);
	}
}
