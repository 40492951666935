import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { ActivityCategoryEntry, Irve } from "../models/asset";
import { AddBlock } from "./sort-activity-categories-per-start.pipe";

@Pipe({
	name: "isAddBlock",
})
export class IsAddBlockPipe implements PipeTransform {
	transform(toTest: Immutable<ActivityCategoryEntry | Irve | AddBlock>): Immutable<AddBlock> | undefined {
		return "template" in toTest ? toTest : undefined;
	}
}
