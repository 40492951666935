import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Stats } from "../components/navigation/my-declarations/stats/stats.component";
import { OperatCsvData } from "../helpers/import-csv-helper";
import { handleSavingUnlock } from "../helpers/saving-helper";
import { ZodParser } from "../helpers/zod-parser";
import { Declaration } from "../models/declaration";
import { DeclarationId } from "../models/ids";
import { Lazy } from "../models/lazy";
import { ResourceInfo, ResourceType } from "../models/resource";

@Injectable({
	providedIn: "root",
})
export class DeclarationService {
	readonly saving$ = new ReplaySubject<boolean>(1);

	constructor(private http: HttpClient) {
		this.saving$.next(false);
	}

	get$(id: DeclarationId): Observable<Lazy<Declaration.Api>> {
		return this.http.get<Lazy<Declaration.Api>>(`${environment.baseApiUrl}/declaration/${id}`);
	}

	getNested$(id: DeclarationId): Observable<Declaration.Api> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append("nested", true);
		return this.http.get<Declaration.Api>(`${environment.baseApiUrl}/declaration/${id}`, { params: httpParams }).pipe(
			map((declaration: Declaration.Api) => {
				// Fix consumptions entries for functional declarations
				for (const functionalDeclaration of declaration.declarations_functional) {
					functionalDeclaration[GreenKeys.KEY_CONSUMPTIONS] = ZodParser.fixConsumptionsType(
						functionalDeclaration[GreenKeys.KEY_CONSUMPTIONS],
					);
				}
				return declaration;
			}),
		);
	}

	save$(declaration: Immutable<Lazy<Declaration.Api>>): Observable<Lazy<Declaration.Api>> {
		this.saving$.next(true);
		return this.http
			.put<Lazy<Declaration.Api>>(`${environment.baseApiUrl}/declaration/${declaration.declaration_id}`, declaration)
			.pipe(handleSavingUnlock(this.saving$));
	}

	create$(declaration: Immutable<Declaration.Api>): Observable<Lazy<Declaration.Api>> {
		this.saving$.next(true);
		return this.http
			.post<Lazy<Declaration.Api>>(`${environment.baseApiUrl}/declaration`, declaration)
			.pipe(handleSavingUnlock(this.saving$));
	}

	delete$(id: DeclarationId): Observable<void> {
		return this.http.delete<void>(`${environment.baseApiUrl}/declaration/${id}`);
	}

	exportCsv$(
		{ id, type }: ResourceInfo,
		data: { first_name: string; last_name: string; email: string },
	): Observable<string> {
		return this.http.post(`${environment.baseApiUrl}/${type}/${id}/export_csv`, data, {
			responseType: "text",
		});
	}

	stats$(
		{ id, type }: ResourceInfo | { id: "all"; type: ResourceType },
		year: number | undefined = undefined,
	): Observable<Stats> {
		return this.http.get<Stats>(`${environment.baseApiUrl}/${type}/${id}/stats${year ? `?year=${year}` : ""}`);
	}

	publicStats$(): Observable<Stats> {
		return this.http.get<Stats>(`${environment.baseApiUrl}/stats`);
	}

	importEFAByCSV(declarationId: DeclarationId, importData: OperatCsvData): Observable<Declaration.Api> {
		this.saving$.next(true);
		return this.http
			.post<Declaration.Api>(`${environment.baseApiUrl}/declaration/${declarationId}/import`, importData)
			.pipe(handleSavingUnlock(this.saving$));
	}
}
