import { Component } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Nullable } from "../../../../../../helpers/nullable";
import { AddressInfo } from "../../../../../../models/address";
import { Declaration } from "../../../../../../models/declaration";
import { FunctionalDeclaration } from "../../../../../../models/functionalDeclaration";
import { Modal } from "../../../../../../models/modal";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

type Input = {
	functionalDeclarations: Immutable<FunctionalDeclaration[]>;
	year: Nullable<number>;
};

type State = {
	functionalDeclarations: Immutable<FunctionalDeclaration[]>;
	year: Nullable<number>;
};

@Component({
	selector: "app-partial-api-upload",
	templateUrl: "./partial-api-upload.component.html",
	styleUrls: ["./partial-api-upload.component.scss"],
})
export class PartialApiUploadComponent extends Modal<Input, State, undefined | boolean> {
	reachedBottom = false;

	inputToState(input: Input): State {
		return {
			functionalDeclarations: input.functionalDeclarations,
			year: input.year,
		};
	}

	get unlockedFd(): Immutable<FunctionalDeclaration[]> {
		return this.state.functionalDeclarations.filter((fd) =>
			this.state.year && this.state.year >= ANNUAL_DECLARATION_STARTING_YEAR
				? fd[GreenKeys.KEY_YEARS_TOKEN_USED].includes(this.state.year)
				: fd.is_token_used,
		);
	}

	addressToString(address: Immutable<AddressInfo>) {
		return AddressInfo.toString(address);
	}

	checkOverflow(div: HTMLElement) {
		return div.offsetHeight < div.scrollHeight;
	}

	onScroll({ target }: Event) {
		const div = target as HTMLElement;
		// visible height + pixel scrolled >= total height
		this.reachedBottom = div.offsetHeight + div.scrollTop >= div.scrollHeight - 50;
	}

	scrollToBottom(div: HTMLElement) {
		div.scrollTop = div.scrollHeight;
	}

	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
}
