import { Component, EventEmitter, Input, Output } from "@angular/core";
import { produce, Immutable } from "immer";
import { HelpSubject } from "src/app/components/help/help.component";
import { CategorySurfaceTertiaire, SurfaceTertiaire } from "src/app/models/functionalDeclaration";

@Component({
	selector: "app-surface-tabs",
	templateUrl: "./surface-tabs.component.html",
	styleUrls: ["./surface-tabs.component.scss"],
})
export class SurfaceTabsComponent {
	readonly exemptedSum = CategorySurfaceTertiaire.surfaceSum;

	@Input() surface: Immutable<SurfaceTertiaire> = new SurfaceTertiaire();
	@Output() updateSurface = new EventEmitter<Immutable<SurfaceTertiaire>>();

	HelpSubject = HelpSubject;

	updateField(key: string, subKey: string | undefined, value: number) {
		const clone = produce(this.surface, (draft) => {
			const categories = draft.categorySurfaceAreas;

			if (subKey !== undefined) {
				if (categories[key] === undefined) {
					categories[key] = {};
				}

				const record = categories[key];
				if (typeof record !== "number") {
					if (value) {
						record[subKey] = value;
					} else {
						delete record[subKey];
						// if the parent is empty then we delete it
						if (Object.keys(record).length === 0) {
							delete categories[key];
						}
					}
				}
			} else {
				if (value) {
					categories[key] = value;
				} else {
					delete categories[key];
				}
			}
		});

		this.updateSurface.emit(clone);
	}

	updateTertiaireArea(area: string) {
		const floatArea = parseInt(area);

		if (!isNaN(floatArea)) {
			const clone = produce(this.surface, (draft) => {
				draft.tertiaireArea = floatArea;
			});
			this.updateSurface.emit(clone);
		}
	}

	updateAccessoryArea(area: string) {
		const floatArea = parseInt(area);

		if (!isNaN(floatArea)) {
			const clone = produce(this.surface, (draft) => {
				draft.accessoryArea = floatArea;
			});
			this.updateSurface.emit(clone);
		}
	}
}
