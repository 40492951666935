import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { AfterViewInit, Component, Input } from "@angular/core";
import { EnergyType, energyTypeLabel } from "../../../../../../models/energyType";

@Component({
	selector: "app-stat-chart-per-energy",
	templateUrl: "./stat-chart-per-energy.component.html",
	styleUrls: ["./stat-chart-per-energy.component.scss"],
})
export class StatChartPerEnergyComponent implements AfterViewInit {
	readonly COLOR_PER_ENERGY: { [energy: string]: string } = {
		electricity_kwh: "#81DA98",
		gaz_nat_network_kwh: "#F2D16A",
		gaz_butane_kg: "#ffd300",
		gaz_nat_liquefied_kg: "#d7a816",
		gaz_propane_kg: "#ad7a01",
		fuel_domestic_litre: "#ff3716",
		coal_agglo_brick_kg: "#a9a9a9",
		coal_kg: "#595959",
		wood_plate_forest: "#65462f",
		wood_gran_brick: "#673a1b",
		wood_logs_stere: "#5e2e0d",
		wood_plate_industrial: "#4d2306",
		heat_network_kwh: "#fc6d0d",
		cool_network_kwh: "#40c0e3",
	};

	@Input() perEnergyCount!: { [type: string]: number };

	ngAfterViewInit() {
		const root = am5.Root.new("perEnergy-chart");
		const chart = root.container.children.push(am5xy.XYChart.new(root, {}));
		const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
		cursor.lineY.set("visible", false);
		const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
		xRenderer.labels.template.setAll({
			rotation: -45,
			centerY: am5.p50,
			centerX: am5.p100,
			paddingRight: 15,
		});

		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				maxDeviation: 0.3,
				categoryField: "energyType",
				renderer: xRenderer,
				tooltip: am5.Tooltip.new(root, {}),
			}),
		);

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				maxDeviation: 0.3,
				renderer: am5xy.AxisRendererY.new(root, {}),
			}),
		);

		const series = chart.series.push(
			am5xy.ColumnSeries.new(root, {
				name: "Series 1",
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: "count",
				sequencedInterpolation: true,
				categoryXField: "energyType",
				tooltip: am5.Tooltip.new(root, {
					labelText: "{valueY}",
				}),
			}),
		);
		series.columns.template.set("strokeOpacity", 0);

		series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
		series.columns.template.adapters.add("fill", (fill, target) => {
			const dataContext = target.dataItem?.dataContext;
			if (
				typeof dataContext === "object" &&
				dataContext &&
				"color" in dataContext &&
				(typeof dataContext.color === "string" || typeof dataContext.color === "number")
			) {
				return am5.Color.fromString(dataContext.color.toString());
			}
			return fill;
		});

		const data = Object.keys(this.perEnergyCount)
			.map((energyType) => ({
				energyType: energyTypeLabel(energyType as EnergyType),
				count: this.perEnergyCount[energyType],
				color: this.COLOR_PER_ENERGY[energyType],
			}))
			.sort((et1, et2) => et2.count - et1.count);

		xAxis.data.setAll(data);
		series.data.setAll(data);

		series.appear();
		chart.appear();
	}
}
