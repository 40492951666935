import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { ActivityCategoryEntry } from "../models/asset";

@Pipe({
	name: "isActivityCategoryEntriesOverlapping",
})
export class IsActivityCategoryEntriesOverlappingPipe implements PipeTransform {
	transform(
		ace: Immutable<{ start: number; end?: number }>,
		activityCategoryEntries: Immutable<ActivityCategoryEntry[]>,
	): boolean {
		let res = false;
		const maxDate = new Date();
		maxDate.setFullYear(maxDate.getFullYear() + 1, 0, 1);
		maxDate.setHours(0, 0, 0, 0);
		const aceEnd = ace.end ?? maxDate.getTime() / 1000;
		const aceStart = ace.start;
		activityCategoryEntries.forEach((ace2) => {
			const ace2End = ace2.end ?? maxDate.getTime() / 1000;
			const ace2Start = ace2.start;
			if (
				ace2 !== ace &&
				((aceStart <= ace2Start && ace2Start < aceEnd) ||
					(aceStart < ace2End && ace2End <= aceEnd) ||
					(ace2Start <= aceStart && aceStart < ace2End) ||
					(ace2Start < aceEnd && aceEnd <= ace2End) ||
					(aceStart === ace2Start && aceEnd === ace2End))
			) {
				res = true;
			}
		});
		return res;
	}
}
