<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0" style="position: relative" (scroll)="onScroll($event)" #contentDiv>
		<h3 class="my-0">Fichier CSV partiel</h3>
		<div class="dialog-small-text">
			<div class="d-flex-col gap-3">
				<div>
					Attention, seule une partie des déclarations
					{{ state.year && state.year >= ANNUAL_DECLARATION_STARTING_YEAR ? "annuelles" : "initiales" }} a été
					effectuée. Le fichier comprendra donc uniquement les déclarations
					<span style="font-weight: bold">complètes</span> des EFA ayant utilisé un crédit
					{{ state.year && state.year >= ANNUAL_DECLARATION_STARTING_YEAR ? "annuel" : "initial" }}.
				</div>
				<div>
					Les EFA ayant utilisé un crédit, mais dont les déclarations sont incomplètes ne seront pas non plus intégrées
					au fichier.
				</div>
				<div>Voulez-vous tout de même télécharger ce fichier ?</div>
				<div>
					Un crédit {{ state.year && state.year >= ANNUAL_DECLARATION_STARTING_YEAR ? "annuel" : "initial" }} a été
					dépensé pour les EFA suivantes :
					<ul>
						<li *ngFor="let fd of unlockedFd">{{ fd.name }} : {{ addressToString(fd.address) }}</li>
					</ul>
				</div>
			</div>
		</div>
		<div style="display: flex; justify-content: center; position: sticky; bottom: 0; left: 0; width: 100%">
			<button
				*ngIf="checkOverflow(contentDiv) && !reachedBottom"
				mat-mini-fab
				color="primary"
				aria-label="Scroll to bottom"
				(click)="scrollToBottom(contentDiv)"
			>
				<mat-icon>expand_more</mat-icon>
			</button>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray mr-auto" [mat-dialog-close]="undefined">Annuler</button>
		<a class="btn-stroked-primary" [href]="state.csv.file" download="{{ state.csv.filename }}.csv"> Télécharger </a>
	</div>
</div>
