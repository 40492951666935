import {
	HttpErrorResponse,
	HttpEvent,
	HttpEventType,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { InitialLoadingService } from "src/app/services/initial-loading.service";
import { UserStateService } from "src/app/services/user-state.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../services/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private userState: UserStateService,
		private authentication: AuthenticationService,
	) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const token = this.userState.user?.token;
		if (req.url.includes(environment.baseApiUrl) && token !== undefined) {
			req = req.clone({ setHeaders: { Authorization: `Basic ${token}` } });
		}
		InitialLoadingService.increment();
		return next.handle(req).pipe(
			catchError((err: unknown) => {
				if (err instanceof HttpErrorResponse) {
					return this.handleAuthError(err, req.url);
				}
				throw err;
			}),
			finalize(() => {
				InitialLoadingService.decrement();
			}),
		);
	}

	private handleAuthError(err: HttpErrorResponse, url: string): Observable<HttpEvent<unknown>> {
		if (err.status === 401 && url.includes(environment.baseApiUrl)) {
			localStorage.setItem("lastUrl", this.router.url);
			this.authentication.logout();
			// Have to do this otherwise if we have a 401 on home page it would get stuck
			this.router.navigateByUrl("/logout", { skipLocationChange: true }).then(() => {
				this.router.navigate(["/"]);
			});

			// I don't really know what we have to send. I think it's not really important because we tell the router to go elsewhere
			return of({ type: HttpEventType.Sent });
		}
		return throwError(err);
	}
}
