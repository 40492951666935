import { Pipe, PipeTransform } from "@angular/core";
import { EnergyType, energyTypeLabel, energyTypeUnit } from "../models/energyType";

@Pipe({
	name: "energyTypeTitle",
})
export class EnergyTypeTitlePipe implements PipeTransform {
	transform(et: EnergyType): string {
		return energyTypeLabel(et) + " (" + energyTypeUnit(et) + ")";
	}
}
