import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { BaseComponent } from "../../models/base-component.directive";

@Component({
	selector: "app-color-picker",
	templateUrl: "./color-picker.component.html",
	styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent extends BaseComponent {
	@Input() value = "#FF0000";
	@Output() valueChange = new EventEmitter<string>();
	@ViewChild("colorCodeInput") colorCodeInput!: ElementRef;

	valueSubject = new BehaviorSubject<string>(this.value);

	constructor() {
		super();

		this.sub(this.valueSubject.pipe(debounceTime(200)), () => {
			if (this.colorCodeInput && this.colorCodeInput.nativeElement.checkValidity()) {
				this.valueChange.emit(this.value);
			}
		});
	}
}
