import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
/**
 * Service used to give parameters to another page, without using query params
 */
export class HiddenRouteParamsService<T> {
	params: T[] = [];

	constructor() {}

	/**
	 * Add values to params array
	 * @param params
	 */
	push(...params: T[]) {
		this.params.push(...params);
	}

	/**
	 * Return every element from the params array (reset params service-side)
	 */
	pop(): T[] {
		return this.params.splice(0, this.params.length);
	}
}
