import { HeaderEnum } from "./HeaderEnum";
import { Activity } from "./Activity";
import { IndicatorGroup } from "./IndicatorGroup";
export class OperatApiBody {
  refOperatEfa;
  anneeDeclaree;
  dateDebutConsoReference;
  categorieActivitePrincipale;
  vehiElecNbBornes;
  vehiElecConsoAvcIRVEKWh;
  consoIndiHorsIRVEKWh;
  consoIndiGazNatReseauxKWh;
  consoIndiGazNatLiquefieKg;
  consoIndiGazPropaneKg;
  consoIndiGazPropaneM3;
  consoIndiGazButaneKg;
  consoIndiGazButaneM3;
  consoIndiFioulDomestiLitre;
  consoIndiCharbonAggloBriqKg;
  consoIndiHouilleKg;
  consoIndiBoisPlaqIndustrieKg;
  consoIndiBoisPlaqForestKg;
  consoIndiBoisGranBriqKg;
  consoIndiBoisBuchesStere;
  consoIndiReseauChaleurKWh;
  consoIndiReseauFroidKWh;
  consoIndiGazoleNonRoutierLitre;
  consoRepHorsIRVEKWh;
  consoRepGazNatReseauxKWh;
  consoRepGazNatLiquefieKg;
  consoRepGazPropaneKg;
  consoRepGazPropaneM3;
  consoRepGazButaneKg;
  consoRepGazButaneM3;
  consoRepFioulDomestiLitre;
  consoRepCharbonAggloBriqKg;
  consoRepHouilleKg;
  consoRepBoisPlaqIndustrieKg;
  consoRepBoisPlaqForestKg;
  consoRepBoisGranBriqKg;
  consoRepBoisBuchesStere;
  consoRepReseauChaleurKWh;
  consoRepReseauFroidKWh;
  consoRepGazoleNonRoutierLitre;
  consoComHorsIRVEKWh;
  consoComGazNatReseauxKWh;
  consoComGazNatLiquefieKg;
  consoComGazPropaneKg;
  consoComGazPropaneM3;
  consoComGazButaneKg;
  consoComGazButaneM3;
  consoComFioulDomestiLitre;
  consoComCharbonAggloBriqKg;
  consoComHouilleKg;
  consoComBoisPlaqIndustrieKg;
  consoComBoisPlaqForestKg;
  consoComBoisGranBriqKg;
  consoComBoisBuchesStere;
  consoComReseauChaleurKWh;
  consoComReseauFroidKWh;
  consoComGazoleNonRoutierLitre;
  stationMeteoEfa;
  chauffageConsoElecKWh;
  chauffageConsoGazNatReseauxKWh;
  chauffageConsoGazNatLiquefieKg;
  chauffageConsoGazPropaneKg;
  chauffageConsoGazPropaneM3;
  chauffageConsoGazButaneKg;
  chauffageConsoGazButaneM3;
  chauffageConsoFioulDomestiLitre;
  chauffageConsoCharbonAggloBriqKg;
  chauffageConsoHouilleKg;
  chauffageConsoBoisPlaqIndustrieKg;
  chauffageConsoBoisPlaqForestKg;
  chauffageConsoBoisGranBriqKg;
  chauffageConsoBoisBuchesStere;
  chauffageConsoReseauChaleurKWh;
  chauffageConsoReseauFroidKWh;
  chauffageConsoGazoleNonRoutierLitre;
  refroidissementConsoElecKWh;
  refroidissementConsoGazNatReseauxKWh;
  refroidissementConsoGazNatLiquefieKg;
  refroidissementConsoGazPropaneKg;
  refroidissementConsoGazPropaneM3;
  refroidissementConsoGazButaneKg;
  refroidissementConsoGazButaneM3;
  refroidissementConsoFioulDomestiLitre;
  refroidissementConsoCharbonAggloBriqKg;
  refroidissementConsoHouilleKg;
  refroidissementConsoBoisPlaqIndustrieKg;
  refroidissementConsoBoisPlaqForestKg;
  refroidissementConsoBoisGranBriqKg;
  refroidissementConsoBoisBuchesStere;
  refroidissementConsoReseauChaleurKWh;
  refroidissementConsoReseauFroidKWh;
  refroidissementConsoGazoleNonRoutierLitre;
  froidLogistHauteurRefroidie;
  froidLogistConsoElecKWh;
  froidLogistConsoGazNatReseauxKWh;
  froidLogistConsoGazNatLiquefieKg;
  froidLogistConsoGazPropaneKg;
  froidLogistConsoGazPropaneM3;
  froidLogistConsoGazButaneKg;
  froidLogistConsoGazButaneM3;
  froidLogistConsoFioulDomestiLitre;
  froidLogistConsoCharbonAggloBriqKg;
  froidLogistConsoHouilleKg;
  froidLogistConsoBoisPlaqIndustrieKg;
  froidLogistConsoBoisPlaqForestKg;
  froidLogistConsoBoisGranBriqKg;
  froidLogistConsoBoisBuchesStere;
  froidLogistConsoReseauChaleurKWh;
  froidLogistConsoReseauFroidKWh;
  froidLogistConsoGazoleNonRoutierLitre;
  froidComConsoElecKWh;
  froidComConsoGazNatReseauxKWh;
  froidComConsoGazNatLiquefieKg;
  froidComConsoGazPropaneKg;
  froidComConsoGazPropaneM3;
  froidComConsoGazButaneKg;
  froidComConsoGazButaneM3;
  froidComConsoFioulDomestiLitre;
  froidComConsoCharbonAggloBriqKg;
  froidComConsoHouilleKg;
  froidComConsoBoisPlaqIndustrieKg;
  froidComConsoBoisPlaqForestKg;
  froidComConsoBoisGranBriqKg;
  froidComConsoBoisBuchesStere;
  froidComConsoReseauChaleurKWh;
  froidComConsoReseauFroidKWh;
  froidComConsoGazoleNonRoutierLitre;
  conservationDocConsoElecKWh;
  conservationDocConsoGazNatReseauxKWh;
  conservationDocConsoGazNatLiquefieKg;
  conservationDocConsoGazPropaneKg;
  conservationDocConsoGazPropaneM3;
  conservationDocConsoGazButaneKg;
  conservationDocConsoGazButaneM3;
  conservationDocConsoFioulDomestiLitre;
  conservationDocConsoCharbonAggloBriqKg;
  conservationDocConsoHouilleKg;
  conservationDocConsoBoisPlaqIndustrieKg;
  conservationDocConsoBoisPlaqForestKg;
  conservationDocConsoBoisGranBriqKg;
  conservationDocConsoBoisBuchesStere;
  conservationDocConsoReseauChaleurKWh;
  conservationDocConsoReseauFroidKWh;
  conservationDocConsoGazoleNonRoutierLitre;
  activites;
  indicateurs;
  constructor(responseBody) {
    if (responseBody) {
      this.refOperatEfa = responseBody[HeaderEnum.REF_OPERAT_EFA] ?? "";
      this.anneeDeclaree = responseBody[HeaderEnum.ANNEE_DECLAREE] ? responseBody[HeaderEnum.ANNEE_DECLAREE] === "REFERENCE" ? responseBody[HeaderEnum.ANNEE_DECLAREE] : parseInt(responseBody[HeaderEnum.ANNEE_DECLAREE]) : "";
      this.dateDebutConsoReference = responseBody[HeaderEnum.DATE_DEBUT_CONSO_REFERENCE] ?? "";
      this.categorieActivitePrincipale = responseBody[HeaderEnum.CATEGORIE_ACTIVITE_PRINCIPALE] ?? "";
      this.vehiElecNbBornes = responseBody[HeaderEnum.VEHI_ELEC_NB_BORNES] ? parseInt(responseBody[HeaderEnum.VEHI_ELEC_NB_BORNES]) : 0;
      this.vehiElecConsoAvcIRVEKWh = responseBody[HeaderEnum.VEHI_ELEC_CONSO_AVC_IRVE_KWH] ? parseFloat(responseBody[HeaderEnum.VEHI_ELEC_CONSO_AVC_IRVE_KWH].replace(",", ".")) : 0;
      this.consoIndiHorsIRVEKWh = responseBody[HeaderEnum.CONSO_INDI_HORS_IRVE_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_HORS_IRVE_KWH].replace(",", ".")) : 0;
      this.consoIndiGazNatReseauxKWh = responseBody[HeaderEnum.CONSO_INDI_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.consoIndiGazNatLiquefieKg = responseBody[HeaderEnum.CONSO_INDI_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.consoIndiGazPropaneKg = responseBody[HeaderEnum.CONSO_INDI_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.consoIndiGazPropaneM3 = responseBody[HeaderEnum.CONSO_INDI_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.consoIndiGazButaneKg = responseBody[HeaderEnum.CONSO_INDI_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.consoIndiGazButaneM3 = responseBody[HeaderEnum.CONSO_INDI_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.consoIndiFioulDomestiLitre = responseBody[HeaderEnum.CONSO_INDI_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.consoIndiCharbonAggloBriqKg = responseBody[HeaderEnum.CONSO_INDI_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.consoIndiHouilleKg = responseBody[HeaderEnum.CONSO_INDI_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_HOUILLE_KG].replace(",", ".")) : 0;
      this.consoIndiBoisPlaqIndustrieKg = responseBody[HeaderEnum.CONSO_INDI_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.consoIndiBoisPlaqForestKg = responseBody[HeaderEnum.CONSO_INDI_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.consoIndiBoisGranBriqKg = responseBody[HeaderEnum.CONSO_INDI_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.consoIndiBoisBuchesStere = responseBody[HeaderEnum.CONSO_INDI_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.consoIndiReseauChaleurKWh = responseBody[HeaderEnum.CONSO_INDI_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.consoIndiReseauFroidKWh = responseBody[HeaderEnum.CONSO_INDI_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.consoIndiGazoleNonRoutierLitre = responseBody[HeaderEnum.CONSO_INDI_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSO_INDI_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.consoRepHorsIRVEKWh = responseBody[HeaderEnum.CONSO_REP_HORS_IRVE_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_HORS_IRVE_KWH].replace(",", ".")) : 0;
      this.consoRepGazNatReseauxKWh = responseBody[HeaderEnum.CONSO_REP_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.consoRepGazNatLiquefieKg = responseBody[HeaderEnum.CONSO_REP_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.consoRepGazPropaneKg = responseBody[HeaderEnum.CONSO_REP_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.consoRepGazPropaneM3 = responseBody[HeaderEnum.CONSO_REP_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.consoRepGazButaneKg = responseBody[HeaderEnum.CONSO_REP_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.consoRepGazButaneM3 = responseBody[HeaderEnum.CONSO_REP_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.consoRepFioulDomestiLitre = responseBody[HeaderEnum.CONSO_REP_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.consoRepCharbonAggloBriqKg = responseBody[HeaderEnum.CONSO_REP_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.consoRepHouilleKg = responseBody[HeaderEnum.CONSO_REP_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_HOUILLE_KG].replace(",", ".")) : 0;
      this.consoRepBoisPlaqIndustrieKg = responseBody[HeaderEnum.CONSO_REP_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.consoRepBoisPlaqForestKg = responseBody[HeaderEnum.CONSO_REP_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.consoRepBoisGranBriqKg = responseBody[HeaderEnum.CONSO_REP_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.consoRepBoisBuchesStere = responseBody[HeaderEnum.CONSO_REP_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.consoRepReseauChaleurKWh = responseBody[HeaderEnum.CONSO_REP_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.consoRepReseauFroidKWh = responseBody[HeaderEnum.CONSO_REP_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.consoRepGazoleNonRoutierLitre = responseBody[HeaderEnum.CONSO_REP_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSO_REP_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.consoComHorsIRVEKWh = responseBody[HeaderEnum.CONSO_COM_HORS_IRVE_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_HORS_IRVE_KWH].replace(",", ".")) : 0;
      this.consoComGazNatReseauxKWh = responseBody[HeaderEnum.CONSO_COM_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.consoComGazNatLiquefieKg = responseBody[HeaderEnum.CONSO_COM_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.consoComGazPropaneKg = responseBody[HeaderEnum.CONSO_COM_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.consoComGazPropaneM3 = responseBody[HeaderEnum.CONSO_COM_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.consoComGazButaneKg = responseBody[HeaderEnum.CONSO_COM_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.consoComGazButaneM3 = responseBody[HeaderEnum.CONSO_COM_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.consoComFioulDomestiLitre = responseBody[HeaderEnum.CONSO_COM_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.consoComCharbonAggloBriqKg = responseBody[HeaderEnum.CONSO_COM_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.consoComHouilleKg = responseBody[HeaderEnum.CONSO_COM_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_HOUILLE_KG].replace(",", ".")) : 0;
      this.consoComBoisPlaqIndustrieKg = responseBody[HeaderEnum.CONSO_COM_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.consoComBoisPlaqForestKg = responseBody[HeaderEnum.CONSO_COM_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.consoComBoisGranBriqKg = responseBody[HeaderEnum.CONSO_COM_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.consoComBoisBuchesStere = responseBody[HeaderEnum.CONSO_COM_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.consoComReseauChaleurKWh = responseBody[HeaderEnum.CONSO_COM_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.consoComReseauFroidKWh = responseBody[HeaderEnum.CONSO_COM_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.consoComGazoleNonRoutierLitre = responseBody[HeaderEnum.CONSO_COM_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSO_COM_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.stationMeteoEfa = responseBody[HeaderEnum.STATION_METEO_EFA] ?? "";
      this.chauffageConsoElecKWh = responseBody[HeaderEnum.CHAUFFAGE_CONSO_ELEC_KWH] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_ELEC_KWH].replace(",", ".")) : 0;
      this.chauffageConsoGazNatReseauxKWh = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.chauffageConsoGazNatLiquefieKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.chauffageConsoGazPropaneKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.chauffageConsoGazPropaneM3 = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.chauffageConsoGazButaneKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.chauffageConsoGazButaneM3 = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.chauffageConsoFioulDomestiLitre = responseBody[HeaderEnum.CHAUFFAGE_CONSO_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.chauffageConsoCharbonAggloBriqKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.chauffageConsoHouilleKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_HOUILLE_KG].replace(",", ".")) : 0;
      this.chauffageConsoBoisPlaqIndustrieKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.chauffageConsoBoisPlaqForestKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.chauffageConsoBoisGranBriqKg = responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.chauffageConsoBoisBuchesStere = responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.chauffageConsoReseauChaleurKWh = responseBody[HeaderEnum.CHAUFFAGE_CONSO_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.chauffageConsoReseauFroidKWh = responseBody[HeaderEnum.CHAUFFAGE_CONSO_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.chauffageConsoGazoleNonRoutierLitre = responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.CHAUFFAGE_CONSO_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.refroidissementConsoElecKWh = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_ELEC_KWH] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_ELEC_KWH].replace(",", ".")) : 0;
      this.refroidissementConsoGazNatReseauxKWh = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.refroidissementConsoGazNatLiquefieKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.refroidissementConsoGazPropaneKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.refroidissementConsoGazPropaneM3 = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.refroidissementConsoGazButaneKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.refroidissementConsoGazButaneM3 = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.refroidissementConsoFioulDomestiLitre = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.refroidissementConsoCharbonAggloBriqKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.refroidissementConsoHouilleKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_HOUILLE_KG].replace(",", ".")) : 0;
      this.refroidissementConsoBoisPlaqIndustrieKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.refroidissementConsoBoisPlaqForestKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.refroidissementConsoBoisGranBriqKg = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.refroidissementConsoBoisBuchesStere = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.refroidissementConsoReseauChaleurKWh = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.refroidissementConsoReseauFroidKWh = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.refroidissementConsoGazoleNonRoutierLitre = responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.REFROIDISSEMENT_CONSO_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.froidLogistHauteurRefroidie = responseBody[HeaderEnum.FROID_LOGIST_HAUTEUR_REFROIDIE] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_HAUTEUR_REFROIDIE].replace(",", ".")) : 0;
      this.froidLogistConsoElecKWh = responseBody[HeaderEnum.FROID_LOGIST_CONSO_ELEC_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_ELEC_KWH].replace(",", ".")) : 0;
      this.froidLogistConsoGazNatReseauxKWh = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.froidLogistConsoGazNatLiquefieKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.froidLogistConsoGazPropaneKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.froidLogistConsoGazPropaneM3 = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.froidLogistConsoGazButaneKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.froidLogistConsoGazButaneM3 = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.froidLogistConsoFioulDomestiLitre = responseBody[HeaderEnum.FROID_LOGIST_CONSO_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.froidLogistConsoCharbonAggloBriqKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.froidLogistConsoHouilleKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_HOUILLE_KG].replace(",", ".")) : 0;
      this.froidLogistConsoBoisPlaqIndustrieKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.froidLogistConsoBoisPlaqForestKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.froidLogistConsoBoisGranBriqKg = responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.froidLogistConsoBoisBuchesStere = responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.froidLogistConsoReseauChaleurKWh = responseBody[HeaderEnum.FROID_LOGIST_CONSO_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.froidLogistConsoReseauFroidKWh = responseBody[HeaderEnum.FROID_LOGIST_CONSO_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.froidLogistConsoGazoleNonRoutierLitre = responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.FROID_LOGIST_CONSO_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.froidComConsoElecKWh = responseBody[HeaderEnum.FROID_COM_CONSO_ELEC_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_ELEC_KWH].replace(",", ".")) : 0;
      this.froidComConsoGazNatReseauxKWh = responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.froidComConsoGazNatLiquefieKg = responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.froidComConsoGazPropaneKg = responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.froidComConsoGazPropaneM3 = responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.froidComConsoGazButaneKg = responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.froidComConsoGazButaneM3 = responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.froidComConsoFioulDomestiLitre = responseBody[HeaderEnum.FROID_COM_CONSO_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.froidComConsoCharbonAggloBriqKg = responseBody[HeaderEnum.FROID_COM_CONSO_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.froidComConsoHouilleKg = responseBody[HeaderEnum.FROID_COM_CONSO_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_HOUILLE_KG].replace(",", ".")) : 0;
      this.froidComConsoBoisPlaqIndustrieKg = responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.froidComConsoBoisPlaqForestKg = responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.froidComConsoBoisGranBriqKg = responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.froidComConsoBoisBuchesStere = responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.froidComConsoReseauChaleurKWh = responseBody[HeaderEnum.FROID_COM_CONSO_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.froidComConsoReseauFroidKWh = responseBody[HeaderEnum.FROID_COM_CONSO_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.froidComConsoGazoleNonRoutierLitre = responseBody[HeaderEnum.FROID_COM_CONSO_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.FROID_COM_CONSO_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
      this.conservationDocConsoElecKWh = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_ELEC_KWH] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_ELEC_KWH].replace(",", ".")) : 0;
      this.conservationDocConsoGazNatReseauxKWh = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_NAT_RESEAUX_KWH] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_NAT_RESEAUX_KWH].replace(",", ".")) : 0;
      this.conservationDocConsoGazNatLiquefieKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_NAT_LIQUEFIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_NAT_LIQUEFIE_KG].replace(",", ".")) : 0;
      this.conservationDocConsoGazPropaneKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_PROPANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_PROPANE_KG].replace(",", ".")) : 0;
      this.conservationDocConsoGazPropaneM3 = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_PROPANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_PROPANE_M3].replace(",", ".")) : 0;
      this.conservationDocConsoGazButaneKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_BUTANE_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_BUTANE_KG].replace(",", ".")) : 0;
      this.conservationDocConsoGazButaneM3 = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_BUTANE_M3] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_BUTANE_M3].replace(",", ".")) : 0;
      this.conservationDocConsoFioulDomestiLitre = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_FIOUL_DOMESTI_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_FIOUL_DOMESTI_LITRE].replace(",", ".")) : 0;
      this.conservationDocConsoCharbonAggloBriqKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_CHARBON_AGGLO_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_CHARBON_AGGLO_BRIQ_KG].replace(",", ".")) : 0;
      this.conservationDocConsoHouilleKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_HOUILLE_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_HOUILLE_KG].replace(",", ".")) : 0;
      this.conservationDocConsoBoisPlaqIndustrieKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_PLAQ_INDUSTRIE_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_PLAQ_INDUSTRIE_KG].replace(",", ".")) : 0;
      this.conservationDocConsoBoisPlaqForestKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_PLAQ_FOREST_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_PLAQ_FOREST_KG].replace(",", ".")) : 0;
      this.conservationDocConsoBoisGranBriqKg = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_GRAN_BRIQ_KG] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_GRAN_BRIQ_KG].replace(",", ".")) : 0;
      this.conservationDocConsoBoisBuchesStere = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_BUCHES_STERE] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_BUCHES_STERE].replace(",", ".")) : 0;
      this.conservationDocConsoReseauChaleurKWh = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_RESEAU_CHALEUR_KWH] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_RESEAU_CHALEUR_KWH].replace(",", ".")) : 0;
      this.conservationDocConsoReseauFroidKWh = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_RESEAU_FROID_KWH] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_RESEAU_FROID_KWH].replace(",", ".")) : 0;
      this.conservationDocConsoGazoleNonRoutierLitre = responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZOLE_NON_ROUTIER_LITRE] ? parseFloat(responseBody[HeaderEnum.CONSERVATION_DOC_CONSO_GAZOLE_NON_ROUTIER_LITRE].replace(",", ".")) : 0;
    } else {
      this.refOperatEfa = "";
      this.anneeDeclaree = "";
      this.dateDebutConsoReference = "";
      this.categorieActivitePrincipale = "";
      this.vehiElecNbBornes = 0;
      this.vehiElecConsoAvcIRVEKWh = 0;
      this.consoIndiHorsIRVEKWh = 0;
      this.consoIndiGazNatReseauxKWh = 0;
      this.consoIndiGazNatLiquefieKg = 0;
      this.consoIndiGazPropaneKg = 0;
      this.consoIndiGazPropaneM3 = 0;
      this.consoIndiGazButaneKg = 0;
      this.consoIndiGazButaneM3 = 0;
      this.consoIndiFioulDomestiLitre = 0;
      this.consoIndiCharbonAggloBriqKg = 0;
      this.consoIndiHouilleKg = 0;
      this.consoIndiBoisPlaqIndustrieKg = 0;
      this.consoIndiBoisPlaqForestKg = 0;
      this.consoIndiBoisGranBriqKg = 0;
      this.consoIndiBoisBuchesStere = 0;
      this.consoIndiReseauChaleurKWh = 0;
      this.consoIndiReseauFroidKWh = 0;
      this.consoIndiGazoleNonRoutierLitre = 0;
      this.consoRepHorsIRVEKWh = 0;
      this.consoRepGazNatReseauxKWh = 0;
      this.consoRepGazNatLiquefieKg = 0;
      this.consoRepGazPropaneKg = 0;
      this.consoRepGazPropaneM3 = 0;
      this.consoRepGazButaneKg = 0;
      this.consoRepGazButaneM3 = 0;
      this.consoRepFioulDomestiLitre = 0;
      this.consoRepCharbonAggloBriqKg = 0;
      this.consoRepHouilleKg = 0;
      this.consoRepBoisPlaqIndustrieKg = 0;
      this.consoRepBoisPlaqForestKg = 0;
      this.consoRepBoisGranBriqKg = 0;
      this.consoRepBoisBuchesStere = 0;
      this.consoRepReseauChaleurKWh = 0;
      this.consoRepReseauFroidKWh = 0;
      this.consoRepGazoleNonRoutierLitre = 0;
      this.consoComHorsIRVEKWh = 0;
      this.consoComGazNatReseauxKWh = 0;
      this.consoComGazNatLiquefieKg = 0;
      this.consoComGazPropaneKg = 0;
      this.consoComGazPropaneM3 = 0;
      this.consoComGazButaneKg = 0;
      this.consoComGazButaneM3 = 0;
      this.consoComFioulDomestiLitre = 0;
      this.consoComCharbonAggloBriqKg = 0;
      this.consoComHouilleKg = 0;
      this.consoComBoisPlaqIndustrieKg = 0;
      this.consoComBoisPlaqForestKg = 0;
      this.consoComBoisGranBriqKg = 0;
      this.consoComBoisBuchesStere = 0;
      this.consoComReseauChaleurKWh = 0;
      this.consoComReseauFroidKWh = 0;
      this.consoComGazoleNonRoutierLitre = 0;
      this.stationMeteoEfa = "";
      this.chauffageConsoElecKWh = 0;
      this.chauffageConsoGazNatReseauxKWh = 0;
      this.chauffageConsoGazNatLiquefieKg = 0;
      this.chauffageConsoGazPropaneKg = 0;
      this.chauffageConsoGazPropaneM3 = 0;
      this.chauffageConsoGazButaneKg = 0;
      this.chauffageConsoGazButaneM3 = 0;
      this.chauffageConsoFioulDomestiLitre = 0;
      this.chauffageConsoCharbonAggloBriqKg = 0;
      this.chauffageConsoHouilleKg = 0;
      this.chauffageConsoBoisPlaqIndustrieKg = 0;
      this.chauffageConsoBoisPlaqForestKg = 0;
      this.chauffageConsoBoisGranBriqKg = 0;
      this.chauffageConsoBoisBuchesStere = 0;
      this.chauffageConsoReseauChaleurKWh = 0;
      this.chauffageConsoReseauFroidKWh = 0;
      this.chauffageConsoGazoleNonRoutierLitre = 0;
      this.refroidissementConsoElecKWh = 0;
      this.refroidissementConsoGazNatReseauxKWh = 0;
      this.refroidissementConsoGazNatLiquefieKg = 0;
      this.refroidissementConsoGazPropaneKg = 0;
      this.refroidissementConsoGazPropaneM3 = 0;
      this.refroidissementConsoGazButaneKg = 0;
      this.refroidissementConsoGazButaneM3 = 0;
      this.refroidissementConsoFioulDomestiLitre = 0;
      this.refroidissementConsoCharbonAggloBriqKg = 0;
      this.refroidissementConsoHouilleKg = 0;
      this.refroidissementConsoBoisPlaqIndustrieKg = 0;
      this.refroidissementConsoBoisPlaqForestKg = 0;
      this.refroidissementConsoBoisGranBriqKg = 0;
      this.refroidissementConsoBoisBuchesStere = 0;
      this.refroidissementConsoReseauChaleurKWh = 0;
      this.refroidissementConsoReseauFroidKWh = 0;
      this.refroidissementConsoGazoleNonRoutierLitre = 0;
      this.froidLogistHauteurRefroidie = 0;
      this.froidLogistConsoElecKWh = 0;
      this.froidLogistConsoGazNatReseauxKWh = 0;
      this.froidLogistConsoGazNatLiquefieKg = 0;
      this.froidLogistConsoGazPropaneKg = 0;
      this.froidLogistConsoGazPropaneM3 = 0;
      this.froidLogistConsoGazButaneKg = 0;
      this.froidLogistConsoGazButaneM3 = 0;
      this.froidLogistConsoFioulDomestiLitre = 0;
      this.froidLogistConsoCharbonAggloBriqKg = 0;
      this.froidLogistConsoHouilleKg = 0;
      this.froidLogistConsoBoisPlaqIndustrieKg = 0;
      this.froidLogistConsoBoisPlaqForestKg = 0;
      this.froidLogistConsoBoisGranBriqKg = 0;
      this.froidLogistConsoBoisBuchesStere = 0;
      this.froidLogistConsoReseauChaleurKWh = 0;
      this.froidLogistConsoReseauFroidKWh = 0;
      this.froidLogistConsoGazoleNonRoutierLitre = 0;
      this.froidComConsoElecKWh = 0;
      this.froidComConsoGazNatReseauxKWh = 0;
      this.froidComConsoGazNatLiquefieKg = 0;
      this.froidComConsoGazPropaneKg = 0;
      this.froidComConsoGazPropaneM3 = 0;
      this.froidComConsoGazButaneKg = 0;
      this.froidComConsoGazButaneM3 = 0;
      this.froidComConsoFioulDomestiLitre = 0;
      this.froidComConsoCharbonAggloBriqKg = 0;
      this.froidComConsoHouilleKg = 0;
      this.froidComConsoBoisPlaqIndustrieKg = 0;
      this.froidComConsoBoisPlaqForestKg = 0;
      this.froidComConsoBoisGranBriqKg = 0;
      this.froidComConsoBoisBuchesStere = 0;
      this.froidComConsoReseauChaleurKWh = 0;
      this.froidComConsoReseauFroidKWh = 0;
      this.froidComConsoGazoleNonRoutierLitre = 0;
      this.conservationDocConsoElecKWh = 0;
      this.conservationDocConsoGazNatReseauxKWh = 0;
      this.conservationDocConsoGazNatLiquefieKg = 0;
      this.conservationDocConsoGazPropaneKg = 0;
      this.conservationDocConsoGazPropaneM3 = 0;
      this.conservationDocConsoGazButaneKg = 0;
      this.conservationDocConsoGazButaneM3 = 0;
      this.conservationDocConsoFioulDomestiLitre = 0;
      this.conservationDocConsoCharbonAggloBriqKg = 0;
      this.conservationDocConsoHouilleKg = 0;
      this.conservationDocConsoBoisPlaqIndustrieKg = 0;
      this.conservationDocConsoBoisPlaqForestKg = 0;
      this.conservationDocConsoBoisGranBriqKg = 0;
      this.conservationDocConsoBoisBuchesStere = 0;
      this.conservationDocConsoReseauChaleurKWh = 0;
      this.conservationDocConsoReseauFroidKWh = 0;
      this.conservationDocConsoGazoleNonRoutierLitre = 0;
    }
    this.activites = (responseBody?.activites ?? []).map(activity => new Activity(activity));
    this.indicateurs = (responseBody?.indicateurs ?? []).map(indicateurs => new IndicatorGroup(indicateurs));
  }
  serialize() {
    const result = {};
    result[HeaderEnum.REF_OPERAT_EFA] = this.refOperatEfa;
    result[HeaderEnum.ANNEE_DECLAREE] = `${this.anneeDeclaree}`;
    result[HeaderEnum.DATE_DEBUT_CONSO_REFERENCE] = this.dateDebutConsoReference;
    result[HeaderEnum.CATEGORIE_ACTIVITE_PRINCIPALE] = this.categorieActivitePrincipale;
    result[HeaderEnum.VEHI_ELEC_NB_BORNES] = this.vehiElecNbBornes > 0 ? `${this.vehiElecNbBornes}` : "";
    result[HeaderEnum.VEHI_ELEC_CONSO_AVC_IRVE_KWH] = this.vehiElecConsoAvcIRVEKWh > 0 ? `${this.vehiElecConsoAvcIRVEKWh}` : "";
    result[HeaderEnum.CONSO_INDI_HORS_IRVE_KWH] = this.consoIndiHorsIRVEKWh > 0 ? `${this.consoIndiHorsIRVEKWh}` : "";
    result[HeaderEnum.CONSO_INDI_GAZ_NAT_RESEAUX_KWH] = this.consoIndiGazNatReseauxKWh > 0 ? `${this.consoIndiGazNatReseauxKWh}` : "";
    result[HeaderEnum.CONSO_INDI_GAZ_NAT_LIQUEFIE_KG] = this.consoIndiGazNatLiquefieKg > 0 ? `${this.consoIndiGazNatLiquefieKg}` : "";
    result[HeaderEnum.CONSO_INDI_GAZ_PROPANE_KG] = this.consoIndiGazPropaneKg > 0 ? `${this.consoIndiGazPropaneKg}` : "";
    result[HeaderEnum.CONSO_INDI_GAZ_PROPANE_M3] = this.consoIndiGazPropaneM3 > 0 ? `${this.consoIndiGazPropaneM3}` : "";
    result[HeaderEnum.CONSO_INDI_GAZ_BUTANE_KG] = this.consoIndiGazButaneKg > 0 ? `${this.consoIndiGazButaneKg}` : "";
    result[HeaderEnum.CONSO_INDI_GAZ_BUTANE_M3] = this.consoIndiGazButaneM3 > 0 ? `${this.consoIndiGazButaneM3}` : "";
    result[HeaderEnum.CONSO_INDI_FIOUL_DOMESTI_LITRE] = this.consoIndiFioulDomestiLitre > 0 ? `${this.consoIndiFioulDomestiLitre}` : "";
    result[HeaderEnum.CONSO_INDI_CHARBON_AGGLO_BRIQ_KG] = this.consoIndiCharbonAggloBriqKg > 0 ? `${this.consoIndiCharbonAggloBriqKg}` : "";
    result[HeaderEnum.CONSO_INDI_HOUILLE_KG] = this.consoIndiHouilleKg > 0 ? `${this.consoIndiHouilleKg}` : "";
    result[HeaderEnum.CONSO_INDI_BOIS_PLAQ_INDUSTRIE_KG] = this.consoIndiBoisPlaqIndustrieKg > 0 ? `${this.consoIndiBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.CONSO_INDI_BOIS_PLAQ_FOREST_KG] = this.consoIndiBoisPlaqForestKg > 0 ? `${this.consoIndiBoisPlaqForestKg}` : "";
    result[HeaderEnum.CONSO_INDI_BOIS_GRAN_BRIQ_KG] = this.consoIndiBoisGranBriqKg > 0 ? `${this.consoIndiBoisGranBriqKg}` : "";
    result[HeaderEnum.CONSO_INDI_BOIS_BUCHES_STERE] = this.consoIndiBoisBuchesStere > 0 ? `${this.consoIndiBoisBuchesStere}` : "";
    result[HeaderEnum.CONSO_INDI_RESEAU_CHALEUR_KWH] = this.consoIndiReseauChaleurKWh > 0 ? `${this.consoIndiReseauChaleurKWh}` : "";
    result[HeaderEnum.CONSO_INDI_RESEAU_FROID_KWH] = this.consoIndiReseauFroidKWh > 0 ? `${this.consoIndiReseauFroidKWh}` : "";
    result[HeaderEnum.CONSO_INDI_GAZOLE_NON_ROUTIER_LITRE] = this.consoIndiGazoleNonRoutierLitre > 0 ? `${this.consoIndiGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.CONSO_REP_HORS_IRVE_KWH] = this.consoRepHorsIRVEKWh > 0 ? `${this.consoRepHorsIRVEKWh}` : "";
    result[HeaderEnum.CONSO_REP_GAZ_NAT_RESEAUX_KWH] = this.consoRepGazNatReseauxKWh > 0 ? `${this.consoRepGazNatReseauxKWh}` : "";
    result[HeaderEnum.CONSO_REP_GAZ_NAT_LIQUEFIE_KG] = this.consoRepGazNatLiquefieKg > 0 ? `${this.consoRepGazNatLiquefieKg}` : "";
    result[HeaderEnum.CONSO_REP_GAZ_PROPANE_KG] = this.consoRepGazPropaneKg > 0 ? `${this.consoRepGazPropaneKg}` : "";
    result[HeaderEnum.CONSO_REP_GAZ_PROPANE_M3] = this.consoRepGazPropaneM3 > 0 ? `${this.consoRepGazPropaneM3}` : "";
    result[HeaderEnum.CONSO_REP_GAZ_BUTANE_KG] = this.consoRepGazButaneKg > 0 ? `${this.consoRepGazButaneKg}` : "";
    result[HeaderEnum.CONSO_REP_GAZ_BUTANE_M3] = this.consoRepGazButaneM3 > 0 ? `${this.consoRepGazButaneM3}` : "";
    result[HeaderEnum.CONSO_REP_FIOUL_DOMESTI_LITRE] = this.consoRepFioulDomestiLitre > 0 ? `${this.consoRepFioulDomestiLitre}` : "";
    result[HeaderEnum.CONSO_REP_CHARBON_AGGLO_BRIQ_KG] = this.consoRepCharbonAggloBriqKg > 0 ? `${this.consoRepCharbonAggloBriqKg}` : "";
    result[HeaderEnum.CONSO_REP_HOUILLE_KG] = this.consoRepHouilleKg > 0 ? `${this.consoRepHouilleKg}` : "";
    result[HeaderEnum.CONSO_REP_BOIS_PLAQ_INDUSTRIE_KG] = this.consoRepBoisPlaqIndustrieKg > 0 ? `${this.consoRepBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.CONSO_REP_BOIS_PLAQ_FOREST_KG] = this.consoRepBoisPlaqForestKg > 0 ? `${this.consoRepBoisPlaqForestKg}` : "";
    result[HeaderEnum.CONSO_REP_BOIS_GRAN_BRIQ_KG] = this.consoRepBoisGranBriqKg > 0 ? `${this.consoRepBoisGranBriqKg}` : "";
    result[HeaderEnum.CONSO_REP_BOIS_BUCHES_STERE] = this.consoRepBoisBuchesStere > 0 ? `${this.consoRepBoisBuchesStere}` : "";
    result[HeaderEnum.CONSO_REP_RESEAU_CHALEUR_KWH] = this.consoRepReseauChaleurKWh > 0 ? `${this.consoRepReseauChaleurKWh}` : "";
    result[HeaderEnum.CONSO_REP_RESEAU_FROID_KWH] = this.consoRepReseauFroidKWh > 0 ? `${this.consoRepReseauFroidKWh}` : "";
    result[HeaderEnum.CONSO_REP_GAZOLE_NON_ROUTIER_LITRE] = this.consoRepGazoleNonRoutierLitre > 0 ? `${this.consoRepGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.CONSO_COM_HORS_IRVE_KWH] = this.consoComHorsIRVEKWh > 0 ? `${this.consoComHorsIRVEKWh}` : "";
    result[HeaderEnum.CONSO_COM_GAZ_NAT_RESEAUX_KWH] = this.consoComGazNatReseauxKWh > 0 ? `${this.consoComGazNatReseauxKWh}` : "";
    result[HeaderEnum.CONSO_COM_GAZ_NAT_LIQUEFIE_KG] = this.consoComGazNatLiquefieKg > 0 ? `${this.consoComGazNatLiquefieKg}` : "";
    result[HeaderEnum.CONSO_COM_GAZ_PROPANE_KG] = this.consoComGazPropaneKg > 0 ? `${this.consoComGazPropaneKg}` : "";
    result[HeaderEnum.CONSO_COM_GAZ_PROPANE_M3] = this.consoComGazPropaneM3 > 0 ? `${this.consoComGazPropaneM3}` : "";
    result[HeaderEnum.CONSO_COM_GAZ_BUTANE_KG] = this.consoComGazButaneKg > 0 ? `${this.consoComGazButaneKg}` : "";
    result[HeaderEnum.CONSO_COM_GAZ_BUTANE_M3] = this.consoComGazButaneM3 > 0 ? `${this.consoComGazButaneM3}` : "";
    result[HeaderEnum.CONSO_COM_FIOUL_DOMESTI_LITRE] = this.consoComFioulDomestiLitre > 0 ? `${this.consoComFioulDomestiLitre}` : "";
    result[HeaderEnum.CONSO_COM_CHARBON_AGGLO_BRIQ_KG] = this.consoComCharbonAggloBriqKg > 0 ? `${this.consoComCharbonAggloBriqKg}` : "";
    result[HeaderEnum.CONSO_COM_HOUILLE_KG] = this.consoComHouilleKg > 0 ? `${this.consoComHouilleKg}` : "";
    result[HeaderEnum.CONSO_COM_BOIS_PLAQ_INDUSTRIE_KG] = this.consoComBoisPlaqIndustrieKg > 0 ? `${this.consoComBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.CONSO_COM_BOIS_PLAQ_FOREST_KG] = this.consoComBoisPlaqForestKg > 0 ? `${this.consoComBoisPlaqForestKg}` : "";
    result[HeaderEnum.CONSO_COM_BOIS_GRAN_BRIQ_KG] = this.consoComBoisGranBriqKg > 0 ? `${this.consoComBoisGranBriqKg}` : "";
    result[HeaderEnum.CONSO_COM_BOIS_BUCHES_STERE] = this.consoComBoisBuchesStere > 0 ? `${this.consoComBoisBuchesStere}` : "";
    result[HeaderEnum.CONSO_COM_RESEAU_CHALEUR_KWH] = this.consoComReseauChaleurKWh > 0 ? `${this.consoComReseauChaleurKWh}` : "";
    result[HeaderEnum.CONSO_COM_RESEAU_FROID_KWH] = this.consoComReseauFroidKWh > 0 ? `${this.consoComReseauFroidKWh}` : "";
    result[HeaderEnum.CONSO_COM_GAZOLE_NON_ROUTIER_LITRE] = this.consoComGazoleNonRoutierLitre > 0 ? `${this.consoComGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.STATION_METEO_EFA] = this.stationMeteoEfa;
    result[HeaderEnum.CHAUFFAGE_CONSO_ELEC_KWH] = this.chauffageConsoElecKWh > 0 ? `${this.chauffageConsoElecKWh}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZ_NAT_RESEAUX_KWH] = this.chauffageConsoGazNatReseauxKWh > 0 ? `${this.chauffageConsoGazNatReseauxKWh}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZ_NAT_LIQUEFIE_KG] = this.chauffageConsoGazNatLiquefieKg > 0 ? `${this.chauffageConsoGazNatLiquefieKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZ_PROPANE_KG] = this.chauffageConsoGazPropaneKg > 0 ? `${this.chauffageConsoGazPropaneKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZ_PROPANE_M3] = this.chauffageConsoGazPropaneM3 > 0 ? `${this.chauffageConsoGazPropaneM3}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZ_BUTANE_KG] = this.chauffageConsoGazButaneKg > 0 ? `${this.chauffageConsoGazButaneKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZ_BUTANE_M3] = this.chauffageConsoGazButaneM3 > 0 ? `${this.chauffageConsoGazButaneM3}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_FIOUL_DOMESTI_LITRE] = this.chauffageConsoFioulDomestiLitre > 0 ? `${this.chauffageConsoFioulDomestiLitre}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_CHARBON_AGGLO_BRIQ_KG] = this.chauffageConsoCharbonAggloBriqKg > 0 ? `${this.chauffageConsoCharbonAggloBriqKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_HOUILLE_KG] = this.chauffageConsoHouilleKg > 0 ? `${this.chauffageConsoHouilleKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_BOIS_PLAQ_INDUSTRIE_KG] = this.chauffageConsoBoisPlaqIndustrieKg > 0 ? `${this.chauffageConsoBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_BOIS_PLAQ_FOREST_KG] = this.chauffageConsoBoisPlaqForestKg > 0 ? `${this.chauffageConsoBoisPlaqForestKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_BOIS_GRAN_BRIQ_KG] = this.chauffageConsoBoisGranBriqKg > 0 ? `${this.chauffageConsoBoisGranBriqKg}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_BOIS_BUCHES_STERE] = this.chauffageConsoBoisBuchesStere > 0 ? `${this.chauffageConsoBoisBuchesStere}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_RESEAU_CHALEUR_KWH] = this.chauffageConsoReseauChaleurKWh > 0 ? `${this.chauffageConsoReseauChaleurKWh}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_RESEAU_FROID_KWH] = this.chauffageConsoReseauFroidKWh > 0 ? `${this.chauffageConsoReseauFroidKWh}` : "";
    result[HeaderEnum.CHAUFFAGE_CONSO_GAZOLE_NON_ROUTIER_LITRE] = this.chauffageConsoGazoleNonRoutierLitre > 0 ? `${this.chauffageConsoGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_ELEC_KWH] = this.refroidissementConsoElecKWh > 0 ? `${this.refroidissementConsoElecKWh}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_NAT_RESEAUX_KWH] = this.refroidissementConsoGazNatReseauxKWh > 0 ? `${this.refroidissementConsoGazNatReseauxKWh}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_NAT_LIQUEFIE_KG] = this.refroidissementConsoGazNatLiquefieKg > 0 ? `${this.refroidissementConsoGazNatLiquefieKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_PROPANE_KG] = this.refroidissementConsoGazPropaneKg > 0 ? `${this.refroidissementConsoGazPropaneKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_PROPANE_M3] = this.refroidissementConsoGazPropaneM3 > 0 ? `${this.refroidissementConsoGazPropaneM3}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_BUTANE_KG] = this.refroidissementConsoGazButaneKg > 0 ? `${this.refroidissementConsoGazButaneKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZ_BUTANE_M3] = this.refroidissementConsoGazButaneM3 > 0 ? `${this.refroidissementConsoGazButaneM3}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_FIOUL_DOMESTI_LITRE] = this.refroidissementConsoFioulDomestiLitre > 0 ? `${this.refroidissementConsoFioulDomestiLitre}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_CHARBON_AGGLO_BRIQ_KG] = this.refroidissementConsoCharbonAggloBriqKg > 0 ? `${this.refroidissementConsoCharbonAggloBriqKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_HOUILLE_KG] = this.refroidissementConsoHouilleKg > 0 ? `${this.refroidissementConsoHouilleKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_PLAQ_INDUSTRIE_KG] = this.refroidissementConsoBoisPlaqIndustrieKg > 0 ? `${this.refroidissementConsoBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_PLAQ_FOREST_KG] = this.refroidissementConsoBoisPlaqForestKg > 0 ? `${this.refroidissementConsoBoisPlaqForestKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_GRAN_BRIQ_KG] = this.refroidissementConsoBoisGranBriqKg > 0 ? `${this.refroidissementConsoBoisGranBriqKg}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_BOIS_BUCHES_STERE] = this.refroidissementConsoBoisBuchesStere > 0 ? `${this.refroidissementConsoBoisBuchesStere}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_RESEAU_CHALEUR_KWH] = this.refroidissementConsoReseauChaleurKWh > 0 ? `${this.refroidissementConsoReseauChaleurKWh}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_RESEAU_FROID_KWH] = this.refroidissementConsoReseauFroidKWh > 0 ? `${this.refroidissementConsoReseauFroidKWh}` : "";
    result[HeaderEnum.REFROIDISSEMENT_CONSO_GAZOLE_NON_ROUTIER_LITRE] = this.refroidissementConsoGazoleNonRoutierLitre > 0 ? `${this.refroidissementConsoGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.FROID_LOGIST_HAUTEUR_REFROIDIE] = this.froidLogistHauteurRefroidie > 0 ? `${this.froidLogistHauteurRefroidie}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_ELEC_KWH] = this.froidLogistConsoElecKWh > 0 ? `${this.froidLogistConsoElecKWh}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZ_NAT_RESEAUX_KWH] = this.froidLogistConsoGazNatReseauxKWh > 0 ? `${this.froidLogistConsoGazNatReseauxKWh}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZ_NAT_LIQUEFIE_KG] = this.froidLogistConsoGazNatLiquefieKg > 0 ? `${this.froidLogistConsoGazNatLiquefieKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZ_PROPANE_KG] = this.froidLogistConsoGazPropaneKg > 0 ? `${this.froidLogistConsoGazPropaneKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZ_PROPANE_M3] = this.froidLogistConsoGazPropaneM3 > 0 ? `${this.froidLogistConsoGazPropaneM3}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZ_BUTANE_KG] = this.froidLogistConsoGazButaneKg > 0 ? `${this.froidLogistConsoGazButaneKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZ_BUTANE_M3] = this.froidLogistConsoGazButaneM3 > 0 ? `${this.froidLogistConsoGazButaneM3}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_FIOUL_DOMESTI_LITRE] = this.froidLogistConsoFioulDomestiLitre > 0 ? `${this.froidLogistConsoFioulDomestiLitre}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_CHARBON_AGGLO_BRIQ_KG] = this.froidLogistConsoCharbonAggloBriqKg > 0 ? `${this.froidLogistConsoCharbonAggloBriqKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_HOUILLE_KG] = this.froidLogistConsoHouilleKg > 0 ? `${this.froidLogistConsoHouilleKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_BOIS_PLAQ_INDUSTRIE_KG] = this.froidLogistConsoBoisPlaqIndustrieKg > 0 ? `${this.froidLogistConsoBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_BOIS_PLAQ_FOREST_KG] = this.froidLogistConsoBoisPlaqForestKg > 0 ? `${this.froidLogistConsoBoisPlaqForestKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_BOIS_GRAN_BRIQ_KG] = this.froidLogistConsoBoisGranBriqKg > 0 ? `${this.froidLogistConsoBoisGranBriqKg}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_BOIS_BUCHES_STERE] = this.froidLogistConsoBoisBuchesStere > 0 ? `${this.froidLogistConsoBoisBuchesStere}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_RESEAU_CHALEUR_KWH] = this.froidLogistConsoReseauChaleurKWh > 0 ? `${this.froidLogistConsoReseauChaleurKWh}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_RESEAU_FROID_KWH] = this.froidLogistConsoReseauFroidKWh > 0 ? `${this.froidLogistConsoReseauFroidKWh}` : "";
    result[HeaderEnum.FROID_LOGIST_CONSO_GAZOLE_NON_ROUTIER_LITRE] = this.froidLogistConsoGazoleNonRoutierLitre > 0 ? `${this.froidLogistConsoGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.FROID_COM_CONSO_ELEC_KWH] = this.froidComConsoElecKWh > 0 ? `${this.froidComConsoElecKWh}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZ_NAT_RESEAUX_KWH] = this.froidComConsoGazNatReseauxKWh > 0 ? `${this.froidComConsoGazNatReseauxKWh}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZ_NAT_LIQUEFIE_KG] = this.froidComConsoGazNatLiquefieKg > 0 ? `${this.froidComConsoGazNatLiquefieKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZ_PROPANE_KG] = this.froidComConsoGazPropaneKg > 0 ? `${this.froidComConsoGazPropaneKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZ_PROPANE_M3] = this.froidComConsoGazPropaneM3 > 0 ? `${this.froidComConsoGazPropaneM3}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZ_BUTANE_KG] = this.froidComConsoGazButaneKg > 0 ? `${this.froidComConsoGazButaneKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZ_BUTANE_M3] = this.froidComConsoGazButaneM3 > 0 ? `${this.froidComConsoGazButaneM3}` : "";
    result[HeaderEnum.FROID_COM_CONSO_FIOUL_DOMESTI_LITRE] = this.froidComConsoFioulDomestiLitre > 0 ? `${this.froidComConsoFioulDomestiLitre}` : "";
    result[HeaderEnum.FROID_COM_CONSO_CHARBON_AGGLO_BRIQ_KG] = this.froidComConsoCharbonAggloBriqKg > 0 ? `${this.froidComConsoCharbonAggloBriqKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_HOUILLE_KG] = this.froidComConsoHouilleKg > 0 ? `${this.froidComConsoHouilleKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_BOIS_PLAQ_INDUSTRIE_KG] = this.froidComConsoBoisPlaqIndustrieKg > 0 ? `${this.froidComConsoBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_BOIS_PLAQ_FOREST_KG] = this.froidComConsoBoisPlaqForestKg > 0 ? `${this.froidComConsoBoisPlaqForestKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_BOIS_GRAN_BRIQ_KG] = this.froidComConsoBoisGranBriqKg > 0 ? `${this.froidComConsoBoisGranBriqKg}` : "";
    result[HeaderEnum.FROID_COM_CONSO_BOIS_BUCHES_STERE] = this.froidComConsoBoisBuchesStere > 0 ? `${this.froidComConsoBoisBuchesStere}` : "";
    result[HeaderEnum.FROID_COM_CONSO_RESEAU_CHALEUR_KWH] = this.froidComConsoReseauChaleurKWh > 0 ? `${this.froidComConsoReseauChaleurKWh}` : "";
    result[HeaderEnum.FROID_COM_CONSO_RESEAU_FROID_KWH] = this.froidComConsoReseauFroidKWh > 0 ? `${this.froidComConsoReseauFroidKWh}` : "";
    result[HeaderEnum.FROID_COM_CONSO_GAZOLE_NON_ROUTIER_LITRE] = this.froidComConsoGazoleNonRoutierLitre > 0 ? `${this.froidComConsoGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_ELEC_KWH] = this.conservationDocConsoElecKWh > 0 ? `${this.conservationDocConsoElecKWh}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_NAT_RESEAUX_KWH] = this.conservationDocConsoGazNatReseauxKWh > 0 ? `${this.conservationDocConsoGazNatReseauxKWh}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_NAT_LIQUEFIE_KG] = this.conservationDocConsoGazNatLiquefieKg > 0 ? `${this.conservationDocConsoGazNatLiquefieKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_PROPANE_KG] = this.conservationDocConsoGazPropaneKg > 0 ? `${this.conservationDocConsoGazPropaneKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_PROPANE_M3] = this.conservationDocConsoGazPropaneM3 > 0 ? `${this.conservationDocConsoGazPropaneM3}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_BUTANE_KG] = this.conservationDocConsoGazButaneKg > 0 ? `${this.conservationDocConsoGazButaneKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZ_BUTANE_M3] = this.conservationDocConsoGazButaneM3 > 0 ? `${this.conservationDocConsoGazButaneM3}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_FIOUL_DOMESTI_LITRE] = this.conservationDocConsoFioulDomestiLitre > 0 ? `${this.conservationDocConsoFioulDomestiLitre}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_CHARBON_AGGLO_BRIQ_KG] = this.conservationDocConsoCharbonAggloBriqKg > 0 ? `${this.conservationDocConsoCharbonAggloBriqKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_HOUILLE_KG] = this.conservationDocConsoHouilleKg > 0 ? `${this.conservationDocConsoHouilleKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_PLAQ_INDUSTRIE_KG] = this.conservationDocConsoBoisPlaqIndustrieKg > 0 ? `${this.conservationDocConsoBoisPlaqIndustrieKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_PLAQ_FOREST_KG] = this.conservationDocConsoBoisPlaqForestKg > 0 ? `${this.conservationDocConsoBoisPlaqForestKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_GRAN_BRIQ_KG] = this.conservationDocConsoBoisGranBriqKg > 0 ? `${this.conservationDocConsoBoisGranBriqKg}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_BOIS_BUCHES_STERE] = this.conservationDocConsoBoisBuchesStere > 0 ? `${this.conservationDocConsoBoisBuchesStere}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_RESEAU_CHALEUR_KWH] = this.conservationDocConsoReseauChaleurKWh > 0 ? `${this.conservationDocConsoReseauChaleurKWh}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_RESEAU_FROID_KWH] = this.conservationDocConsoReseauFroidKWh > 0 ? `${this.conservationDocConsoReseauFroidKWh}` : "";
    result[HeaderEnum.CONSERVATION_DOC_CONSO_GAZOLE_NON_ROUTIER_LITRE] = this.conservationDocConsoGazoleNonRoutierLitre > 0 ? `${this.conservationDocConsoGazoleNonRoutierLitre}` : "";
    result[HeaderEnum.ACTIVITES] = this.activites.map(activites => activites.serialize());
    result[HeaderEnum.INDICATEURS] = this.indicateurs.map(indicateur => indicateur.serialize());
    return result;
  }
  getConsoValues() {
    const result = {};
    result[HeaderEnum.CONSO_INDI_HORS_IRVE_KWH] = this.consoIndiHorsIRVEKWh;
    result[HeaderEnum.CONSO_INDI_GAZ_NAT_RESEAUX_KWH] = this.consoIndiGazNatReseauxKWh;
    result[HeaderEnum.CONSO_INDI_GAZ_NAT_LIQUEFIE_KG] = this.consoIndiGazNatLiquefieKg;
    result[HeaderEnum.CONSO_INDI_GAZ_PROPANE_KG] = this.consoIndiGazPropaneKg;
    result[HeaderEnum.CONSO_INDI_GAZ_PROPANE_M3] = this.consoIndiGazPropaneM3;
    result[HeaderEnum.CONSO_INDI_GAZ_BUTANE_KG] = this.consoIndiGazButaneKg;
    result[HeaderEnum.CONSO_INDI_GAZ_BUTANE_M3] = this.consoIndiGazButaneM3;
    result[HeaderEnum.CONSO_INDI_FIOUL_DOMESTI_LITRE] = this.consoIndiFioulDomestiLitre;
    result[HeaderEnum.CONSO_INDI_CHARBON_AGGLO_BRIQ_KG] = this.consoIndiCharbonAggloBriqKg;
    result[HeaderEnum.CONSO_INDI_HOUILLE_KG] = this.consoIndiHouilleKg;
    result[HeaderEnum.CONSO_INDI_BOIS_PLAQ_INDUSTRIE_KG] = this.consoIndiBoisPlaqIndustrieKg;
    result[HeaderEnum.CONSO_INDI_BOIS_PLAQ_FOREST_KG] = this.consoIndiBoisPlaqForestKg;
    result[HeaderEnum.CONSO_INDI_BOIS_GRAN_BRIQ_KG] = this.consoIndiBoisGranBriqKg;
    result[HeaderEnum.CONSO_INDI_BOIS_BUCHES_STERE] = this.consoIndiBoisBuchesStere;
    result[HeaderEnum.CONSO_INDI_RESEAU_CHALEUR_KWH] = this.consoIndiReseauChaleurKWh;
    result[HeaderEnum.CONSO_INDI_RESEAU_FROID_KWH] = this.consoIndiReseauFroidKWh;
    result[HeaderEnum.CONSO_INDI_GAZOLE_NON_ROUTIER_LITRE] = this.consoIndiGazoleNonRoutierLitre;
    result[HeaderEnum.CONSO_REP_HORS_IRVE_KWH] = this.consoRepHorsIRVEKWh;
    result[HeaderEnum.CONSO_REP_GAZ_NAT_RESEAUX_KWH] = this.consoRepGazNatReseauxKWh;
    result[HeaderEnum.CONSO_REP_GAZ_NAT_LIQUEFIE_KG] = this.consoRepGazNatLiquefieKg;
    result[HeaderEnum.CONSO_REP_GAZ_PROPANE_KG] = this.consoRepGazPropaneKg;
    result[HeaderEnum.CONSO_REP_GAZ_PROPANE_M3] = this.consoRepGazPropaneM3;
    result[HeaderEnum.CONSO_REP_GAZ_BUTANE_KG] = this.consoRepGazButaneKg;
    result[HeaderEnum.CONSO_REP_GAZ_BUTANE_M3] = this.consoRepGazButaneM3;
    result[HeaderEnum.CONSO_REP_FIOUL_DOMESTI_LITRE] = this.consoRepFioulDomestiLitre;
    result[HeaderEnum.CONSO_REP_CHARBON_AGGLO_BRIQ_KG] = this.consoRepCharbonAggloBriqKg;
    result[HeaderEnum.CONSO_REP_HOUILLE_KG] = this.consoRepHouilleKg;
    result[HeaderEnum.CONSO_REP_BOIS_PLAQ_INDUSTRIE_KG] = this.consoRepBoisPlaqIndustrieKg;
    result[HeaderEnum.CONSO_REP_BOIS_PLAQ_FOREST_KG] = this.consoRepBoisPlaqForestKg;
    result[HeaderEnum.CONSO_REP_BOIS_GRAN_BRIQ_KG] = this.consoRepBoisGranBriqKg;
    result[HeaderEnum.CONSO_REP_BOIS_BUCHES_STERE] = this.consoRepBoisBuchesStere;
    result[HeaderEnum.CONSO_REP_RESEAU_CHALEUR_KWH] = this.consoRepReseauChaleurKWh;
    result[HeaderEnum.CONSO_REP_RESEAU_FROID_KWH] = this.consoRepReseauFroidKWh;
    result[HeaderEnum.CONSO_REP_GAZOLE_NON_ROUTIER_LITRE] = this.consoRepGazoleNonRoutierLitre;
    result[HeaderEnum.CONSO_COM_HORS_IRVE_KWH] = this.consoComHorsIRVEKWh;
    result[HeaderEnum.CONSO_COM_GAZ_NAT_RESEAUX_KWH] = this.consoComGazNatReseauxKWh;
    result[HeaderEnum.CONSO_COM_GAZ_NAT_LIQUEFIE_KG] = this.consoComGazNatLiquefieKg;
    result[HeaderEnum.CONSO_COM_GAZ_PROPANE_KG] = this.consoComGazPropaneKg;
    result[HeaderEnum.CONSO_COM_GAZ_PROPANE_M3] = this.consoComGazPropaneM3;
    result[HeaderEnum.CONSO_COM_GAZ_BUTANE_KG] = this.consoComGazButaneKg;
    result[HeaderEnum.CONSO_COM_GAZ_BUTANE_M3] = this.consoComGazButaneM3;
    result[HeaderEnum.CONSO_COM_FIOUL_DOMESTI_LITRE] = this.consoComFioulDomestiLitre;
    result[HeaderEnum.CONSO_COM_CHARBON_AGGLO_BRIQ_KG] = this.consoComCharbonAggloBriqKg;
    result[HeaderEnum.CONSO_COM_HOUILLE_KG] = this.consoComHouilleKg;
    result[HeaderEnum.CONSO_COM_BOIS_PLAQ_INDUSTRIE_KG] = this.consoComBoisPlaqIndustrieKg;
    result[HeaderEnum.CONSO_COM_BOIS_PLAQ_FOREST_KG] = this.consoComBoisPlaqForestKg;
    result[HeaderEnum.CONSO_COM_BOIS_GRAN_BRIQ_KG] = this.consoComBoisGranBriqKg;
    result[HeaderEnum.CONSO_COM_BOIS_BUCHES_STERE] = this.consoComBoisBuchesStere;
    result[HeaderEnum.CONSO_COM_RESEAU_CHALEUR_KWH] = this.consoComReseauChaleurKWh;
    result[HeaderEnum.CONSO_COM_RESEAU_FROID_KWH] = this.consoComReseauFroidKWh;
    result[HeaderEnum.CONSO_COM_GAZOLE_NON_ROUTIER_LITRE] = this.consoComGazoleNonRoutierLitre;
    return result;
  }
}
