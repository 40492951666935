import { formatDate } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { EnergyType, getDeliverables } from "src/app/models/energyType";
import { EnergyConsumptions, FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { z, ZodDate } from "zod";
import { Nullable } from "../../../../../../../helpers/nullable";
import { AgreementAsk } from "../../../../../../../models/agreementAsk";
import { Declaration } from "../../../../../../../models/declaration";
import { EnergyCategory, getEnergyCategoriesToAdd } from "../../../../../../../models/energyCategory";
import { AgreementForSubCategoryPipe } from "../../../../../../../pipes/agreement-for-sub-category.pipe";
import { DateToTimestampPipe } from "../../../../../../../pipes/date-to-timestamp.pipe";
import { periodRange, PeriodRangePipe } from "../../../../../../../pipes/period-range.pipe";
import { ZodSafeParsePipe } from "../../../../../../../pipes/zod-safe-parse.pipe";
import { ConfirmationModalComponent } from "../../../../../../confirmation-modal/confirmation-modal.component";
import { HelpSubject } from "../../../../../../help/help.component";
import { EditUmpteenthModalComponent } from "./edit-umpteenth-modal/edit-umpteenth-modal.component";

export const TODAY = new Date();
const dateToTimestamp = new DateToTimestampPipe();
const zodSafeParse = new ZodSafeParsePipe();

@Component({
	selector: "app-add-row-modal",
	templateUrl: "./add-row-modal.component.html",
	styleUrls: ["./add-row-modal.component.scss"],
})
export class AddRowModalComponent {
	readonly START = new Date(Declaration.MINIMUM_DATE_TIME * 1000);
	readonly TODAY = TODAY;

	readonly deductableCategories = getEnergyCategoriesToAdd(true);

	readonly DELIVERABLE = getDeliverables();

	zodCheckDateStart: ZodDate;
	startErrorMessage: string = "";

	row: {
		label: string;
		values: {
			[key: string]: EnergyConsumptions;
		};
	};
	initialRow: Immutable<{
		label: string;
		values: {
			[key: string]: EnergyConsumptions;
		};
	}>;
	adding: boolean;
	energyTypeAvailable: EnergyType[] = [];

	energyTypes: EnergyType[] = [];

	isDeduct: boolean = false;
	sharedBuilding: boolean = false;

	agreement?: AgreementAsk;

	HelpSubject = HelpSubject;
	functionalDeclarationStartingDate: Nullable<Date>;
	favoritePeriod: number;

	energyCategory: EnergyCategory;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			functionalDeclaration: FunctionalDeclaration;
			rowName?: string;
			row: {
				label: string;
				values: {
					[key: string]: EnergyConsumptions;
				};
			};
			adding: boolean;
			energyType: Immutable<EnergyType[]>;
			isDeduct: boolean;
			sharedBuilding: boolean;
			energyCategory: EnergyCategory;
		},
		private dialogRef: MatDialogRef<AddRowModalComponent>,
		private dialog: MatDialog,
	) {
		this.energyCategory = data.energyCategory;
		const agreementForSubCategoryPipe = new AgreementForSubCategoryPipe();
		if (data.rowName) {
			this.agreement = agreementForSubCategoryPipe.transform(
				data.rowName,
				data.functionalDeclaration[GreenKeys.KEY_AGREEMENT_ASKS],
			);
		}

		if (data.functionalDeclaration.first_year_declaration) {
			const periodRange = new PeriodRangePipe();
			const { start } = periodRange.transform(
				data.functionalDeclaration.first_year_declaration,
				data.functionalDeclaration[GreenKeys.KEY_INFOS].favoritePeriod,
			);
			this.functionalDeclarationStartingDate = new Date(dateToTimestamp.transform(start) * 1000);
			this.functionalDeclarationStartingDate.setHours(0, 0);
		}

		this.isDeduct = data.isDeduct;
		this.sharedBuilding = data.sharedBuilding;
		this.initialRow = data.row;
		const values: {
			[key: string]: EnergyConsumptions;
		} = {};
		Object.keys(data.row.values).map((energyType) => {
			values[energyType] = new EnergyConsumptions(data.row.values[energyType]);
		});
		this.row = { ...data.row, values };
		this.adding = data.adding;

		if (this.isDeduct) {
			Object.keys(this.row.values).forEach((energy) => {
				if (this.row.values[energy].deductTo === undefined) {
					this.row.values[energy].deductTo = EnergyCategory.INDIVIDUAL;
				}
			});
		}

		this.energyTypeAvailable = [...data.energyType];

		Object.keys(this.row.values).forEach((k) => this.energyTypes.push(k as EnergyType));

		let startDate = "2009-01-01";
		let errorMessage = "Vous ne pouvez pas déclarer de donnée avant 2009";
		this.favoritePeriod = data.functionalDeclaration[GreenKeys.KEY_INFOS].favoritePeriod;
		if (data.functionalDeclaration.first_year_declaration) {
			const { start } = periodRange(data.functionalDeclaration.first_year_declaration, this.favoritePeriod);
			startDate = start;
			errorMessage = "Vous ne pouvez pas déclarer de données avant votre date d'assujettissement";
		}

		this.zodCheckDateStart = z.date().min(new Date(startDate), { message: errorMessage });
	}

	updateEnergyInRow() {
		const formatDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		this.energyTypes.forEach((energyType) => {
			if (this.row.values[energyType] === undefined) {
				this.row.values[energyType] = new EnergyConsumptions({
					byInvoice: this.DELIVERABLE.includes(energyType),
					start: this.functionalDeclarationStartingDate
						? dateToTimestamp.transform(formatDate(this.functionalDeclarationStartingDate))
						: Declaration.MINIMUM_DATE_TIME,
				});
			}
		});
		Object.keys(this.row.values).forEach((energyType) => {
			if (!this.energyTypes.includes(energyType as EnergyType)) {
				delete this.row.values[energyType];
			}
		});
	}

	onStartDateChange(date: string, row: EnergyConsumptions) {
		this.startErrorMessage = zodSafeParse.transform(new Date(date), this.zodCheckDateStart);
		const newDate = dateToTimestamp.transform(date);
		// If date is valid -> update row date
		if (!this.startErrorMessage) {
			row.start = newDate;
		}
	}

	onEndDateChange(date: string, row: EnergyConsumptions) {
		if (date === "") {
			row.end = undefined;
		} else {
			row.end = new Date(date).getTime() / 1000;
		}
	}

	onStartYearChange(year: string, row: EnergyConsumptions) {
		const newDate = new Date(year + "-01-01").getTime() / 1000;
		const dateToCheck = periodRange(year, this.favoritePeriod).start;
		this.startErrorMessage = zodSafeParse.transform(new Date(dateToCheck), this.zodCheckDateStart);
		if (newDate) {
			row.start = newDate;
		}
	}

	onEndYearChange(year: string, row: EnergyConsumptions) {
		row.end = new Date(year + "-01-01").getTime() / 1000;
	}

	onDeliveryChange(row: EnergyConsumptions) {
		row.start = new Date(formatDate(row.start * 1000, "yyyy-01-01", "fr-FR")).getTime() / 1000;
		row.end = row.end ? new Date(formatDate(row.end * 1000, "yyyy-01-01", "fr-FR")).getTime() / 1000 : undefined;
	}

	public delete(): void {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: "Supprimer des consommations",
					description:
						"Vous êtes sur le point de supprimer un ensemble de données de consommation. Une fois supprimées ces données ne pourront pas être récupérées, êtes-vous sûr de vouloir continuer ?",
				},
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((confirmDelete) => {
				if (confirmDelete) {
					this.dialogRef.close(null);
				}
			});
	}

	public reloadData(): void {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: "Recharger les données",
					description:
						"Vous êtes sur le point de supprimer un ensemble de données de consommation afin de les remplacer par les données transmises par le distributeur. Une fois effacées, ces données ne pourront pas être récupérées. Etes-vous sûrs de vouloir continuer ?",
				},
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((confirmDelete) => {
				if (confirmDelete) {
					if (this.agreement && this.agreement.type === "PRM") {
						this.row.values[EnergyType.ELECTRICITY_KWH].values = this.agreement.original_consumptions ?? [];
					}
					if (this.agreement && this.agreement.type === "PCE") {
						this.row.values[EnergyType.GAZ_NAT_NETWORK_KWH].values = this.agreement.original_consumptions ?? [];
					}
					this.dialogRef.close(this.row);
				}
			});
	}

	updateUmpteenth(row: EnergyConsumptions, energyType: string) {
		this.dialog
			.open(EditUmpteenthModalComponent, {
				panelClass: "p-0",
				data: row,
			})
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					this.row.values[energyType] = data;
				}
			});
	}
}
