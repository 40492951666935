import { Component } from "@angular/core";
import { Immutable } from "immer";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { HelpSubject } from "src/app/components/help/help.component";
import { SURFACE_FOR_ASSUJETTI } from "src/app/helpers/consts";
import {
	BuildingInfo,
	cloneBuildingInfo,
	getAssujettiSurfaceFromBuilding,
	SurfaceTertiaire,
} from "src/app/models/functionalDeclaration";
import { dialogOpen, Modal } from "src/app/models/modal";
import { OptionalBuildingData } from "src/app/models/optionalBuildingData";
import { SurfaceModalComponent } from "../surface-modal/surface-modal.component";
import { LotNumberModalComponent } from "./lot-number-modal/lot-number-modal.component";

type BuildingInfoForForm = Omit<BuildingInfo, "isOnlyTertiary" | "notOwnedSurfaces"> & {
	optionalBuildingData: OptionalBuildingData;
	notOwnedSurfaces?: SurfaceTertiaire[];
	isOnlyTertiary?: boolean;
};

function regenerateBuildingInfo(from: BuildingInfoForForm): BuildingInfo {
	return {
		...from,
		isOnlyTertiary: from.isOnlyTertiary ?? false,
		notOwnedSurfaces: from.notOwnedSurfaces ?? [],
	};
}

type Input = Immutable<{
	building: Immutable<BuildingInfo>;
	canDelete?: boolean;
	surfaceAssujettiNotOwned: number;
	surfaceAssujettiOwned: number;
	adding: boolean;
}>;
type State = {
	buildingInfo: BuildingInfoForForm;
	canDelete?: boolean;
	assujettiWithoutBuilding: number;
	surfaceAssujettiNotOwned: number;
	buildingAssujettiOwned: number;
	adding: boolean;
};
type Output =
	| { type: OutputType.Save; info: BuildingInfo }
	| {
			type: OutputType.Cancel;
	  }
	| { type: OutputType.Delete };

enum OutputType {
	Save,
	Cancel,
	Delete,
}

@Component({
	selector: "app-building-modal",
	templateUrl: "./building-modal.component.html",
})
export class BuildingModalComponent extends Modal<Input, State, Output> {
	readonly regenerateBuildingInfo = regenerateBuildingInfo;

	static OutputType = OutputType;

	SURFACE_FOR_ASSUJETTI = SURFACE_FOR_ASSUJETTI;
	HelpSubject = HelpSubject;
	isOptionalDataHidden = true;

	inputToState(input: Input): State {
		const { owned: buildingOwned } = getAssujettiSurfaceFromBuilding(input.building);
		const clonedBuildingInfo = cloneBuildingInfo(input.building);

		return {
			buildingInfo: {
				...clonedBuildingInfo,
				optionalBuildingData: clonedBuildingInfo.optionalBuildingData
					? clonedBuildingInfo.optionalBuildingData
					: { table5: {}, table6: { autresEquipementsImmobiliers: [] } },
				isOnlyTertiary: input.adding ? undefined : clonedBuildingInfo.isOnlyTertiary,
				notOwnedSurfaces: input.adding ? undefined : clonedBuildingInfo.notOwnedSurfaces,
			},
			canDelete: input.canDelete,
			assujettiWithoutBuilding: input.surfaceAssujettiOwned - buildingOwned,
			buildingAssujettiOwned: buildingOwned,
			adding: input.adding,
			surfaceAssujettiNotOwned: input.surfaceAssujettiNotOwned,
		};
	}

	toggleOwnWholeBuilding(isYes: boolean) {
		this.state.buildingInfo.notOwnedSurfaces = isYes ? [new SurfaceTertiaire()] : [];
	}

	cancel() {
		this.close({ type: OutputType.Cancel });
	}

	save(requiredData: { notOwnedSurfaces: SurfaceTertiaire[]; isOnlyTertiary: boolean }) {
		if (
			this.state.assujettiWithoutBuilding + this.state.buildingAssujettiOwned + this.state.surfaceAssujettiNotOwned <
				1000 &&
			requiredData.notOwnedSurfaces.length > 0 &&
			requiredData.notOwnedSurfaces.map(({ tertiaireArea }) => tertiaireArea).reduce((a, b) => a + b, 0) === 0
		) {
			dialogOpen(
				this.dialog,
				ConfirmationModalComponent,
				{
					title: "Surface des autres occupants nulle",
					description:
						"Les surfaces renseignées ne vous rendent pas assujetti. Cependant vous avez renseigné une surface tertiaire non exemptée nulle pour les autres occupants du bâtiment. Voulez-vous tout de même continuer ?",
				},
				{ panelClass: "p-0" },
			)
				.afterClosed()
				.subscribe((accept) => {
					if (accept) {
						this.close({ type: OutputType.Save, info: { ...this.state.buildingInfo, ...requiredData } });
					}
				});
		} else {
			this.close({ type: OutputType.Save, info: { ...this.state.buildingInfo, ...requiredData } });
		}
	}

	updateOwnedSurface(surface: SurfaceTertiaire) {
		this.state.buildingInfo.ownedSurface = surface;
		this.refreshAssujetti();
	}

	addNotOwnedSurface(notOwnedSurfaces: SurfaceTertiaire[]) {
		notOwnedSurfaces.push(new SurfaceTertiaire());
	}

	trackSurface(_: number, surface: Immutable<SurfaceTertiaire>): string {
		return surface.id;
	}

	deleteNotOwnedSurface(notOwnedSurfaces: SurfaceTertiaire[], index: number) {
		notOwnedSurfaces.splice(index, 1);
	}

	delete() {
		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Suppression d'un bâtiment",
				description:
					"Vous êtes sur le point de supprimer un bâtiment." +
					" Une fois supprimées les données liées à ce bâtiment ne pourront pas être récupérées," +
					"êtes-vous sûr de vouloir continuer ?",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (output) {
					this.close({ type: OutputType.Delete });
				}
			});
	}

	editNotOwnedSurface(notOwnedSurfaces: SurfaceTertiaire[], surfaceIndex: number) {
		dialogOpen(
			this.dialog,
			SurfaceModalComponent,
			{
				surface: notOwnedSurfaces[surfaceIndex],
				title: "Surface indépendante de mon entité fonctionnelle",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (!output) {
					return;
				}

				if (output.type === OutputType.Save) {
					notOwnedSurfaces[surfaceIndex] = output.surface;
				}

				if (output.type === OutputType.Delete) {
					notOwnedSurfaces.splice(surfaceIndex, 1);
				}

				this.refreshAssujetti();
			});
	}

	refreshAssujetti() {
		const { isOnlyTertiary, notOwnedSurfaces } = this.state.buildingInfo;
		this.state.buildingAssujettiOwned = getAssujettiSurfaceFromBuilding({
			...this.state.buildingInfo,
			isOnlyTertiary: isOnlyTertiary ?? false,
			notOwnedSurfaces: notOwnedSurfaces ?? [],
		}).owned;
	}

	changeOptionalBuilding(data: OptionalBuildingData) {
		this.state.buildingInfo.optionalBuildingData = data;
	}

	openLotNumberModal() {
		dialogOpen(this.dialog, LotNumberModalComponent, {
			lotNumbers: this.state.buildingInfo.lotNumbers,
		})
			.afterClosed()
			.subscribe((lotNumbers) => {
				if (!lotNumbers) {
					return;
				}

				this.state.buildingInfo.lotNumbers = lotNumbers;
			});
	}
}
