<table class="mb-1">
	<thead #rowElement>
		<tr>
			<th style="border: none; width: 150px">Catégories</th>
			<th *ngFor="let year of years" style="text-align: left">{{ year }}</th>
		</tr>
	</thead>
	<tbody
		#rowElement
		*ngFor="let subCategory of functionalDeclaration.infos.asset.categories | uniqueSubCategoriesPerName"
	>
		<tr>
			<th [rowSpan]="subCategory.entries.length + 1">
				<div class="row-header">
					<p class="m-0">
						{{ (subCategory.subCategoryCode | subCategoryFromCode)?.libelle }}
					</p>
				</div>
			</th>
		</tr>
		<tr *ngFor="let entry of subCategory.entries">
			<td
				[colSpan]="years.length"
				*ngFor="
					let categories of [
						entry.activityCategoryEntries | sortActivityCategoriesPerStart: MINIMUM_DATE_TIME : maxDate,
					]
				"
			>
				<div class="blocks pointer">
					<!-- Add an empty block to set height -->
					<div class="block" style="width: 0.01%; position: relative">
						<div class="d-flex-col">
							<span>&nbsp;</span>
							<span>&nbsp;</span>
							<div class="d-flex" style="justify-content: center">&nbsp;</div>
						</div>
					</div>
					<ng-container *ngFor="let ace of categories; index as i">
						<ng-container
							*ngFor="
								let startPercentage of [
									ace.start < MINIMUM_DATE_TIME
										? (MINIMUM_DATE_TIME | timestampPercentage: MINIMUM_DATE_TIME : maxDate)
										: (ace.start | timestampPercentage: MINIMUM_DATE_TIME : maxDate),
								]
							"
						>
							<ng-container *ngIf="ace | isActivityCategory as activityCategory">
								<div
									[class]="{
										block: true,
										overlapping: ace | isActivityCategoryEntriesOverlapping: entry.activityCategoryEntries,
										pointer: true,
										conflictedCategory: (conflictedCategories$ | async)?.includes(activityCategory),
									}"
									[style]="{
										left: startPercentage + '%',
										width:
											'calc(' +
											((ace.end ?? maxDate | timestampPercentage: MINIMUM_DATE_TIME : maxDate) - startPercentage) +
											'% - 4px)',
									}"
									(click)="editCategoryAction.emit(activityCategory); $event.stopPropagation()"
								>
									<div class="d-flex-col">
										<span>{{ activityCategory.name }}</span>
										<span>{{ activityCategory.surface }}m²</span>
										<div class="d-flex" style="justify-content: center">
											<mat-icon
												class="danger small-icon"
												*ngIf="HeatModifierType.HEAT | inArray: activityCategory.temperatureModifiers"
												>local_fire_department
											</mat-icon>
											<mat-icon
												class="primary-light small-icon"
												*ngIf="HeatModifierType.COOL | inArray: activityCategory.temperatureModifiers"
												>ac_unit
											</mat-icon>
											<mat-icon
												class="primary-light small-icon"
												*ngIf="
													(HeatModifierType.COMMERCIAL_COOL | inArray: activityCategory.temperatureModifiers) ||
													(HeatModifierType.LOGISTIC_COOL | inArray: activityCategory.temperatureModifiers) ||
													(HeatModifierType.DOCUMENT_AND_COLLECTION | inArray: activityCategory.temperatureModifiers)
												"
												>severe_cold
											</mat-icon>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="ace | isAddBlock as addBlock">
								<div
									[class]="{
										block: true,
										overlapping: ace | isActivityCategoryEntriesOverlapping: entry.activityCategoryEntries,
										pointer: true,
										blockAdd: true,
									}"
									[style]="{
										left: startPercentage + '%',
										width:
											'calc(' +
											((ace.end ?? maxDate | timestampPercentage: MINIMUM_DATE_TIME : maxDate) - startPercentage) +
											'% - 4px)',
									}"
									(click)="addCategoryAction.emit(addBlock); $event.stopPropagation()"
								>
									<mat-icon>add</mat-icon>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</td>
		</tr>
	</tbody>
	<tbody #rowElement *ngIf="functionalDeclaration.infos.asset.electricCarDock.length !== 0">
		<tr>
			<th>
				<div class="row-header">
					<p class="m-0">Bornes IRVE</p>
				</div>
			</th>
			<td [colSpan]="this.years.length">
				<div class="blocks">
					<div class="block" style="width: 0.01%; position: relative">
						<div class="d-flex-col">
							<span>&nbsp;</span>
							<span>&nbsp;</span>
						</div>
					</div>
					<ng-container
						*ngFor="
							let entry of functionalDeclaration.infos.asset.electricCarDock
								| sortIRVEPerStart: MINIMUM_DATE_TIME : maxDate;
							index as i
						"
					>
						<ng-container
							*ngFor="
								let startPercentage of [
									entry.start < MINIMUM_DATE_TIME
										? (MINIMUM_DATE_TIME | timestampPercentage: MINIMUM_DATE_TIME : maxDate)
										: (entry.start | timestampPercentage: MINIMUM_DATE_TIME : maxDate),
								]
							"
						>
							<ng-container *ngIf="entry | isIrve as irve">
								<div
									[class]="{
										block: true,
										'electric-car-dock-block': true,
										overlapping:
											entry | isElectricCarDockEntriesOverlapping: functionalDeclaration.infos.asset.electricCarDock,
										pointer: true,
									}"
									[style]="{
										left: startPercentage + '%',
										width:
											'calc(' +
											((entry.end ?? maxDate | timestampPercentage: MINIMUM_DATE_TIME : maxDate) - startPercentage) +
											'% - 4px)',
									}"
									(click)="editElectricDockAction.emit(irve); $event.stopPropagation()"
								>
									Bornes IRVE<br />
									{{ irve.count }}
								</div>
							</ng-container>
							<ng-container *ngIf="entry | isAddBlock as addBlock">
								<div
									[class]="{
										block: true,
										'electric-car-dock-block': true,
										overlapping:
											entry | isElectricCarDockEntriesOverlapping: functionalDeclaration.infos.asset.electricCarDock,
										pointer: true,
										blockAdd: true,
									}"
									[style]="{
										left: startPercentage + '%',
										width:
											'calc(' +
											((entry.end ?? maxDate | timestampPercentage: MINIMUM_DATE_TIME : maxDate) - startPercentage) +
											'% - 4px)',
									}"
									(click)="addElectricDockAction.emit(addBlock); $event.stopPropagation()"
								>
									<mat-icon>add</mat-icon>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</td>
		</tr>
	</tbody>
</table>
