import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Declaration } from "src/app/models/declaration";
import { DeclarationGroup } from "src/app/models/declarationGroup";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId, RepresentativeId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { Representative } from "src/app/models/representative";
import { ResourceType } from "src/app/models/resource";
import { RouteDealer } from "src/app/models/routes";
import { InitStateService } from "src/app/services/init-state.service";
import { UserStateService } from "src/app/services/user-state.service";
import { IsolationStateService } from "../../services/isolation-state.service";
import { compareByName, compareStrings } from "src/app/helpers/sort-by-name";

@Component({
	selector: "app-authorization-view",
	templateUrl: "./authorization-view.component.html",
	styleUrls: ["./authorization-view.component.scss"],
})
export class AuthorizationViewComponent {
	ResourceType = ResourceType;

	authRepresentatives: Lazy<Representative>[] = [];
	authGroups: Lazy<DeclarationGroup>[] = [];
	authDeclarations: Lazy<Declaration>[] = [];
	authEntities: FunctionalDeclaration[] = [];

	currentResourceId: string;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			selectAuto: boolean;
			authRepresentatives: Lazy<Representative>[];
			authGroups: Lazy<DeclarationGroup>[];
			authDeclarations: Lazy<Declaration>[];
			authEntities: FunctionalDeclaration[];
		},
		private router: Router,
		private initService: InitStateService,
		userState: UserStateService,
		isolation: IsolationStateService,
	) {
		this.authRepresentatives = [...data.authRepresentatives].sort(compareByName);
		this.authGroups = [...data.authGroups].sort(compareByName);
		this.authDeclarations = [...data.authDeclarations].sort(compareByName);
		this.authEntities = [...data.authEntities].sort((a, b) => compareStrings(a.description, b.description));

		this.currentResourceId = isolation.isolationInfo?.id ?? "";

		if (userState.user === undefined) {
			router.navigate(RouteDealer.login());
			return;
		}
	}

	selectFunctionalDeclaration(id: FunctionalDeclarationId) {
		this.initService
			.initFunctionalDeclaration$(id)
			.subscribe(() => this.router.navigate(RouteDealer.qualification(id)));
	}

	selectDeclaration(id: DeclarationId) {
		this.initService.initDeclaration$(id).subscribe(() => this.router.navigate(RouteDealer.myDeclaration(id)));
	}

	selectGroup(id: DeclarationGroupId) {
		this.initService.initGroup$(id).subscribe(() => this.router.navigate(RouteDealer.myDeclaration(id)));
	}

	selectRepresentative(id: RepresentativeId) {
		this.initService.initRepresentative$(id).subscribe(() => this.router.navigate(RouteDealer.myDeclaration(id)));
	}
}
