import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "periodRange",
})
export class PeriodRangePipe implements PipeTransform {
	transform = periodRange;
}

export function periodRange(year: string | number, period: number): { start: string; end: string } {
	if (typeof year === "string") {
		year = Number(year);
	}
	if (period > 6) {
		year--;
	}

	return {
		start: `${year}-${period + 1}-01`,
		end: `${year + 1}-${(period % 12) + 1}-01`,
	};
}
