import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LOGO_URL } from "../../helpers/consts";
import { BaseComponent } from "../../models/base-component.directive";
import { ChangeLog } from "../../models/changelog";
import { ResourceType } from "../../models/resource";
import { Route, RouteDealer } from "../../models/routes";
import { IsolationStateService } from "../../services/isolation-state.service";
import { UserStateService } from "../../services/user-state.service";
import { VersionService } from "../../services/version.service";

@Component({
	selector: "app-changelog",
	templateUrl: "./changelog.component.html",
	styleUrls: ["./changelog.component.scss"],
})
export class ChangelogComponent extends BaseComponent {
	LOGO_URL = LOGO_URL;
	route: string;
	currentChangelog: ChangeLog | undefined = undefined;
	markdown = "";
	changelogs: ChangeLog[] = [];

	constructor(
		userState: UserStateService,
		public isolation: IsolationStateService,
		public router: Router,
		versionService: VersionService,
	) {
		super();
		this.route = userState.user?.user_id !== undefined ? `/${Route.MyDeclaration}` : "/";
		versionService.getChangelogs$().subscribe((changelogs) => {
			this.changelogs = changelogs;
			if (this.changelogs.length > 0) {
				this.currentChangelog = this.changelogs[0];
			}
		});
	}

	goHome() {
		if (this.isolation.isolationInfo) {
			if (this.isolation.isolationInfo.type === ResourceType.FunctionalDeclaration) {
				this.router.navigate(RouteDealer.qualification(this.isolation.isolationInfo.id));
				return;
			} else {
				this.router.navigate(RouteDealer.myDeclaration(this.isolation.isolationInfo.id));
				return;
			}
		}
		this.router.navigate(["/"]);
	}
}
