import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { z } from "zod";

const altitudeSchema = z.object({
	elevations: z.array(z.object({ z: z.coerce.number() })).nonempty(),
});

@Injectable({
	providedIn: "root",
})
export class ElevationService {
	constructor(private http: HttpClient) {}

	getHeight(lon: number, lat: number): Observable<number> {
		return this.http
			.get<unknown>(environment.elevationApiUrl, { params: { lon, lat, resource: "ign_rge_alti_wld" } })
			.pipe(
				timeout({ first: 5000 }),
				catchError((err) => {
					console.error("First altitude api call failed" + (err instanceof Error ? ": " + err.message : ""));

					return this.http.get<unknown>(environment.oldElevationApiUrl, { params: { lon, lat } });
				}),
				map((res) => altitudeSchema.parse(res).elevations[0].z),
			);
	}
}
