/* eslint-disable @typescript-eslint/no-namespace */
import { GreenKeys as Keys } from "@grs/greenkeys";
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Point, Polygon, Position } from "geojson";
import { immerable, Immutable } from "immer";
import { MapGeoJSONFeature } from "maplibre-gl";
import * as uuid from "uuid";
import { GoalReport } from "../helpers/conso";
import { entries, keys } from "../helpers/immutable";
import { Nullable } from "../helpers/nullable";
import { AddressInfo } from "./address";
import { AgreementAsk, cloneAgreementAsk } from "./agreementAsk";
import { AssetInfo, cloneAssetInfo, newAssetInfo } from "./asset";
import { AssociationApi } from "./associationApi";
import { Declaration } from "./declaration";
import { cloneEfaStatus, EfaStatus } from "./efaStatus";
import { EnergyCategory } from "./energyCategory";
import { DeclarationId, FunctionalDeclarationId } from "./ids";
import { Lazy } from "./lazy";
import { cloneOptionalBuildingData, OptionalBuildingData } from "./optionalBuildingData";
import { ParcelFeature } from "./Parcel";
import { ApiAddress, EtablissementApi, PeriodeEtablissement, UniteLegaleSiretRoute } from "./siren-api.types";
import { StreetType } from "./StreetType";

// GreenKeys has only 'linked_efa', and can't use computed string as object property, so we define them as const instead
export const KEY_LINKED_EFA_1 = "linked_efa_1";
export const KEY_LINKED_EFA_2 = "linked_efa_2";
export const KEY_LINKED_EFA_3 = "linked_efa_3";

export function cloneLazyFunctionalDeclaration(
	fd: Immutable<Lazy<FunctionalDeclaration>>,
): Lazy<FunctionalDeclaration> {
	return {
		...fd,
		[Keys.KEY_ADDRESS]: { ...fd[Keys.KEY_ADDRESS] },
		[Keys.KEY_EFA_STATUS]: cloneEfaStatus(fd.efa_status),
		[Keys.KEY_YEARS_TOKEN_USED]: [...fd[Keys.KEY_YEARS_TOKEN_USED]],
	};
}

export interface FileEntry {
	uuid: string;
	name: string;
	size: number;
	date: number;
	type: string;
}

export interface FunctionalDeclaration {
	[Keys.KEY_DECLARATION_ID]?: Nullable<DeclarationId>;
	[Keys.KEY_DECLARATION_FUNCTIONAL_ID]?: Nullable<FunctionalDeclarationId>;
	/**
	 * Why no RNA?
	 * If the structure that owns the function entity has only a RNA then the entity too.
	 * If the structure has a SIREN then we must provide a SIRET or the entity will be a "ETABLISSEMENT SANS SIRET"
	 */
	[Keys.KEY_NAME]?: Nullable<string>;
	[Keys.KEY_DESCRIPTION]: Nullable<string>;
	[Keys.KEY_IS_TOKEN_USED]: boolean;
	[Keys.KEY_ADDRESS]: AddressInfo;
	[Keys.KEY_ESTABLISHMENT_PERIOD]: PeriodeEtablissement[];
	[Keys.KEY_AGREEMENT_ASKS]: AgreementAsk[];
	[Keys.KEY_CONSUMPTIONS]: Consumptions;
	[Keys.KEY_INDEX]: number;
	[Keys.KEY_LEGAL_UNIT]: UniteLegaleSiretRoute;
	[Keys.KEY_INFOS]: EntityInfo;
	[Keys.KEY_EFA_STATUS]: EfaStatus;
	[Keys.KEY_IS_DEMO]: boolean;
	[Keys.KEY_FILES]: FileEntry[];
	[Keys.KEY_NOT_IN_CSV]: Nullable<boolean>;
	[Keys.KEY_YEARS_TOKEN_USED]: number[];
	[KEY_LINKED_EFA_1]: Nullable<LinkedEFA>;
	[KEY_LINKED_EFA_2]: Nullable<LinkedEFA>;
	[KEY_LINKED_EFA_3]: Nullable<LinkedEFA>;
	[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: boolean;
	[Keys.KEY_FIRST_YEAR_DECLARATION]: Nullable<number>;
	[Keys.KEY_OPERAT_CREATION_METHOD]: Nullable<string>;
}

export namespace FunctionalDeclaration {
	/**
	 * Why an API interface? Because sometimes the server gives us garbage we don't care about.
	 * However, this garbage can be important. For example, if `declaration_functional_ids` (in the Declaration interface)
	 * is not correct then the server will delete entities. So to be less error prone we will hide
	 * this "feature" thanks to this interface.
	 * Besides, this makes us able to do changes without modifying the server.
	 */
	export interface Api {
		readonly FunctionalDeclarationApi: unique symbol;

		[Keys.KEY_DECLARATION_ID]?: Nullable<DeclarationId>;
		[Keys.KEY_DECLARATION_FUNCTIONAL_ID]?: Nullable<FunctionalDeclarationId>;
		[Keys.KEY_NAME]?: Nullable<string>;
		[Keys.KEY_DESCRIPTION]: Nullable<string>;
		[Keys.KEY_IS_TOKEN_USED]: boolean;
		[Keys.KEY_ADDRESS]: AddressInfo;
		[Keys.KEY_ESTABLISHMENT_PERIOD]: PeriodeEtablissement[];
		[Keys.KEY_AGREEMENT_ASKS]: AgreementAsk[];
		[Keys.KEY_CONSUMPTIONS]: Consumptions;
		[Keys.KEY_INDEX]: number;
		[Keys.KEY_LEGAL_UNIT]: UniteLegaleSiretRoute;
		[Keys.KEY_INFOS]: EntityInfo;
		[Keys.KEY_EFA_STATUS]: EfaStatus;
		[Keys.KEY_IS_DEMO]: boolean;
		[Keys.KEY_FILES]: FileEntry[];
		[Keys.KEY_NOT_IN_CSV]: Nullable<boolean>;
		[Keys.KEY_YEARS_TOKEN_USED]: number[];
		[KEY_LINKED_EFA_1]: Nullable<LinkedEFA>;
		[KEY_LINKED_EFA_2]: Nullable<LinkedEFA>;
		[KEY_LINKED_EFA_3]: Nullable<LinkedEFA>;
		[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: boolean;
		[Keys.KEY_FIRST_YEAR_DECLARATION]: Nullable<number>;
		[Keys.KEY_OPERAT_CREATION_METHOD]: Nullable<string>;
	}

	export function fromApi(api: Api): FunctionalDeclaration {
		const functionalDeclaration = {
			address: api.address,
			agreement_asks: api.agreement_asks,
			consumptions: api.consumptions,
			description: api.description,
			establishment_period: api.establishment_period,
			infos: api.infos,
			is_token_used: api.is_token_used,
			legal_unit: api.legal_unit,
			declaration_functional_id: api.declaration_functional_id,
			declaration_id: api.declaration_id,
			name: api.name,
			[Keys.KEY_EFA_STATUS]: api.efa_status,
			[Keys.KEY_IS_DEMO]: api[Keys.KEY_IS_DEMO],
			[Keys.KEY_FILES]: api[Keys.KEY_FILES],
			[Keys.KEY_NOT_IN_CSV]: api[Keys.KEY_NOT_IN_CSV],
			[Keys.KEY_YEARS_TOKEN_USED]: api[Keys.KEY_YEARS_TOKEN_USED],
			[Keys.KEY_INDEX]: api[Keys.KEY_INDEX],
			[KEY_LINKED_EFA_1]: api[KEY_LINKED_EFA_1],
			[KEY_LINKED_EFA_2]: api[KEY_LINKED_EFA_2],
			[KEY_LINKED_EFA_3]: api[KEY_LINKED_EFA_3],
			[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: api[Keys.KEY_IS_IMPORTED_FROM_OPERAT],
			[Keys.KEY_FIRST_YEAR_DECLARATION]: api[Keys.KEY_FIRST_YEAR_DECLARATION],
			[Keys.KEY_OPERAT_CREATION_METHOD]: api[Keys.KEY_OPERAT_CREATION_METHOD],
		};

		functionalDeclaration.infos.parcelles = functionalDeclaration.infos.parcelles ?? [];

		return functionalDeclaration;
	}

	export function fromLazyApi(api: Lazy<Api>): Lazy<FunctionalDeclaration> {
		return {
			address: api.address,
			description: api.description,
			is_token_used: api.is_token_used,
			declaration_functional_id: api.declaration_functional_id,
			declaration_id: api.declaration_id,
			name: api.name,
			[Keys.KEY_EFA_STATUS]: api.efa_status,
			[Keys.KEY_IS_DEMO]: api[Keys.KEY_IS_DEMO],
			[Keys.KEY_NOT_IN_CSV]: api[Keys.KEY_NOT_IN_CSV],
			[Keys.KEY_YEARS_TOKEN_USED]: api[Keys.KEY_YEARS_TOKEN_USED],
			[Keys.KEY_INDEX]: api[Keys.KEY_INDEX],
			[KEY_LINKED_EFA_1]: api[KEY_LINKED_EFA_1],
			[KEY_LINKED_EFA_2]: api[KEY_LINKED_EFA_2],
			[KEY_LINKED_EFA_3]: api[KEY_LINKED_EFA_3],
			[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: api[Keys.KEY_IS_IMPORTED_FROM_OPERAT],
			[Keys.KEY_FIRST_YEAR_DECLARATION]: api[Keys.KEY_FIRST_YEAR_DECLARATION],
			[Keys.KEY_OPERAT_CREATION_METHOD]: api[Keys.KEY_OPERAT_CREATION_METHOD],
		};
	}

	export function toApi(fd: Immutable<FunctionalDeclaration>): Immutable<Api> {
		const api: Immutable<Omit<Api, "FunctionalDeclarationApi">> = {
			address: fd.address,
			agreement_asks: fd.agreement_asks,
			consumptions: fd.consumptions,
			description: fd.description,
			establishment_period: fd.establishment_period,
			infos: fd.infos,
			is_token_used: fd.is_token_used,
			legal_unit: fd.legal_unit,
			declaration_functional_id: fd.declaration_functional_id,
			declaration_id: fd.declaration_id,
			name: fd.name,
			[Keys.KEY_EFA_STATUS]: fd.efa_status,
			[Keys.KEY_IS_DEMO]: fd[Keys.KEY_IS_DEMO],
			[Keys.KEY_FILES]: fd[Keys.KEY_FILES],
			[Keys.KEY_NOT_IN_CSV]: fd[Keys.KEY_NOT_IN_CSV],
			[Keys.KEY_YEARS_TOKEN_USED]: fd[Keys.KEY_YEARS_TOKEN_USED],
			[Keys.KEY_INDEX]: fd[Keys.KEY_INDEX],
			[KEY_LINKED_EFA_1]: fd[KEY_LINKED_EFA_1],
			[KEY_LINKED_EFA_2]: fd[KEY_LINKED_EFA_2],
			[KEY_LINKED_EFA_3]: fd[KEY_LINKED_EFA_3],
			[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: fd[Keys.KEY_IS_IMPORTED_FROM_OPERAT],
			[Keys.KEY_FIRST_YEAR_DECLARATION]: fd[Keys.KEY_FIRST_YEAR_DECLARATION],
			[Keys.KEY_OPERAT_CREATION_METHOD]: fd[Keys.KEY_OPERAT_CREATION_METHOD],
		};
		return api as Immutable<Api>;
	}

	export function toLazyApi(fd: Immutable<Lazy<FunctionalDeclaration>>): Immutable<Lazy<Api>> {
		const api: Immutable<Omit<Lazy<Api>, "FunctionalDeclarationApi">> = {
			address: fd.address,
			description: fd.description,
			is_token_used: fd.is_token_used,
			declaration_functional_id: fd.declaration_functional_id,
			declaration_id: fd.declaration_id,
			name: fd.name,
			[Keys.KEY_EFA_STATUS]: fd.efa_status,
			[Keys.KEY_IS_DEMO]: fd[Keys.KEY_IS_DEMO],
			[Keys.KEY_NOT_IN_CSV]: fd[Keys.KEY_NOT_IN_CSV],
			[Keys.KEY_YEARS_TOKEN_USED]: fd[Keys.KEY_YEARS_TOKEN_USED],
			[Keys.KEY_INDEX]: fd[Keys.KEY_INDEX],
			[KEY_LINKED_EFA_1]: fd.linked_efa_1,
			[KEY_LINKED_EFA_2]: fd.linked_efa_2,
			[KEY_LINKED_EFA_3]: fd.linked_efa_3,
			[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: fd[Keys.KEY_IS_IMPORTED_FROM_OPERAT],
			[Keys.KEY_FIRST_YEAR_DECLARATION]: fd[Keys.KEY_FIRST_YEAR_DECLARATION],
			[Keys.KEY_OPERAT_CREATION_METHOD]: fd[Keys.KEY_OPERAT_CREATION_METHOD],
		};

		return api as Immutable<Lazy<Api>>;
	}
}

export function newFunctionalDeclaration(
	data: Partial<FunctionalDeclaration> = {},
	efaStatus: EfaStatus,
): FunctionalDeclaration {
	return {
		...data,
		[Keys.KEY_DESCRIPTION]: data[Keys.KEY_DESCRIPTION] ?? "",
		[Keys.KEY_IS_TOKEN_USED]: data[Keys.KEY_IS_TOKEN_USED] ?? false,
		[Keys.KEY_ADDRESS]: data[Keys.KEY_ADDRESS] ?? new AddressInfo(),
		[Keys.KEY_ESTABLISHMENT_PERIOD]: data[Keys.KEY_ESTABLISHMENT_PERIOD] ?? [],
		[Keys.KEY_AGREEMENT_ASKS]: data[Keys.KEY_AGREEMENT_ASKS] ?? [],
		[Keys.KEY_CONSUMPTIONS]: data[Keys.KEY_CONSUMPTIONS] ?? new Consumptions(),
		[Keys.KEY_LEGAL_UNIT]: data[Keys.KEY_LEGAL_UNIT] ?? new UniteLegaleSiretRoute(),
		[Keys.KEY_INFOS]: new EntityInfo(),
		[Keys.KEY_EFA_STATUS]: efaStatus,
		[Keys.KEY_IS_DEMO]: false,
		[Keys.KEY_FILES]: [],
		[Keys.KEY_NOT_IN_CSV]: data[Keys.KEY_NOT_IN_CSV] ?? false,
		[Keys.KEY_YEARS_TOKEN_USED]: data[Keys.KEY_YEARS_TOKEN_USED] ?? [],
		[Keys.KEY_INDEX]: data[Keys.KEY_INDEX] ?? -1, // Back ignores this field
		[KEY_LINKED_EFA_1]: data.linked_efa_1,
		[KEY_LINKED_EFA_2]: data.linked_efa_2,
		[KEY_LINKED_EFA_3]: data.linked_efa_3,
		[Keys.KEY_IS_IMPORTED_FROM_OPERAT]: data[Keys.KEY_IS_IMPORTED_FROM_OPERAT] ?? false,
		[Keys.KEY_FIRST_YEAR_DECLARATION]: data[Keys.KEY_FIRST_YEAR_DECLARATION],
		[Keys.KEY_OPERAT_CREATION_METHOD]: data[Keys.KEY_OPERAT_CREATION_METHOD],
	};
}

export function isMultiOccupation(functionalDeclaration: Immutable<FunctionalDeclaration>): boolean {
	return (
		(functionalDeclaration.is_imported_from_operat &&
			functionalDeclaration.infos.buildingInfos.length === 0 &&
			(Object.keys(functionalDeclaration.consumptions[EnergyCategory.COMMON] ?? {}).length > 0 ||
				Object.keys(functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED] ?? {}).length > 0)) ||
		functionalDeclaration[Keys.KEY_INFOS].buildingInfos.some((building) => building.notOwnedSurfaces.length > 0)
	);
}

export function cloneFunctionalDeclaration(fd: Immutable<FunctionalDeclaration>): FunctionalDeclaration {
	const clone: FunctionalDeclaration = {
		...fd,
		establishment_period: fd[Keys.KEY_ESTABLISHMENT_PERIOD].map((period) => ({ ...period })),
		agreement_asks: fd[Keys.KEY_AGREEMENT_ASKS].map(cloneAgreementAsk),
		infos: cloneEntityInfo(fd.infos),
		legal_unit: { ...fd.legal_unit },
		consumptions: cloneConsumptions(fd[Keys.KEY_CONSUMPTIONS]),
		address: { ...fd.address },
		[Keys.KEY_EFA_STATUS]: cloneEfaStatus(fd.efa_status),
		[Keys.KEY_FILES]: fd[Keys.KEY_FILES].map((file) => ({ ...file })),
		[Keys.KEY_YEARS_TOKEN_USED]: [...fd[Keys.KEY_YEARS_TOKEN_USED]],
	};

	return clone;
}

export class Consumptions {
	[key: string]: {
		[key: string]: { label: string; values: { [energy: string]: EnergyConsumptions } };
	};
}

export class EnergyConsumptions {
	values: ConsumptionEntry[];
	start: number;
	end?: number | undefined;
	deductTo?: EnergyCategory | undefined;
	// Storable element -> can be added to deliverable history
	byInvoice?: boolean | undefined;
	hasRepartitionKey?: boolean | undefined;
	partUmpteenth: number;
	totalUmpteenth: number;
	yearAverage?: number | undefined;
	repartitionKeys: {
		partUmpteenth: number;
		totalUmpteenth: number;
		start: number;
		end?: number | undefined;
	}[];
	invoiceData: { value: number; date: number }[];

	constructor(data: Partial<Immutable<EnergyConsumptions>>) {
		this.values = data.values ? data.values.map((entry) => new ConsumptionEntry({ ...entry })) : [];
		this.start = data.start ?? Declaration.MINIMUM_DATE_TIME;
		this.end = data.end;
		this.deductTo = data.deductTo ?? EnergyCategory.INDIVIDUAL;
		this.byInvoice = data.byInvoice ?? false;
		this.hasRepartitionKey = data.hasRepartitionKey ?? false;
		this.yearAverage = data.yearAverage ?? 4;
		this.repartitionKeys = data.repartitionKeys ? data.repartitionKeys.map((entry) => ({ ...entry })) : [];
		this.invoiceData = data.invoiceData ? data.invoiceData.map((entry) => ({ ...entry })) : [];
		this.partUmpteenth = data.partUmpteenth ?? 100;
		this.totalUmpteenth = data.totalUmpteenth ?? 100;
	}
}

export class ConsumptionEntry {
	date_start: number;
	date_end: number;
	value: number;
	source = "";
	commentary: string = "";
	partUmpteenth: number;
	totalUmpteenth: number;
	hasRepartitionKey: boolean;

	constructor(data: Partial<ConsumptionEntry> = {}) {
		this.date_start = data.date_start ?? Declaration.MINIMUM_DATE_TIME;
		this.date_end = data.date_end ?? Declaration.MINIMUM_DATE_TIME;
		this.value = data.value ?? 0;
		this.source = data.source ?? "";
		this.commentary = data.commentary ?? "";
		this.partUmpteenth = data.partUmpteenth ?? 100;
		this.totalUmpteenth = data.totalUmpteenth ?? 100;
		this.hasRepartitionKey = data.hasRepartitionKey ?? false;
	}
}

export namespace ConsumptionEntry {
	export function getCorrectedValue(entry: Immutable<ConsumptionEntry>): number {
		return entry.hasRepartitionKey ? (entry.value * entry.partUmpteenth) / entry.totalUmpteenth : entry.value;
	}

	export function getEntriesTouchingRange(
		entries: Immutable<ConsumptionEntry[]>,
		start: number,
		end: number,
	): Immutable<ConsumptionEntry>[] {
		return entries.filter((entry) => entry.date_start < end && entry.date_end > start);
	}
}

export function cloneConsumptions(consumptions: Immutable<Consumptions>): Consumptions {
	const clone: {
		[key: string]: {
			[key: string]: { label: string; values: { [energy: string]: EnergyConsumptions } };
		};
	} = {};

	keys(consumptions).forEach((cat) => {
		if (clone[cat] === undefined) {
			clone[cat] = {};
		}
		keys(consumptions[cat]).forEach((key) => {
			clone[cat][key] = { label: consumptions[cat][key].label, values: {} };
			keys(consumptions[cat][key].values).forEach((subKey) => {
				clone[cat][key].values[subKey] = new EnergyConsumptions(consumptions[cat][key].values[subKey]);
			});
		});
	});
	return clone;
}

export const WRONG_ABSOLUTE_OBJECTIVE = -1;

export class EntityInfo {
	buildingInfos: BuildingInfo[] = [];
	parcelles: (FeatureCollection | ParcelFeature)[] = []; // backward compatibility
	altitude: number | undefined;
	// (FeatureCollection:old, Feature: new)
	otherBuildingOnSameSite?: SurfaceTertiaire;
	asset: AssetInfo;
	referenceYear: number | undefined;
	referenceYearConsumption: number | undefined;
	relativeObjective: number | undefined;
	absoluteObjective: number | undefined;
	// favoritePeriod can be equal to all integers lesser or equal to 11
	// 0 = January -> December
	// 1 = February -> January
	// 2 = March -> February
	// etc...
	favoritePeriod: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 = 0;

	constructor() {
		this.buildingInfos = [];
		this.parcelles = [];
		this.asset = newAssetInfo();
	}

	static assujettiArea(info: Immutable<EntityInfo>): { owned: number; notOwned: number } {
		let totalAssujettiSurface = 0;
		let totalOwnedAssujettiSurface = 0;

		for (const buildingInfo of info.buildingInfos) {
			totalOwnedAssujettiSurface += SurfaceTertiaire.getAssujettiSurfaceArea(buildingInfo.ownedSurface);
			totalAssujettiSurface +=
				SurfaceTertiaire.getAssujettiSurfaceArea(buildingInfo.ownedSurface) +
				buildingInfo.notOwnedSurfaces
					.map((surface) => SurfaceTertiaire.getAssujettiSurfaceArea(surface))
					.reduce((a, b) => a + b, 0);
		}
		totalAssujettiSurface += info.otherBuildingOnSameSite
			? SurfaceTertiaire.getAssujettiSurfaceArea(info.otherBuildingOnSameSite)
			: 0;
		return {
			owned: totalOwnedAssujettiSurface,
			notOwned: totalAssujettiSurface - totalOwnedAssujettiSurface,
		};
	}

	static isReportDifferentFromObjectives(info: Immutable<EntityInfo>, report: GoalReport): boolean {
		return (
			info.referenceYear !== report.referenceYear ||
			info.referenceYearConsumption !== report.referenceYearConsumption ||
			info.relativeObjective !== report.relativeObjective ||
			info.absoluteObjective !== report.absoluteObjective
		);
	}
}

export function cloneEntityInfo(info: Immutable<EntityInfo>): EntityInfo {
	const clone: EntityInfo = {
		...info,
		parcelles: JSON.parse(JSON.stringify(info.parcelles)),
		otherBuildingOnSameSite: info.otherBuildingOnSameSite
			? cloneSurfaceTertiaire(info.otherBuildingOnSameSite)
			: undefined,
		buildingInfos: info.buildingInfos.map(cloneBuildingInfo),
		asset: cloneAssetInfo(info.asset),
	};

	return clone;
}

export const CATEGORIES: { title: string; items?: string[] }[] = [
	{ title: "installationPrecaire" },
	{ title: "culte" },
	{
		title: "defense",
		items: [
			"isCentreCommandementOperationnelInterventionForceMilitaire",
			"isSiteStrategique",
			"isLocauxSimulateurVolOuConduiteEngin",
			"isServeurDataCenterActiviteOperationnelleFinDeDefense",
		],
	},
	{
		title: "securiteCivile",
		items: [
			"isCentreOuCelluleCommandeSuiviOperationnelDesServicesDepartementauxIncendieEtSecours",
			"isCaserneDePompiers",
			"isCentreAppelEtIntervention",
			"isCentreGestionTraficAerienFerroviaireMaritimeFluvial",
			"isCelluleOuPosteDeCommandementDeGestionDeCriseEnMinistereEtEnPrefecture",
		],
	},
	{
		title: "sureteInterieureDuTerritoire",
		items: [
			"isCentreOuCellulesDeCommandementEtDeSuiviAuNiveauDuMinistereDeLInterieurEtEnPrefecture",
			"isCellulesStrategiquesDeSureteInterieureDuTerritoire",
			"isServeursEtDataCenterLiesAuxActivitesOperationnellesADesFinDeSureteDuTerritoire",
		],
	},
	{
		title: "stockage",
		items: ["isStockageDeMatieresPremieres", "isStockageDeProduitsFinisenFluxTendus"],
	},
	{
		title: "locauxPourLePersonnelHeberge",
		items: ["isActivitePrincipaleIndustrielleOuArtisanale"],
	},
	{ title: "stationnementPublic", items: ["isParcsDeStationnementACielOuvert"] },
	{ title: "locauxNonExploites", items: ["isLocauxVacantsOuDesaffectes"] },
	{
		title: "etablissementSociauxEtMedicoSociaux",
		items: ["isCrpEsatNonLiesAuTertiaire", "isEtablissementsDAccueilNonMedicalises", "isServicesDHebergementSocial"],
	},
	{
		title: "residencesDeTourisme",
		items: ["isGites", "isMeublesDeTourismeEtAirbnb", "isAppartementEnMandatDeGestion"],
	},
	{
		title: "laboratoireDeRechercheDAnalyseMecaniqueOuDEssais",
		items: ["isLiesALaRechercheEtAuDeveloppementIndustriel"],
	},
	{
		title: "entretienDesRoutesEtDesAutoroutes",
		items: ["isUniteDEmulsion", "isCentralesDEnrobes"],
	},
	{ title: "equipementsSportifs", items: ["isUniquementPiscineExterieur"] },
	{ title: "logementsDeFonction", items: ["isLogementAssociesAuxEtablissementsScolaires"] },
];

export type CategorySurfaceTertiaire = Record<string, number | Record<string, number>>;

export namespace CategorySurfaceTertiaire {
	export function surfaceSum(categories: CategorySurfaceTertiaire): number {
		return Object.values(categories)
			.flatMap((layer) => (typeof layer === "number" ? [layer] : Object.values(layer)))
			.reduce((a, b) => a + b, 0);
	}
}

export function cloneCategorySurfaceTertiaire(
	categories: Immutable<CategorySurfaceTertiaire>,
): CategorySurfaceTertiaire {
	const clone: CategorySurfaceTertiaire = {};

	entries(categories).forEach(([key, value]) => {
		if (typeof value === "number") {
			clone[key] = value;
		} else {
			clone[key] = { ...value };
		}
	});

	return clone;
}

export function getTotalSurface(categorySurfaceAreas: Immutable<CategorySurfaceTertiaire>): number {
	let totalSurface = 0;

	for (const firstLayer of CATEGORIES) {
		if (firstLayer.items) {
			for (const item of firstLayer.items) {
				const record = categorySurfaceAreas[firstLayer.title];
				totalSurface +=
					record !== undefined && typeof record !== "number" && record[item] !== undefined ? record[item] : 0;
			}
		} else {
			const value = categorySurfaceAreas[firstLayer.title];
			totalSurface += value !== undefined && typeof value === "number" ? value : 0;
		}
	}

	return totalSurface;
}

/**
 * Will delete every keys that are not in the variable `CATEGORIES` and that are equal to 0. Useful
 * when we will remove categories in the future.
 * @param categorySurfaceAreas The categories to clean
 */
export function cleanCategories(categorySurfaceAreas: CategorySurfaceTertiaire) {
	const keysToDelete: string[] = [];
	for (const key of Object.keys(categorySurfaceAreas)) {
		if (!CATEGORIES.map((firstLayer) => firstLayer.title).includes(key)) {
			keysToDelete.push(key);
		} else if (typeof categorySurfaceAreas[key] === "number" && categorySurfaceAreas[key] === 0) {
			keysToDelete.push(key);
		}
	}
	for (const key of keysToDelete) {
		delete categorySurfaceAreas[key];
	}
	for (const firstLayer of CATEGORIES) {
		if (firstLayer.items) {
			const record = categorySurfaceAreas[firstLayer.title];
			if (record && typeof record !== "number") {
				const subKeyToDelete: string[] = [];
				for (const subKey of Object.keys(record)) {
					if (!firstLayer.items.includes(subKey)) {
						subKeyToDelete.push(subKey);
					} else if (record[subKey] === 0) {
						subKeyToDelete.push(subKey);
					}
				}
				for (const subKey of subKeyToDelete) {
					delete record[subKey];
				}
				if (Object.keys(record).length === 0) {
					delete categorySurfaceAreas[firstLayer.title];
				}
			}
		}
	}
}

export class SurfaceTertiaire {
	[immerable] = true;

	id: string;
	tertiaireArea: number;
	accessoryArea: number;
	categorySurfaceAreas: CategorySurfaceTertiaire;

	constructor(data: Partial<SurfaceTertiaire> = {}) {
		this.id = data.id ?? uuid.v1();
		this.tertiaireArea = data.tertiaireArea ?? 0;
		this.accessoryArea = data.accessoryArea ?? 0;
		this.categorySurfaceAreas = data.categorySurfaceAreas ?? {};
	}

	// why as a static method ?
	// because to use this method we must save instances with the method...
	// And storing method in a database is strange...
	static getAssujettiSurfaceArea(surface: Immutable<SurfaceTertiaire>): number {
		return surface.tertiaireArea - getTotalSurface(surface.categorySurfaceAreas) + surface.accessoryArea;
	}
}

export function cloneSurfaceTertiaire(surface: Immutable<SurfaceTertiaire>): SurfaceTertiaire {
	// id for backward compatibility
	const clone: SurfaceTertiaire = {
		...surface,
		id: surface.id ?? uuid.v1(),
		categorySurfaceAreas: cloneCategorySurfaceTertiaire(surface.categorySurfaceAreas),
	};

	return clone;
}

export type BuildingFeature = Feature<Polygon | MultiPolygon | Point, GeoJsonProperties>; // Point
// =
// custom
// building

export function toBuildingFeature(feature: MapGeoJSONFeature): BuildingFeature | null {
	const geometry = feature.geometry;

	if (geometry.type !== "Polygon" && geometry.type !== "MultiPolygon") {
		return null;
	}

	return { geometry, properties: feature.properties, type: feature.type, id: feature.id };
}

export interface BuildingInfo {
	building: FeatureCollection | BuildingFeature; // FeatureCollection: old, Feature: new
	label: [string, Position | undefined];
	lotNumbers: string[];
	internalCode: string;
	ownedSurface: SurfaceTertiaire;
	notOwnedSurfaces: SurfaceTertiaire[];
	isOnlyTertiary: boolean;
	optionalBuildingData?: OptionalBuildingData;
}

export function getAssujettiSurfaceFromBuilding(buildingInfo: Immutable<BuildingInfo>): {
	owned: number;
	notOwned: number;
} {
	return {
		owned: SurfaceTertiaire.getAssujettiSurfaceArea(buildingInfo.ownedSurface),
		notOwned: buildingInfo.notOwnedSurfaces
			.map((surface) => SurfaceTertiaire.getAssujettiSurfaceArea(surface))
			.reduce((a, b) => a + b, 0),
	};
}

export function cloneBuildingInfo(info: Immutable<BuildingInfo>): BuildingInfo {
	const clone: BuildingInfo = {
		...info,
		label: [info.label[0], info.label[1] ? [...info.label[1]] : undefined],
		building: JSON.parse(JSON.stringify(info.building)), // sorry
		ownedSurface: cloneSurfaceTertiaire(info.ownedSurface),
		notOwnedSurfaces: info.notOwnedSurfaces.map(cloneSurfaceTertiaire),
		optionalBuildingData: info.optionalBuildingData ? cloneOptionalBuildingData(info.optionalBuildingData) : undefined,
		lotNumbers: [...info.lotNumbers],
	};

	return clone;
}

export function etablissementApiToFunctionalEntity(
	eApi: EtablissementApi,
	efaStatus: EfaStatus,
): FunctionalDeclaration {
	const enseigne = eApi.periodesEtablissement[0]?.enseigne1Etablissement;

	return newFunctionalDeclaration(
		{
			name: enseigne ? enseigne : eApi.uniteLegale.denominationUniteLegale,
			address: apiAddressToAddressInfo(eApi.adresseEtablissement),
			establishment_period: eApi.periodesEtablissement,
			legal_unit: eApi.uniteLegale,
			description: enseigne ? enseigne : eApi.uniteLegale.denominationUniteLegale,
		},
		efaStatus,
	);
}

export function apiAddressToAddressInfo(address: ApiAddress): AddressInfo {
	return {
		cityCode: address.codeCommuneEtablissement,
		cityName: address.libelleCommuneEtablissement,
		postalCode: address.codePostalEtablissement,
		houseNumber: address.numeroVoieEtablissement ?? "",
		streetName: address.libelleVoieEtablissement,
		details: address.complementAdresseEtablissement ?? "",
		streetType: StreetType[address.typeVoieEtablissement] ?? StreetType.VOID,
	};
}

export function associationToAddressInfo(association: AssociationApi): AddressInfo {
	// Enum name -> value
	const streetType = Object.entries(StreetType)
		.map(([key, value]) => ({ key, value }))
		.find(({ key }) => key === association.street_type_asso)?.value;

	return {
		streetType: streetType ?? StreetType.VOID,
		cityCode: association.com_code_asso ?? "",
		cityName: association.com_name_asso ?? "",
		details: "",
		houseNumber: association.street_number_asso ?? "",
		postalCode: association.pc_address_asso ?? "",
		streetName: association.street_name_asso ?? "",
	};
}

export interface LinkedEFA {
	refOperat: Nullable<string>;
	surfaceEfa: Nullable<number>;
}

export function cloneLinkedEfa(linkedEfa: Nullable<Immutable<LinkedEFA>>) {
	return {
		refOperat: linkedEfa?.refOperat,
		surfaceEfa: linkedEfa?.surfaceEfa,
	};
}

export function entityCanAccessPremiumFeatures(functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>): boolean {
	return functionalDeclaration.is_token_used || functionalDeclaration.is_imported_from_operat;
}
