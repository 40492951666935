import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys as Keys } from "@grs/greenkeys";

@Pipe({
	name: "getIconFromEfaStatus",
})
export class GetIconFromEfaStatusPipe implements PipeTransform {
	transform(
		value: Keys.KEY_CONDOMINIUM | Keys.KEY_OWNER_OCCUPANT | Keys.KEY_LANDLORD | Keys.KEY_TENANT | "empty",
	): unknown {
		switch (value) {
			case Keys.KEY_CONDOMINIUM:
				return "groups";
			case Keys.KEY_LANDLORD:
				return "real_estate_agent";
			case Keys.KEY_OWNER_OCCUPANT:
				return "person";
			case Keys.KEY_TENANT:
				return "handshake";
			case "empty":
				return "person_search";
		}
	}
}
