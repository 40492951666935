import { Pipe, PipeTransform } from "@angular/core";
import { getCategories, Indicator } from "../../assets/data/categories";

@Pipe({
	name: "indicatorFromCode",
})
export class IndicatorFromCodePipe implements PipeTransform {
	transform(indicatorRef: string, subCategoryCode: string): Indicator | undefined {
		return getCategories()
			.map((c) => c.subCategories)
			.flat()
			.find((sc) => sc.code === subCategoryCode)
			?.indicators.flat()
			.find((i) => i.code === indicatorRef);
	}
}
