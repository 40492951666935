import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Page } from "../components/navigation/stepper/stepper.component";
import { hasNotDoneStep } from "../helpers/hasDoneStep";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { Declaration } from "../models/declaration";

@Pipe({
	name: "stepTodo",
})
export class StepTodoPipe implements PipeTransform {
	transform(declaration: Immutable<Declaration>, page: Page): Immutable<FunctionalDeclaration>[] {
		return declaration.declarations_functional.filter(
			(declarationFunctional) => hasNotDoneStep(page, declarationFunctional) !== null,
		);
	}
}
