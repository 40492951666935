<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">{{ state.title }}</h3>
		<div [innerHTML]="state.description"></div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray" [mat-dialog-close]="false">Annuler</button>
		<button *ngIf="state.descriptionTwice" class="btn-stroked-primary" (click)="newModal(state.descriptionTwice)">
			Confirmer
		</button>
		<button *ngIf="!state.descriptionTwice" class="btn-stroked-primary" [mat-dialog-close]="true">Confirmer</button>
	</div>
</div>
