import { Pipe, PipeTransform } from "@angular/core";
import { Nullable } from "../helpers/nullable";

@Pipe({
	name: "followingDay",
})
export class FollowingDayPipe implements PipeTransform {
	transform(date: Nullable<string>): string | null {
		if (!date) {
			return null;
		}
		const dateObj = new Date(date);
		dateObj.setDate(dateObj.getDate() + 1);
		return (
			dateObj.getFullYear() +
			"-" +
			String(dateObj.getMonth() + 1).padStart(2, "0") +
			"-" +
			String(dateObj.getDate()).padStart(2, "0")
		);
	}
}
