import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";

@Component({
	selector: "app-custom-form",
	templateUrl: "./custom-form.component.html",
	styleUrls: ["./custom-form.component.scss"],
})
export class CustomFormComponent implements AfterViewInit {
	formValid = false;
	@ViewChild("form") form: ElementRef<HTMLFormElement> | undefined;

	ngAfterViewInit() {
		if (this.form) {
			this.form.nativeElement.addEventListener("input", () => {
				this.updateFormValidity();
			});
			this.updateFormValidity();
		}
	}

	updateFormValidity() {
		// we have to add a setTimeout, otherwise sometimes it gets the previous state
		setTimeout(() => {
			this.formValid = this.form?.nativeElement.checkValidity() ?? false;
		});
	}
}
