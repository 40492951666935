import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../models/ids";
import { dateToTimestamp } from "../pipes/date-to-timestamp.pipe";
import { periodRange } from "../pipes/period-range.pipe";
import { isHoleInConsumption, noCategoryForYear } from "./conso";

export function getIncompleteFilterFunction(
	year: number,
): (functionalDeclarations: Immutable<FunctionalDeclaration[]>) => Immutable<FunctionalDeclaration[]> {
	return (functionalDeclarations: Immutable<FunctionalDeclaration[]>) =>
		functionalDeclarations.filter((functionalDeclaration) => {
			const period = periodRange(year, functionalDeclaration.infos.favoritePeriod);

			return (
				(functionalDeclaration.is_token_used ||
					(functionalDeclaration.is_imported_from_operat &&
						year > Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION &&
						year < Declaration.ANNUAL_DECLARATION_STARTING_YEAR)) &&
				!functionalDeclaration.not_in_csv &&
				(year < Declaration.ANNUAL_DECLARATION_STARTING_YEAR ||
					functionalDeclaration.years_token_used.includes(year)) &&
				(isHoleInConsumption(functionalDeclaration, dateToTimestamp(period.start), dateToTimestamp(period.end)) ||
					noCategoryForYear(functionalDeclaration, year))
			);
		});
}

export function getFunctionalDeclarationsSelected(
	declaration: Immutable<Declaration>,
): Immutable<FunctionalDeclaration[]> {
	return filterFunctionalDeclarations(
		declaration.declarations_functional,
		declaration.chosen_declaration_functional_ids_for_csv,
	);
}

export function filterFunctionalDeclarations(
	functionalDeclarations: Immutable<FunctionalDeclaration[]>,
	ids: Immutable<Set<FunctionalDeclarationId>>,
): Immutable<FunctionalDeclaration[]> {
	return functionalDeclarations.filter(
		(entity) =>
			(entity.is_token_used || entity.is_imported_from_operat) &&
			entity.declaration_functional_id &&
			(ids.size === 0 || ids.has(entity.declaration_functional_id)),
	);
}

export function getEntitiesWithoutReferenceYear(
	functionalDeclarations: Immutable<FunctionalDeclaration[]>,
): Immutable<FunctionalDeclaration[]> {
	const paidEntities = functionalDeclarations.filter((functionalDeclaration) => {
		return functionalDeclaration.is_token_used && !functionalDeclaration.not_in_csv;
	});
	return getIncompleteFilterForReference(paidEntities);
}

export function getIncompleteFilterForReference(
	functionalDeclarations: Immutable<FunctionalDeclaration[]>,
): Immutable<FunctionalDeclaration[]> {
	return functionalDeclarations.filter((functionalDeclaration) => {
		return (
			!functionalDeclaration.infos.referenceYear ||
			getIncompleteFilterFunction(functionalDeclaration.infos.referenceYear)([functionalDeclaration]).length > 0
		);
	});
}
