import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";

@Pipe({
	name: "isElectricCarDockEntriesOverlapping",
})
export class IsElectricCarDockEntriesOverlappingPipe implements PipeTransform {
	transform(
		electricCarDockEntry: { start: number; end?: number },
		electricCarDockEntries: Immutable<{ start: number; end?: number; count: number }[]>,
	): boolean {
		let res = false;
		const aceEnd = electricCarDockEntry.end ?? new Date().getTime() / 1000;
		const aceStart = electricCarDockEntry.start;
		electricCarDockEntries.forEach((electricCarDockEntry2) => {
			const ace2End = electricCarDockEntry2.end ?? new Date().getTime() / 1000;
			const ace2Start = electricCarDockEntry2.start;
			if (
				electricCarDockEntry2 !== electricCarDockEntry &&
				((aceStart <= ace2Start && ace2Start < aceEnd) ||
					(aceStart < ace2End && ace2End <= aceEnd) ||
					(ace2Start <= aceStart && aceStart < ace2End) ||
					(ace2Start < aceEnd && aceEnd <= ace2End) ||
					(aceStart === ace2Start && aceEnd === ace2End))
			) {
				res = true;
			}
		});
		return res;
	}
}
