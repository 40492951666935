import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { getCategoryFromSubCategoryCode } from "../helpers/category-helpers";
import { FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "sumCategoryPeriod",
})
export class SumCategoryPeriodPipe implements PipeTransform {
	// Sum the surfaces of the category between the given period
	transform(
		declarationFunctional: Immutable<FunctionalDeclaration>,
		periodStart: number,
		periodEnd: number,
		categoryIgnore: string[] = [],
	): number {
		let surfaceSum = 0;
		declarationFunctional.infos.asset.categories.forEach((categoryEntry) => {
			const start = Math.max(periodStart, categoryEntry.start);
			const end = Math.min(periodEnd, categoryEntry.end ?? periodEnd);

			if (
				end > periodStart &&
				start < periodEnd &&
				!categoryIgnore.find(
					(category) => category === getCategoryFromSubCategoryCode(categoryEntry.subCategoryCode).code,
				)
			) {
				surfaceSum += categoryEntry.surface * ((end - start) / (periodEnd - periodStart));
			}
		});
		return surfaceSum;
	}
}
