import { Component, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { EMPTY, Observable, ReplaySubject, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { unwrap } from "src/app/helpers/unwrap";
import { BaseComponent } from "src/app/models/base-component.directive";
import { cloneLazyDeclaration, Declaration } from "src/app/models/declaration";
import { Lazy } from "src/app/models/lazy";
import { RouteDealer } from "src/app/models/routes";
import { CsvService } from "src/app/services/csv.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { range } from "src/app/helpers/array";
import { dialogOpen } from "src/app/models/modal";
import { DeclarationService } from "src/app/services/declaration.service";
import { TourStateService } from "src/app/services/tour-state.service";
import { getEntitiesWithoutReferenceYear, getIncompleteFilterFunction } from "../../../../helpers/csv-conso-helpers";
import { Reason } from "../../../../helpers/hasDoneStep";
import { Nullable } from "../../../../helpers/nullable";
import { DialogComponent, HelpSubject } from "../../../help/help.component";
import { PdfReportComponent } from "../../pdf-report/pdf-report.component";
import { ChoiceEntityModalComponent } from "./choice-entity-modal/choice-entity-modal.component";
import { countInvalidDatesEntity } from "../siren-form/siren-form.component";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;
import MAXIMUM_YEAR_INITIAL_DECLARATION = Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION;

@Component({
	selector: "app-csv",
	templateUrl: "./csv.component.html",
	styleUrls: ["./csv.component.scss"],
})
export class CsvComponent extends BaseComponent {
	readonly MAXIMUM_YEAR = new Date().getFullYear();
	readonly ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

	@ViewChild("unavailableCsv") unavailableCsv!: TemplateRef<MatDialog>;

	years = range(Declaration.MINIMUM_YEAR, 2020);

	annualDeclarationYears = range(ANNUAL_DECLARATION_STARTING_YEAR, this.MAXIMUM_YEAR);

	search = "";

	declaration$: Observable<Declaration>;
	/**
	 * EFA = Entité Fonctionnelle Assujettie
	 */
	efa$: Observable<{ file: SafeUrl; filename: string }>;

	constructor(
		private csvService: CsvService,
		private sanitizer: DomSanitizer,
		public declarationState: DeclarationStateService,
		private declarationService: DeclarationService,
		private tourState: TourStateService,
		private dialog: MatDialog,
		private router: Router,
	) {
		super();

		this.tourState.initialize([
			{
				anchorId: "csv-efa",
				title: "Fichier EFA",
				content:
					"Le fichier EFA permet de déclarer votre patrimoine. Il contient les données administratives sur vos entités fonctionnelles (SIRET, adresse, parcelle, etc.).",
			},
			{
				anchorId: "csv-consumption",
				title: "Fichier Consommations",
				content:
					"Les fichiers de consommations contiennent les données de consommations et les informations des catégories d’activité. Pour chaque entité, il faudra télécharger les fichiers 2020, 2021, et celui de l’année de référence.",
			},
		]);

		// we have to use a subject to buffer the declaration. Otherwise, each observable created with ".pipe" will
		// call getNested$ and that's not good
		const declaration$ = new ReplaySubject<Declaration>(1);

		this.sub(
			declarationState.get$.pipe(
				switchMap((declaration) => (declaration?.value.declaration_id ? of(declaration.value.declaration_id) : EMPTY)),
				switchMap((declarationId) => this.declarationService.getNested$(declarationId)),
				map(Declaration.fromApi),
				switchMap((declaration) => {
					// Check if at least one EFA has been paid
					const paidEFA = declaration.declarations_functional.filter(
						(entity) => entity.is_token_used || entity.is_imported_from_operat,
					);
					if (declaration.declarations_functional.length === 0 || paidEFA.length > 0) {
						return of(declaration);
					}

					setTimeout(
						() =>
							this.router.navigate(
								RouteDealer.qualification(unwrap(declaration.declarations_functional[0].declaration_functional_id)),
								{
									queryParams: { error: Reason.NoMainParcel },
								},
							),
						0,
					);
					return EMPTY;
				}),
			),
			declaration$,
		);

		this.declaration$ = declaration$;

		this.efa$ = declaration$.pipe(
			switchMap(({ declaration_id, chosen_declaration_functional_ids_for_csv, structure }) =>
				zip(
					this.csvService.getEfa$(unwrap(declaration_id), Array.from(chosen_declaration_functional_ids_for_csv)),
					of(structure),
				),
			),
			// check if the file doesn't have lines (excluding the headers line)
			switchMap(([text, structure]) => (text.split("\n").length > 1 ? of({ text, structure }) : EMPTY)),
			map(({ text, structure }) => {
				const structureId = getStructureId(structure);

				return {
					file: this.toSafeCsvFileUrl(text),
					filename: `${structureId}_EFA_${date}`,
				};
			}),
		);

		const date = getDateString();
	}

	readonly RouteDealer = RouteDealer;

	startTour() {
		this.tourState.startInitialized();
	}

	toSafeCsvFileUrl(text: string): SafeUrl {
		return this.sanitizer.bypassSecurityTrustUrl(
			URL.createObjectURL(new Blob([text], { type: "text/csv;charset=utf-8" })),
		);
	}

	openPdfReportModal(declaration: Declaration) {
		this.dialog.open(PdfReportComponent, {
			data: declaration,
			panelClass: "p-0",
		});
	}

	readonly getIgnoredEntitiesCount = (declaration: Immutable<Lazy<Declaration>>) =>
		declaration.declarations_functional.filter(({ is_token_used, not_in_csv }) => is_token_used && not_in_csv).length;

	openFileRejectedModal() {
		dialogOpen(
			this.dialog,
			DialogComponent,
			{ subject: HelpSubject.FileRejectedOperat, argument: "" },
			{ panelClass: "p-0" },
		);
	}

	openChoiceEfaModal(declaration: Lazy<Declaration>) {
		dialogOpen(
			this.dialog,
			ChoiceEntityModalComponent,
			{
				alreadyChosen: declaration.chosen_declaration_functional_ids_for_csv,
				functionalEntities: declaration.declarations_functional,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.pipe(
				switchMap((chosenEntities) => {
					if (!chosenEntities) {
						return EMPTY;
					}
					const clone = cloneLazyDeclaration(declaration); // I clone because I want to be sure that I only have the lazy declaration to not upload everything
					clone.chosen_declaration_functional_ids_for_csv = chosenEntities;
					return this.declarationState.updateSync$(clone);
				}),
			)
			.subscribe();
	}

	redirectToConsommation() {
		this.dialog.closeAll();
		this.declaration$.subscribe((declaration) => {
			const fd = declaration.declarations_functional[0];
			this.router.navigate(RouteDealer.consoTable(unwrap(fd[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID]))).then();
		});
	}

	declarationHasNewlyLiableEntities(declaration: Nullable<Immutable<Declaration>>): boolean {
		return (
			!!declaration &&
			!!declaration.declarations_functional.find(
				(functionalDeclaration) => functionalDeclaration.first_year_declaration,
			)
		);
	}

	readonly countInvalidDatesEntity = countInvalidDatesEntity;

	updateDeclaration(decla: Declaration) {
		this.declarationState.updateSync$(decla).subscribe();
	}

	openLockedYearDialog(isInitialDeclaration: boolean = false, from: "csv" | "api" = "csv") {
		this.dialog.open(this.unavailableCsv, { data: { isInitialDeclaration, from }, panelClass: "p-0" });
	}

	protected readonly getIncompleteFilterFunction = getIncompleteFilterFunction;
	protected readonly getEntitiesWithoutReferenceYear = getEntitiesWithoutReferenceYear;
	readonly filterIsIncomplete2021 = getIncompleteFilterFunction(2021);
	readonly filterIsIncomplete2020 = getIncompleteFilterFunction(2020);

	protected readonly HelpSubject = HelpSubject;
	protected readonly MAXIMUM_YEAR_INITIAL_DECLARATION = MAXIMUM_YEAR_INITIAL_DECLARATION;
}

function getDateString(): string {
	const date = new Date();
	const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
	const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
	const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
	return `${da}${mo}${ye}`;
}

function getStructureId(structure: Declaration["structure"]): string {
	return "siren" in structure ? structure.siren : "rna" in structure ? structure.rna : structure.id;
}
