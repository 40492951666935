<div *ngIf="authRepresentatives.length === 1 && authRepresentatives[0].tokens === 0" class="w-100">
	<h1 class="primary">Vous souhaitez aller plus loin dans la saisie de vos déclarations dans le Configurateur ?</h1>
	<ul>
		<li>Déclarez votre patrimoine,</li>
		<li>Récupérez automatiquement les données de consommation sur 10 ans,</li>
		<li>Automatisez le calcul de votre année de référence et objectifs,</li>
		<li>Générez vos déclarations</li>
	</ul>
	<h4 class="text-secondary">Vous n'avez plus qu'à les charger sur OPERAT</h4>
	<h1 class="primary">Continuer mes déclarations :</h1>
</div>
<mat-tab-group
	class="w-100 d-flex"
	mat-stretch-tabs
	(selectedIndexChange)="triggerSelectStep($event)"
	[(selectedIndex)]="tabIndex"
>
	<mat-tab label="Achat de crédit">
		<div class="d-flex-col w-100" style="display: inline-flex; justify-content: center">
			<div *ngIf="!success" class="d-flex" style="justify-content: space-evenly">
				<table class="w-100">
					<tr>
						<td><h3 class="accent2 text-center">Crédits initiaux</h3></td>
						<td><h3 class="accent4 text-center">Crédits annuels</h3></td>
					</tr>
					<tr>
						<td>
							<div class="img-container">
								<img
									src="assets/img/crédit-déclaration-initiale.svg"
									alt="crédit-déclaration-initiale"
									(click)="switchTab(1)"
								/>
							</div>
						</td>
						<td style="position: relative">
							<div style="position: absolute; bottom: 0; width: 100%">
								<img
									src="assets/img/crédit-déclaration-annuelle.svg"
									alt="crédit-déclaration-annuelle"
									(click)="switchTab(2)"
								/>
							</div>
						</td>
					</tr>
					<tr>
						<td class="text-explication pt-2 pr-5">
							<p>
								Les crédits initiaux permettent d'effectuer la déclaration d'entrée dans le dispositif du décret
								tertiaire.<br />
								Ils permettent notamment :
							</p>
							<ul>
								<li>D'étudier votre assujetissement,</li>
								<li>De faire votre déclaration patrimoniale,</li>
								<li>De déclarer vos consommations 2020 et 2021,</li>
								<li>D'identifier et de déclarer votre année de référence.</li>
							</ul>
						</td>
						<td class="text-explication pt-2 pl-5">
							<p>
								Les crédits annuels permettent d'effectuer les déclarations annuels, qui suivent la déclaration
								initiale.<br />
								Ils permettent notamment :
							</p>
							<ul>
								<li>De déclarer vos évolutions patrimoniales chaque année,</li>
								<li>De déclarer vos consommations annuelles</li>
							</ul>
							<p>Un crédit annuel devra être consommé par EFA et par année déclarée après 2021.</p>
						</td>
					</tr>
					<tr>
						<td>
							<div class="d-flex mt-2" style="justify-content: center">
								<button type="button" class="btn btn-primary" (click)="switchTab(1)">Acheter</button>
							</div>
						</td>
						<td>
							<div class="d-flex mt-2" style="justify-content: center">
								<button type="button" class="btn btn-primary" (click)="switchTab(2)">Acheter</button>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div *ngIf="success" class="step-content-wrapper">
				<div class="step-content d-flex-col text-center mt-5" style="justify-content: center">
					<div class="icon-done">
						<mat-icon>done</mat-icon>
					</div>
					<h3 class="text-secondary mb-2">Nous vous remercions pour votre achat</h3>
					<span class="text-gray mb-2">
						Votre paiement devrait être validé d'ici peu de temps. Une fois cela fait, vous serez automatiquement
						crédité de vos crédits
					</span>
					<div>
						<button class="btn-text-secondary m-auto" (click)="redirectToMainResource()">
							{{ registration ? "Aller au tableau de bord" : "Retourner à l'accueil" }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="Crédits initiaux" [disabled]="success">
		<app-buy-token-type-form
			[registration]="registration"
			[authRepresentatives]="authRepresentatives"
			[success]="success"
			[unitPrice]="200"
			[tokenType]="TokenType.INITIAL_TOKEN"
			title="Crédits initiaux"
			colorClass="accent2"
			description="Un crédit initial permet d'effectuer la déclaration d'une entité fonctionnelle. De manière générale, une entité fonctionnelle correspondra à un SIRET."
			[functionDisplayRemainingToken]="functionRemainingInitialToken"
		></app-buy-token-type-form>
	</mat-tab>
	<mat-tab label="Crédits annuels" [disabled]="success">
		<app-buy-token-type-form
			[registration]="registration"
			[authRepresentatives]="authRepresentatives"
			[success]="success"
			[unitPrice]="150"
			[tokenType]="TokenType.ANNUAL_TOKEN"
			title="Crédits annuels"
			colorClass="accent4"
			description="Un crédit annuel permet d'effectuer la déclaration annuelle d'une entité fonctionnelle. De manière générale, une entité fonctionnelle correspondra à un SIRET."
			[functionDisplayRemainingToken]="functionRemainingAnnualToken"
		></app-buy-token-type-form>
	</mat-tab>
</mat-tab-group>
