/* eslint-disable @typescript-eslint/no-namespace */
import { Immutable } from "immer";
import { Nullable } from "../helpers/nullable";
import { unwrap } from "../helpers/unwrap";
import { DeclarationGroup } from "./declarationGroup";
import {
	cloneFunctionalDeclaration,
	cloneLazyFunctionalDeclaration,
	FunctionalDeclaration,
} from "./functionalDeclaration";
import { IdOther, Rna, Siren, Siret } from "./identifier";
import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId } from "./ids";
import { Lazy } from "./lazy";
import { Representative } from "./representative";
import { ResourceInfo, ResourceType } from "./resource";

export function newDeclaration(data: Partial<Declaration> & { structure: Declaration["structure"] }): Declaration {
	return {
		...data,
		declarations_functional: data.declarations_functional ?? [],
		is_mandated: data.is_mandated ?? false,
		is_demo: data.is_demo ?? false,
		chosen_declaration_functional_ids_for_csv: new Set(),
		operat_user_key: data.operat_user_key,
	};
}

export interface Declaration {
	declaration_id?: Nullable<DeclarationId>;
	declaration_group_id?: Nullable<DeclarationGroupId>;
	structure: { rna: Rna } | { siren: Siren; maybeRna?: Nullable<Rna> } | IdOther;
	ape_code?: Nullable<string>;
	name?: Nullable<string>;
	sirets?: Nullable<Siret[]>;
	status?: Nullable<string>;
	main_parcel?: Nullable<string>;
	declarations_functional: FunctionalDeclaration[];
	is_mandated: boolean;
	is_demo: boolean;
	chosen_declaration_functional_ids_for_csv: Set<FunctionalDeclarationId>;
	operat_user_key: Nullable<string>;
}

export namespace Declaration {
	export const MINIMUM_YEAR = 2009;
	export const MINIMUM_DATE = `${MINIMUM_YEAR}-01-01`;
	export const MINIMUM_DATE_TIME = new Date(MINIMUM_DATE).getTime() / 1000;
	export const MAXIMUM_YEAR_INITIAL_DECLARATION = 2020;
	export const ANNUAL_DECLARATION_STARTING_YEAR = 2022;
	export const ANNUAL_DECLARATION_STARTING_DATE = `${ANNUAL_DECLARATION_STARTING_YEAR}-01-01`;
	export const ANNUAL_DECLARATION_STARTING_DATE_TIME = new Date(ANNUAL_DECLARATION_STARTING_DATE).getTime() / 1000;

	/**
	 * Why an API interface? Because sometimes the server gives us garbage we don't care about.
	 * However, this garbage can be important. For example, if `declaration_functional_ids` (in the Declaration interface)
	 * is not correct then the server will delete entities. So to be less error prone we will hide
	 * this "feature" thanks to this interface.
	 * Besides, this makes us able to do changes without modifying the server.
	 */
	export interface Api {
		readonly DeclarationApi: unique symbol;

		declaration_id?: Nullable<DeclarationId>;
		declaration_group_id?: Nullable<DeclarationGroupId>;
		siren?: Nullable<Siren>;
		rna?: Nullable<Rna>;
		ape_code?: Nullable<string>;
		name?: Nullable<string>;
		sirets?: Nullable<Siret[]>;
		status?: Nullable<string>;
		main_parcel?: Nullable<string>;
		// data given by getNested can have both old and new types
		declarations_functional: FunctionalDeclaration.Api[];
		declaration_functional_ids?: Nullable<FunctionalDeclarationId[]>;
		is_mandated: boolean;
		is_demo: boolean;

		other_id: Nullable<string>;
		other_label: Nullable<string>;

		chosen_declaration_functional_ids_for_csv: Nullable<FunctionalDeclarationId[]>;

		operat_user_key: Nullable<string>;
	}

	export type SearchApi = Omit<Declaration.Api, "declarations_functional">;
	export type SearchDeclaration = Omit<Declaration, "declarations_functional">;
	export type DeclarationFromApiType<T extends SearchApi | Api> = T extends Api ? Declaration : SearchDeclaration;

	export function fromApi<T extends SearchApi | Api>(api: T): DeclarationFromApiType<T>;
	export function fromApi(api: Api | SearchApi): DeclarationFromApiType<typeof api> {
		return {
			...("declarations_functional" in api
				? { declarations_functional: api.declarations_functional.map(FunctionalDeclaration.fromApi) }
				: {}),
			structure: api.siren
				? { siren: api.siren, maybeRna: api.rna }
				: api.rna
					? { rna: api.rna }
					: { id: api.other_id ?? "", label: api.other_label ?? "" },
			ape_code: api.ape_code,
			declaration_group_id: api.declaration_group_id,
			declaration_id: api.declaration_id,
			main_parcel: api.main_parcel,
			name: api.name,
			sirets: api.sirets,
			status: api.status,
			is_mandated: api.is_mandated,
			is_demo: api.is_demo,
			chosen_declaration_functional_ids_for_csv: api.chosen_declaration_functional_ids_for_csv
				? new Set(api.chosen_declaration_functional_ids_for_csv)
				: new Set(),
			operat_user_key: api.operat_user_key,
		};
	}

	export function fromLazyApi(api: Lazy<Api>): Lazy<Declaration> {
		return {
			declarations_functional: api.declarations_functional.map(FunctionalDeclaration.fromLazyApi),
			structure: api.siren
				? { siren: api.siren, maybeRna: api.rna }
				: api.rna
					? { rna: api.rna }
					: { id: api.other_id ?? "", label: api.other_label ?? "" },
			ape_code: api.ape_code,
			declaration_group_id: api.declaration_group_id,
			declaration_id: api.declaration_id,
			main_parcel: api.main_parcel,
			name: api.name,
			sirets: api.sirets,
			status: api.status,
			is_mandated: api.is_mandated,
			is_demo: api.is_demo,
			chosen_declaration_functional_ids_for_csv: api.chosen_declaration_functional_ids_for_csv
				? new Set(api.chosen_declaration_functional_ids_for_csv)
				: new Set(),
			operat_user_key: api.operat_user_key,
		};
	}

	export function toApi(declaration: Immutable<Declaration>): Immutable<Api> {
		const { structure } = declaration;
		const functionalIds: FunctionalDeclarationId[] = [];
		declaration.declarations_functional.forEach(({ declaration_functional_id }) => {
			if (declaration_functional_id) {
				functionalIds.push(declaration_functional_id);
			}
		});
		const api: Omit<Immutable<Api>, "DeclarationApi"> = {
			declarations_functional: declaration.declarations_functional.map(FunctionalDeclaration.toApi),
			ape_code: declaration.ape_code,
			declaration_functional_ids: functionalIds,
			declaration_group_id: declaration.declaration_group_id,
			declaration_id: declaration.declaration_id,
			main_parcel: declaration.main_parcel,
			name: declaration.name,
			rna: "rna" in structure ? structure.rna : "maybeRna" in structure ? structure.maybeRna : undefined,
			siren: "siren" in structure ? structure.siren : undefined,
			sirets: declaration.sirets,
			status: declaration.status,
			is_mandated: declaration.is_mandated,
			is_demo: declaration.is_demo,
			other_id: "id" in structure ? structure.id : undefined,
			other_label: "label" in structure ? structure.label : undefined,
			chosen_declaration_functional_ids_for_csv: Array.from(declaration.chosen_declaration_functional_ids_for_csv),
			operat_user_key: declaration.operat_user_key,
		};

		return api as Immutable<Api>;
	}

	export function toLazyApi(declaration: Immutable<Lazy<Declaration>>): Immutable<Lazy<Api>> {
		const { structure } = declaration;
		const functionalIds: FunctionalDeclarationId[] = [];
		declaration.declarations_functional.forEach(({ declaration_functional_id }) => {
			if (declaration_functional_id) {
				functionalIds.push(declaration_functional_id);
			}
		});
		const api: Omit<Immutable<Lazy<Declaration.Api>>, "DeclarationApi"> = {
			declarations_functional: declaration.declarations_functional.map(FunctionalDeclaration.toLazyApi),
			ape_code: declaration.ape_code,
			declaration_functional_ids: functionalIds,
			declaration_group_id: declaration.declaration_group_id,
			declaration_id: declaration.declaration_id,
			main_parcel: declaration.main_parcel,
			name: declaration.name,
			rna: "rna" in structure ? structure.rna : "maybeRna" in structure ? structure.maybeRna : undefined,
			siren: "siren" in structure ? structure.siren : undefined,
			sirets: declaration.sirets,
			status: declaration.status,
			is_mandated: declaration.is_mandated,
			is_demo: declaration.is_demo,
			other_id: "id" in structure ? structure.id : undefined,
			other_label: "label" in structure ? structure.label : undefined,
			chosen_declaration_functional_ids_for_csv: Array.from(declaration.chosen_declaration_functional_ids_for_csv),
			operat_user_key: declaration.operat_user_key,
		};

		return api as Immutable<Lazy<Api>>;
	}

	export function getIdentifier({ structure }: Immutable<Lazy<Declaration>>): string {
		return "rna" in structure ? structure.rna : "siren" in structure ? structure.siren : structure.label;
	}
}

export function cloneDeclaration(declaration: Immutable<Declaration>): Declaration {
	const clone: Declaration = {
		...declaration,
		sirets: declaration.sirets ? [...declaration.sirets] : undefined,
		declarations_functional: declaration.declarations_functional.map(cloneFunctionalDeclaration),
		structure: { ...declaration.structure },
		chosen_declaration_functional_ids_for_csv: new Set(declaration.chosen_declaration_functional_ids_for_csv),
	};

	return clone;
}

export function cloneLazyDeclaration(declaration: Immutable<Lazy<Declaration>>): Lazy<Declaration> {
	return {
		...declaration,
		sirets: declaration.sirets ? [...declaration.sirets] : undefined,
		declarations_functional: declaration.declarations_functional.map(cloneLazyFunctionalDeclaration),
		structure: { ...declaration.structure },
		chosen_declaration_functional_ids_for_csv: new Set(declaration.chosen_declaration_functional_ids_for_csv),
	};
}

export function resourceInfoFromResource(
	resource: Immutable<Representative | DeclarationGroup | Declaration | FunctionalDeclaration>,
): ResourceInfo {
	if ("declaration_groups" in resource) {
		return { id: resource.representative_id, type: ResourceType.Representative };
	}
	if ("declarations" in resource) {
		return { id: unwrap(resource.declaration_group_id), type: ResourceType.DeclarationGroup };
	}
	if ("declarations_functional" in resource) {
		return { id: unwrap(resource.declaration_id), type: ResourceType.Declaration };
	}
	return { id: unwrap(resource.declaration_functional_id), type: ResourceType.FunctionalDeclaration };
}
