import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Immutable } from "immer";
import { map } from "rxjs/operators";
import { compareFunctionalDeclaration } from "src/app/helpers/sort-by-name";
import { unwrap } from "src/app/helpers/unwrap";
import { Declaration } from "src/app/models/declaration";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { Lazy } from "src/app/models/lazy";
import { DeclarationService } from "src/app/services/declaration.service";

@Component({
	selector: "app-declaration-functional-list-dialog",
	templateUrl: "./declaration-functional-list-dialog.component.html",
	styleUrls: ["./declaration-functional-list-dialog.component.scss"],
})
export class DeclarationFunctionalListDialogComponent {
	declarationsFunctional: FunctionalDeclaration[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			declaration: Immutable<Lazy<Declaration>>;
		},
		declarationService: DeclarationService,
	) {
		declarationService
			.getNested$(unwrap(data.declaration.declaration_id))
			.pipe(map(Declaration.fromApi))
			.subscribe((declaration) => {
				this.declarationsFunctional = declaration.declarations_functional.sort(compareFunctionalDeclaration);
			});
	}
}
