<div class="custom-input-group">
	<label required class="d-flex" style="align-items: end" (click)="$event.preventDefault()"
		>CSV OPERAT <app-help [subject]="HelpSubject.CsvOperat"></app-help>
	</label>
	<div class="message-warning">
		<span>Seuls les fichiers de consommations de l'année 2021 peuvent être importées de cette manière.</span>
	</div>
	<span
		>Attention, les EFA sans SIRET, qui n'appartiennent ni à des Collectivités ni à des Opérateurs de l'état, ne seront
		pas traitées lors de cet import.</span
	>
	<div
		class="drop-zone custom-input d-flex"
		[ngClass]="{ error: isInvalid }"
		(drop)="handleCsvDrop($event)"
		(dragover)="$event.preventDefault()"
		(click)="fileInput.click()"
	>
		<div class="d-flex-col" style="justify-content: center">
			<ng-container *ngIf="csvFile; else dropCsv">
				<mat-icon class="mx-auto">description</mat-icon>
				{{ csvFile.name }}
			</ng-container>
			<ng-template #dropCsv>
				<mat-icon class="mx-auto">download</mat-icon>
				<span>Déposez votre fichier CSV</span>
				<span>Ou</span>
				<span>Cliquez pour choisir un fichier</span>
			</ng-template>
			<input #fileInput type="file" hidden (change)="handleFileInput(fileInput.files)" />
		</div>
	</div>
</div>
