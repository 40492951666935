import { Pipe, PipeTransform } from "@angular/core";
import { Siren } from "../models/identifier";

@Pipe({
	name: "checkSirenForCsv",
})
export class CheckSirenForCsvPipe implements PipeTransform {
	transform(csvFile: File, siren: Siren): boolean {
		return csvFile.name.split("_")[0] === siren;
	}
}
