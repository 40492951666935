<div *ngIf="declaration$ | async as declaration" class="reference-year">
	<div class="d-flex" style="justify-content: space-between">
		<p class="title">5. Recherche de l'année de référence</p>

		<button class="btn-primary" (click)="startTour()">
			<mat-icon class="mr-1">swipe_up</mat-icon>
			Guide interactif
		</button>
	</div>
	<h2 class="subtitle mb-1">Récapitulatif des objectifs</h2>
	<div class="explicative-text mb-2">
		Voici les valeurs des différents objectifs absolus et relatifs, pour l'ensemble des entités fonctionnelles de <br />
		votre patrimoine. Vous pouvez désormais accéder à une synthèse de la démarche.
	</div>
	<table style="width: 100%; table-layout: fixed">
		<tr>
			<td></td>
			<td>
				<ng-container *ngFor="let djus of [djus$ | async]">
					<button class="btn-primary-light mx-auto my-2" (click)="openBenchmark(declaration, djus ?? {})">
						<mat-icon class="mr-2">query_stats</mat-icon>
						Graphique comparatif
					</button>
				</ng-container>
			</td>
			<td>
				<button class="btn-primary-light ml-auto my-2" (click)="exportAllCsv(declaration)">
					Exporter toutes les données de consommation
				</button>
			</td>
		</tr>
	</table>

	<div style="width: fit-content; margin: auto">
		<div class="d-flex" style="justify-content: space-between">
			<div class="input-icon">
				<input
					[(ngModel)]="search"
					class="custom-input-light"
					style="min-width: 370px; display: block"
					placeholder="Rechercher une entité fonctionnelle"
					(ngModelChange)="updateSearch(declaration)"
				/>
				<span class="input-suffix">
					{{ filteredDeclarationsFunctional.length }}
					<mat-icon>domain</mat-icon>
				</span>
			</div>
		</div>
		<ng-container *ngFor="let djus of [djus$ | async]">
			<ng-container *ngFor="let absoluteObjectives of [absoluteObjectives$ | async]">
				<app-reference-year-summary-table
					[functionalDeclarations]="declarationsFunctionalToShow"
					[showAbsoluteObjectiveError]="hasWrongAbsoluteObjective"
					[loading]="loading"
					[djus]="djus ?? {}"
					[absoluteObjectives]="absoluteObjectives ?? {}"
					(calculateObjectives)="recalculateObjectives(declaration, true)"
				></app-reference-year-summary-table>
			</ng-container>
		</ng-container>
		<app-pagination [currentPage]="page" [maxPage]="maxPage" (pageChange)="setPage($event)"></app-pagination>
	</div>
</div>
