<div class="dialog-container">
	<div class="dialog-header bg-danger">
		<div class="d-flex-col" style="justify-content: center">
			<h3 class="my-0">
				{{ state.errors.length > 1 ? "Des" : "Une" }} erreur{{ state.errors.length > 1 ? "s" : "" }}
				{{ state.errors.length > 1 ? "sont" : "est" }} survenue{{ state.errors.length > 1 ? "s" : "" }}
				lors du transfert
			</h3>
		</div>
	</div>
	<div class="dialog-content pt-0" style="position: relative">
		<!--    <div class="dialog-small-text">-->
		<p>
			{{ state.errors.length > 1 ? "Des" : "Une" }} erreur{{ state.errors.length > 1 ? "s" : "" }}
			{{ state.errors.length > 1 ? "sont" : "est" }} survenue{{ state.errors.length > 1 ? "s" : "" }}
			lors de la communication avec OPERAT. Une ou plusieurs déclarations de consommations n'ont pas pu être
			transférées.
		</p>
		<p>Erreur{{ state.errors.length > 1 ? "s" : "" }} :</p>
		<ng-container *ngIf="state.errors | apply: getGlobalErrors as globalErrors">
			<b *ngFor="let error of globalErrors">{{ error.code | operatApiErrorMessage: state.year }}</b>
		</ng-container>
		<ng-container *ngIf="state.errors | apply: getSpecificErrors as specificErrors">
			<div class="mt-2 mb-2" *ngFor="let error of specificErrors">
				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								{{ error.code | operatApiErrorMessage: state.year }}
							</mat-panel-title>
							<mat-panel-description>
								{{ error.code }}
							</mat-panel-description>
						</mat-expansion-panel-header>
						<table mat-table [dataSource]="error.concernedEntities" class="mat-elevation-z8">
							<ng-container matColumnDef="refOperat">
								<th mat-header-cell *matHeaderCellDef>Référence OPERAT</th>
								<td mat-cell *matCellDef="let element">{{ element.ref }}</td>
							</ng-container>

							<ng-container matColumnDef="name">
								<th mat-header-cell *matHeaderCellDef>Nom de l'EFA</th>
								<td mat-cell *matCellDef="let element">
									<!--If error is about categories, add a link to entity's asset declarations-->
									<ng-container *ngIf="element.id && error.code === OperatApiErrorType.CATEGORY_ERROR; else onlyName">
										<a
											[routerLink]="element.id | apply: RouteDealer.assetDeclaration"
											(click)="close(undefined)"
											class="text-secondary"
										>
											{{ element.name }}
										</a>
									</ng-container>
									<ng-template #onlyName>{{ element.name }}</ng-template>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
						</table>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</ng-container>
		<!--    </div>-->
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray ml-auto" [mat-dialog-close]="undefined">OK</button>
	</div>
</div>
