import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import {
	ASSET_PAGE,
	CONSO_PAGE,
	Page,
	QUALIFICATION_PAGE,
	SEARCH_PAGE,
} from "../components/navigation/stepper/stepper.component";
import { hasNotDoneStep, Reason } from "../helpers/hasDoneStep";
import { FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "canReachStep",
})
export class CanReachStepPipe implements PipeTransform {
	transform = canReachStep;
}

export function canReachStep(functionalDeclaration: Immutable<FunctionalDeclaration>, page: Page): boolean {
	if (page === ASSET_PAGE && hasNotDoneStep(QUALIFICATION_PAGE, functionalDeclaration) !== null) {
		return false;
	}

	if (page === CONSO_PAGE && hasNotDoneStep(ASSET_PAGE, functionalDeclaration) !== null) {
		return false;
	}

	if (page === SEARCH_PAGE && hasNotDoneStep(CONSO_PAGE, functionalDeclaration) !== null) {
		return false;
	}

	return true;
}

export function canNotReachStep(functionalDeclaration: Immutable<FunctionalDeclaration>, page: Page): Reason | null {
	if (page === ASSET_PAGE) {
		const reason = hasNotDoneStep(QUALIFICATION_PAGE, functionalDeclaration);
		if (reason !== null) {
			return reason;
		}
	}

	if (page === CONSO_PAGE) {
		const reason = hasNotDoneStep(ASSET_PAGE, functionalDeclaration);
		if (reason !== null) {
			return reason;
		}
	}

	if (page === SEARCH_PAGE) {
		const reason = hasNotDoneStep(CONSO_PAGE, functionalDeclaration);
		if (reason !== null) {
			return reason;
		}
	}

	return null;
}
