import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChangeLog } from "../../../models/changelog";

@Component({
	selector: "app-new-version-modal",
	templateUrl: "./new-version-modal.component.html",
	styleUrls: ["./new-version-modal.component.scss"],
})
export class NewVersionModalComponent {
	changelog: ChangeLog;

	constructor(@Inject(MAT_DIALOG_DATA) changelog: ChangeLog) {
		this.changelog = changelog;
	}
}
