import { Pipe, PipeTransform } from "@angular/core";
import { hasHoleInConsumptionRow } from "../helpers/conso";
import { EnergyConsumptions } from "../models/functionalDeclaration";

@Pipe({
	name: "hasHoleInConsumptionRow",
})
export class HasHoleInConsumptionRowPipe implements PipeTransform {
	transform(value: EnergyConsumptions, start: number, end: number): boolean {
		return hasHoleInConsumptionRow(value, start, end);
	}
}
