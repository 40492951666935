import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../environments/environment";
import { User } from "../models/user";

@Injectable({
	providedIn: "root",
})
export class UserService {
	constructor(private http: HttpClient) {}

	put$(user: User): Observable<User> {
		return this.http.put(`${environment.baseApiUrl}/users`, user).pipe(map((obj) => User.parse(obj)));
	}
}
