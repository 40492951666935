import { animate, state, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Immutable } from "immer";
import { BehaviorSubject, EMPTY, from, Observable, of } from "rxjs";
import { first, map, switchMap, tap } from "rxjs/operators";
import { DeclarationFunctionalListDialogComponent } from "src/app/components/navigation/stepper/declaration-functional-list-dialog/declaration-functional-list-dialog.component";
import { Declaration } from "src/app/models/declaration";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { Lazy } from "src/app/models/lazy";
import { ResourceType } from "src/app/models/resource";
import { Route, RouteDealer, routeToResourceType } from "src/app/models/routes";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { NavbarService } from "src/app/services/navbar.service";
import * as icons from "src/assets/icons";
import * as text from "src/assets/text";
import { Nullable } from "../../../helpers/nullable";
import { unwrap } from "../../../helpers/unwrap";
import { BaseComponent } from "../../../models/base-component.directive";
import { FunctionalDeclarationId } from "../../../models/ids";
import { DeclarationService } from "../../../services/declaration.service";
import { RepresentativeStateService } from "../../../services/representative-state.service";
import { HelpSubject } from "../../help/help.component";
import { compareFunctionalDeclaration } from "src/app/helpers/sort-by-name";

@Component({
	selector: "app-stepper",
	templateUrl: "./stepper.component.html",
	styleUrls: ["./stepper.component.scss"],
	animations: [
		trigger("collapseHeight", [
			state("open", style({ height: "*", opacity: 1 })),
			state("close", style({ height: 0, opacity: 0 })),
			transition("open => close", [style({ height: "*", opacity: 1 }), animate(150, style({ height: 0, opacity: 0 }))]),
			transition("close => open", [style({ height: 0, opacity: 0 }), animate(150, style({ height: "*", opacity: 1 }))]),
		]),
		trigger("collapse", [
			state("open", style({ width: "*" })),
			state("close", style({ width: 30 })),
			transition("open => close", [style({ width: "*" }), animate(100, style({ width: 30 }))]),
			transition("close => open", [style({ width: 0 }), animate(100, style({ width: "*" }))]),
		]),
	],
})
export class StepperComponent extends BaseComponent implements OnDestroy, AfterViewChecked {
	readonly FUNCTIONAL_DECLARATION_TO_SHOW = 7;
	readonly TOKEN_NOT_USED = text.TOKEN_NOT_USED;
	readonly ICON_TOKEN_NOT_USED = icons.TOKEN_NOT_USED;

	public isOpen: boolean | undefined;
	segment$: BehaviorSubject<string>;
	readonly PAGES = DEFAULT_PAGES;

	nestedDeclaration$: Observable<Immutable<Declaration>>;

	constructor(
		private router: Router,
		declarationState: DeclarationStateService,
		declarationService: DeclarationService,
		public navBarService: NavbarService,
		public functionalDeclarationState: FunctionalDeclarationStateService,
		location: Location,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		public representativeState: RepresentativeStateService,
		private cdRef: ChangeDetectorRef,
	) {
		super();
		if (this.navBarService.getFuncNavState() === null) {
			this.isOpen = true;
		} else {
			this.isOpen = /true/i.test(String(this.navBarService.getFuncNavState()));
		}

		this.nestedDeclaration$ = declarationState.get$.pipe(
			switchMap((declaration) => (declaration ? of(declaration) : EMPTY)),
			switchMap(({ value: { declaration_id } }) => declarationService.getNested$(unwrap(declaration_id))),
			map(Declaration.fromApi),
			tap((declaration) => declaration.declarations_functional.sort(compareFunctionalDeclaration)),
		);

		// We have to do this because when we refresh the page
		// router.events doesn't fire any event.
		const lastSegment = String(location.path().split("?")[0].split("/").pop());
		this.segment$ = new BehaviorSubject(lastSegment);

		this.sub(
			router.events.pipe(
				switchMap((event) => {
					if (event instanceof NavigationEnd) {
						const lastSegment = String(event.url.split("?")[0].split("/").pop());
						return of(lastSegment);
					}
					return EMPTY;
				}),
			),
			this.segment$,
		);
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	get collapseNavState() {
		return this.isOpen ? "open" : "close";
	}

	getcollapseState(page: Page) {
		const segment = this.segment$.value;
		return segment === page.goToSegment || page.segmentsForStepper.some((route) => route === segment)
			? "open"
			: "close";
	}

	selectDeclarationStep(page: Page, declaration: Immutable<Lazy<Declaration>>) {
		return this.activatedRoute.queryParamMap
			.pipe(
				first(),
				// find the declaration with id from the "from" parameter
				map(
					(params) =>
						declaration.declarations_functional.find(
							({ declaration_functional_id }) => declaration_functional_id === params.get("from"),
						)?.declaration_functional_id,
				),
				switchMap((origin) => {
					// get the target functionalDeclarationId, which is the one from the state if defined,
					// otherwise the one from the "from" parameter if defined
					// otherwise we take the first functional declaration from the declaration
					let currentFunctionalDeclarationId: Nullable<FunctionalDeclarationId> =
						this.functionalDeclarationState.getId();
					currentFunctionalDeclarationId = currentFunctionalDeclarationId
						? currentFunctionalDeclarationId
						: origin
							? origin
							: declaration.declarations_functional[0]?.declaration_functional_id;

					return this.changePage$(declaration, currentFunctionalDeclarationId, page);
				}),
			)
			.subscribe();
	}

	selectFunctionalStep(functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>) {
		// If we are on a page that can redirect to a functional-compatible page or if we are directly on the functional-compatible page
		const actualPage = DEFAULT_PAGES.find(
			(page) => page.goToSegment === this.segment$.value || page.goToSegmentFunctional === this.segment$.value,
		);

		if (!actualPage || !actualPage.goToSegmentFunctional) {
			return EMPTY;
		}

		return this.changePage$(null, functionalDeclaration.declaration_functional_id, actualPage, true).subscribe();
	}

	readonly canRedirectToFunctional = (segment: string) => {
		return (
			DEFAULT_PAGES.find((page) => page.goToSegment === segment || page.goToSegmentFunctional === segment)
				?.goToSegmentFunctional !== undefined
		);
	};

	next(declaration: Immutable<Lazy<Declaration>>, currentFunctionalDeclarationId: FunctionalDeclarationId) {
		const pagePosition = DEFAULT_PAGES.findIndex((page) =>
			page.segmentsForStepper.some((route) => route === this.segment$.value),
		);
		if (pagePosition + 1 < DEFAULT_PAGES.length) {
			const nextPage = DEFAULT_PAGES[pagePosition + 1];

			this.changePage$(declaration, currentFunctionalDeclarationId, nextPage).subscribe();
		}
	}

	changePage$(
		declaration: Nullable<Immutable<Lazy<Declaration>>>,
		functionalDeclarationId: Nullable<FunctionalDeclarationId>,
		page: Page,
		goToFunctional: boolean = false,
	): Observable<boolean> {
		return this.representativeState.get$.pipe(
			first(),
			switchMap((representative) => {
				if (
					page !== ADDRESS_PAGE &&
					page !== QUALIFICATION_PAGE &&
					representative?.value &&
					representative.value.tokens === 0
				) {
					// then navigate to payment page
					return from(this.router.navigate(RouteDealer.buyToken(representative.value.representative_id)));
				}
				// check if the user is trying to access another page than address and qualification and hasn't bought tokens yet
				if (
					(routeToResourceType(page.goToSegment) === ResourceType.FunctionalDeclaration ||
						(page.goToSegmentFunctional && goToFunctional)) &&
					functionalDeclarationId
				) {
					return this.functionalDeclarationState
						.select$(functionalDeclarationId)
						.pipe(
							switchMap(() =>
								from(
									this.router.navigate([
										functionalDeclarationId,
										routeToResourceType(page.goToSegment) === ResourceType.FunctionalDeclaration
											? page.goToSegment
											: page.goToSegmentFunctional,
									]),
								),
							),
						);
				}
				if (declaration) {
					const options = functionalDeclarationId ? { from: functionalDeclarationId } : {};
					return from(
						this.router.navigate([declaration.declaration_id, page.goToSegment], {
							queryParams: options,
						}),
					);
				}
				throw new Error("Could not redirect the user.");
			}),
		);
	}

	public shrink(): void {
		this.navBarService.setFuncNav((!this.isOpen).toString());
		this.isOpen = /true/i.test(String(this.navBarService.getFuncNavState()));
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 151);
	}

	public openDeclarationFunctionalListModal(declaration: Immutable<Lazy<Declaration>>) {
		this.dialog.open(DeclarationFunctionalListDialogComponent, { data: { declaration } });
	}

	protected readonly QUALIFICATION_PAGE = QUALIFICATION_PAGE;
}

/**
 * This is used by the stepper. It knows where it has to go thanks to `goToSegment` and knows
 * when to active a step thanks to `segmentsForStepper`.
 */
export interface Page {
	label: string;
	goToSegment: Route;
	segmentsForStepper: Route[];
	// useful for functionalDeclaration isolation
	goToSegmentFunctional?: Route;
	help?: HelpSubject;
}

export const ADDRESS_PAGE: Page = {
	label: "Définition du périmètre",
	goToSegment: Route.Addresses,
	segmentsForStepper: [Route.Addresses],
	help: HelpSubject.Qualification,
};

export const QUALIFICATION_PAGE: Page = {
	label: "Qualification des sites",
	goToSegment: Route.Qualification,
	segmentsForStepper: [Route.Qualification],
	goToSegmentFunctional: Route.Qualification,
	help: HelpSubject.Qualification,
};

export const ASSET_PAGE: Page = {
	label: "Déclaration patrimoniale",
	goToSegment: Route.AssetDeclaration,
	segmentsForStepper: [Route.AssetDeclaration],
	goToSegmentFunctional: Route.AssetDeclaration,
};

export const CONSO_PAGE: Page = {
	label: "Déclaration des consommations",
	goToSegment: Route.ConsoList,
	segmentsForStepper: [Route.ConsoList, Route.ConsoTable],
	goToSegmentFunctional: Route.ConsoTable,
};

export const SEARCH_PAGE: Page = {
	label: "Recherche de l'année de référence",
	goToSegment: Route.Reference,
	segmentsForStepper: [Route.Reference, Route.ReferenceEntity],
	goToSegmentFunctional: Route.ReferenceEntity,
};

export const FILE_PAGE: Page = {
	label: "Génération des fichiers",
	goToSegment: Route.Csv,
	segmentsForStepper: [Route.Csv],
};

export const FUNCTIONAL_PAGES: Page[] = [QUALIFICATION_PAGE, ASSET_PAGE, CONSO_PAGE, SEARCH_PAGE];

export const PROGRESS_PAGES: Page[] = [ADDRESS_PAGE, ...FUNCTIONAL_PAGES];

export const DEFAULT_PAGES: Page[] = [...PROGRESS_PAGES, FILE_PAGE];
