import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import FileSaver from "file-saver";
import { Immutable } from "immer";
import JSZip from "jszip";
import { EMPTY, of, Subscription, zip } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { formatDataForCsv, generateCsvFromGraphData } from "../../../../../helpers/export-graph-to-csv";
import { sequentialJoin } from "../../../../../helpers/sequentialJoin";
import { unwrap } from "../../../../../helpers/unwrap";
import { Declaration } from "../../../../../models/declaration";
import { getOperatReference } from "../../../../../models/efaStatus";
import { FunctionalDeclaration } from "../../../../../models/functionalDeclaration";
import { Lazy } from "../../../../../models/lazy";
import { Modal } from "../../../../../models/modal";
import { DeclarationFunctionalService } from "../../../../../services/declaration-functional.service";
import { DeclarationService } from "../../../../../services/declaration.service";

type Data = {
	djus: { [year: string]: number };
	absoluteObjectives: { [year: string]: number };
	functionalDeclaration: Immutable<FunctionalDeclaration>;
};

type Input = {
	declaration: Immutable<Lazy<Declaration>>;
	declarationService: DeclarationService;
	declarationFunctionalService: DeclarationFunctionalService;
};

type State = {
	declaration: Immutable<Lazy<Declaration>>;
	declarationService: DeclarationService;
	declarationFunctionalService: DeclarationFunctionalService;
};

type Output = undefined;

const ESTIMATE_TIME_PER_ENTITY = 0.2;

@Component({
	selector: "app-download-all-csv-modal",
	templateUrl: "./download-all-csv-modal.component.html",
	styleUrls: ["./download-all-csv-modal.component.scss"],
})
export class DownloadAllCsvModalComponent extends Modal<Input, State, Output> {
	startTime = 0;
	toTreat = -1;
	progress = 0;
	consumptionDatas$: Subscription | undefined;
	activeEstimateDate: Date | undefined;
	stepTime = 0;
	estimateDate: Date | undefined;
	isLoading = false;

	download() {
		this.isLoading = true;
		this.startTime = new Date().getTime();
		this.activeEstimateDate = this.estimateDate;
		if (!this.state.declaration.declaration_id) {
			return;
		}

		const interval = setInterval(() => {
			this.calculateEstimation();
		}, 1000);

		const zipFile = new JSZip();

		this.consumptionDatas$ = this.state.declarationService
			.getNested$(this.state.declaration.declaration_id)
			.pipe(
				map(Declaration.fromApi),
				switchMap((declaration) => (declaration ? of(declaration) : EMPTY)),
				map((declaration) => {
					return declaration.declarations_functional;
				}),
				tap((functionalDeclarations) => {
					this.toTreat = functionalDeclarations.length;
				}),
				map((fonctionalDeclarations) =>
					fonctionalDeclarations.map((fd) => {
						return zip(
							of(fd),
							this.state.declarationFunctionalService.getDju$(unwrap(fd.declaration_functional_id)).pipe(
								catchError((err: unknown) => {
									// If we can't because no meteo station or no altitude is defined then ignore
									if (err instanceof HttpErrorResponse && err.status === 400) {
										return of({});
									}
									throw err;
								}),
							),
							this.state.declarationFunctionalService.getAbsoluteObjective$(unwrap(fd.declaration_functional_id)).pipe(
								catchError((err: unknown) => {
									if (err instanceof HttpErrorResponse && err.status === 400) {
										return of({});
									}
									throw err;
								}),
							),
						).pipe(
							map(([functionalDeclaration, djus, absoluteObjectives]) => {
								return {
									functionalDeclaration,
									djus,
									absoluteObjectives,
								};
							}),
							tap(() => {
								this.progress++;
								this.stepTime = new Date().getTime();
							}),
						);
					}),
				),
				switchMap((consumptionsData) => sequentialJoin(consumptionsData)),
			)
			.subscribe((consumptionDatas: Data[]) => {
				clearInterval(interval);
				consumptionDatas.forEach((consumptionData) => {
					const csvData = formatDataForCsv(
						consumptionData.functionalDeclaration,
						consumptionData.djus,
						consumptionData.absoluteObjectives,
					);

					const csv = generateCsvFromGraphData(csvData);

					// conversion in base64 for excel encoding
					zipFile.file(
						`${consumptionData.functionalDeclaration.name} (${getOperatReference(consumptionData.functionalDeclaration, this.state.declaration)}) - Consommations annuelles.csv`,
						btoa(csv),
						{ base64: true },
					);
				});

				zipFile
					.generateAsync({ type: "blob" })
					.then((content) => {
						FileSaver.saveAs(content, `${this.state.declaration.name ?? "export"}.zip`);
					})
					.then(() => this.dialog.closeAll());
			});
	}

	cancelDownload() {
		this.consumptionDatas$?.unsubscribe();
		this.dialog.closeAll();
	}

	inputToState(input: Input): State {
		this.estimateDate = new Date(input.declaration.declarations_functional.length * 1000 * ESTIMATE_TIME_PER_ENTITY);

		return {
			declaration: input.declaration,
			declarationService: input.declarationService,
			declarationFunctionalService: input.declarationFunctionalService,
		};
	}

	calculateEstimation() {
		// we wait before having at least 2 generated in order to have a better visualisation on how much time it may take
		if (this.progress > 1) {
			this.activeEstimateDate = new Date(
				Math.max(
					((this.stepTime - this.startTime) * (this.toTreat - this.progress)) / this.progress -
						(new Date().getTime() - this.stepTime),
					0,
				),
			);
		} else {
			this.activeEstimateDate = new Date(
				ESTIMATE_TIME_PER_ENTITY * this.toTreat * 1000 - (new Date().getTime() - this.startTime),
			);
		}
	}

	protected readonly ESTIMATE_TIME_PER_ENTITY = ESTIMATE_TIME_PER_ENTITY;
}
