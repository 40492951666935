<div class="dialog-container" style="max-width: 900px">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content">
		<div class="message-warning" *ngIf="this.state.canEdit">
			Une fois les données reçues, les paramètres du point de livraison (tantième et type de consommation notamment) ne
			seront plus modifiables.
		</div>
		<div class="message-warning mt-2" *ngIf="this.state.agreement | agreementCanEdit: !this.state.canEdit">
			Une demande initiale a été effectuée pour ce PRM. Cette dernière ne peut être réitérée.<br />
			Les modifications des informations du titulaire du contrat seront prises en compte uniquement lors du
			renouvellement annuel des mandats.
		</div>
		<app-custom-form #form>
			<div class="d-flex multipart-dialog">
				<div class="py-5 pr-4 d-flex-col gap-2 w-0" style="flex: 1 1 0">
					<h3 class="m-0 p-0">Informations du point de livraison</h3>

					<div class="custom-input-group">
						<label>{{ state.agreement.type }}</label>
						<input
							type="text"
							class="custom-input"
							required
							[(ngModel)]="state.agreement.reference"
							[disabled]="!state.canEdit"
							(ngModelChange)="reloadAgreement(referenceInput)"
							#referenceInput
						/>
						<span class="custom-input-error">{{ referenceInput.validationMessage }}</span>
					</div>
					<div class="d-flex-col" *ngIf="prmConfirmed === false">
						<mat-spinner *ngIf="prmAddress === undefined"></mat-spinner>
						<span *ngIf="prmAddress">Votre PRM semble se trouver à l'adresse "{{ prmAddress }}". Confirmez-vous ?</span>
						<span *ngIf="prmAddress === false"
							>Votre référence ne semble pas être un PRM Enedis, souhaitez-vous tout de même confirmer le PRM (la
							récupération automatique des données sera impossible) ?</span
						>
						<div class="d-flex gap-2" *ngIf="prmAddress !== undefined">
							<button class="btn-accent" (click)="confirm(prmAddress)">Confirmer</button>
						</div>
					</div>

					<div class="d-flex-col" *ngIf="state.functionalDeclaration | isMultiOccupation">
						<label
							>Type de consommation
							<app-help [subject]="HelpSubject.ConsumptionType"></app-help>
						</label>
						<ng-container>
							<ng-select
								[clearable]="false"
								style="height: 40px"
								[(ngModel)]="state.agreement.energy_category"
								[disabled]="!state.canEdit"
								required
							>
								<ng-option *ngFor="let energyCategory of state.energyCategoriesAddable" [value]="energyCategory">
									{{ energyCategory | energyCategoriesLabel }}
								</ng-option>
							</ng-select>
						</ng-container>
					</div>

					<div class="d-flex-col">
						<mat-checkbox color="primary" [(ngModel)]="state.agreement.has_repartition_key" [disabled]="!state.canEdit">
							Saisie au tantième
						</mat-checkbox>

						<ng-container *ngIf="state.agreement.has_repartition_key">
							<label>Tantième</label>
							<div class="d-flex gap-1">
								<div class="custom-input-group w-0" style="flex: 1 1 0">
									<input
										type="number"
										class="custom-input"
										#partUmpteenthInput
										min="0"
										[max]="state.agreement.total_umpteenth"
										[disabled]="!state.canEdit"
										[(ngModel)]="state.agreement.part_umpteenth"
										required
									/>
								</div>
								<div style="font-size: 30px">/</div>
								<div class="custom-input-group w-0" style="flex: 1 1 0">
									<input
										type="number"
										class="custom-input"
										#totalUmpteenthInput
										[min]="state.agreement.part_umpteenth"
										[(ngModel)]="state.agreement.total_umpteenth"
										[disabled]="!state.canEdit"
										required
									/>
									<span class="custom-input-error">{{ totalUmpteenthInput.validationMessage }}</span>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="py-5 pl-4 d-flex-col gap-2 w-0" style="flex: 1 1 0">
					<h3 class="m-0 p-0">Informations du titulaire et du référent</h3>

					<div class="d-flex-col">
						<mat-label>Type du titulaire du contrat</mat-label>
						<div class="help-input-select">
							<ng-select
								[clearable]="false"
								[disabled]="!(this.state.agreement | agreementCanEdit: this.state.canEdit)"
								style="height: 40px"
								required
								[(ngModel)]="state.agreement.holder_type"
							>
								<ng-option *ngFor="let name of [HolderTypeEnum.COMPANY, HolderTypeEnum.INDIVIDUAL]" [value]="name">
									{{ name | asHolderDisplayType }}
								</ng-option>
							</ng-select>
							<app-help
								class="help"
								matSuffix
								[subject]="HelpSubject.ContractHolder"
								(click)="$event.stopPropagation()"
							></app-help>
						</div>
					</div>

					<div class="d-flex-col" *ngIf="state.agreement.holder_type === HolderTypeEnum.COMPANY">
						<mat-label>Raison sociale</mat-label>
						<input
							class="custom-input"
							[(ngModel)]="state.agreement.holder_company_name"
							[disabled]="!(this.state.agreement | agreementCanEdit: this.state.canEdit)"
						/>
					</div>

					<ng-container *ngIf="state.agreement.holder_type === HolderTypeEnum.INDIVIDUAL">
						<div class="d-flex-col">
							<mat-label>Prénom du titulaire du contrat</mat-label>
							<div class="help-input">
								<input
									class="custom-input"
									[(ngModel)]="state.agreement.holder_individual_first_name"
									[disabled]="!(this.state.agreement | agreementCanEdit: this.state.canEdit)"
								/>
								<app-help
									class="help"
									matSuffix
									[subject]="HelpSubject.ContractHolder"
									(click)="$event.stopPropagation()"
								></app-help>
							</div>
						</div>

						<div class="d-flex-col">
							<mat-label>Nom du titulaire du contrat</mat-label>
							<div class="help-input">
								<input
									class="custom-input"
									[(ngModel)]="state.agreement.holder_individual_last_name"
									[disabled]="!(this.state.agreement | agreementCanEdit: this.state.canEdit)"
								/>
								<app-help
									class="help"
									matSuffix
									[subject]="HelpSubject.ContractHolder"
									(click)="$event.stopPropagation()"
								>
								</app-help>
							</div>
						</div>
					</ng-container>

					<div class="d-flex-col">
						<mat-label>Nom du référent</mat-label>
						<div class="help-input">
							<input
								class="custom-input"
								required
								[(ngModel)]="state.agreement.name"
								[disabled]="!(this.state.agreement | agreementCanEdit: this.state.canEdit)"
							/>
							<app-help
								class="help"
								matSuffix
								[subject]="HelpSubject.Referent"
								(click)="$event.stopPropagation()"
							></app-help>
						</div>
					</div>

					<div class="d-flex-col p-relative">
						<mat-label>Email du référent</mat-label>
						<div class="help-input">
							<input
								class="custom-input"
								style="padding-right: 35px"
								required
								[(ngModel)]="state.agreement.email"
								[disabled]="!(this.state.agreement | agreementCanEdit: this.state.canEdit)"
								type="email"
								email
							/>
							<app-help
								class="help"
								matSuffix
								[subject]="HelpSubject.Referent"
								(click)="$event.stopPropagation()"
							></app-help>
						</div>
					</div>

					<!--Only possible before fist email validation-->
					<div class="custom-input-group" *ngIf="state.agreement.type === 'PCE'">
						<label>Code postal</label>
						<input class="custom-input" [(ngModel)]="state.agreement.postal_code" [disabled]="!this.state.canEdit" />
					</div>
					<div class="custom-input-group" *ngIf="state.agreement.type === 'PRM'">
						<label>SIRET</label>
						<input class="custom-input" [(ngModel)]="state.agreement.siret_override" [disabled]="!this.state.canEdit" />
					</div>
				</div>
			</div>
		</app-custom-form>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [mat-dialog-close]="undefined">Annuler</button>
		<button *ngIf="state.canDelete" (click)="delete()" class="btn-stroked-danger">Supprimer</button>
		<button
			class="btn-stroked-primary"
			[disabled]="
				!form.formValid || !(this.state.agreement | agreementCanEdit: this.state.canEdit) || prmConfirmed === false
			"
			(click)="close()"
		>
			{{ state.adding ? "Ajouter" : "Modifier" }}
		</button>
	</div>
</div>
