import { Pipe, PipeTransform } from "@angular/core";
import { periodRange } from "./period-range.pipe";
import { TimestampToDatePipe } from "./timestamp-to-date.pipe";

@Pipe({
	name: "yearForDateAndPeriod",
})
export class YearForDateAndPeriodPipe implements PipeTransform {
	transform(date: number, favoritePeriod: number): number {
		const timestampToDatePipe = new TimestampToDatePipe();
		const dateObject = new Date(timestampToDatePipe.transform(date));
		const { start, end } = periodRange(dateObject.getFullYear(), favoritePeriod);
		if (dateObject < new Date(start)) {
			return dateObject.getFullYear() - 1;
		}
		if (dateObject >= new Date(end)) {
			return dateObject.getFullYear() + 1;
		}
		return dateObject.getFullYear();
	}
}
