import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { dialogOpen, Modal } from "src/app/models/modal";

export enum HelpSubject {
	FloorArea,
	Site,
	FunctionalEntityTodo,
	AccessoryArea,
	FavoritePeriod,
	IndividualConso,
	DistributedConso,
	SharedConso,
	LotNumber,
	ContractHolder, // Titulaire du contrat
	Referent,
	DeductConso,
	Tantieme,
	ByDelivery,
	RNA,
	Qualification,
	Addresses,
	CustomLabel,
	LongHistory,
	ProofFiles,
	Benchmark,
	ConsumptionType,
	EnergyTypes,
	WeatherStation,
	EfaInCsv,
	PrivateIndividual,
	MultipleLotNumbers,
	MaybeSirenDoesntExist,
	FileRejectedOperat,
	LinkedEfa,
	CsvOperat,
	EntitiesDeclaredAfterYear,
	NewlyLiable,
	OwnerStatus,
	OperatUserKey,
	OperatQuota,
}

@Component({
	selector: "app-help",
	templateUrl: "./help.component.html",
	styleUrls: ["./help.component.scss"],
})
export class HelpComponent {
	@Input() subject = HelpSubject.FloorArea;
	@Input() argument: string = "";

	constructor(private dialog: MatDialog) {}

	openDialog() {
		dialogOpen(this.dialog, DialogComponent, { subject: this.subject, argument: this.argument }, { panelClass: "p-0" });
	}
}

interface DialogParameters {
	subject: HelpSubject;
	argument: string;
}

@Component({
	templateUrl: "dialog.html",
	selector: "app-help-dialog",
})
export class DialogComponent extends Modal<DialogParameters, DialogParameters, void> {
	readonly HelpSubject = HelpSubject;

	inputToState(input: DialogParameters): DialogParameters {
		return input;
	}
}
