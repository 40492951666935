import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Nullable } from "../helpers/nullable";
import { DeclarationId, FunctionalDeclarationId } from "../models/ids";

export interface IdAndIsReference {
	id: FunctionalDeclarationId;
	isReference: boolean;
}

@Injectable({
	providedIn: "root",
})
export class CsvService {
	constructor(private http: HttpClient) {}

	getEfa$(id: DeclarationId, functionalEntities: Immutable<FunctionalDeclarationId[]> = []): Observable<string> {
		if (functionalEntities.length > 0) {
			return this.http.post(`${environment.baseApiUrl}/declaration/${id}/csv/efa`, functionalEntities, {
				responseType: "text",
			});
		}

		return this.http.get(`${environment.baseApiUrl}/declaration/${id}/csv/efa`, {
			responseType: "text",
		});
	}

	getConso$(
		id: DeclarationId,
		year: number,
		{
			functionalDeclarationId,
			onlyReference,
		}: {
			functionalDeclarationId?: Nullable<FunctionalDeclarationId>;
			onlyReference?: boolean;
		} = {},
	): Observable<{ idAndIsReferenceList: IdAndIsReference[]; content: string }> {
		return this.http.get<{ idAndIsReferenceList: IdAndIsReference[]; content: string }>(
			`${environment.baseApiUrl}/declaration/${id}/csv/conso/${year}`,
			{
				params: {
					...(functionalDeclarationId ? { declaration_functional_id: functionalDeclarationId } : {}),
					...(onlyReference ? { only_reference: onlyReference } : {}),
				},
			},
		);
	}
}
