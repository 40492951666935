import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Siret } from "src/app/models/identifier";
import { SirenService } from "src/app/services/siren.service";
import { HelpSubject } from "../help/help.component";

// global data
const validSirets = new Map<Siret, boolean>();

/**
 * In this component, I don't use pipes.
 * For pipes to work I have to make Angular detect changes
 * in `validSirets` and in `siret` at the same time and that's quite hard.
 */
@Component({
	selector: "app-siret-input",
	templateUrl: "./siret-input.component.html",
	styleUrls: ["./siret-input.component.scss"],
})
export class SiretInputComponent {
	static readonly validSirets = validSirets;
	readonly HelpSubject = HelpSubject;
	tasksCount = 0;
	readonly validSirets = validSirets;
	readonly Siret = Siret;
	@Input() siret = "" as Siret;
	@Input() placeholder = "";
	@Output() siretChange = new EventEmitter<Siret>();
	@Output() valueChange = new EventEmitter<void>();

	constructor(private sirenService: SirenService) {}

	checkSiret(input: HTMLInputElement, siret: Siret) {
		++this.tasksCount;

		this.sirenService.doesSiretExist$(siret).subscribe((doesExist) => {
			this.validSirets.set(siret, doesExist);
			--this.tasksCount;
			input.setCustomValidity(doesExist ? "" : "Votre SIRET n'existe pas");
			this.valueChange.emit();
		});
	}

	resetCustomValidity(input: HTMLInputElement) {
		input.setCustomValidity("");
	}
}
