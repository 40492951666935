import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Irve } from "../models/asset";
import { AddBlock } from "./sort-activity-categories-per-start.pipe";

@Pipe({
	name: "sortIRVEPerStart",
})
export class SortIRVEPerStartPipe implements PipeTransform {
	transform(immIRVE: Immutable<Irve[]>, start: number, end: number): Immutable<Irve | AddBlock>[] {
		const irves = [...immIRVE];
		// we sort the irves per start date
		irves.sort((irve1, irve2) => irve1.start - irve2.start);

		// result array with irves and AddBlock
		const newArray: Immutable<Irve | AddBlock>[] = [];

		// previousEnd is used to save the end of the previous block while looping
		let previousEnd: number | undefined = start;
		for (let i = 0; i < irves.length; i++) {
			// if previousEnd is undefined it means it's the end so if it is not the end
			// and the previousEnd (with a threshold of one day) is before the start of this block we add an AddBlock
			if (previousEnd && irves[i].start > previousEnd) {
				// We add a day so that if it ends the 31, the next one will start on the 1st of next month for example
				// We make the add block start from the previousEnd to the next start
				newArray.push(new AddBlock(previousEnd, irves[i].start, { type: "irve", object: irves[i] }));
			}
			// Add the irve to the table and set the previousEnd
			newArray.push(irves[i]);
			previousEnd = irves[i]?.end;
		}
		// Add the last AddBlock if necessary
		if (previousEnd !== undefined && previousEnd < end) {
			newArray.push(new AddBlock(previousEnd, undefined, { type: "irve", object: irves[irves.length - 1] }));
		}
		return newArray;
	}
}
