import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { produce, Immutable } from "immer";
import { EMPTY } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Nullable } from "src/app/helpers/nullable";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { DeclarationGroup } from "../../../../../models/declarationGroup";
import { Lazy } from "../../../../../models/lazy";
import { Modal } from "../../../../../models/modal";
import { getParcelId } from "../../../../../models/Parcel";
import { Representative } from "../../../../../models/representative";
import { DeclarationFunctionalService } from "../../../../../services/declaration-functional.service";
import { TokenTransferModalComponent } from "../../../token-transfer-modal/token-transfer-modal.component";

type Input = {
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	functionalService: DeclarationFunctionalService;
	representative: Nullable<Immutable<Lazy<Representative>>>;
	declarationGroup: Nullable<Immutable<Lazy<DeclarationGroup>>>;
	messageType?: number;
};

type State = {
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	functionalService: DeclarationFunctionalService;
	representative: Nullable<Immutable<Lazy<Representative>>>;
	declarationGroup: Nullable<Immutable<Lazy<DeclarationGroup>>>;
	messageType: number;
};

type Output = { type: "cancel" } | { type: "updated"; entity: FunctionalDeclaration } | { type: "cant buy" };

@Component({
	templateUrl: "consume-token-dialog.html",
	selector: "app-functional-dialog",
})
export class ConsumeTokenDialogComponent extends Modal<Input, State, Output> {
	readonly NO_NAME_MESSAGE = 'Veuillez vous rendre sur "Définition du périmètre" pour donner un nom à cette entité.';

	closeDialog(buy: boolean) {
		if (!buy) {
			this.close({ type: "cancel" });
			return;
		}

		let entity;

		if (!this.state.functionalDeclaration.is_imported_from_operat) {
			const [firstParcel] = this.state.functionalDeclaration.infos.parcelles;
			entity = produce(this.state.functionalDeclaration, (draft) => {
				draft.infos.asset.mainParcel = getParcelId(firstParcel);
			});
		} else {
			entity = produce(this.state.functionalDeclaration, (draft) => {
				draft.is_token_used = true;
			});
		}

		this.state.functionalService
			.update$(FunctionalDeclaration.toApi(entity))
			.pipe(
				map(FunctionalDeclaration.fromApi),
				catchError((err: unknown) => {
					if (err instanceof HttpErrorResponse && err.error.error === "Not enough token to go to this step") {
						if (this.state.representative && this.state.declarationGroup) {
							this.dialog.open(TokenTransferModalComponent, {
								data: {
									declarationGroup: this.state.declarationGroup,
									representative: this.state.representative,
									showWarning: true,
								},
								panelClass: "p-0",
							});
							return EMPTY;
						}
						this.close({ type: "cant buy" });
					}
					this.close({ type: "cancel" });

					throw err;
				}),
			)
			.subscribe((updatedEntity) => this.close({ type: "updated", entity: updatedEntity }));
	}

	inputToState(input: Input): State {
		return {
			functionalDeclaration: input.functionalDeclaration,
			functionalService: input.functionalService,
			representative: input.representative,
			declarationGroup: input.declarationGroup,
			messageType: input.messageType ?? ConsumeTokenMessageType.INITIAL_MESSAGE,
		};
	}

	protected readonly ConsumeTokenMessageType = ConsumeTokenMessageType;
}

export enum ConsumeTokenMessageType {
	INITIAL_MESSAGE = 1,
	LOCKED_CONSUMPTION = 2,
}
