import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { Immutable } from "immer";
import { EMPTY, Observable } from "rxjs";
import { range } from "../../../../../../helpers/array";
import { ActivityCategoryEntry, Irve } from "../../../../../../models/asset";
import { Declaration } from "../../../../../../models/declaration";
import { FunctionalDeclaration } from "../../../../../../models/functionalDeclaration";
import { HeatModifierType } from "../../../../../../models/heatModifierType";
import { AddBlock } from "../../../../../../pipes/sort-activity-categories-per-start.pipe";

@Component({
	selector: "app-activity-category-table",
	templateUrl: "./activity-category-table.component.html",
	styleUrls: ["./activity-category-table.component.scss"],
})
export class ActivityCategoryTableComponent {
	readonly HeatModifierType = HeatModifierType;
	readonly todayTimestamp = new Date().getTime() / 1000;
	readonly MINIMUM_DATE = Declaration.MINIMUM_DATE;
	readonly MINIMUM_DATE_TIME = Declaration.MINIMUM_DATE_TIME;
	readonly maxDate: number;
	readonly years = range(new Date(this.MINIMUM_DATE).getFullYear(), new Date().getFullYear() + 1);

	@Input() functionalDeclaration!: Immutable<FunctionalDeclaration>;
	@Input() conflictedCategories$: Observable<Immutable<ActivityCategoryEntry>[] | undefined> = EMPTY;

	@Output() addCategoryAction = new EventEmitter<AddBlock>();
	@Output() editCategoryAction = new EventEmitter<Immutable<ActivityCategoryEntry>>();

	@Output() addElectricDockAction = new EventEmitter<AddBlock>();
	@Output() editElectricDockAction = new EventEmitter<Irve>();

	@ViewChildren("rowElement") rowElements!: QueryList<ElementRef>;

	constructor() {
		const maxDate = new Date();
		maxDate.setFullYear(maxDate.getFullYear() + 1, 0, 1);
		maxDate.setHours(0, 0, 0, 0);
		this.maxDate = maxDate.getTime() / 1000;
	}
}
