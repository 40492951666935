<form #registrationForm>
	<div class="d-flex-col gap-2">
		<div class="d-flex gap-2">
			<div class="custom-input-group w-50">
				<label class="login-p" required>Civilité</label>
				<select
					#civilityInput
					[(ngModel)]="civility"
					(ngModelChange)="updateFormValidity()"
					class="custom-input w-100"
					name="civility"
					required
					type="text"
				>
					<option [value]="0">Monsieur</option>
					<option [value]="1">Madame</option>
				</select>
				<span class="custom-input-error">{{ civilityInput.validationMessage }}</span>
			</div>

			<div class="custom-input-group w-100">
				<label class="login-p" required>Prénom</label>
				<input
					#firstNameInput
					[(ngModel)]="firstName"
					(ngModelChange)="updateFormValidity()"
					[placeholder]="'Entrez votre prénom'"
					class="custom-input w-100"
					name="first_name"
					required
					type="text"
				/>
				<span class="custom-input-error">{{ firstNameInput.validationMessage }}</span>
			</div>

			<div class="custom-input-group w-100">
				<label class="login-p" required>Nom</label>
				<input
					#lastNameInput
					[(ngModel)]="lastName"
					(ngModelChange)="updateFormValidity()"
					[placeholder]="'Entrez votre nom'"
					class="custom-input w-100"
					name="last_name"
					required
					type="text"
				/>
				<span class="custom-input-error">{{ lastNameInput.validationMessage }}</span>
			</div>
		</div>

		<div class="d-flex gap-2">
			<div class="custom-input-group w-100">
				<label class="login-p" required>Email</label>
				<input
					#emailInput
					[(ngModel)]="email"
					(ngModelChange)="updateEmail()"
					[placeholder]="'Entrez votre adresse email'"
					class="custom-input w-100"
					name="email"
					required
					type="email"
				/>
				<span class="custom-input-error">{{ emailInput.validationMessage }}</span>
			</div>
			<div class="custom-input-group w-100">
				<label class="login-p">Téléphone</label>
				<input
					#phoneInput
					[(ngModel)]="phone"
					(ngModelChange)="updateFormValidity()"
					[placeholder]="'Entrez votre numéro de téléphone'"
					class="custom-input w-100"
					name="phone"
					type="tel"
				/>
				<span class="custom-input-error">{{ phoneInput.validationMessage }}</span>
			</div>
		</div>

		<div class="d-flex gap-2">
			<div class="custom-input-group w-100">
				<label class="login-p" required>Mot de passe</label>
				<input
					#passwordInput
					[(ngModel)]="password"
					(ngModelChange)="checkConfirmPasswordValidity()"
					[placeholder]="'Entrez votre mot de passe'"
					class="custom-input w-100"
					minlength="6"
					name="password"
					required
					type="password"
				/>
				<span class="custom-input-error">{{ passwordInput.validationMessage }}</span>
			</div>
			<div class="custom-input-group w-100">
				<label class="login-p" required>Confirmez votre mot de passe</label>
				<input
					#confirmPasswordInput
					[(ngModel)]="confirm_password"
					(ngModelChange)="checkConfirmPasswordValidity()"
					[placeholder]="'Confirmez votre mot de passe'"
					class="custom-input w-100"
					name="confirm_password"
					required
					type="password"
				/>
				<span class="custom-input-error">{{ confirmPasswordInput.validationMessage }}</span>
			</div>
		</div>

		<div class="d-flex gap-2">
			<div class="custom-input-group w-100">
				<label class="login-p" required>Société</label>
				<input
					#companyNameInput
					[(ngModel)]="company_name"
					(ngModelChange)="updateFormValidity()"
					[placeholder]="'Entrez le nom de votre société'"
					class="custom-input w-100"
					name="company"
					required
					type="text"
				/>
				<span class="custom-input-error">{{ companyNameInput.validationMessage }}</span>
			</div>
			<div class="custom-input-group w-100">
				<label class="login-p">SIRET</label>
				<input
					#siretInput
					[(ngModel)]="siret"
					(ngModelChange)="updateFormValidity()"
					[placeholder]="'Entrez le siret de votre entreprise'"
					class="custom-input w-100"
					name="siret"
					pattern="[0-9]{14}"
					type="text"
				/>
				<span class="custom-input-error">{{ siretInput.validationMessage }}</span>
				<span class="text-gray" style="font-size: 12px">
					Si un membre de votre entreprise possède déjà un compte, préférez l'inscription par invitation
				</span>
			</div>
		</div>

		<div class="d-flex gap-2">
			<div class="w-100">
				<span class="text-gray" style="font-size: 12px">
					<input
						[(ngModel)]="accept_cgu_cgv"
						(ngModelChange)="updateFormValidity()"
						id="cgu_cgv"
						name="cgu_cgv"
						required
						type="checkbox"
					/>
					<label for="cgu_cgv" required>
						Je déclare accepter sans réserve les
						<a class="link-text-secondary" href="assets/CGU.pdf" target="_blank">
							conditions générales d'utilisation et de ventes
						</a>
					</label>
				</span>
			</div>
			<div class="w-100">
				<button
					(click)="register()"
					[class.btn-loading]="loading"
					[disabled]="!formValid || loading"
					class="btn-warning w-100"
					style="justify-content: center"
					type="button"
				>
					QUALIFIER GRATUITEMENT MON PATRIMOINE
				</button>
			</div>
		</div>
	</div>
</form>
