var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// index.ts
import { multiPolygon, polygon } from "@turf/helpers";
import { geomEach } from "@turf/meta";
import polygonClipping from "polygon-clipping";
function intersect(features, options = {}) {
  const geoms = [];
  geomEach(features, geom => {
    geoms.push(geom.coordinates);
  });
  if (geoms.length < 2) {
    throw new Error("Must specify at least 2 geometries");
  }
  const intersection = polygonClipping.intersection(geoms[0], ...geoms.slice(1));
  if (intersection.length === 0) return null;
  if (intersection.length === 1) return polygon(intersection[0], options.properties);
  return multiPolygon(intersection, options.properties);
}
__name(intersect, "intersect");
var turf_intersect_default = intersect;
export { turf_intersect_default as default, intersect };
