<span mat-dialog-title>
	<span class="text-icon">
		<mat-icon>cloud_upload</mat-icon>
		Mes fichiers justificatifs
	</span>
</span>

<div mat-dialog-content class="d-flex" style="flex-direction: column">
	<app-file-button
		[disabled]="isLoading"
		[files]="filesToUpload"
		(selectFiles)="filesToUpload = $event"
		(upload)="upload()"
	></app-file-button>
	<div *ngIf="state.files.length === 0" class="d-flex" style="justify-content: center">
		<h3 class="ml-3 mr-3">
			<i class="text-lightgray">Vous n'avez pas de fichiers justificatifs.</i>
		</h3>
	</div>
	<table style="border-spacing: 1em 0.2em">
		<tr *ngFor="let entry of state.files">
			<td>
				<span class="d-flex gap-1 pointer" style="align-items: center" (click)="downloadFile(entry)">
					<mat-icon>{{ entry.type | iconFromMimeType }}</mat-icon>
					{{ entry.name }}
				</span>
			</td>
			<td class="text-gray">{{ entry.size | fileSize }}</td>
			<td class="text-gray">{{ entry.date | timestampToDate: "short" }}</td>
			<td><mat-icon class="danger pointer" (click)="deleteFile(entry)">delete_forever</mat-icon></td>
		</tr>
	</table>
</div>

<div mat-dialog-actions>
	<button [disabled]="isLoading" mat-button (click)="closeModal()">Fermer</button>
</div>
