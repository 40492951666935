import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk } from "../models/agreementAsk";

@Pipe({
	name: "cantGetAutomaticallyReason",
})
export class CantGetAutomaticallyReasonPipe implements PipeTransform {
	transform(agreement: Immutable<AgreementAsk>): string | null {
		if (agreement.reference.match("^[0-9]{13}$")) {
			return 'L\'identifiant renseigné ne correspond à aucun format de nos partenaires. Un "0" en tête du numéro a pu être omis suite au copier-coller.';
		}
		if (agreement.type === "PRM" && agreement.reference.match("^[0-9]{14}$") === null) {
			return "L'identifiant renseigné ne correspond à aucun format de nos partenaires";
		}
		if (agreement.type === "PCE" && agreement.reference.match("(^[0-9]{14}$)|(^GI[0-9]{6}$)") === null) {
			return "L'identifiant renseigné ne correspond à aucun format de nos partenaires";
		}
		return null;
	}
}
