import { Immutable } from "immer";
import { EnergyCategory } from "./energyCategory";
import { ConsumptionEntry } from "./functionalDeclaration";
import { HolderType } from "./HolderType";

export class AgreementAsk {
	agreement_ask_id?: string;
	type: "PRM" | "PCE";
	reference: string;
	email: string;
	name: string;
	is_email_sent: boolean;
	is_accepted: boolean;
	declaration_functional_id?: string;
	email_sent_at?: number;
	is_treated: boolean = false;
	short_data_received: boolean = false;
	year_data_received: boolean = false;
	holder_type: HolderType;
	holder_company_name: string;
	holder_individual_first_name: string;
	holder_individual_last_name: string;
	has_repartition_key: boolean;
	part_umpteenth: number;
	total_umpteenth: number;
	energy_category: EnergyCategory;
	agreements: { [key: string]: boolean } = {};
	comments: string[] = [];
	original_consumptions: ConsumptionEntry[] | undefined = [];
	postal_code: string | undefined | null;
	siret_override: string | undefined | null;
	last_renewal?: number | null;

	constructor(type: "PRM" | "PCE") {
		this.type = type;
		this.reference = "";
		this.email = "";
		this.name = "";
		this.is_email_sent = false;
		this.is_accepted = false;
		this.holder_type = HolderType.COMPANY;
		this.holder_company_name = "";
		this.holder_individual_first_name = "";
		this.holder_individual_last_name = "";
		this.has_repartition_key = false;
		this.part_umpteenth = 100;
		this.total_umpteenth = 100;
		this.energy_category = EnergyCategory.INDIVIDUAL;
		this.postal_code = undefined;
		this.siret_override = undefined;
		this.last_renewal = null;
	}
}

export function cloneAgreementAsk(agreementAsk: Immutable<AgreementAsk>): AgreementAsk {
	const clone: AgreementAsk = {
		...agreementAsk,
		agreements: { ...agreementAsk.agreements },
		comments: [...agreementAsk.comments],
		original_consumptions: agreementAsk.original_consumptions
			? agreementAsk.original_consumptions.map((original) => ({ ...original }))
			: [],
	};

	return clone;
}
