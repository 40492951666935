import { Immutable } from "immer";
import { Nullable } from "../helpers/nullable";
import { AddressInfo } from "./address";
import { FunctionalDeclaration } from "./functionalDeclaration";
import { IdRefEtat, IdSiret, Rna, Siren, Siret } from "./identifier";
import { Lazy } from "./lazy";
import { StreetTypeString } from "./StreetType";

class EtablissementApi {
	siren: Siren;
	siret: Siret;
	adresseEtablissement: ApiAddress;
	periodesEtablissement: PeriodeEtablissement[];
	uniteLegale: UniteLegaleSiretRoute;

	// to ditch unused fields from an API call
	constructor(copy: EtablissementApi) {
		this.siren = copy.siren;
		this.siret = copy.siret;
		this.adresseEtablissement = copy.adresseEtablissement;
		this.periodesEtablissement = copy.periodesEtablissement.map((periode) => new PeriodeEtablissement(periode));
		this.uniteLegale = new UniteLegaleSiretRoute(copy.uniteLegale);
	}
}

// with multiple addresses
interface Etablissement {
	identifier: Nullable<IdSiret | { denomination: string } | { rna: Rna } | IdRefEtat>;
	functionalEntities: Set<Immutable<Lazy<FunctionalDeclaration>>>;
	address: Immutable<AddressInfo>;
}

export interface ApiAddress {
	libelleVoieEtablissement: string;
	codePostalEtablissement: string;
	libelleCommuneEtablissement: string;
	codeCommuneEtablissement: string;
	numeroVoieEtablissement: string | null;
	complementAdresseEtablissement: string | null;
	typeVoieEtablissement: StreetTypeString;
}

class UniteLegaleSiretRoute {
	denominationUniteLegale: string;
	activitePrincipaleUniteLegale: string;
	nomenclatureActivitePrincipaleUniteLegale: string;

	// to ditch unused fields from an API call
	constructor(copy?: UniteLegaleSiretRoute) {
		this.denominationUniteLegale = copy?.denominationUniteLegale ?? "";
		this.activitePrincipaleUniteLegale = copy?.activitePrincipaleUniteLegale ?? "";
		this.nomenclatureActivitePrincipaleUniteLegale = copy?.nomenclatureActivitePrincipaleUniteLegale ?? "";
	}
}

class PeriodeEtablissement {
	activitePrincipaleEtablissement: string;
	nomenclatureActivitePrincipaleEtablissement: string;
	enseigne1Etablissement: string | null;
	etatAdministratifEtablissement: "A" | "F"; // A = actif, F = fermé

	// to ditch unused fields from an API call
	constructor(copy: PeriodeEtablissement) {
		this.activitePrincipaleEtablissement = copy.activitePrincipaleEtablissement;
		this.nomenclatureActivitePrincipaleEtablissement = copy.nomenclatureActivitePrincipaleEtablissement;
		this.enseigne1Etablissement = copy.enseigne1Etablissement;
		this.etatAdministratifEtablissement = copy.etatAdministratifEtablissement;
	}
}

interface UniteLegaleSirenRoute {
	siren: Siren;
	identifiantAssociationUniteLegale: Rna | null;
	periodesUniteLegale: PeriodeUniteLegale[];
}

interface PeriodeUniteLegale {
	denominationUniteLegale: string;
	activitePrincipaleUniteLegale: string;
	nomenclatureActivitePrincipaleUniteLegale: string;
}

export {
	Etablissement,
	EtablissementApi,
	UniteLegaleSiretRoute,
	PeriodeEtablissement,
	UniteLegaleSirenRoute,
	PeriodeUniteLegale,
};
