import { Pipe, PipeTransform } from "@angular/core";
import { NumberUnitObjectPipe } from "./number-unit-object.pipe";

@Pipe({
	name: "numberUnit",
})
export class NumberUnitPipe implements PipeTransform {
	// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
	transform(num: number): string {
		const numberUnitObject = new NumberUnitObjectPipe();
		const result = numberUnitObject.transform(num);
		return result.value + result.symbol;
	}
}
