import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "../../../models/base-component.directive";

@Component({
	selector: "app-payment-slider",
	templateUrl: "./payment-slider.component.html",
	styleUrls: ["./payment-slider.component.scss"],
})
export class PaymentSliderComponent extends BaseComponent {
	@Input() quantity: number = 1;
	@Input() adding = true;
	@Input() nextButton = true;
	@Input() maximum = 10;
	@Output() quantityChange = new EventEmitter<number>();
	@Output() continue = new EventEmitter<void>();
	@Input() unitPrice = 200;

	constructor() {
		super();
	}
}
