export enum EnergyType {
	ELECTRICITY_KWH = "electricity_kwh",
	GAZ_NAT_NETWORK_KWH = "gaz_nat_network_kwh",
	GAZ_NAT_LIQUEFIED_KG = "gaz_nat_liquefied_kg",
	GAZ_PROPANE_KG = "gaz_propane_kg",
	GAZ_PROPANE_M3 = "gaz_propane_m3",
	GAZ_BUTANE_KG = "gaz_butane_kg",
	GAZ_BUTANE_M3 = "gaz_butane_m3",
	FUEL_DOMESTIC_LITRE = "fuel_domestic_litre",
	OFF_ROAD_DIESEL_LITRE = "off_road_diesel_litre",
	COAL_AGGLO_BRICK_KG = "coal_agglo_brick_kg",
	COAL_KG = "coal_kg",
	WOOD_PLATE_INDUSTRIAL_KG = "wood_plate_industrial",
	WOOD_PLATE_FOREST_KG = "wood_plate_forest",
	WOOD_GRAN_BRICK_KG = "wood_gran_brick",
	WOOD_LOGS_STERE = "wood_logs_stere",
	HEAT_NETWORK_KWH = "heat_network_kwh",
	COOL_NETWORK_HWH = "cool_network_kwh",
}

export const ENERGY_TYPES = [
	EnergyType.ELECTRICITY_KWH,
	EnergyType.GAZ_NAT_NETWORK_KWH,
	EnergyType.GAZ_NAT_LIQUEFIED_KG,
	EnergyType.FUEL_DOMESTIC_LITRE,
	EnergyType.OFF_ROAD_DIESEL_LITRE,
	EnergyType.HEAT_NETWORK_KWH,
	EnergyType.COOL_NETWORK_HWH,
	EnergyType.GAZ_PROPANE_KG,
	EnergyType.GAZ_PROPANE_M3,
	EnergyType.GAZ_BUTANE_KG,
	EnergyType.GAZ_BUTANE_M3,
	EnergyType.COAL_AGGLO_BRICK_KG,
	EnergyType.COAL_KG,
	EnergyType.WOOD_PLATE_INDUSTRIAL_KG,
	EnergyType.WOOD_PLATE_FOREST_KG,
	EnergyType.WOOD_GRAN_BRICK_KG,
	EnergyType.WOOD_LOGS_STERE,
] as const;

export function getDeliverables() {
	return [
		EnergyType.GAZ_NAT_LIQUEFIED_KG,
		EnergyType.GAZ_PROPANE_KG,
		EnergyType.GAZ_PROPANE_M3,
		EnergyType.GAZ_BUTANE_KG,
		EnergyType.GAZ_BUTANE_M3,
		EnergyType.FUEL_DOMESTIC_LITRE,
		EnergyType.OFF_ROAD_DIESEL_LITRE,
		EnergyType.COAL_AGGLO_BRICK_KG,
		EnergyType.COAL_KG,
		EnergyType.WOOD_PLATE_INDUSTRIAL_KG,
		EnergyType.WOOD_PLATE_FOREST_KG,
		EnergyType.WOOD_GRAN_BRICK_KG,
		EnergyType.WOOD_LOGS_STERE,
	];
}

export function energyTypeUnit(energyType: EnergyType): string {
	switch (energyType) {
		case EnergyType.ELECTRICITY_KWH:
		case EnergyType.GAZ_NAT_NETWORK_KWH:
		case EnergyType.HEAT_NETWORK_KWH:
		case EnergyType.COOL_NETWORK_HWH:
			return "kWh";
		case EnergyType.GAZ_NAT_LIQUEFIED_KG:
		case EnergyType.GAZ_PROPANE_KG:
		case EnergyType.GAZ_BUTANE_KG:
		case EnergyType.COAL_AGGLO_BRICK_KG:
		case EnergyType.COAL_KG:
		case EnergyType.WOOD_PLATE_INDUSTRIAL_KG:
		case EnergyType.WOOD_PLATE_FOREST_KG:
		case EnergyType.WOOD_GRAN_BRICK_KG:
			return "kg";
		case EnergyType.GAZ_PROPANE_M3:
		case EnergyType.GAZ_BUTANE_M3:
			return "m³";
		case EnergyType.FUEL_DOMESTIC_LITRE:
		case EnergyType.OFF_ROAD_DIESEL_LITRE:
			return "litre";
		case EnergyType.WOOD_LOGS_STERE:
			return "stere";
	}
}

export function energyTypeLabel(energyType: EnergyType): string {
	switch (energyType) {
		case EnergyType.ELECTRICITY_KWH:
			return "électricité";
		case EnergyType.GAZ_NAT_NETWORK_KWH:
			return "gaz naturel";
		case EnergyType.HEAT_NETWORK_KWH:
			return "réseau de chaleur";
		case EnergyType.COOL_NETWORK_HWH:
			return "réseau de froid";
		case EnergyType.GAZ_NAT_LIQUEFIED_KG:
			return "gaz liquéfié";
		case EnergyType.GAZ_PROPANE_KG:
		case EnergyType.GAZ_PROPANE_M3:
			return "gaz propane";
		case EnergyType.GAZ_BUTANE_KG:
		case EnergyType.GAZ_BUTANE_M3:
			return "gaz butane";
		case EnergyType.COAL_AGGLO_BRICK_KG:
			return "charbon aggloméré et briquettes";
		case EnergyType.COAL_KG:
			return "houille";
		case EnergyType.WOOD_PLATE_INDUSTRIAL_KG:
			return "bois (plaquettes d'industries)";
		case EnergyType.WOOD_PLATE_FOREST_KG:
			return "bois (plaquettes forestières)";
		case EnergyType.WOOD_GRAN_BRICK_KG:
			return "bois (granulés ou briquettes)";
		case EnergyType.FUEL_DOMESTIC_LITRE:
			return "fioul domestique";
		case EnergyType.OFF_ROAD_DIESEL_LITRE:
			return "gazole non routier";
		case EnergyType.WOOD_LOGS_STERE:
			return "bois (bûches)";
	}
}

export function energyTypeUnitToDatabaseStorage(energyType: EnergyType, number: number) {
	switch (energyType) {
		case EnergyType.ELECTRICITY_KWH:
			return number * 3600000;
		case EnergyType.HEAT_NETWORK_KWH:
			return number * 3600000;
		case EnergyType.COOL_NETWORK_HWH:
			return number * 3600000;
		case EnergyType.GAZ_NAT_NETWORK_KWH:
			return number * 3600000;
		case EnergyType.FUEL_DOMESTIC_LITRE:
		case EnergyType.OFF_ROAD_DIESEL_LITRE:
			return number / 1000;
		default:
			return number;
	}
}

export function energyTypeUnitDatabaseToDisplay(energyType: EnergyType, number: number) {
	return number / energyTypeUnitToDatabaseStorage(energyType, 1);
}

export function energyTypeUnitToJoulePci(energyType: EnergyType, number: number): number {
	switch (energyType) {
		case EnergyType.ELECTRICITY_KWH:
			return number;
		case EnergyType.HEAT_NETWORK_KWH:
			return number * 0.77;
		case EnergyType.COOL_NETWORK_HWH:
			return number * 0.25;
		case EnergyType.GAZ_NAT_NETWORK_KWH:
			return number * 0.9;
		case EnergyType.GAZ_NAT_LIQUEFIED_KG:
			return number * 12.553 * 3600000;
		case EnergyType.GAZ_PROPANE_KG:
			return number * 12.66 * 3600000;
		case EnergyType.GAZ_BUTANE_KG:
			return number * 12.57 * 3600000;
		case EnergyType.COAL_AGGLO_BRICK_KG:
			return number * 8.889 * 3600000;
		case EnergyType.COAL_KG:
			return number * 7.222 * 3600000;
		case EnergyType.WOOD_PLATE_INDUSTRIAL_KG:
			return number * 2.2 * 3600000;
		case EnergyType.WOOD_PLATE_FOREST_KG:
			return number * 2.7 * 3600000;
		case EnergyType.WOOD_GRAN_BRICK_KG:
			return number * 4.6 * 3600000;
		case EnergyType.GAZ_PROPANE_M3:
			return number * 23.7 * 3600000;
		case EnergyType.GAZ_BUTANE_M3:
			return number * 30.45 * 3600000;
		case EnergyType.FUEL_DOMESTIC_LITRE:
			// we convert it back to litre since we stock it in m³
			return number * 9.97 * 3600000 * 1000;
		case EnergyType.OFF_ROAD_DIESEL_LITRE:
			// we convert it back to litre since we stock it in m³
			return number * 9.81 * 3600000 * 1000;
		case EnergyType.WOOD_LOGS_STERE:
			return number * 1680 * 3600000;
	}
}

export function energyTypeJouleToUnit(energyType: EnergyType, number: number): number {
	return number / energyTypeUnitToJoulePci(energyType, 1);
}
