import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { VersionService } from "../services/version.service";

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
	constructor(private versionService: VersionService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			tap((response) => {
				if (
					request.url.includes(environment.baseApiUrl) &&
					response instanceof HttpResponse &&
					response.headers.get("Version")
				) {
					this.versionService.setVersion(response.headers.get("Version"));
				}
			}),
		);
	}
}
