import { Pipe, PipeTransform } from "@angular/core";
import { Nullable } from "../helpers/nullable";

@Pipe({
	name: "checkErrorsFormat",
})
export class CheckErrorsFormatPipe implements PipeTransform {
	transform(errors: { name: Nullable<string>; errors: string[] }[]): string {
		return errors
			.map((error) => error.name + ":\n" + error.errors.map((error) => " - " + error).join("\n"))
			.join("\n\n");
	}
}
