<div style="width: 1000px">
	<div #search>
		<div class="message-warning mt-1 mb-1">
			Attention, seules les entités avec des déclarations de consommations <b>complètes</b> seront importées.
		</div>
		<app-custom-form>
			<div class="w-100 d-flex" style="gap: 5rem">
				<div class="w-50 d-flex-col">
					<div *ngIf="errorIds" class="danger bold">
						Le communication par API est impossible.
						<span *ngIf="editableUserKey">
							<br />
							Merci de vérifier l'identifiant de la structure ainsi que votre clé utilisateur.
						</span>
						<span *ngIf="!editableUserKey">
							<br />
							Veuillez contacter l'administrateur de votre compte.
						</span>
					</div>
					<ng-container *ngIf="editableInseeId || editableUserKey">
						<div class="custom-input-group" *ngIf="editableInseeId">
							<label>ID de la structure</label>
							<input class="custom-input" [(ngModel)]="inseeId" />
						</div>
						<div class="custom-input-group" *ngIf="editableUserKey">
							<div class="d-flex" style="text-align: center">
								<div class="d-flex-col" style="justify-content: center"><label>Clé utilisateur</label></div>
								<app-help [subject]="HelpSubject.OperatUserKey"></app-help>
							</div>
							<input class="custom-input" [(ngModel)]="userKey" />
						</div>
					</ng-container>
				</div>
				<div class="w-50 d-flex-col gap-2 mt-1" style="justify-content: space-between">
					<div *ngIf="noDataToImport" class="danger bold">Aucune donnée à importer trouvée.</div>
					<div class="custom-input-group">
						<mat-slide-toggle [ngModel]="importAll" (ngModelChange)="importAll = $event; resetImportData()"
							>Importer toutes les entités fonctionnelles de la structure</mat-slide-toggle
						>
					</div>
					<div *ngIf="!importAll">
						<div class="custom-input-group w-100">
							<div *ngIf="errorUserRefs" class="danger bold">La liste ne peut pas être vide.</div>
							<button
								*ngIf="userReferences$ | async as userRef"
								class="btn btn-primary"
								(click)="openUserReferencesModal()"
							>
								{{ userRef.length }} entité{{ userRef.length > 1 ? "s" : "" }} à importer
								<span *ngIf="userRef.length === 0">- Cliquez pour ajouter</span>
							</button>
						</div>
					</div>
					<button
						*ngFor="let userReferences of [userReferences$ | async]"
						class="btn-primary"
						(click)="searchEntities(userReferences ?? [])"
						[disabled]="searchingEntities$ | async"
					>
						<ng-container *ngIf="(searchingEntities$ | async) === false; else loading">
							Vérifier les données
						</ng-container>
						<ng-template #loading>
							<mat-spinner class="mx-auto" [diameter]="20"></mat-spinner>
						</ng-template>
					</button>
					<div class="message-warning" *ngIf="searchingEntities$ | async">
						Cette opération peut prendre quelques dizaines de secondes.
					</div>
				</div>
			</div>
		</app-custom-form>
	</div>
	<ng-container *ngIf="refErrors.length > 0">
		<mat-divider class="mt-2 mb-2"></mat-divider>
		<div class="message-danger">
			<span>Une ou plusieurs références sont introuvables :</span>
			<div id="results" class="d-flex px-2">
				{{ refErrors.join(", ") }}
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="unknownError">
		<mat-divider class="mt-2 mb-2"></mat-divider>
		<div class="message-danger">
			Une erreur est survenue lors de la communication avec OPERAT, veuillez réessayer.<br />
			Si l'erreur persiste, veuillez contacter l'administrateur de votre compte.
		</div>
	</ng-container>
	<ng-container *ngIf="refErrors.length === 0 && importData.references.length > 0">
		<mat-divider class="mt-2 mb-2"></mat-divider>
		<div
			*ngIf="!noDataToImport && importData.references.length > 0"
			class="bold message-primary-reverse ml-auto mt-1"
			style="width: fit-content"
		>
			{{ importData.references.length }} entité{{ importData.references.length > 1 ? "s" : "" }} trouvée{{
				importData.references.length > 1 ? "s" : ""
			}}.
		</div>
	</ng-container>
</div>

<ng-template #duplicateEfa let-data>
	<div class="dialog-container">
		<div class="dialog-content">
			<h3>Entités déjà existantes</h3>
			Les entités suivantes existent déjà sur le configurateur, elles ne seront donc pas importées :
			<ul>
				<li *ngFor="let efa of data.efas">
					<div class="d-flex" style="justify-content: space-between">
						<span>{{ efa.refOperat }}</span>
						<span>{{ efa.name }}</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="dialog-actions">
			<button class="btn-primary-reverse ml-auto" [mat-dialog-close]="undefined">Ok</button>
		</div>
	</div>
</ng-template>

<ng-template #userReferencesModal>
	<div class="dialog-container">
		<div class="dialog-content">
			<h3>Liste des références à importer</h3>
			<table *ngIf="userReferences$ | async as userReferences" class="w-100 pl-2" style="text-align: center">
				<tr *ngFor="let reference of userReferences; let i = index">
					<td>
						<div class="d-flex" style="justify-content: space-between; vertical-align: center">
							{{ reference }}
							<mat-icon
								color="warn"
								style="transform: rotate(45deg); cursor: pointer"
								(click)="removeReference(reference, userReferences)"
								>add</mat-icon
							>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="dialog-actions">
			<button class="btn-primary-reverse" (click)="openAddReferenceModal()">Ajouter des références</button>
			<button class="btn-primary-reverse ml-auto" [mat-dialog-close]="undefined">Ok</button>
		</div>
	</div>
</ng-template>

<ng-template #addUserReferencesModal>
	<div class="dialog-container">
		<div class="dialog-content">
			<h3>Ajouter des références</h3>
			<textarea
				#textArea
				class="custom-input w-100"
				style="resize: vertical"
				[(ngModel)]="userReferencesToAdd"
				(paste)="parseReferences($event)"
			>
			</textarea>
		</div>
		<div class="dialog-actions">
			<button class="btn-reverse" [mat-dialog-close]="undefined">Annuler</button>
			<button class="btn-primary-reverse ml-auto" [mat-dialog-close]="undefined" (click)="addUserReferences()">
				Ajouter ces références
			</button>
		</div>
	</div>
</ng-template>
