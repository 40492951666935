<div class="agreement-entry d-flex gap-1">
	<div class="agreement-details">
		<h3 class="my-0 d-flex gap-1" [class.danger]="!(agreement | canGetAutomatically)">
			{{ agreement.reference }}
			<div *ngIf="!(agreement | canGetAutomatically)">
				<mat-icon [matTooltip]="(agreement | cantGetAutomaticallyReason) ?? ''" matTooltipPosition="above"
					>help</mat-icon
				>
			</div>
		</h3>
		<span class="text-gray">
			<ng-container *ngIf="agreement.holder_type === HolderType.INDIVIDUAL">
				{{ agreement.holder_individual_first_name + " " + agreement.holder_individual_last_name }}
			</ng-container>
			<ng-container *ngIf="agreement.holder_type === HolderType.COMPANY">
				{{ agreement.holder_company_name }}
			</ng-container>
			- {{ agreement.email }}
		</span>
		<ul class="danger m-0" *ngIf="showStatus && agreement.comments.length > 0">
			<li *ngFor="let comment of agreement.comments">{{ comment }}</li>
		</ul>
	</div>

	<div class="d-flex gap-1">
		<button
			*ngIf="canGoAssetDeclaration"
			class="btn-primary btn-squared"
			(click)="goAssetDeclaration.emit()"
			matTooltip="Se rendre à la déclaration patrimoniale."
			matTooltipPosition="above"
		>
			<mat-icon>visibility</mat-icon>
		</button>
		<ng-container *ngIf="showStatus && (agreement | canGetAutomatically)">
			<button
				*ngIf="!agreement.is_accepted && (agreement | onCoolodwn)"
				class="btn-text-gray btn-squared"
				matTooltip="Une demande à été envoyé récemment à votre correspondant."
				matTooltipPosition="above"
			>
				<mat-icon>hourglass_empty</mat-icon>
			</button>
			<button
				*ngIf="!agreement.is_accepted && !(agreement | onCoolodwn)"
				class="btn-primary btn-squared"
				(click)="resendAction.emit(agreement)"
				[matTooltip]="(agreement.email_sent_at ? 'Renvoyer' : 'Envoyer') + ' une demande à votre correspondant.'"
				matTooltipPosition="above"
			>
				<mat-icon>send</mat-icon>
			</button>
			<button
				*ngIf="agreement.is_accepted && (!agreement.short_data_received || !agreement.year_data_received)"
				class="btn-text-gray btn-squared"
				matTooltip="En cours de récupération des données de consommation."
				matTooltipPosition="above"
			>
				<mat-icon>pending</mat-icon>
			</button>
			<button
				*ngIf="agreement.is_accepted && agreement.year_data_received && agreement.short_data_received"
				class="btn-accent btn-squared"
				matTooltip="Données de consommation reçues"
				matTooltipPosition="above"
			>
				<mat-icon>done</mat-icon>
			</button>

			<button
				[disabled]="!canRenewAgreement"
				class="btn-primary-light btn-squared"
				matTooltip="Renouveler les accords"
				matTooltipPosition="above"
				(click)="openConfirmDialog()"
			>
				<mat-icon>sync</mat-icon>
			</button>
		</ng-container>
		<button *ngIf="canEdit" class="btn-{{ color }} btn-squared" (click)="editAction.emit(agreement)">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
</div>

<ng-template #modalRenewal>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="my-0">Renouvellement du consentement</h3>
			<div class="dialog-small-text">
				<div class="d-flex-col gap-3">
					<div>
						Souhaitez-vous vraiment renvoyer une demande de renouvellement du consentement pour ce point de livraison ?
					</div>
					<div *ngIf="isLessThan6Months()">Une réponse a déjà été reçue il y a moins de 6 mois.</div>
				</div>
			</div>
		</div>
		<div class="dialog-actions d-flex" style="justify-content: space-between">
			<button class="btn-stroked-text-gray" [mat-dialog-close]="undefined">Annuler</button>
			<button class="btn-stroked-primary" (click)="renewalAction.emit(this.agreement)" [mat-dialog-close]="undefined">
				Confirmer
			</button>
		</div>
	</div>
</ng-template>
