export enum EnergyCategory {
	// To add
	INDIVIDUAL = "individual",
	DISTRIBUTED = "distributed",
	COMMON = "common",

	// To remove
	DEDUCT = "deduct",

	// Heat/cool
	USAGE = "usage",
}

export function energyCategoryLabel(energyCategory: EnergyCategory): string {
	switch (energyCategory) {
		case EnergyCategory.INDIVIDUAL:
			return "Individuelle";
		case EnergyCategory.DISTRIBUTED:
			return "Répartie";
		case EnergyCategory.COMMON:
			return "Commune";
		case EnergyCategory.DEDUCT:
			return "Déduite";
		case EnergyCategory.USAGE:
			return "Usage";
	}
}

export function getEnergyCategoriesToAdd(multiOccupation: boolean): Set<EnergyCategory> {
	return multiOccupation
		? new Set([EnergyCategory.INDIVIDUAL, EnergyCategory.DISTRIBUTED, EnergyCategory.COMMON])
		: new Set([EnergyCategory.INDIVIDUAL]);
}
