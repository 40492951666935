<button
	(click)="emit()"
	[class.btn-primary]="!current"
	class="h-100 d-flex-col p-3"
	[class.btn-accent]="current"
	style="width: 200px; align-items: center"
	mat-dialog-close=""
>
	<h4 class="m-0">{{ description | titlecase }}</h4>
	<span *ngIf="type !== 'Mandataire'" class="text-lightgray" style="font-size: 12px">{{ type }}</span>
</button>
