import { Immutable } from "immer";
import { UserIdZod } from "./ids";
import { z } from "zod";

export type User = z.infer<typeof User>;

export const User = z.object({
	user_id: UserIdZod.optional().nullable(),
	email: z.string().optional().nullable(),
	first_name: z.string().optional().nullable(),
	name: z.string().optional().nullable(),
	token: z.string().optional().nullable(),
	is_temporary: z.boolean().optional().nullable(),
	hasNoAccount: z.boolean().optional().nullable(),
	phone_number: z.string().optional().nullable(),
	last_connection: z.coerce.number().optional().nullable(),
	civility: z.number().optional().nullable(),
	address: z
		.object({
			country: z.string(),
			houseNumber: z.string(),
			address: z.string(),
			address2: z.string(),
			city: z.string(),
			postalCode: z.string(),
			vatNumber: z.string(),
		})
		.catch({
			country: "France",
			houseNumber: "",
			address: "",
			address2: "",
			city: "",
			postalCode: "",
			vatNumber: "",
		}),
	color: z.string().optional().nullable(),
});

export function cloneUser(user: Immutable<User>): User {
	const clone: User = { ...user, address: { ...user.address } };

	return clone;
}
