<mat-dialog-content>
	<p>
		L'import de fichier CSV permet de saisir en masse vos données de consommations. Pour cela, vous devez d'abord
		télécharger le fichier modèle
		<a class="text-secondary pointer" role="button" (click)="downloadCallback()">ici</a>
		.
	</p>
	<p>
		Vous devez ensuite compléter les trois colonnes présentes : la première contient les dates de début et de fin
		associées à la consommation saisie dans la troisième colonne. Les dates doivent être au format JJ/MM/AAAA ou
		JJ/MM/AA.
	</p>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [matDialogClose]="null">Fermer</button>
</mat-dialog-actions>
