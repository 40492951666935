import { Bullet, Circle, Color, ICircleSettings, Label, p50, Root, Template, Tooltip } from "@amcharts/amcharts5";
import { XYChart } from "@amcharts/amcharts5/.internal/charts/xy/XYChart";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AxisRendererX, AxisRendererY, LineSeries, ValueAxis, XYCursor } from "@amcharts/amcharts5/xy";
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { ChartData } from "../../../../../helpers/benchmark-data";
import { getColorFromPercentage } from "../../../../../helpers/color";
import { Declaration } from "../../../../../models/declaration";
import { FunctionalDeclarationId } from "../../../../../models/ids";
import { Djus } from "../../../../../services/declaration-functional.service";

@Component({
	selector: "app-benchmark-graph",
	templateUrl: "./benchmark-graph.component.html",
	styleUrls: ["./benchmark-graph.component.scss"],
})
export class BenchmarkGraphComponent implements OnInit, OnChanges {
	@Input() animate = false;
	@Input() declaration!: Declaration;
	@Input() djus!: Djus;
	@Input() year = 2021;
	@Input() focusId: FunctionalDeclarationId | undefined | null;
	@Input() data: ChartData[] = [];
	@Input() minTotal = Infinity;
	@Input() maxTotal = -Infinity;
	@Input() minPerSurface = Infinity;
	@Input() maxPerSurface = -Infinity;

	@ViewChild("benchmark") benchmark!: ElementRef;

	FOCUS_COLOR = [54, 92, 193];
	LOW_COLOR = [129, 218, 152];
	MEDIUM_COLOR = [255, 156, 7];
	HIGH_COLOR = [209, 88, 129];

	series: LineSeries | undefined;
	xLabel: Label | undefined;
	yLabel: Label | undefined;
	root: Root | undefined;

	constructor() {}

	ngOnInit() {
		setTimeout(() => {
			const root = Root.new(this.benchmark.nativeElement);
			this.root = root;

			if (this.animate) {
				root.setThemes([am5themes_Animated.new(root)]);
			}

			const chart = root.container.children.push(
				XYChart.new(root, {
					panX: true,
					panY: true,
					wheelY: "zoomXY",
					pinchZoomX: true,
					pinchZoomY: true,
				}),
			);

			const xAxis = chart.xAxes.push(
				ValueAxis.new(root, {
					renderer: AxisRendererX.new(root, {}),
					tooltip: Tooltip.new(root, {}),
					min: 0,
					numberFormat: "#.#aWh",
					strictMinMax: false,
					calculateTotals: true,
				}),
			);

			this.xLabel = Label.new(root, {
				text: `Consommation totale de ${this.year}`,
				x: p50,
				centerX: p50,
			});

			xAxis.children.moveValue(this.xLabel, xAxis.children.length - 1);

			const yAxis = chart.yAxes.push(
				ValueAxis.new(root, {
					renderer: AxisRendererY.new(root, {}),
					tooltip: Tooltip.new(root, {}),
					min: 0,
					numberFormat: "#.#aWh/m²",
					strictMinMax: false,
					calculateTotals: true,
				}),
			);

			this.yLabel = Label.new(root, {
				rotation: -90,
				text: `Consommation surfacique en ${this.year}`,
				y: p50,
				centerX: p50,
			});

			yAxis.children.moveValue(this.yLabel, 0);

			this.series = chart.series.push(
				LineSeries.new(root, {
					calculateAggregates: true,
					xAxis,
					yAxis,
					valueXField: "totalConsumption",
					valueYField: "consumptionPerSurface",
					seriesTooltipTarget: "bullet",
				}),
			);

			const tooltip = this.series.set(
				"tooltip",
				Tooltip.new(root, {
					pointerOrientation: "vertical",
					tooltipText:
						"[bold]{title}[/]\nConsommation totale: {valueX.formatNumber('#,###')}\nConsommation par m²: {valueY.formatNumber('#,###.')}",
				}),
			);

			tooltip.adapters.add("labelText", (label, target) => {
				if (target.dataItem) {
					const context = target.dataItem.dataContext as ChartData;
					return (
						`[bold]${context.name}[/]\n` +
						`\t\t${context.address}\n` +
						`\t\t[bold]Consommation surfacique:[/] {valueY.formatNumber('#.##a')}Wh/m²\n` +
						`\t\t[bold]Consommation totale:[/] {valueX.formatNumber('#.##a')}Wh`
					);
				}
				return "";
			});

			this.series.strokes.template.set("visible", false);

			const circleTemplate = Template.new<Circle>({});
			circleTemplate.adapters.add("fill", (fill, target) => {
				let color = getColorFromPercentage(0, this.LOW_COLOR, this.MEDIUM_COLOR, this.HIGH_COLOR);
				if (target.dataItem) {
					const context = target.dataItem.dataContext as ChartData;
					if (this.focusId === context.id) {
						return Color.fromRGB(this.FOCUS_COLOR[0], this.FOCUS_COLOR[1], this.FOCUS_COLOR[2]);
					}
					const perSurfacePercentage =
						(context.consumptionPerSurface - this.minPerSurface) / (this.maxPerSurface - this.minPerSurface);
					const totalPercentage = (context.totalConsumption - this.minTotal) / (this.maxTotal - this.minTotal);
					color = getColorFromPercentage(
						(perSurfacePercentage + totalPercentage) / 2,
						this.LOW_COLOR,
						this.MEDIUM_COLOR,
						this.HIGH_COLOR,
					);
				}
				return Color.fromRGB(color[0], color[1], color[2]);
			});

			const focusRules: { key: keyof ICircleSettings; onFocus: number; default: number; animate: boolean }[] = [
				{ key: "radius", onFocus: 14, default: 7, animate: false },
				{ key: "fillOpacity", onFocus: 1, default: 0.8, animate: true },
				{ key: "layer", onFocus: 10, default: 1, animate: false },
			];
			focusRules
				.filter((obj) => this.animate || !obj.animate)
				.forEach((obj) => {
					circleTemplate.adapters.add(obj.key, (value, target) => {
						if (target.dataItem) {
							const context = target.dataItem.dataContext as ChartData;
							if (this.focusId === context.id) {
								return obj.onFocus;
							}
						}
						return value ?? obj.default;
					});
				});

			this.series.bullets.push(() => {
				const bulletCircle = Circle.new(
					root,
					{
						radius: 7,
						fill: this.series?.get("fill"),
						fillOpacity: this.animate ? 0.8 : 1,
					},
					circleTemplate,
				);
				return Bullet.new(root, {
					sprite: bulletCircle,
				});
			});

			chart.set(
				"cursor",
				XYCursor.new(root, {
					xAxis: xAxis,
					yAxis: yAxis,
					snapToSeries: [this.series],
				}),
			);

			this.series.data.setAll(this.data);

			this.series.appear(1000);
			chart.appear(1000, 100);

			this.series.data.setAll(this.data);
		}, 300);
	}

	ngOnChanges() {
		this.series?.data.setAll(this.data);
		this.xLabel?.set("text", `Consommation totale de ${this.year}`);
		this.yLabel?.set("text", `Consommation surfacique en ${this.year}`);
	}
}
