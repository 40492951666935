import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { getCategoryFromSubCategoryCode } from "../helpers/category-helpers";
import { ActivityCategoryEntry, CategoryCode } from "../models/asset";

@Pipe({
	name: "inCategories",
})
export class InCategoriesPipe implements PipeTransform {
	transform(mainCategory: CategoryCode | undefined, categories: Immutable<ActivityCategoryEntry[]>): boolean {
		return (
			!mainCategory ||
			categories.length === 0 ||
			categories.find((category) => getCategoryFromSubCategoryCode(category.subCategoryCode).code === mainCategory) !==
				undefined
		);
	}
}
