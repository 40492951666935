import { AfterViewInit, Directive, OnDestroy } from "@angular/core";
import { Observable, PartialObserver, Subscription } from "rxjs";
import { InitialLoadingService } from "../services/initial-loading.service";

/**
 * Provides the `sub` method that unsubscribes the subscriptions automatically on destroy.
 */
@Directive()
export abstract class BaseComponent implements OnDestroy, AfterViewInit {
	private toUnsub: Subscription[] = [];

	constructor() {
		InitialLoadingService.increment();
	}

	ngAfterViewInit() {
		InitialLoadingService.decrement();
	}

	ngOnDestroy(): void {
		this.toUnsub.forEach((sub) => {
			sub.unsubscribe();
		});
	}

	protected sub<T>(observable: Observable<T>, observer: PartialObserver<T>): void;

	protected sub<T>(
		observable: Observable<T>,
		next?: (value: T) => void,
		error?: ((error: unknown) => void) | undefined,
		complete?: (() => void) | undefined,
	): void;

	protected sub<T>(
		observable: Observable<T>,
		observerOrNext: PartialObserver<T> | ((value: T) => void) | undefined,
		error?: ((error: unknown) => void) | undefined,
		complete?: (() => void) | undefined,
	) {
		if (typeof observerOrNext === "function") {
			this.toUnsub.push(observable.subscribe(observerOrNext, error, complete));
		} else {
			this.toUnsub.push(observable.subscribe(observerOrNext));
		}
	}
}
