import { Pipe, PipeTransform } from "@angular/core";
import { Category, getCategories } from "../../assets/data/categories";
import { CategoryCode } from "../models/asset";

@Pipe({
	name: "categoryFromCode",
})
export class CategoryFromCodePipe implements PipeTransform {
	transform(code: CategoryCode): Category | undefined {
		return getCategories().find((c) => c.code === code);
	}
}
