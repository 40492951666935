<button
	mat-flat-button
	class="{{ classes }}"
	matTooltip="Nombre de crédits restants. Ils sont décomptés à partir de l'étape déclaration patrimoniale."
	matTooltipPosition="above"
	(click)="click()"
>
	<mat-icon class="mr-1">toll</mat-icon>
	<span>
		Crédit{{ this.totalTokens - this.usedToken > 1 ? "s" : "" }} {{ type }} initia{{
			this.totalTokens - this.usedToken > 1 ? "ux" : "l"
		}}
		: {{ this.totalTokens - this.usedToken }},
	</span>
	<span>
		annuel{{ this.annualTokens - this.usedAnnualTokens > 1 ? "s" : "" }} :
		{{ this.annualTokens - this.usedAnnualTokens }}
	</span>
</button>
