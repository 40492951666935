<div style="border-radius: 15px; overflow: hidden">
	<app-my-map
		[style]="STYLE"
		[center]="center"
		[layers]="LAYERS"
		[featureStates]="featureStates.value"
		(layerClick)="$event.layerId === 'parcelles-fill' ? onParcelClick($event.event) : undefined"
		(layerMouseMove)="$event.layerId === 'parcelles-fill' ? enableHover($event.event) : undefined"
		(layerMouseLeave)="$event.layerId === 'parcelles-fill' ? disableHover() : undefined"
	></app-my-map>
</div>
