import { Immutable } from "immer";
import { Declaration } from "./declaration";
import { EnergyType } from "./energyType";
import { HeatModifierType } from "./heatModifierType";
import { ParcelId } from "./ids";

export interface AssetInfo {
	heatId: string;
	mainParcel?: ParcelId;
	station?: number;
	energyType: EnergyType[];
	electricCarDock: { start: number; end?: number; count: number }[];
	localArea?: number;
	categories: ActivityCategoryEntry[];
	mainCategory?: CategoryCode;
}

export function newAssetInfo(): AssetInfo {
	return {
		heatId: "",
		categories: [],
		electricCarDock: [],
		energyType: [EnergyType.ELECTRICITY_KWH],
		mainParcel: undefined,
		station: undefined,
		mainCategory: undefined,
	};
}

export function cloneAssetInfo(info: Immutable<AssetInfo>): AssetInfo {
	return {
		...info,
		energyType: [...info.energyType],
		categories: info.categories ? info.categories.map(cloneActivityCategoryEntry) : [],
		electricCarDock: info.electricCarDock ? info.electricCarDock.map((data) => ({ ...data })) : [],
	};
}

export type SurfaceByModifier = { [key in HeatModifierType]?: number };

export type CategoryCode = string & { readonly CategoryCode: unique symbol };
export type SubCategoryCode = string & { readonly SubCategoryCode: unique symbol };

export function isSubCategoryCodeDefault(code: SubCategoryCode): boolean {
	return code.endsWith("00");
}

export class ActivityCategoryEntry {
	name: string;
	subCategoryCode: SubCategoryCode;
	surface: number;
	temperatureModifiers: HeatModifierType[];
	logisticCooledHeight: number;
	start = Declaration.MINIMUM_DATE_TIME;
	end?: number;
	indicators: ActivityIndicator[] = [];

	constructor(data: Immutable<Partial<ActivityCategoryEntry> & { subCategoryCode: SubCategoryCode }>) {
		this.name = data.name ?? "";
		this.subCategoryCode = data.subCategoryCode;
		this.surface = data.surface ?? 0;
		this.temperatureModifiers = [...(data.temperatureModifiers ?? [])];
		this.logisticCooledHeight = data.logisticCooledHeight ?? 0;
		this.start = data.start ?? Declaration.MINIMUM_DATE_TIME;
		this.end = data.end;
		this.indicators = data.indicators ? data.indicators.map((indicator) => ({ ...indicator })) : [];
	}
}

export class Irve {
	start: number;
	end?: number;
	count: number;

	constructor(data: Immutable<Partial<Irve>>) {
		this.start = data.start ?? Declaration.MINIMUM_DATE_TIME;
		this.end = data.end;
		this.count = data.count ?? 1;
	}
}

export function cloneActivityCategoryEntry(
	activityCategoryEntry: Immutable<ActivityCategoryEntry>,
): ActivityCategoryEntry {
	return {
		...activityCategoryEntry,
		indicators: activityCategoryEntry.indicators.map((i) => ({ ...i })),
		temperatureModifiers: [...activityCategoryEntry.temperatureModifiers],
	};
}

export interface ActivityIndicator {
	code: string;
	value: number;
}
