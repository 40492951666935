import { GreenKeys as Keys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Observable, of, zip } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AssociationApi } from "../models/associationApi";
import { Declaration, newDeclaration } from "../models/declaration";
import { etablissementApiToFunctionalEntity } from "../models/functionalDeclaration";
import { IdOther, Rna, Siren, Siret } from "../models/identifier";
import { UniteLegaleSirenRoute } from "../models/siren-api.types";
import { RnaService } from "../services/rna.service";
import { SirenService } from "../services/siren.service";
import { myForkjoin } from "./to-observable";

export function otherToDeclaration$(
	idOther: Immutable<IdOther>,
	isMandated: boolean,
	tenants?: {
		sirenService: SirenService;
		sirets: Immutable<Set<Siret>>;
	},
): Observable<Declaration> {
	if (tenants) {
		return myForkjoin(Array.from(tenants.sirets).map((siret) => tenants.sirenService.getEtablissement$(siret))).pipe(
			map((etablissements) =>
				etablissements.map((etablissement) =>
					etablissementApiToFunctionalEntity(etablissement, {
						type: Keys.KEY_RNA,
						secondaryOwner: undefined,
						status: {
							condominium: undefined,
							type: Keys.KEY_LANDLORD,
							tenant: { siret: etablissement.siret },
						},
					}),
				),
			),
			map((declarations_functional) =>
				newDeclaration({
					structure: { id: idOther.id, label: idOther.label },
					name: idOther.id,
					declarations_functional,
					is_mandated: isMandated,
				}),
			),
		);
	}

	return of(
		newDeclaration({
			structure: { id: idOther.id, label: idOther.label },
			name: idOther.label,
			is_mandated: isMandated,
		}),
	);
}

export function rnaToDeclaration$(
	rnaService: RnaService,
	rna: Rna,
	isMandated: boolean,
	tenants?: {
		sirenService: SirenService;
		sirets: Immutable<Set<Siret>>;
	},
): Observable<Declaration> {
	if (tenants) {
		return myForkjoin(Array.from(tenants.sirets).map((siret) => tenants.sirenService.getEtablissement$(siret))).pipe(
			map((etablissements) =>
				etablissements.map((etablissement) =>
					etablissementApiToFunctionalEntity(etablissement, {
						type: Keys.KEY_SIREN,
						secondaryOwner: undefined,
						status: {
							type: Keys.KEY_LANDLORD,
							data: {
								tenant_without_siret: false,
								tenant: { siret: etablissement.siret },
								condominium: undefined,
							},
						},
					}),
				),
			),
			switchMap((functionalDeclarations) => zip(of(functionalDeclarations), rnaService.getAssociation$(rna))),
			map(([declarations_functional, association]) =>
				newDeclaration({
					structure: { rna },
					name: association.title,
					declarations_functional,
					is_mandated: isMandated,
					is_demo: false,
				}),
			),
		);
	}

	return rnaService.getAssociation$(rna).pipe(
		map<AssociationApi, Declaration>((association) => ({
			version: 1,

			structure: { rna },
			name: association.title,
			declarations_functional: [],
			is_mandated: isMandated,
			is_demo: false,
			chosen_declaration_functional_ids_for_csv: new Set(),
			operat_user_key: undefined,
		})),
	);
}

export function fullSirenToDeclaration$(
	sirenService: SirenService,
	siren: Siren,
	isMandated: boolean,
	areOccupant: boolean,
): Observable<Declaration> {
	return sirenService.getEtablissements$(siren).pipe(
		map((etablissementsApi) => {
			let openedEtablissements = etablissementsApi.filter(
				(etablissement) => etablissement.periodesEtablissement[0].etatAdministratifEtablissement === "A",
			);
			if (openedEtablissements.length === 0) {
				// If everything is closed then we give every closed etablissements
				openedEtablissements = etablissementsApi;
			}
			return openedEtablissements.map((eApi) =>
				etablissementApiToFunctionalEntity(eApi, {
					type: Keys.KEY_SIREN,
					status: {
						type: Keys.KEY_OWNER_OCCUPANT,
						data: { without_siret: false, condominium: undefined, siret: eApi.siret },
					},
					secondaryOwner: undefined,
				}),
			);
		}),
		switchMap((functionalDeclarations) =>
			zip(of(functionalDeclarations), sirenToDeclaration$(sirenService, siren, new Set(), areOccupant, isMandated)),
		),
		tap(([functionalDeclarations, declaration]) => {
			declaration.declarations_functional = functionalDeclarations;
		}),
		map(([, declaration]) => declaration),
	);
}

export function sirenToDeclaration$(
	sirenService: SirenService,
	siren: Siren,
	sirets: Immutable<Set<Siret>>,
	areOccupant: boolean,
	isMandated: boolean,
	isImported: boolean = false,
): Observable<Declaration> {
	return sirenService
		.getUniteLegale$(siren)
		.pipe(
			switchMap((legalUnit) =>
				legalUnitToDeclaration$(sirenService, legalUnit, sirets, areOccupant, isMandated, isImported),
			),
		);
}

export function legalUnitToDeclaration$(
	sirenService: SirenService,
	legalUnit: UniteLegaleSirenRoute,
	sirets: Immutable<Set<Siret>>,
	areOccupant: boolean,
	isMandated: boolean,
	isImported: boolean,
): Observable<Declaration> {
	return myForkjoin(Array.from(sirets).map((siret) => sirenService.getEtablissement$(siret))).pipe(
		map((etablissements) => {
			const declarations_functional = isImported
				? []
				: etablissements.map((eApi) =>
						etablissementApiToFunctionalEntity(
							eApi,
							// can't have a tenant with the same siren as the owner
							areOccupant && eApi.siren !== legalUnit.siren
								? {
										type: Keys.KEY_SIREN,
										status: {
											type: Keys.KEY_LANDLORD,
											data: {
												[Keys.KEY_TENANT_WITHOUT_SIRET]: false,
												condominium: null,
												tenant: { siret: eApi.siret },
											},
										},
										secondaryOwner: undefined,
									}
								: {
										type: Keys.KEY_SIREN,
										status: {
											type: Keys.KEY_OWNER_OCCUPANT,
											data: { [Keys.KEY_WITHOUT_SIRET]: false, siret: eApi.siret, condominium: undefined },
										},
										secondaryOwner: undefined,
									},
						),
					);

			return {
				version: 1,
				structure: {
					siren: legalUnit.siren,
					maybeRna: legalUnit.identifiantAssociationUniteLegale,
				},
				siren: legalUnit.siren,
				name: legalUnit.periodesUniteLegale[0].denominationUniteLegale,
				ape_code: legalUnit.periodesUniteLegale[0].activitePrincipaleUniteLegale,
				declarations_functional,
				favorite_period: 0,
				is_mandated: isMandated,
				is_demo: false,
				chosen_declaration_functional_ids_for_csv: new Set(),
				operat_user_key: undefined,
			};
		}),
	);
}
