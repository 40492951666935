import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk } from "../models/agreementAsk";

@Pipe({
	name: "agreementCanEdit",
})
export class AgreementCanEditPipe implements PipeTransform {
	transform(agreement: Immutable<AgreementAsk>, canEdit: boolean): boolean {
		return canEdit || agreement.is_treated || (!agreement.is_accepted && !!agreement.last_renewal);
	}
}
