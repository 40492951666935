import { Injectable } from "@angular/core";
import { ResourceInfo } from "../models/resource";

const ISOLATION_KEY = "isolation";

@Injectable({
	providedIn: "root",
})
export class IsolationStateService {
	isolationInfo?: ResourceInfo;

	static setIsolationToStorage(info: ResourceInfo) {
		localStorage.setItem(ISOLATION_KEY, JSON.stringify(info));
	}

	static getIsolationFromStorage(): ResourceInfo | null {
		const text = localStorage.getItem(ISOLATION_KEY);
		return text ? JSON.parse(text) : null;
	}

	setIsolation(info: ResourceInfo) {
		this.isolationInfo = info;
	}
}
