export enum HolderType {
	COMPANY = "company",
	INDIVIDUAL = "individual",
}

export function getHolderTypeDisplayString(ht: HolderType): string {
	switch (ht) {
		case HolderType.COMPANY:
			return "Entreprise/Collectivité";
		case HolderType.INDIVIDUAL:
			return "Particulier";
	}
}
