<div class="dialog-container" tourAnchor="building-modal">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2 text-icon">
			<mat-icon>apartment</mat-icon>
			{{ state.buildingInfo.label[0] ? state.buildingInfo.label[0] : "Bâtiment sans nom" }}
		</h3>
		<div class="d-flex gap-3">
			<div>
				<div class="candy-form">
					<div class="d-flex" style="align-items: end; margin-bottom: 1em">
						<div>
							<div>
								<label required for="buildingName">Nom du bâtiment</label>
							</div>
							<input
								required
								id="buildingName"
								[(ngModel)]="state.buildingInfo.label[0]"
								class="custom-input"
								style="margin-right: 5px"
								placeholder="Nom du bâtiment"
								appInputRestriction="alphanumeric"
							/>
						</div>
						<button mat-button class="btn-primary" style="height: 40px" (click)="openLotNumberModal()">
							<mat-icon>tag</mat-icon>
							<span class="ml-2">{{
								state.buildingInfo.lotNumbers.length === 0
									? "Choisir mon numéro de lot"
									: state.buildingInfo.lotNumbers.length === 1
										? state.buildingInfo.lotNumbers[0]
										: state.buildingInfo.lotNumbers.length + " numéros de lot"
							}}</span>
						</button>
						<app-help [subject]="HelpSubject.LotNumber"></app-help>
					</div>
					<div class="mb-3">
						<span class="d-inline-flex gap-2">
							<div class="d-flex-col" style="justify-content: center">
								<label required style="height: fit-content"> Le bâtiment héberge exclusivement du tertiaire : </label>
							</div>
							<mat-radio-group
								aria-label="Select an option"
								class="d-inline-flex gap-2"
								[(ngModel)]="state.buildingInfo.isOnlyTertiary"
							>
								<mat-radio-button [value]="true">Oui</mat-radio-button>
								<mat-radio-button [value]="false">Non</mat-radio-button>
							</mat-radio-group>
						</span>
					</div>
					<div class="d-flex mb-3" style="align-items: center">
						<span class="d-flex gap-3" style="align-items: center">
							<span class="d-inline-flex gap-2">
								<div class="d-flex-col" style="justify-content: center">
									<label required style="height: fit-content"> Le bâtiment est en multi-occupation : </label>
								</div>
								<mat-radio-group
									aria-label="Select an option"
									class="d-inline-flex gap-2"
									[value]="
										state.buildingInfo.notOwnedSurfaces ? state.buildingInfo.notOwnedSurfaces.length > 0 : undefined
									"
								>
									<mat-radio-button [value]="true" (change)="toggleOwnWholeBuilding(true)">Oui</mat-radio-button>
									<mat-radio-button [value]="false" (change)="toggleOwnWholeBuilding(false)">Non</mat-radio-button>
								</mat-radio-group>
							</span>
						</span>
					</div>
				</div>

				<h3>
					<span class="text-icon"><mat-icon>crop</mat-icon>Surface tertiaire que vous occupez</span>
				</h3>
				<app-surface-tabs
					[surface]="state.buildingInfo.ownedSurface"
					(updateSurface)="updateOwnedSurface($event)"
				></app-surface-tabs>
				<h3 class="mt-3 pointer" (click)="isOptionalDataHidden = !isOptionalDataHidden">
					<span class="text-icon"><mat-icon>list_alt</mat-icon>Données facultatives</span>
					<mat-icon
						class="expand-icon"
						[style]="{ transform: isOptionalDataHidden ? 'rotate(0deg)' : 'rotate(180deg)' }"
					>
						expand_more
					</mat-icon>
				</h3>
				<div [hidden]="isOptionalDataHidden" class="candy-form">
					<app-optional-data-building [data]="state.buildingInfo.optionalBuildingData"></app-optional-data-building>
				</div>
			</div>
			<div
				style="position: sticky; align-self: flex-start; top: 0; max-width: 25em"
				*ngIf="state.buildingInfo.notOwnedSurfaces && state.buildingInfo.notOwnedSurfaces.length > 0"
			>
				<div class="d-flex gap-3" style="flex-direction: column">
					<div
						*ngIf="
							state.assujettiWithoutBuilding + state.buildingAssujettiOwned >= SURFACE_FOR_ASSUJETTI;
							else elseMessage
						"
						class="message-accent"
						style="width: auto"
					>
						<mat-icon>priority_high</mat-icon>
						Vous êtes assujetti avec seulement vos surfaces, vous n'avez donc pas besoin de renseigner d'avantage ces
						surfaces.
					</div>
					<ng-template #elseMessage>
						<div class="message-lighter-gray" style="width: auto">
							<mat-icon>priority_high</mat-icon>
							Vous pouvez additionner les surfaces des autres occupants dans une unique surface si vous ne ressentez pas
							le besoin de les détailler une par une.
						</div>
					</ng-template>
					<button
						mat-flat-button
						class="btn-primary-light"
						style="width: 100%"
						(click)="addNotOwnedSurface(state.buildingInfo.notOwnedSurfaces)"
					>
						<mat-icon>add</mat-icon>
						<span style="padding-left: 15px">Ajouter la surface d'un occupant</span>
					</button>
				</div>
				<mat-action-list>
					<ng-container *ngIf="state.buildingInfo.notOwnedSurfaces.length > 0">
						<p class="pb-1" mat-subheader>Décrire la surface des autres occupants</p>
						<ng-container *ngFor="let surface of state.buildingInfo.notOwnedSurfaces; index as i">
							<mat-list-item (click)="editNotOwnedSurface(state.buildingInfo.notOwnedSurfaces, i)">
								<mat-icon mat-list-icon> crop </mat-icon>
								<span mat-line>Occupant {{ i + 1 }}</span>
								<span class="ml-3" style="color: gray">{{ surface | surfaceAssujetti }} m² non exemptés</span>
							</mat-list-item>
						</ng-container>
					</ng-container>
				</mat-action-list>
			</div>
		</div>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" (click)="cancel()">Annuler</button>
		<button *ngIf="state.canDelete" class="btn-stroked-danger" (click)="delete()">Supprimer</button>
		<button
			*ngIf="
				state.buildingInfo.notOwnedSurfaces !== undefined &&
					state.buildingInfo.isOnlyTertiary !== undefined &&
					state.buildingInfo.ownedSurface.tertiaireArea > 0 &&
					state.buildingAssujettiOwned >= 0;
				else disabledButton
			"
			class="btn-stroked-primary"
			(click)="
				save({
					notOwnedSurfaces: state.buildingInfo.notOwnedSurfaces,
					isOnlyTertiary: state.buildingInfo.isOnlyTertiary,
				})
			"
		>
			{{ state.adding ? "Ajouter" : "Modifier" }}
		</button>
		<ng-template #disabledButton>
			<button
				class="btn-stroked-primary"
				[matTooltip]="
					state.buildingInfo.isOnlyTertiary === undefined
						? 'Veuillez préciser si le bâtiment héberge exclusivement du tertiaire.'
						: state.buildingInfo.ownedSurface.tertiaireArea <= 0
							? 'Veuillez donner une surface tertiaire supérieure à 0 m².'
							: state.buildingAssujettiOwned < 0
								? 'La somme des surfaces exemptées est supérieure à la surface tertiaire du bâtiment.'
								: 'Veuillez préciser si le bâtiment est en multi-occupation.'
				"
				[disabled]="true"
			>
				{{ state.adding ? "Ajouter" : "Modifier" }}
			</button>
		</ng-template>
	</div>
</div>
