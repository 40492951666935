import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { AddressInfo } from "../models/address";
import { Declaration } from "../models/declaration";
import { FunctionalDeclarationId } from "../models/ids";
import { Lazy } from "../models/lazy";
import { sortConsumptions } from "../pipes/sort-consumptions.pipe";
import { sumCategorySurfacePerYear } from "../pipes/sum-category-surface-per-year.pipe";
import { Djus } from "../services/declaration-functional.service";
import { unwrap } from "./unwrap";

export interface ChartData {
	name: string;
	id: FunctionalDeclarationId;
	address: string;
	totalConsumption: number;
	consumptionPerSurface: number;
}

function getData(declaration: Immutable<Declaration>, djus: Djus, year: number) {
	let minPerSurface = Infinity;
	let maxPerSurface = -Infinity;
	let minTotal = Infinity;
	let maxTotal = -Infinity;

	const data = declaration.declarations_functional.flatMap((declarationFunctional) => {
		// if year after 2021 and not unlocked
		if (
			(!declarationFunctional[GreenKeys.KEY_IS_TOKEN_USED] &&
				!declarationFunctional[GreenKeys.KEY_IS_IMPORTED_FROM_OPERAT]) ||
			(year >= Declaration.ANNUAL_DECLARATION_STARTING_YEAR &&
				!declarationFunctional[GreenKeys.KEY_YEARS_TOKEN_USED].includes(year))
		) {
			return [];
		}
		const surfacePerYear = sumCategorySurfacePerYear(declarationFunctional);
		if (!surfacePerYear[year]) {
			return [];
		}
		const yearData = sortConsumptions(
			declarationFunctional,
			djus[unwrap(declarationFunctional[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID])] ?? {},
			year,
			declarationFunctional[GreenKeys.KEY_INFOS].favoritePeriod,
		);
		if (!yearData) {
			return [];
		}
		const totalConsumption = yearData.tertiaire;
		const perSurface = yearData.tertiaire / surfacePerYear[year];
		minPerSurface = Math.min(perSurface, minPerSurface);
		maxPerSurface = Math.max(perSurface, maxPerSurface);
		minTotal = Math.min(totalConsumption, minTotal);
		maxTotal = Math.max(totalConsumption, maxTotal);
		return [
			{
				name: declarationFunctional[GreenKeys.KEY_NAME] ?? "",
				id: unwrap(declarationFunctional[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID]),
				address: AddressInfo.toString(declarationFunctional[GreenKeys.KEY_ADDRESS]),
				totalConsumption: totalConsumption,
				consumptionPerSurface: perSurface,
			},
		];
	});

	return {
		benchmarkData: data,
		minTotal,
		maxTotal,
		minPerSurface,
		maxPerSurface,
	};
}

function generateExport(declaration: Immutable<Lazy<Declaration>>, data: ChartData[], year: number) {
	const exportData = data.map(({ name, address, totalConsumption, consumptionPerSurface }) => {
		const numberTo2Decimals = (num: number) => parseFloat(num.toFixed(2));

		return {
			name,
			address,
			totalConsumption: numberTo2Decimals(totalConsumption / 1000),
			consumptionPerSurface: numberTo2Decimals(consumptionPerSurface / 1000),
		};
	});

	if (exportData.length <= 0) {
		return;
	}

	const exportCsv = exportData.map((row) =>
		[row.name, `"${row.address.trim()}"`, row.totalConsumption, row.consumptionPerSurface].join(","),
	);
	const headers = ["Nom", "Adresse", "Consommation totale (kWh)", "Consommation surfacique (kWh/m\u{00B2})"];
	exportCsv.unshift(headers.join(","));

	const csv = exportCsv.join("\r\n");

	return {
		csvData: csv,
		filename: `${declaration.name} - Nuage de points (${year})`,
	};
}

export const BenchMarkData = {
	getData,
	generateExport,
};
