<app-welcome>
	<div>
		<h1 class="login-title">Déclarons ensemble</h1>
		<div (keydown.enter)="testCredentials()">
			<p class="login-p" style="margin-top: 5px">Merci de vous connecter avec vos identifiants.</p>
			<p class="login-p">Email</p>
			<input
				[(ngModel)]="email"
				(ngModelChange)="resetError()"
				placeholder="Entrez votre adresse email"
				class="custom-input w-100"
				name="email"
				type="email"
			/>
			<p class="login-p">Mot de passe</p>
			<input
				[(ngModel)]="password"
				(ngModelChange)="resetError()"
				placeholder="Entrez votre mot de passe"
				class="custom-input w-100"
				name="password"
				type="password"
			/>
			<p *ngIf="error" class="danger">{{ error }}</p>
			<p *ngIf="errorEmail" class="danger">Vérifiez votre adresse mail.</p>
			<p *ngIf="errorPassword" class="danger">Vérifiez votre mot de passe.</p>
			<div class="d-flex mt-3 mb-1" style="justify-content: space-between">
				<a [routerLink]="Route.ForgotPassword" class="link-text-secondary">Mot de passe oublié ?</a>

				<a class="link-text-secondary" href="assets/CGU.pdf" style="font-size: 12px" target="_blank">
					Conditions générales d'utilisation
				</a>
			</div>
			<div class="d-flex gap-2">
				<button
					(click)="testCredentials()"
					[class]="{
						'btn-accent2': true,
						'btn-loading': this.loading,
					}"
					mat-flat-button
					class="w-75"
					type="submit"
				>
					Se connecter
				</button>
				<button [routerLink]="Route.Register" class="btn-primary w-25" style="justify-content: center">
					Créer un compte
				</button>
			</div>
		</div>
	</div>
</app-welcome>
