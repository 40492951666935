<div class="split">
	<div class="left"></div>
	<div class="right" style="overflow: auto">
		<div class="logo mt-2 px-5">
			<img [src]="LOGO_URL" />
		</div>
		<ng-container
			*ngIf="!done && !loading && (organizedAgreement['PCE'].length > 0 || organizedAgreement['PRM'].length > 0)"
		>
			<div class="px-5 pb-5">
				<div>
					<p>
						Nous avons besoin de votre autorisation pour accéder aux données de consommations électriques et gaz de(s)
						bâtiment(s) que vous gérez.
					</p>
					<p>
						L'historique de consommations pourra être obtenu sous réserve de sa mise à disposition par les
						distributeurs.
					</p>
					<div class="message-warning mb-2 d-flex w-100 gap-2" style="align-items: center">
						<mat-icon style="min-width: 24px">warning</mat-icon>
						Afin d’obtenir les données de consommations de vos compteurs gaz, il sera également nécessaire de valider
						votre accord auprès de GRDF. Une fois l’accord donné à Trace Software, vous recevrez prochainement un mail
						de la part de GRDF afin de confirmer cette démarche.
					</div>
				</div>
				<div class="d-flex gap-2">
					<button class="btn-primary-light" (click)="selectAll()">Tout sélectionner</button>
					<button class="btn-primary" (click)="sendAgreements()">Accepter les éléments sélectionnés</button>
				</div>
				<span class="danger" *ngIf="noneSelected"
					>Aucun accord n'à été sélectionné. Si vous souhaitez ne donner aucun consentement, fermez simplement cette
					page.</span
				>
				<div class="d-flex gap-3">
					<div class="w-50" *ngIf="organizedAgreement['PRM'].length > 0">
						<h3 class="mb-0">Accords Enedis</h3>
						<div class="d-flex-col gap-3">
							<div *ngFor="let prm of organizedAgreement['PRM']">
								<div class="d-flex" style="flex-direction: column; margin-left: 10px">
									<fieldset class="b-1-accent box">
										<legend class="box-title" style="align-items: center">
											<b class="d-flex">
												<mat-icon>bolt</mat-icon>
												{{ prm.agreement.reference }}</b
											>
											<span class="text-gray" *ngIf="prm.entities.length === 1">
												&nbsp;- {{ prm.entities[0].address | addressToString }}
											</span>
											<span class="text-gray" *ngIf="prm.entities.length > 1" [matTooltip]="prm.entities | addressList">
												&nbsp;- {{ prm.entities.length }} établissements
											</span>
										</legend>
										<div class="d-flex-col">
											<mat-checkbox
												color="primary"
												name="daily_index_history_authorization"
												[(ngModel)]="prm.agreement.agreements['daily_index_history_authorization']"
											>
												J'autorise l'accès à l'historique des relevés d'index quotidiens, en kWh sur 3 ans, et la
												puissance maximale quotidienne, en kVA ou kW, du site (mois).
											</mat-checkbox>
											<mat-checkbox
												color="primary"
												name="year_index_history_authorization"
												[(ngModel)]="prm.agreement.agreements['year_index_history_authorization']"
											>
												J'autorise l'accès à l'historique des relevés d'index quotidiens, en kWh sur 10 ans, et la
												puissance maximale quotidienne, en kVA ou kW, du site (année).
											</mat-checkbox>
											<mat-checkbox
												color="primary"
												name="contractual_technical_data_authorization"
												[(ngModel)]="prm.agreement.agreements['contractual_technical_data_authorization']"
											>
												J'autorise l'accès aux données techniques et contractuelles disponibles du site.
											</mat-checkbox>
											<mat-checkbox
												color="primary"
												name="charge_curve_history_authorization"
												[(ngModel)]="prm.agreement.agreements['charge_curve_history_authorization']"
											>
												J'autorise l'accès à l'historique de courbe de charge, au pas restitué par Enedis, du site.
												<span class="text-gray">(facultatif)</span>
											</mat-checkbox>
											<span
												*ngIf="prm.agreement.agreement_ask_id && errors[prm.agreement.agreement_ask_id]"
												class="danger"
											>
												{{ errors[prm.agreement.agreement_ask_id] }}
											</span>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
					</div>
					<div class="w-50" *ngIf="organizedAgreement['PCE'].length > 0">
						<h3 class="mb-0">Accords GRDF</h3>
						<div class="d-flex-col gap-3">
							<div *ngFor="let pce of organizedAgreement['PCE']">
								<div class="d-flex" style="flex-direction: column; margin-left: 10px">
									<fieldset class="b-1-accent2 box">
										<legend class="box-title" style="align-items: center">
											<b class="d-flex">
												<mat-icon>local_fire_department</mat-icon>
												{{ pce.agreement.reference }}</b
											>
											<span class="text-gray" *ngIf="pce.entities.length === 1">
												&nbsp;- {{ pce.entities[0].name }} {{ pce.entities[0].address | addressToString }}
											</span>
											<span class="text-gray" *ngIf="pce.entities.length > 1" [matTooltip]="pce.entities | addressList">
												&nbsp;- {{ pce.entities.length }} établissements
											</span>
										</legend>
										<div class="d-flex-col">
											<mat-checkbox
												color="primary"
												name="historic_data"
												[(ngModel)]="pce.agreement.agreements['historic_data']"
											>
												J'autorise l'accès aux données historiques à partir du {{ fiveYearsAgo | date: "dd/MM/yyyy" }}.
											</mat-checkbox>
											<mat-checkbox
												color="primary"
												name="published_data"
												[(ngModel)]="pce.agreement.agreements['published_data']"
											>
												J'autorise l'accès aux données publiées.
											</mat-checkbox>
											<span
												*ngIf="pce.agreement.agreement_ask_id && errors[pce.agreement.agreement_ask_id]"
												class="danger"
											>
												{{ errors[pce.agreement.agreement_ask_id] }}
											</span>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center" style="padding-top: 50px">
					Les données ainsi collectées seront stockées pendant une durée d'un an sur nos serveurs hébergés en France.
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!done && organizedAgreement['PCE'].length === 0 && organizedAgreement['PRM'].length === 0">
			<div class="text-center">
				<h3>Vous n'avez aucune demande à valider.</h3>
			</div>
		</ng-container>
		<ng-container *ngIf="loading">
			<div class="ml-3 mt-3">
				<mat-spinner></mat-spinner>
			</div>
		</ng-container>
		<ng-container *ngIf="done">
			<div class="text-center">
				<h3>Merci d'avoir donné votre agrément</h3>
			</div>
		</ng-container>
	</div>
</div>
