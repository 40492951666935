import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk, cloneAgreementAsk } from "../models/agreementAsk";

@Pipe({
	name: "getPrm",
})
export class GetPrmPipe implements PipeTransform {
	transform(asks: Immutable<AgreementAsk[]> | AgreementAsk[], copy = true): Immutable<AgreementAsk[]> {
		const agreements = asks.filter((ask) => ask.type === "PRM");

		if (copy) {
			return agreements.map(cloneAgreementAsk);
		}
		return agreements;
	}
}
