import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Immutable } from "immer";
import { BaseComponent } from "../../../../models/base-component.directive";
import { Lazy } from "../../../../models/lazy";
import { Representative } from "../../../../models/representative";
import { RouteDealer } from "../../../../models/routes";
import { TokenType } from "../../../../models/TokenType";
import { AuthorizationViewLoaderService } from "../../../../services/authorization-view-loader.service";
import { PaymentService } from "../../../../services/payment.service";
import { RepresentativeStateService } from "../../../../services/representative-state.service";

@Component({
	selector: "app-buy-token-form",
	templateUrl: "./buy-token-form.component.html",
	styleUrls: ["./buy-token-form.component.scss"],
})
export class BuyTokenFormComponent extends BaseComponent implements AfterViewInit {
	@Input() registration: boolean | undefined = undefined;
	@Output() changeDrawerState = new EventEmitter<boolean>();
	authRepresentatives: Lazy<Representative>[] = [];
	success = false;
	tabIndex = 0;

	constructor(
		private authorizationViewLoaderService: AuthorizationViewLoaderService,
		private authorizationViewLoader: AuthorizationViewLoaderService,
		private activatedRoute: ActivatedRoute,
		private paymentService: PaymentService,
		private router: Router,
		private representativeState: RepresentativeStateService,
	) {
		super();
		this.sub(this.activatedRoute.queryParams, (params) => {
			if (params.redirect_status && params.redirect_status === "succeeded" && params.payment_intent_client_secret) {
				this.success = true;
				// setTimeout otherwise it doesn't work
				setTimeout(() => this.changeDrawerState.emit(true), 0);

				this.paymentService.validatePayment$(params.payment_intent_client_secret).subscribe();
			}
		});

		this.sub(this.authorizationViewLoaderService.feedAuthorizations$(), () => {
			this.authRepresentatives = this.authorizationViewLoaderService.authRepresentatives;
		});
	}

	redirectToMainResource() {
		const id = this.representativeState.getId();
		if (id) {
			this.router.navigate(RouteDealer.myDeclaration(id));
		} else {
			this.authorizationViewLoader.loadModal(true);
		}
	}

	/**
	 * Display or hide buying history
	 */
	triggerSelectStep(tab: number) {
		switch (tab) {
			case 0:
				this.changeDrawerState.emit(false);
				break;
			case 1:
			case 2:
				this.changeDrawerState.emit(true);
				break;
		}
	}

	switchTab(index: number) {
		this.tabIndex = index;
	}

	get functionRemainingInitialToken(): (representative: Immutable<Lazy<Representative>>) => string {
		return (representative: Immutable<Lazy<Representative>>) => {
			const remainingTokens = representative.tokens - representative.used_tokens;
			const usedTokens = representative.used_tokens;
			return `${remainingTokens} crédit${remainingTokens > 1 ? "s" : ""} initia${remainingTokens > 1 ? "ux" : "l"} restant${remainingTokens > 1 ? "s" : ""} / \
      ${usedTokens} crédit${usedTokens > 1 ? "s" : ""} initia${usedTokens > 1 ? "ux" : "l"} attribué${usedTokens > 1 ? "s" : ""}`;
		};
	}

	get functionRemainingAnnualToken(): (representative: Immutable<Lazy<Representative>>) => string {
		return (representative: Immutable<Lazy<Representative>>) => {
			const remainingTokens = representative.annual_tokens - representative.used_annual_tokens;
			const usedTokens = representative.used_annual_tokens;
			return `${remainingTokens} crédit${remainingTokens > 1 ? "s" : ""} annuel${remainingTokens > 1 ? "s" : ""} restant${remainingTokens > 1 ? "s" : ""} / \
      ${usedTokens} crédit${usedTokens > 1 ? "s" : ""} annuel${usedTokens > 1 ? "s" : ""} attribué${usedTokens > 1 ? "s" : ""}`;
		};
	}

	protected readonly TokenType = TokenType;
}
