<fieldset class="box b-1-{{ color }}">
	<legend class="box-title">
		<mat-icon
			><span class="material-symbols-outlined">{{ icon }}</span></mat-icon
		>
		{{ listTitle }}
	</legend>
	<div>
		<app-agreement
			*ngFor="let agreement of agreements; index as i"
			[agreement]="agreement"
			[color]="color"
			[canEdit]="canEdit"
			(editAction)="editAction.emit({ agreement: $event, index: i })"
			[showStatus]="showStatus"
			(goAssetDeclaration)="goAssetDeclaration.emit()"
			[canGoAssetDeclaration]="canGoAssetDeclaration"
			(resendAction)="resendAction.emit($event)"
			(renewalAction)="renewalAction.emit($event)"
		></app-agreement>
	</div>
	<button *ngIf="canAdd" class="btn-{{ color }} btn-rounded box-add-button" (click)="addAction.emit()">
		<mat-icon>add</mat-icon>
	</button>
</fieldset>
