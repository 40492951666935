import { Component } from "@angular/core";
import { Immutable } from "immer";
import { HelpSubject } from "src/app/components/help/help.component";
import { Modal } from "src/app/models/modal";

type Input = Immutable<{
	lotNumbers: string[];
}>;

type State = {
	lotNumbers: string[];
};

type Output = string[] | undefined;

@Component({
	selector: "app-lot-number-modal",
	templateUrl: "./lot-number-modal.component.html",
	styleUrls: ["./lot-number-modal.component.scss"],
})
export class LotNumberModalComponent extends Modal<Input, State, Output> {
	wantMultipleNumbers = false;
	inputBuffer = "";

	inputToState({ lotNumbers }: Input): State {
		return { lotNumbers: lotNumbers.length > 0 ? [...lotNumbers] : [""] };
	}

	addLotNumber(lotNumber: string): boolean {
		lotNumber = lotNumber.trim();
		const pattern = /^[a-zA-Z0-9 ]+$/;

		if (!pattern.test(lotNumber)) {
			this.snackBar.open("Seuls les chiffres et les lettres sont autorisés pour le numéro !", "OK", {
				verticalPosition: "top",
			});
			return false;
		}
		if (this.state.lotNumbers.find((inArray) => inArray === lotNumber) === undefined) {
			this.state.lotNumbers.push(lotNumber);
		}

		return true;
	}

	// This function is directly used inside the template
	// because I'm using ngModel and it's hard to use pipes in this case
	canAdd(): boolean {
		return (
			this.inputBuffer !== "" && this.state.lotNumbers.find((inArray) => inArray === this.inputBuffer) === undefined
		);
	}

	cancel() {
		this.close(undefined);
	}

	save() {
		const { lotNumbers } = this.state;

		this.close(lotNumbers.length === 1 && lotNumbers[0] === "" ? [] : lotNumbers);
	}

	delete(index: number) {
		this.state.lotNumbers.splice(index, 1);
	}

	HelpSubject = HelpSubject;
}
