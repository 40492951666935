import { Component } from "@angular/core";
import { Modal } from "../../../../../models/modal";

@Component({
	selector: "app-api-access-error-modal",
	templateUrl: "./api-access-error-modal.component.html",
	styleUrls: ["./api-access-error-modal.component.scss"],
})
export class ApiAccessErrorModalComponent extends Modal<undefined, undefined, undefined> {
	inputToState(): undefined {
		return undefined;
	}
}
