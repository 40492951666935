import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { SubCategory } from "../../assets/data/categories";
import { IMMUTABLE_CATEGORIES } from "../helpers/category-helpers";
import { unwrap } from "../helpers/unwrap";
import { SubCategoryCode } from "../models/asset";

@Pipe({
	name: "subCategoryFromCode",
})
export class SubCategoryFromCodePipe implements PipeTransform {
	transform(code: SubCategoryCode): Immutable<SubCategory> {
		return subCategoryFromCode(code);
	}
}

export function subCategoryFromCode(code: SubCategoryCode): Immutable<SubCategory> {
	return unwrap(
		IMMUTABLE_CATEGORIES.map((c) => c.subCategories)
			.flat()
			.find((c) => c.code === code),
	);
}
