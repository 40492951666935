import { Pipe, PipeTransform } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { Immutable } from "immer";
import { ConsoState } from "../components/navigation/stepper/csv/operat-api-interactions/operat-api-interactions.component";
import { Nullable } from "../helpers/nullable";
import { Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../models/ids";
import { EntitiesNotConcernedByYearPipe } from "./entities-not-concerned-by-year.pipe";

export interface ConsoCsvFile {
	file: SafeUrl;
	filename: string;
	ids: FunctionalDeclarationId[];
}

@Pipe({
	name: "getConsoStateForCsv",
})
export class GetConsoStateForCsvPipe implements PipeTransform {
	transform(
		functionalDeclarations: Immutable<FunctionalDeclaration[]>,
		numberOfLockedEntities: number,
		csv: Nullable<ConsoCsvFile>,
		year: number = Declaration.ANNUAL_DECLARATION_STARTING_YEAR - 1,
	): ConsoState {
		const entitiesNotConcernedByYearPipe = new EntitiesNotConcernedByYearPipe();
		const entitiesNotConcernedByYear = entitiesNotConcernedByYearPipe.transform(functionalDeclarations, year);

		// If csv empty
		if (!csv || csv.ids.length === 0) {
			// If there's some EFA selected
			if (functionalDeclarations.length > 0) {
				// If they're all locked
				if (numberOfLockedEntities === functionalDeclarations.length) {
					return "LOCKED";
					// else if they're all liable after the year
				} else if (entitiesNotConcernedByYear === functionalDeclarations.length) {
					return "NO DECLARATION";
				}
			}
			// otherwise, conso empty
			return "EMPTY";
		}

		// If csv isn´t empty but not all EFA are available
		if (csv.ids.length > 0 && numberOfLockedEntities > 0) {
			return "PARTIAL";
		}

		return "COMPLETE";
	}
}
