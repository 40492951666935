import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EnergyConsumptions } from "../../../../../../../../models/functionalDeclaration";

@Component({
	selector: "app-edit-umpteenth-modal",
	templateUrl: "./edit-umpteenth-modal.component.html",
})
export class EditUmpteenthModalComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public row: EnergyConsumptions,
		private dialogRef: MatDialogRef<EditUmpteenthModalComponent>,
	) {}

	save() {
		this.row.values.forEach((value) => {
			value.partUmpteenth = this.row.partUmpteenth;
			value.totalUmpteenth = this.row.totalUmpteenth;
			value.hasRepartitionKey = true;
		});
		this.dialogRef.close(this.row);
	}
}
