import { Pipe, PipeTransform } from "@angular/core";
import { AddressInfo } from "../models/address";

const DOMTON_POSTAL_START_CODE = "97";

@Pipe({
	name: "isInDomTom",
})
export class IsInDomTomPipe implements PipeTransform {
	transform(address: AddressInfo): boolean {
		return address.postalCode.startsWith(DOMTON_POSTAL_START_CODE);
	}
}
