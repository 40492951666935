import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GreenKeys } from "@grs/greenkeys";
import { produce, castDraft, Immutable } from "immer";
import { EMPTY, of, ReplaySubject, zip } from "rxjs";
import { delay, first, map, switchMap } from "rxjs/operators";
import { getBestGoalReportFromFunctionalDeclaration } from "src/app/helpers/conso";
import { contains } from "src/app/helpers/string";
import { unwrap } from "src/app/helpers/unwrap";
import { AddressInfo } from "src/app/models/address";
import { BaseComponent } from "src/app/models/base-component.directive";
import { Declaration } from "src/app/models/declaration";
import { getIdentifierStringFromEfaStatus } from "src/app/models/efaStatus";
import { EntityInfo, FunctionalDeclaration, WRONG_ABSOLUTE_OBJECTIVE } from "src/app/models/functionalDeclaration";
import { FunctionalDeclarationId } from "src/app/models/ids";
import { allConsumptionsIncomplete } from "src/app/pipes/all-consumptions-incomplete.pipe";
import {
	AbsoluteObjectives,
	DeclarationFunctionalService,
	Djus,
} from "src/app/services/declaration-functional.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { DeclarationService } from "src/app/services/declaration.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { TourStateService } from "src/app/services/tour-state.service";
import { sequentialJoin } from "../../../../helpers/sequentialJoin";
import { Lazy } from "../../../../models/lazy";
import { BenchmarkModalComponent } from "../benchmark-modal/benchmark-modal.component";
import { DownloadAllCsvModalComponent } from "./download-all-csv-modal/download-all-csv-modal.component";
import { compareFunctionalDeclaration } from "src/app/helpers/sort-by-name";

@Component({
	selector: "app-reference-year-summary",
	templateUrl: "./reference-year-summary.component.html",
	styleUrls: ["./reference-year-summary.component.scss"],
})
export class ReferenceYearSummaryComponent extends BaseComponent implements OnDestroy {
	readonly AMOUNT_PER_PAGE = 10;

	page = 1;
	maxPage = 1;
	search = "";

	filteredDeclarationsFunctional: Immutable<FunctionalDeclaration[]> = [];
	declarationsFunctionalToShow: Immutable<FunctionalDeclaration[]> = [];

	// if someone has time, replace this with an Observable<Immutable<Declaration>>
	declaration$: ReplaySubject<Immutable<Declaration>> = new ReplaySubject(1);
	years = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019];

	currentCompleteYear = new Date().getFullYear() - 1;

	loading = false;

	hasWrongAbsoluteObjective = false;
	WRONG_ABSOLUTE_OBJECTIVE = WRONG_ABSOLUTE_OBJECTIVE;

	djus$: ReplaySubject<Djus> = new ReplaySubject<Djus>(1);
	absoluteObjectives$: ReplaySubject<AbsoluteObjectives> = new ReplaySubject<AbsoluteObjectives>(1);

	constructor(
		private declarationService: DeclarationService,
		public declarationState: DeclarationStateService,
		private declarationFunctionalService: DeclarationFunctionalService,
		functionalDeclarationState: FunctionalDeclarationStateService,
		private tourState: TourStateService,
		private dialog: MatDialog,
	) {
		super();
		tourState.initialize([
			{
				anchorId: "reference-year-summary-all",
				content:
					"Ce tableau centralise les années de référence et les objectifs de l'ensemble de vos entités fonctionnelles.",
				title: "Vos années de référence et vos objectifs",
			},
			{
				anchorId: "reference-year-summary-year",
				content:
					"Pour chaque entité fonctionnelle, vous retrouverez l'année de référence sélectionnée, pour laquelle les objectifs sont les plus simples à atteindre.",
				title: "Année de référence",
			},
			{
				anchorId: "reference-year-summary-relative",
				content:
					"L'objectif relatif est l'objectif à atteindre pour 2030. Il correspond à votre consommation de référence, moins 40%.",
				title: "Objectif relatif",
			},
			{
				anchorId: "reference-year-summary-absolute",
				content:
					"L'objectif absolu est un autre type d'objectif, calculé selon votre configuration et vos catégories d'activité tertiaires.",
				title: "Objectif absolu",
			},
			{
				anchorId: "reference-year-summary-row",
				content:
					"Pour chacune de vos entités fonctionnelles, vous pouvez accéder au détail des consommations par année en cliquant sur une ligne du tableau. Vous pourrez éventuellement y choisir une autre année de référence.",
				title: "Voir le détail",
			},
		]);

		functionalDeclarationState.clear();
		const ids$ = new ReplaySubject<FunctionalDeclarationId[]>(1);

		this.sub(
			declarationState.get$.pipe(
				switchMap((declaration) => (declaration ? of(declaration) : EMPTY)),
				switchMap(({ value: declaration }) =>
					declaration && declaration.declaration_id
						? this.declarationService.getNested$(declaration.declaration_id)
						: EMPTY,
				),
				map(Declaration.fromApi),
			),
			this.declaration$,
		);

		this.sub(
			this.declaration$.pipe(
				map((declaration) =>
					declaration.declarations_functional.flatMap(({ declaration_functional_id }) =>
						declaration_functional_id ? [declaration_functional_id] : [],
					),
				),
			),
			ids$,
		);

		this.sub(
			ids$.pipe(
				switchMap((ids) => {
					return this.declarationFunctionalService.getDjus$(ids);
				}),
			),
			this.djus$,
		);

		this.sub(
			ids$.pipe(
				switchMap((ids) => {
					return this.declarationFunctionalService.getAbsoluteObjectives$(ids);
				}),
			),
			this.absoluteObjectives$,
		);

		this.sub(this.declaration$, (declaration) => {
			this.updateSearch(declaration);

			this.recalculateObjectives(declaration, false);
		});
	}

	startTour() {
		this.tourState.startInitialized();
	}

	recalculateObjectives(declaration: Immutable<Declaration>, override: boolean) {
		this.loading = true;

		zip(this.djus$, this.absoluteObjectives$)
			.pipe(
				first(),
				map(([djus, objectives]) => {
					return declaration.declarations_functional
						.map(removeYearReferenceFromFunctionalDeclarationWithNoCompleteYear)
						.map(({ functionalDeclaration, isModified, incomplete }) => {
							return isModified
								? { functionalDeclaration, isModified }
								: incomplete
									? { functionalDeclaration, isModified: false } // if incomplete and not modified then no need to upload
									: this.getFunctionalDeclarationWithObjectives(functionalDeclaration, djus, objectives, override);
						});
				}),
				switchMap((entities) => {
					// If no entity has been modified, don't update
					if (entities.map(({ isModified }) => !isModified).every(Boolean)) {
						return of(declaration);
					}
					return sequentialJoin(
						entities.map(({ functionalDeclaration, isModified }) =>
							isModified
								? this.declarationFunctionalService
										.update$(FunctionalDeclaration.toApi(functionalDeclaration))
										.pipe(map(FunctionalDeclaration.fromApi))
								: of(functionalDeclaration),
						),
					).pipe(
						map((entities) =>
							produce(declaration, (draft) => {
								draft.declarations_functional = castDraft(entities);
							}),
						),
						switchMap((newDecla) => this.declarationState.update$(newDecla).pipe(map(() => newDecla))),
					);
				}),
				delay(500), // delay, just to show the user that something happened
			)
			.subscribe((newDecla) => {
				this.loading = false;
				this.updateSearch(newDecla);
			});
	}

	/**
	 * Will use the DJUs and the objectives to update the functional declaration's objectives
	 * @returns The functional declaration and a boolean that says if it was modified (to know which functional declaration we have to upload)
	 */
	getFunctionalDeclarationWithObjectives(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		djus: Djus,
		absoluteObjectives: AbsoluteObjectives,
		override: boolean,
	): { functionalDeclaration: Immutable<FunctionalDeclaration>; isModified: boolean } {
		const functionalDeclarationId = unwrap(functionalDeclaration.declaration_functional_id);

		const report = getBestGoalReportFromFunctionalDeclaration(
			functionalDeclaration,
			djus[functionalDeclarationId] ?? {},
			absoluteObjectives[functionalDeclarationId] ?? {},
		);

		const info = functionalDeclaration.infos;
		if (!report || (info.referenceYear !== undefined && !override)) {
			return { functionalDeclaration, isModified: false };
		}

		if (EntityInfo.isReportDifferentFromObjectives(info, report)) {
			return {
				isModified: true,
				functionalDeclaration: produce(functionalDeclaration, (draft) => {
					draft.infos = castDraft({ ...info, ...report });
				}),
			};
		}

		return { functionalDeclaration, isModified: false };
	}

	setPage(page: number) {
		this.page = page;
		this.declarationsFunctionalToShow = this.filteredDeclarationsFunctional.slice(
			(page - 1) * this.AMOUNT_PER_PAGE,
			page * this.AMOUNT_PER_PAGE,
		);
	}

	updateSearch(declaration: Immutable<Declaration>) {
		const searchString = this.search.toLowerCase();
		this.filteredDeclarationsFunctional = [...declaration.declarations_functional].sort(compareFunctionalDeclaration);
		if (searchString.length > 0) {
			this.filteredDeclarationsFunctional = this.filteredDeclarationsFunctional.filter((declarationFunctional) => {
				const { name, efa_status } = declarationFunctional;
				const identifier = getIdentifierStringFromEfaStatus(efa_status);
				return (
					contains(name, searchString) ||
					contains(identifier, searchString) ||
					contains(AddressInfo.toString(declarationFunctional[GreenKeys.KEY_ADDRESS]), searchString)
				);
			});
		}
		this.maxPage = Math.ceil(this.filteredDeclarationsFunctional.length / this.AMOUNT_PER_PAGE);
		this.setPage(1);
	}

	openBenchmark(declaration: Immutable<Declaration>, djus: Djus) {
		this.dialog.open(BenchmarkModalComponent, {
			data: {
				declaration,
				djus,
			},
			height: "95%",
			panelClass: "p-0",
		});
	}

	exportAllCsv(lazyDeclaration: Immutable<Lazy<Declaration>>) {
		this.dialog.open(DownloadAllCsvModalComponent, {
			data: {
				declaration: lazyDeclaration,
				declarationService: this.declarationService,
				declarationFunctionalService: this.declarationFunctionalService,
			},
		});
	}
}

/**
 * Detect if the functional declaration's years are all incomplete. If so, it will delete the functional declaration's objectives.
 * @returns The functional declaration and a boolean that says if it was modified (to know which functional declaration we have to upload)
 */
function removeYearReferenceFromFunctionalDeclarationWithNoCompleteYear(
	functionalDeclaration: Immutable<FunctionalDeclaration>,
): {
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	isModified: boolean;
	incomplete: boolean;
} {
	if (allConsumptionsIncomplete(functionalDeclaration)) {
		const info = functionalDeclaration.infos;
		if (
			info.referenceYear === undefined &&
			info.absoluteObjective === undefined &&
			info.relativeObjective === undefined
		) {
			return { functionalDeclaration, incomplete: true, isModified: false };
		}
		return {
			functionalDeclaration: produce(functionalDeclaration, (draft) => {
				draft.infos.referenceYear = undefined;
				draft.infos.absoluteObjective = undefined;
				draft.infos.relativeObjective = undefined;
			}),
			isModified: true,
			incomplete: true,
		};
	}
	return { functionalDeclaration, isModified: false, incomplete: false };
}
