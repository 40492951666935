import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-optional-building-data-select",
	templateUrl: "./optional-building-data-select.component.html",
	styleUrls: ["./optional-building-data-select.component.scss"],
})
export class OptionalBuildingDataSelectComponent<T> {
	@Input() choices: T[] = [];
	@Input() choice: T | undefined;
	@Input() choiceToString: (choice: T) => string = () => "";
	@Input() label = "";
	@Output() choiceChange = new EventEmitter<T | undefined>();
}
