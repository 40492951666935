<div class="navigation" *ngIf="userState.user as user">
	<ng-container *ngIf="isolation.isolationInfo as isolation">
		<div class="sidebar" [class.shrink]="!isOpen" [@collapse]="collapseState">
			<div class="nav">
				<div class="size-icon" (click)="shrink()">
					<div class="size-icon-up"></div>
					<div class="size-icon-center">
						<mat-icon>chevron_left</mat-icon>
					</div>
					<div class="size-icon-down"></div>
				</div>
				<div class="logo">
					<img class="hide" id="nav-logo" [src]="LOGO_URL" />
				</div>
				<div class="d-flex" style="justify-content: center; width: 30px">
					<img class="small-logo show" [src]="LOGO_SMALL_URL" />
				</div>
				<div (click)="profileActive = !profileActive" class="profile hide" [class.active]="profileActive">
					<div class="d-flex w-100 pointer gap-2" style="align-items: center">
						<app-avatar style="width: 40px" [user]="user"></app-avatar>
						<div class="d-flex-col w-100">
							<div class="d-flex w-100 dropdown" style="justify-content: space-between">
								<ng-container *ngFor="let representative of [(representativeState.get$ | async)?.value]">
									<div class="d-flex-col">
										<p style="font-weight: bold" class="m-0">{{ user.first_name }} {{ user.name }}</p>
										<span *ngIf="representative">{{ representative.name }}</span>
									</div>
									<div class="d-flex-col ml-2" style="justify-content: center" *ngIf="representative">
										<span class="d-flex gap-1" style="align-items: center; justify-content: flex-end">
											{{ representative.tokens - representative.used_tokens }}
											<mat-icon class="accent2" style="font-size: 18px; width: 18px; height: 18px">toll</mat-icon>
										</span>
										<span class="d-flex gap-1" style="align-items: center; justify-content: flex-end">
											{{ representative.annual_tokens - representative.used_annual_tokens }}
											<mat-icon class="accent4" style="font-size: 18px; width: 18px; height: 18px">toll</mat-icon>
										</span>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<div class="ml-auto profile-expand-icon">
						<mat-icon>expand_more</mat-icon>
					</div>
					<div class="profile-menu">
						<div
							class="profile-menu-element"
							*ngIf="(representativeState.get$ | async)?.value as representative"
							(click)="goToBuyToken(representative.representative_id)"
						>
							<div class="d-flex-col w-100">
								<ng-container *ngFor="let remainingTokens of [representative.tokens - representative.used_tokens]">
									<div class="d-flex w-100" style="justify-content: flex-start">
										<mat-icon class="accent2 mr-2">toll</mat-icon>
										<span>
											{{ remainingTokens }} crédit{{ remainingTokens > 1 ? "s" : "" }} initia{{
												remainingTokens > 1 ? "ux" : "l"
											}}
										</span>
									</div>
								</ng-container>
								<ng-container
									*ngFor="
										let remainingAnnualTokens of [representative.annual_tokens - representative.used_annual_tokens]
									"
								>
									<div class="d-flex" style="justify-content: flex-start">
										<mat-icon class="accent4 mr-2">toll</mat-icon>
										{{ remainingAnnualTokens }} crédit{{ remainingAnnualTokens > 1 ? "s" : "" }} annuel{{
											remainingAnnualTokens > 1 ? "s" : ""
										}}
									</div>
								</ng-container>
							</div>
						</div>
						<div class="profile-menu-element" [routerLink]="Route.MyProfile">
							<mat-icon>person</mat-icon>
							Mon profil
						</div>
						<div class="profile-menu-element" (click)="changeAuthorization()">
							<mat-icon>menu</mat-icon>
							Liste de mes accès
						</div>
						<div class="profile-menu-element" routerLink="/logout">
							<mat-icon>logout</mat-icon>
							Se déconnecter
						</div>
					</div>
				</div>
				<div
					class="hide d-flex"
					style="
						padding-left: 20px;
						padding-right: 20px;
						padding-bottom: 10px;
						flex-direction: column;
						justify-content: space-between;
						flex-grow: 1;
					"
				>
					<div *ngIf="isolation.type !== ResourceType.FunctionalDeclaration">
						<button
							mat-button
							(click)="goToDashboard(isolation.id)"
							color="primary"
							class="btn-declaration primary btn-shadow"
							tourAnchor="navigation.dashboard"
						>
							<mat-icon>receipt_long</mat-icon>
							<span style="padding-right: 89px">Tableau de bord</span>
						</button>
					</div>

					<div style="flex-grow: 1; height: 0; margin-bottom: 10px">
						<ul style="height: 100%; padding-left: 20px">
							<ng-scrollbar visibility="hover" appearance="native">
								<ng-container *ngIf="isolation.type === ResourceType.Representative">
									<ng-container *ngFor="let group of groups$ | async">
										<ng-container
											*ngFor="
												let expand of [
													this.focusGroupId === group.declaration_group_id ||
														(groupState.get$ | async)?.value?.declaration_group_id === group.declaration_group_id,
												]
											"
										>
											<li
												*ngIf="group.declarations && group.declarations.length > 1"
												[class.active]="
													(groupState.get$ | async)?.value?.declaration_group_id === group.declaration_group_id
												"
												class="declaration-group"
											>
												<div
													(click)="selectFocus(group.declaration_group_id)"
													class="d-flex"
													style="justify-content: space-between"
												>
													<p style="margin: 0">{{ group.name }}</p>
													<mat-icon
														[style]="{ transform: expand ? 'rotate(0deg)' : 'rotate(180deg)' }"
														class="expand-icon"
														>expand_less
													</mat-icon>
												</div>
												<div
													[class.expanded]="expand"
													[id]="'declaration-group-' + group.declaration_group_id"
													class="expandable"
												>
													<ul class="expandable-content">
														<ng-container *ngFor="let declaration of group.declarations">
															<li
																(click)="selectDeclaration(declaration.declaration_id, group.declaration_group_id)"
																*ngIf="
																	declaration.declaration_id !== undefined &&
																	declaration.declaration_id !== null &&
																	group.declaration_group_id !== undefined
																"
																[class.active]="
																	declaration.declaration_id === (declarationState.get$ | async)?.value?.declaration_id
																"
															>
																{{ declaration.name }}
															</li>
														</ng-container>
													</ul>
												</div>
											</li>
										</ng-container>
										<li
											class="declaration-group"
											[class.active]="
												group.declaration_group_id === (declarationState.get$ | async)?.value?.declaration_group_id
											"
											*ngIf="
												group.declarations.length === 1 &&
												!group.is_archived &&
												group.declarations[0].declaration_id !== undefined &&
												group.declarations[0].declaration_id !== null &&
												group.declaration_group_id !== undefined
											"
											(click)="selectDeclaration(group.declarations[0].declaration_id, group.declaration_group_id)"
										>
											{{ group.name }}
										</li>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="isolation.type === ResourceType.DeclarationGroup">
									<ng-container *ngIf="(groupState.get$ | async)?.value as group">
										<ng-container *ngIf="!group.is_archived">
											<li
												class="declaration-group"
												[class.active]="
													(groupState.get$ | async)?.value?.declaration_group_id === group.declaration_group_id
												"
												*ngIf="group.declarations && group.declarations.length > 1"
											>
												<p style="margin-bottom: 5px">{{ group.name }}</p>
												<ul *ngFor="let d of group.declarations">
													<li
														*ngIf="
															d.declaration_id !== null &&
															d.declaration_id !== undefined &&
															group.declaration_group_id !== undefined
														"
														[class.active]="d.declaration_id === (declarationState.get$ | async)?.value?.declaration_id"
														(click)="selectDeclaration(d.declaration_id, group.declaration_group_id)"
													>
														{{ d.name }}
													</li>
												</ul>
											</li>
											<li
												class="declaration-group"
												[class.active]="
													group.declaration_group_id === (declarationState.get$ | async)?.value?.declaration_group_id
												"
												*ngIf="
													group.declarations &&
													group.declarations.length === 1 &&
													group.declarations[0].declaration_id !== undefined &&
													group.declarations[0].declaration_id !== null &&
													group.declaration_group_id !== undefined
												"
												(click)="selectDeclaration(group.declarations[0].declaration_id, group.declaration_group_id)"
											>
												{{ group.name }}
											</li>
										</ng-container>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="isolation.type === ResourceType.Declaration">
									<p
										class="declaration-group"
										[class.active]="true"
										*ngIf="(declarationState.get$ | async)?.value as declaration"
										(click)="selectDeclaration(isolation.id)"
									>
										{{ declaration.name }}
									</p>
								</ng-container>
							</ng-scrollbar>
						</ul>
					</div>
					<div class="sidebar-footer">
						<mat-divider class="divider-secondary"></mat-divider>

						<div>
							<ng-container *ngIf="(representativeState.get$ | async)?.value as representative">
								<div style="position: relative; margin-bottom: 8px" *ngIf="user.is_temporary === false">
									<div
										class="d-flex-col"
										style="position: absolute; width: 100%"
										[@newDeclaMenu]="newDeclaMenuState"
										tourAnchor="navigation.new_declaration"
									>
										<button (click)="goToNewDeclarationGroup()" class="btn-declaration btn-primary-light">
											<mat-icon>add_circle_outline</mat-icon>
											<span style="padding-left: 5px">Nouvelle déclaration</span>
										</button>
										<button
											class="btn-declaration btn-primary-light from-operat-button"
											(click)="importDeclarationGroup()"
										>
											<mat-icon>add_circle_outline</mat-icon>
											<span style="padding-left: 5px">Continuer depuis OPERAT</span>
										</button>
									</div>
									<button
										(click)="changeDeclarationMenuState()"
										style="z-index: 10; position: relative"
										class="btn-declaration btn-primary"
									>
										<mat-icon [@newDeclaIcon]="newDeclaMenuState">add_circle_outline</mat-icon>
										<span [@newDeclaText]="newDeclaMenuState" style="padding-left: 5px; padding-right: 74px"
											>Nouvelle déclaration</span
										>
									</button>
								</div>
								<button
									(click)="goToBuyToken(representative.representative_id)"
									class="btn-primary btn-declaration"
									tourAnchor="navigation.add_tokens"
									style="margin-bottom: 8px"
								>
									<mat-icon>shopping_cart</mat-icon>
									<span style="padding-left: 5px">Acheter</span>
								</button>
							</ng-container>

							<div class="buttons">
								<button class="btn-primary-light" routerLink="/logout">
									<mat-icon>logout</mat-icon>
									<span style="padding-left: 5px">Se déconnecter</span>
								</button>
								<a
									style="flex-grow: 1"
									class="btn-primary-light"
									href="https://decret-tertiaire.tawk.help/"
									target="_blank"
									rel="noopener noreferrer"
									tourAnchor="navigation.faq"
								>
									<mat-icon>help_center</mat-icon>
									<span style="padding-left: 5px">FAQ</span>
								</a>
							</div>
							<div class="d-flex-col">
								<a href="assets/CGU.pdf" target="_blank" class="link-text-secondary" style="font-size: 12px">
									Conditions générales d'utilisation
								</a>
								<a
									[routerLink]="'/' + Route.Changelog"
									class="link-text-secondary"
									style="font-size: 12px"
									*ngIf="version"
								>
									Version {{ version }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<router-outlet></router-outlet>
		</div>
	</ng-container>
	<ng-container *ngIf="canDisplayYearIndexWarning$ | async">
		<div class="p-0" id="yearIndexWarning">
			<div class="dialog-container d-flex justify-content-center" style="position: relative">
				<div class="dialog-header d-flex justify-content-center">
					<h2 class="primary mb-0">Enedis - Historique long</h2>
				</div>
				<div class="dialog-content">
					<div class="d-flex-col gap-2">
						<span>
							Le service de récupération d'historique long d'Enedis a été remis en service. Vous pourrez de nouveau
							accéder à vos historiques de consommations depuis 2010.
						</span>
						<span>Les demandes seront désormais traités quotidiennement à 14h00.</span>
					</div>
					<div class="d-flex" style="justify-content: flex-end">
						<div class="d-flex-col" style="justify-content: flex-end">
							<mat-checkbox #dontRemindMe>Ne plus afficher</mat-checkbox>
							<button
								style="width: fit-content"
								type="button"
								class="btn btn-primary ml-auto"
								(click)="closeYearIndexWarning(!dontRemindMe.checked)"
							>
								Fermer
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
