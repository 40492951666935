import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import * as immObject from "../helpers/immutable";
import { getEnergyCategoriesToAdd } from "../models/energyCategory";
import { EnergyType, energyTypeUnitToJoulePci } from "../models/energyType";
import { ConsumptionEntry, FunctionalDeclaration, isMultiOccupation } from "../models/functionalDeclaration";
import { DateToTimestampPipe } from "./date-to-timestamp.pipe";
import { DisplayableConsumptionEntryPipe } from "./displayable-consumption-entry.pipe";
import { EnergyTypeValueDisplayPipe } from "./energy-type-value-display.pipe";
import { PeriodRangePipe } from "./period-range.pipe";

@Pipe({
	name: "sortConsumptions",
})
export class SortConsumptionsPipe implements PipeTransform {
	transform = sortConsumptions;
}

export function sortConsumptions(
	declarationFunctional: Immutable<FunctionalDeclaration>,
	djus: Immutable<{ [year: string]: number }>,
	year: number,
	period: number,
):
	| {
			year: number;
			deduct: number;
			total: number;
			tertiaire: number;
			dju: number;
			absoluteCorrection: number | undefined;
			relative: number | undefined;
	  }
	| undefined {
	const displayableValuePipe = new DisplayableConsumptionEntryPipe();
	const dateToTimestampPipe = new DateToTimestampPipe();
	const periodRangePipe = new PeriodRangePipe();
	const energyTypeValueDisplayPipe = new EnergyTypeValueDisplayPipe();

	const periodStart = dateToTimestampPipe.transform(periodRangePipe.transform(year, period).start);
	const periodEnd = dateToTimestampPipe.transform(periodRangePipe.transform(year, period).end);

	let deductSum = 0;
	if (declarationFunctional.consumptions["deduct"]) {
		immObject.keys(declarationFunctional.consumptions["deduct"]).forEach((cat) => {
			immObject.keys(declarationFunctional.consumptions["deduct"][cat].values).forEach((energy) => {
				declarationFunctional.consumptions["deduct"][cat].values[energy].values.forEach((ce) => {
					const start = Math.max(periodStart, declarationFunctional.consumptions["deduct"][cat].values[energy].start);
					const end = Math.min(
						periodEnd,
						declarationFunctional.consumptions["deduct"][cat].values[energy].end ?? periodEnd,
					);
					const dce = start <= end ? displayableValuePipe.transform(ce, Number(start), end) : undefined;
					if (dce) {
						deductSum += energyTypeUnitToJoulePci(energy as EnergyType, ConsumptionEntry.getCorrectedValue(dce));
					}
				});
			});
		});
	}
	let totalSum = 0;
	getEnergyCategoriesToAdd(isMultiOccupation(declarationFunctional)).forEach((type) => {
		if (declarationFunctional.consumptions[type]) {
			immObject.keys(declarationFunctional.consumptions[type]).forEach((cat) => {
				immObject.keys(declarationFunctional.consumptions[type][cat].values).forEach((energy) => {
					declarationFunctional.consumptions[type][cat].values[energy].values.forEach((ce) => {
						const start = Math.max(periodStart, declarationFunctional.consumptions[type][cat].values[energy].start);
						const end = Math.min(
							periodEnd,
							declarationFunctional.consumptions[type][cat].values[energy].end ?? periodEnd,
						);
						const dce = start <= end ? displayableValuePipe.transform(ce, Number(start), end) : undefined;
						if (dce) {
							totalSum += energyTypeUnitToJoulePci(energy as EnergyType, ConsumptionEntry.getCorrectedValue(dce));
						}
					});
				});
			});
		}
	});
	if (totalSum !== 0) {
		const dju = (djus[year] ?? 0) * 1000;
		totalSum = energyTypeValueDisplayPipe.transform(totalSum, EnergyType.ELECTRICITY_KWH) * 1000;
		deductSum = energyTypeValueDisplayPipe.transform(deductSum, EnergyType.ELECTRICITY_KWH) * 1000;
		const yearData: {
			year: number;
			deduct: number;
			total: number;
			tertiaire: number;
			dju: number;
			absoluteCorrection: number | undefined;
			relative: number | undefined;
		} = {
			year,
			deduct: deductSum,
			total: totalSum,
			tertiaire: totalSum - deductSum + dju,
			dju,
			relative: undefined,
			absoluteCorrection: undefined,
		};
		return yearData;
	}
	return undefined;
}
