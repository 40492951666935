import { Pipe, PipeTransform } from "@angular/core";
import { Etablissement } from "../models/siren-api.types";

@Pipe({
	name: "etablissementHasTokenUsed",
})
export class EtablissementHasTokenUsedPipe implements PipeTransform {
	transform(etablissement: Etablissement): boolean {
		for (const functionalDeclaration of etablissement.functionalEntities) {
			if (functionalDeclaration.is_token_used) {
				return true;
			}
		}

		return false;
	}
}
