<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content d-flex gap-2 pt-0" style="flex-direction: column">
		<h2 class="primary">Nouvellement assujetti</h2>
		<div>
			Attention, sont considérés comme "nouvellement assujettis" les établissement qui sont rentrés plus tard dans le
			dispositif éco-énergie tertiaire (à partir de 2022).
		</div>
		<div>
			Si vous êtes simplement retardataires et que vous devez déclarer les années 2020/2021 ainsi qu'une année de
			référence, merci de ne pas choisir cette option.
		</div>
		<div>Voulez-vous vraiment créer cette {{ state.type }} en tant que nouvellement assujetti ?</div>
	</div>

	<div class="dialog-actions">
		<button class="btn-primary-reverse" type="submit" [mat-dialog-close]="undefined">Annuler</button>
		<button class="btn-danger-reverse ml-2" type="submit" [mat-dialog-close]="true">Confirmer</button>
	</div>
</div>
