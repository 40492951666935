import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { EMPTY, Observable, of, Subject, zip } from "rxjs";
import { first, map, mapTo, switchMap, tap } from "rxjs/operators";
import { cloneLazyDeclarationGroup, DeclarationGroup } from "../models/declarationGroup";
import { HasResourceFromOwner, Origin } from "../models/HasResourceFromOwner";
import { DeclarationGroupId, IdFromResource } from "../models/ids";
import { Lazy } from "../models/lazy";
import { cloneLazyRepresentative, Representative } from "../models/representative";
import { FeedableObservable, ResourceOwner } from "../models/ResourceOwner";
import { DeclarationGroupService } from "./declaration-group.service";

@Injectable({
	providedIn: "root",
})
export class RepresentativeStateService
	extends HasResourceFromOwner<Lazy<Representative>>
	implements ResourceOwner<Lazy<DeclarationGroup>>
{
	private cachedGroup?: { id: string; group: Immutable<Lazy<DeclarationGroup>> };

	constructor(private groupService: DeclarationGroupService) {
		super();
	}

	getIdFromResource(resource: Immutable<Lazy<Representative>>): IdFromResource<Lazy<Representative>> {
		return resource.representative_id;
	}

	getFeedableObservable(): FeedableObservable<Lazy<DeclarationGroup>> {
		const subject = new Subject<{ id: DeclarationGroupId; origin: Origin }>();
		return {
			next: (id, origin) => subject.next({ id, origin }),
			observable: subject.pipe(
				switchMap((idAndOrigin) => zip(of(idAndOrigin), this.get$)),
				switchMap(([idAndOrigin, self]) => (self ? of({ idAndOrigin, self: self.value }) : EMPTY)),
				map(({ idAndOrigin: { id, origin }, self }) => {
					if (this.cachedGroup?.id === id) {
						return { value: this.cachedGroup.group, origin };
					}
					const group = self.declaration_groups.find((group) => group.declaration_group_id === id);
					if (group) {
						this.cachedGroup = { id, group };
						return { value: group, origin };
					}

					return null;
				}),
			),
		};
	}

	clearCache() {
		this.cachedGroup = undefined;
	}

	update$(resource: Immutable<Lazy<Representative>>): Observable<unknown> {
		return super.update$(resource).pipe(tap(() => (this.cachedGroup = undefined)));
	}

	updateSync$(resource: Immutable<Lazy<Representative>>): Observable<unknown> {
		return super.updateSync$(resource).pipe(tap(() => (this.cachedGroup = undefined)));
	}

	updateOwned$(group: Immutable<Lazy<DeclarationGroup>>) {
		return this.get$.pipe(
			first(),
			switchMap((self) => (self ? of(self) : EMPTY)),
			map((self) => {
				if (this.cachedGroup && group.declaration_group_id === this.cachedGroup.id) {
					this.cachedGroup = undefined;
				}
				const clone = cloneLazyRepresentative(self.value);
				const toModifyIndex = clone.declaration_groups.findIndex(
					(g) => g.declaration_group_id === group.declaration_group_id,
				);
				if (toModifyIndex === -1) {
					throw new Error("Declaration group not found when updating");
				}
				clone.declaration_groups[toModifyIndex] = cloneLazyDeclarationGroup(group);
				return clone;
			}),
			switchMap((clone) => this.update$(clone)),
		);
	}

	updateOwnedSync$(resource: Immutable<Lazy<DeclarationGroup>>): Observable<Immutable<Lazy<DeclarationGroup>>> {
		return this.get$.pipe(
			first(),
			switchMap((self) => (self ? of(self) : EMPTY)),
			switchMap((self) =>
				zip(
					of(self),
					this.groupService.save$(DeclarationGroup.toLazyApi(resource)).pipe(map(DeclarationGroup.fromLazyApi)),
				),
			),
			switchMap(([self, updated]) => {
				if (this.cachedGroup && updated.declaration_group_id === this.cachedGroup.id) {
					this.cachedGroup = undefined;
				}
				const selfClone = cloneLazyRepresentative(self.value);
				const indexToModify = selfClone.declaration_groups.findIndex(
					(group) => group.declaration_group_id === resource.declaration_group_id,
				);
				if (indexToModify === -1) {
					throw new Error("Declaration not found when updating");
				}

				selfClone.declaration_groups[indexToModify] = updated;
				return this.update$(selfClone).pipe(mapTo(updated));
			}),
		);
	}
}
