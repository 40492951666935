import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "categoryStart",
})
export class CategoryStartPipe implements PipeTransform {
	transform(categories: { start: number }[]): number {
		return categories.reduce((prev, curr) => (prev.start < curr.start ? prev : curr)).start;
	}
}
