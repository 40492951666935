import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class InitialLoadingService {
	public static subject = new BehaviorSubject(true);
	public static countSubject = new BehaviorSubject(0);
	private static count = 0;

	private static loading = false;

	static increment() {
		if (this.count === 0) {
			this.loading = true;
		}
		this.count++;

		this.countSubject.next(this.count);
	}

	static decrement() {
		this.count--;

		if (this.count === 0) {
			this.subject.next(false);
			this.loading = false;
		}
		this.countSubject.next(this.count);
	}

	static reset() {
		this.count = 0;
		this.subject.next(false);
		this.loading = false;
		this.countSubject.next(this.count);
	}

	static isLoading(): boolean {
		return this.loading;
	}
}
