<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content">
		<h2 class="mt-0 mb-0">Choix des déclarations à générer</h2>
		<ul style="list-style: none">
			<li class="mb-3 d-flex" style="align-items: center">
				<mat-checkbox
					[checked]="state.trueCount === state.items.length - state.notInCsvCount"
					(change)="setAll($event.checked)"
					[indeterminate]="state.trueCount !== 0 && state.trueCount < state.items.length - state.notInCsvCount"
					>Tout sélectionner
				</mat-checkbox>
				<div class="input-icon ml-auto">
					<input
						class="custom-input-light"
						style="min-width: 370px; display: block"
						placeholder="Rechercher une entité fonctionnelle"
						[(ngModel)]="searchQuery"
						(ngModelChange)="search($event)"
					/>
					<span class="input-suffix">
						{{ state.filterCount | async }}
						<mat-icon>domain</mat-icon>
					</span>
				</div>
			</li>
			<div style="min-width: 50em; height: 30em; overflow-y: scroll">
				<ng-container *ngFor="let item of state.items">
					<li *ngIf="state.filter.value.has(item.id)" class="d-flex gap-2" style="align-items: center">
						<mat-checkbox
							[disabled]="item.not_in_csv"
							[checked]="item.chosen"
							(change)="item.chosen = !item.chosen; updateTrueCount()"
						>
							<b>{{ item.name }}</b> - {{ item.address | addressToString }}
						</mat-checkbox>
						<b *ngIf="item.not_in_csv"
							>Entité exclue depuis
							<a [routerLink]="item.id | apply: RouteDealer.assetDeclaration" (click)="cancel()"
								>la déclaration patrimoniale</a
							></b
						>
					</li>
				</ng-container>
			</div>
		</ul>
	</div>

	<div class="dialog-actions d-flex gap-2">
		<button class="btn-stroked-text-gray" [mat-dialog-close]="undefined">Annuler</button>
		<button class="btn-stroked-primary" [disabled]="state.trueCount === 0" (click)="close()">Confirmer</button>
	</div>
</div>
