import { Component } from "@angular/core";
import { Immutable } from "immer";
import { EMPTY, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { AddressInfo } from "src/app/models/address";
import { Modal } from "src/app/models/modal";
import { StreetType } from "src/app/models/StreetType";
import { AddressSearchService } from "src/app/services/address-search.service";

type Input = { address: Immutable<AddressInfo>; addressService: AddressSearchService };
type State = {
	address: AddressInfo;
	addressService: AddressSearchService;
	searchSubject: Subject<string>;
	cityStream$: Observable<{ label: string; citycode: string; postcode: string }[]>;
	selectedCity: { label: string; citycode: string; postcode: string } | undefined;
};
type Output = AddressInfo;

@Component({
	selector: "app-edit-address-modal",
	templateUrl: "./edit-address-modal.component.html",
	styleUrls: ["./edit-address-modal.component.scss"],
})
export class EditAddressModalComponent extends Modal<Input, State, Output> {
	enumKeys = Object.keys(StreetType);

	inputToState({ address, addressService }: Input): State {
		const searchSubject = new Subject<string>();

		return {
			selectedCity: undefined,
			address: { ...address },
			addressService,
			searchSubject,
			cityStream$: searchSubject.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap((q) => (q ? of(q) : EMPTY)),
				switchMap((q) => addressService.getCities$(q)),
				catchError(() => of([])),
			),
		};
	}

	save() {
		if (!this.state.selectedCity || this.state.address.streetName === "") {
			return;
		}

		this.close({
			houseNumber: this.state.address.houseNumber,
			streetName: this.state.address.streetName,
			details: this.state.address.details,
			streetType: this.state.address.streetType,
			cityCode: this.state.selectedCity.citycode,
			cityName: this.state.selectedCity.label,
			postalCode: this.state.selectedCity.postcode,
		});
	}
}
