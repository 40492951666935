import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId, RepresentativeId } from "./ids";

export enum ResourceType {
	Representative = "representative",
	DeclarationGroup = "declaration_group",
	Declaration = "declaration",
	FunctionalDeclaration = "declaration_functional",
}

export type ResourceInfo =
	| { type: ResourceType.FunctionalDeclaration; id: FunctionalDeclarationId }
	| { type: ResourceType.Declaration; id: DeclarationId }
	| { type: ResourceType.DeclarationGroup; id: DeclarationGroupId }
	| { type: ResourceType.Representative; id: RepresentativeId };

export function getResourceTypeFromString(resource?: string): ResourceType | undefined {
	switch (resource) {
		case ResourceType.Representative:
			return ResourceType.Representative;
		case ResourceType.DeclarationGroup:
			return ResourceType.DeclarationGroup;
		case ResourceType.Declaration:
			return ResourceType.Declaration;
		case ResourceType.FunctionalDeclaration:
			return ResourceType.FunctionalDeclaration;
		default:
			return undefined;
	}
}
