import { Pipe, PipeTransform } from "@angular/core";
import { EnergyType } from "../models/energyType";

@Pipe({
	name: "energyTypeFromString",
})
export class EnergyTypeFromStringPipe implements PipeTransform {
	transform(value: string): EnergyType {
		return value as EnergyType;
	}
}
