<mat-dialog-content class="modal">
	<div style="margin-bottom: 15px">
		<h2 class="mt-0 mb-0">Importer un fichier CSV</h2>
	</div>
	<div class="d-flex" style="flex-direction: column">
		<ng-container>
			<p>Vous pouvez importer un fichier CSV dans la déclaration des consommations.</p>
			<p class="mt-0">
				Pour plus d'informations,
				<a class="text-secondary pointer" (click)="openImportTutorial()">cliquez ici</a>
			</p>
			<div class="d-flex" style="align-items: center">
				<input #inputFile type="file" name="files" multiple value="process" (change)="parseCSV(inputFile.files)" />
				<button mat-flat-button color="primary" style="margin-left: 10px" (click)="downloadFile()">
					Télécharger le fichier modèle
				</button>
			</div>
			<ng-container *ngIf="toAddValues.length > 0">
				<div>
					<table style="width: 100%">
						<thead>
							<tr>
								<th>Date de début</th>
								<th>Valeur</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let record of toAddValues" style="text-align: center">
								<td>{{ record.date | timestampToDate: "dd/MM/yyyy" }}</td>
								<td>{{ record.value | energyTypeValueDisplay: energy }} {{ energy | energyTypeUnit }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</ng-container>
		</ng-container>
	</div>
</mat-dialog-content>
<div>
	<button mat-button [matDialogClose]="null">Fermer</button>
	<button mat-flat-button *ngIf="toAddValues.length > 0" color="primary" (click)="saveCsvImport()">Ajouter</button>
</div>
