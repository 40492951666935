import { animate, state, style, transition, trigger } from "@angular/animations";
import { ComponentType } from "@angular/cdk/portal";
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GreenKeys as Keys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Observable, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { Nullable } from "src/app/helpers/nullable";
import { AddressInfo } from "src/app/models/address";
import { snackError } from "src/app/models/CustomError";
import { Declaration } from "src/app/models/declaration";
import {
	CaseRnaOrOtherStructure,
	CaseSirenStructure,
	cloneEfaStatus,
	CondominiumWithoutSiret,
	CondominiumWithSiret,
	EfaStatus,
	getSiretCompatibleWithStructureIfExists,
	OwnerOccupantWithoutSiret,
	OwnerOccupantWithSiret,
	OwnerWithoutSiret,
	OwnerWithSiret,
	TenantWithoutSiret,
	TenantWithSiret,
} from "src/app/models/efaStatus";
import { cloneLinkedEfa, FunctionalDeclaration, LinkedEFA } from "src/app/models/functionalDeclaration";
import {
	Condominium,
	CondominiumMarker,
	Identifier,
	MarkerToTuple,
	newIdOther,
	newIdRna,
	newIdSiren,
	newIdSiret,
	newRefEtat,
	newRefEtatLess,
	Owner,
	OwnerMarker,
	Siren,
	Siret,
	Tenant,
	TenantMarker,
	wrapCode,
} from "src/app/models/identifier";
import { Lazy } from "src/app/models/lazy";
import { dialogOpen, Modal } from "src/app/models/modal";
import { AddressSearchService } from "src/app/services/address-search.service";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";
import { CustomFormComponent } from "../custom-form/custom-form.component";
import { HelpSubject } from "../help/help.component";
import { IdentifierModalComponent } from "../identifier-modal/identifier-modal.component";
import { EditAddressModalComponent } from "../navigation/stepper/siren-form/edit-address-modal/edit-address-modal.component";
import { TODAY } from "../navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/add-row-modal/add-row-modal.component";
import { ConfirmNewlyLiableComponent } from "../navigation/user-info-form/confirm-newly-liable/confirm-newly-liable.component";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

type Input = {
	structure: Immutable<Declaration["structure"]>;
	addressService: AddressSearchService;
	entityAndCanDelete: { entity: Immutable<Lazy<FunctionalDeclaration>>; canDelete: boolean } | undefined;
	adding: boolean;
	operatReference: Nullable<string>;
};

type State = {
	addressService: AddressSearchService;
	structure: Immutable<Declaration["structure"]>;
	efaStatus: EfaStatus;
	searchSubject: Subject<string>;
	addressInfoStream$: Observable<AddressInfo[]>;
	name: string;
	canDelete: { isTokenUsed: boolean } | undefined;
	selectedAddress: AddressInfo | undefined;
	adding: boolean;
	mainSiret: Siret; // to prevent loosing the siret that is compatible with the structure when changing situation
	linkedEfas: Nullable<LinkedEFA>[];
	hasUsedToken: boolean;
	isImportedFromOperat: boolean;
	firstYearDeclaration: Nullable<number>;
	operatReference: Nullable<string>;
};

enum OutputType {
	Save,
	Delete,
}

type Output =
	| {
			type: OutputType.Save;
			efaStatus: EfaStatus;
			addressInfo: AddressInfo | undefined;
			name: string;
			linkedEfas: Nullable<LinkedEFA>[];
			firstYearDeclaration: Nullable<number>;
	  }
	| { type: OutputType.Delete }
	| undefined;

const CURRENT_YEAR = new Date().getFullYear();

@Component({
	selector: "app-add-functional-entity-modal",
	templateUrl: "./add-functional-entity-modal.component.html",
	styleUrls: ["./add-functional-entity-modal.component.scss"],
	animations: [
		trigger("collapse", [
			state("open", style({ height: "*", marginBottom: "8px" })),
			state("close", style({ height: 0 })),
			transition("open => close", [animate("200ms ease-in-out")]),
			transition("close => open", [animate("200ms ease-in-out")]),
		]),
	],
})
export class AddFunctionalEntityModalComponent extends Modal<Input, State, Output> {
	static readonly OutputType = OutputType;
	readonly TODAY = TODAY;
	readonly STATUS_TYPES = [
		Keys.KEY_OWNER_OCCUPANT,
		Keys.KEY_LANDLORD,
		"empty",
		Keys.KEY_TENANT,
		Keys.KEY_CONDOMINIUM,
	] as const;
	readonly STATUS_TYPES_FOR_OTHER = [Keys.KEY_LANDLORD, Keys.KEY_CONDOMINIUM] as const;

	readonly Keys = Keys;

	// User hasn't spent a token, or there is already a linkedEfa registered
	hasConfirmedAddEfa: boolean =
		(!this.state.hasUsedToken && !this.state.isImportedFromOperat) ||
		(!!this.state.linkedEfas[0] && (!!this.state.linkedEfas[0].refOperat || !!this.state.linkedEfas[0].surfaceEfa));
	linkedEfaIndex = Math.max(
		this.state.linkedEfas.filter((linkedEfa) => !!linkedEfa && !!linkedEfa.refOperat && !!linkedEfa.surfaceEfa).length,
		1,
	);
	// Closed if first efa is empty
	collapseOpen =
		!!this.state.linkedEfas[0] && (!!this.state.linkedEfas[0].refOperat || !!this.state.linkedEfas[0].surfaceEfa);
	isNewlyLiable = !!this.state.firstYearDeclaration;

	@ViewChild("confirmAddLinkedEfa") confirmAddLinkedDialog!: TemplateRef<MatDialog>;
	@ViewChild("form") customForm!: CustomFormComponent;

	get needsAddress(): boolean {
		return needsAddress(this.state.efaStatus);
	}

	inputToState({
		structure,
		addressService,
		entityAndCanDelete: functionalDeclaration,
		adding,
		operatReference,
	}: Input): State {
		const searchSubject = new Subject<string>();
		const mainSiret = functionalDeclaration
			? getSiretCompatibleWithStructureIfExists(functionalDeclaration.entity.efa_status)
			: undefined;

		const linkedEfas = [
			cloneLinkedEfa(functionalDeclaration?.entity.linked_efa_1),
			cloneLinkedEfa(functionalDeclaration?.entity.linked_efa_2),
			cloneLinkedEfa(functionalDeclaration?.entity.linked_efa_3),
		];

		return {
			mainSiret: mainSiret ?? ("" as Siret),
			adding,
			addressService,
			structure,
			selectedAddress:
				functionalDeclaration && needsAddress(functionalDeclaration.entity.efa_status)
					? { ...functionalDeclaration.entity.address }
					: undefined,
			canDelete:
				functionalDeclaration && functionalDeclaration.canDelete
					? { isTokenUsed: functionalDeclaration.entity.is_token_used }
					: undefined,
			name: functionalDeclaration?.entity.name ?? "",
			efaStatus: functionalDeclaration
				? cloneEfaStatus(functionalDeclaration.entity.efa_status)
				: {
						...("siren" in structure
							? {
									type: Keys.KEY_SIREN,
									status: {
										type: Keys.KEY_OWNER_OCCUPANT,
										data: {
											without_siret: false,
											siret: wrapCode<Siret>(""),
											condominium: undefined,
										},
									},
								}
							: "rna" in structure
								? {
										type: Keys.KEY_RNA,
										status: { type: Keys.KEY_OWNER_OCCUPANT, condominium: undefined },
									}
								: {
										type: Keys.KEY_RNA,
										status: { type: Keys.KEY_LANDLORD, condominium: undefined, tenant: { siret: "" as Siret } },
									}),
						secondaryOwner: undefined,
					},
			searchSubject,
			addressInfoStream$: searchSubject.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap((q) => addressService.getAddressInfo$(q)),
				map((addresses) => addresses.filter(({ streetName }) => streetName !== "")),
				catchError((error: unknown) => {
					snackError(this.snackBar, error);

					throw error;
				}),
			),
			hasUsedToken: functionalDeclaration ? functionalDeclaration.entity.is_token_used : false,
			linkedEfas: linkedEfas,
			firstYearDeclaration: functionalDeclaration?.entity.first_year_declaration,
			isImportedFromOperat: functionalDeclaration ? functionalDeclaration.entity.is_imported_from_operat : false,
			operatReference,
		};
	}

	toggleSecondaryOwner() {
		this.state.efaStatus.secondaryOwner = this.state.efaStatus.secondaryOwner
			? undefined
			: { owner: { siren: wrapCode("") } };
	}

	changeStatusType(
		statusSiren: CaseSirenStructure,
		{
			value,
		}: {
			value: Keys.KEY_LANDLORD | Keys.KEY_OWNER_OCCUPANT | Keys.KEY_TENANT | Keys.KEY_CONDOMINIUM | "empty";
		},
	) {
		statusSiren.status =
			value === Keys.KEY_CONDOMINIUM
				? {
						type: Keys.KEY_CONDOMINIUM,
						data: {
							tenant_without_siret: false,
							owner: { siren: wrapCode<Siren>("") },
							tenant: undefined,
							siret: this.state.mainSiret,
						},
					}
				: value === Keys.KEY_LANDLORD
					? {
							type: Keys.KEY_LANDLORD,
							data: { tenant_without_siret: false, tenant: { siret: wrapCode<Siret>("") }, condominium: undefined },
						}
					: value === Keys.KEY_OWNER_OCCUPANT
						? {
								type: Keys.KEY_OWNER_OCCUPANT,
								data: { without_siret: false, condominium: undefined, siret: this.state.mainSiret },
							}
						: value === "empty"
							? {
									type: Keys.KEY_LANDLORD,
									data: { tenant_without_siret: false, condominium: undefined, tenant: undefined },
								}
							: {
									type: Keys.KEY_TENANT,
									data: {
										without_siret: false,
										condominium: undefined,
										siret: this.state.mainSiret,
										owner: { siren: wrapCode<Siren>("") },
									},
								};
	}

	changeStatusRnaOrOtherType(
		statusSiren: CaseRnaOrOtherStructure,
		{
			value,
		}: {
			value: Keys.KEY_LANDLORD | Keys.KEY_OWNER_OCCUPANT | Keys.KEY_TENANT | Keys.KEY_CONDOMINIUM | "empty";
		},
		structure: Immutable<Declaration["structure"]>,
	) {
		if ("id" in structure) {
			if (value === Keys.KEY_CONDOMINIUM) {
				statusSiren.status = {
					type: Keys.KEY_CONDOMINIUM,
					owner: { siren: "" as Siren },
					tenant: { siret: "" as Siret },
				};
				return;
			}
			if (value === Keys.KEY_LANDLORD) {
				statusSiren.status = {
					type: Keys.KEY_LANDLORD,
					condominium: undefined,
					tenant: { siret: "" as Siret },
				};
				return;
			}
			return;
		}
		statusSiren.status =
			value === Keys.KEY_CONDOMINIUM
				? {
						type: Keys.KEY_CONDOMINIUM,
						owner: { siren: wrapCode<Siren>("") },
						tenant: undefined,
					}
				: value === Keys.KEY_LANDLORD
					? {
							type: Keys.KEY_LANDLORD,
							tenant: { siret: "" as Siret },
							condominium: undefined,
						}
					: value === "empty"
						? {
								type: Keys.KEY_LANDLORD,
								tenant: undefined,
								condominium: undefined,
							}
						: value === Keys.KEY_OWNER_OCCUPANT
							? {
									type: Keys.KEY_OWNER_OCCUPANT,
									condominium: undefined,
								}
							: {
									type: Keys.KEY_TENANT,
									condominium: undefined,
									owner: { siren: wrapCode<Siren>("") },
								};
	}

	changeOwnerOccupantWithoutSiret(status: { data: OwnerOccupantWithSiret | OwnerOccupantWithoutSiret }) {
		status.data = status.data.without_siret
			? { without_siret: false, condominium: status.data.condominium, siret: wrapCode("") }
			: { without_siret: true, condominium: status.data.condominium, denomination: "" };
	}

	changeOwnerWithoutSiret(status: { data: OwnerWithSiret | OwnerWithoutSiret }) {
		status.data = status.data.tenant_without_siret
			? {
					tenant_without_siret: false,
					condominium: status.data.condominium,
					tenant: { siret: wrapCode<Siret>("") },
				}
			: {
					tenant_without_siret: true,
					condominium: status.data.condominium,
					denomination: "",
					tenant: { siren: wrapCode<Siren>("") },
				};
	}

	changeTenantWithoutSiret(status: { data: TenantWithSiret | TenantWithoutSiret }) {
		status.data = status.data.without_siret
			? {
					without_siret: false,
					condominium: status.data.condominium,
					owner: status.data.owner,
					siret: wrapCode<Siret>(""),
				}
			: {
					without_siret: true,
					condominium: status.data.condominium,
					owner: status.data.owner,
					denomination: "",
				};
	}

	changeCondominiumWithoutSiret(status: { data: CondominiumWithSiret | CondominiumWithoutSiret }) {
		status.data = status.data.tenant_without_siret
			? {
					tenant_without_siret: false,
					owner: status.data.owner,
					tenant: undefined,
					siret: status.data.siret,
				}
			: {
					tenant_without_siret: true,
					denomination: "",
					owner: status.data.owner,
					tenant: { siren: wrapCode<Siren>("") },
					siret: status.data.siret,
				};
	}

	toggleCondominium(hasCondominium: { condominium: Nullable<Condominium> }) {
		hasCondominium.condominium = hasCondominium.condominium ? undefined : { siret: wrapCode<Siret>("") };
	}

	editCondominium(hasCondominium: { condominium: Nullable<Condominium> }) {
		const myClass: ComponentType<IdentifierModalComponent<CondominiumMarker>> = IdentifierModalComponent;

		const choices: MarkerToTuple<CondominiumMarker> = [newIdSiret(), newIdRna(), newRefEtat(), newIdOther()];

		let index: number;

		const { condominium } = hasCondominium;

		if (condominium) {
			if ("siret" in condominium) {
				index = 0;
				choices[0] = { ...condominium };
			} else if ("rna" in condominium) {
				index = 1;
				choices[1] = { ...condominium };
			} else if ("refEtat" in condominium) {
				index = 2;
				choices[2] = { ...condominium };
			} else {
				index = 3;
				choices[3] = { ...condominium };
			}
		} else {
			index = 0;
		}

		dialogOpen(this.dialog, myClass, { choices, choice: index }, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((condominium) => {
				if (condominium) {
					hasCondominium.condominium = condominium;
				}
			});
	}

	toggleTenant(hasTenant: { tenant: Nullable<Tenant> }) {
		hasTenant.tenant = hasTenant.tenant ? undefined : { siret: wrapCode<Siret>("") };
	}

	editTenant(hasTenant: { tenant: Nullable<Tenant> }) {
		const myClass: ComponentType<IdentifierModalComponent<TenantMarker>> = IdentifierModalComponent;

		const choices: MarkerToTuple<TenantMarker> = [newIdSiret(), newIdRna()];

		let index: number;

		const { tenant } = hasTenant;

		if (tenant) {
			if ("siret" in tenant) {
				index = 0;
				choices[0] = { ...tenant };
			} else {
				index = 1;
				choices[1] = { ...tenant };
			}
		} else {
			index = 0;
		}

		dialogOpen(this.dialog, myClass, { choices, choice: index }, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((tenant) => {
				if (tenant) {
					hasTenant.tenant = tenant;
				}
			});
	}

	editOwner(hasOwner: { owner: Owner } | { secondaryOwner?: EfaStatus["secondaryOwner"] }) {
		const myClass: ComponentType<IdentifierModalComponent<OwnerMarker>> = IdentifierModalComponent;

		const choices: MarkerToTuple<OwnerMarker> = [newIdSiren(), newIdRna(), newRefEtatLess(), newIdOther()];

		let index: number;

		const owner = "owner" in hasOwner ? hasOwner.owner : hasOwner.secondaryOwner?.owner;

		if (owner) {
			if ("siren" in owner) {
				index = 0;
				choices[0] = { ...owner };
			} else if ("rna" in owner) {
				index = 1;
				choices[1] = { ...owner };
			} else if ("refEtatLess" in owner) {
				index = 2;
				choices[2] = { ...owner };
			} else {
				index = 3;
				choices[3] = { ...owner };
			}
		} else {
			index = 0;
		}

		dialogOpen(this.dialog, myClass, { choices, choice: index }, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((owner) => {
				if (owner) {
					if ("owner" in hasOwner) {
						hasOwner.owner = owner;
					} else if (hasOwner.secondaryOwner) {
						hasOwner.secondaryOwner.owner = owner;
					} else {
						hasOwner.secondaryOwner = { owner };
					}
				}
			});
	}

	searchAddress(searchQuery: string) {
		if (searchQuery && searchQuery.length > 3) {
			this.state.searchSubject.next(searchQuery);
		}
	}

	selectAddress(info: AddressInfo) {
		this.state.selectedAddress = info;
	}

	exit() {
		this.close(undefined);
	}

	save() {
		const reason = this.isFormValid();
		if (reason === undefined) {
			const { efaStatus, name, linkedEfas } = this.state;
			if (this.state.hasUsedToken || !this.isNewlyLiable) {
				this.close({
					type: OutputType.Save,
					efaStatus,
					addressInfo: this.needsAddress ? this.state.selectedAddress : undefined,
					name,
					linkedEfas,
					firstYearDeclaration: undefined,
				});
				return;
			}

			dialogOpen(this.dialog, ConfirmNewlyLiableComponent, { type: "entité fonctionnelle" }, { panelClass: "p-0" })
				.afterClosed()
				.subscribe((confirm) => {
					if (confirm) {
						this.close({
							type: OutputType.Save,
							efaStatus,
							addressInfo: this.needsAddress ? this.state.selectedAddress : undefined,
							name,
							linkedEfas,
							firstYearDeclaration: this.isNewlyLiable ? this.state.firstYearDeclaration : undefined,
						});
					}
				});
		} else {
			this.snackBar.open(reason, "OK", { verticalPosition: "top" });
		}
	}

	delete() {
		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Suppression d'une entité fonctionnelle",
				description:
					"Vous êtes sur le point de supprimer une entité fonctionnelle. " +
					"Une fois supprimées les données liées à cette " +
					"entité fonctionnelle ne pourront pas être récupérées, " +
					"êtes-vous sûr de vouloir continuer ?",
				descriptionTwice: this.state.canDelete?.isTokenUsed
					? "Vous avez dépensé un crédit pour cette entité " +
						"et vous ne serez pas remboursé en cas de suppression. " +
						"Il est encore temps de changer d'avis."
					: undefined,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((wantToDelete) => {
				if (wantToDelete) {
					this.close({ type: OutputType.Delete });
				}
			});
	}

	isFormValid(): string | undefined {
		const { efaStatus, structure, selectedAddress, name } = this.state;

		const { secondaryOwner } = efaStatus;

		if (secondaryOwner && isIdentifierCompatibleWithStructure(secondaryOwner.owner, structure)) {
			return STRUCTURE_AND_SECONDARY_OWNER_SAME;
		}

		if (name === "") {
			return NAME_NOT_SET;
		}

		if (needsAddress(efaStatus) && selectedAddress === undefined) {
			return ADDRESS_NOT_SET;
		}

		if (efaStatus.type === Keys.KEY_SIREN) {
			const { status } = efaStatus;

			if (status.type === Keys.KEY_OWNER_OCCUPANT) {
				if (status.data.without_siret) {
					const {
						data: { denomination, condominium },
					} = status;

					if (condominium && !Identifier.isValid(condominium)) {
						return CONDOMINIUM_IDENTIFIER_NOT_VALID;
					}

					return denomination === "" ? DENOMINATION_NOT_SET : undefined;
				}

				const {
					data: { siret, condominium },
				} = status;

				if (condominium && !Identifier.isValid(condominium)) {
					return CONDOMINIUM_IDENTIFIER_NOT_VALID;
				}

				if (!Siret.isValid(siret)) {
					return SIRET_NOT_VALID;
				}

				return Siret.toSiren(siret) !== ("siren" in structure ? structure.siren : undefined)
					? SIRET_NOT_COMPATIBLE
					: undefined;
			}

			if (status.type === Keys.KEY_LANDLORD) {
				if (status.data.tenant_without_siret) {
					const {
						data: { denomination, tenant, condominium },
					} = status;
					if (!Siren.isValid(tenant.siren)) {
						return SIREN_NOT_VALID;
					}

					if (condominium && !Identifier.isValid(condominium)) {
						return CONDOMINIUM_IDENTIFIER_NOT_VALID;
					}

					if (denomination === "") {
						return DENOMINATION_NOT_SET;
					}

					return undefined;
				}

				const {
					data: { tenant, condominium },
				} = status;

				if (tenant && isIdentifierCompatibleWithStructure(tenant, structure)) {
					return STRUCTURE_AND_TENANT_COMPATIBLE;
				}

				if (condominium && !Identifier.isValid(condominium)) {
					return CONDOMINIUM_IDENTIFIER_NOT_VALID;
				}

				return tenant && !Identifier.isValid(tenant) ? TENANT_IDENTIFIER_NOT_VALID : undefined;
			}

			if (status.type === Keys.KEY_TENANT) {
				const { owner } = status.data;

				if (isIdentifierCompatibleWithStructure(owner, structure)) {
					return STRUCTURE_AND_OWNER_SAME;
				}

				if (secondaryOwner && Identifier.equals(owner, secondaryOwner.owner)) {
					return OWNER_AND_SECONDARY_OWNER_SAME;
				}

				if (status.data.without_siret) {
					const {
						data: { denomination, condominium },
					} = status;

					if (condominium && !Identifier.isValid(condominium)) {
						return CONDOMINIUM_IDENTIFIER_NOT_VALID;
					}

					if (!Identifier.isValid(owner)) {
						return OWNER_IDENTIFIER_NOT_VALID;
					}

					return denomination === "" ? DENOMINATION_NOT_SET : undefined;
				}

				const {
					data: { siret, condominium },
				} = status;

				if (condominium && !Identifier.isValid(condominium)) {
					return CONDOMINIUM_IDENTIFIER_NOT_VALID;
				}

				if (!Identifier.isValid(owner)) {
					return OWNER_IDENTIFIER_NOT_VALID;
				}

				if (!Siret.isValid(siret)) {
					return SIRET_NOT_VALID;
				}

				return Siret.toSiren(siret) !== ("siren" in structure ? structure.siren : undefined)
					? SIRET_NOT_COMPATIBLE
					: undefined;
			}

			const { owner } = status.data;

			if (secondaryOwner && Identifier.equals(owner, secondaryOwner.owner)) {
				return OWNER_AND_SECONDARY_OWNER_SAME;
			}

			if (status.data.tenant_without_siret) {
				const {
					data: { denomination, siret, tenant },
				} = status;

				if (!Identifier.isValid(owner)) {
					return OWNER_IDENTIFIER_NOT_VALID;
				}

				if (denomination === "") {
					return DENOMINATION_NOT_SET;
				}

				if (!Siren.isValid(tenant.siren)) {
					return TENANT_SIRET_NOT_VALID;
				}

				return Siret.toSiren(siret) !== ("siren" in structure ? structure.siren : undefined)
					? CONDOMINIUM_SIRET_NOT_COMPATIBLE
					: undefined;
			}

			const {
				data: { siret, tenant },
			} = status;

			if (!Identifier.isValid(owner)) {
				return OWNER_IDENTIFIER_NOT_VALID;
			}

			if (tenant && !Identifier.isValid(tenant)) {
				return TENANT_IDENTIFIER_NOT_VALID;
			}

			if (!Siret.isValid(siret)) {
				return CONDOMINIUM_SIRET_NOT_VALID;
			}

			return Siret.toSiren(siret) !== ("siren" in structure ? structure.siren : undefined)
				? CONDOMINIUM_SIRET_NOT_COMPATIBLE
				: undefined;
		}

		const { status } = efaStatus;

		if (status.type === Keys.KEY_OWNER_OCCUPANT) {
			const { condominium } = status;
			return condominium && !Identifier.isValid(condominium) ? CONDOMINIUM_IDENTIFIER_NOT_VALID : undefined;
		}

		if (status.type === Keys.KEY_LANDLORD) {
			const { condominium, tenant } = status;

			if (tenant && isIdentifierCompatibleWithStructure(tenant, structure)) {
				return STRUCTURE_AND_TENANT_COMPATIBLE;
			}

			if (condominium && !Identifier.isValid(condominium)) {
				return CONDOMINIUM_IDENTIFIER_NOT_VALID;
			}

			return tenant && !Identifier.isValid(tenant) ? TENANT_IDENTIFIER_NOT_VALID : undefined;
		}

		if (status.type === Keys.KEY_TENANT) {
			const { condominium, owner } = status;

			if (isIdentifierCompatibleWithStructure(owner, structure)) {
				return STRUCTURE_AND_OWNER_SAME;
			}

			if (!Identifier.isValid(owner)) {
				return OWNER_IDENTIFIER_NOT_VALID;
			}

			return condominium && !Identifier.isValid(condominium) ? CONDOMINIUM_IDENTIFIER_NOT_VALID : undefined;
		}

		const { owner, tenant } = status;

		if (!Identifier.isValid(owner)) {
			return OWNER_IDENTIFIER_NOT_VALID;
		}

		return tenant && !Identifier.isValid(tenant) ? TENANT_IDENTIFIER_NOT_VALID : undefined;
	}

	@ViewChild("form") form: CustomFormComponent | undefined;
	openAddressModal() {
		dialogOpen(
			this.dialog,
			EditAddressModalComponent,
			{
				address: new AddressInfo(),
				addressService: this.state.addressService,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((address) => {
				if (address) {
					this.state.selectedAddress = address;
				}
				this.form?.updateFormValidity();
			});
	}

	setMainSiret(siret: Siret) {
		this.state.mainSiret = siret;
	}

	addLinkedEfa() {
		if (this.linkedEfaIndex === this.state.linkedEfas.length) {
			return;
		}

		this.state.linkedEfas[this.linkedEfaIndex++] = { refOperat: null, surfaceEfa: null };
		this.customForm.updateFormValidity();
	}

	get collapseState() {
		return this.collapseOpen ? "open" : "close";
	}

	switchCollapseState() {
		if ((this.state.hasUsedToken || this.state.isImportedFromOperat) && !this.hasConfirmedAddEfa) {
			const dialog = this.dialog.open(this.confirmAddLinkedDialog, { width: "700px" });
			dialog.afterClosed().subscribe((value: boolean | undefined) => {
				if (value) {
					this.hasConfirmedAddEfa = true;
					this.collapseOpen = !this.collapseOpen;
				}
			});
		} else {
			this.collapseOpen = !this.collapseOpen;
		}
	}

	removeLinkedEfa(index: number) {
		this.state.linkedEfas.splice(index, 1);
		this.state.linkedEfas.push({ refOperat: null, surfaceEfa: null });
		this.linkedEfaIndex--;
		// We keep 1 empty by default
		if (this.linkedEfaIndex === 0) {
			this.addLinkedEfa();
		}
		this.customForm.updateFormValidity();
	}

	protected readonly HelpSubject = HelpSubject;
	protected readonly Declaration = Declaration;
	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
	protected readonly CURRENT_YEAR = CURRENT_YEAR;
}

export function needsAddress(efaStatus: Immutable<EfaStatus>): boolean {
	if (efaStatus.type === Keys.KEY_SIREN) {
		if (efaStatus.status.type === Keys.KEY_LANDLORD || efaStatus.status.type === Keys.KEY_CONDOMINIUM) {
			return efaStatus.status.data.tenant_without_siret || efaStatus.status.data.tenant == null;
		}

		return efaStatus.status.data.without_siret;
	}

	return (
		(efaStatus.status.type === Keys.KEY_LANDLORD || efaStatus.status.type === Keys.KEY_CONDOMINIUM) &&
		efaStatus.status.tenant == null
	);
}

const NAME_NOT_SET = "Nom de l'entité à renseigner";
const SIRET_NOT_VALID = "SIRET invalide";
const SIREN_NOT_VALID = "SIREN invalide";
const TENANT_SIRET_NOT_VALID = "SIREN de l'occupant invalide";
const CONDOMINIUM_SIRET_NOT_VALID = "SIRET du syndicat invalide";
const DENOMINATION_NOT_SET = "Dénomination à renseigner";
const ADDRESS_NOT_SET = "Adresse à renseigner";
const SIRET_NOT_COMPATIBLE =
	"Le SIRET n'est pas compatible avec le SIREN de la structure à partir de laquelle la déclaration a été créée. Le SIREN de rattachement de la déclaration peut-être édité uniquement lors de la création de la déclaration";
const CONDOMINIUM_IDENTIFIER_NOT_VALID = "L'identifiant du syndicat est invalide";
const TENANT_IDENTIFIER_NOT_VALID = "L'identifiant de l'occupant est invalide";
const OWNER_IDENTIFIER_NOT_VALID = "L'identifiant du propriétaire est invalide";
const CONDOMINIUM_SIRET_NOT_COMPATIBLE = "Le SIRET du syndicat est incompatible avec le SIREN de la structure";
const STRUCTURE_AND_SECONDARY_OWNER_SAME =
	"Le propriétaire secondaire ne peut pas correspondre à la structure déclarante";
const STRUCTURE_AND_TENANT_COMPATIBLE =
	"L'identifiant de l'occupant ne doit pas correspondre à celui de la structure déclarante";
const STRUCTURE_AND_OWNER_SAME = "Vous ne pouvez pas choisir la structure déclarante en tant que propriétaire";
const OWNER_AND_SECONDARY_OWNER_SAME = "Le propriétaire et le propriétaire secondaire ne peuvent pas être identiques";

/**
 * An identifier is compatible with the structure if:
 * - the structure contains the identifier
 * - the provided identifier is a SIRET and it is compatible with the structure's SIREN
 * @param identifier The identifier
 * @param structure The declaration's structure
 * @returns True if the identifer is compatible with the structure
 */
function isIdentifierCompatibleWithStructure(
	identifier: Immutable<Identifier>,
	structure: Immutable<Declaration["structure"]>,
): boolean {
	return (
		("siren" in structure &&
			(("siren" in identifier && identifier.siren === structure.siren) ||
				("siret" in identifier && Siret.toSiren(identifier.siret) === structure.siren) ||
				("rna" in identifier && identifier.rna === structure.maybeRna))) ||
		("rna" in structure && "rna" in identifier && structure.rna === identifier.rna) ||
		("id" in structure && "id" in identifier && structure.id === identifier.id && structure.label === identifier.label)
	);
}
