import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DeclarationGroup } from "../models/declarationGroup";
import { DeclarationGroupId, RepresentativeId } from "../models/ids";
import { Lazy } from "../models/lazy";
import { Representative, RepresentativeCounts } from "../models/representative";

@Injectable({
	providedIn: "root",
})
export class RepresentativeService {
	constructor(private http: HttpClient) {}

	get$(id: RepresentativeId, onlyEntity: boolean = false): Observable<Lazy<Representative.Api>> {
		const options = onlyEntity ? { params: { onlyEntity: onlyEntity } } : {};
		return this.http.get<Lazy<Representative.Api>>(`${environment.baseApiUrl}/representative/${id}`, options);
	}

	getCounts$(id: RepresentativeId): Observable<RepresentativeCounts> {
		return this.http.get<RepresentativeCounts>(`${environment.baseApiUrl}/representative/${id}/counts`);
	}

	getNavigation$(id: RepresentativeId): Observable<DeclarationGroup[]> {
		return this.http.get<DeclarationGroup[]>(`${environment.baseApiUrl}/representative/${id}/navigation`);
	}

	search$(id: RepresentativeId, search: string): Observable<DeclarationGroupId[]> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append("search", search);
		return this.http.get<DeclarationGroupId[]>(`${environment.baseApiUrl}/representative/${id}/search`, {
			params: httpParams,
		});
	}

	transferToken$(
		representativeId: RepresentativeId,
		declarationGroupId: DeclarationGroupId,
		tokens: number,
		annualTokens: number,
	): Observable<Lazy<Representative.Api>> {
		return this.http.put<Lazy<Representative.Api>>(
			`${environment.baseApiUrl}/representative/${representativeId}/transfer_token/${declarationGroupId}`,
			{
				tokens: tokens,
				annual_tokens: annualTokens,
			},
		);
	}

	save$(representative: Immutable<Lazy<Representative.Api>>): Observable<Lazy<Representative.Api>> {
		return this.http.put<Lazy<Representative.Api>>(
			`${environment.baseApiUrl}/representative/${representative.representative_id}`,
			representative,
		);
	}

	updateImage$(representativeId: RepresentativeId, imageType: string, image: File): Observable<void> {
		const data = new FormData();
		data.append("file", image);
		return this.http.post<void>(`${environment.baseApiUrl}/representative/${representativeId}/${imageType}`, data);
	}
}
