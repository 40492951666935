<div class="d-flex gap-3">
	<div class="w-100">
		<h3 class="primary-light mb-2">
			<ng-container *ngIf="quantity <= 10">
				{{ adding ? "Ajouter" : "Acheter" }}
				{{ quantity }}
				crédit{{ quantity > 1 ? "s" : "" }}
			</ng-container>
			<ng-container *ngIf="quantity > 10"> Vous souhaitez plus de 10 crédits ? Contactez-nous </ng-container>
		</h3>
		<input
			[(ngModel)]="quantity"
			(ngModelChange)="quantityChange.emit(quantity)"
			class="large-slider"
			[max]="maximum"
			min="1"
			step="1"
			type="range"
		/>
	</div>

	<div>
		<h3 class="primary-light mb-2" style="white-space: nowrap">
			<ng-container *ngIf="quantity <= 10"> {{ quantity * unitPrice }}€ HT </ng-container>
			<ng-container *ngIf="quantity > 10"> &nbsp; </ng-container>
		</h3>

		<ng-container *ngIf="quantity <= 10 && nextButton">
			<button (click)="continue.emit()" class="btn-primary" mat-flat-button>Continuer</button>
		</ng-container>
		<ng-container *ngIf="quantity > 10">
			<!--TODO URL-->
			<a class="btn-primary" href="https://www.greensystemes.com/contact/" mat-flat-button target="_blank">
				Nous contacter
			</a>
		</ng-container>
	</div>
</div>
