import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk } from "../models/agreementAsk";
import { CantGetAutomaticallyReasonPipe } from "./cant-get-automatically-reason.pipe";

@Pipe({
	name: "canGetAutomatically",
})
export class CanGetAutomaticallyPipe implements PipeTransform {
	transform(agreement: Immutable<AgreementAsk>): boolean {
		return new CantGetAutomaticallyReasonPipe().transform(agreement) === null;
	}
}
