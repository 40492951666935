import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Parcel } from "../models/Parcel";

@Pipe({
	name: "newParcelName",
})
export class NewParcelNamePipe implements PipeTransform {
	transform(parcel: Immutable<Parcel>): string {
		return `${parcel.commune} ${parcel.prefixe} ${parcel.section} ${parcel.numero}`;
	}
}
