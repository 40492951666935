<div class="pagination" *ngIf="maxPage > 1">
	<button mat-stroked-button [disabled]="currentPage === 1" (click)="pageChange.emit(currentPage - 1)">
		<mat-icon>navigate_before</mat-icon>
	</button>
	<ng-container *ngIf="currentPage > 2">
		<button mat-stroked-button (click)="pageChange.emit(1)">1</button>
		<mat-icon>more_horiz</mat-icon>
	</ng-container>

	<ng-container *ngFor="let i of [currentPage - 1, currentPage, currentPage + 1]">
		<button
			mat-stroked-button
			[class.btn-stroked-primary-light]="currentPage === i"
			*ngIf="0 < i && i <= maxPage"
			(click)="pageChange.emit(i)"
		>
			{{ i }}
		</button>
	</ng-container>

	<ng-container *ngIf="currentPage < maxPage - 1">
		<mat-icon>more_horiz</mat-icon>
		<button mat-stroked-button (click)="pageChange.emit(maxPage)">
			{{ maxPage }}
		</button>
	</ng-container>
	<button mat-stroked-button [disabled]="currentPage === maxPage" (click)="pageChange.emit(currentPage + 1)">
		<mat-icon>navigate_next</mat-icon>
	</button>
</div>
