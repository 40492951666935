import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FeatureCollection } from "geojson";
import { Immutable } from "immer";
import { FeatureIdentifier, LngLatLike, MapLayerMouseEvent } from "maplibre-gl";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { FeatureState, MyLayer } from "src/app/components/my-map/my-map.component";
import { BaseComponent } from "src/app/models/base-component.directive";
import { ParcelId } from "src/app/models/ids";
import { getParcelId, ParcelFeature, toParcelFeature } from "src/app/models/Parcel";
import { STYLE } from "../../qualification-form-map/building-map/style";
import { formatParcel } from "../../qualification-form-map/qualification-form-map.component";
import { LayerSpecification } from "@maplibre/maplibre-gl-style-spec";

@Component({
	selector: "app-asset-map",
	templateUrl: "./asset-map.component.html",
	styleUrls: ["./asset-map.component.scss"],
})
export class AssetMapComponent extends BaseComponent implements OnChanges {
	@Input() center: LngLatLike = [2.349014, 48.864716]; // Trace Software
	@Input() parcels: Immutable<(FeatureCollection | ParcelFeature)[]> = [];
	@Input() selectedParcelId: string | null = null;
	featureStates = new BehaviorSubject<FeatureState[]>([]);
	readonly LAYERS = LAYERS;
	readonly STYLE = STYLE;
	@Output() private selectParcelId = new EventEmitter<ParcelId>();
	private mainParcelSubject = new BehaviorSubject<{
		mainParcel: Immutable<ParcelFeature> | null;
		parcels: Immutable<ParcelFeature>[];
	}>({
		mainParcel: null,
		parcels: [],
	});
	private hoverSubject = new BehaviorSubject<Immutable<ParcelFeature> | null>(null);

	constructor() {
		super();

		const featureStates$ = combineLatest([this.mainParcelSubject, this.hoverSubject]).pipe(
			map(([{ mainParcel, parcels }, hoveredParcel]) => {
				const featureStates = parcels
					.filter((parcel) => parcel.id !== mainParcel?.id)
					.map((parcel) => {
						const featureIdentifier: FeatureIdentifier = {
							source: SOURCE,
							sourceLayer: SOURCE_LAYER,
							id: parcel.id,
						};

						const featureState: FeatureState = {
							feature: featureIdentifier,
							state: { selected: true },
						};

						return featureState;
					});

				if (mainParcel !== null) {
					const featureIdentifier: FeatureIdentifier = {
						source: SOURCE,
						sourceLayer: SOURCE_LAYER,
						id: mainParcel.id,
					};
					featureStates.push({ feature: featureIdentifier, state: { main: true } });
				}

				if (hoveredParcel !== null) {
					const featureIdentifier: FeatureIdentifier = {
						source: SOURCE,
						sourceLayer: SOURCE_LAYER,
						id: hoveredParcel.id,
					};
					featureStates.push({ feature: featureIdentifier, state: { hover: true } });
				}

				return featureStates;
			}),
		);

		this.sub(featureStates$, this.featureStates);
	}

	ngOnChanges() {
		const selectedParcel = this.parcels.find((parcel) => getParcelId(parcel) === this.selectedParcelId);

		const parcels: Immutable<ParcelFeature>[] = [];

		this.parcels.forEach((parcel) => {
			if (parcel.type === "Feature") {
				parcels.push(parcel);
			}
		});

		this.mainParcelSubject.next({
			mainParcel: selectedParcel !== undefined && selectedParcel.type === "Feature" ? selectedParcel : null,
			parcels,
		});
	}

	onParcelClick({ features }: MapLayerMouseEvent) {
		const [feature] = features ?? [];

		const nullableParcel = toParcelFeature(feature);

		if (nullableParcel === null) {
			return;
		}

		const parcelFeature = formatParcel(nullableParcel);

		const selectedParcel = this.parcels.find((parcel) => getParcelId(parcel) === getParcelId(parcelFeature));

		// If we didn't click on a parcel that is not outlined
		if (selectedParcel !== undefined) {
			this.selectParcelId.emit(getParcelId(parcelFeature));
		}
	}

	enableHover({ features }: MapLayerMouseEvent) {
		const [feature] = features ?? [];

		if (feature === undefined) {
			return;
		}

		const nullableParcel = toParcelFeature(feature);

		if (nullableParcel === null) {
			return;
		}

		const parcelFeature = formatParcel(nullableParcel);

		const hoveredParcel = this.parcels.find((parcel) => getParcelId(parcel) === getParcelId(parcelFeature));

		this.hoverSubject.next(hoveredParcel !== undefined && hoveredParcel.type === "Feature" ? hoveredParcel : null);
	}

	disableHover() {
		this.hoverSubject.next(null);
	}
}

const MAIN_COLOR = "#12a200";
const SELECTED_COLOR = "#75cbff";

const PAINT_PARCELS_LINE: LayerSpecification["paint"] = {
	// if selected then use SELECTED_COLOR, MAIN_COLOR else. If the parcel is not the main parcel
	// then it will be invisible so we don't care about its color
	"line-color": ["case", ["boolean", ["feature-state", "selected"], false], SELECTED_COLOR, MAIN_COLOR],
	"line-width": 4,
	// will check isSelected or isMain, if not then opacity 0
	"line-opacity": ["case", ["boolean", ["feature-state", "selected"], ["feature-state", "main"], false], 1, 0],
};

const PAINT_PARCELLES_FILL: LayerSpecification["paint"] = {
	"fill-color": MAIN_COLOR,
	"fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.5, 0],
};

const SOURCE = "cadastre";
const SOURCE_LAYER = "parcelles";

const LAYERS: MyLayer[] = [
	{
		id: "parcelles-line",
		source: SOURCE,
		"source-layer": SOURCE_LAYER,
		type: "line",
		paint: PAINT_PARCELS_LINE,
	},
	{
		id: "parcelles-fill",
		source: SOURCE,
		"source-layer": SOURCE_LAYER,
		type: "fill",
		paint: PAINT_PARCELLES_FILL,
	},
];
