import { Pipe, PipeTransform } from "@angular/core";
import { Identifier } from "../models/identifier";

@Pipe({
	name: "labelFromIdentifier",
})
export class LabelFromIdentifierPipe implements PipeTransform {
	transform(id: Identifier): string {
		return "siret" in id
			? "SIRET"
			: "siren" in id
				? "SIREN"
				: "rna" in id
					? "RNA"
					: "refEtat" in id || "refEtatLess" in id
						? "Référentiel de l'État"
						: "Autre";
	}
}
