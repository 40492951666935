import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "src/app/models/base-component.directive";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { wrapId } from "src/app/models/ids";
import { LOGO_URL } from "../../helpers/consts";
import { unwrap } from "../../helpers/unwrap";
import { AgreementAsk, cloneAgreementAsk } from "../../models/agreementAsk";
import { CanGetAutomaticallyPipe } from "../../pipes/can-get-automatically.pipe";
import { AgreementService } from "../../services/agreement.service";

@Component({
	selector: "app-agreements",
	templateUrl: "./agreements.component.html",
	styleUrls: ["./agreements.component.scss"],
})
export class AgreementsComponent extends BaseComponent {
	public error?: string;
	noneSelected: boolean | undefined = undefined;
	loading = false;
	done = false;

	fiveYearsAgo: Date;

	LOGO_URL = LOGO_URL;

	functionalDeclarations: FunctionalDeclaration[] = [];

	allAgreements: AgreementAsk[] = [];

	organizedAgreement: {
		[type: string]: {
			agreement: AgreementAsk;
			entities: FunctionalDeclaration[];
		}[];
	} = {
		PCE: [],
		PRM: [],
	};

	errors: { [agreementId: string]: string } = {};

	constructor(
		private activatedRoute: ActivatedRoute,
		private agreementSaver: AgreementService,
	) {
		super();
		this.fiveYearsAgo = new Date();
		this.fiveYearsAgo.setFullYear(this.fiveYearsAgo.getFullYear() - 5);
		this.sub(this.activatedRoute.paramMap, (params) => {
			const declarationId = params.get("declarationId");
			if (declarationId) {
				agreementSaver.getAgreements(wrapId(declarationId)).subscribe((declaration) => {
					this.functionalDeclarations = declaration.declarations_functional;
					this.functionalDeclarations.forEach((functionalDeclaration) => {
						functionalDeclaration.agreement_asks.map(cloneAgreementAsk).forEach((agreementAsk) => {
							this.allAgreements.push(agreementAsk);
							const existingAgreement = this.organizedAgreement[agreementAsk.type].find(
								(search) =>
									agreementAsk.reference === search.agreement.reference &&
									JSON.stringify(agreementAsk.agreements) === JSON.stringify(search.agreement.agreements),
							);
							if (existingAgreement) {
								if (
									!existingAgreement.entities.find(
										(entity) => entity.declaration_functional_id === functionalDeclaration.declaration_functional_id,
									)
								) {
									existingAgreement.entities.push(functionalDeclaration);
								}
							} else {
								switch (agreementAsk.type) {
									// Init agreements if doesn't exist
									case "PRM":
										agreementAsk.agreements = {
											...agreementAsk.agreements,
											daily_index_history_authorization:
												agreementAsk.agreements["daily_index_history_authorization"] ?? false,
											contractual_technical_data_authorization:
												agreementAsk.agreements["contractual_technical_data_authorization"] ?? false,
											charge_curve_history_authorization:
												agreementAsk.agreements["charge_curve_history_authorization"] ?? false,
											year_index_history_authorization:
												agreementAsk.agreements["year_index_history_authorization"] ?? false,
										};
										break;
									case "PCE":
										agreementAsk.agreements = {
											...agreementAsk.agreements,
											historic_data: agreementAsk.agreements["historic_data"] ?? false,
											published_data: agreementAsk.agreements["published_data"] ?? false,
										};
										break;
								}
								this.organizedAgreement[agreementAsk.type].push({
									agreement: agreementAsk,
									entities: [functionalDeclaration],
								});
							}
						});
					});
				});
			}
		});
	}

	public sendAgreements() {
		const canGetAutomaticallyPipe = new CanGetAutomaticallyPipe();
		const errors: { [agreementId: string]: string } = {};
		[...this.organizedAgreement["PCE"], ...this.organizedAgreement["PRM"]].forEach((entry) => {
			if (
				canGetAutomaticallyPipe.transform(entry.agreement) &&
				entry.agreement.agreements["historic_data"] !== entry.agreement.agreements["published_data"]
			) {
				errors[entry.agreement.agreement_ask_id ?? ""] =
					"Afin de pouvoir recevoir les données de consommations vous devez sélectionner l'accès aux données historiques et publiées.";
			}
		});
		this.errors = errors;
		if (Object.keys(this.errors).length === 0) {
			const agreements: { [id: string]: { [agreement_key: string]: boolean } } = {};
			this.noneSelected = true;
			this.allAgreements.forEach((agreement) => {
				agreement.agreements =
					this.organizedAgreement[agreement.type].find((entry) => entry.agreement.reference === agreement.reference)
						?.agreement.agreements ?? {};
				agreements[unwrap(agreement.agreement_ask_id)] = agreement.agreements;
				Object.values(agreement.agreements).forEach((val) => {
					if (val) {
						this.noneSelected = false;
					}
				});
			});
			if (!this.noneSelected) {
				this.loading = true;
				this.agreementSaver.sendAgreements(agreements).subscribe(() => {
					this.loading = false;
					this.done = true;
				});
			}
		} else {
			document
				.getElementById("agreement_" + Object.keys(this.errors)[0])
				?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		}
	}

	public selectAll() {
		this.organizedAgreement["PRM"].forEach((entry) => {
			entry.agreement.agreements = {
				daily_index_history_authorization: true,
				contractual_technical_data_authorization: true,
				charge_curve_history_authorization: true,
				year_index_history_authorization: true,
			};
		});
		this.organizedAgreement["PCE"].forEach((entry) => {
			entry.agreement.agreements = {
				historic_data: true,
				published_data: true,
			};
		});
	}
}
