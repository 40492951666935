<div class="dialog-container" *ngIf="declarationGroup && representative">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h2 class="mt-0 mb-0">Transférer des crédits à {{ declarationGroup.name }}</h2>
		<a href="javascript:void(0);" class="italic text-secondary" (click)="openTokenExplanationModal()"
			>Pourquoi ai-je deux soldes différents ?</a
		>
		<div class="d-flex-col" style="justify-content: center">
			<div class="dialog-small-text token-type" id="initialTokens">
				<span class="title">Crédits initiaux</span>
				<span class="danger" *ngIf="showWarning"
					>Vous devez d'abord transférer des crédits initiaux avant de pouvoir les consommer.</span
				>
				<span *ngIf="representativeTokensRemaining < 0 || declarationGroupTokensRemaining < 0" class="danger">
					Le nombre de crédits initiaux restant ne peut pas être inférieur à 0.
				</span>
				<form>
					<div class="d-flex gap-2 mb-2">
						<div class="w-100">
							<h4 class="mb-0">{{ representative.name }}</h4>
							<div class="custom-input-group">
								<label>Crédits initiaux restants</label>
								<input
									[disabled]="true"
									min="0"
									class="custom-input"
									name="representative_used_tokens"
									[(ngModel)]="representativeTokensRemaining"
									(ngModelChange)="setTokenFromRepresentative()"
								/>
							</div>
						</div>
						<div class="w-100">
							<h4 class="mb-0">{{ declarationGroup.name }}</h4>
							<div class="custom-input-group">
								<label>Crédits initiaux restants</label>
								<input
									type="number"
									min="0"
									class="custom-input"
									name="declarationGroup_tokens"
									[(ngModel)]="declarationGroupTokensRemaining"
									(ngModelChange)="setTokenFromDeclarationGroup()"
								/>
							</div>
						</div>
					</div>
					<div class="w-100 mb-2">
						<div class="custom-input-group">
							<label>Crédits initiaux à transférer</label>
							<input
								type="number"
								class="custom-input"
								name="toTransfer"
								[(ngModel)]="tokenToTransfer"
								(ngModelChange)="setTokens()"
							/>
						</div>
					</div>
				</form>
			</div>
			<div class="dialog-small-text token-type" id="annualTokens">
				<span class="title">Crédits annuels</span>
				<span class="danger" *ngIf="showWarningAnnual"
					>Vous devez d'abord transférer des crédits annuels avant de pouvoir les consommer.</span
				>
				<span
					*ngIf="representativeAnnualTokensRemaining < 0 || declarationGroupAnnualTokensRemaining < 0"
					class="danger"
				>
					Le nombre de crédits annuels restant ne peut pas être inférieur à 0.
				</span>
				<form>
					<div class="d-flex gap-2 mb-2">
						<div class="w-100">
							<h4 class="mb-0">{{ representative.name }}</h4>
							<div class="custom-input-group">
								<label>Crédits annuels restants</label>
								<input
									[disabled]="true"
									min="0"
									class="custom-input"
									name="representative_used_tokens"
									[(ngModel)]="representativeAnnualTokensRemaining"
									(ngModelChange)="setAnnualTokenFromRepresentative()"
								/>
							</div>
						</div>
						<div class="w-100">
							<h4 class="mb-0">{{ declarationGroup.name }}</h4>
							<div class="custom-input-group">
								<label>Crédits annuels restants</label>
								<input
									type="number"
									min="0"
									class="custom-input"
									name="declarationGroup_tokens"
									[(ngModel)]="declarationGroupAnnualTokensRemaining"
									(ngModelChange)="setAnnualTokenFromDeclarationGroup()"
								/>
							</div>
						</div>
					</div>
					<div class="w-100 mb-2">
						<div class="custom-input-group">
							<label>Crédits annuels à transférer</label>
							<input
								type="number"
								class="custom-input"
								name="toTransfer"
								[(ngModel)]="annualTokenToTransfer"
								(ngModelChange)="setTokens()"
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray mr-auto" mat-dialog-close="">Fermer</button>
		<button
			(click)="transfer(representative.representative_id)"
			[disabled]="
				representativeAnnualTokensRemaining < 0 ||
				declarationGroupAnnualTokensRemaining < 0 ||
				representativeTokensRemaining < 0 ||
				declarationGroupTokensRemaining < 0
			"
			class="btn-stroked-primary"
		>
			Transférer
		</button>
	</div>
</div>

<ng-template #modalTokenExplanation>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h2 class="mt-0 mb-0 text-center">Pourquoi ai-je deux soldes différents ?</h2>
			<div class="d-flex" style="justify-content: space-between">
				<div class="dialog-small-text p-5">
					<h3 class="accent2 text-center">Crédits initiaux</h3>
					<p>
						Les crédits initiaux permettent d'effectuer la déclaration d'entrée dans le dispositif du décret
						tertiaire.<br />
						Ils permettent notamment :
					</p>
					<ul>
						<li>De déclarer votre patrimoine et votre activité,</li>
						<li>De collecter et de déclarer vos consommations 2020 et 2021,</li>
						<li>D'identifier et de déclarer votre année de référence.</li>
					</ul>
				</div>
				<div class="dialog-small-text p-5">
					<h3 class="accent4 text-center">Crédits annuels</h3>
					<p>
						Les crédits annuels permettent d'effectuer les déclarations annuelles, qui suivent la déclaration
						initiale.<br />
						Ils permettent :
					</p>
					<ul>
						<li>De déclarer les évolutions de votre patrimoine et de votre activité chaque année,</li>
						<li>De déclarer vos consommations annuelles,</li>
						<li>De suivre vos économies d'énergie annuelles.</li>
					</ul>
					<p>Un crédit annuel devra être consommé par EFA et par année déclarée après 2021.</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>
