import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import * as turf from "@turf/turf";
import { FeatureCollection, Position } from "geojson";
import { Immutable } from "immer";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { BaseComponent } from "src/app/models/base-component.directive";
import { BackgroundLayerSpecification, LayerSpecification, StyleSpecification } from "@maplibre/maplibre-gl-style-spec";
import { FeatureIdentifier, LngLatLike, Map, MapGeoJSONFeature, MapLayerMouseEvent } from "maplibre-gl";

export interface LayerEvent {
	layerId: string;
	event: MapLayerMouseEvent;
}

/**
 * Can change display properties of a feature (a shape) on the map.
 * Useful to do hover or outline effects.
 */
export interface FeatureState {
	feature: FeatureIdentifier | MapGeoJSONFeature;
	state: { [key: string]: unknown };
}

type Label = [string, Position];

export type MyLayer = Exclude<LayerSpecification, BackgroundLayerSpecification>;

@Component({
	selector: "app-my-map",
	templateUrl: "./my-map.component.html",
	styleUrls: ["./my-map.component.scss"],
})
export class MyMapComponent extends BaseComponent implements OnChanges {
	@Input() style?: StyleSpecification;
	@Input() center?: LngLatLike;
	@Input() layers: MyLayer[] = [];
	@Input() featureStates: FeatureState[] = [];
	@Input() labels: Immutable<Label[]> = [];
	labelsSource = new BehaviorSubject<FeatureCollection>(turf.featureCollection([]));
	readonly LABELS_LAYOUT: LayerSpecification["layout"] = { "text-field": ["get", "text"], "text-size": 20 };
	readonly LABELS_PAINT = {
		"text-color": "rgba(0, 0, 0, 1)",
		"text-halo-width": 2,
		"text-halo-color": "rgba(255, 255, 255, 1)",
	};
	private map?: Map;
	@Output() private layerClick = new EventEmitter<LayerEvent>();
	@Output() private layerMouseMove = new EventEmitter<LayerEvent>();
	@Output() private layerMouseLeave = new EventEmitter<LayerEvent>();
	@Output() private mapLoad = new EventEmitter<Map>();
	private labelsSubject = new BehaviorSubject<Immutable<Label[]>>([]);

	constructor() {
		super();

		this.sub(
			this.labelsSubject.pipe(
				map((labels) =>
					labels.map(([text, pos]) => turf.feature({ type: "Point", coordinates: pos as number[] }, { text })),
				),
				map((features) => turf.featureCollection(features) as FeatureCollection),
			),
			this.labelsSource,
		);
	}

	onFirstLoad(map: Map) {
		this.map = map;

		this.map.dragRotate.disable();
		this.featureStates.forEach((featureState) => map.setFeatureState(featureState.feature, featureState.state));

		this.labelsSubject.next(this.labels);
		this.mapLoad.emit(map);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const map = this.map;

		if (map === undefined || changes.featureStates === undefined) {
			return;
		}

		const { currentValue, previousValue } = changes.featureStates as {
			currentValue: FeatureState[];
			previousValue: FeatureState[] | undefined;
		};

		if (previousValue) {
			previousValue.forEach((featureState) => map.removeFeatureState(featureState.feature));
		}

		currentValue.forEach((featureState) => {
			map.setFeatureState(featureState.feature, featureState.state);
		});

		this.labelsSubject.next(this.labels);
	}

	onLayerClick(layerId: string, event: MapLayerMouseEvent) {
		this.layerClick.emit({ layerId, event });
	}

	onLayerMouseMove(layerId: string, event: MapLayerMouseEvent) {
		this.layerMouseMove.emit({ layerId, event });
	}

	onLayerMouseLeave(layerId: string, event: MapLayerMouseEvent) {
		this.layerMouseLeave.emit({ layerId, event });
	}
}
