import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FeatureCollection, Point, Position } from "geojson";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AddressInfo } from "../models/address";
import { StreetType, StreetTypeString } from "../models/StreetType";

const NOT_VALID_CITYCODES = ["2B", "2A"] as const; // the api doesn't like Corsica

@Injectable({
	providedIn: "root",
})
export class AddressSearchService {
	private cachedPositionByAddress: Record<string, Position> = {};

	constructor(private http: HttpClient) {}

	getAddressInfo$(searchQuery: string): Observable<AddressInfo[]> {
		return this.http.get<FeatureCollection>(environment.addressSearchApiUrl, { params: { q: searchQuery } }).pipe(
			map((collection) =>
				collection.features.map((feature) => {
					let street = feature.properties?.street;
					let streetType = "";
					if (feature.properties?.street) {
						const type = feature.properties.street.split(" ")[0];
						Object.keys(StreetType).forEach((st) => {
							if (StreetType[st as StreetTypeString].toUpperCase() === type.toUpperCase()) {
								streetType = StreetType[st as StreetTypeString];
							}
						});
						if (<StreetType>streetType !== "") {
							street = street.substr(streetType.length);
						}
					}

					return {
						label: feature.properties?.label ?? "",
						coordinates: (feature.geometry as Point).coordinates,
						cityCode: feature.properties?.citycode ?? "",
						cityName: feature.properties?.city ?? "",
						postalCode: feature.properties?.postcode ?? "",
						houseNumber: feature.properties?.housenumber ?? "",
						streetName: street ?? "",
						streetType: <StreetType>streetType,
						details: "",
					};
				}),
			),
		);
	}

	getCities$(searchQuery: string): Observable<
		{
			citycode: string;
			label: string;
			postcode: string;
		}[]
	> {
		return this.http
			.get<FeatureCollection>(environment.addressSearchApiUrl, { params: { q: searchQuery, type: "municipality" } })
			.pipe(
				map((collection) =>
					collection.features.map(({ properties }) => {
						return { label: properties?.label, citycode: properties?.citycode, postcode: properties?.postcode };
					}),
				),
			);
	}

	getOneAddressInfoRequest(searchQuery: string, citycode = ""): Observable<FeatureCollection> {
		citycode = citycode.toUpperCase();

		if (searchQuery.trim() === "" && citycode === "") {
			return of({ features: [], type: "FeatureCollection" });
		}

		const slice = citycode.slice(0, 2);

		for (const badSlice of NOT_VALID_CITYCODES) {
			if (badSlice === slice) {
				citycode = "";
				break;
			}
		}

		return this.http.get<FeatureCollection>(environment.addressSearchApiUrl, { params: { q: searchQuery, citycode } });
	}

	getPosition(searchQuery: string, city: string, citycode: string): Observable<Position> {
		citycode = citycode.toUpperCase();

		const cachedPosition = this.cachedPositionByAddress[searchQuery + city + citycode];

		if (cachedPosition) {
			return of(cachedPosition);
		}

		const defaultPoint: Point = { type: "Point", coordinates: [2.2945, 48.8584] };

		const slice = citycode.slice(0, 2);

		for (const badSlice of NOT_VALID_CITYCODES) {
			if (badSlice === slice) {
				citycode = "";
				break;
			}
		}

		return this.getOneAddressInfoRequest(searchQuery + " " + city, citycode).pipe(
			switchMap((collection) =>
				// if the API returns data
				collection.features.length > 0
					? of(collection)
					: this
							// then just returns the collection with the data
							.getOneAddressInfoRequest(city, citycode)
							.pipe(
								// else call the API with the city only
								switchMap(
									(collection) => (collection.features.length > 0 ? of(collection) : of(defaultPoint)), // if the API returns data // then just returns
									// the collection with the data // else call
									// the API with Paris
								),
							),
			),
			map((collection: FeatureCollection | Point) =>
				collection.type === "Point" ? collection.coordinates : (collection.features[0].geometry as Point).coordinates,
			),
			// We feed the cache with the newly fetched data
			tap((pos) => (this.cachedPositionByAddress[searchQuery + city + citycode] = pos)),
		);
	}
}
