<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content">
		<div *ngIf="!state.userKey" class="d-flex-col" style="justify-content: space-evenly">
			<h3>Veuillez renseigner votre clé utilisateur d'OPERAT</h3>
			<div class="custom-input-group">
				<div class="d-flex" style="text-align: center">
					<div class="d-flex-col" style="justify-content: center"><label>Clé utilisateur OPERAT</label></div>
					<app-help [subject]="HelpSubject.OperatUserKey"></app-help>
				</div>
				<input class="custom-input" [(ngModel)]="inputUserKey" />
				<span class="text-danger" *ngIf="errorUserKey"
					>La connexion par API est impossible. Merci de vérifier votre clé utilisateur.</span
				>
			</div>
		</div>
		<div *ngIf="state.userKey">
			<mat-spinner class="mx-auto" *ngIf="loading"></mat-spinner>
			<ng-container *ngIf="!loading">
				<div *ngIf="dataToImport && dataToImport.length > 0">
					<h3>Veuillez choisir les données à importer pour chaque années</h3>
					<div class="message-warning mt-1 mb-1">
						Pour les années choisies, vos catégories d'activité actuelles seront remplacées, mais vos données de
						consommations ne seront pas écrasées : des lignes de consommations seront ajoutées.
					</div>
					<table class="w-100" style="text-align: center; table-layout: fixed">
						<thead>
							<tr>
								<th>Année</th>
								<th>Catégories</th>
								<th>Consommations</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let apiData of dataToImport">
								<td>
									<div class="d-flex-col">
										<span [ngClass]="{ 'reference-year': apiData.year === referenceYear }">{{ apiData.year }}</span>
										<span *ngIf="apiData.year === referenceYear">(Année de référence)</span>
									</div>
								</td>
								<td><mat-checkbox [(ngModel)]="apiData.categories"></mat-checkbox></td>
								<td><mat-checkbox [(ngModel)]="apiData.consumptions"></mat-checkbox></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div *ngIf="dataToImport && dataToImport.length === 0">Aucune déclaration trouvée</div>
			</ng-container>
		</div>
	</div>
	<div class="d-flex gap-2 dialog-actions" style="justify-content: space-between">
		<button class="btn-text-gray-reverse" [mat-dialog-close]="undefined">Annuler</button>
		<button class="btn-primary-reverse" *ngIf="!state.userKey" (click)="checkUserKey()">Valider</button>
		<button class="btn-primary-reverse" *ngIf="state.userKey" (click)="close(dataToImport)">Valider</button>
	</div>
</div>
