<div class="d-flex-col pt-2 mb-2" style="justify-content: space-around">
	<div class="message-primary mt-2">
		Le Configurateur étant directement connecté à la plateforme OPERAT, cet onglet permet de téléverser directement vos
		déclarations sur la plateforme de l'ADEME, sans passer par un export/import de fichiers.
	</div>
	<div class="message-warning mt-2">
		Afin d'ouvrir la connexion avec OPERAT, il est nécessaire de renseigner Trace Software en tant que tiers sur le
		compte de votre structure sur la plateforme de l'ADEME. Pour cela, rendez-vous sur votre compte OPERAT, puis dans
		l'onglet "Paramétrage - Echanges par API". Cliquez ensuite sur "Ajouter un Tiers". Sélectionnez enfin Trace
		Software, une date de début/fin et confirmez.
	</div>
	<div class="message-danger mt-2" *ngIf="quotaLimitReached">
		<span>
			Nous sommes désolés, nous avons atteint notre limite de communication quotidienne avec OPERAT. En raison de cette
			limitation, nous ne sommes plus en mesures de transférer vos déclarations pour le moment. Merci de réessayer
			demain, ou bien d'utiliser la méthode d'import par fichiers CSV. <br />
			<a href="#" class="primary" (click)="openLimitedQuotaModal(); $event.preventDefault()">En savoir plus</a>
		</span>
	</div>
	<div class="d-flex mt-2" style="justify-content: space-between">
		<div *ngIf="declaration | apply: getFunctionalDeclarationsSelected as selectedFunctionalDeclarations">
			<div *ngFor="let year of years">
				<h4 class="mb-0">
					Consommations {{ year }}
					<ng-container
						*ngIf="
							declaration.declarations_functional
								| apply: getIncompleteFilterFunction(year) as incompletesFunctionalDeclarations
						"
					>
						<a
							*ngIf="
								incompletesFunctionalDeclarations.length > 0 &&
								incompletesFunctionalDeclarations.length >
									(incompletesFunctionalDeclarations | lockedFunctionalDeclarationsForYear: year).length
							"
							class="danger pointer"
							(click)="scrollTo(year)"
						>
							<mat-icon>error</mat-icon>
						</a>
					</ng-container>
					<app-help
						argument="{{ year }}"
						[subject]="HelpSubject.EntitiesDeclaredAfterYear"
						*ngIf="(selectedFunctionalDeclarations | entitiesNotConcernedByYear: year) > 0"
					></app-help>
				</h4>
				<ng-container [ngSwitch]="selectedFunctionalDeclarations | apply: getConsoStateForYear(year)">
					<div *ngSwitchCase="'COMPLETE'">
						<button
							[disabled]="quotaLimitReached"
							class="btn-stroked-primary badge-danger"
							(click)="uploadForYear(declaration.declaration_id, year)"
						>
							<span class="text-icon">
								<mat-icon>upload</mat-icon>
								Envoyer vers OPERAT
							</span>
						</button>
					</div>
					<div *ngSwitchCase="'PARTIAL'">
						<button
							[disabled]="quotaLimitReached"
							class="btn-stroked-warning badge-danger"
							(click)="openPartialUpload(selectedFunctionalDeclarations, year, declaration.declaration_id)"
						>
							<span class="text-icon">
								<mat-icon>upload</mat-icon>
								Envoyer vers OPERAT
							</span>
						</button>
					</div>
					<div *ngSwitchCase="'EMPTY'">
						Vos surfaces d'activités ou vos consommations sont incomplètes pour {{ year }}. <br />
						Merci de vérifier la complétude des données sur les entités et l'année concernées.
					</div>
					<div *ngSwitchCase="'NO DECLARATION'">
						Le fichier généré est vide. Aucune entité n'a accès aux déclarations de consommations de cette année
						(assujettissement ultérieur à {{ year }})
					</div>
					<div *ngSwitchCase="'LOCKED'">
						<a
							class="btn-stroked-text-gray badge-danger"
							style="position: relative; width: fit-content"
							(click)="openLockedModalFunction(year < ANNUAL_DECLARATION_STARTING_YEAR)"
						>
							<span class="text-icon">
								<mat-icon>upload</mat-icon>
								Envoyer vers OPERAT
							</span>
							<mat-icon class="lock-icon" color="warn">lock</mat-icon>
						</a>
					</div>
				</ng-container>
			</div>
			<div
				*ngIf="
					selectedFunctionalDeclarations.length >
					(selectedFunctionalDeclarations | entitiesNotConcernedByYear: ANNUAL_DECLARATION_STARTING_YEAR - 1)
				"
			>
				<h4 class="mb-0">
					Consommations de référence
					<a
						*ngIf="(declaration.declarations_functional | apply: getEntitiesWithoutReferenceYear).length > 0"
						class="danger pointer"
						(click)="scrollTo(2021)"
					>
						<mat-icon>error</mat-icon>
					</a>
				</h4>
				<ng-container [ngSwitch]="selectedFunctionalDeclarations | apply: getConsoStateForYear('REFERENCE')">
					<div *ngSwitchCase="'COMPLETE'">
						<button
							[disabled]="quotaLimitReached"
							class="btn-stroked-primary badge-danger"
							(click)="uploadForYear(declaration.declaration_id, 'REFERENCE')"
						>
							<span class="text-icon">
								<mat-icon>upload</mat-icon>
								Envoyer vers OPERAT
							</span>
						</button>
					</div>
					<div *ngSwitchCase="'PARTIAL'">
						<button
							[disabled]="quotaLimitReached"
							class="btn-stroked-warning badge-danger"
							(click)="openPartialUpload(selectedFunctionalDeclarations, null, declaration.declaration_id)"
						>
							<span class="text-icon">
								<mat-icon>upload</mat-icon>
								Envoyer vers OPERAT
							</span>
						</button>
					</div>
					<div *ngSwitchCase="'EMPTY'">
						Vos surfaces d'activités ou vos consommations sont incomplètes pour vos années de références. <br />
						Merci de vérifier la complétude des données sur les entités et l'année concernées.
					</div>
					<div *ngSwitchCase="'NO DECLARATION'"></div>
					<div *ngSwitchCase="'LOCKED'">
						<a
							class="btn-stroked-text-gray badge-danger"
							style="position: relative; width: fit-content"
							(click)="openLockedModalFunction()"
						>
							<span class="text-icon">
								<mat-icon>upload</mat-icon>
								Envoyer vers OPERAT
							</span>
							<mat-icon class="lock-icon" color="warn">lock</mat-icon>
						</a>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="custom-input-group">
			<!--prevent default, otherwise trigger app-help even if we don't click directly on it-->
			<label (click)="$event.preventDefault()"
				>Clé utilisateur <app-help [subject]="HelpSubject.OperatUserKey"></app-help
			></label>
			<div class="d-flex" *ngIf="isEditing">
				<input class="custom-input" style="width: 500px" type="text" [(ngModel)]="userKey" />
				<button
					class="btn-accent btn-squared ml-1 mt-auto mb-auto"
					[disabled]="userKey && userKey.length > 0 && !userKeyZod.safeParse(userKey.trim()).success"
					matTooltip="Valider"
					matTooltipPosition="above"
					(click)="saveUserKey()"
				>
					<mat-icon>done</mat-icon>
				</button>
				<button
					class="btn-danger btn-squared ml-1 mt-auto mb-auto"
					matTooltip="Annuler"
					matTooltipPosition="above"
					(click)="isEditing = false"
				>
					<mat-icon>cancel</mat-icon>
				</button>
			</div>
			<span class="input-error" *ngIf="isEditing && userKey && userKey.length > 0">{{
				userKey.trim() | zodSafeParse: userKeyZod
			}}</span>
			<ng-container *ngFor="let declarationGroup of [groupState.get$ | async]">
				<div *ngIf="!isEditing" class="d-flex">
					<input
						(click)="copyKeyToClipboard(declarationGroup?.value)"
						class="custom-input disabled"
						style="width: 500px"
						type="text"
						[ngModel]="censoredUserKey"
						readonly
					/>
					<!--Can only edit if access to declarationGroup or higher-->
					<ng-container *ngIf="declarationGroup">
						<button
							class="btn-primary btn-squared ml-1 mt-auto mb-auto"
							matTooltip="Éditer"
							matTooltipPosition="above"
							(click)="startEditing()"
						>
							<mat-icon>edit</mat-icon>
						</button>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #noKey>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="my-0">Clé utilisateur non renseignée</h3>
			<div class="dialog-small-text">
				<div class="d-flex-col gap-3">
					<div>La clé utilisateur est nécessaire pour pouvoir téléverser les données de consommation vers OPERAT.</div>
					<div>
						Celle-ci est accessible dans l'onglet "Mes données personnelles", accessible en haut à droite de la
						plateforme OPERAT.
					</div>
					<div>
						Votre clé utilisateur sera affichée une fois l'étape précédente réalisée. Veuillez la renseigner dans le
						champ 'Clé utilisateur' sur la droite.
					</div>
				</div>
			</div>
		</div>
		<div class="dialog-footer d-flex" style="justify-content: right">
			<button class="btn-stroked-text-gray" [mat-dialog-close]="undefined">Ok</button>
		</div>
	</div>
</ng-template>
