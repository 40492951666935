import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Identifier } from "src/app/models/identifier";

@Component({
	selector: "app-identifier-view",
	templateUrl: "./identifier-view.component.html",
	styleUrls: ["./identifier-view.component.scss"],
})
export class IdentifierViewComponent implements OnChanges {
	@Input() identifier!: Identifier;
	@Output() editClick = new EventEmitter<void>();
	@Output() valueChanged = new EventEmitter<void>();

	ngOnChanges() {
		this.valueChanged.emit();
	}
}
