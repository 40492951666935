export function getColorFromPercentage(
	percentage: number,
	color1: number[],
	color2: number[],
	color3?: number[],
): number[] {
	let useColor1 = color1;
	let useColor2 = color2;
	if (color3) {
		percentage *= 2;
		if (percentage >= 1) {
			percentage -= 1;
			useColor1 = color2;
			useColor2 = color3;
		}
	}
	return [
		Math.round(useColor1[0] * (1 - percentage) + useColor2[0] * percentage),
		Math.round(useColor1[1] * (1 - percentage) + useColor2[1] * percentage),
		Math.round(useColor1[2] * (1 - percentage) + useColor2[2] * percentage),
	];
}

export function componentToHex(c: number) {
	const hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
