import { Component } from "@angular/core";
import { Immutable } from "immer";
import { Nullable } from "../../../../helpers/nullable";
import { Siren } from "../../../../models/identifier";
import { Modal } from "../../../../models/modal";
import { InitialLoadingService } from "../../../../services/initial-loading.service";
import { ImportTypeEnum } from "../../user-info-form/operat-search-form/ImportTypeEnum";
import { OperatApiImportData } from "../../user-info-form/operat-search-form/operat-search-form.component";

export type CSVImportData = Immutable<{ csvFile: File; siren: Siren }>;

type Output = CSVImportData | OperatApiImportData | undefined;

@Component({
	selector: "app-import-declaration-modal",
	templateUrl: "./import-declaration-modal.component.html",
	styleUrls: ["./import-declaration-modal.component.scss"],
})
export class ImportDeclarationModalComponent extends Modal<undefined, undefined, Output> {
	initialLoading = InitialLoadingService;
	importIdentifier: Siren = "" as Siren;
	csvFile: Nullable<File>;
	importTabIndex = 0;
	apiImportData: OperatApiImportData = { references: [], userKey: "", structId: "" };

	inputToState(): undefined {
		return undefined;
	}

	protected readonly Siren = Siren;
	protected readonly ImportTypeEnum = ImportTypeEnum;
}
