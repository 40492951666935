<ng-container *ngIf="nestedDeclaration$ | async as declaration; else noDeclaration">
	<div *ngIf="declaration.is_demo" class="demo-indicator">DEMO</div>
	<div class="navigation">
		<div
			[@collapse]="collapseNavState"
			[class.shrink]="!isOpen"
			[class.sidebar-warning]="declaration.is_demo"
			class="sidebar"
		>
			<div [@collapse]="collapseNavState" class="nav">
				<div (click)="shrink(); $event.stopPropagation()" class="size-icon">
					<div class="size-icon-up"></div>
					<div class="size-icon-center">
						<mat-icon>chevron_left</mat-icon>
					</div>
					<div class="size-icon-down"></div>
				</div>
				<div class="nav-steps">
					<div>
						<p class="nav-title hide">Etapes de déclarations</p>
					</div>
					<div *ngFor="let page of PAGES" class="nav-step">
						<div
							(click)="selectDeclarationStep(page, declaration)"
							*ngFor="let active of [page | stepActiveFromPage: segment$.value]"
							[class.active]="active"
							class="number"
						>
							<ng-container *ngIf="declaration.declaration_id === declaration.declaration_id; else stepLoading">
								<ng-container *ngFor="let todo of [declaration | stepTodoPercentage: page]">
									<mat-icon *ngIf="active; else elseBlock" matTooltip="{{ page.label }} - {{ todo | number: '1.0-0' }}%"
										>radio_button_checked</mat-icon
									>
									<ng-template #elseBlock>
										<mat-icon
											*ngIf="todo === 100; else notDone"
											matTooltip="{{ page.label }} - {{ todo | number: '1.0-0' }}%"
											>adjust</mat-icon
										>
										<ng-template #notDone>
											<mat-icon class="small" matTooltip="{{ page.label }} - {{ todo | number: '1.0-0' }}%"
												>fiber_manual_record</mat-icon
											>
										</ng-template>
									</ng-template>
								</ng-container>
							</ng-container>
							<ng-template #stepLoading>
								<mat-icon class="small">fiber_manual_record</mat-icon>
							</ng-template>
						</div>
						<div class="info hide">
							<p (click)="selectDeclarationStep(page, declaration)" class="title">
								{{ page.label }}
							</p>
							<div
								*ngIf="segment$.value | apply: canRedirectToFunctional"
								[@collapseHeight]="page | collapsableState: segment$.value"
								class="text"
							>
								<ng-container
									*ngFor="
										let currentDeclarationFunctionalId of [
											(functionalDeclarationState.get$ | async)?.value?.declaration_functional_id,
										]
									"
								>
									<ng-container *ngIf="page | stepActiveFromPage: segment$.value">
										<div
											(click)="
												getcollapseState(page) === 'open' ? selectFunctionalStep(functionalDeclaration) : '';
												$event.stopPropagation()
											"
											*ngFor="
												let functionalDeclaration of declaration
													| getFunctionalDeclarationAroundCurrent
														: currentDeclarationFunctionalId
														: FUNCTIONAL_DECLARATION_TO_SHOW
											"
											[class.active]="
												currentDeclarationFunctionalId === functionalDeclaration.declaration_functional_id
											"
											[ngStyle]="{
												cursor: (page | collapsableState: segment$.value) === 'open' ? 'pointer' : 'default',
											}"
											style="margin-bottom: 10px"
										>
											<span class="d-inline-flex gap-2" style="align-items: center">
												&#8226;
												<span>
													{{ functionalDeclaration.name ? functionalDeclaration.name : "Entité sans nom" }}
													<i *ngIf="functionalDeclaration.name">
														{{ functionalDeclaration.address.cityName | titlecase }}
													</i>
												</span>

												<mat-icon *ngIf="!functionalDeclaration.is_token_used" [matTooltip]="TOKEN_NOT_USED">
													{{ ICON_TOKEN_NOT_USED }}
												</mat-icon>
											</span>
										</div>
									</ng-container>
								</ng-container>

								<button
									(click)="openDeclarationFunctionalListModal(declaration)"
									*ngIf="declaration.declarations_functional.length > FUNCTIONAL_DECLARATION_TO_SHOW"
									class="pointer btn-stroked-primary-light"
									mat-stroked-button
									style="margin-bottom: 10px"
								>
									{{ declaration.declarations_functional.length - FUNCTIONAL_DECLARATION_TO_SHOW }}
									{{
										declaration.declarations_functional.length - FUNCTIONAL_DECLARATION_TO_SHOW > 1
											? "établissements supplémentaires"
											: "établissement supplémentaire"
									}}
								</button>
								<button
									(click)="
										getcollapseState(page) === 'open' ? next(declaration, functionalDeclarationId) : '';
										$event.stopPropagation()
									"
									*ngIf="
										(functionalDeclarationState.get$ | async)?.value
											?.declaration_functional_id as functionalDeclarationId
									"
									[ngStyle]="{ cursor: (page | collapsableState: segment$.value) === 'open' ? 'pointer' : 'default' }"
									class="btn-accent3"
									style="margin-right: 10px"
								>
									Étape suivante
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content" style="margin-top: 20px">
			<ng-container *ngIf="segment$.value | isSegmentFunctional">
				<div class="adr-icon-container">
					<div
						(click)="openDeclarationFunctionalListModal(declaration)"
						*ngIf="(functionalDeclarationState.get$ | async)?.value as functionalDeclaration"
						class="adr-icon pointer"
					>
						<div class="adr-icon-left"></div>
						<div class="adr-icon-center">
							{{ functionalDeclaration.address | addressToString }}
						</div>
						<div class="adr-icon-right"></div>
					</div>
				</div>
			</ng-container>
			<div class="pane" style="margin: 0 10px 20px 0">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #noDeclaration>
	<ng-container *ngIf="(functionalDeclarationState.get$ | async)?.value as functionalDeclaration">
		<div *ngIf="functionalDeclaration.is_demo" class="demo-indicator">DEMO</div>
		<div *ngIf="functionalDeclaration.declaration_functional_id as functionalDeclarationId" class="navigation">
			<div [@collapse]="collapseNavState" [class.shrink]="!isOpen" class="sidebar">
				<div [@collapse]="collapseNavState" class="nav">
					<div (click)="shrink(); $event.stopPropagation()" class="size-icon">
						<div class="size-icon-up"></div>
						<div class="size-icon-center">
							<mat-icon>chevron_left</mat-icon>
						</div>
						<div class="size-icon-down"></div>
					</div>
					<div>
						<p class="nav-title hide">Etapes de déclarations</p>
					</div>
					<div class="nav-steps">
						<ng-container *ngFor="let page of PAGES">
							<div *ngIf="page.goToSegmentFunctional" class="nav-step isolated">
								<div
									*ngFor="let active of [page | stepActiveFromPage: segment$.value]"
									[class.active]="active"
									class="number"
									routerLink="/{{ functionalDeclarationId }}/{{ page.goToSegmentFunctional }}"
								>
									<ng-container *ngFor="let todo of [!(page | hasNotDoneStep: functionalDeclaration)]">
										<mat-icon *ngIf="active; else elseBlock">radio_button_checked</mat-icon>
										<ng-template #elseBlock>
											<mat-icon *ngIf="todo; else notDone">adjust</mat-icon>
											<ng-template #notDone>
												<mat-icon class="small">fiber_manual_record</mat-icon>
											</ng-template>
										</ng-template>
									</ng-container>
								</div>
								<div class="info hide">
									<a class="title" routerLink="/{{ functionalDeclarationId }}/{{ page.goToSegmentFunctional }}">
										{{ page.label }}
									</a>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="content">
				<ng-container *ngIf="segment$.value | isSegmentFunctional">
					<div class="adr-icon-container">
						<div *ngIf="functionalDeclarationState.get$ | async as functionalDeclaration" class="adr-icon">
							<div class="adr-icon-left"></div>
							<div class="adr-icon-center">
								{{ functionalDeclaration.value.address | addressToString }}
							</div>
							<div class="adr-icon-right"></div>
						</div>
					</div>
				</ng-container>
				<div class="pane" style="margin: 20px 10px 20px 0">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>
