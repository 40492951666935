<mgl-map
	#map
	[style]="style ?? ''"
	[zoom]="[17]"
	[center]="center"
	(mapLoad)="onFirstLoad($event)"
	movingMethod="jumpTo"
>
	<!--<mgl-control mglNavigation></mgl-control> Can't disable it by code so I prefer to not use it -->
	<mgl-marker [lngLat]="center"></mgl-marker>

	<mgl-layer
		*ngFor="let layer of layers"
		[id]="layer.id"
		[source]="layer.source"
		[sourceLayer]="layer['source-layer'] ?? ''"
		[type]="layer.type"
		[paint]="layer.paint"
		(layerClick)="onLayerClick(layer.id, $event)"
		(layerMouseMove)="onLayerMouseMove(layer.id, $event)"
		(layerMouseLeave)="onLayerMouseLeave(layer.id, $event)"
		before="labels"
	></mgl-layer>

	<mgl-geojson-source id="labels" [data]="this.labelsSource.value"> </mgl-geojson-source>
	<mgl-layer id="labels" type="symbol" source="labels" [layout]="LABELS_LAYOUT" [paint]="LABELS_PAINT"> </mgl-layer>
</mgl-map>
