import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Immutable } from "immer";
import { unwrap } from "src/app/helpers/unwrap";
import { Declaration } from "src/app/models/declaration";
import { DeclarationGroup } from "src/app/models/declarationGroup";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId, RepresentativeId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { Representative } from "src/app/models/representative";
import { BaseComponent } from "../../../models/base-component.directive";

@Component({
	selector: "app-resource-link",
	templateUrl: "./resource-link.component.html",
	styleUrls: ["./resource-link.component.scss"],
})
export class ResourceLinkComponent extends BaseComponent implements OnInit {
	@Input() representative?: Immutable<Lazy<Representative>>;
	@Input() group?: Immutable<Lazy<DeclarationGroup>>;
	@Input() declaration?: Immutable<Lazy<Declaration>>;
	@Input() functionalDeclaration?: Immutable<FunctionalDeclaration>;
	@Input() current = false;

	@Output() selectFunctionalDeclaration = new EventEmitter<FunctionalDeclarationId>();
	@Output() selectDeclaration = new EventEmitter<DeclarationId>();
	@Output() selectDeclarationGroup = new EventEmitter<DeclarationGroupId>();
	@Output() selectRepresentative = new EventEmitter<RepresentativeId>();

	description = "";
	type = "";

	ngOnInit() {
		if (this.functionalDeclaration) {
			this.type = "Entité fonctionnelle";
			this.description = this.functionalDeclaration.description ?? "";
		} else if (this.group) {
			this.type = "Groupe de déclarations";
			this.description = this.group.name ?? "No name";
		} else if (this.declaration) {
			this.type = "Déclaration";
			this.description = this.declaration.name ?? "No name";
		} else if (this.representative) {
			this.type = "Mandataire";
			this.description = this.representative.name;
		}
	}

	emit() {
		if (this.functionalDeclaration) {
			this.selectFunctionalDeclaration.emit(unwrap(this.functionalDeclaration.declaration_functional_id));
		} else if (this.group) {
			this.selectDeclarationGroup.emit(unwrap(this.group.declaration_group_id));
		} else if (this.declaration) {
			this.selectDeclaration.emit(unwrap(this.declaration.declaration_id));
		} else if (this.representative) {
			this.selectRepresentative.emit(this.representative.representative_id);
		}
	}
}
