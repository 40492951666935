import { Location } from "@angular/common";
import { AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { Immutable } from "immer";
import { BehaviorSubject, EMPTY, of, zip } from "rxjs";
import { catchError, first, map, switchMap, tap } from "rxjs/operators";
import { toObservable } from "src/app/helpers/to-observable";
import { unwrap } from "src/app/helpers/unwrap";
import { DeclarationGroup } from "src/app/models/declarationGroup";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { Lazy } from "src/app/models/lazy";
import { Representative } from "src/app/models/representative";
import { ResourceType } from "src/app/models/resource";
import { Route, routeToResourceType, stringToRoute } from "src/app/models/routes";
import { DeclarationGroupStateService } from "src/app/services/declaration-group-state.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { IsolationStateService } from "src/app/services/isolation-state.service";
import { RepresentativeStateService } from "src/app/services/representative-state.service";
import { UserStateService } from "src/app/services/user-state.service";
import { BaseComponent } from "../../../../models/base-component.directive";
import { Declaration } from "../../../../models/declaration";
import { dialogOpen } from "../../../../models/modal";
import { OperatApiService } from "../../../../services/operat-api.service";
import { TourStateService } from "../../../../services/tour-state.service";
import { AuthorizationListModalComponent } from "../../authorization-list-modal/authorization-list-modal.component";
import { TokenTransferModalComponent } from "../../token-transfer-modal/token-transfer-modal.component";
import { ADDRESS_PAGE, DEFAULT_PAGES, FUNCTIONAL_PAGES, Page, QUALIFICATION_PAGE } from "../stepper.component";
import { CompleteFunctionalDeclarationFromApiComponent } from "./complete-functional-declaration-from-api/complete-functional-declaration-from-api.component";

function getPageIndexAndSegment(url: string, pages: Page[]): { index: number; segment: Route } | undefined {
	const segment = String(url.split("?")[0].split("/").pop());
	const index = pages.findIndex(
		(page) => page.goToSegment === segment || page.segmentsForStepper.some((route) => route === segment),
	);
	const route = stringToRoute(segment);

	if (index === -1 || route === undefined) {
		return undefined;
	}

	return { index, segment: route };
}

@Component({
	selector: "app-functional-entity-stepper",
	templateUrl: "./functional-entity-stepper.component.html",
	styleUrls: ["./functional-entity-stepper.component.scss"],
})
export class FunctionalEntityStepperComponent
	extends BaseComponent
	implements OnDestroy, AfterViewChecked, AfterContentChecked
{
	pageInfo$: BehaviorSubject<{ page: Page; index: number; segment: Route }>;
	step = new BehaviorSubject(0);

	pages: Page[];

	constructor(
		private dialog: MatDialog,
		private router: Router,
		public functionalDeclarationState: FunctionalDeclarationStateService,
		public declarationState: DeclarationStateService,
		public groupState: DeclarationGroupStateService,
		public userState: UserStateService,
		public representativeState: RepresentativeStateService,
		private isolationState: IsolationStateService,
		location: Location,
		public tourStateService: TourStateService,
		private cdRef: ChangeDetectorRef,
		private operatApi: OperatApiService,
	) {
		super();
		// We have to do this because when we refresh the page
		// router.events doesn't fire any event.
		this.pages =
			isolationState.isolationInfo?.type === ResourceType.FunctionalDeclaration ? FUNCTIONAL_PAGES : DEFAULT_PAGES;
		const pageIndexAndSegment = getPageIndexAndSegment(location.path(), this.pages);
		if (pageIndexAndSegment !== undefined) {
			const { index, segment } = pageIndexAndSegment;
			this.pageInfo$ = new BehaviorSubject({
				page: this.pages[index],
				index: index,
				segment,
			});
		} else {
			this.pageInfo$ = new BehaviorSubject({ page: ADDRESS_PAGE, index: 0, segment: ADDRESS_PAGE.goToSegment });
		}
		// We search for the last segment
		this.sub(
			router.events.pipe(
				switchMap((event) => {
					if (event instanceof NavigationEnd) {
						const pageIndexAndSegment = getPageIndexAndSegment(event.url, this.pages);
						return pageIndexAndSegment !== undefined
							? of({
									page: this.pages[pageIndexAndSegment.index],
									index: pageIndexAndSegment.index,
									segment: pageIndexAndSegment.segment,
								})
							: EMPTY;
					}
					return EMPTY;
				}),
			),
			this.pageInfo$,
		);

		// We search for the entity's position
		this.sub(
			functionalDeclarationState.get$.pipe(
				switchMap((functionalDeclaration) => zip(of(functionalDeclaration), declarationState.get$)),
				switchMap(([functionalDeclaration, declaration]) =>
					functionalDeclaration && declaration
						? of({ functionalDeclaration: functionalDeclaration.value, declaration: declaration.value })
						: EMPTY,
				),
				map(({ functionalDeclaration, declaration }) =>
					declaration.declarations_functional
						.map((fd) => fd.declaration_functional_id)
						.findIndex((id) => id === functionalDeclaration.declaration_functional_id),
				),
			),
			this.step,
		);
	}

	ngAfterContentChecked() {
		this.cdRef.detectChanges();
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	startGuide() {
		this.tourStateService.startInitialized();
	}

	next(entities: Immutable<Lazy<FunctionalDeclaration>[]>, segment: Route) {
		this.router.navigate([entities[this.step.value + 1].declaration_functional_id, segment]);
	}

	nextStep(currentFunctionalDeclarationId: string, pageIndex: number) {
		const nextPage = this.pages[pageIndex + 1];
		if (this.isolationState.isolationInfo?.type === ResourceType.FunctionalDeclaration) {
			this.router.navigate([currentFunctionalDeclarationId, nextPage.goToSegmentFunctional]);
		} else {
			this.declarationState.get$
				.pipe(
					switchMap((declaration) => (declaration ? of(declaration.value) : EMPTY)),
					first(),
					switchMap((declaration) => {
						const type = routeToResourceType(nextPage.goToSegment);
						if (type === ResourceType.FunctionalDeclaration) {
							return this.functionalDeclarationState
								.select$(unwrap(declaration.declarations_functional[0].declaration_functional_id))
								.pipe(
									tap(() => {
										this.router.navigate([
											declaration.declarations_functional[0].declaration_functional_id,
											nextPage.goToSegment,
										]);
									}),
								);
						}
						return toObservable(() => this.router.navigate([declaration.declaration_id, nextPage.goToSegment]));
					}),
				)
				.subscribe();
		}
	}

	invite(
		group: Immutable<Lazy<DeclarationGroup>>,
		functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>,
		userId: string,
	) {
		if (functionalDeclaration.declaration_functional_id && group?.owner_id) {
			this.dialog.open(AuthorizationListModalComponent, {
				data: {
					resourceId: functionalDeclaration.declaration_functional_id,
					resourceType: ResourceType.FunctionalDeclaration,
					ownerId: group.owner_id,
					forceTemporary: true,
					userId,
				},
				panelClass: "p-0",
			});
		}
	}

	openTransferDialog(
		declarationGroup: Immutable<Lazy<DeclarationGroup>>,
		representative: Immutable<Lazy<Representative>>,
	) {
		this.dialog.open(TokenTransferModalComponent, { data: { declarationGroup, representative }, panelClass: "p-0" });
	}

	fillWithOperatApi(
		declaration: Immutable<Lazy<Declaration>>,
		functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>,
	): void {
		dialogOpen(
			this.dialog,
			CompleteFunctionalDeclarationFromApiComponent,
			{
				declaration,
				functionalDeclaration,
				operatApi: this.operatApi,
				declarationService: this.declarationState,
			},
			{ panelClass: "p-0", maxWidth: "600px" },
		)
			.afterClosed()
			.pipe(
				switchMap((output) => (output ? of(output) : EMPTY)),
				tap(() => this.declarationState.saving$.next(true)),
				switchMap((dataToImport) =>
					this.operatApi.fillEfa(unwrap(functionalDeclaration.declaration_functional_id), dataToImport),
				),
				map(FunctionalDeclaration.fromApi),
				switchMap((functionalDeclaration) => this.functionalDeclarationState.update$(functionalDeclaration)),
				catchError((error) => {
					this.declarationState.saving$.next(false);
					throw error;
				}),
			)
			.subscribe(() => this.declarationState.saving$.next(false));
	}

	protected readonly QUALIFICATION_PAGE = QUALIFICATION_PAGE;
}
