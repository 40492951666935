import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "../../../models/base-component.directive";

@Component({
	selector: "app-token-button",
	templateUrl: "./token-button.component.html",
	styleUrls: ["./token-button.component.scss"],
})
export class TokenButtonComponent extends BaseComponent {
	@Input() usedToken: number = 0;
	@Input() totalTokens: number = 0;
	@Input() usedAnnualTokens: number = 0;
	@Input() annualTokens: number = 0;
	@Input() type: string = "";
	@Input() classes: string = "btn-primary";
	@Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

	click() {
		this.buttonClick.emit();
	}
}
