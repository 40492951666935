export const removeAccent = (s: string) => {
	return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const contains = (haystack: string | undefined | null, needle: string) => {
	return !needle || (haystack && removeAccent(haystack.toLowerCase()).includes(removeAccent(needle.toLowerCase())));
};

export function toSnakeCase(inputString: string) {
	return inputString
		.split("")
		.map((character) => {
			if (character === " ") {
				return "_";
			}
			return character
				.toLowerCase()
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "");
		})
		.join("");
}
