import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Immutable } from "immer";
import { EMPTY, Observable, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { range } from "../../../../../helpers/array";
import {
	getEntitiesWithoutReferenceYear,
	getFunctionalDeclarationsSelected,
	getIncompleteFilterFunction,
} from "../../../../../helpers/csv-conso-helpers";
import { myForkjoin } from "../../../../../helpers/to-observable";
import { unwrap } from "../../../../../helpers/unwrap";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { Declaration } from "../../../../../models/declaration";
import { FunctionalDeclaration } from "../../../../../models/functionalDeclaration";
import { Lazy } from "../../../../../models/lazy";
import { dialogOpen } from "../../../../../models/modal";
import { RouteDealer } from "../../../../../models/routes";
import { EntitiesNotConcernedByYearPipe } from "../../../../../pipes/entities-not-concerned-by-year.pipe";
import { ConsoCsvFile } from "../../../../../pipes/get-conso-state-for-csv.pipe";
import { CsvService, IdAndIsReference } from "../../../../../services/csv.service";
import { DeclarationStateService } from "../../../../../services/declaration-state.service";
import { HelpSubject } from "../../../../help/help.component";
import { PartialCsvDownloadComponent } from "./partial-csv-download/partial-csv-download.component";
import MAXIMUM_YEAR_INITIAL_DECLARATION = Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION;
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

@Component({
	selector: "app-consumptions-csv",
	templateUrl: "./consumptions-csv.component.html",
	styleUrls: ["./consumptions-csv.component.scss"],
})
export class ConsumptionsCsvComponent extends BaseComponent implements OnInit {
	@Input() declaration$: Observable<Declaration> = EMPTY;
	@Output() openLockedModal = new EventEmitter<boolean>();

	readonly MAXIMUM_YEAR = new Date().getFullYear();
	readonly ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

	years: Immutable<number[]> = range(new Date(Declaration.MINIMUM_DATE).getFullYear(), 2020);
	annualDeclarationYears: Immutable<number[]> = range(this.ANNUAL_DECLARATION_STARTING_YEAR, this.MAXIMUM_YEAR);
	search = "";
	// We replace files without content by undefined (null is reserved for an observable that has not yet emitted in the template)
	consos: { year: number; csv$: Observable<ConsoCsvFile | undefined> }[] = [];
	referenceConso$: Observable<ConsoCsvFile | undefined> = EMPTY;

	scrollTo(year: number) {
		const stepErrorDiv = document.getElementById(`${year}incomplete`);

		if (stepErrorDiv) {
			stepErrorDiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		}
	}

	constructor(
		private csvService: CsvService,
		private sanitizer: DomSanitizer,
		public declarationState: DeclarationStateService,
		private dialog: MatDialog,
	) {
		super();
	}

	ngOnInit(): void {
		const date = getDateString();

		this.referenceConso$ = this.declaration$.pipe(
			switchMap((declaration) => {
				const consoCsvsAsync = range(new Date(Declaration.MINIMUM_DATE).getFullYear(), 2020)
					.reverse()
					.map((year) => {
						return this.csvService.getConso$(unwrap(declaration.declaration_id), year, { onlyReference: true });
					});
				return zip(myForkjoin(consoCsvsAsync), of(declaration.structure));
			}),
			map(([csvsByYear, structure]) => {
				const structureId = getStructureId(structure);
				return this.getCsvFile(
					csvsByYear.flatMap((consoCsvs) => consoCsvs),
					`${structureId}_CONSOMMATIONS_REFERENCE_${date}`,
				);
			}),
		);

		const yearToConsoCsv$ = (year: number) =>
			this.declaration$.pipe(
				switchMap((declaration) => {
					return zip(this.csvService.getConso$(unwrap(declaration.declaration_id), year), of(declaration.structure));
				}),
				map(([consoCsv, structure]) => {
					const structureId = getStructureId(structure);

					return this.getCsvFile([consoCsv], `${structureId}_CONSOMMATIONS_${year}_${date}`);
				}),
			);

		this.consos = [
			...[...this.annualDeclarationYears].reverse().map((year) => ({ year: year, csv$: yearToConsoCsv$(year) })),
			{ year: 2021, csv$: yearToConsoCsv$(2021) },
			{ year: 2020, csv$: yearToConsoCsv$(2020) },
		];
	}

	readonly RouteDealer = RouteDealer;

	readonly filterIsIncomplete2021 = getIncompleteFilterFunction(2021);

	toSafeCsvFileUrl(text: string): SafeUrl {
		return this.sanitizer.bypassSecurityTrustUrl(
			URL.createObjectURL(new Blob([text], { type: "text/csv;charset=utf-8" })),
		);
	}

	getCsvFile(
		responses: {
			idAndIsReferenceList: IdAndIsReference[];
			content: string;
		}[],
		filename: string,
	): ConsoCsvFile | undefined {
		const file = mergeCsvFiles(responses.map(({ content }) => content));

		if (!file) {
			return undefined;
		}

		const ids = responses.flatMap(({ idAndIsReferenceList }) => idAndIsReferenceList).map(({ id }) => id);

		return {
			file: this.toSafeCsvFileUrl(file),
			filename,
			ids,
		};
	}

	openLockedYearDialog(isInitialDeclaration: boolean = true) {
		this.openLockedModal.emit(isInitialDeclaration);
	}

	openPartialCsvDialog(declaration: Declaration, year: number | null, csv: ConsoCsvFile) {
		dialogOpen(
			this.dialog,
			PartialCsvDownloadComponent,
			{
				functionalDeclarations: this.getFunctionalDeclarationsSelected(declaration),
				year: year,
				csv: csv,
			},
			{ panelClass: "p-0" },
		);
	}

	numberOfLockedFunctionalDeclarationsForReferenceYear(
		functionalDeclarations: Immutable<Lazy<FunctionalDeclaration>[]>,
	) {
		let i = 0;
		for (const functionalDeclaration of functionalDeclarations) {
			if (!functionalDeclaration.is_token_used) {
				i++;
			}
		}

		return i;
	}

	/**
	 * Check if there's entities that don´t have reference data when they should
	 * @param functionalDeclarations
	 */
	missingReferenceData(functionalDeclarations: Immutable<FunctionalDeclaration[]>): boolean {
		const entitiesNotConcernedByYearPipe = new EntitiesNotConcernedByYearPipe();
		const entitiesWithoutReferenceYear = getEntitiesWithoutReferenceYear(functionalDeclarations);

		// If some entities without ref are concerned by 2021 ( === not newly liable)
		return (
			entitiesNotConcernedByYearPipe.transform(entitiesWithoutReferenceYear, ANNUAL_DECLARATION_STARTING_YEAR - 1) <
			entitiesWithoutReferenceYear.length
		);
	}

	protected readonly getFunctionalDeclarationsSelected = getFunctionalDeclarationsSelected;
	protected readonly getIncompleteFilterFunction = getIncompleteFilterFunction;
	protected readonly HelpSubject = HelpSubject;
	protected readonly Declaration = Declaration;
	protected readonly MAXIMUM_YEAR_INITIAL_DECLARATION = MAXIMUM_YEAR_INITIAL_DECLARATION;
}

function getDateString(): string {
	const date = new Date();
	const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
	const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
	const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
	return `${da}${mo}${ye}`;
}

/**
 * Merge multiple CSV files into one.
 * @param files CSV files. Each files must include its header line.
 * @returns The merged file. `null` if `files` is empty.
 */
function mergeCsvFiles(files: string[]): string | null {
	if (files.length === 0) {
		return null;
	}

	const headerLine = files[0].split("\n")[0];

	const lines = files.flatMap((file) => {
		const [, ...lines] = file.split("\n");
		return lines;
	});

	return [headerLine, ...lines].join("\n");
}

function getStructureId(structure: Declaration["structure"]): string {
	return "siren" in structure ? structure.siren : "rna" in structure ? structure.rna : structure.id;
}
