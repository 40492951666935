<span mat-dialog-title>
	<span class="text-icon">
		<mat-icon>tag</mat-icon>
		{{ state.lotNumbers.length > 1 || wantMultipleNumbers ? "Mes numéros de lot" : "Mon numéro de lot" }}
		<app-help
			*ngIf="state.lotNumbers.length > 1 || wantMultipleNumbers"
			[subject]="HelpSubject.MultipleLotNumbers"
		></app-help>
	</span>
</span>
<div mat-dialog-content class="d-flex gap-2" style="flex-direction: column">
	<ng-container *ngIf="state.lotNumbers.length === 1 && !wantMultipleNumbers; else multiple">
		<div class="input-icon">
			<input
				placeholder="Mon numéro de lot"
				class="custom-input"
				minlength="1"
				maxlength="8"
				pattern="[a-zA-Z0-9 ]+"
				appInputRestriction="alphanumeric"
				[(ngModel)]="state.lotNumbers[0]"
			/>
			<span class="input-suffix text-gray">
				<mat-icon>{{ state.lotNumbers[0] === "" ? "close" : "check" }}</mat-icon>
			</span>
		</div>

		<button
			mat-button
			class="btn-primary"
			(click)="wantMultipleNumbers = true; state.lotNumbers[0] === '' ? (state.lotNumbers = []) : undefined"
		>
			Je veux renseigner plusieurs numéros
		</button>
	</ng-container>
	<ng-template #multiple>
		<div class="d-flex gap-2">
			<input
				placeholder="Ajouter un numéro de lot"
				[(ngModel)]="inputBuffer"
				class="custom-input"
				minlength="1"
				maxlength="8"
				pattern="[a-zA-Z0-9 ]+"
				(keyup.enter)="addLotNumber(inputBuffer); inputBuffer = ''"
				appInputRestriction="alphanumeric"
			/>

			<button
				mat-button
				class="btn-accent btn-squared"
				style="height: 40px; width: 40px"
				[disabled]="!canAdd()"
				(click)="addLotNumber(inputBuffer) ? (inputBuffer = '') : undefined"
			>
				<mat-icon>{{ canAdd() ? "arrow_forward" : "close" }}</mat-icon>
			</button>
		</div>

		<h4 class="text-gray" *ngIf="state.lotNumbers.length === 0">Vous n'avez pas de numéros de lot.</h4>

		<button *ngFor="let lotNumber of state.lotNumbers; index as i" mat-button (click)="delete(i)">
			<mat-icon>delete_forever</mat-icon>
			{{ lotNumber }}
		</button>
	</ng-template>
</div>
<div mat-dialog-actions class="d-flex" style="justify-content: space-between">
	<span>
		<button mat-button (click)="cancel()">Annuler</button>
		<button mat-button (click)="save()">Sauvegarder</button>
	</span>
</div>
