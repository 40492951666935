import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "iconFromMimeType",
})
export class IconFromMimeTypePipe implements PipeTransform {
	transform(type: string): string {
		if (type.includes("image")) {
			return "image";
		}
		if (type.includes("pdf")) {
			return "picture_as_pdf";
		}

		return "description";
	}
}
