import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "min",
})
export class MinPipe implements PipeTransform {
	transform(...numbers: number[]): number {
		return Math.min(...numbers);
	}
}
