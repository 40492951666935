import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Nullable } from "../helpers/nullable";
import { Declaration } from "../models/declaration";
import { Rna, Siren } from "../models/identifier";

@Pipe({
	name: "asSirenStructure",
})
export class AsSirenStructurePipe implements PipeTransform {
	transform(
		structure: Immutable<Declaration["structure"]>,
	): Immutable<{ siren: Siren; maybeRna?: Nullable<Rna> }> | undefined {
		return "siren" in structure ? structure : undefined;
	}
}
