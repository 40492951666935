import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Immutable } from "immer";
import { EnergyType, energyTypeUnitToDatabaseStorage } from "src/app/models/energyType";
import { EnergyConsumptions } from "src/app/models/functionalDeclaration";
import { Declaration } from "../../../../../../models/declaration";
import { TourStateService } from "../../../../../../services/tour-state.service";
import { CustomFormComponent } from "../../../../../custom-form/custom-form.component";
import { HelpSubject } from "../../../../../help/help.component";
import { ImportDeliveryCsvModalComponent } from "./import-delivery-csv-modal/import-delivery-csv-modal.component";

@Component({
	selector: "app-add-delivery-values-modal",
	templateUrl: "./add-delivery-values-modal.component.html",
	styleUrls: ["./add-delivery-values-modal.component.scss"],
})
export class AddDeliveryValuesModalComponent {
	initialConsumptionTable: Immutable<EnergyConsumptions>;
	consumptionTable: EnergyConsumptions;
	energy: EnergyType;
	category: string;
	EnergyType = EnergyType;
	HelpSubject = HelpSubject;
	readonly START = new Date(Declaration.MINIMUM_DATE_TIME * 1000);
	readonly TODAY = new Date();

	@ViewChild("form") form: CustomFormComponent | undefined;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			consumptionTable: Immutable<EnergyConsumptions>;
			energy: EnergyType;
			category: string;
		},
		public dialog: MatDialog,
		private tourState: TourStateService,
		public cdRef: ChangeDetectorRef,
	) {
		if (tourState.onTour) {
			if (tourState.onTour) {
				tourState.end();
				tourState.start([
					{
						anchorId: "consumption-add-delivery-average",
						content:
							"Choisissez le nombre d'années sur lequel vous voulez moyenner vos livraisons, cela permet de lisser les consommations sur plusieurs années, et pourra impacter les objectifs.",
						title: "Choisissez le nombre d'années à moyenner",
					},
					{
						anchorId: "consumption-add-delivery-date",
						content: "Entrez la date de la livraison",
						title: "Entrez la date de la livraison",
					},
					{
						anchorId: "consumption-add-delivery-value",
						content: "Entrez la quantité livrée",
						title: "Entrez la quantité livrée",
					},
					{
						anchorId: "consumption-add-delivery-row",
						content: "Ajouter une autre livraison",
						title: "Ajouter une autre livraison",
					},
					{
						anchorId: "consumption-add-delivery-save",
						content: "Il ne vous reste plus qu'à sauvegarder.",
						title: "Sauvegardez",
						nextOnAnchorClick: true,
					},
				]);
			}
		}
		this.consumptionTable = new EnergyConsumptions(data.consumptionTable);
		this.initialConsumptionTable = data.consumptionTable;
		this.energy = data.energy;
		this.category = data.category;
		if (this.consumptionTable.repartitionKeys.length > 0) {
			this.consumptionTable.repartitionKeys[0].start = this.consumptionTable.start;
		}
		if (this.consumptionTable.invoiceData.length === 0) {
			this.addInvoice();
		}
	}

	checkError(start: number, end: number | undefined, input: HTMLInputElement) {
		input.classList.add("ng-touched");
		input.setCustomValidity(end && start > end ? "La date de fin doit être inférieure à la date de début." : "");
		this.form?.updateFormValidity();
	}

	public openImportCSVModal(): void {
		this.dialog
			.open(ImportDeliveryCsvModalComponent, {
				data: {
					invoiceTable: this.consumptionTable.invoiceData,
					energy: this.energy,
				},
			})
			.afterClosed()
			.subscribe((invoiceTable) => {
				if (invoiceTable) {
					this.consumptionTable.invoiceData.push(...invoiceTable);
					this.sortInvoice();
				}
			});
	}

	onValueChange(value: string, invoice: { value: number }): void {
		invoice.value = energyTypeUnitToDatabaseStorage(this.energy, Number(value));
	}

	sortInvoice() {
		this.consumptionTable.invoiceData.sort((a, b) => {
			return a.date > b.date ? 1 : -1;
		});
	}

	public initRepartitionKeys() {
		if (this.consumptionTable.hasRepartitionKey && this.consumptionTable.repartitionKeys.length === 0) {
			this.addRepartitionKey();
			this.form?.updateFormValidity();
		}
	}

	public addRepartitionKey() {
		let start = this.consumptionTable.start;
		const length = this.consumptionTable.repartitionKeys.length;
		if (length !== 0) {
			const previousEnd = this.consumptionTable.repartitionKeys[length - 1].end;
			if (previousEnd) {
				start = previousEnd;
			}
		}
		this.consumptionTable.repartitionKeys.push({
			start,
			end: this.consumptionTable.end,
			partUmpteenth: 100,
			totalUmpteenth: 100,
		});
	}

	public deleteRepartitionKey(repartitionKey: {
		partUmpteenth: number;
		totalUmpteenth: number;
		start: number;
		end?: number | undefined;
	}) {
		if (this.consumptionTable.repartitionKeys.length > 1) {
			this.consumptionTable.repartitionKeys = this.consumptionTable.repartitionKeys.filter(
				(rk) => rk !== repartitionKey,
			);
		}
		this.form?.updateFormValidity();
	}

	public repartitionKeyEndDateChange(
		repartitionKey: {
			partUmpteenth: number;
			totalUmpteenth: number;
			start: number;
			end?: number | undefined;
		},
		date: string,
	) {
		repartitionKey.end = new Date(date).getTime() / 1000;
		if (repartitionKey.end < repartitionKey.start) {
			this.consumptionTable.repartitionKeys = [...this.consumptionTable.repartitionKeys];
			return;
		}
		const index = this.consumptionTable.repartitionKeys.indexOf(repartitionKey);
		if (index !== -1 && index !== this.consumptionTable.repartitionKeys.length - 1) {
			this.consumptionTable.repartitionKeys[index + 1].start = repartitionKey.end;
		}
		this.consumptionTable.repartitionKeys = [...this.consumptionTable.repartitionKeys];
		this.form?.updateFormValidity();
	}

	public addInvoice() {
		let date = this.consumptionTable.start;
		const length = this.consumptionTable.invoiceData.length;
		if (length !== 0) {
			const previousEnd = this.consumptionTable.invoiceData[length - 1].date;
			if (previousEnd) {
				date = previousEnd;
			}
		}
		this.consumptionTable.invoiceData.push({
			date,
			value: 0,
		});
		this.form?.updateFormValidity();
	}

	public deleteInvoice(invoice: { date: number; value: number }) {
		if (this.consumptionTable.invoiceData.length > 1) {
			this.consumptionTable.invoiceData = this.consumptionTable.invoiceData.filter(
				(loopInvoice) => loopInvoice !== invoice,
			);
		}
		this.form?.updateFormValidity();
	}

	public invoiceDateChange(invoice: { date: number; value: number }, date: string) {
		const newDate = new Date(date).getTime() / 1000;
		if (newDate) {
			invoice.date = newDate;
		}
		this.sortInvoice();
		const index = this.consumptionTable.invoiceData.indexOf(invoice);
		document.getElementById(`invoice-data-${index}`)?.scrollIntoView({
			behavior: "auto",
			block: "center",
			inline: "center",
		});
	}
}
