import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { CURRENT_VERSION, VERSION_KEY } from "../models/version";

@Injectable({
	providedIn: "root",
})
export class UserStateService {
	private currentUser?: User;

	get user(): User | undefined {
		return this.currentUser;
	}

	set user(user: User | undefined) {
		if (user === undefined) {
			this.currentUser = undefined;
			localStorage.removeItem("user");
		} else {
			this.currentUser = { ...user, token: this.currentUser?.token };
			if (user.token) {
				this.currentUser.token = user.token;
			}

			localStorage.setItem(VERSION_KEY, CURRENT_VERSION);
			localStorage.setItem("user", JSON.stringify(this.currentUser));
		}
	}
}
