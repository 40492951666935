import { HeaderEnum } from "./HeaderEnum";
export class Indicator {
  name;
  value;
  constructor(name, value) {
    this.name = name;
    this.value = parseFloat(value);
  }
  serialize(index) {
    return {
      [`${HeaderEnum.NOM_INDICATEUR}${index}`]: this.name,
      [`${HeaderEnum.VALEUR_INDICATEUR}${index}`]: `${this.value}`
    };
  }
  isEmpty() {
    return !this.name;
  }
}
