import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { YearForDateAndPeriodPipe } from "./year-for-date-and-period.pipe";
import MAXIMUM_YEAR_INITIAL_DECLARATION = Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION;
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

@Pipe({
	name: "csvDataWillBePartial",
})
export class CsvDataWillBePartialPipe implements PipeTransform {
	transform(fd: Immutable<FunctionalDeclaration>, forAnnualData: boolean = true): boolean {
		const yearForDateAndPeriod = new YearForDateAndPeriodPipe();
		const favoritePeriod = fd.infos.favoritePeriod;
		const nbSecondsInADay = 86400;

		for (const type of Object.values(fd[GreenKeys.KEY_CONSUMPTIONS])) {
			for (const consumValues of Object.values(type)) {
				for (const energyValues of Object.values(consumValues.values)) {
					for (const entryValue of Object.values(energyValues.values)) {
						const yearStart = yearForDateAndPeriod.transform(entryValue.date_start, favoritePeriod);
						// End date is exclusive, so we remove a day when comparing periods
						const yearEnd = yearForDateAndPeriod.transform(entryValue.date_end - nbSecondsInADay, favoritePeriod);
						if (forAnnualData) {
							if (yearStart >= ANNUAL_DECLARATION_STARTING_YEAR && !fd.years_token_used.includes(yearStart)) {
								return true;
							}
							if (yearEnd >= ANNUAL_DECLARATION_STARTING_YEAR && !fd.years_token_used.includes(yearEnd)) {
								return true;
							}
						} else {
							if (
								(yearEnd <= MAXIMUM_YEAR_INITIAL_DECLARATION || yearStart <= MAXIMUM_YEAR_INITIAL_DECLARATION) &&
								!fd.is_token_used
							) {
								return true;
							}
						}
					}
				}
			}
		}
		return false;
	}
}
