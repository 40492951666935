import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "entitiesNotConcernedByYear",
})
export class EntitiesNotConcernedByYearPipe implements PipeTransform {
	transform(functionalDeclarations: Immutable<FunctionalDeclaration[]>, year: number): number {
		let result = 0;
		for (const functionalDeclaration of functionalDeclarations) {
			if (functionalDeclaration.first_year_declaration && functionalDeclaration.first_year_declaration > year) {
				result++;
			}
		}
		return result;
	}
}
