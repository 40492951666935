import { forkJoin, Observable, of } from "rxjs";
import { MapLibreEvent, Map, CameraOptions } from "maplibre-gl";

/**
 * Turns a function into an observable without calling it. Subscribing to the observable will
 * call the function. Useful to compose multiple observables to be sure what is executed first.
 * @param func The function
 * @returns A fresh observable that emits once
 */
export function toObservable<T>(func: () => T): Observable<T> {
	return new Observable<T>((sub) => {
		sub.next(func());
		sub.complete();
	});
}

/**
 * Will move the map and complete when everything is loaded.
 * @param map
 * @param options
 * @returns An observable
 */
export function mapJumpTo$(map: Map, options: CameraOptions): Observable<MapLibreEvent & object> {
	return new Observable((sub) => {
		map.once("idle", (event) => {
			sub.next(event);
			sub.complete();
		});
		map.jumpTo(options);
	});
}

/**
 * Like forkjoin but doesn't freeze when the provided array is empty.
 * @param observables The observables to wait.
 * @returns An observable that emits when all observables have emitted.
 */
export function myForkjoin<T>(observables: Observable<T>[]): Observable<T[]> {
	return observables.length > 0 ? forkJoin(observables) : of([]);
}
