import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError } from "rxjs/operators";

export class CustomError extends Error {}

export function customizeError<T>(message: string) {
	return catchError<T, never>(() => {
		throw new CustomError(message);
	});
}

export function snackError(snackBar: MatSnackBar, error: unknown) {
	snackBar.open(error instanceof CustomError ? error.message : "Une erreur est survenue.", "OK", {
		verticalPosition: "top",
	});
}
