import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { BaseComponent } from "../../../models/base-component.directive";
import { cloneUser, User } from "../../../models/user";
import { AuthenticationService } from "../../../services/authentication.service";
import { UserStateService } from "../../../services/user-state.service";

@Component({
	selector: "app-my-profile",
	templateUrl: "./my-profile.component.html",
	styleUrls: ["./my-profile.component.scss"],
})
export class MyProfileComponent extends BaseComponent implements AfterViewInit {
	user: User | undefined = undefined;

	actualPassword: string = "";
	newPassword: string = "";
	newPasswordConfirm: string = "";

	loading = false;

	@ViewChild("infoForm") infoForm: ElementRef | null = null;
	infoFormValid = false;

	@ViewChild("passwordForm") passwordForm: ElementRef | null = null;
	passwordFormValid = false;

	constructor(
		userState: UserStateService,
		private authenticationService: AuthenticationService,
	) {
		super();

		if (userState.user) {
			this.user = cloneUser(userState.user);
		}
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		setTimeout(() => {
			this.checkInfoFormValidity();
			this.checkPasswordFormValidity();
		});
	}

	checkInfoFormValidity() {
		this.infoFormValid = this.infoForm?.nativeElement.checkValidity() ?? false;
	}

	save(user: User) {
		this.loading = true;
		this.authenticationService.updateUser$(user).subscribe(() => {
			this.loading = false;
		});
	}

	checkPasswordFormValidity() {
		const actualPasswordInput = document.querySelector('input[name="actualPassword"]');
		if (actualPasswordInput instanceof HTMLInputElement) {
			actualPasswordInput.setCustomValidity("");
		}

		const confirmPasswordInput = document.querySelector('input[name="newPasswordConfirm"]');
		if (confirmPasswordInput instanceof HTMLInputElement) {
			confirmPasswordInput.setCustomValidity(
				this.newPassword !== this.newPasswordConfirm ? "Les mots de passe doivent correspondre." : "",
			);
		}

		this.passwordFormValid = this.passwordForm?.nativeElement.checkValidity();
	}

	savePassword() {
		this.checkPasswordFormValidity();
		if (this.passwordFormValid) {
			this.loading = true;
			this.authenticationService
				.updatePassword$({
					current_password: this.actualPassword,
					password: this.newPassword,
					password_confirm: this.newPasswordConfirm,
				})
				.subscribe(
					() => {
						this.loading = false;
					},
					(error: unknown) => {
						if (
							error instanceof HttpErrorResponse &&
							error.status === 400 &&
							error.error.error === "Invalid credentials."
						) {
							const actualPasswordInput = document.querySelector('input[name="actualPassword"]');
							if (actualPasswordInput) {
								(actualPasswordInput as HTMLInputElement).setCustomValidity("Le mot de passe est incorrect.");
							}
						}
						this.loading = false;
					},
				);
		}
	}
}
