import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { EMPTY, of } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";
import { range } from "../../../../helpers/array";
import { Nullable } from "../../../../helpers/nullable";
import { BaseComponent } from "../../../../models/base-component.directive";
import { wrapId } from "../../../../models/ids";
import { ResourceInfo, ResourceType } from "../../../../models/resource";
import { DeclarationGroupService } from "../../../../services/declaration-group.service";
import { DeclarationService } from "../../../../services/declaration.service";
import { RepresentativeService } from "../../../../services/representative.service";

const MINIMUM_YEAR_FOR_STATS = 2021;

@Component({
	selector: "app-stats",
	templateUrl: "./stats.component.html",
	styleUrls: ["./stats.component.scss"],
})
export class StatsComponent extends BaseComponent implements OnInit {
	stats: Stats | undefined;
	possibleYears = range(MINIMUM_YEAR_FOR_STATS, new Date().getFullYear());
	currentYear = this.possibleYears[0];

	// cache for values that change depending on the selected year
	cachePartialStats: { [year: number]: PartialStats } = {};
	entityID: string | undefined;
	entityType: string | undefined;
	name: Nullable<string> | undefined;

	readonly INDEX_ID = 1;
	readonly INDEX_TYPE = 2;
	readonly PATH_FULL_LENGTH = 4;

	constructor(
		private declarationService: DeclarationService,
		private representativeService: RepresentativeService,
		private declarationGroupService: DeclarationGroupService,
		private location: Location,
	) {
		super();
	}

	ngOnInit() {
		const path = this.location.path().split("/");
		if (path.length === this.PATH_FULL_LENGTH) {
			this.entityID = path[this.INDEX_ID];
			this.entityType = path[this.INDEX_TYPE];
		}

		this.sub(
			of(undefined).pipe(
				switchMap(() => {
					return this.entityType && this.entityID
						? this.declarationService.stats$({ id: this.entityID, type: this.entityType } as ResourceInfo)
						: EMPTY;
				}),
				tap((stats) => {
					this.cachePartialStats[this.currentYear] = {
						assujettiSurface: stats.assujettiSurface,
						energySum: stats.energySum,
					};
					this.stats = stats;
					// We receive kwh, and we want to show wh, so we multiply by a thousand.
					this.stats.energySum *= 1000;
				}),
				switchMap(() => {
					// if it's the stats of a specific entity, get its name to display
					if (this.entityID && this.entityID !== "all") {
						switch (this.entityType) {
							case ResourceType.Representative:
								return this.representativeService.get$(wrapId(this.entityID));
							case ResourceType.DeclarationGroup:
								return this.declarationGroupService.get$(wrapId(this.entityID));
							case ResourceType.Declaration:
								return this.declarationService.get$(wrapId(this.entityID));
							default:
								return EMPTY;
						}
					}
					return EMPTY;
				}),
			),
			(entity) => {
				this.name = entity.name;
			},
		);
	}

	loadSurfaceAndEnergySum() {
		if (!this.entityID || !this.entityType || !this.stats) {
			return;
		}

		// Pass stats to a copy and set it to undefined, so we can use the loading display
		const copyStats: Stats = this.stats;
		this.stats = undefined;

		// if year's values in cache, use them, otherwise load and store them
		this.sub(
			Object.prototype.hasOwnProperty.call(this.cachePartialStats, this.currentYear)
				? of(this.cachePartialStats[this.currentYear])
				: this.declarationService
						.stats$({ id: this.entityID, type: this.entityType } as ResourceInfo, this.currentYear)
						.pipe(
							map(({ assujettiSurface, energySum }) => ({ assujettiSurface, energySum })),
							tap((partialStats: PartialStats) => (this.cachePartialStats[this.currentYear] = partialStats)),
						),
			(partialStats) => {
				this.stats = {
					...copyStats,
					assujettiSurface: partialStats.assujettiSurface,
					energySum: partialStats.energySum * 1000,
				};
			},
		);
	}
}

export type PartialStats = {
	assujettiSurface: number;
	energySum: number;
};

export type Stats = PartialStats & {
	siteCount: number;
	perEnergyCount: { [type: string]: number };
	agreementReceivedCount: { PRM: number; PCE: number };
	irveCount: number;
	points: {
		latitude: number;
		longitude: number;
		label: string;
	}[];
	perCounty: { [county: string]: number };
	mainActivityCount: { [categoryCode: string]: number };
};
