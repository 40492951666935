import { Bullet, Circle, color, Legend, p0, p50, percent, Root, Tooltip } from "@amcharts/amcharts5";
import { IXYAxis } from "@amcharts/amcharts5/.internal/charts/xy/series/XYSeries";
import { XYChart } from "@amcharts/amcharts5/.internal/charts/xy/XYChart";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
	AxisRendererX,
	AxisRendererY,
	CategoryAxis,
	ColumnSeries,
	LineSeries,
	ValueAxis,
	XYCursor,
} from "@amcharts/amcharts5/xy";
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import FileSaver from "file-saver";
import { produce, Immutable } from "immer";
import { ReplaySubject } from "rxjs";
import { range } from "src/app/helpers/array";
import {
	CURRENT_COMPLETE_YEAR,
	getBestGoalReportFromFunctionalDeclaration,
	getCorrectionDataList,
	getHoleInConsumption,
	isHoleInConsumption,
	noCategoryForYear,
} from "src/app/helpers/conso";
import { TupleToUnion } from "src/app/helpers/tupleToUnion";
import { unwrap } from "src/app/helpers/unwrap";
import { BaseComponent } from "src/app/models/base-component.directive";
import { Declaration } from "src/app/models/declaration";
import { FunctionalDeclaration, WRONG_ABSOLUTE_OBJECTIVE } from "src/app/models/functionalDeclaration";
import { RouteDealer } from "src/app/models/routes";
import { allConsumptionsIncomplete } from "src/app/pipes/all-consumptions-incomplete.pipe";
import { dateToTimestamp } from "src/app/pipes/date-to-timestamp.pipe";
import { periodRange } from "src/app/pipes/period-range.pipe";
import { formatDataForCsv, generateCsvFromGraphData } from "../../../../../../helpers/export-graph-to-csv";
import { Nullable } from "../../../../../../helpers/nullable";
import { ObjectivesHelper } from "../../../../../../helpers/objectives";
import { GetReferenceYearForFunctionalDeclarationPipe } from "../../../../../../pipes/get-reference-year-for-fd.pipe";

interface ChartData {
	year: number;
	tertiaire: number | undefined;
	relative: number | undefined;
	absolute: number | undefined;
	total: number | undefined;
	correction: number | undefined;
	fieldMissing: string | undefined;
	noCategory: boolean;
}

const ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;
const OBJECTIVE_YEAR = ObjectivesHelper.OBJECTIVE_YEAR;

function getEmptyData(): Omit<ChartData, "year"> {
	return {
		tertiaire: 0,
		relative: 0,
		absolute: 0,
		total: 0,
		correction: 0,
		fieldMissing: undefined,
		noCategory: false,
	};
}

@Component({
	selector: "app-year-reference-data",
	templateUrl: "./year-reference-data.component.html",
	styleUrls: ["./year-reference-data.component.scss"],
})
export class YearReferenceDataComponent extends BaseComponent implements OnInit, OnChanges {
	static instanceCount = 0;
	@ViewChild("chartDiv") chartDiv!: ElementRef;
	readySubject$ = new ReplaySubject<boolean>(1);
	instance = YearReferenceDataComponent.instanceCount++;

	exportFile: { file: Blob; filename: string } | undefined;

	readonly OBJECTIVE_YEAR = OBJECTIVE_YEAR;

	readonly getObjectiveRateFromReferenceYear = ObjectivesHelper.getObjectiveRateFromReferenceYear;
	readonly getObjectiveRate = ObjectivesHelper.getObjectiveRate;

	hasInfinityValue = false;

	objectiveToShow: TupleToUnion<typeof OBJECTIVE_YEAR> = 2030;

	// Context
	@Input() functionalDeclaration!: Immutable<FunctionalDeclaration>;
	@Input() djus: { [year: string]: number } = {};
	@Input() absoluteObjectives: { [year: string]: number } = {};
	referenceYearConsumption: number | undefined;
	absoluteData: { year: number; absolute: number | undefined }[] = [];
	reportForFraction?: {
		year: number;
		total: number;
		correction: number;
	};
	readonly WRONG_ABSOLUTE_OBJECTIVE = WRONG_ABSOLUTE_OBJECTIVE;
	readonly CURRENT_COMPLETE_YEAR = CURRENT_COMPLETE_YEAR;
	// Output
	@Output() resourceChanged = new EventEmitter<Immutable<FunctionalDeclaration>>();
	// chart
	@Input() animate = true;
	@Input() chartHeight = 450;
	graphData?: {
		xAxis: IXYAxis;
		lineSeries: LineSeries;
		absoluteSeries: LineSeries;
		fixedSeries: ColumnSeries;
		missingConsumptionSeries: ColumnSeries;
	};
	private years = range(new Date(Declaration.MINIMUM_DATE).getFullYear() + 1, new Date().getFullYear());

	constructor(private router: Router) {
		super();
	}

	ngOnInit(): void {
		// Timeout now needed otherwise there is an error
		setTimeout(() => {
			const root = Root.new(`chartdiv-${this.instance}`);
			if (this.animate) {
				root.setThemes([am5themes_Animated.new(root)]);
			}

			const chart = root.container.children.push(
				XYChart.new(root, { panX: false, panY: false, wheelX: "panX", wheelY: "zoomX", layout: root.verticalLayout }),
			);

			const axisRender = AxisRendererX.new(root, { minGridDistance: 20 });
			axisRender.labels.template.setAll({
				rotation: 45,
				paddingRight: 15,
				centerY: p50,
				centerX: p0,
			});
			const xAxis = chart.xAxes.push(
				CategoryAxis.new(root, {
					renderer: axisRender,
					tooltip: Tooltip.new(root, {}),
					categoryField: "year",
				}),
			);
			xAxis.getNumberFormatter().set("numberFormat", "#");

			const yAxis = chart.yAxes.push(
				ValueAxis.new(root, {
					min: 0,
					numberFormat: "#aWh/m²",
					strictMinMax: false,
					calculateTotals: true,
					renderer: AxisRendererY.new(root, {}),
				}),
			);

			const legend = chart.children.push(Legend.new(root, {}));

			chart.appear(1000, 100);

			const fixedSeries = this.getSeries(root, xAxis, yAxis, "Consommation corrigée", 0x67b7dc, false);
			chart.series.push(fixedSeries);
			legend.data.push(fixedSeries);

			const missingConsumptionSeries = this.getSeries(root, xAxis, yAxis, "Consommation incomplète", 0xff0000, false);
			chart.series.push(missingConsumptionSeries);
			legend.data.push(missingConsumptionSeries);

			const lineSeries = chart.series.push(
				LineSeries.new(root, {
					name: "Objectif relatif",
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "relative",
					categoryXField: "year",
					stroke: color(0x365cc1),
					fill: color(0x365cc1),
					tooltip: Tooltip.new(root, {
						pointerOrientation: "horizontal",
						labelText: "{categoryX} {name} : {valueY.formatNumber('#.a')}Wh/m²",
					}),
				}),
			);

			lineSeries.strokes.template.setAll({ strokeWidth: 2 });

			lineSeries.bullets.push(() => {
				return Bullet.new(root, {
					locationY: 0.5,
					sprite: Circle.new(root, {
						radius: 5,
						stroke: lineSeries.get("stroke"),
						strokeWidth: 2,
						fill: root.interfaceColors.get("background"),
					}),
				});
			});
			lineSeries.appear();

			chart.set("cursor", XYCursor.new(root, {}));

			legend.data.push(lineSeries);

			const absoluteSeries = chart.series.push(
				LineSeries.new(root, {
					name: "Objectif absolu",
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "absolute",
					categoryXField: "year",
					stroke: color(0xff4500),
					fill: color(0xff4500),
					tooltip: Tooltip.new(root, {
						pointerOrientation: "horizontal",
						labelText: "{categoryX} {name} : {valueY.formatNumber('#.a')}Wh/m²",
					}),
				}),
			);

			absoluteSeries.strokes.template.setAll({ strokeWidth: 2, strokeDasharray: [10, 5] });

			absoluteSeries.appear();
			legend.data.push(absoluteSeries);

			this.graphData = {
				absoluteSeries,
				fixedSeries,
				lineSeries,
				missingConsumptionSeries,
				xAxis,
			};

			this.updateData(
				this.graphData,
				getCorrectionDataList(this.functionalDeclaration, this.djus, this.absoluteObjectives).find(
					({ year }) => year === this.referenceYear,
				),
			);

			setTimeout(() => {
				this.readySubject$.next(true);
				this.readySubject$.complete();
			}, 1000);
		});
	}

	generateExport(
		data: { year: number; total: number; total_surface: number; corrected: number; corrected_surface: number }[],
	) {
		if (data.length === 0) {
			return;
		}

		const csv = generateCsvFromGraphData(data);

		this.exportFile = {
			file: new Blob([csv], { type: "text/csv;charset=utf-8" }),
			filename: `${this.functionalDeclaration.name} - Consommations annuelles`,
		};
	}

	downloadExport() {
		if (this.exportFile) {
			FileSaver.saveAs(this.exportFile.file, this.exportFile.filename);
		}
	}

	selectReferenceYear(referenceYear: number) {
		this.resourceChanged.emit(
			produce(this.functionalDeclaration, (draft) => {
				draft.infos.referenceYear = referenceYear;
			}),
		);
	}

	updateData(
		graphData: NonNullable<typeof this.graphData>,
		report:
			| {
					year: number;
					correctedConsumption: number;
					total: number;
					correction: number;
			  }
			| undefined,
	) {
		const list = getCorrectionDataList(this.functionalDeclaration, this.djus, this.absoluteObjectives);

		const objective = this.absoluteObjectives[CURRENT_COMPLETE_YEAR];
		this.absoluteData = range(this.years[0], this.objectiveToShow + 1).map((year) => ({
			year,
			absolute: objective
				? objective *
					ObjectivesHelper.getObjectiveRate(OBJECTIVE_YEAR.find((objectiveYear) => year <= objectiveYear) ?? 2050)
				: undefined,
		}));
		const correctedConsoPerYear = new Map(
			list.map(({ year, correctedConsumption, correction, total, surface }) => [
				year,
				{
					correctedConsumption,
					correction,
					total,
					surface,
				},
			]),
		);

		const showData = range(this.years[0], this.objectiveToShow + 1).map((graphYear) => {
			// OBJECTIVE_YEAR is sorted so it will give the objective year just after the year.
			// For example: if year = 2021 then it will return 2030. If year = 2031 it will return 2040
			const nextObjectiveYear = OBJECTIVE_YEAR.find((objectiveYear) => graphYear <= objectiveYear) ?? 2050;
			let relative: number | undefined = undefined;
			if (report) {
				const { year: selectedYear, correctedConsumption: selectedCorrected } = report;
				if (graphYear >= selectedYear) {
					for (const [from, to] of [
						[selectedYear, 2030],
						[2030, 2040],
						[2040, 2050],
					] as const) {
						if (graphYear <= to) {
							const fromRate =
								from === 2030 || from === 2040 ? ObjectivesHelper.getObjectiveRateFromReferenceYear(from) : 1;
							const fromValue = selectedCorrected * fromRate;
							const factor = ObjectivesHelper.getObjectiveRateFromReferenceYear(to) / fromRate;
							relative = fromValue * (1 - ((1 - factor) * (graphYear - from)) / (to - from));
							break;
						}
					}
				}
			}

			const correctionData = correctedConsoPerYear.get(graphYear);

			if (correctionData && correctionData.surface === 0 && correctionData.correctedConsumption !== 0) {
				this.hasInfinityValue = true;
			}

			const { start, end } = periodRange(graphYear, this.functionalDeclaration.infos.favoritePeriod);

			return {
				fieldMissing: getHoleInConsumption(this.functionalDeclaration, dateToTimestamp(start), dateToTimestamp(end)),
				noCategory: noCategoryForYear(this.functionalDeclaration, graphYear) && (correctionData?.surface ?? 0) > 0, // only if incomplete categories but not empty
				year: graphYear,
				tertiaire: correctionData?.correctedConsumption,
				total: correctionData?.total,
				correction: correctionData?.correction,
				relative,
				surface: correctionData?.surface,
				absolute: this.absoluteObjectives[CURRENT_COMPLETE_YEAR]
					? this.absoluteObjectives[CURRENT_COMPLETE_YEAR] * ObjectivesHelper.getObjectiveRate(nextObjectiveYear)
					: undefined,
			};
		});

		this.reportForFraction = report;

		this.updateGraphData(
			graphData,
			showData,
			showData.map((data) =>
				data.fieldMissing === undefined && !data.noCategory && data.surface !== 0
					? data
					: { year: data.year, ...getEmptyData() },
			),
			showData.map((data) =>
				data.fieldMissing !== undefined || data.noCategory || data.surface === 0
					? data
					: { year: data.year, ...getEmptyData() },
			),
		);

		this.generateExport(formatDataForCsv(this.functionalDeclaration, this.djus, this.absoluteObjectives));
	}

	updateGraphData(
		{ xAxis, lineSeries, absoluteSeries, fixedSeries, missingConsumptionSeries }: NonNullable<typeof this.graphData>,
		showData: ChartData[],
		fixedData: ChartData[],
		missingData: ChartData[],
	) {
		xAxis.data.setAll(showData);
		lineSeries.data.setAll(showData);
		absoluteSeries.data.setAll(this.absoluteData);
		fixedSeries.data.setAll(fixedData);
		missingConsumptionSeries.data.setAll(missingData);
	}

	setObjectiveToShow(graphData: NonNullable<typeof this.graphData>, objective: 2030 | 2040 | 2050) {
		if (objective !== this.objectiveToShow) {
			this.objectiveToShow = objective;
			this.updateData(
				graphData,
				getCorrectionDataList(this.functionalDeclaration, this.djus, this.absoluteObjectives).find(
					({ year }) => year === this.referenceYear,
				),
			);
		}
	}

	ngOnChanges() {
		if (this.functionalDeclaration.infos.referenceYear === undefined) {
			const report = getBestGoalReportFromFunctionalDeclaration(
				this.functionalDeclaration,
				this.djus,
				this.absoluteObjectives,
			);
			if (report && !allConsumptionsIncomplete(this.functionalDeclaration)) {
				const updatedFunctionalDeclaration = produce(this.functionalDeclaration, (draft) => {
					draft.infos.referenceYear = report.referenceYear;
				});
				this.resourceChanged.emit(updatedFunctionalDeclaration);
				return;
			}
		}

		if (this.graphData) {
			this.updateData(
				this.graphData,
				getCorrectionDataList(this.functionalDeclaration, this.djus, this.absoluteObjectives).find(
					({ year }) => year === this.referenceYear,
				),
			);
		}
	}

	getSeries(
		root: Root,
		xAxis: IXYAxis,
		yAxis: IXYAxis,
		name: string,
		fillColor: number,
		displayTooltipWhen0: boolean,
	): ColumnSeries {
		const tooltip = Tooltip.new(root, {
			pointerOrientation: "horizontal",
			labelText: "{categoryX} {name} {fieldMissing} : {valueY.formatNumber('#.a')}Wh/m²",
		});

		tooltip.adapters.add("labelText", (label, target) => {
			if (target.dataItem) {
				const context = target.dataItem.dataContext as ChartData;
				if (context.fieldMissing) {
					return `Données manquantes sur la ligne de consommation "${context.fieldMissing}"`;
				}
				if (context.noCategory) {
					return `L'année ${context.year} n'est pas entièrement couverte par des surfaces d'activité.\nLes déclarations de consommations doivent impérativement couvrir 12 mois consécutifs.`;
				}
			}
			return label;
		});

		if (!displayTooltipWhen0) {
			tooltip.adapters.add("visible", (isVisible, target) => {
				const dataItem = target.dataItem;
				if (!dataItem) {
					return isVisible;
				}

				const data = dataItem.dataContext as { tertiaire: number };

				return isVisible && data.tertiaire !== 0;
			});
		}

		const series = ColumnSeries.new(root, {
			name,
			stacked: true,
			xAxis,
			yAxis,
			fill: color(fillColor),
			valueYField: "tertiaire",
			categoryXField: "year",
			tooltip,
		});

		series.columns.template.adapters.add("fill", (currentColor, data) => {
			const context = data.dataItem?.dataContext as ChartData;
			if (context.year >= 2020 && context.year < ANNUAL_DECLARATION_STARTING_YEAR && !context.fieldMissing) {
				return color(0x666666);
			}

			return currentColor;
		});

		series.appear();

		const options: { [key: string]: unknown } = {
			tooltipY: percent(10),
			cursorOverStyle: "pointer",
			cornerRadiusTR: 10,
			cornerRadiusTL: 10,
		};
		if (this.animate) {
			options["fillOpacity"] = 0.3;
			options["strokeOpacity"] = 0;
		}
		series.columns.template.setAll(options);

		series.columns.template.set("interactive", true);

		series.columns.template.adapters.add("fillOpacity", (opacity, target) => {
			const dataItem = target.dataItem;
			if (!dataItem) {
				return opacity;
			}

			const data = dataItem.dataContext as { year: number };
			return data.year === this.referenceYear ? 1 : opacity;
		});

		series.columns.template.events.on("click", (ev) => {
			const data = ev.target.dataItem?.dataContext as { year: number; surface: Nullable<number> };
			if (data) {
				const period = periodRange(data.year, this.functionalDeclaration.infos.favoritePeriod);

				if (
					isHoleInConsumption(this.functionalDeclaration, dateToTimestamp(period.start), dateToTimestamp(period.end))
				) {
					this.router.navigate(
						RouteDealer.consoTable(unwrap(this.functionalDeclaration[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID])),
						{
							queryParams: { year: data.year },
						},
					);
					return;
				} else if (data.surface === 0 || noCategoryForYear(this.functionalDeclaration, data.year)) {
					this.router.navigate(
						RouteDealer.assetDeclaration(unwrap(this.functionalDeclaration[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID])),
					);
				} else if (this.graphData && data.year < 2020 && this.functionalDeclaration.is_token_used) {
					this.selectReferenceYear(data.year);
				}
			}
		});
		series.columns.template.events.on("pointerover", (ev) => {
			const data = ev.target.dataItem?.dataContext as ChartData | undefined;
			if (data && data.tertiaire !== undefined && data.total !== undefined && data.correction !== undefined) {
				this.reportForFraction = { correction: data.correction, total: data.total, year: data.year };
			}
		});
		series.columns.template.events.on("pointerout", () => {
			this.reportForFraction = getCorrectionDataList(
				this.functionalDeclaration,
				this.djus,
				this.absoluteObjectives,
			).find(({ year }) => year === this.referenceYear);
		});

		return series;
	}

	getEnergySaving(): { raw: number; percent: number; years: string } | undefined {
		const referenceYear = this.referenceYear;
		if (!referenceYear) return undefined;

		const correctionDataList = getCorrectionDataList(this.functionalDeclaration, this.djus, this.absoluteObjectives);

		const referenceYearData = correctionDataList.find(({ year }) => year === referenceYear);
		let currentYear = CURRENT_COMPLETE_YEAR + 1;
		let currentCompleteYearData;
		do {
			currentYear--;
			currentCompleteYearData = correctionDataList.find(({ year }) => year === currentYear);
		} while (
			(!currentCompleteYearData || currentCompleteYearData.isIncomplete) &&
			currentYear > Declaration.MINIMUM_YEAR
		);

		if (!referenceYearData || !currentCompleteYearData) {
			return undefined;
		}

		const diff = currentCompleteYearData.correctedConsumption - referenceYearData.correctedConsumption;
		return {
			raw: diff,
			percent: Math.round((diff / referenceYearData.correctedConsumption) * 100),
			years: `${referenceYear} -> ${currentYear}`,
		};
	}

	/**
	 * Get the referenceYear of the declaration
	 * If no referenceYear is defined, select the first completed year after 2021
	 */
	get referenceYear() {
		const getReferenceYear = new GetReferenceYearForFunctionalDeclarationPipe();
		return getReferenceYear.transform(this.functionalDeclaration, this.djus, this.absoluteObjectives);
	}

	getCurrentAbsoluteObjective = (absoluteObjectives: { [year: number]: number }) => {
		return ObjectivesHelper.getCurrentAbsoluteObjective(absoluteObjectives, CURRENT_COMPLETE_YEAR);
	};
}
