import { Component } from "@angular/core";
import { Immutable } from "immer";
import { Declaration } from "src/app/models/declaration";
import { Lazy } from "src/app/models/lazy";
import { Modal } from "src/app/models/modal";
import { Etablissement } from "src/app/models/siren-api.types";
import { isEfaLinkedToEtablissement } from "../siren-form.component";
import { FunctionalDeclarationId } from "src/app/models/ids";

type Input = Immutable<{
	etablissement: Etablissement;
	declaration: Lazy<Declaration>;
}>;
type State = {
	description: string;
	affectedIds: FunctionalDeclarationId[];
};
type Output = { description: string; affectedIds: FunctionalDeclarationId[] } | undefined;

@Component({
	selector: "app-description-edit-modal",
	templateUrl: "./description-edit-modal.component.html",
	styleUrls: ["./description-edit-modal.component.scss"],
})
export class DescriptionEditModalComponent extends Modal<Input, State, Output> {
	inputToState({ declaration, etablissement }: Input): State {
		const affectedIds = declaration.declarations_functional
			.filter((fd) => isEfaLinkedToEtablissement(fd, etablissement))
			.flatMap(({ declaration_functional_id: id }) => (id ? [id] : []));

		const [functionalDeclaration] = etablissement.functionalEntities;

		return { affectedIds, description: functionalDeclaration.description ?? "" };
	}

	save() {
		this.close(this.state);
	}
}
