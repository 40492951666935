import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "asAutre",
})
export class AsAutrePipe implements PipeTransform {
	transform<T>(value: { autre: string } | number | string | T): { autre: string } | undefined {
		return value &&
			typeof value !== "string" &&
			typeof value !== "number" &&
			typeof value === "object" &&
			"autre" in value
			? value
			: undefined;
	}
}
