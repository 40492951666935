import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { ActivityCategoryEntry } from "../models/asset";
import { AddBlock } from "./sort-activity-categories-per-start.pipe";

@Pipe({
	name: "isActivityCategory",
})
export class IsActivityCategoryPipe implements PipeTransform {
	transform(toTest: Immutable<ActivityCategoryEntry | AddBlock>): Immutable<ActivityCategoryEntry> | undefined {
		return "temperatureModifiers" in toTest ? toTest : undefined;
	}
}
