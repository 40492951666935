import { Component, EventEmitter, Input, Output } from "@angular/core";
import { z, ZodNullable, ZodNumber, ZodOptional } from "zod";
import { Nullable } from "../../../../helpers/nullable";
import { Declaration } from "../../../../models/declaration";
import { HelpSubject } from "../../../help/help.component";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

const TODAY = new Date().getFullYear();

@Component({
	selector: "app-newly-liable-input",
	templateUrl: "./newly-liable-input.component.html",
	styleUrls: ["./newly-liable-input.component.scss"],
})
export class NewlyLiableInputComponent {
	@Input() isNewlyLiable: boolean = false;
	@Input() firstYearDeclaration: Nullable<number>;
	@Input() disabled: boolean = false;
	@Input() hidden: boolean = false;
	@Output() isNewlyLiableChange = new EventEmitter<boolean>();
	@Output() firstYearDeclarationChange = new EventEmitter<Nullable<number>>();

	zodCheckFirstYear: ZodOptional<ZodNullable<ZodNumber>>;

	constructor() {
		this.zodCheckFirstYear = z
			.number()
			.min(ANNUAL_DECLARATION_STARTING_YEAR, {
				message: `Vous devez choisir une année supérieur a ${ANNUAL_DECLARATION_STARTING_YEAR}`,
			})
			.max(TODAY, { message: "Vous ne pouvez pas être assujetti dans le futur !" })
			.nullish();
	}

	isValid() {
		return (
			!this.isNewlyLiable ||
			(this.firstYearDeclaration && this.zodCheckFirstYear.safeParse(this.firstYearDeclaration).success)
		);
	}

	protected readonly Declaration = Declaration;
	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
	protected readonly TODAY = TODAY;
	protected readonly HelpSubject = HelpSubject;
}
