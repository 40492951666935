<div>
	<strong>{{ label }}</strong>
	<ng-select [(ngModel)]="choice" (ngModelChange)="choiceChange.emit(choice)">
		<ng-option *ngFor="let choice of choices" [value]="choice">
			<span class="option" [title]="choice | apply: choiceToString">
				{{ choice | apply: choiceToString }}
			</span>
		</ng-option>
	</ng-select>
</div>
