import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "fileSize",
})
export class FileSizePipe implements PipeTransform {
	transform(size: number): string {
		if (size < 1000) {
			return `${size} o`;
		}

		if (size < 1000 * 1000) {
			return `${Math.round(size / 100) / 10} ko`;
		}

		return `${Math.round(size / 100000) / 10} Mo`;
	}
}
