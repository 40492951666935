<ng-container *ngIf="(declarationState.get$ | async)?.value as declaration; else noDeclaration">
	<div class="header-actions">
		<div class="d-flex mb-1" style="justify-content: space-between; align-items: center">
			<span class="title"
				>{{ pageInfo$.value.index + 1 }}. {{ pageInfo$.value.page.label }}
				<app-help *ngIf="pageInfo$.value.page.help" [subject]="pageInfo$.value.page.help"></app-help>
			</span>

			<div class="d-flex gap-1">
				<ng-container *ngIf="(groupState.get$ | async)?.value as group">
					<ng-container *ngIf="functionalDeclarationState.get$ | async as functionalDeclaration">
						<button
							*ngIf="pageInfo$.value.page !== QUALIFICATION_PAGE && functionalDeclaration.value"
							class="btn-primary"
							(click)="fillWithOperatApi(declaration, functionalDeclaration.value)"
						>
							<mat-icon class="mr-1">sync</mat-icon>
							Compléter depuis OPERAT
						</button>
					</ng-container>
					<app-token-button
						*ngIf="(representativeState.get$ | async)?.value as representative"
						[usedToken]="group.used_tokens"
						[totalTokens]="group.tokens"
						[usedAnnualTokens]="group.used_annual_tokens"
						[annualTokens]="group.annual_tokens"
						classes="btn-primary"
						type="client"
						(buttonClick)="openTransferDialog(group, representative)"
					>
					</app-token-button>
					<button
						*ngIf="userState.user?.is_temporary === false"
						mat-flat-button
						class="btn-accent"
						(click)="invite(group, declaration.declarations_functional[step.value], userState.user?.user_id ?? '')"
					>
						<mat-icon>link</mat-icon>
						Partager
					</button>
				</ng-container>

				<button class="btn-primary" (click)="startGuide()" *ngIf="tourStateService.hasTour$ | async">
					<mat-icon class="mr-1">swipe_up</mat-icon>
					Guide interactif
				</button>
			</div>
		</div>
	</div>

	<router-outlet></router-outlet>

	<button
		*ngIf="step.value < declaration.declarations_functional.length - 1"
		mat-flat-button
		class="btn-accent3"
		style="margin-right: 10px"
		(click)="next(declaration.declarations_functional, pageInfo$.value.segment)"
	>
		Etablissement suivant
	</button>
	<ng-container
		*ngIf="(functionalDeclarationState.get$ | async)?.value?.declaration_functional_id as functionalDeclarationId"
	>
		<button
			*ngIf="step.value === declaration.declarations_functional.length - 1"
			mat-flat-button
			class="btn-accent3"
			style="margin-right: 10px"
			(click)="nextStep(functionalDeclarationId, pageInfo$.value.index)"
		>
			Étape suivante
		</button>
	</ng-container>
</ng-container>
<ng-template #noDeclaration>
	<ng-container *ngIf="(functionalDeclarationState.get$ | async)?.value as functionalDeclaration">
		<router-outlet></router-outlet>
		<button
			*ngIf="pageInfo$.value.index !== pages.length - 1"
			mat-flat-button
			class="btn-accent3"
			style="margin-right: 10px"
			(click)="nextStep(functionalDeclaration.declaration_functional_id ?? '', pageInfo$.value.index)"
		>
			Étape suivante
		</button>
	</ng-container>
</ng-template>
