import { Immutable } from "immer";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { DateToTimestampPipe } from "../pipes/date-to-timestamp.pipe";
import { PeriodRangePipe } from "../pipes/period-range.pipe";

const periodRange = new PeriodRangePipe();
const dateToTimestamp = new DateToTimestampPipe();

function getMinDateForNewlyLiableEntity(functionalDeclaration: Immutable<FunctionalDeclaration>, date: number) {
	if (!functionalDeclaration.first_year_declaration) {
		return date;
	}

	const { start } = periodRange.transform(
		functionalDeclaration.first_year_declaration,
		functionalDeclaration.infos.favoritePeriod,
	);
	const startTimestamp = dateToTimestamp.transform(start);

	return Math.max(startTimestamp, date);
}

export const MinDateWithDateStartEntity = {
	getMinDateForNewlyLiableEntity,
};
