import { Component } from "@angular/core";
import { Route } from "src/app/models/routes";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserStateService } from "src/app/services/user-state.service";
import { BaseComponent } from "../../models/base-component.directive";
import { AuthorizationViewLoaderService } from "../../services/authorization-view-loader.service";

@Component({
	selector: "app-login",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent extends BaseComponent {
	public loading: boolean = false;
	public email: string = "";
	public errorEmail: boolean = false;
	public sent: boolean = false;
	Route = Route;

	constructor(
		private authentication: AuthenticationService,
		userState: UserStateService,
		private authorizationViewLoader: AuthorizationViewLoaderService,
	) {
		super();
		if (userState.user) {
			this.authorizationViewLoader.loadModal(true);
		}
	}

	public resetError(): void {
		this.errorEmail = false;
	}

	public testCredentials(): void {
		this.errorEmail = this.email.length === 0;
		if (!this.errorEmail) {
			this.formSubmit();
		}
	}

	formSubmit(): void {
		this.loading = true;
		this.authentication.forgotPassword$(this.email).subscribe({
			next: () => {
				this.loading = false;
				this.sent = true;
			},
			error: () => {
				this.loading = false;
			},
		});
	}
}
