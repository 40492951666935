import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Declaration } from "../models/declaration";
import { DeclarationId } from "../models/ids";
import { Isolation } from "../models/isolation";
import { Lazy } from "../models/lazy";
import { DeclarationService } from "./declaration.service";

@Injectable({
	providedIn: "root",
})
export class DeclarationIsolationService extends Isolation<Lazy<Declaration>> {
	constructor(private declaration: DeclarationService) {
		super();
	}

	getIdFromResource(resource: Immutable<Lazy<Declaration>>): DeclarationId {
		const id = resource.declaration_id;

		if (id) {
			return id;
		}

		throw new Error("Resource without ID.");
	}

	fetchResource$(id: DeclarationId): Observable<Immutable<Lazy<Declaration>>> {
		return this.declaration.get$(id).pipe(map(Declaration.fromLazyApi));
	}

	saveResourceToServer$(resource: Immutable<Lazy<Declaration>>): Observable<Immutable<Lazy<Declaration>>> {
		return this.declaration.save$(Declaration.toLazyApi(resource)).pipe(map(Declaration.fromLazyApi));
	}
}
