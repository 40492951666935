import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Immutable } from "immer";
import { ZodType } from "zod";
import { range } from "../../../../../helpers/array";
import { noCategoryForYear } from "../../../../../helpers/conso";
import {
	getEntitiesWithoutReferenceYear,
	getFunctionalDeclarationsSelected,
	getIncompleteFilterForReference,
	getIncompleteFilterFunction,
} from "../../../../../helpers/csv-conso-helpers";
import { Nullable } from "../../../../../helpers/nullable";
import { unwrap } from "../../../../../helpers/unwrap";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { Declaration } from "../../../../../models/declaration";
import { DeclarationGroup } from "../../../../../models/declarationGroup";
import { getOperatReference } from "../../../../../models/efaStatus";
import { FunctionalDeclaration } from "../../../../../models/functionalDeclaration";
import { DeclarationId } from "../../../../../models/ids";
import { Lazy } from "../../../../../models/lazy";
import { dialogOpen } from "../../../../../models/modal";
import { EntitiesNotConcernedByYearPipe } from "../../../../../pipes/entities-not-concerned-by-year.pipe";
import { LockedFunctionalDeclarationsForYearPipe } from "../../../../../pipes/locked-functional-declarations-for-year";
import { DeclarationGroupStateService } from "../../../../../services/declaration-group-state.service";
import { OperatApiService } from "../../../../../services/operat-api.service";
import { DialogComponent, HelpSubject } from "../../../../help/help.component";
import { ApiErrorsModalComponent, OperatErrorAndEntities } from "./api-errors-modal/api-errors-modal.component";
import { PartialApiUploadComponent } from "./partial-api-upload/partial-api-upload.component";
import { z } from "zod";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

export type ConsoState = "COMPLETE" | "PARTIAL" | "EMPTY" | "LOCKED" | "NO DECLARATION";

const DTApiErrorConsoNoCategorie = "No consumption to upload";

@Component({
	selector: "app-operat-api-interactions",
	templateUrl: "./operat-api-interactions.component.html",
	styleUrls: ["./operat-api-interactions.component.scss"],
})
export class OperatApiInteractionsComponent extends BaseComponent implements AfterViewInit {
	@Input() declaration!: Declaration;
	@Output() updateDeclaration = new EventEmitter<Declaration>();
	@Output() openLockedModal = new EventEmitter<boolean>();
	quotaLimitReached = false;

	isEditing = false;

	userKey: Nullable<string>;
	userKeyZod: ZodType;

	years: Immutable<number[]> = range(2020, new Date().getFullYear()).reverse();

	@ViewChild("noKey") noKeyModal!: TemplateRef<MatDialog>;

	constructor(
		private snackbar: MatSnackBar,
		private dialog: MatDialog,
		private operatService: OperatApiService,
		public groupState: DeclarationGroupStateService,
	) {
		super();
		this.userKeyZod = z.string().uuid({ message: "Format de la clé invalide" }).nullish();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		// Allow edit if user has access to declarationGroup
		this.sub(this.groupState.get$, () => {
			this.isEditing = !this.declaration.operat_user_key;
		});
	}

	scrollTo(year: number) {
		const stepErrorDiv = document.getElementById(`${year}incomplete`);

		if (stepErrorDiv) {
			stepErrorDiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		}
	}

	get censoredUserKey() {
		//replace everything except '-' by bullets
		// eslint-disable-next-line no-useless-escape
		return (this.declaration?.operat_user_key ?? "").replace(/[^\-]/g, "\u2022");
	}

	copyKeyToClipboard(declarationGroup: Nullable<Immutable<Lazy<DeclarationGroup>>>) {
		if (declarationGroup && this.declaration.operat_user_key) {
			navigator.clipboard
				.writeText(this.declaration.operat_user_key)
				.then(() => this.snackbar.open("Clé copiée dans le presse-papier", "OK", { duration: 1000 }));
		}
	}

	startEditing() {
		this.userKey = this.declaration.operat_user_key;
		this.isEditing = true;
	}

	saveUserKey() {
		this.declaration.operat_user_key = this.userKey?.trim();
		this.updateDeclaration.emit(this.declaration);
		this.isEditing = false;
	}

	openLockedModalFunction(isInitial = true) {
		this.openLockedModal.emit(isInitial);
	}

	openPartialUpload(
		selectedFunctionalDeclarations: Immutable<FunctionalDeclaration[]>,
		year: Nullable<number>,
		declarationId: Nullable<DeclarationId>,
	) {
		dialogOpen(
			this.dialog,
			PartialApiUploadComponent,
			{ functionalDeclarations: selectedFunctionalDeclarations, year: year },
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (output) {
					this.uploadForYear(declarationId, year ?? "REFERENCE");
				}
			});
	}

	uploadForYear(declarationId: Nullable<DeclarationId>, year: number | "REFERENCE") {
		if (!this.declaration.operat_user_key) {
			this.dialog.open(this.noKeyModal);
			return;
		}

		this.operatService.uploadConsumptionsForDeclarationAndYear(unwrap(declarationId), `${year}`).subscribe((errors) => {
			const yearToDisplay = year === "REFERENCE" ? "l'année de référence" : year;
			// If there is errors, format it and open errors modal
			if (errors && errors.length > 0) {
				let formatedErrors: OperatErrorAndEntities[];
				try {
					const toJson: { [key: string]: string[] } = JSON.parse(errors);
					// Using Set to remove duplicate error codes
					const errorCodes: string[] = [...new Set(Object.values(toJson).flat())];
					formatedErrors = errorCodes.map((code) => {
						return {
							code: code,
							// Get all reference in the request result and check if the error code is associated to them
							concernedEntities: Object.keys(toJson)
								.filter((ref) => toJson[ref].includes(code))
								.map((ref) => {
									const efa = this.getEFANameFromOperatRef(ref);
									return { ref, name: efa?.name, id: efa?.declaration_functional_id };
								}),
						};
					});
				} catch {
					// Since both DT and OPERAT API use 400 errors with custom messages, no choice but to compare error message directly
					if (errors === DTApiErrorConsoNoCategorie) {
						this.snackbar.open(`Aucune données de consommation à envoyer pour ${yearToDisplay}`, "OK", {});
						return;
					}
					// Not an object => unique error code from auth
					formatedErrors = [{ code: errors, concernedEntities: [] }];
				}

				dialogOpen(
					this.dialog,
					ApiErrorsModalComponent,
					{ errors: formatedErrors, year: `${year}` },
					{ panelClass: "p-0" },
				);
			} else {
				// Else, inform success with snackbar
				this.snackbar.open(
					`Les déclarations de consommations pour ${yearToDisplay} ont été envoyées avec succès`,
					"OK",
					{},
				);
			}
		});
	}

	getConsoStateForYear(year: number | "REFERENCE") {
		const lockedFunctionalDeclarationForYear = new LockedFunctionalDeclarationsForYearPipe();
		const entitiesNotConcernedByYear = new EntitiesNotConcernedByYearPipe();
		return (functionalDeclarations: Immutable<FunctionalDeclaration[]>): ConsoState => {
			const yearToUseIfReference = year === "REFERENCE" ? ANNUAL_DECLARATION_STARTING_YEAR - 1 : year;

			// entities locked for year or imported and not bought
			const lockedFunctionalDeclarations =
				year === "REFERENCE"
					? functionalDeclarations.filter((fd) => !fd.is_token_used && fd.is_imported_from_operat)
					: lockedFunctionalDeclarationForYear.transform(functionalDeclarations, year);

			// entities with incomplete consumptions
			const incompletesFunctionalDeclarations =
				year === "REFERENCE"
					? getIncompleteFilterForReference(functionalDeclarations)
					: getIncompleteFilterFunction(year)(functionalDeclarations);

			const numberOfEntitiesNotConcerned = entitiesNotConcernedByYear.transform(
				functionalDeclarations,
				yearToUseIfReference,
			);

			// entities concerned by year, not locked and that have asset categories for year
			const availableFunctionalDeclarations = functionalDeclarations.filter(
				(functionalDeclaration) =>
					!incompletesFunctionalDeclarations.includes(functionalDeclaration) &&
					!lockedFunctionalDeclarations.includes(functionalDeclaration) &&
					(!functionalDeclaration.first_year_declaration ||
						yearToUseIfReference >= functionalDeclaration.first_year_declaration) &&
					!noCategoryForYear(functionalDeclaration, year),
			);

			// There's no entity
			if (functionalDeclarations.length === 0) {
				return "EMPTY";
			}

			// If no entities are liable during this year
			if (numberOfEntitiesNotConcerned === functionalDeclarations.length) {
				return "NO DECLARATION";
			}

			// If no entity available
			if (availableFunctionalDeclarations.length === 0) {
				// If they're all locked
				if (lockedFunctionalDeclarations.length === functionalDeclarations.length) {
					return "LOCKED";
				}

				// Else empty
				return "EMPTY";
			}

			// Not all entities are available
			if (lockedFunctionalDeclarations.length > 0) {
				return "PARTIAL";
			}

			// all entities are available
			return "COMPLETE";
		};
	}

	protected readonly HelpSubject = HelpSubject;
	protected readonly getFunctionalDeclarationsSelected = getFunctionalDeclarationsSelected;
	protected readonly getIncompleteFilterFunction = getIncompleteFilterFunction;
	protected readonly getEntitiesWithoutReferenceYear = getEntitiesWithoutReferenceYear;

	openLimitedQuotaModal() {
		dialogOpen(this.dialog, DialogComponent, { subject: HelpSubject.OperatQuota, argument: "" }, { panelClass: "p-0" });
	}

	getEFANameFromOperatRef(operatRef: string): Nullable<Immutable<FunctionalDeclaration>> {
		return this.declaration.declarations_functional.find(
			(efa) => getOperatReference(efa, this.declaration) === operatRef,
		);
	}

	protected readonly Declaration = Declaration;
	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
}
