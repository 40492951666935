import { Component } from "@angular/core";
import { Immutable } from "immer";
import { dialogOpen, Modal } from "src/app/models/modal";

type Input = Immutable<{ title: string; description: string; descriptionTwice?: string }>;
type State = Input;
type Output = boolean;

@Component({
	selector: "app-confirmation-modal",
	templateUrl: "./confirmation-modal.component.html",
	styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent extends Modal<Input, State, Output> {
	inputToState(input: Input): State {
		return input;
	}

	newModal(description: string) {
		dialogOpen(this.dialog, ConfirmationModalComponent, { title: "Attention", description }, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((confirm) => this.close(confirm ?? false));
	}
}
