import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "apply",
})
export class ApplyPipe implements PipeTransform {
	transform<T, U>(value: T, map: (value: T) => U): U {
		return map(value);
	}
}
