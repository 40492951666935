import { Component, Inject, TemplateRef, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Immutable } from "immer";
import { map, switchMap, tap } from "rxjs/operators";
import { cloneLazyDeclarationGroup, DeclarationGroup } from "src/app/models/declarationGroup";
import { RepresentativeId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { cloneLazyRepresentative, Representative } from "src/app/models/representative";
import { DeclarationGroupStateService } from "src/app/services/declaration-group-state.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { RepresentativeStateService } from "src/app/services/representative-state.service";
import { RepresentativeService } from "src/app/services/representative.service";

@Component({
	selector: "app-token-transfer-modal",
	templateUrl: "./token-transfer-modal.component.html",
	styleUrls: ["./token-transfer-modal.component.scss"],
})
export class TokenTransferModalComponent {
	representative: Lazy<Representative>;
	declarationGroup: Lazy<DeclarationGroup>;

	representativeTokensRemaining: number;
	representativeAnnualTokensRemaining: number;
	declarationGroupTokensRemaining: number;
	declarationGroupAnnualTokensRemaining: number;
	tokenToTransfer = 0;
	annualTokenToTransfer = 0;

	showWarning: boolean;
	showWarningAnnual: boolean;

	@ViewChild("modalTokenExplanation", { static: true }) modalTokenExplanation!: TemplateRef<MatDialog>;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			declarationGroup: Immutable<Lazy<DeclarationGroup>>;
			representative: Immutable<Lazy<Representative>>;
			showWarning?: boolean;
			showWarningAnnual?: boolean;
		},
		private dialogRef: MatDialogRef<TokenTransferModalComponent>,
		private dialog: MatDialog,
		private representativeService: RepresentativeService,
		private representativeState: RepresentativeStateService,
		private groupState: DeclarationGroupStateService,
		private declarationState: DeclarationStateService,
		public matDialog: MatDialog,
	) {
		this.showWarning = data.showWarning ?? false;
		this.showWarningAnnual = data.showWarningAnnual ?? false;
		this.representative = cloneLazyRepresentative(data.representative);
		this.representativeTokensRemaining = this.representative.tokens - this.representative.used_tokens;
		this.representativeAnnualTokensRemaining =
			this.representative.annual_tokens - this.representative.used_annual_tokens;
		this.declarationGroup = cloneLazyDeclarationGroup(data.declarationGroup);
		this.declarationGroupTokensRemaining = this.declarationGroup.tokens - this.declarationGroup.used_tokens;
		this.declarationGroupAnnualTokensRemaining =
			this.declarationGroup.annual_tokens - this.declarationGroup.used_annual_tokens;
	}

	setTokenFromRepresentative() {
		this.tokenToTransfer =
			this.representativeTokensRemaining -
			((this.representative?.tokens ?? 0) - (this.representative?.used_tokens ?? 0));
		this.setTokens();
	}

	setAnnualTokenFromRepresentative() {
		this.annualTokenToTransfer =
			this.representativeAnnualTokensRemaining -
			((this.representative?.annual_tokens ?? 0) - (this.representative?.used_annual_tokens ?? 0));
		this.setTokens();
	}

	setTokenFromDeclarationGroup() {
		this.tokenToTransfer =
			this.declarationGroupTokensRemaining -
			((this.declarationGroup?.tokens ?? 0) - (this.declarationGroup?.used_tokens ?? 0));
		this.setTokens();
	}

	setAnnualTokenFromDeclarationGroup() {
		this.annualTokenToTransfer =
			this.declarationGroupAnnualTokensRemaining -
			((this.declarationGroup?.annual_tokens ?? 0) - (this.declarationGroup?.used_annual_tokens ?? 0));
		this.setTokens();
	}

	setTokens() {
		this.representativeTokensRemaining =
			(this.representative?.tokens ?? 0) - (this.representative?.used_tokens ?? 0) - this.tokenToTransfer;
		this.declarationGroupTokensRemaining =
			(this.declarationGroup?.tokens ?? 0) - (this.declarationGroup?.used_tokens ?? 0) + this.tokenToTransfer;

		this.representativeAnnualTokensRemaining =
			(this.representative?.annual_tokens ?? 0) -
			(this.representative?.used_annual_tokens ?? 0) -
			this.annualTokenToTransfer;
		this.declarationGroupAnnualTokensRemaining =
			(this.declarationGroup?.annual_tokens ?? 0) -
			(this.declarationGroup?.used_annual_tokens ?? 0) +
			this.annualTokenToTransfer;
	}

	transfer(representativeId: RepresentativeId) {
		const groupId = this.groupState.getId();
		const declarationId = this.declarationState.getId();

		if (!groupId || !declarationId) {
			throw new Error("Impossible: Empty group and declaration state.");
		}

		this.representativeService
			// The representative retrieve only contains the group that has been edited
			.transferToken$(representativeId, groupId, this.tokenToTransfer, this.annualTokenToTransfer)
			.pipe(
				map(Representative.fromLazyApi),
				map((representative) => {
					const [newGroup] = representative.declaration_groups;
					representative.declaration_groups = this.representative.declaration_groups.map((group) => {
						if (newGroup.declaration_group_id === group.declaration_group_id) {
							newGroup.declarations = group.declarations;
							return newGroup;
						}
						return group;
					});
					return representative;
				}),
				switchMap((representative) => this.representativeState.update$(representative)),
				// To reload everything
				tap(() => {
					this.groupState.clearCache();
					this.declarationState.clearCache();
					this.dialogRef.close();
				}),
				switchMap(() => this.groupState.select$(groupId)),
				switchMap(() => this.declarationState.select$(declarationId)),
			)
			.subscribe(() => {
				this.dialogRef.close();
			});
	}

	openTokenExplanationModal() {
		this.matDialog.open(this.modalTokenExplanation);
	}
}
