import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { forkJoin, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthorizationViewComponent } from "../components/authorization-view/authorization-view.component";
import { unwrap } from "../helpers/unwrap";
import { Declaration } from "../models/declaration";
import { DeclarationGroup } from "../models/declarationGroup";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId, RepresentativeId } from "../models/ids";
import { Lazy } from "../models/lazy";
import { Representative } from "../models/representative";
import { ResourceType } from "../models/resource";
import { RouteDealer } from "../models/routes";
import { AuthorizationService } from "./authorization.service";
import { DeclarationFunctionalService } from "./declaration-functional.service";
import { DeclarationGroupService } from "./declaration-group.service";
import { DeclarationService } from "./declaration.service";
import { InitStateService } from "./init-state.service";
import { RepresentativeService } from "./representative.service";

@Injectable({
	providedIn: "root",
})
export class AuthorizationViewLoaderService {
	authRepresentatives: Lazy<Representative>[] = [];
	authGroups: Lazy<DeclarationGroup>[] = [];
	authDeclarations: Lazy<Declaration>[] = [];
	authEntities: FunctionalDeclaration[] = [];
	loading = false;

	constructor(
		private dialog: MatDialog,
		private authorizationService: AuthorizationService,
		private functionalService: DeclarationFunctionalService,
		private router: Router,
		private initService: InitStateService,
		private declarationService: DeclarationService,
		private groupService: DeclarationGroupService,
		private representativeService: RepresentativeService,
	) {}

	loadModal(autoSelect: boolean, resetAuthorization = false) {
		if (resetAuthorization) {
			this.authRepresentatives = [];
			this.authGroups = [];
			this.authDeclarations = [];
			this.authEntities = [];
		}
		if (!this.loading) {
			this.loading = true;
			this.feedAuthorizations$().subscribe(() => {
				if (autoSelect) {
					if (this.authRepresentatives.length !== 0) {
						this.loading = false;
						this.selectRepresentative(this.authRepresentatives[0].representative_id);
						return;
					} else {
						if (this.authGroups.length !== 0) {
							this.loading = false;
							this.selectGroup(unwrap(this.authGroups[0].declaration_group_id));
							return;
						} else {
							if (this.authDeclarations.length !== 0) {
								this.loading = false;
								this.selectDeclaration(unwrap(this.authDeclarations[0].declaration_id));
								return;
							} else {
								if (this.authEntities.length !== 0) {
									this.loading = false;
									this.selectFunctionalDeclaration(unwrap(this.authEntities[0].declaration_functional_id));
									return;
								}
							}
						}
					}
				}
				this.loading = false;
				this.dialog.open(AuthorizationViewComponent, {
					data: {
						selectAuto: autoSelect,
						authRepresentatives: this.authRepresentatives,
						authGroups: this.authGroups,
						authDeclarations: this.authDeclarations,
						authEntities: this.authEntities,
					},
					// width: "700px",
					// height: "50%",
				});
			});
		}
	}

	feedAuthorizations$(): Observable<unknown> {
		if (
			this.authRepresentatives.length === 0 &&
			this.authGroups.length === 0 &&
			this.authDeclarations.length === 0 &&
			this.authEntities.length === 0
		) {
			this.authRepresentatives = [];
			this.authGroups = [];
			this.authDeclarations = [];
			this.authEntities = [];
			return this.authorizationService.get$().pipe(
				switchMap((authorizations) => {
					const observables = authorizations.map((authorization) => {
						const resourceInfo = authorization.resourceInfo;
						switch (resourceInfo.type) {
							case ResourceType.FunctionalDeclaration:
								return this.functionalService.get$(resourceInfo.id).pipe(
									map(FunctionalDeclaration.fromApi),
									tap((fd) => {
										if (
											!this.authEntities.find(
												(searchEntity) =>
													searchEntity[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID] ===
													fd[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID],
											)
										) {
											this.authEntities.push(fd);
										}
									}),
								);
							case ResourceType.Declaration:
								return this.declarationService.get$(resourceInfo.id).pipe(
									map(Declaration.fromLazyApi),
									tap((declaration) => {
										if (
											!this.authDeclarations.find(
												(searchDeclaration) => searchDeclaration.declaration_id === declaration.declaration_id,
											)
										) {
											this.authDeclarations.push(declaration);
										}
									}),
								);
							case ResourceType.DeclarationGroup:
								return this.groupService.get$(resourceInfo.id).pipe(
									map(DeclarationGroup.fromLazyApi),
									tap((group) => {
										if (
											!this.authGroups.find(
												(searchGroup) => searchGroup.declaration_group_id === group.declaration_group_id,
											)
										) {
											this.authGroups.push(group);
										}
									}),
								);
							case ResourceType.Representative:
								return this.representativeService.get$(resourceInfo.id, true).pipe(
									map(Representative.fromLazyApi),
									tap((representative) => {
										if (
											!this.authRepresentatives.find(
												(searchRepresentative) =>
													searchRepresentative.representative_id === representative.representative_id,
											)
										) {
											this.authRepresentatives.push(representative);
										}
									}),
								);
						}
					});

					return forkJoin(observables);
				}),
			);
		}
		return of({});
	}

	selectFunctionalDeclaration(id: FunctionalDeclarationId) {
		this.initService
			.initFunctionalDeclaration$(id)
			.subscribe(() => this.router.navigate(RouteDealer.qualification(id)));
	}

	selectDeclaration(id: DeclarationId) {
		this.initService.initDeclaration$(id).subscribe(() => this.router.navigate(RouteDealer.myDeclaration(id)));
	}

	selectGroup(id: DeclarationGroupId) {
		this.initService.initGroup$(id).subscribe(() => this.router.navigate(RouteDealer.myDeclaration(id)));
	}

	selectRepresentative(id: RepresentativeId) {
		this.initService.initRepresentative$(id).subscribe(() => this.router.navigate(RouteDealer.myDeclaration(id)));
	}
}
