import { Component, Input, OnChanges } from "@angular/core";
import { ThemePalette } from "@angular/material/core";

@Component({
	selector: "app-progress-percentage",
	templateUrl: "./progress-percentage.component.html",
	styleUrls: ["./progress-percentage.component.scss"],
})
export class ProgressPercentageComponent implements OnChanges {
	@Input() color: ThemePalette = "primary";
	@Input() textColor: string = "text-gray";
	@Input() value: number = 0;
	@Input() max: number = 100;
	@Input() percentage: number = 0;

	showPercentage: number = 0;

	ngOnChanges() {
		this.showPercentage = Math.max((this.value * 100) / this.max, this.percentage);
	}
}
