import { Component } from "@angular/core";
import { Immutable } from "immer";
import { DeclarationsCreationComponent } from "src/app/components/declarations-creation/declarations-creation.component";
import { Siren, Rna, Siret } from "src/app/models/identifier";
import { dialogOpen, Modal } from "src/app/models/modal";
import { InitialLoadingService } from "src/app/services/initial-loading.service";
import { Nullable } from "../../../../helpers/nullable";
import { Declaration } from "../../../../models/declaration";
import { ConfirmNewlyLiableComponent } from "../../user-info-form/confirm-newly-liable/confirm-newly-liable.component";

type Output =
	| Immutable<{
			isMandated: boolean;
			choice: DeclarationsCreationComponent.AllChoice;
			firstYearDeclaration: Nullable<number>;
	  }>
	| undefined;

@Component({
	selector: "app-new-declaration-modal",
	templateUrl: "./new-declaration-modal.component.html",
	styleUrls: ["./new-declaration-modal.component.scss"],
})
export class NewDeclarationModalComponent extends Modal<undefined, undefined, Output> {
	initialLoading = InitialLoadingService;
	isNewlyLiable = false;
	firstYearDeclaration: Nullable<number>;

	inputToState(): undefined {}

	submit(choice: Immutable<DeclarationsCreationComponent.AllChoice>, isMandated: boolean) {
		if (!this.isNewlyLiable) {
			this.close({ choice, isMandated, firstYearDeclaration: undefined });
			return;
		}

		dialogOpen(this.dialog, ConfirmNewlyLiableComponent, { type: "déclaration" }, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((confirm) => {
				if (confirm) {
					this.close({ choice, isMandated, firstYearDeclaration: this.firstYearDeclaration });
				}
			});
	}

	choice?: Immutable<DeclarationsCreationComponent.AllChoice>;
	isMandated = false;
	errors: Immutable<Set<Siren | Siret | Rna>> = new Set();

	readonly isChoiceValid = DeclarationsCreationComponent.isChoiceValid;
	protected readonly Declaration = Declaration;
}
