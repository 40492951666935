import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { EMPTY, Observable, of, Subject, zip } from "rxjs";
import { first, map, mapTo, switchMap, tap } from "rxjs/operators";
import { unwrap } from "../helpers/unwrap";
import { cloneLazyDeclaration, Declaration } from "../models/declaration";
import { cloneLazyDeclarationGroup, DeclarationGroup } from "../models/declarationGroup";
import { HasResourceFromOwner, Origin } from "../models/HasResourceFromOwner";
import { DeclarationId, IdFromResource } from "../models/ids";
import { Lazy } from "../models/lazy";
import { FeedableObservable, ResourceOwner } from "../models/ResourceOwner";
import { DeclarationService } from "./declaration.service";

@Injectable({
	providedIn: "root",
})
export class DeclarationGroupStateService
	extends HasResourceFromOwner<Lazy<DeclarationGroup>>
	implements ResourceOwner<Lazy<Declaration>>
{
	private cachedDeclaration?: { id: string; declaration: Immutable<Lazy<Declaration>> };

	constructor(private declarationService: DeclarationService) {
		super();
	}

	getIdFromResource(resource: Immutable<Lazy<DeclarationGroup>>): IdFromResource<Lazy<DeclarationGroup>> {
		return unwrap(resource.declaration_group_id);
	}

	clearCache() {
		this.cachedDeclaration = undefined;
	}

	getFeedableObservable(): FeedableObservable<Lazy<Declaration>> {
		const subject = new Subject<{ id: DeclarationId; origin: Origin }>();
		return {
			next: (id, origin) => subject.next({ id, origin }),
			observable: subject.pipe(
				switchMap((idAndOrigin) => zip(of(idAndOrigin), this.get$)),
				switchMap(([idAndOrigin, self]) => (self ? of({ idAndOrigin, self: self.value }) : EMPTY)),
				map(({ idAndOrigin: { id, origin }, self }) => {
					if (this.cachedDeclaration?.id === id) {
						return { value: this.cachedDeclaration.declaration, origin };
					}
					const declaration = self.declarations.find((declaration) => declaration.declaration_id === id);
					if (declaration) {
						this.cachedDeclaration = { id, declaration };
						return { value: declaration, origin };
					}

					return null;
				}),
			),
		};
	}

	update$(resource: Immutable<Lazy<DeclarationGroup>>): Observable<unknown> {
		return super.update$(resource).pipe(tap(() => (this.cachedDeclaration = undefined)));
	}

	updateSync$(resource: Immutable<Lazy<DeclarationGroup>>): Observable<unknown> {
		return super.updateSync$(resource).pipe(tap(() => (this.cachedDeclaration = undefined)));
	}

	updateOwned$(declaration: Immutable<Lazy<Declaration>>): Observable<unknown> {
		return this.get$.pipe(
			first(),
			switchMap((self) => (self ? of(self) : EMPTY)),
			map(({ value: self }) => {
				if (this.cachedDeclaration && declaration.declaration_id === this.cachedDeclaration.id) {
					this.cachedDeclaration = undefined;
				}
				const clone = cloneLazyDeclarationGroup(self);
				const toModifyIndex = clone.declarations.findIndex((d) => d.declaration_id === declaration.declaration_id);
				if (toModifyIndex === -1) {
					throw new Error("Declaration not found when updating");
				}
				clone.declarations[toModifyIndex] = cloneLazyDeclaration(declaration);
				return clone;
			}),
			switchMap((clone) => this.update$(clone)),
		);
	}

	updateOwnedSync$(resource: Immutable<Lazy<Declaration>>): Observable<Immutable<Lazy<Declaration>>> {
		return this.get$.pipe(
			first(),
			switchMap((self) => (self ? of(self) : EMPTY)),
			switchMap((self) =>
				zip(
					of(self),
					this.declarationService.save$(Declaration.toLazyApi(resource)).pipe(map(Declaration.fromLazyApi)),
				),
			),
			switchMap(([{ value: self }, updated]) => {
				if (this.cachedDeclaration && updated.declaration_id === this.cachedDeclaration.id) {
					this.cachedDeclaration = undefined;
				}
				const selfClone = cloneLazyDeclarationGroup(self);
				const indexToModify = selfClone.declarations.findIndex(
					(declaration) => declaration.declaration_id === resource.declaration_id,
				);
				if (indexToModify === -1) {
					throw new Error("Declaration not found when updating");
				}

				selfClone.declarations[indexToModify] = updated;
				return this.update$(selfClone).pipe(mapTo(updated));
			}),
		);
	}
}
