import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { EntityInfo } from "../models/functionalDeclaration";

@Pipe({
	name: "getAssujettiSurfaceText",
})
export class GetAssujettiSurfaceTextPipe implements PipeTransform {
	transform(info: Immutable<EntityInfo>): string {
		const { owned, notOwned } = EntityInfo.assujettiArea(info);

		return `${owned + notOwned} m² (${owned} + ${notOwned})`;
	}
}
