import { Pipe, PipeTransform } from "@angular/core";
import { Nullable } from "../helpers/nullable";
import { Declaration } from "../models/declaration";
import { getEnergyCategoriesToAdd } from "../models/energyCategory";
import { EnergyType } from "../models/energyType";
import { Consumptions } from "../models/functionalDeclaration";
import { PeriodRangePipe } from "./period-range.pipe";

@Pipe({
	name: "minStartForEnergyType",
})
export class MinStartForEnergyTypePipe implements PipeTransform {
	transform(
		consumptions: Consumptions,
		et: EnergyType,
		multiOccupation: boolean,
		minYear: Nullable<number>,
		favoritePeriod: number,
	): number {
		let start: number | undefined = undefined;
		getEnergyCategoriesToAdd(multiOccupation).forEach((cat) => {
			if (consumptions[cat]) {
				Object.keys(consumptions[cat]).forEach((subCat) => {
					Object.keys(consumptions[cat][subCat].values).forEach((energyType) => {
						if (
							energyType === et &&
							consumptions[cat][subCat].values[energyType].values.length !== 0 &&
							(start === undefined || consumptions[cat][subCat].values[energyType].start < start)
						) {
							start = consumptions[cat][subCat].values[energyType].start;
						}
					});
				});
			}
		});

		let minYearDate;
		if (minYear) {
			const yearToPeriod = new PeriodRangePipe();
			const { start } = yearToPeriod.transform(minYear, favoritePeriod);
			minYearDate = new Date(start).getTime() / 1000;
		}

		return start ?? minYearDate ?? Declaration.MINIMUM_DATE_TIME;
	}
}
