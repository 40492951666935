<div class="w-75 mx-auto">
	<h2 class="text-center {{ colorClass }}">{{ title }}</h2>
	<div [class.selected]="currentStep === STEP_SELECTION" #STEP_SELECTION_DIV class="step">
		<div (click)="selectStep(STEP_SELECTION)" class="step-fold">
			<div class="step-fold-number">{{ STEP_SELECTION + 1 }}</div>
			<span class="step-fold-title">Sélection</span>
		</div>
		<div class="step-content-wrapper">
			<div class="step-content d-flex-col">
				<p class="text-secondary">
					{{ description }}
				</p>
				<div *ngIf="!registration && authRepresentatives.length > 1">
					<h3 class="mb-1 mt-0 text-secondary">Ajouter des crédits annuels à</h3>
					<div>
						<ng-select
							[(ngModel)]="payment[GreenKeys.KEY_REPRESENTATIVE]"
							(ngModelChange)="resourceChange()"
							class="bg-white"
							[items]="authRepresentatives"
							bindLabel="name"
							appendTo="body"
							[searchable]="false"
							[clearable]="false"
						>
						</ng-select>
					</div>
				</div>
				<ng-container *ngIf="payment[GreenKeys.KEY_REPRESENTATIVE] as representative">
					<span *ngIf="representative" class="text-gray">
						{{ functionDisplayRemainingToken(representative) }}
					</span>
				</ng-container>
				<app-payment-slider
					[adding]="registration === true"
					[maximum]="registration ? 10 : 11"
					(continue)="selectStep(STEP_SELECTION + 1)"
					[(quantity)]="payment[GreenKeys.KEY_QUANTITY]"
					[unitPrice]="unitPrice"
				>
				</app-payment-slider>
			</div>
		</div>
	</div>

	<ng-container *ngIf="registration">
		<div [class.selected]="currentStep === STEP_ACCOUNT" #STEP_ACCOUNT_DIV class="step">
			<div (click)="selectStep(STEP_ACCOUNT)" class="step-fold">
				<div class="step-fold-number">{{ STEP_ACCOUNT + 1 }}</div>
				<span class="step-fold-title">Création du compte</span>
			</div>
			<div class="step-content-wrapper">
				<div class="step-content d-flex-col">
					<app-register-form (continue)="register($event)"></app-register-form>
				</div>
			</div>
		</div>
	</ng-container>

	<div [class.selected]="currentStep === STEP_INFORMATIONS" #STEP_INFORMATION_DIV class="step">
		<div (click)="selectStep(STEP_INFORMATIONS)" class="step-fold">
			<div class="step-fold-number">{{ STEP_INFORMATIONS + 1 }}</div>
			<span class="step-fold-title">Informations de facturation</span>
		</div>
		<div class="step-content-wrapper">
			<div class="step-content d-flex-col">
				<app-address-form
					[user]="user"
					buttonLabel="Continuer"
					[loading]="loadingAddress"
					(continue)="saveAddress($event)"
					[showName]="!registration"
				>
				</app-address-form>
			</div>
		</div>
	</div>

	<div [class.selected]="currentStep === STEP_PAYMENT" #STEP_PAYMENT_DIV class="step">
		<div (click)="selectStep(STEP_PAYMENT)" class="step-fold">
			<div class="step-fold-number">{{ STEP_PAYMENT + 1 }}</div>
			<span class="step-fold-title">Paiement</span>
		</div>
		<div class="step-content-wrapper">
			<div class="step-content">
				<ng-container *ngIf="STEP_PAYMENT === currentStep && payment[GreenKeys.KEY_REPRESENTATIVE]">
					<div class="d-flex gap-4" style="justify-content: space-between">
						<div class="w-100">
							<app-payment #paymentComponent [displayBuyButton]="false" [payment]="payment"></app-payment>
						</div>
						<div>
							<table class="cart-table">
								<tr>
									<th>Crédit{{ payment[GreenKeys.KEY_QUANTITY] > 1 ? "s" : "" }}</th>
									<td>{{ payment[GreenKeys.KEY_QUANTITY] }}</td>
									<td>{{ payment[GreenKeys.KEY_QUANTITY] * unitPrice }}€</td>
								</tr>
								<tr>
									<th>TVA</th>
									<td>20%</td>
									<td>
										{{
											payment[GreenKeys.KEY_QUANTITY] * unitPrice * 1.2 - payment[GreenKeys.KEY_QUANTITY] * unitPrice
										}}€
									</td>
								</tr>
								<tr>
									<th>TOTAL</th>
									<td></td>
									<td>{{ payment[GreenKeys.KEY_QUANTITY] * unitPrice * 1.2 }}€</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="d-flex gap-2 mt-2" style="justify-content: flex-end">
						<button (click)="selectStep(STEP_PAYMENT - 1)" class="btn-text-gray" mat-flat-button>Retour</button>

						<button
							(click)="paymentComponent.sendPayment()"
							[class.btn-loading]="paymentComponent?.loading"
							class="btn-primary"
							id="submit"
							mat-flat-button
						>
							Acheter
						</button>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
