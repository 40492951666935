<div *ngIf="asyncData$ | async as data">
	<ng-container *ngIf="entityCanAccessPremiumFeatures(data.functionalDeclaration)">
		<div class="d-flex gap-1">
			<button
				mat-button
				class="btn-primary"
				*ngIf="data.functionalDeclaration.declaration_id"
				[routerLink]="RouteDealer.reference(data.functionalDeclaration.declaration_id)"
			>
				<mat-icon class="mr-1">arrow_back</mat-icon>
				Retourner au récapitulatif
			</button>
			<button
				[disabled]="data.functionalDeclaration | apply: allConsumptionsIncomplete"
				mat-button
				class="btn-primary"
				(click)="searchYearReference(data)"
				[matTooltip]="
					(data.functionalDeclaration | apply: allConsumptionsIncomplete) ? 'Toutes vos années sont incomplètes' : ''
				"
			>
				Calcul automatique de l'année de référence
			</button>
		</div>

		<app-year-reference-data
			[functionalDeclaration]="data.functionalDeclaration"
			[djus]="data.djus"
			[absoluteObjectives]="data.absoluteObjectives"
			(resourceChanged)="updateResource($event)"
		>
		</app-year-reference-data>
	</ng-container>
	<ng-container *ngIf="!entityCanAccessPremiumFeatures(data.functionalDeclaration)">
		<mat-spinner></mat-spinner>
	</ng-container>
</div>
