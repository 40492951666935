import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { SURFACE_FOR_ASSUJETTI } from "../helpers/consts";
import { EntityInfo, FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "isAssujetti",
})
export class IsAssujettiPipe implements PipeTransform {
	transform = isAssujetti;
}

export function isAssujetti(fd: Immutable<FunctionalDeclaration>): boolean {
	const { owned, notOwned } = EntityInfo.assujettiArea(fd.infos);
	return fd.is_imported_from_operat || (owned > 0 && owned + notOwned >= SURFACE_FOR_ASSUJETTI);
}
