import { Component, EventEmitter, Input, Output } from "@angular/core";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CustomError } from "src/app/models/CustomError";
import { Siren } from "src/app/models/identifier";
import { SirenService } from "src/app/services/siren.service";
import { HelpSubject } from "../help/help.component";

// global data
const validSirens = new Map<Siren, boolean>();

@Component({
	selector: "app-siren-input",
	templateUrl: "./siren-input.component.html",
	styleUrls: ["./siren-input.component.scss"],
})
export class SirenInputComponent {
	static readonly validSirens = validSirens;
	readonly HelpSubject = HelpSubject;
	constructor(private sirenService: SirenService) {}

	tasksCount = 0;
	readonly validSirens = validSirens;
	readonly Siren = Siren;

	@Input() siren = "" as Siren;
	@Input() placeholder = "";
	@Output() sirenChange = new EventEmitter<Siren>();

	checkSiren(input: HTMLInputElement, siren: Siren) {
		++this.tasksCount;

		this.sirenService
			.getUniteLegale$(siren)
			.pipe(
				map(() => true),
				catchError((err: unknown) => {
					if (err instanceof CustomError) {
						return of(false);
					}
					throw err;
				}),
			)
			.subscribe((doesExist) => {
				input.setCustomValidity(doesExist ? "" : "Votre SIREN n'existe pas");
				this.validSirens.set(siren, doesExist);
				--this.tasksCount;
			});
	}

	resetCustomValidity(input: HTMLInputElement) {
		input.setCustomValidity("");
	}
}
