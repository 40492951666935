import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { Lazy } from "../../../models/lazy";
import { Representative } from "../../../models/representative";
import { User } from "../../../models/user";
import { AuthenticationService } from "../../../services/authentication.service";
import { UserStateService } from "../../../services/user-state.service";

@Component({
	selector: "app-register-form",
	templateUrl: "./register-form.component.html",
	styleUrls: ["./register-form.component.scss"],
})
export class RegisterFormComponent {
	@Output() continue = new EventEmitter<{ user: User; representative: Lazy<Representative> }>();

	@ViewChild("registrationForm") form!: ElementRef;

	formValid = false;

	firstName: string = "";
	lastName: string = "";
	email: string = "";
	phone: string = "";
	company_name: string = "";
	siret: string = "";
	password: string = "";
	confirm_password: string = "";
	accept_cgu_cgv: boolean = false;
	tokens: number = 1;
	civility: number | undefined = undefined;
	loading: boolean = false;

	constructor(
		private authenticationService: AuthenticationService,
		private userState: UserStateService,
	) {}

	updateFormValidity() {
		this.formValid = this.form.nativeElement.checkValidity() ?? false;
	}

	checkConfirmPasswordValidity() {
		const confirmPasswordInput = document.querySelector('input[name="confirm_password"]');
		if (confirmPasswordInput instanceof HTMLInputElement) {
			confirmPasswordInput.setCustomValidity(
				this.password !== this.confirm_password ? "Les mots de passe doivent correspondre." : "",
			);
		}
		this.updateFormValidity();
	}

	updateEmail() {
		const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement;
		if (emailInput) {
			emailInput.setCustomValidity("");
		}
		this.updateFormValidity();
	}

	register() {
		if (!this.loading) {
			this.loading = true;
			this.authenticationService
				.register$({
					password: this.password,
					confirm_password: this.confirm_password,
					user: {
						first_name: this.firstName,
						name: this.lastName,
						email: this.email,
						phone_number: this.phone,
						civility: this.civility,
						address: {
							country: "France",
							houseNumber: "",
							address: "",
							address2: "",
							city: "",
							postalCode: "",
							vatNumber: "",
						},
					},
					representative: {
						siret: this.siret,
						name: this.company_name,
					},
				})
				.subscribe(
					(data) => {
						this.authenticationService.logout();
						this.userState.user = data.user;
						this.continue.next(data);
						this.loading = false;
					},
					(error: unknown) => {
						this.loading = false;
						if (error instanceof HttpErrorResponse && error.status === 422) {
							if (error.error.detail.email[0] === "The email is already used") {
								const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement;
								if (emailInput) {
									emailInput.setCustomValidity("Cette adresse email est déjà utilisée.");
									this.updateFormValidity();
								}
							}
						} else {
							throw error;
						}
					},
				);
		}
	}
}
