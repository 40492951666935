<div class="d-flex h-100">
	<div class="w-50 bg-primary h-100 d-flex-col" style="overflow: auto; font-size: 1rem">
		<div class="p-5 text-center my-auto">
			<h1 class="my-0 ultra-bold" *ngIf="!showPrice" style="text-transform: uppercase">CONFIGURATEUR</h1>
			<h1 class="my-0 ultra-bold" style="text-transform: uppercase">DÉCRET TERTIAIRE</h1>
			<h1 class="my-0" *ngIf="showPrice">
				Vos Déclarations <span class="ultra-bold">en <span class="green-highlight">20 minutes</span></span>
			</h1>
			<div [class.mt-5]="!showPrice">
				<div class="text-left mt-3" style="font-weight: normal">
					<span>Le Configurateur réalise vos déclarations en <b>6 étapes clés</b> :</span>
					<ol class="mt-2 pl-0 green-list">
						<li>Définissez et étudiez rapidement votre assujettissement</li>
						<li>Qualifiez vos sites en quelques clics</li>
						<li>Déclarez votre patrimoine facilement</li>
						<li>Automatisez la collecte de vos données de consommation</li>
						<li>Calculez automatiquement votre année de référence et vos objectifs</li>
						<li>Générez vos déclarations à charger sur OPERAT</li>
					</ol>
				</div>

				<ng-container *ngIf="!showPrice">
					<img src="assets/img/welcome-image.svg" class="w-100" style="max-height: 250px" />
					<h2>
						<span class="ultra-bold"
							>Déjà plus de <span [countUp]="surface" [options]="{ decimal: ',', separator: ' ' }">0</span> de m²</span
						>
						déclarés grâce au Configurateur
					</h2>
				</ng-container>
				<ng-container *ngIf="showPrice">
					<h2>
						<span class="ultra-bold"
							>Déjà plus de <span [countUp]="surface" [options]="{ decimal: ',', separator: ' ' }">0</span> de m²</span
						>
						déclarés grâce au Configurateur
					</h2>
					<img src="assets/img/welcome-image.svg" class="w-100" style="max-height: 250px" />
					<div class="w-100" style="height: 3px; background-color: white"></div>
					<div class="text-left">
						<h2 class="my-0 ultra-bold">200€</h2>
						<h2 class="my-0 ultra-bold">/entité fonctionnelle assujettie</h2>
						<p style="font-weight: normal">
							* Vous avez plus de 10 entités fonctionnelles à déclarer ? Vous êtes un BE et souhaitez déclarer pour le
							compte de vos clients ? Prix dégressifs au delà de 10 déclarations.
						</p>
						<a
							class="btn-accent"
							href="https://www.greensystemes.com/contact/"
							target="_blank"
							style="border-radius: 15px; width: min-content; white-space: nowrap"
						>
							Contactez-nous >
						</a>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="w-50 h-100 d-flex-col" style="overflow: auto">
		<div class="my-auto p-5">
			<img class="logo" [src]="LOGO_URL" alt="Logo" />
			<ng-content></ng-content>
		</div>
	</div>
</div>
