import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Etablissement } from "../models/siren-api.types";

@Pipe({
	name: "etablissementSubtitle",
})
export class EtablissementSubtitlePipe implements PipeTransform {
	transform({ identifier }: Immutable<Etablissement>): string {
		return !identifier
			? ""
			: "siret" in identifier
				? identifier.siret
				: "denomination" in identifier
					? identifier.denomination
					: "refEtat" in identifier
						? identifier.refEtat
						: identifier.rna;
	}
}
