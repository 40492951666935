import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Nullable } from "../helpers/nullable";
import { Declaration } from "../models/declaration";
import { CheckFunctionalDeclarationStatePipe } from "./check-functional-declaration-state.pipe";

@Pipe({
	name: "checkDeclarationState",
})
export class CheckDeclarationStatePipe implements PipeTransform {
	transform(declaration: Immutable<Declaration>): { name: Nullable<string>; errors: string[] }[] {
		const checkEntityState = new CheckFunctionalDeclarationStatePipe();
		return declaration.declarations_functional.slice(0, 5).flatMap((entity) => {
			const errors = checkEntityState.transform(entity);
			return errors.length === 0 ? [] : [{ name: entity.name, errors }];
		});
	}
}
