import { Component } from "@angular/core";
import { Immutable } from "immer";
import { Declaration, resourceInfoFromResource } from "../../../models/declaration";
import { DeclarationGroup } from "../../../models/declarationGroup";
import { FunctionalDeclaration } from "../../../models/functionalDeclaration";
import { Modal } from "../../../models/modal";
import { DeclarationService } from "../../../services/declaration.service";

type Input = {
	resource: Immutable<DeclarationGroup | Declaration | FunctionalDeclaration>;
	declarationService: DeclarationService;
};
type State = {
	resource: Immutable<DeclarationGroup | Declaration | FunctionalDeclaration>;
	first_name: string;
	last_name: string;
	email: string;
};
type Output = boolean;

@Component({
	selector: "app-export-csv-modal",
	templateUrl: "./export-csv-modal.component.html",
	styleUrls: ["./export-csv-modal.component.scss"],
})
export class ExportCsvModalComponent extends Modal<Input, State, Output> {
	private declarationService!: DeclarationService;

	inputToState(input: Input): State {
		this.declarationService = input.declarationService;
		return {
			resource: input.resource,
			first_name: "",
			last_name: "",
			email: "",
		};
	}

	downloadCsv() {
		this.declarationService
			.exportCsv$(resourceInfoFromResource(this.state.resource), this.state)
			.subscribe((content) => {
				const blob = new Blob([content], { type: "text/csv" });
				const elem = window.document.createElement("a");
				elem.href = window.URL.createObjectURL(blob);
				elem.download = "kit_patrimoine.csv";
				document.body.appendChild(elem);
				elem.click();
				document.body.removeChild(elem);
				this.close(true);
			});
	}
}
