import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { DeclarationsCreationComponent } from "../components/declarations-creation/declarations-creation.component";
import { Nullable } from "../helpers/nullable";
import AllChoice = DeclarationsCreationComponent.AllChoice;

@Pipe({
	name: "choiceIsOwner",
})
export class ChoiceIsOwnerPipe implements PipeTransform {
	transform(choice: Nullable<Immutable<AllChoice>>): boolean | undefined {
		if (!choice) {
			return undefined;
		}

		return choice.isOwner;
	}
}
