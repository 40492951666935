import { Pipe, PipeTransform } from "@angular/core";
import { HeatModifierType } from "../models/heatModifierType";

@Pipe({
	name: "heatModifierTypeDisplay",
})
export class HeatModifierTypeDisplayPipe implements PipeTransform {
	transform(hmt: HeatModifierType): string {
		return translateHeatModifier(hmt);
	}
}

export function translateHeatModifier(hmt: HeatModifierType): string {
	switch (hmt) {
		case HeatModifierType.HEAT:
			return "Chauffage";
		case HeatModifierType.COOL:
			return "Refroidissement";
		case HeatModifierType.LOGISTIC_COOL:
			return "Froid logistique";
		case HeatModifierType.COMMERCIAL_COOL:
			return "Froid commercial";
		case HeatModifierType.DOCUMENT_AND_COLLECTION:
			return "Conservation de documents ou de collections";
	}
}
