import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Point, Polygon } from "geojson";
import { Immutable } from "immer";
import { MapGeoJSONFeature } from "maplibre-gl";
import * as uuid from "uuid";
import { ParcelId } from "./ids";

export interface Parcel {
	arpente: boolean | undefined;
	commune: string;
	contenance: number | undefined;
	id: ParcelId;
	numero: string;
	prefixe: string;
	section: string;
}

/**
 * WARNING, we use the feature's id only for the map. But for the "main parcel" in a
 * functional declaration, we must use the id inside the feature's properties. Just use getParcelId.
 */
export type ParcelFeature = Feature<Polygon | MultiPolygon | Point, Parcel>; // Point = custom

function geoJsonPropertiesToParcel(properties: GeoJsonProperties): null | Parcel {
	if (properties === null) {
		return null;
	}

	const { arpente, commune, contenance, id, numero, prefixe, section } = properties;

	return commune !== undefined &&
		id !== undefined &&
		numero !== undefined &&
		prefixe !== undefined &&
		section !== undefined
		? { arpente, commune, contenance, id, numero, prefixe, section }
		: null;
}

export function toParcelFeature(feature: MapGeoJSONFeature): ParcelFeature | null {
	const properties = geoJsonPropertiesToParcel(feature.properties);

	if (properties === null) {
		return null;
	}

	const geometry = feature.geometry;

	if (geometry.type !== "Polygon" && geometry.type !== "MultiPolygon") {
		return null;
	}

	return { properties, geometry, type: feature.type, id: feature.id };
}

/**
 * Returns the old parcel's id that's compatible with the new format.
 * @param parcel a parcel of the old type.
 * @returns the parcel's id that's compatible with the new format.
 */
export function getOldParcelId(parcel: Immutable<FeatureCollection>): ParcelId {
	const properties = parcel.features[0].properties;

	if (properties === null) {
		throw new Error("Null properties");
	}

	const prefixe = properties["code_arr"] !== "000" ? properties["code_arr"] : properties["com_abs"];

	return <ParcelId>(
		`${properties["code_dep"]}${properties["code_com"]}${prefixe}${properties["section"]}${properties["numero"]}`
	);
}

export function getParcelId(parcel: Immutable<FeatureCollection | ParcelFeature>): ParcelId {
	return parcel.type === "FeatureCollection" ? getOldParcelId(parcel) : parcel.properties.id;
}

export function customParcelFeature(
	commune: string,
	numero: string,
	com_abs: string,
	code_arr: string,
	section: string,
): ParcelFeature {
	const prefixe = code_arr !== "000" ? code_arr : com_abs;

	const id = (commune + prefixe + section + numero) as ParcelId;

	const properties: Parcel = {
		commune,
		numero,
		prefixe,
		section,
		arpente: false,
		contenance: 0,
		id,
	};

	return {
		properties,
		geometry: { type: "Point", coordinates: [0, 0] },
		type: "Feature",
		id: uuid.v1(),
	};
}
