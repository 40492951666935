<div class="dialog-container" style="overflow: hidden">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="false">close</mat-icon>
	</div>
	<div class="text-center">
		<div>
			<app-year-slider
				[minYear]="minYear"
				[maxYear]="maxYear"
				[currentYear]="year"
				(currentYearChange)="yearChanged($event)"
			></app-year-slider>
		</div>
		<h2 class="mb-0">Consommations énergétiques du patrimoine en {{ year }} : nuage de points</h2>
		<span class="primary pointer" (click)="openHelpModal()"
			>Obtenir des explications pour mieux comprendre ce graphique</span
		>

		<a
			class="btn-stroked-primary ml-auto mr-2"
			[ngClass]="{ disabled: !exportFile }"
			style="width: fit-content"
			[href]="exportFile?.file"
			download="{{ exportFile?.filename }}.csv"
		>
			<span class="text-icon">
				<mat-icon>download</mat-icon>
				Exporter les données
			</span>
		</a>
	</div>
	<app-benchmark-graph
		[declaration]="declaration"
		[animate]="true"
		[djus]="djus"
		[data]="benchmarkData"
		[minTotal]="minTotal"
		[maxTotal]="maxTotal"
		[minPerSurface]="minPerSurface"
		[maxPerSurface]="maxPerSurface"
		[year]="year"
	></app-benchmark-graph>
	<div *ngIf="benchmarkData.length <= 0" class="error-message d-flex-col">
		<h2 *ngIf="isYearLocked; else noData">Aucun crédit annuel n'a été utilisé pour cette déclaration.</h2>
		<ng-template #noData>
			<h2>Aucune donnée renseignée pour l'année {{ this.year }}.</h2>
		</ng-template>
	</div>
</div>
