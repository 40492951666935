import { Component } from "@angular/core";
import { Immutable } from "immer";
import { EMPTY } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Nullable } from "../../../../../../../helpers/nullable";
import { DeclarationGroup } from "../../../../../../../models/declarationGroup";
import { FunctionalDeclaration } from "../../../../../../../models/functionalDeclaration";
import { Lazy } from "../../../../../../../models/lazy";
import { Modal } from "../../../../../../../models/modal";
import { Representative } from "../../../../../../../models/representative";
import { DeclarationFunctionalService } from "../../../../../../../services/declaration-functional.service";
import { DeclarationGroupStateService } from "../../../../../../../services/declaration-group-state.service";
import { TokenTransferModalComponent } from "../../../../../token-transfer-modal/token-transfer-modal.component";

type Input = {
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	functionalService: DeclarationFunctionalService;
	groupService: DeclarationGroupStateService;
	representative: Nullable<Immutable<Lazy<Representative>>>;
	declarationGroup: Nullable<Immutable<Lazy<DeclarationGroup>>>;
	year: number;
};

type State = {
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	functionalService: DeclarationFunctionalService;
	groupService: DeclarationGroupStateService;
	representative: Nullable<Immutable<Lazy<Representative>>>;
	declarationGroup: Nullable<Immutable<Lazy<DeclarationGroup>>>;
	year: number;
};

type Output =
	| { type: "cancel" }
	| { type: "updated"; entity: FunctionalDeclaration }
	| { type: "cant buy" }
	| { type: "no permissions" };

@Component({
	selector: "app-consume-annual-token-modal",
	templateUrl: "./consume-annual-token-modal.component.html",
	styleUrls: ["./consume-annual-token-modal.component.scss"],
})
export class ConsumeAnnualTokenModalComponent extends Modal<Input, State, Output> {
	waitingForTokens = false;

	closeDialog(buy: boolean): void {
		if (!buy) {
			this.close({ type: "cancel" });
			return;
		}

		if (!this.state.declarationGroup) {
			this.close({ type: "no permissions" });
			return;
		}

		if (this.state.declarationGroup.annual_tokens - this.state.declarationGroup.used_annual_tokens < 1) {
			if (this.state.representative) {
				this.waitingForTokens = true;
				const tokenTransfer = this.dialog.open(TokenTransferModalComponent, {
					data: {
						declarationGroup: this.state.declarationGroup,
						representative: this.state.representative,
						showWarningAnnual: true,
					},
					panelClass: "p-0",
				});
				tokenTransfer
					.afterClosed()
					.pipe(
						switchMap(() => {
							if (this.state.declarationGroup && this.state.declarationGroup.declaration_group_id)
								return this.state.groupService.select$(this.state.declarationGroup.declaration_group_id);
							return EMPTY;
						}),
					)
					.subscribe((group) => {
						if (group) {
							this.state.declarationGroup = group.value;
						}
						this.waitingForTokens = false;
					});
				return;
			} else {
				this.close({ type: "cant buy" });
				return;
			}
		}

		this.state.functionalService
			.unlockYear$(this.state.functionalDeclaration.declaration_functional_id, this.state.year)
			.subscribe((fd) => {
				this.close({ type: "updated", entity: FunctionalDeclaration.fromApi(fd) });
				return;
			});
	}

	inputToState(input: Input): State {
		return {
			functionalDeclaration: input.functionalDeclaration,
			functionalService: input.functionalService,
			groupService: input.groupService,
			representative: input.representative,
			declarationGroup: input.declarationGroup,
			year: input.year,
		};
	}
	isCurrentYear() {
		const today = new Date();
		return today.getFullYear() === this.state.year;
	}
}
