import { AfterViewInit, Component, Input } from "@angular/core";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { environment } from "../../../environments/environment";
import { BaseComponent } from "../../models/base-component.directive";
import { Payment } from "../../models/payment";
import { PaymentService } from "../../services/payment.service";

@Component({
	selector: "app-payment",
	templateUrl: "./payment.component.html",
	styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent extends BaseComponent implements AfterViewInit {
	@Input() payment: Payment | undefined;
	@Input() returnUrl = window.location.href;
	@Input() displayBuyButton: boolean = true;
	stripe: Stripe | undefined = undefined;
	elements: StripeElements | undefined = undefined;
	pageLoading = true;
	loading = false;

	constructor(private paymentService: PaymentService) {
		super();
	}

	ngAfterViewInit() {
		if (this.payment) {
			loadStripe(environment.stripePublicApiKey).then((stripe) => {
				if (stripe && this.payment) {
					this.stripe = stripe;
					this.paymentService.createPayment$(this.payment).subscribe((response) => {
						const clientSecret = response.client_secret;
						this.elements = stripe.elements({ clientSecret });
						const paymentElement = this.elements.create("payment");
						paymentElement.mount("#payment-element");
						paymentElement.on("ready", () => {
							this.pageLoading = false;
						});
					});
				}
			});
		}
	}

	sendPayment() {
		if (this.stripe && this.elements) {
			this.loading = true;
			this.stripe
				?.confirmPayment({
					elements: this.elements,
					confirmParams: {
						return_url: this.returnUrl,
					},
				})
				.then((r) => {
					const error = r.error;
					if (error.type === "card_error" || error.type === "validation_error") {
						this.showMessage(error.message ?? "");
					} else {
						this.showMessage("Une erreur est survenue.");
					}
					this.loading = false;
				});
		}
	}

	showMessage(messageText: string) {
		const messageContainer = document.querySelector("#payment-message");

		if (messageContainer) {
			messageContainer.classList.remove("hidden");
			messageContainer.textContent = messageText;

			setTimeout(function () {
				messageContainer.classList.add("hidden");
			}, 4000);
		}
	}
}
