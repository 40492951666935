export class NavbarService {
	setNav(state: string) {
		localStorage.setItem("navState", state);
	}

	setFuncNav(state: string) {
		localStorage.setItem("funcNavState", state);
	}

	getNavState(): string | null {
		return localStorage.getItem("navState");
	}

	getFuncNavState(): string | null {
		return localStorage.getItem("funcNavState");
	}
}
