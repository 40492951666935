import { Pipe, PipeTransform } from "@angular/core";
import { Identifier, IdOther } from "../models/identifier";

@Pipe({
	name: "asOther",
})
export class AsOtherPipe implements PipeTransform {
	transform(id: Identifier): IdOther | undefined {
		return "label" in id ? id : undefined;
	}
}
