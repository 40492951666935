import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { getIdentifierStringFromEfaStatus } from "src/app/models/efaStatus";
import { Lazy } from "src/app/models/lazy";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { FunctionalDeclaration } from "../../../../../models/functionalDeclaration";
import { AddressToStringPipe } from "../../../../../pipes/address-to-string.pipe";
import { FunctionalDeclarationStateService } from "../../../../../services/functional-declaration-state.service";
import { FUNCTIONAL_PAGES, Page } from "../../stepper.component";

@Component({
	selector: "app-declaration-functional-list",
	templateUrl: "./declaration-functional-list.component.html",
	styleUrls: ["./declaration-functional-list.component.scss"],
})
export class DeclarationFunctionalListComponent extends BaseComponent implements OnChanges {
	@Input() declarationsFunctional: Immutable<FunctionalDeclaration[]> = [];

	readonly AMOUNT_PER_PAGE = 10;

	FUNCTIONAL_PAGES = FUNCTIONAL_PAGES;

	page = 1;
	maxPage = 1;
	search = "";

	filteredDeclarationsFunctional: Immutable<FunctionalDeclaration[]> = [];
	declarationsFunctionalToShow: Immutable<FunctionalDeclaration[]> = [];

	constructor(
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private router: Router,
		private dialog: MatDialog,
	) {
		super();
	}

	ngOnChanges() {
		this.filteredDeclarationsFunctional = this.declarationsFunctional;
		this.maxPage = Math.ceil(this.declarationsFunctional.length / this.AMOUNT_PER_PAGE);
		this.setPage(1);
	}

	setPage(page: number) {
		this.page = page;
		this.declarationsFunctionalToShow = this.filteredDeclarationsFunctional.slice(
			(page - 1) * this.AMOUNT_PER_PAGE,
			page * this.AMOUNT_PER_PAGE,
		);
	}

	updateSearch() {
		const addressToStringPipe = new AddressToStringPipe();
		const searchString = this.search.toLowerCase();
		this.filteredDeclarationsFunctional = this.declarationsFunctional.filter((functionalDeclaration) => {
			const { efa_status } = functionalDeclaration;
			for (const agreement of functionalDeclaration.agreement_asks) {
				if (agreement.reference.toLowerCase().includes(searchString)) {
					return true;
				}
			}
			return (
				functionalDeclaration[GreenKeys.KEY_NAME]?.toLowerCase().includes(searchString) ||
				getIdentifierStringFromEfaStatus(efa_status).toLowerCase().includes(searchString) ||
				addressToStringPipe.transform(functionalDeclaration[GreenKeys.KEY_ADDRESS]).toLowerCase().includes(searchString)
			);
		});
		this.maxPage = Math.ceil(this.filteredDeclarationsFunctional.length / this.AMOUNT_PER_PAGE);
		this.setPage(1);
	}

	selectFunctionalStep(functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>, page: Page) {
		const id = functionalDeclaration.declaration_functional_id;

		if (!id) {
			throw new Error("Functional declaration without id.");
		}

		this.functionalDeclarationState.select$(id).subscribe(() => {
			this.router.navigate([id, page.goToSegmentFunctional]);
			this.dialog.closeAll();
		});
	}
}
