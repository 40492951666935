<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">Importer un fichier CSV</h3>
		<div class="d-flex" style="flex-direction: column">
			<ng-container>
				<p>Vous pouvez importer un fichier CSV dans la déclaration des consommations.</p>
				<p class="mt-0">
					Pour plus d'informations,
					<a class="text-secondary pointer" (click)="openImportTutorial()">cliquez ici</a>
				</p>
				<div class="d-flex" style="align-items: center">
					<input #inputFile type="file" name="files" multiple value="process" (change)="parseCSV(inputFile.files)" />
				</div>
				<div *ngIf="category === EnergyCategory.COMMON || category === EnergyCategory.DISTRIBUTED">
					<mat-checkbox [(ngModel)]="this.hasRepartitionKey" color="primary">
						Les consommations sont réparties aux tantièmes
						<app-help [subject]="HelpSubject.Tantieme"></app-help>
					</mat-checkbox>
					<div *ngIf="this.hasRepartitionKey">
						<div class="d-flex" style="align-items: center">
							<mat-form-field appearance="outline" class="w-25 mat-form-field-no-padding">
								<mat-label>Tantièmes</mat-label>
								<input required matInput [(ngModel)]="this.partUmpteenth" />
							</mat-form-field>
							<div style="font-size: 40px; padding: 0 5px">/</div>
							<mat-form-field appearance="outline" class="w-25 mat-form-field-no-padding" style="margin-right: 10px">
								<input required matInput [(ngModel)]="this.totalUmpteenth" />
							</mat-form-field>
						</div>
					</div>
				</div>
				<ng-container *ngIf="toAddValues.length > 0">
					<div>
						<table style="width: 100%">
							<thead>
								<tr>
									<th>Date de début</th>
									<th>Date de fin</th>
									<th>Valeur</th>
									<th *ngIf="category === EnergyCategory.COMMON || category === EnergyCategory.DISTRIBUTED">
										Valeur corrigée
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of toAddValues" style="text-align: center">
									<td>{{ record.date_start | timestampToDate: "dd/MM/yyyy" }}</td>
									<td>{{ record.date_end | timestampToDate: "dd/MM/yyyy" }}</td>
									<td>{{ record.value | energyTypeValueDisplay: energy }} {{ energy | energyTypeUnit }}</td>
									<td *ngIf="category === EnergyCategory.COMMON || category === EnergyCategory.DISTRIBUTED">
										{{ record | correctedValue | energyTypeValueDisplay: energy }} {{ energy | energyTypeUnit }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [matDialogClose]="null">Fermer</button>
		<button class="btn-stroked-primary-light" (click)="downloadFile()">Télécharger le fichier modèle</button>
		<button *ngIf="toAddValues.length > 0" class="btn-stroked-primary" (click)="saveCsvImport()">Ajouter</button>
	</div>
</div>
