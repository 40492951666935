<div class="dialog-container tall" style="min-width: 650px">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="m-0 mb-2">
			{{ state.adding ? "Ajout d'une catégorie d'activité" : "Modification d'une catégorie d'activité" }}
		</h3>
		<app-custom-form #form>
			<div class="form">
				<mat-label>Nom de la catégorie d'activité</mat-label>
				<ng-container *ngIf="state.step === 'entry'">
					<input
						[disabled]="state.useDefaultCategory"
						[(ngModel)]="state.entry.name"
						class="custom-input mb-2"
						name="name"
						required
						type="text"
					/>
				</ng-container>
				<ng-container *ngIf="state.step !== 'entry'">
					<input
						[disabled]="state.useDefaultCategory"
						[(ngModel)]="state.entryName"
						class="custom-input mb-2"
						name="name"
						required
						type="text"
					/>
				</ng-container>

				<mat-label>Catégorie</mat-label>
				<ng-select
					class="mb-2"
					[(ngModel)]="state.categoryCode"
					[disabled]="state.useDefaultCategory"
					(ngModelChange)="onCategoryChange($event, state.step === 'entry' ? state.entry.name : state.entryName)"
					[clearable]="false"
					name="category"
					required
				>
					<ng-option *ngFor="let category of CATEGORIES" [value]="category.code">
						<div class="option" [title]="category.libelle">
							{{ category.libelle }}
						</div>
					</ng-option>
				</ng-select>
				<ng-container *ngIf="state.step !== 'category'">
					<mat-label>Sous catégorie</mat-label>
					<ng-select
						class="mb-2"
						[(ngModel)]="state.subCategoryCode"
						(ngModelChange)="onSubCategoryChange(state, $event)"
						[clearable]="false"
						name="sub-category"
						required
						[disabled]="state.useDefaultCategory"
					>
						<ng-option
							*ngFor="let subCategory of (state.categoryCode | categoryFromCode)?.subCategories"
							[value]="subCategory.code"
						>
							<div class="option" [title]="subCategory.libelle">
								{{ subCategory.libelle }}
							</div>
						</ng-option>
					</ng-select>
				</ng-container>
				<ng-container *ngIf="state.step === 'entry'">
					<div class="italic mb-2" *ngIf="absoluteObjective$ | async as absoluteObjective">
						<span class="absolute-objective">Objectif absolu étalon :</span> {{ absoluteObjective | number }} kWh/m²
					</div>
					<div
						*ngIf="state.subCategoryCode | apply: isSubCategoryCodeDefault"
						class="d-flex"
						style="justify-content: center"
					>
						<div class="message-warning mb-2 d-flex gap-2" style="align-items: center; max-width: 30em">
							<mat-icon style="min-width: 24px">warning</mat-icon>
							Cette sous-catégorie ne peut être sélectionnée que lorsque le total des surfaces relevant de la catégorie
							concernée représente plus de 80 % de la surface déclarée de l'entité fonctionnelle.
						</div>
					</div>
					<div class="d-flex gap-4">
						<div class="w-100 d-flex-col" [class.has-side]="state.entry.indicators.length > 0">
							<div
								class="custom-input-group"
								*ngIf="((state.entry.subCategoryCode | subCategoryFromCode)?.heatModifiers ?? []).length > 0"
							>
								<mat-label>Usages chauffage/refroidissement</mat-label>
								<ng-select
									[(ngModel)]="state.entry.temperatureModifiers"
									(ngModelChange)="updateModifiers(state.entry)"
									[clearable]="false"
									[closeOnSelect]="false"
									[multiple]="true"
									appearance="outline"
									class="mb-2"
								>
									<ng-container
										*ngFor="let modifier of (state.entry.subCategoryCode | subCategoryFromCode)?.heatModifiers"
									>
										<ng-option [value]="modifier">
											{{ modifier | heatModifierTypeDisplay }}
										</ng-option>
									</ng-container>
								</ng-select>
							</div>
							<div class="custom-input-group mb-2">
								<label>Surface de l'activité (m²)</label>
								<div class="unit m2">
									<input
										[(ngModel)]="state.entry.surface"
										[min]="1"
										appInputRestriction="integer"
										class="custom-input"
										name="surface"
										style="width: 100%"
										type="number"
										required
									/>
								</div>
							</div>
							<ng-container *ngIf="state.entry.temperatureModifiers | hasLogisticCooling">
								<mat-label>Hauteur de froid logistique</mat-label>
								<div class="unit m1 mb-2">
									<input
										[(ngModel)]="state.entry.logisticCooledHeight"
										[min]="0"
										appInputRestriction="integer"
										class="custom-input"
										required
										style="width: 100%"
										type="number"
									/>
								</div>
							</ng-container>
							<div class="dates">
								<div class="mb-2 custom-input-group">
									<label>Date de début (incluse)</label>
									<input
										#start
										(change)="startDateChange(state.entry, start.value)"
										[value]="state.entry.start | timestampToDate"
										class="custom-input"
										name="start"
										required
										type="date"
										[min]="START | date: 'y-MM-dd'"
										[max]="TODAY | date: 'y-MM-dd'"
									/>
									<span class="custom-input-error">{{ start.validationMessage }}</span>
								</div>
								<div class="mb-2 custom-input-group">
									<label>Date de fin (exclue)(vide pour aujourd'hui)</label>
									<input
										#end
										(change)="endDateChange(state.entry, end.value)"
										[value]="state.entry.end ? (state.entry.end | timestampToDate) : null"
										class="custom-input"
										name="end"
										type="date"
										[min]="start.value | followingDay"
										[max]="TODAY | date: 'y-MM-dd'"
									/>
									<span class="custom-input-error">{{ end.validationMessage }}</span>
								</div>
							</div>
						</div>

						<div class="w-100 d-flex-col" *ngIf="state.entry.indicators.length > 0">
							<mat-label>Indicateurs d'intensité d'usage</mat-label>
							<div class="custom-input-group mb-2" *ngFor="let indicator of state.entry.indicators">
								<ng-container *ngIf="indicator.code | indicatorFromCode: state.subCategoryCode as currentIndicator">
									<mat-label>{{ currentIndicator.libelle }}</mat-label>
									<div class="input-icon w-100">
										<input
											[(ngModel)]="indicator.value"
											appInputRestriction="negative_integer"
											class="custom-input w-100"
											name="{{ indicator.code }}"
											type="number"
											step="0.1"
											[placeholder]="currentIndicator.defaultValue"
											[min]="currentIndicator.min ?? null"
											[max]="currentIndicator.max ?? null"
											#indicatorInput
										/>
										<span
											class="text-gray input-suffix"
											*ngIf="currentIndicator.defaultValue === indicator.value || indicator.value === null"
										>
											(Par défaut)
										</span>
									</div>
									<span class="custom-input-error d-block">{{ indicatorInput.validationMessage }}</span>
								</ng-container>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</app-custom-form>
	</div>
	<div class="dialog-actions gap-1">
		<button (click)="exit()" class="btn-stroked-text-gray mr-auto">Annuler</button>
		<button *ngIf="!state.adding" (click)="delete()" class="btn-stroked-danger">Supprimer</button>
		<button
			*ngIf="state.step === 'entry' && form.formValid; else cantSave"
			(click)="save(state.entry)"
			class="btn-stroked-primary"
		>
			{{ state.adding ? "Ajouter" : "Modifier" }}
		</button>
		<ng-template #cantSave>
			<button [disabled]="true" class="btn-stroked-primary">{{ state.adding ? "Ajouter" : "Modifier" }}</button>
		</ng-template>
	</div>
</div>
