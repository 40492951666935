import { Observable, Subject } from "rxjs";
import { catchError, tap } from "rxjs/operators";

/**
 * Prevents infinite loading from this.saving$
 * @param saving$
 */
export function handleSavingUnlock<T>(saving$: Subject<boolean>): (source$: Observable<T>) => Observable<T> {
	return (source$) =>
		source$.pipe(
			tap(() => saving$.next(false)),
			catchError((err) => {
				saving$.next(false);
				throw err;
			}),
		);
}
