<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">
			<mat-icon class="mr-2">landscape</mat-icon>
			Ajouter une parcelle
		</h3>
		<div class="d-flex" style="flex-direction: column">
			<div class="custom-input-group">
				<label>Code département</label>
				<input
					[(ngModel)]="state.dep"
					appInputRestriction="integer"
					class="custom-input"
					placeholder="Code département"
				/>
			</div>
			<div class="custom-input-group">
				<label>Code Insee</label>
				<input [(ngModel)]="state.insee" appInputRestriction="integer" class="custom-input" placeholder="Code Insee" />
			</div>
			<div class="custom-input-group">
				<label>Préfixe cadastre</label>
				<input
					[(ngModel)]="state.prefixe"
					appInputRestriction="integer"
					maxlength="3"
					class="custom-input"
					placeholder="Préfixe"
				/>
			</div>
			<div class="custom-input-group">
				<label>Section cadastre</label>
				<input [(ngModel)]="state.section" class="custom-input" maxlength="2" placeholder="Section" />
			</div>
			<div class="custom-input-group">
				<label>Numéro de la parcelle</label>
				<input
					[(ngModel)]="state.numero"
					appInputRestriction="integer"
					class="custom-input"
					maxlength="4"
					placeholder="Numéro"
				/>
			</div>
		</div>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" (click)="cancel()">Annuler</button>
		<button class="btn-stroked-primary" (click)="save()">Ajouter</button>
	</div>
</div>
