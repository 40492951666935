<span class="identifier">
	<ng-container *ngIf="identifier | asSiret as id">
		<label class="label">{{ id | labelFromIdentifier }}</label>
		<input
			#input
			required
			class="id custom-input disabled"
			[value]="id.siret"
			placeholder="Non renseigné"
			(input)="input.value = id.siret"
		/>
	</ng-container>
	<ng-container *ngIf="identifier | asSiren as id">
		<label class="label">{{ id | labelFromIdentifier }}</label>
		<input
			#input
			required
			class="id custom-input disabled"
			[value]="id.siren"
			placeholder="Non renseigné"
			(input)="input.value = id.siren"
		/>
	</ng-container>
	<ng-container *ngIf="identifier | asRna as id">
		<label class="label">{{ id | labelFromIdentifier }}</label>
		<input
			#input
			required
			class="id custom-input disabled"
			[value]="id.rna"
			placeholder="Non renseigné"
			(input)="input.value = id.rna"
		/>
	</ng-container>
	<ng-container *ngIf="identifier | asRefEtat as id">
		<label class="label">{{ id | labelFromIdentifier }}</label>
		<input
			#input
			required
			class="id custom-input disabled"
			[value]="id.refEtat"
			placeholder="Non renseigné"
			(input)="input.value = id.refEtat"
		/>
	</ng-container>
	<ng-container *ngIf="identifier | asRefEtatLess as id">
		<label class="label">{{ id | labelFromIdentifier }}</label>
		<input
			#input
			required
			class="id custom-input disabled"
			[value]="id.refEtatLess"
			placeholder="Non renseigné"
			(input)="input.value = id.refEtatLess"
		/>
	</ng-container>
	<ng-container *ngIf="identifier | asOther as id">
		<label class="label">{{ id | labelFromIdentifier }}</label>
		<input
			#input
			required
			class="id custom-input disabled"
			[value]="id.id"
			placeholder="Non renseigné"
			(input)="input.value = id.id"
		/>
	</ng-container>
	<span class="button" (click)="editClick.emit()"><mat-icon>edit</mat-icon></span>
</span>
