import { Pipe, PipeTransform } from "@angular/core";
import { Identifier, IdRna } from "../models/identifier";

@Pipe({
	name: "asRna",
})
export class AsRnaPipe implements PipeTransform {
	transform(id: Identifier): IdRna | undefined {
		return "rna" in id ? id : undefined;
	}
}
