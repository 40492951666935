import { ComponentType } from "@angular/cdk/portal";
import { Directive, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

/**
 * This class is useful to have typesafe modals!
 * Use the `dialogOpen` function to open a modal and the `close` method to... close the modal.
 * The `MatDialog` service is passed to children thanks to the `protected dialog` member.
 */
@Directive()
export abstract class Modal<Input, State, Output> {
	state: State;

	constructor(
		private dialogRef: MatDialogRef<Modal<Input, State, Output>>,
		protected dialog: MatDialog,
		protected snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) input: Input,
	) {
		this.state = this.inputToState(input);
		this.afterStateInit();
	}

	/**
	 * For the child to have an initial state accessible from the template.
	 * We have to use this method or the child will have nullable members.
	 * @param input The modal's input
	 */
	abstract inputToState(input: Input): State;

	public afterStateInit() {}

	public close(output: Output) {
		this.dialogRef.close(output);
	}
}

/**
 * Opens a type safe modal.
 * @param dialog The dialog service
 * @param component The component which inherits Modal
 * @param input The input which the modal waits
 * @param config
 * @returns The reference to the dialog
 */
export function dialogOpen<Input, Output>(
	dialog: MatDialog,
	component: ComponentType<Modal<Input, unknown, Output>>,
	input: Input,
	config: Omit<MatDialogConfig, "data" | "autoFocus"> = {},
): MatDialogRef<Modal<Input, unknown, Output>, Output> {
	return dialog.open<Modal<Input, unknown, Output>, Input, Output>(component, {
		data: input,
		autoFocus: false,
		...config,
	});
}
