import { TitleCasePipe } from "@angular/common";
import { Immutable } from "immer";
import { StreetType } from "./StreetType";

export class AddressInfo {
	cityCode = "";
	cityName = "";
	postalCode = "";
	houseNumber: string | number = "";
	streetName = "";
	streetType = StreetType.RUE;
	details = ""; // complément d'adresse

	static equals(a: Immutable<AddressInfo>, b: Immutable<AddressInfo>) {
		return (
			a.cityCode === b.cityCode &&
			a.streetName === b.streetName &&
			a.houseNumber === b.houseNumber &&
			a.streetType === b.streetType &&
			a.details === b.details
		);
	}

	static toString(address: Immutable<AddressInfo>) {
		const houseNumber = address.houseNumber !== "" ? `${address.houseNumber} ` : "";
		const streetName = address.streetName !== undefined && address.streetName !== "" ? `${address.streetName},` : "";
		return `${houseNumber} ${TITLE_CASE_PIPE.transform(address.streetType)} ${streetName} ${TITLE_CASE_PIPE.transform(address.cityName)} ${address.postalCode}`;
	}
}

const TITLE_CASE_PIPE = new TitleCasePipe();
