import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "sumInvoicePerYear",
})
export class SumInvoicePerYearPipe implements PipeTransform {
	transform(invoiceData: { date: number; value: number }[], favoritePeriod: number): { [year: number]: number } {
		const values: { [year: number]: number } = {};
		invoiceData.forEach((data) => {
			const date = new Date(data.date * 1000);
			let year;
			if (favoritePeriod > 7) {
				year = date.getFullYear() + (date.getMonth() <= favoritePeriod ? 0 : -1);
			} else {
				year = date.getFullYear() + (date.getMonth() >= favoritePeriod ? 0 : 1);
			}
			if (!values[year]) {
				values[year] = 0;
			}
			values[year] += Number(data.value);
		});

		return values;
	}
}
