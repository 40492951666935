import { Component } from "@angular/core";
import { Immutable } from "immer";
import { Modal } from "src/app/models/modal";
import { customParcelFeature, ParcelFeature } from "src/app/models/Parcel";

type Input = Immutable<{ insee: string }>;
type State = { insee: string; numero: string; prefixe: string; section: string; dep: string };
type Output = { type: OutputType.Save; parcel: ParcelFeature } | { type: OutputType.Cancel };

enum OutputType {
	Save,
	Cancel,
}

@Component({
	selector: "app-parcel-modal",
	templateUrl: "./parcel-modal.component.html",
})
export class ParcelModalComponent extends Modal<Input, State, Output> {
	static OutputType = OutputType;

	inputToState(input: Input): State {
		return { ...input, numero: "", prefixe: "", section: "", dep: "" };
	}

	cancel() {
		this.close({ type: OutputType.Cancel });
	}

	save() {
		const parcel = customParcelFeature(
			this.state.insee,
			this.state.numero,
			this.state.prefixe,
			this.state.prefixe,
			this.state.section,
		);

		this.close({ type: OutputType.Save, parcel });
	}
}
