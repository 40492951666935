import { Declaration } from "../models/declaration";
import { EnergyCategory } from "../models/energyCategory";
import { Consumptions } from "../models/functionalDeclaration";
import { z, ZodError } from "zod";
import { environment } from "../../environments/environment";

const catchErrorDisplay = ({ error }: { error: ZodError }) => {
	if (!environment.production) {
		console.warn(error);
	}
	return {};
};

const ConsumptionEntryZod = z.object({
	date_start: z.number().default(Declaration.MINIMUM_DATE_TIME),
	date_end: z.number().default(Declaration.MINIMUM_DATE_TIME),
	value: z.number().default(0).catch(0),
	source: z.string().default(""),
	commentary: z.string().default(""),
	partUmpteenth: z.number().default(100),
	totalUmpteenth: z.number().default(100),
	hasRepartitionKey: z.boolean().default(false),
});

const EnergyConsumptionsZod = z.object({
	values: z.array(ConsumptionEntryZod).default([]),
	start: z.number().default(Declaration.MINIMUM_DATE_TIME),
	end: z.number().optional(),
	deductTo: z.nativeEnum(EnergyCategory).default(EnergyCategory.INDIVIDUAL),
	byInvoice: z.boolean().default(false),
	hasRepartitionKey: z.boolean().default(false),
	partUmpteenth: z.number().default(100),
	totalUmpteenth: z.number().default(100),
	yearAverage: z.number().default(4),
	repartitionKeys: z
		.array(
			z.object({
				partUmpteenth: z.number(),
				totalUmpteenth: z.number(),
				start: z.number(),
				end: z.number().optional(),
			}),
		)
		.default([]),
	invoiceData: z
		.array(
			z.object({
				value: z.number(),
				date: z.number(),
			}),
		)
		.default([]),
});

/**
 * ZOD type for Consumptions
 */
const ConsumptionsZod = z
	.record(
		z
			.record(
				z.object({
					label: z.string(),
					values: z.record(EnergyConsumptionsZod).catch(catchErrorDisplay),
				}),
			)
			.catch(catchErrorDisplay),
	)
	.catch(catchErrorDisplay);

/**
 * Parse a consumptions and replace bad values (i.e. empty array instead of object) with default values
 * @param consumptions
 */
function fixConsumptionsType(consumptions: Consumptions): Consumptions {
	return ConsumptionsZod.parse(consumptions);
}

export const ZodParser = {
	fixConsumptionsType,
};
