<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">
			<mat-icon class="mr-2">crop</mat-icon>
			{{ state.title }}
		</h3>
		<app-surface-tabs [surface]="state.surface" (updateSurface)="state.surface = $event"></app-surface-tabs>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" (click)="cancel()">Annuler</button>
		<button class="btn-stroked-danger" (click)="delete()">Supprimer</button>
		<button
			class="btn-stroked-primary"
			[disabled]="
				state.surface.tertiaireArea <= 0 ||
				(state.surface.categorySurfaceAreas | apply: exemptedSum) > state.surface.tertiaireArea
			"
			(click)="save()"
		>
			Modifier
		</button>
	</div>
</div>
