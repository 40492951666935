import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { ActivityCategoryEntry, Irve } from "../models/asset";

@Pipe({
	name: "sortActivityCategoriesPerStart",
})
export class SortActivityCategoriesPerStartPipe implements PipeTransform {
	transform(
		immActivityCategories: Immutable<ActivityCategoryEntry[]>,
		start: number,
		end: number,
	): Immutable<ActivityCategoryEntry | AddBlock>[] {
		const activityCategories = [...immActivityCategories];
		// we sort the categories per start date
		activityCategories.sort(
			(activityCategory1, activityCategory2) => activityCategory1.start - activityCategory2.start,
		);

		// result array with categories and AddBlock
		const newArray: Immutable<ActivityCategoryEntry | AddBlock>[] = [];

		// previousEnd is used to save the end of the previous block while looping
		let previousEnd: number | undefined = start;
		for (let i = 0; i < activityCategories.length; i++) {
			// if previousEnd is undefined it means it's the end so if it is not the end
			// and the previousEnd (with a threshold of one day) is before the start of this block we add an AddBlock
			if (previousEnd && activityCategories[i].start > previousEnd) {
				// We add a day so that if it ends the 31, the next one will start on the 1st of next month for example
				// We make the add block start from the previousEnd to the next start
				newArray.push(
					new AddBlock(previousEnd, activityCategories[i].start, {
						type: "activityCategory",
						object: activityCategories[i],
					}),
				);
			}
			// Add the category to the table and set the previousEnd
			newArray.push(activityCategories[i]);
			previousEnd = activityCategories[i]?.end;
		}
		// Add the last AddBlock if necessary
		if (previousEnd !== undefined && previousEnd < end) {
			newArray.push(
				new AddBlock(previousEnd, undefined, {
					type: "activityCategory",
					object: activityCategories[activityCategories.length - 1],
				}),
			);
		}
		return newArray;
	}
}

export class AddBlock {
	constructor(
		public start: number,
		public end: number | undefined,
		public template: Immutable<
			{ type: "activityCategory"; object: ActivityCategoryEntry } | { type: "irve"; object: Irve }
		>,
	) {}
}
