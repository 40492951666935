import { Pipe, PipeTransform } from "@angular/core";
import { EnergyCategory, energyCategoryLabel } from "../models/energyCategory";

@Pipe({
	name: "energyCategoriesLabel",
})
export class EnergyCategoriesLabelPipe implements PipeTransform {
	transform(energyCategory: EnergyCategory): string {
		return energyCategoryLabel(energyCategory);
	}
}
