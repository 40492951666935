import { Pipe, PipeTransform } from "@angular/core";
import { Identifier, IdRefEtat } from "../models/identifier";

@Pipe({
	name: "asRefEtat",
})
export class AsRefEtatPipe implements PipeTransform {
	transform(id: Identifier): IdRefEtat | undefined {
		return "refEtat" in id ? id : undefined;
	}
}
