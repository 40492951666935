import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ResourceId, UserId } from "../models/ids";
import { Lazy } from "../models/lazy";
import { Representative } from "../models/representative";
import { ResourceType } from "../models/resource";
import { User } from "../models/user";
import { DeclarationGroupStateService } from "./declaration-group-state.service";
import { DeclarationStateService } from "./declaration-state.service";
import { FunctionalDeclarationStateService } from "./functional-declaration-state.service";
import { IsolationStateService } from "./isolation-state.service";
import { RepresentativeIsolationService } from "./representative-isolation.service";
import { RepresentativeStateService } from "./representative-state.service";
import { UserStateService } from "./user-state.service";
import { YearIndexWarningStorageService } from "./year-index-warning-storage.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	constructor(
		private http: HttpClient,
		private isolation: IsolationStateService,
		private userState: UserStateService,
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private declarationState: DeclarationStateService,
		private groupState: DeclarationGroupStateService,
		private representativeState: RepresentativeStateService,
		private representativeIsolation: RepresentativeIsolationService,
		private yearIndexWarningStorageService: YearIndexWarningStorageService,
	) {}

	login$(email: string, password: string): Observable<User> {
		return this.http.post(`${environment.baseApiUrl}/login`, { email, password }).pipe(map((obj) => User.parse(obj)));
	}

	register$(data: {
		user: User;
		representative: Partial<Lazy<Representative>>;
		password: string;
		confirm_password: string;
	}): Observable<{ user: User; representative: Lazy<Representative> }> {
		return this.http.post<{ user: User; representative: Lazy<Representative> }>(
			`${environment.baseApiUrl}/register`,
			data,
		);
	}

	updateUser$(user: User): Observable<User> {
		return this.http.put<User>(`${environment.baseApiUrl}/users`, user).pipe(
			map((obj) => User.parse(obj)),
			tap((user) => {
				this.userState.user = { ...user, token: this.userState.user?.token };
			}),
		);
	}

	updatePassword$(data: { current_password: string; password: string; password_confirm: string }): Observable<void> {
		return this.http.put<void>(`${environment.baseApiUrl}/users/password`, data);
	}

	resetPassword$(newPassword: string, repeatPassword: string, token: string, userId: UserId): Observable<User> {
		return this.http.put<User>(`${environment.baseApiUrl}/users/password/reset`, {
			id: userId,
			token: token,
			password: newPassword,
			password_confirm: repeatPassword,
		});
	}

	forgotPassword$(email: string): Observable<void> {
		return this.http.put<void>(`${environment.baseApiUrl}/users/password/forgot`, { email });
	}

	inviteUserToResource$(
		name: string,
		email: string,
		is_temporary: boolean,
		resourceId: ResourceId,
		resourceType: ResourceType,
	): Observable<void> {
		return this.http.post<void>(`${environment.baseApiUrl}/${resourceType}/${resourceId}/authorization`, {
			name,
			email,
			is_temporary,
		});
	}

	getInvitations$(resourceId: ResourceId, resourceType: ResourceType): Observable<User[]> {
		return this.http.get<User[]>(`${environment.baseApiUrl}/${resourceType}/${resourceId}/authorization`);
	}

	deleteInvitation$(userId: UserId, resourceId: ResourceId, resourceType: ResourceType): Observable<void> {
		return this.http.delete<void>(`${environment.baseApiUrl}/${resourceType}/${resourceId}/authorization/${userId}`);
	}

	logout() {
		const lastUrl = localStorage.getItem("lastUrl");
		// save displayWarning and put back to cache after clear
		const displayWarning = this.yearIndexWarningStorageService.getDisplay();
		this.representativeIsolation.clearCache();
		this.isolation.isolationInfo = undefined;
		localStorage.clear();
		this.userState.user = undefined;
		this.functionalDeclarationState.clear();
		this.declarationState.clear();
		this.groupState.clear();
		this.representativeState.clear();
		if (lastUrl) {
			localStorage.setItem("lastUrl", lastUrl);
		}
		this.yearIndexWarningStorageService.setDisplay(displayWarning);
	}
}
