import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Immutable } from "immer";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { Lazy } from "src/app/models/lazy";
import { dialogOpen } from "src/app/models/modal";
import { Representative } from "src/app/models/representative";
import { Nullable } from "../../../../../helpers/nullable";
import { unwrap } from "../../../../../helpers/unwrap";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { Declaration } from "../../../../../models/declaration";
import { DeclarationGroup } from "../../../../../models/declarationGroup";
import { DeclarationId } from "../../../../../models/ids";
import { ResourceType } from "../../../../../models/resource";
import { RouteDealer } from "../../../../../models/routes";
import { User } from "../../../../../models/user";
import { DeclarationService } from "../../../../../services/declaration.service";
import { AuthorizationListModalComponent } from "../../../authorization-list-modal/authorization-list-modal.component";
import { ExportCsvModalComponent } from "../../../export-csv-modal/export-csv-modal.component";
import { Page, PROGRESS_PAGES } from "../../../stepper/stepper.component";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { compareFunctionalDeclaration } from "src/app/helpers/sort-by-name";

@Component({
	selector: "app-declaration-row",
	templateUrl: "./declaration-row.component.html",
	styleUrls: ["./declaration-row.component.scss"],
})
export class DeclarationRowComponent extends BaseComponent implements OnChanges {
	@Input() declaration!: Immutable<Declaration>;
	@Input() group!: Immutable<DeclarationGroup>;
	@Input() user!: User;
	@Input() representative?: Immutable<Lazy<Representative>>;
	@Input() developAll = false;
	@Output() stepActionEmitter = new EventEmitter<{
		declaration: Immutable<Declaration>;
		page: Page;
	}>();
	@Output() deleteDeclaration = new EventEmitter<void>();

	isTokenUsedInDeclaration = false;
	develop = false;
	developing = false;
	PROGRESS_PAGES = PROGRESS_PAGES;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private declarationService: DeclarationService,
	) {
		super();
	}

	ngOnChanges() {
		this.isTokenUsedInDeclaration = this.declaration.declarations_functional.some(
			(functionalDeclaration) => functionalDeclaration.is_token_used,
		);
	}

	toggleDevelop() {
		if (this.develop) {
			this.developing = true;
			this.develop = !this.develop;
			setTimeout(() => {
				this.developing = false;
			}, 10);
		} else {
			this.developing = true;
			this.develop = !this.develop;
			setTimeout(() => {
				if (this.develop) {
					this.developing = false;
				}
			}, 500);
		}
	}

	openDeclarationAuthorizationListDialog(
		declaration: Immutable<Declaration>,
		declarationGroup: Immutable<DeclarationGroup>,
		userId: string,
	): void {
		if (declaration.declaration_id && declarationGroup.owner_id) {
			this.dialog.open(AuthorizationListModalComponent, {
				data: {
					resourceId: declaration.declaration_id,
					resourceType: "declaration",
					ownerId: declarationGroup.owner_id,
					userId,
				},
				panelClass: "p-0",
			});
		}
	}

	delete() {
		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Suppression d'une déclaration",
				description:
					"Vous êtes sur le point de supprimer une déclaration. " +
					"Une fois supprimées les données liées à cette " +
					"déclaration ne pourront pas être récupérées, " +
					"êtes-vous sûr de vouloir continuer ?",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((wantToDelete) => {
				if (wantToDelete) {
					this.deleteDeclaration.emit();
				}
			});
	}

	goToStats(id: Nullable<DeclarationId>) {
		this.router.navigate(RouteDealer.stats({ id: unwrap(id), type: ResourceType.Declaration }));
	}

	openGreenExport(resource: Immutable<DeclarationGroup | Declaration>) {
		dialogOpen(
			this.dialog,
			ExportCsvModalComponent,
			{ resource: resource, declarationService: this.declarationService },
			{ panelClass: "p-0" },
		);
	}

	readonly sortFunctionalDeclarations = (functionalDeclarations: Immutable<FunctionalDeclaration[]>) =>
		[...functionalDeclarations].sort(compareFunctionalDeclaration);
}
