<mat-dialog-content class="modal">
	<h3 class="mt-0">Liste de mes accès :</h3>
	<div class="actions">
		<ng-container *ngFor="let representative of authRepresentatives">
			<app-resource-link
				[representative]="representative"
				(selectRepresentative)="selectRepresentative($event)"
				[current]="representative.representative_id === currentResourceId"
			></app-resource-link>
		</ng-container>
		<ng-container *ngFor="let group of authGroups">
			<app-resource-link
				[group]="group"
				(selectDeclarationGroup)="selectGroup($event)"
				[current]="group.declaration_group_id === currentResourceId"
			></app-resource-link>
		</ng-container>
		<ng-container *ngFor="let declaration of authDeclarations">
			<app-resource-link
				[declaration]="declaration"
				(selectDeclaration)="selectDeclaration($event)"
				[current]="declaration.declaration_id === currentResourceId"
			></app-resource-link>
		</ng-container>
		<ng-container *ngFor="let functionalDeclaration of authEntities">
			<app-resource-link
				[functionalDeclaration]="functionalDeclaration"
				(selectFunctionalDeclaration)="selectFunctionalDeclaration($event)"
				[current]="functionalDeclaration.declaration_functional_id === currentResourceId"
			></app-resource-link>
		</ng-container>
	</div>
</mat-dialog-content>
