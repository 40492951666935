<ng-container *ngIf="!isCurrentYear(); else currentYear">
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="my-0">Vous êtes sur le point de consommer un crédit annuel</h3>
			<div class="dialog-small-text">
				<div class="d-flex-col gap-3">
					<div>
						En confirmant cette action, un crédit annuel sera consommé. Attention, pour une entité fonctionnelle, chaque
						déclaration d'une année ultérieure à 2021 nécessite de consommer un crédit annuel.
					</div>
					<div *ngIf="this.state.declarationGroup">
						Voulez-vous vraiment débloquer l'année {{ state.year }} pour cette déclaration ?
					</div>
					<div class="danger" *ngIf="!this.state.declarationGroup">
						Vous n'avez pas les droits suffisants pour consommer un crédit de déclaration annuelle. Merci de vous
						référer à un administrateur du compte ou à la personne vous ayant invités.
					</div>
				</div>
			</div>
		</div>
		<div class="dialog-actions">
			<button (click)="closeDialog(false)" class="btn-stroked-text-gray mr-auto">Annuler</button>
			<button
				(click)="closeDialog(true)"
				class="btn-stroked-primary"
				[disabled]="!this.state.declarationGroup || waitingForTokens"
			>
				<ng-container *ngIf="!waitingForTokens"> Continuer </ng-container>
				<mat-spinner *ngIf="waitingForTokens" [diameter]="20"></mat-spinner>
			</button>
		</div>
	</div>
</ng-container>

<ng-template #currentYear>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="my-0">Vous êtes sur le point de consommer un crédit annuel</h3>
			<div class="dialog-small-text">
				<div class="d-flex-col gap-3">
					<div>
						Il est actuellement impossible d'effectuer la déclaration pour l'année {{ state.year }}. La fonctionnalité
						sera ajoutée dès lors que la plateforme OPERAT le permettra.
					</div>
				</div>
			</div>
		</div>
		<div class="dialog-actions">
			<button [mat-dialog-close]="undefined" class="btn-stroked-text-gray ml-auto">Ok</button>
		</div>
	</div>
</ng-template>
