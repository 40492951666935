<div *ngIf="positionObservable | async as position; else spinner" style="border-radius: 15px; overflow: hidden">
	<app-my-map
		[style]="STYLE"
		[center]="position"
		[layers]="LAYERS"
		[featureStates]="featureStates.value"
		[labels]="labels.value"
		(layerMouseMove)="enableHover($event)"
		(layerMouseLeave)="disableHover($event)"
		(layerClick)="onLayerClick($event)"
		(mapLoad)="onMapLoad($event)"
	></app-my-map>
</div>
<ng-template #spinner>
	<div class="spinner">
		<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
	</div>
</ng-template>
