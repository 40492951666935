import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-file-button",
	templateUrl: "./file-button.component.html",
	styleUrls: ["./file-button.component.scss"],
})
export class FileButtonComponent {
	@Input() label = "Sélectionner un fichier";
	@Input() files: FileList | null = null;
	@Input() disabled = false;
	@Output() upload = new EventEmitter<void>();
	@Output() selectFiles = new EventEmitter<FileList>();
}
