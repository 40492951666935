import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk, cloneAgreementAsk } from "../models/agreementAsk";

@Pipe({
	name: "getPce",
})
export class GetPcePipe implements PipeTransform {
	transform(asks: Immutable<AgreementAsk[]> | AgreementAsk[], copy = true): Immutable<AgreementAsk[]> {
		const agreements = asks.filter((ask) => ask.type === "PCE");

		if (copy) {
			return agreements.map(cloneAgreementAsk);
		}
		return agreements;
	}
}
