var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// index.ts
import { point } from "@turf/helpers";
import { coordEach } from "@turf/meta";
function centroid(geojson, options = {}) {
  let xSum = 0;
  let ySum = 0;
  let len = 0;
  coordEach(geojson, function (coord) {
    xSum += coord[0];
    ySum += coord[1];
    len++;
  }, true);
  return point([xSum / len, ySum / len], options.properties);
}
__name(centroid, "centroid");
var turf_centroid_default = centroid;
export { centroid, turf_centroid_default as default };
