import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import * as turf from "@turf/turf";
import { Feature, FeatureCollection, Position } from "geojson";
import { Immutable } from "immer";
import maplibregl, { FeatureIdentifier, MapGeoJSONFeature } from "maplibre-gl";
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { FeatureState, LayerEvent, MyLayer } from "src/app/components/my-map/my-map.component";
import { Nullable } from "src/app/helpers/nullable";
import { unwrap } from "src/app/helpers/unwrap";
import { AddressInfo } from "src/app/models/address";
import { BaseComponent } from "src/app/models/base-component.directive";
import { BuildingFeature, BuildingInfo, toBuildingFeature } from "src/app/models/functionalDeclaration";
import { getParcelId, ParcelFeature, toParcelFeature } from "src/app/models/Parcel";
import { AddressSearchService } from "src/app/services/address-search.service";
import { formatParcel } from "../qualification-form-map.component";
import { STYLE } from "./style";
import {
	DataDrivenPropertyValueSpecification,
	ExpressionSpecification,
	LayerSpecification,
} from "@maplibre/maplibre-gl-style-spec";

@Component({
	selector: "app-building-map",
	templateUrl: "./building-map.component.html",
	styleUrls: ["./building-map.component.scss"],
})
export class BuildingMapComponent extends BaseComponent implements OnChanges {
	@Input() addressInfo?: Immutable<AddressInfo>;
	@Input() buildingInfos: Immutable<BuildingInfo[]> = [];
	@Input() parcelles: Immutable<(FeatureCollection | ParcelFeature)[]> = [];
	@Input() freezeHover = false;
	@Input() isSelectingBuilding = false;
	@Input() trackId: Nullable<number | string>;
	/**
	 * I use subjects like pipes. If I want to map something to use the result only in the template
	 * then I use an observable. This is more flexible.
	 */
	featureStates = new BehaviorSubject<FeatureState[]>([]);
	labels = new BehaviorSubject<[string, Immutable<Position>][]>([]);
	readonly STYLE = STYLE;
	readonly LAYERS = LAYERS;
	positionObservable: Observable<[number, number]>;
	runningTaskCount = 0;
	@Output() private buildingSelected = new EventEmitter<BuildingFeature>();
	@Output() private buildingUnselected = new EventEmitter<number>();
	@Output() private parcelleSelected = new EventEmitter<ParcelFeature>(); // possible multiple
	// parcelles
	@Output() private parcelleUnselected = new EventEmitter<string>();
	@Output() private mapLoad = new EventEmitter<maplibregl.Map>();
	private hoveredParcelSubject = new BehaviorSubject<MapGeoJSONFeature | null>(null);
	private selectedParcelsSubject = new BehaviorSubject<Immutable<(FeatureCollection | ParcelFeature)[]>>([]);
	private hoveredBuildingSubject = new BehaviorSubject<MapGeoJSONFeature | null>(null);
	private selectedBuildingsSubject = new BehaviorSubject<Immutable<(FeatureCollection | Feature)[]>>([]);
	private buildingInfosSubject = new BehaviorSubject<Immutable<BuildingInfo[]>>([]);
	private positionSubject = new ReplaySubject<Nullable<Immutable<AddressInfo>>>(1);
	private buildingSubject = new ReplaySubject<[number, number]>(1);

	constructor(private addressApi: AddressSearchService) {
		super();

		this.sub(
			/**
			 * When one subject has a new value all of this is executed.
			 */
			combineLatest([
				this.hoveredParcelSubject,
				this.selectedParcelsSubject,
				this.hoveredBuildingSubject,
				this.selectedBuildingsSubject,
			]).pipe(
				map(([hoveredParcel, selectedParcels, hoveredBuilding, selectedBuildings]) => {
					const featureStates: FeatureState[] = [];

					selectedParcels.forEach((parcel) => {
						if (parcel.type === "Feature") {
							const featureIdentifier: FeatureIdentifier = {
								source: SOURCE,
								sourceLayer: SOURCE_LAYER_PARCEL,
								id: parcel.id,
							};
							featureStates.push({ feature: featureIdentifier, state: { selected: true } });
						}
					});

					selectedBuildings.forEach((building) => {
						if (building.type === "Feature") {
							const featureIdentifier: FeatureIdentifier = {
								source: SOURCE,
								sourceLayer: SOURCE_LAYER_BUILDING,
								id: building.id,
							};
							featureStates.push({ feature: featureIdentifier, state: { selected: true } });
						}
					});

					if (hoveredParcel) {
						featureStates.push({ feature: hoveredParcel, state: { hover: true } });
					}

					if (hoveredBuilding) {
						featureStates.push({ feature: hoveredBuilding, state: { hover: true } });
					}

					return featureStates;
				}),
			),
			this.featureStates,
		);

		this.sub(
			this.buildingInfosSubject.pipe(
				map((infos) => {
					const labels: [string, Immutable<Position>][] = [];
					infos.forEach(({ label }) => {
						const position = label[1];
						if (position !== undefined) {
							labels.push([label[0] ? label[0] : "Sans nom", position]);
						}
					});
					return labels;
				}),
			),
			this.labels,
		);

		this.positionObservable = merge(
			this.positionSubject.pipe(
				distinctUntilChanged(),
				switchMap((info) =>
					info
						? this.addressApi
								.getPosition(`${info.houseNumber} ${info.streetName} ${info.cityName}`, info.cityName, info.cityCode)
								.pipe(map((pos) => [pos[0], pos[1]] as [number, number]))
						: EMPTY,
				),
			),
			this.buildingSubject,
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.freezeHover && changes.trackId && changes.trackId.previousValue !== changes.trackId.currentValue) {
			const [buildingInfo] = this.buildingInfos;
			if (buildingInfo !== undefined) {
				const building = buildingInfo.building;
				// I do casting because turf doesn't accept immutable variables.
				const position = turf.center(building as Feature | FeatureCollection).geometry.coordinates;
				if (position[0] === 0 && position[1] === 0) {
					this.positionSubject.next(this.addressInfo);
				} else {
					this.buildingSubject.next([position[0], position[1]]);
				}
			} else {
				this.positionSubject.next(this.addressInfo);
			}
		}

		this.selectedParcelsSubject.next(this.parcelles);
		this.selectedBuildingsSubject.next(this.buildingInfos.map((info) => info.building));
		this.buildingInfosSubject.next(this.buildingInfos);
	}

	setIsSelectingBuilding(value: boolean) {
		this.isSelectingBuilding = value;
	}

	onLayerClick({ layerId, event: { features } }: LayerEvent) {
		if (this.freezeHover) {
			return;
		}

		const [feature] = features ?? [];

		const buildingFeature = unwrap(toBuildingFeature(feature));

		if (this.isSelectingBuilding) {
			if (layerId === BATIMENTS_FILL) {
				const toRemove = this.buildingInfos
					.map((info) => (info.building.type === "Feature" ? info.building.id : info.building.features[0].id))
					.findIndex((id) => id === feature.id);
				if (toRemove !== -1) {
					this.buildingUnselected.emit(toRemove);
				} else {
					this.buildingSelected.emit(buildingFeature);
				}
			}
		} else if (layerId === PARCELLES_FILL) {
			const nullableFeatureParcel = toParcelFeature(feature);

			if (nullableFeatureParcel !== null) {
				const featureParcel = formatParcel(nullableFeatureParcel);
				const toRemove = this.parcelles.find((displayedParcel) => {
					if (displayedParcel.type === "FeatureCollection") {
						return false;
					}

					return displayedParcel.id === featureParcel.id;
				});
				if (toRemove !== undefined) {
					this.parcelleUnselected.emit(getParcelId(toRemove));
				} else {
					const geometry = feature.geometry;

					if (geometry.type === "Polygon" || geometry.type === "MultiPolygon") {
						this.parcelleSelected.emit(featureParcel);
						return;
					}

					throw new Error("Non polygon parcel");
				}
			}
		}
		return;
	}

	enableHover({ layerId, event: { features } }: LayerEvent) {
		const [feature] = features ?? [];

		if (this.freezeHover || feature === undefined || feature.properties === null) {
			return;
		}

		if (this.isSelectingBuilding) {
			if (layerId === BATIMENTS_FILL) {
				this.hoveredBuildingSubject.next(feature);
			}
		} else if (layerId === PARCELLES_FILL) {
			this.hoveredParcelSubject.next(feature);
		}
	}

	disableHover({ layerId }: LayerEvent) {
		if (this.freezeHover) {
			return;
		}

		if (this.isSelectingBuilding) {
			if (layerId === BATIMENTS_FILL) {
				this.hoveredBuildingSubject.next(null);
			}
		} else if (layerId === PARCELLES_FILL) {
			this.hoveredParcelSubject.next(null);
		}
	}

	onMapLoad(map: maplibregl.Map) {
		this.mapLoad.emit(map);
	}
}

const BATIMENTS_FILL = "batiments-fill";
const BATIMENTS_LINE = "batiments-line";
const PARCELLES_FILL = "parcelles-fill";
const PARCELLES_LINE = "parcelles-line";

const FILL_COLOR_EXPRESSION: DataDrivenPropertyValueSpecification<ExpressionSpecification> = [
	"case",
	["boolean", ["feature-state", "hover"], false],
	"rgba(54,92,193,150)",
	"rgba(150, 150, 150, 1)",
];

const PAINT_BUILDINGS_FILL: LayerSpecification["paint"] = {
	"fill-color": FILL_COLOR_EXPRESSION,
	"fill-opacity": {
		type: "interval" as const,
		stops: [
			[16, 0],
			[17, 0.6],
		],
	},
	"fill-antialias": true,
};

const PAINT_BUILDINGS_LINE: LayerSpecification["paint"] = {
	"line-opacity": 1,
	"line-color": ["case", ["boolean", ["feature-state", "selected"], false], "rgba(54,92,193,255)", "rgba(0, 0, 0, 1)"],
	"line-width": ["case", ["boolean", ["feature-state", "selected"], false], 4, 1],
};
// impossible to import the good types so I make one to please typescript
const PAINT_PARCELLES_FILL: LayerSpecification["paint"] = {
	"fill-color": "#75cbff",
	"fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.5, 0],
};

const PAINT_PARCELS_LINE: LayerSpecification["paint"] = {
	"line-color": "#75cbff",
	"line-width": 4,
	"line-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 1, 0],
};

const SOURCE = "cadastre";
const SOURCE_LAYER_PARCEL = "parcelles";
const SOURCE_LAYER_BUILDING = "batiments";

const LAYERS: MyLayer[] = [
	{
		id: BATIMENTS_FILL,
		source: SOURCE,
		"source-layer": SOURCE_LAYER_BUILDING,
		type: "fill",
		paint: PAINT_BUILDINGS_FILL,
	},
	{
		id: BATIMENTS_LINE,
		source: SOURCE,
		"source-layer": SOURCE_LAYER_BUILDING,
		type: "line",
		paint: PAINT_BUILDINGS_LINE,
	},
	{
		id: PARCELLES_FILL,
		source: SOURCE,
		"source-layer": SOURCE_LAYER_PARCEL,
		type: "fill",
		paint: PAINT_PARCELLES_FILL,
	},
	{
		id: PARCELLES_LINE,
		source: SOURCE,
		"source-layer": SOURCE_LAYER_PARCEL,
		type: "line",
		paint: PAINT_PARCELS_LINE,
	},
];
