import { now } from "@amcharts/amcharts5/.internal/core/util/Time";
import { Immutable } from "immer";
import { AgreementAsk } from "../models/agreementAsk";
import { OnCoolodwnPipe } from "../pipes/on-coolodwn.pipe";

/**
 * Return if agreement can be renewed
 * Condition is agreement treated or has been asked for renewal with no answers, and hasn't been asked in the last 6 months
 * @param agreement
 */
export function canAskForAgreementRenewal(agreement: Immutable<AgreementAsk>) {
	const onCooldownPipe = new OnCoolodwnPipe();
	return (
		(agreement.is_treated ||
			(!agreement.is_accepted && !onCooldownPipe.transform(agreement) && !!agreement.last_renewal)) &&
		!renewalAskedRecently(agreement)
	);
}

export function renewalAskedRecently(agreement: Immutable<AgreementAsk>) {
	if (!agreement.last_renewal) {
		return false;
	}
	const date = new Date(agreement.last_renewal * 1000);
	const yearDiff = now().getFullYear() - date.getFullYear();

	const monthDiff = now().getMonth() + 12 * yearDiff - date.getMonth();

	if (monthDiff < 6) return true;

	if (monthDiff === 6) {
		const dayDiff = now().getDay() - date.getDay();
		return dayDiff < 0;
	}

	return false;
}
