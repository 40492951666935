import { Pipe, PipeTransform } from "@angular/core";
import { EnergyType } from "../models/energyType";
import { ConsumptionEntry } from "../models/functionalDeclaration";

@Pipe({
	name: "noValueForYear",
})
export class NoValueForYearPipe implements PipeTransform {
	transform(energy: EnergyType, values: ConsumptionEntry[], start: number, end: number): boolean {
		for (const value of values) {
			if (value.date_start >= start && value.date_start < end) {
				return false;
			} else if (value.date_start < start && value.date_end <= end && value.date_end > start) {
				return false;
			} else if (value.date_start <= start && value.date_end >= end) {
				return false;
			}
		}
		return true;
	}
}
