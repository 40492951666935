<ng-container *ngIf="(declarationState.get$ | async)?.value as declaration">
	<div *ngIf="!loading" style="overflow: auto">
		<h3>Demandes d'autorisation d'accéder aux données de consommation d'électricité et de gaz</h3>

		<div class="d-flex" style="justify-content: space-between">
			<div class="input-icon">
				<input
					[(ngModel)]="search"
					class="custom-input-light"
					style="min-width: 370px; display: block"
					placeholder="Rechercher une entité fonctionnelle"
					(ngModelChange)="updateSearch()"
				/>
				<span class="input-suffix">
					{{ filteredDeclarationsFunctional.length }}
					<mat-icon>domain</mat-icon>
				</span>
			</div>
			<button mat-button class="btn-primary-light" (click)="openHistoryModal()">Obtenir l'historique long GRDF</button>
		</div>
		<div
			*ngIf="(todayString | dateToTimestamp) < (grdfMaintenanceEnd | dateToTimestamp)"
			class="message-warning my-2"
			style="align-items: center"
		>
			<mat-icon>warning</mat-icon>
			<p class="p-0 m-0">
				Le service de récupération de données de consommations GRDF sera indisponible du jeudi 24 novembre 2022 au mardi
				29 novembre 2022 inclus. Le
				<a (click)="openHistoryModal(); $event.preventDefault()" class="white" href="#"
					>service d'obtention d'historique long</a
				>
				reste cependant disponible.
			</p>
		</div>

		<div class="conso-list-page">
			<ng-container
				*ngFor="let functionalDeclaration of declarationsFunctionalToShow; index as siteCount"
				style="vertical-align: top"
			>
				<ng-container
					*ngIf="
						(functionalDeclaration.agreement_asks | getPce).length +
							(functionalDeclaration.agreement_asks | getPrm).length >
							0 && functionalDeclaration.declaration_functional_id
					"
				>
					<div class="agreement-container">
						<p class="agreement-container-title">
							<b
								>Site {{ siteCount + 1 + (page - 1) * 10 }} -
								{{ functionalDeclaration.name ?? (functionalDeclaration.address | addressToString) }}</b
							>
							<i class="text-gray">&nbsp;{{ functionalDeclaration | functionalDeclarationSubtitle }}</i>
						</p>
						<div class="d-flex gap-2">
							<div class="w-50">
								<app-agreement-container
									*ngIf="(functionalDeclaration.agreement_asks | getPrm).length > 0"
									[agreements]="functionalDeclaration.agreement_asks | getPrm"
									color="accent"
									listTitle="PRM"
									icon="bolt"
									[showStatus]="true"
									[canGoAssetDeclaration]="true"
									(goAssetDeclaration)="
										router.navigate(RouteDealer.assetDeclaration(functionalDeclaration.declaration_functional_id))
									"
									(resendAction)="sendAgreementInvite(declaration, $event.agreement_ask_id)"
									(renewalAction)="
										$event.agreement_ask_id ? sendRenewalAgreement(declaration, $event.agreement_ask_id) : null
									"
								></app-agreement-container>
							</div>
							<div class="w-50">
								<app-agreement-container
									*ngIf="(functionalDeclaration.agreement_asks | getPce).length"
									[agreements]="functionalDeclaration.agreement_asks | getPce"
									color="accent2"
									listTitle="PCE"
									icon="local_fire_department"
									[showStatus]="true"
									[canGoAssetDeclaration]="true"
									(goAssetDeclaration)="
										router.navigate(RouteDealer.assetDeclaration(functionalDeclaration.declaration_functional_id))
									"
									(resendAction)="sendAgreementInvite(declaration, $event.agreement_ask_id)"
									(renewalAction)="
										$event.agreement_ask_id ? sendRenewalAgreement(declaration, $event.agreement_ask_id) : null
									"
								></app-agreement-container>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<app-pagination [currentPage]="page" [maxPage]="maxPage" (pageChange)="setPage($event)"></app-pagination>
			<div style="margin-top: 15px">
				<ng-container *ngIf="this.canSendOne">
					<p>
						Un email va être envoyé à chaque destinataire afin qu'il donne son autorisation d'accéder aux données de
						consommations de gaz et d'électricité.
					</p>
					<p>Le processus est entièrement numérique et simple à réaliser.</p>
				</ng-container>
				<div class="d-flex">
					<button
						*ngIf="this.canSendOne"
						class="btn-primary"
						style="margin-right: 10px"
						(click)="sendAgreementInvite(declaration)"
					>
						Envoyer toutes les demandes d'autorisation
					</button>
					<button mat-flat-button class="bg-primary-light" (click)="openRenewalModal()" style="margin-right: 10px">
						Renouveler toutes les demandes
					</button>
				</div>
				<button mat-flat-button class="btn-accent3 mt-5" (click)="goToTable(declaration)">
					Déclaration des consommations
				</button>
			</div>
		</div>
		<div *ngIf="loading">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</ng-container>

<ng-template #modalRenewal>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="my-0">Renouvellement du consentement</h3>
			<div class="dialog-small-text">
				<div class="d-flex-col gap-3">
					<div>
						Attention, les données récupérées suite au renouvellement du consentement sont limitées à l'historique
						court. Les données déjà renseignées ne seront pas remplacées, qu'elles aient été saisies manuellement ou
						récupérées automatiquement. La récupération comblera donc simplement les trous de données.
					</div>
					<div>
						Le renouvellement en masse des consentements concernera uniquement les demandes n'ayant pas reçu de réponse
						positive dans les 6 derniers mois. Pour forcer le renvoi d'une demande déjà effectuée, utiliser le bouton
						<mat-icon>sync</mat-icon> (Renouveler les accords) à droite de cette dernière.
					</div>
					<div>Les demandes d'autorisation pour les PRM et PCE suivants seront envoyées :</div>
					<div class="d-flex" style="justify-content: space-between">
						<div
							class="danger"
							*ngIf="
								getAvailableAgreementsForRenewal().length === 0 && getAvailableAgreementsForRenewal('PCE').length === 0
							"
						>
							Aucune demande de renouvellement ne peut être envoyée.
						</div>
						<fieldset class="box b-1-accent w-100" *ngIf="getAvailableAgreementsForRenewal().length > 0">
							<legend class="box-title">
								<mat-icon><span class="material-symbols-outlined">bolt</span></mat-icon>
								PRM
							</legend>
							<div *ngFor="let agreement of getAvailableAgreementsForRenewal()" class="mb-2">
								<h3 class="my-0">{{ agreement.reference }}</h3>
								<span class="text-gray">{{ agreement.email }}</span>
							</div>
						</fieldset>
						<fieldset class="box b-1-accent2 w-100" *ngIf="getAvailableAgreementsForRenewal('PCE').length > 0">
							<legend class="box-title">
								<mat-icon><span class="material-symbols-outlined">local_fire_department</span></mat-icon>
								PCE
							</legend>
							<div *ngFor="let agreement of getAvailableAgreementsForRenewal('PCE')" class="mb-2">
								<h3 class="my-0">{{ agreement.reference }}</h3>
								<span class="text-gray">{{ agreement.email }}</span>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</div>
		<div class="dialog-actions d-flex" style="justify-content: space-between">
			<button class="btn-stroked-text-gray" [mat-dialog-close]="undefined">Annuler</button>
			<button class="btn-stroked-primary" (click)="sendAvailableRenewals()" [mat-dialog-close]="undefined">
				Confirmer
			</button>
		</div>
	</div>
</ng-template>
