import { Pipe, PipeTransform } from "@angular/core";
import { Page } from "../components/navigation/stepper/stepper.component";

@Pipe({
	name: "stepActiveFromPage",
})
export class StepActiveFromPagePipe implements PipeTransform {
	transform(page: Page, segment: string): boolean {
		return page.segmentsForStepper.some((route) => route === segment);
	}
}
