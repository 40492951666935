<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<div class="custom-input-group">
			<label
				>Description ({{ state.affectedIds.length }} EFA concernée{{ state.affectedIds.length > 1 ? "s" : "" }})</label
			>
			<input [(ngModel)]="state.description" class="custom-input" placeholder="Nouvelle description" />
		</div>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [mat-dialog-close]="undefined">Annuler</button>
		<button (click)="save()" class="btn-stroked-primary">Sauvegarder</button>
	</div>
</div>
