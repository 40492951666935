import { Pipe, PipeTransform } from "@angular/core";
import { StreetType, StreetTypeString } from "../models/StreetType";

@Pipe({
	name: "asStreetType",
})
export class AsStreetTypePipe implements PipeTransform {
	transform(str: string): string {
		return StreetType[str as StreetTypeString];
	}
}
