import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "categoryKeyToLabel",
})
export class CategoryKeyToLabelPipe implements PipeTransform {
	transform(value: string): string {
		return KEY_TO_LABEL[value] ?? value;
	}
}

const KEY_TO_LABEL: Record<string, string> = {
	extension: "Extension",
	installationPrecaire: "Installation précaire",
	culte: "Bâtiments de culte",
	defense: "Activité de défense",
	isCentreCommandementOperationnelInterventionForceMilitaire:
		"Centres ou cellules de commandement opérationnel des interventions des forces militaires",
	isSiteStrategique: "Sites stratégiques",
	isLocauxSimulateurVolOuConduiteEngin: "Locaux accueillant des simulateurs de vol ou de conduites d'engins",
	isServeurDataCenterActiviteOperationnelleFinDeDefense:
		"Serveurs et data center liés aux activités opérationnelles à des fins de défense",
	securiteCivile: "Sécurité civile",
	isCentreOuCelluleCommandeSuiviOperationnelDesServicesDepartementauxIncendieEtSecours:
		"Centre ou cellule de commandement et de suivi opérationnel des services départementaux d'incendies et de secours",
	isCaserneDePompiers:
		"Casernes de pompiers (partie bureaux cf. FAQ, zones d'entraînement, de stationnement et de stockage opérationnel, lieux de vie)",
	isCentreAppelEtIntervention: "Centre d'appels et d'intervention (SAMU, 112, …)",
	isCentreGestionTraficAerienFerroviaireMaritimeFluvial:
		"Centres de gestion du trafic aérien, ferroviaire, maritime et fluvial",
	isCelluleOuPosteDeCommandementDeGestionDeCriseEnMinistereEtEnPrefecture:
		"Cellules ou poste de commandement de gestion de crise en ministère et en préfecture",

	sureteInterieureDuTerritoire: "Sûreté intérieure du territoire",
	isCentreOuCellulesDeCommandementEtDeSuiviAuNiveauDuMinistereDeLInterieurEtEnPrefecture:
		"Centre ou cellules de commandement et de suivi au niveau du ministère de l'intérieur et en préfecture",
	isCellulesStrategiquesDeSureteInterieureDuTerritoire: "Cellules stratégiques de sûreté intérieure du territoire",
	isServeursEtDataCenterLiesAuxActivitesOperationnellesADesFinDeSureteDuTerritoire:
		"Serveurs et data center liés aux activités opérationnelles à des fins de sûreté du territoire",

	stockage: "Stockage sur un site industriel",
	isStockageDeMatieresPremieres: "Stockage de matières premières",
	isStockageDeProduitsFinisenFluxTendus: "Stockage de produits finis en flux tendus (stockage inférieur à 3 à 5 jours)",

	locauxPourLePersonnelHeberge: "Locaux pour le personnel hébergé",
	isActivitePrincipaleIndustrielleOuArtisanale: "Si activité principale industrielle ou artisanale",
	stationnementPublic: "Stationnement public",
	isParcsDeStationnementACielOuvert: "Parcs de stationnement à ciel ouvert",

	locauxNonExploites: "Locaux non exploités",
	isLocauxVacantsOuDesaffectes: "Si locaux vacants ou désaffectés : non assujettis",

	etablissementSociauxEtMedicoSociaux: "Etablissements sociaux et médico-sociaux",
	isCrpEsatNonLiesAuTertiaire: "CRP ESAT non liés au tertiaire",
	isEtablissementsDAccueilNonMedicalises: "Etablissements d'accueil non médicalisés",
	isServicesDHebergementSocial: "Services d'hébergement social, que ce soit pour les personnes âgés ou handicapées",
	residencesDeTourisme: "Résidences de tourisme",
	isGites: "Gîtes",
	isMeublesDeTourismeEtAirbnb: "Meublés de tourisme et Airbnb",
	isAppartementEnMandatDeGestion: "Appartement en mandat de gestion",
	laboratoireDeRechercheDAnalyseMecaniqueOuDEssais: "Laboratoire de recherche, d'analyse mécanique ou d'essais",
	isLiesALaRechercheEtAuDeveloppementIndustriel: "Liés à la recherche et au développement industriel",
	entretienDesRoutesEtDesAutoroutes: "Entretien des routes et des autoroutes",
	isUniteDEmulsion: "Unités d'émulsion",
	isCentralesDEnrobes: "Centrales d'enrobés",
	equipementsSportifs: "Equipements sportifs",
	isUniquementPiscineExterieur: "Piscines uniquement extérieures",
	logementsDeFonction: "Logements de fonction",
	isLogementAssociesAuxEtablissementsScolaires: "Logements associés aux établissement scolaires",
};
