import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
	selector: "app-image-input",
	templateUrl: "./image-input.component.html",
	styleUrls: ["./image-input.component.scss"],
})
export class ImageInputComponent {
	// Id is necessary otherwise there is confusion between inputs
	static id = 0;
	id;

	@Input() label = "image";
	@Input() name = "";
	@Input() placeholder = "Choisir une image (JPG,PNG,SVG) ...";
	@Input() accept = ".png,.jpeg,.jpg,.svg,image/png,image/jpeg,image/svg";
	@Output() fileUploaded = new EventEmitter<void>();
	result: string | undefined;
	@ViewChild("previewImageComponent") previewImageComponent!: ElementRef;
	@ViewChild("imageInput") imageInput!: ElementRef;

	constructor() {
		this.id = ImageInputComponent.id;
		ImageInputComponent.id++;
	}

	previewImage(input: HTMLInputElement) {
		this.fileUploaded.emit();
		if (input.files) {
			const file = input.files?.item(0);
			if (file) {
				const navLogo = this.previewImageComponent.nativeElement;
				if (navLogo && navLogo instanceof HTMLImageElement) {
					this.result = file.name;
					navLogo.src = URL.createObjectURL(file);
					navLogo.classList.remove("d-none");
				}
			}
		}
	}
}
