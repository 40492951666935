import { Component } from "@angular/core";
import { BaseComponent } from "../../models/base-component.directive";
import { AuthorizationViewLoaderService } from "../../services/authorization-view-loader.service";

@Component({
	selector: "app-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
})
export class RegisterComponent extends BaseComponent {
	constructor(private authorizationViewLoader: AuthorizationViewLoaderService) {
		super();
	}

	login() {
		this.authorizationViewLoader.loadModal(true);
	}
}
