import { GreenKeys, GreenKeys as Keys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Nullable } from "../helpers/nullable";
import { Declaration } from "./declaration";
import { FunctionalDeclaration } from "./functionalDeclaration";
import {
	Condominium,
	IdRefEtat,
	IdRna,
	IdSiret,
	Owner,
	Siren,
	Siret,
	Tenant,
	TenantWithoutSiret as TenantWithoutSiretId,
} from "./identifier";
import { Lazy } from "./lazy";

/**
 * The reference_operat_efa uses the tenant's identifier if they exist.
 * Else the reference uses the owner's one.
 */

/**
 * If Tenant then the identifier is the (tenant + establishment)'s one.
 * If Condominium then the identifier is the (condominium + establishment)'s one.
 * If Landlord then the identifier is the owner's one.
 * If OwnerOccupant then the identifier is the (owner + tenant + establishment)'s one
 */

export type OwnerOccupantWithSiret = {
	[Keys.KEY_WITHOUT_SIRET]: false;
	[Keys.KEY_SIRET]: Siret;
	[Keys.KEY_CONDOMINIUM]: Nullable<Condominium>;
};
export type OwnerOccupantWithoutSiret = {
	[Keys.KEY_WITHOUT_SIRET]: true;
	[Keys.KEY_CONDOMINIUM]: Nullable<Condominium>;
	[Keys.KEY_DENOMINATION]: string;
};

export type OwnerWithSiret = {
	[Keys.KEY_TENANT_WITHOUT_SIRET]: false;
	[Keys.KEY_TENANT]: Nullable<Tenant>;
	[Keys.KEY_CONDOMINIUM]: Nullable<Condominium>;
};
export type OwnerWithoutSiret = {
	[Keys.KEY_TENANT_WITHOUT_SIRET]: true;
	[Keys.KEY_TENANT]: TenantWithoutSiretId;
	[Keys.KEY_CONDOMINIUM]: Nullable<Condominium>;
	[Keys.KEY_DENOMINATION]: string;
};

export type TenantWithSiret = {
	[Keys.KEY_WITHOUT_SIRET]: false;
	[Keys.KEY_SIRET]: Siret;
	[Keys.KEY_OWNER]: Owner;
	[Keys.KEY_CONDOMINIUM]: Nullable<Condominium>;
};
export type TenantWithoutSiret = {
	[Keys.KEY_WITHOUT_SIRET]: true;
	[Keys.KEY_OWNER]: Owner;
	[Keys.KEY_CONDOMINIUM]: Nullable<Condominium>;
	[Keys.KEY_DENOMINATION]: string;
};

export type CondominiumWithSiret = {
	[Keys.KEY_TENANT_WITHOUT_SIRET]: false;
	[Keys.KEY_TENANT]: Nullable<Tenant>;
	[Keys.KEY_OWNER]: Owner;
	[Keys.KEY_SIRET]: Siret;
};
export type CondominiumWithoutSiret = {
	[Keys.KEY_TENANT_WITHOUT_SIRET]: true;
	[Keys.KEY_TENANT]: TenantWithoutSiretId;
	[Keys.KEY_OWNER]: Owner;
	[Keys.KEY_DENOMINATION]: string;
	[Keys.KEY_SIRET]: Siret;
};

export type CaseSirenStructure = {
	[Keys.KEY_TYPE]: Keys.KEY_SIREN;
	[Keys.KEY_STATUS]:
		| {
				[Keys.KEY_TYPE]: Keys.KEY_OWNER_OCCUPANT;
				[Keys.KEY_DATA]: OwnerOccupantWithSiret | OwnerOccupantWithoutSiret;
		  }
		| {
				[Keys.KEY_TYPE]: Keys.KEY_LANDLORD;
				[Keys.KEY_DATA]: OwnerWithSiret | OwnerWithoutSiret;
		  }
		| {
				[Keys.KEY_TYPE]: Keys.KEY_TENANT;
				[Keys.KEY_DATA]: TenantWithSiret | TenantWithoutSiret;
		  }
		| {
				[Keys.KEY_TYPE]: Keys.KEY_CONDOMINIUM;
				[Keys.KEY_DATA]: CondominiumWithSiret | CondominiumWithoutSiret;
		  };
};

export type CaseRnaOrOtherStructure = {
	[Keys.KEY_TYPE]: Keys.KEY_RNA;
	[Keys.KEY_STATUS]:
		| ((
				| { [Keys.KEY_TYPE]: Keys.KEY_OWNER_OCCUPANT }
				| { [Keys.KEY_TYPE]: Keys.KEY_LANDLORD; [Keys.KEY_TENANT]: Nullable<Tenant> }
				| { [Keys.KEY_TYPE]: Keys.KEY_TENANT; [Keys.KEY_OWNER]: Owner }
		  ) & { [Keys.KEY_CONDOMINIUM]: Nullable<Condominium> })
		| { [Keys.KEY_TYPE]: Keys.KEY_CONDOMINIUM; [Keys.KEY_TENANT]: Nullable<Tenant>; [Keys.KEY_OWNER]: Owner };
};

export type EfaStatus = (CaseSirenStructure | CaseRnaOrOtherStructure) & {
	ownerStartDate?: Nullable<string>; // nullable because it is a breaking change from 4.12 -> 4.13 so the users must provide the dates
	secondaryOwner?: Nullable<{ owner: Owner; startDate?: Nullable<string> }>;
};

export function getOwnerIfExists(efaStatus: Immutable<EfaStatus>): Nullable<Immutable<Owner>> {
	if (efaStatus.type === Keys.KEY_SIREN) {
		const { status } = efaStatus;
		if (status.type === Keys.KEY_TENANT) {
			return status.data.owner;
		}
	}
	if (efaStatus.type === Keys.KEY_RNA) {
		const { status } = efaStatus;
		if (status.type === Keys.KEY_TENANT) {
			return status.owner;
		}
	}
	return undefined;
}

export function getCondominiumIfExists(efaStatus: Immutable<EfaStatus>): Nullable<Immutable<Condominium>> {
	if (efaStatus.type === Keys.KEY_SIREN) {
		const { status } = efaStatus;
		if (status.type !== Keys.KEY_CONDOMINIUM) {
			return status.data.condominium;
		}
	}
	if (efaStatus.type === Keys.KEY_RNA) {
		const { status } = efaStatus;
		if (status.type !== Keys.KEY_CONDOMINIUM) {
			return status.condominium;
		}
	}
	return undefined;
}

export function cloneEfaStatus(efaStatus: Immutable<EfaStatus>): EfaStatus {
	return structuredClone(efaStatus);
}

export function getOperatReference(
	functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>,
	declaration: Immutable<Lazy<Declaration>>,
): string {
	const efaStatus = functionalDeclaration[Keys.KEY_EFA_STATUS];
	const pad = (num: number, places: number) => String(num).padStart(places, "0");
	let identifier = getCsvIdentifierStringFromEfaStatus(efaStatus);
	identifier = identifier ? identifier : Declaration.getIdentifier(declaration);
	const creationMethod = (functionalDeclaration.operat_creation_method ?? "CSV").toUpperCase();
	return `${identifier}_${functionalDeclaration[Keys.KEY_ADDRESS].postalCode}_${creationMethod}_${pad(functionalDeclaration[Keys.KEY_INDEX], 6)}`;
}

export function getIdentifierFromEfaStatus(
	efaStatus: Immutable<EfaStatus>,
): IdRna | IdSiret | IdRefEtat | { [Keys.KEY_DENOMINATION]: string; siren?: Siren } | undefined {
	if (efaStatus.type === "siren") {
		if (
			(efaStatus.status.type === Keys.KEY_CONDOMINIUM || efaStatus.status.type === Keys.KEY_LANDLORD) &&
			efaStatus.status.data.tenant
		) {
			// Si "Etablissement_sans_SIRET"= 'OUI'
			// la valeur du champ "Type_identifiant_occupant" est obligatoirement égale à 'SIREN', sous peine de rejet.
			// IdEtablissement = Identifiant de l'établissement assujetti/occupant
			return efaStatus.status.data.tenant_without_siret
				? { [Keys.KEY_DENOMINATION]: efaStatus.status.data.denomination, siren: efaStatus.status.data.tenant.siren }
				: { ...efaStatus.status.data.tenant };
		}
		if (efaStatus.status.type === Keys.KEY_OWNER_OCCUPANT || efaStatus.status.type === Keys.KEY_TENANT) {
			return efaStatus.status.data.without_siret
				? { [Keys.KEY_DENOMINATION]: efaStatus.status.data.denomination, siren: undefined }
				: { [Keys.KEY_SIRET]: efaStatus.status.data.siret };
		}

		return undefined;
	}

	if (
		(efaStatus.status.type === Keys.KEY_CONDOMINIUM || efaStatus.status.type === Keys.KEY_LANDLORD) &&
		efaStatus.status.tenant
	) {
		return { ...efaStatus.status.tenant };
	}

	return undefined;
}

/**
 * Get Identifier for OPERAT's reference so EFA's denomination is ignored
 * @param efaStatus
 */
export function getCsvIdentifierStringFromEfaStatus(efaStatus: Immutable<EfaStatus>): string | undefined {
	const identifier = getIdentifierFromEfaStatus(efaStatus);

	if (!identifier) {
		return "";
	}

	return "rna" in identifier
		? identifier.rna
		: "siret" in identifier
			? identifier.siret
			: "siren" in identifier
				? identifier.siren
				: "refEtat" in identifier
					? identifier.refEtat
					: undefined;
}

export function getIdentifierStringFromEfaStatus(efaStatus: Immutable<EfaStatus>): string {
	const identifier = getIdentifierFromEfaStatus(efaStatus);

	if (!identifier) {
		return "";
	}

	return "rna" in identifier
		? identifier.rna
		: "siret" in identifier
			? identifier.siret
			: "refEtat" in identifier
				? identifier.refEtat
				: identifier[GreenKeys.KEY_DENOMINATION];
}

export function getSiretCompatibleWithStructureIfExists(efaStatus: Immutable<EfaStatus>): Siret | undefined {
	const identifier = getIdentifierFromEfaStatus(efaStatus);
	return identifier !== undefined && "siret" in identifier ? identifier.siret : undefined;
}
