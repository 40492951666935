import { Immutable } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { mapJumpTo$ } from "src/app/helpers/to-observable";
import * as turf from "@turf/turf";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { MapGeoJSONFeature, Map } from "maplibre-gl";

export function restoreId$(
	cadastreMap: Map,
	feature: Immutable<Feature<Polygon | MultiPolygon, unknown>>,
	layer: "batiments-fill" | "parcelles-fill",
): Observable<string | number | undefined> {
	const center = turf.center(feature as Feature);

	return mapJumpTo$(cadastreMap, { center: center.geometry.coordinates as [number, number], zoom: 17 }).pipe(
		map(() => {
			return getMaxFeature(
				cadastreMap.queryRenderedFeatures(undefined, { layers: [layer] }),
				(mapGeoJsonFeature: MapGeoJSONFeature) => {
					const geometry = mapGeoJsonFeature.geometry;
					if (geometry.type === "Point") {
						return 0;
					}
					const intersection = turf.intersect(
						turf.helpers.featureCollection([
							turf.helpers.feature(geometry as Polygon | MultiPolygon),
							turf.helpers.feature(feature.geometry as Polygon | MultiPolygon),
						]),
					);

					return intersection !== null ? turf.area(intersection) : 0;
				},
			)?.id;
		}),
	);
}

/**
 * Similar to maxByKey without Immutable to prevent 'Type instantiation is excessively deep and possibly infinite' error
 */
function getMaxFeature(
	list: MapGeoJSONFeature[],
	f: (value: MapGeoJSONFeature) => number,
): MapGeoJSONFeature | undefined {
	if (list.length === 0) {
		return undefined;
	}

	// eslint-disable-next-line prefer-const
	let [maxValue, ...rest] = list;

	let maxKey = f(maxValue);

	for (const value of rest) {
		const key = f(value);
		if (key > maxKey) {
			maxValue = value;
			maxKey = key;
		}
	}

	return maxValue;
}
