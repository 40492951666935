import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { produce, Immutable } from "immer";
import { EMPTY, Observable, of, zip } from "rxjs";
import { catchError, distinctUntilKeyChanged, first, map, switchMap } from "rxjs/operators";
import { getBestGoalReportFromFunctionalDeclaration } from "src/app/helpers/conso";
import { unwrap } from "src/app/helpers/unwrap";
import { entityCanAccessPremiumFeatures, FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { allConsumptionsIncomplete } from "src/app/pipes/all-consumptions-incomplete.pipe";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { DeclarationId } from "../../../../../models/ids";
import { RouteDealer } from "../../../../../models/routes";
import { canReachStep } from "../../../../../pipes/can-reach-step.pipe";
import { DeclarationFunctionalService } from "../../../../../services/declaration-functional.service";
import { DeclarationGroupStateService } from "../../../../../services/declaration-group-state.service";
import { DeclarationStateService } from "../../../../../services/declaration-state.service";
import { RepresentativeStateService } from "../../../../../services/representative-state.service";
import { TourStateService } from "../../../../../services/tour-state.service";
import { SEARCH_PAGE } from "../../stepper.component";
import { YearReferenceDataComponent } from "./year-reference-data/year-reference-data.component";
import { GreenKeys } from "@grs/greenkeys";
import { dialogOpen } from "../../../../../models/modal";
import { ConsumeTokenDialogComponent, ConsumeTokenMessageType } from "../asset-declaration/consume-token-dialog";

type Data = {
	djus: { [year: string]: number };
	absoluteObjectives: { [year: string]: number };
	functionalDeclaration: Immutable<FunctionalDeclaration>;
};

@Component({
	selector: "app-year-reference",
	templateUrl: "./year-reference.component.html",
	styleUrls: ["./year-reference.component.scss"],
})
export class YearReferenceComponent extends BaseComponent implements OnDestroy {
	readonly asyncData$: Observable<Data>;

	readonly RouteDealer = RouteDealer;

	@ViewChild(YearReferenceDataComponent) yearReferenceDataComponent: YearReferenceDataComponent | undefined;
	readonly allConsumptionsIncomplete = allConsumptionsIncomplete;

	constructor(
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private router: Router,
		private declarationFunctionalService: DeclarationFunctionalService,
		public declarationState: DeclarationStateService,
		private representativeState: RepresentativeStateService,
		private declarationGroupState: DeclarationGroupStateService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		tourState: TourStateService,
	) {
		super();

		tourState.resetTour();

		this.asyncData$ = this.functionalDeclarationState.get$.pipe(
			switchMap((declarationFunctional) => (declarationFunctional ? of(declarationFunctional.value) : EMPTY)),
			switchMap((functionalDeclaration) => {
				if (!canReachStep(functionalDeclaration, SEARCH_PAGE)) {
					// HOTFIX: without the timeout it's not working
					setTimeout(
						() =>
							this.router.navigate(RouteDealer.consoTable(unwrap(functionalDeclaration.declaration_functional_id)), {
								queryParams: { error: true },
							}),
						0,
					);
					return EMPTY;
				}

				return of(functionalDeclaration);
			}),
			switchMap((declarationFunctional) =>
				zip(
					of(declarationFunctional),
					this.declarationFunctionalService.getDju$(unwrap(declarationFunctional.declaration_functional_id)).pipe(
						catchError((err: unknown) => {
							// If we can't because no meteo station or no altitude is defined then ignore
							if (err instanceof HttpErrorResponse && err.status === 400) {
								return of({});
							}
							throw err;
						}),
					),
					this.declarationFunctionalService
						.getAbsoluteObjective$(unwrap(declarationFunctional.declaration_functional_id))
						.pipe(
							catchError((err: unknown) => {
								if (err instanceof HttpErrorResponse && err.status === 400) {
									return of({});
								}
								throw err;
							}),
						),
				),
			),
			map(([functionalDeclaration, djus, absoluteObjectives]) => ({
				functionalDeclaration,
				djus,
				absoluteObjectives,
			})),
		);

		this.sub(
			this.asyncData$.pipe(
				map(({ functionalDeclaration }) => functionalDeclaration),
				// Reload when functional declaration change
				distinctUntilKeyChanged(GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID),
				switchMap((functionalDeclaration) => {
					return zip(
						of(functionalDeclaration),
						this.representativeState.get$.pipe(first()),
						this.declarationGroupState.get$.pipe(first()),
					);
				}),
			),
			([functionalDeclaration, representative, group]) => {
				if (entityCanAccessPremiumFeatures(functionalDeclaration)) {
					return EMPTY;
				}
				const dialog = dialogOpen(
					this.dialog,
					ConsumeTokenDialogComponent,
					{
						functionalService: this.declarationFunctionalService,
						functionalDeclaration: functionalDeclaration,
						representative: representative?.value,
						declarationGroup: group?.value,
						messageType: ConsumeTokenMessageType.LOCKED_CONSUMPTION,
					},
					{ panelClass: "p-0" },
				);
				return dialog
					.afterClosed()
					.pipe(
						switchMap((output) => {
							if (output) {
								switch (output.type) {
									case "cancel": {
										this.back(unwrap(functionalDeclaration.declaration_id));
										return EMPTY;
									}
									case "cant buy": {
										this.snackBar.open(
											"Vous n'avez pas assez de déclarations pour aller jusqu'à cette étape. Pour continuer veuillez contacter votre référent",
											"OK",
											{ verticalPosition: "top" },
										);
										this.back(unwrap(functionalDeclaration.declaration_id));
										return EMPTY;
									}
									case "updated":
										return of(output.entity).pipe(
											switchMap((entityWithTokenUsed) => {
												if (declarationGroupState.getId()) {
													return declarationGroupState.get$.pipe(
														first(),
														switchMap((group) => (group ? of(group.value) : EMPTY)),
														map((group) =>
															produce(group, (draft) => {
																draft.used_tokens++;
															}),
														),
														switchMap((group) => declarationGroupState.update$(group)),
														map(() => entityWithTokenUsed),
													);
												}
												return of(entityWithTokenUsed);
											}),
										);
								}
							}
							this.back(unwrap(functionalDeclaration.declaration_id));
							return EMPTY;
						}),
					)
					.subscribe((entity) => this.declarationFunctionalService.update(entity));
			},
		);
	}

	back(id: DeclarationId) {
		this.router.navigate(RouteDealer.reference(id));
	}

	updateResource(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		this.functionalDeclarationState.updateSync$(functionalDeclaration).subscribe();
	}

	searchYearReference({ functionalDeclaration, djus, absoluteObjectives }: Data) {
		const report = getBestGoalReportFromFunctionalDeclaration(functionalDeclaration, djus, absoluteObjectives);
		if (report && !allConsumptionsIncomplete(functionalDeclaration)) {
			const updatedFunctionalDeclaration = produce(functionalDeclaration, (draft) => {
				draft.infos.referenceYear = report.referenceYear;
			});
			this.functionalDeclarationState.updateSync$(updatedFunctionalDeclaration).subscribe();
		}
	}

	protected readonly entityCanAccessPremiumFeatures = entityCanAccessPremiumFeatures;
}
