import { Injectable } from "@angular/core";
import { castDraft, Immutable, produce } from "immer";
import { EMPTY, Observable, of, ReplaySubject, Subject, zip } from "rxjs";
import { first, map, switchMap, tap } from "rxjs/operators";
import { handleSavingUnlock } from "../helpers/saving-helper";
import { unwrap } from "../helpers/unwrap";
import { cloneLazyDeclaration, Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { HasResourceFromOwner, Origin } from "../models/HasResourceFromOwner";
import { FunctionalDeclarationId, IdFromResource } from "../models/ids";
import { Lazy } from "../models/lazy";
import { FeedableObservable, ResourceOwner } from "../models/ResourceOwner";
import { DeclarationFunctionalService } from "./declaration-functional.service";

@Injectable({
	providedIn: "root",
})
export class DeclarationStateService
	extends HasResourceFromOwner<Lazy<Declaration>>
	implements ResourceOwner<FunctionalDeclaration>
{
	readonly saving$ = new ReplaySubject<boolean>(1);

	private cachedFunctionalDeclaration?: {
		id: string;
		functionalDeclaration: Immutable<FunctionalDeclaration>;
	};

	constructor(private functionalService: DeclarationFunctionalService) {
		super();
		this.saving$.next(false);
	}

	getIdFromResource(resource: Immutable<Lazy<Declaration>>): IdFromResource<Lazy<Declaration>> {
		return unwrap(resource.declaration_id);
	}

	clearCache() {
		this.cachedFunctionalDeclaration = undefined;
	}

	getFeedableObservable(): FeedableObservable<FunctionalDeclaration> {
		const subject = new Subject<{ id: FunctionalDeclarationId; origin: Origin }>();
		return {
			next: (id, origin) => subject.next({ id, origin }),
			observable: subject.pipe(
				switchMap((idAndOrigin) => zip(of(idAndOrigin), this.get$)),
				switchMap(([idAndOrigin, self]) => (self ? of({ idAndOrigin, self: self.value }) : EMPTY)),
				switchMap(({ idAndOrigin: { id, origin }, self }) => {
					// cache causes pages to desync
					if (this.cachedFunctionalDeclaration?.id === id) {
						return of({ value: this.cachedFunctionalDeclaration.functionalDeclaration, origin });
					}
					// In case the functional state is misused and there is a problem with its parent.
					if (
						self.declarations_functional.find(
							(functionalDeclaration) => functionalDeclaration.declaration_functional_id === id,
						) === undefined
					) {
						return of(null);
					}
					return this.functionalService
						.get$(id)
						.pipe(
							map(FunctionalDeclaration.fromApi),
							tap((functionalDeclaration) => (this.cachedFunctionalDeclaration = { id, functionalDeclaration })),
						)
						.pipe(map((value) => ({ value, origin })));
				}),
			),
		};
	}

	update$(resource: Immutable<Lazy<Declaration>>): Observable<unknown> {
		this.saving$.next(true);
		// we can't modify functionalDeclaration through declaration, so we can keep the cache
		return super.update$(resource).pipe(
			tap(() => {
				if (
					!resource.declarations_functional.find(
						(entity) => entity.declaration_functional_id === this.cachedFunctionalDeclaration?.id,
					)
				) {
					this.cachedFunctionalDeclaration = undefined;
				}
			}),
			handleSavingUnlock(this.saving$),
		);
	}

	updateSync$(resource: Immutable<Lazy<Declaration>>): Observable<unknown> {
		this.saving$.next(true);
		// we can't modify functionalDeclaration through declaration, so we can keep the cache
		return super.updateSync$(resource).pipe(
			tap(() => {
				if (
					!resource.declarations_functional.find(
						(entity) => entity.declaration_functional_id === this.cachedFunctionalDeclaration?.id,
					)
				) {
					this.cachedFunctionalDeclaration = undefined;
				}
			}),
			handleSavingUnlock(this.saving$),
		);
	}

	updateOwned$(updated: Immutable<FunctionalDeclaration>): Observable<unknown> {
		this.saving$.next(true);
		return this.get$.pipe(
			first(),
			switchMap((self) => (self ? of(self) : EMPTY)),
			switchMap((self) => {
				this.cachedFunctionalDeclaration = {
					functionalDeclaration: updated,
					id: unwrap(updated.declaration_functional_id),
				};

				const indexToModify = self.value.declarations_functional.findIndex(
					(fd) => fd.declaration_functional_id === updated.declaration_functional_id,
				);

				if (indexToModify === -1) {
					throw new Error("Entity not found when updating");
				}

				const clone = produce(self.value, (draft) => {
					draft.declarations_functional[indexToModify] = castDraft(updated);
				});

				return this.update$(clone).pipe(map(() => updated));
			}),
			handleSavingUnlock(this.saving$),
		);
	}

	updateOwnedSync$(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
	): Observable<Immutable<FunctionalDeclaration>> {
		this.saving$.next(true);
		return this.get$.pipe(
			first(),
			switchMap((self) => (self ? of(self) : EMPTY)),
			switchMap((self) =>
				zip(
					this.functionalService
						.update$(FunctionalDeclaration.toApi(functionalDeclaration))
						.pipe(map(FunctionalDeclaration.fromApi)),
					of(self),
				),
			),
			switchMap(([updated, { value: self }]) => {
				this.cachedFunctionalDeclaration = {
					functionalDeclaration: updated,
					id: unwrap(updated.declaration_functional_id),
				};
				const clone = cloneLazyDeclaration(self);
				const indexToModify = clone.declarations_functional.findIndex(
					(fd) => fd.declaration_functional_id === updated.declaration_functional_id,
				);
				if (indexToModify === -1) {
					throw new Error("Entity not found when updating");
				}

				clone.declarations_functional[indexToModify] = updated;
				return this.update$(clone).pipe(map(() => updated));
			}),
			handleSavingUnlock(this.saving$),
		);
	}
}
