import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Immutable } from "immer";
import { Nullable } from "../../../../../helpers/nullable";
import { ObjectivesHelper } from "../../../../../helpers/objectives";
import { TupleToUnion } from "../../../../../helpers/tupleToUnion";
import { unwrap } from "../../../../../helpers/unwrap";
import { FunctionalDeclaration, WRONG_ABSOLUTE_OBJECTIVE } from "../../../../../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../../../../../models/ids";
import { RouteDealer } from "../../../../../models/routes";
import { GetReferenceYearForFunctionalDeclarationPipe } from "../../../../../pipes/get-reference-year-for-fd.pipe";
import { GetReportPipe } from "../../../../../pipes/get-report.pipe";
import { AbsoluteObjectives, Djus } from "../../../../../services/declaration-functional.service";
import { FunctionalDeclarationStateService } from "../../../../../services/functional-declaration-state.service";

type ReferenceAndObjectives = {
	reference: number | undefined;
	correctedConsumption: number | undefined;
	relativeObjective: number | undefined;
	absoluteObjective: number | undefined;
};

@Component({
	selector: "app-reference-year-summary-table",
	templateUrl: "./reference-year-summary-table.component.html",
	styleUrls: ["./reference-year-summary-table.component.scss"],
})
export class ReferenceYearSummaryTableComponent {
	WRONG_ABSOLUTE_OBJECTIVE = WRONG_ABSOLUTE_OBJECTIVE;

	@ViewChild("header") headerElement!: ElementRef;
	@ViewChildren("entityRow") entityRowElement!: QueryList<ElementRef>;

	@Input() showAbsoluteObjectiveError = false;
	@Input() loading = false;
	@Input() functionalDeclarations: Immutable<FunctionalDeclaration[]> = [];
	@Input() fullWidth = false;
	@Input() djus: Djus = {};
	@Input() absoluteObjectives: AbsoluteObjectives = {};

	@Output() calculateObjectives = new EventEmitter<void>();

	objectiveToShow: TupleToUnion<typeof ObjectivesHelper.OBJECTIVE_YEAR> = 2030;

	constructor(
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private router: Router,
	) {}

	visitReferenceYear(functionalDeclarationId: Nullable<FunctionalDeclarationId>) {
		const id = unwrap(functionalDeclarationId);
		this.functionalDeclarationState.select$(id).subscribe(() => this.router.navigate(RouteDealer.referenceEntity(id)));
	}

	getFunctionalDeclarationRefAndObjectives = (
		functionalDeclaration: Immutable<FunctionalDeclaration>,
	): ReferenceAndObjectives => {
		const getReferenceYearPipe = new GetReferenceYearForFunctionalDeclarationPipe();
		const getReportPipe = new GetReportPipe();
		const functionalDeclarationDjus = this.djus[unwrap(functionalDeclaration.declaration_functional_id)] ?? {};
		const functionalDeclarationAbsoluteObjectives =
			this.absoluteObjectives[unwrap(functionalDeclaration.declaration_functional_id)] ?? {};

		const result: ReferenceAndObjectives = {
			reference: undefined,
			correctedConsumption: undefined,
			relativeObjective: undefined,
			absoluteObjective: undefined,
		};

		result.reference = getReferenceYearPipe.transform(
			functionalDeclaration,
			functionalDeclarationDjus,
			functionalDeclarationAbsoluteObjectives,
		);
		if (result.reference) {
			const report = getReportPipe.transform(
				functionalDeclaration,
				functionalDeclarationDjus,
				functionalDeclarationAbsoluteObjectives,
				result.reference,
			);
			if (report) {
				result.correctedConsumption = report.correctedConsumption;
				result.relativeObjective =
					report.correctedConsumption * ObjectivesHelper.getObjectiveRateFromReferenceYear(this.objectiveToShow);
			}
			if (functionalDeclarationAbsoluteObjectives[result.reference] > 0) {
				result.absoluteObjective =
					functionalDeclarationAbsoluteObjectives[result.reference] *
					ObjectivesHelper.getObjectiveRate(this.objectiveToShow);
			} else {
				result.absoluteObjective = WRONG_ABSOLUTE_OBJECTIVE;
			}
		}

		return result;
	};
}
