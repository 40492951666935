<div class="d-flex gap-2" style="align-items: center; justify-content: center">
	<input
		type="number"
		min="1"
		max="31"
		size="2"
		[(ngModel)]="day"
		class="custom-input"
		placeholder="Jour"
		(ngModelChange)="changeDate.emit(getDateText())"
		style="min-width: 6em"
		[ngClass]="{ warning: !date && (!!year || !!month || !!day) }"
	/>
	/
	<input
		type="number"
		min="1"
		max="12"
		size="2"
		[(ngModel)]="month"
		class="custom-input"
		placeholder="Mois"
		(ngModelChange)="changeDate.emit(getDateText())"
		style="min-width: 6em"
		[ngClass]="{ warning: !date && (!!year || !!month || !!day) }"
	/>
	/
	<input
		type="number"
		min="1000"
		size="4"
		[max]="CURRENT_YEAR"
		[(ngModel)]="year"
		class="custom-input"
		placeholder="Année"
		(ngModelChange)="changeDate.emit(getDateText())"
		style="min-width: 6em"
		[ngClass]="{ warning: !date }"
	/>
</div>
<div *ngIf="!date" class="d-flex danger" style="align-items: center; justify-content: center; font-size: 12px">
	{{ !!year || !!month || !!day ? "La date est invalide" : "L'année est obligatoire (mois et jour facultatifs)" }}
</div>
