<ng-container *ngIf="skipUpdateFunctionalDeclaration as functionalDeclaration">
	<p class="text-gray">
		Sélectionnez les parcelles cadastrales, puis les bâtiments. Si des éléments sont trop récents et n'apparaissent pas
		sur la carte, activez l'option "Ajouter des bâtiments/parcelles sans la carte".
	</p>
	<div class="d-flex gap-2 mb-2" style="align-items: center">
		<ng-container *ngIf="!mapHidden">
			<button
				tourAnchor="select-plot"
				[disabled]="freezeHover"
				mat-flat-button
				[class]="
					'btn-' +
					(isSelectingBuilding ? 'stroked-' : '') +
					(functionalDeclaration.infos.parcelles.length > 0 ? 'more-' : 'less-') +
					'than-one'
				"
				(click)="isSelectingBuilding = false"
			>
				<mat-icon></mat-icon>
				Parcelle(s)
			</button>
			<button
				tourAnchor="select-building"
				[disabled]="freezeHover"
				mat-flat-button
				[class]="
					'btn-' +
					(isSelectingBuilding ? '' : 'stroked-') +
					(functionalDeclaration.infos.buildingInfos.length > 0 ? 'more-' : 'less-') +
					'than-one'
				"
				(click)="isSelectingBuilding = true"
			>
				<mat-icon></mat-icon>
				Bâtiment(s)
			</button>
			<button
				[disabled]="freezeHover"
				*ngIf="map"
				mat-flat-button
				class="btn-warning"
				style="background-color: var(--accent3) !important"
				(click)="restoreAll(functionalDeclaration, map)"
			>
				<mat-icon>visibility_off</mat-icon>
				Des bâtiments/parcelles ne sont plus visibles
			</button>
		</ng-container>
		<span *ngIf="mapHidden" class="d-flex gap-2">
			<button mat-button [disabled]="freezeHover" class="btn-primary" (click)="addBuilding(functionalDeclaration)">
				<mat-icon>apartment</mat-icon>
				<span class="ml-2">Ajouter un bâtiment</span>
			</button>
			<button
				mat-button
				[disabled]="freezeHover"
				class="btn-primary"
				(click)="addCustomParcelle(functionalDeclaration)"
			>
				<mat-icon>landscape</mat-icon>
				<span class="ml-2">Ajouter une parcelle</span>
			</button>
		</span>
		<mat-slide-toggle style="margin-left: auto" color="primary" [(ngModel)]="mapHidden" [disabled]="freezeHover">
			Ajouter des bâtiments/parcelles sans la carte
		</mat-slide-toggle>
	</div>
	<div *ngIf="parcelError !== undefined" class="danger step-error" id="stepErrorDiv">
		{{ parcelError | reasonToText }}
	</div>
	<div tourAnchor="map" [class.b-1-danger]="parcelError !== undefined">
		<app-building-map
			*ngIf="!mapHidden"
			[addressInfo]="functionalDeclaration.address"
			(buildingSelected)="addBuilding(functionalDeclaration, $event)"
			(buildingUnselected)="editBuilding(functionalDeclaration, $event)"
			(parcelleSelected)="addParcelle(functionalDeclaration, $event)"
			(parcelleUnselected)="removeParcelle(functionalDeclaration, $event, true)"
			[buildingInfos]="functionalDeclaration.infos.buildingInfos"
			[parcelles]="functionalDeclaration.infos.parcelles"
			(mapLoad)="onMapLoad($event)"
			[freezeHover]="freezeHover"
			[isSelectingBuilding]="isSelectingBuilding"
			[trackId]="functionalDeclaration.declaration_functional_id"
		>
		</app-building-map>
	</div>
	<div>
		<span class="d-inline-flex gap-2" style="align-items: center">
			<span>
				Il existe des bâtiments indépendants de mon entité fonctionnelle qui hébergent des activités tertiaires sur la
				même <b>unité foncière</b> ou même <b>site</b>:
			</span>

			<mat-radio-group
				aria-label="Select an option"
				class="d-inline-flex gap-2"
				[value]="functionalDeclaration.infos.otherBuildingOnSameSite !== undefined"
				(change)="toggleOtherBuildingOnSameSite(functionalDeclaration)"
			>
				<mat-radio-button [value]="true">Oui</mat-radio-button>
				<mat-radio-button [value]="false">Non</mat-radio-button>
			</mat-radio-group>
			<app-help [subject]="HelpSubject.Site"></app-help>
		</span>
		<mat-action-list>
			<div class="d-flex gap-3">
				<div class="w-100">
					<p class="pb-1" mat-subheader>Bâtiments que vous occupez</p>
					<mat-list-item
						class="disable-line"
						*ngIf="functionalDeclaration.infos.buildingInfos.length === 0"
						[disabled]="true"
					>
						<i>Pas de bâtiments</i>
					</mat-list-item>
					<ng-container *ngFor="let building of functionalDeclaration.infos.buildingInfos; index as buildingIndex">
						<mat-list-item (click)="editBuilding(functionalDeclaration, buildingIndex)">
							<mat-icon matListItemIcon> apartment </mat-icon>
							<b matListItemTitle class="primary bold"
								>{{ building.label[0] === "" ? "Bâtiment sans nom" : building.label[0] }}
								<span *ngIf="building.building.type === 'FeatureCollection'">
									<b>(Ancien format, à mettre jour)</b>
								</span>
							</b>
							<span matListItemLine class="text-gray" *ngIf="building | buildingAssujetti as assujetti">
								{{ assujetti.owned + assujetti.notOwned | number }} m² non exemptés
							</span>
						</mat-list-item>
					</ng-container>
					<ng-container *ngIf="functionalDeclaration.infos.otherBuildingOnSameSite !== undefined">
						<p class="pb-1" mat-subheader>Bâtiments indépendants de mon entité fonctionnelle</p>
						<div class="message-accent" *ngIf="functionalDeclaration.infos | isOwnedAssujetti">
							<mat-icon>priority_high</mat-icon>
							Vous êtes assujetti avec seulement vos surfaces, il est donc facultatif de renseigner cette surface.
						</div>
						<mat-list-item
							(click)="
								editSurfaceBuildingOnSameSite(
									functionalDeclaration,
									functionalDeclaration.infos.otherBuildingOnSameSite
								)
							"
						>
							<mat-icon matListItemIcon> crop </mat-icon>
							<span matListItemTitle>Surface tertiaire de ces bâtiments</span>
							<span class="text-gray" matListItemLine
								>{{ functionalDeclaration.infos.otherBuildingOnSameSite | surfaceAssujetti }} m² non exemptés</span
							>
						</mat-list-item>
					</ng-container>
				</div>
				<div class="w-100">
					<p class="pb-1" mat-subheader>Mes parcelles</p>
					<mat-list-item
						*ngIf="functionalDeclaration.infos.parcelles.length === 0"
						class="disable-line"
						[disabled]="true"
					>
						<i>Pas de parcelles</i>
					</mat-list-item>
					<ng-container *ngFor="let parcel of functionalDeclaration.infos.parcelles">
						<mat-list-item [matMenuTriggerFor]="menu">
							<mat-icon matListItemIcon> landscape </mat-icon>
							<span matListItemLine *ngIf="parcel.type === 'FeatureCollection'">
								{{ parcel | parcelName }} <b>(Ancien format, à mettre jour)</b>
							</span>
							<b matListItemTitle class="primary bold" *ngIf="parcel.type === 'Feature'">
								{{ parcel.properties | newParcelName }}
							</b>
						</mat-list-item>
						<mat-menu #menu="matMenu">
							<button mat-menu-item (click)="removeParcelleWithoutId(functionalDeclaration, parcel)">
								<mat-icon>delete_forever</mat-icon>
								<span>Supprimer</span>
							</button>
						</mat-menu>
					</ng-container>
				</div>
			</div>
		</mat-action-list>
	</div>

	<div style="width: 95%; position: absolute">
		<div class="d-flex pt-1" style="justify-content: flex-end">
			<button class="btn-stroked-primary" *ngIf="functionalDeclaration | isAssujetti; else elseBlock">
				Assujetti {{ functionalDeclaration.infos | getAssujettiSurfaceText }}
			</button>
			<ng-template #elseBlock>
				<button class="btn-stroked-primary">Non assujetti</button>
			</ng-template>
		</div>
	</div>
</ng-container>
