import { Pipe, PipeTransform } from "@angular/core";
import { FeatureCollection } from "geojson";
import { Immutable } from "immer";
import { getParcelId, ParcelFeature } from "../models/Parcel";

@Pipe({
	name: "parcelFromName",
})
export class ParcelFromNamePipe implements PipeTransform {
	transform(
		name: string | undefined,
		parcelles: Immutable<FeatureCollection | ParcelFeature>[],
	): Immutable<FeatureCollection | ParcelFeature | undefined> {
		if (!name) {
			return undefined;
		}
		name = name.replace(/\s+/g, "");
		return parcelles.find((parcelle) => name === getParcelId(parcelle));
	}
}
