import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { GreenKeys as Keys } from "@grs/greenkeys";
import { produce, castDraft, Immutable } from "immer";
import { EMPTY, Observable, of, Subject, zip } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { AddFunctionalEntityModalComponent } from "src/app/components/add-functional-entity-modal/add-functional-entity-modal.component";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { HelpSubject } from "src/app/components/help/help.component";
import { Nullable } from "src/app/helpers/nullable";
import { contains } from "src/app/helpers/string";
import { myForkjoin, toObservable } from "src/app/helpers/to-observable";
import { unwrap } from "src/app/helpers/unwrap";
import { AddressInfo } from "src/app/models/address";
import { BaseComponent } from "src/app/models/base-component.directive";
import { snackError } from "src/app/models/CustomError";
import { cloneLazyDeclaration, Declaration } from "src/app/models/declaration";
import {
	EfaStatus,
	getIdentifierFromEfaStatus,
	getIdentifierStringFromEfaStatus,
	getOperatReference,
} from "src/app/models/efaStatus";
import {
	apiAddressToAddressInfo,
	associationToAddressInfo,
	entityCanAccessPremiumFeatures,
	etablissementApiToFunctionalEntity,
	FunctionalDeclaration,
	KEY_LINKED_EFA_1,
	KEY_LINKED_EFA_2,
	KEY_LINKED_EFA_3,
	newFunctionalDeclaration,
} from "src/app/models/functionalDeclaration";
import { Rna, Tenant } from "src/app/models/identifier";
import { FunctionalDeclarationId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { dialogOpen } from "src/app/models/modal";
import { RouteDealer } from "src/app/models/routes";
import { Etablissement } from "src/app/models/siren-api.types";
import { StreetType } from "src/app/models/StreetType";
import { getLabelEtablissement } from "src/app/pipes/get-label-establishment.pipe";
import { AddressSearchService } from "src/app/services/address-search.service";
import { DeclarationFunctionalService } from "src/app/services/declaration-functional.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { DeclarationService } from "src/app/services/declaration.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { RnaService } from "src/app/services/rna.service";
import { SirenService } from "src/app/services/siren.service";
import * as icons from "src/assets/icons";
import * as text from "src/assets/text";
import { DeclarationGroup } from "../../../../models/declarationGroup";
import { DeclarationGroupStateService } from "../../../../services/declaration-group-state.service";
import { OperatApiService } from "../../../../services/operat-api.service";
import { OperatImportLoadingComponent } from "../../user-info-form/operat-import-loading/operat-import-loading.component";
import { ImportTypeEnum } from "../../user-info-form/operat-search-form/ImportTypeEnum";
import { DescriptionEditModalComponent } from "./description-edit-modal/description-edit-modal.component";
import { ImportEfaModalComponent } from "./import-efa-modal/import-efa-modal.component";
import { HiddenRouteParamsService } from "../../../../services/hidden-route-params.service";
import { ImportReferencesErrorComponent } from "./import-references-error/import-references-error.component";

@Component({
	selector: "app-siren-form",
	templateUrl: "./siren-form.component.html",
	styleUrls: ["./siren-form.component.scss"],
})
export class SirenFormComponent extends BaseComponent {
	readonly AMOUNT_PER_PAGE = 9;

	onlyEntitiesWithProblem = false;

	page = 1;
	maxPage = 1;
	search = "";
	importErrors: string[] = [];

	viaChoice = ChoiceAddEstablishment.Custom;
	VIA_CHOICES = VIA_CHOICES;
	HelpSubject = HelpSubject;
	isLandlord = false;
	readonly TEXT_TOKEN_NOT_USED = text.TOKEN_NOT_USED;
	readonly ICON_TOKEN_NOT_USED = icons.TOKEN_NOT_USED;
	readonly ICON_INVALID_DATES = icons.INVALID_DATES;
	readonly TEXT_INVALID_DATES = text.INVALID_DATES;
	Keys = Keys;

	/**
	 * I have to use a variable because if I only use refs, input updates are fired only when the
	 * input is unfocused. It is not a wanted behavior because the button needs direct updates from
	 * the input.
	 */
	customLabel = "";
	customLabelAddress: AddressInfo | null = null;

	ChoiceAddEstablishment = ChoiceAddEstablishment;
	etablissements = new Set<Etablissement>();
	// fired.
	private searchSubject = new Subject<string>();
	addressInfoStream = this.searchSubject.pipe(
		debounceTime(500),
		distinctUntilChanged(),
		switchMap((q) => this.addressApi.getAddressInfo$(q)),
		catchError((error: unknown) => {
			snackError(this.snackBar, error);

			throw error;
		}),
	);

	constructor(
		private sirenService: SirenService,
		private rnaService: RnaService,
		private addressApi: AddressSearchService,
		public declarationState: DeclarationStateService,
		private dialog: MatDialog,
		private functionalService: DeclarationFunctionalService,
		functionalState: FunctionalDeclarationStateService,
		private router: Router,
		private snackBar: MatSnackBar,
		private declarationService: DeclarationService,
		public groupState: DeclarationGroupStateService,
		private operatApi: OperatApiService,
		private hiddenRouteParams: HiddenRouteParamsService<string>,
	) {
		super();
		// We can delete functionalEntities in this part so we must clear the state
		functionalState.clear();

		const errorsImport = this.hiddenRouteParams.pop();

		if (errorsImport.length > 0) {
			dialogOpen(this.dialog, ImportReferencesErrorComponent, { references: errorsImport }, { panelClass: "p-0" });
		}

		this.sub(
			this.declarationState.get$.pipe(switchMap((declaration) => (declaration ? of(declaration) : EMPTY))),
			({ value }) => this.generateEtablissement(value),
			(error: unknown) => {
				snackError(this.snackBar, error);
			},
		);
	}

	get etablissementSlice(): Etablissement[] {
		return Array.from(this.etablissements).slice(
			this.AMOUNT_PER_PAGE * (this.page - 1),
			this.AMOUNT_PER_PAGE * this.page,
		);
	}

	generateDefaultEntities(declaration: Immutable<Lazy<Declaration>>) {
		const { structure } = declaration;

		if ("id" in structure) {
			throw new Error("Not implemented for an 'other' structure.");
		}

		this.declarationState.saving$.next(true);

		if ("siren" in structure) {
			this.sirenService
				.getEtablissements$(structure.siren)
				.pipe(
					map((etablissementsApi) => {
						let openedEtablissements = etablissementsApi.filter(
							(etablissement) => etablissement.periodesEtablissement[0].etatAdministratifEtablissement === "A",
						);
						if (openedEtablissements.length === 0) {
							// If everything is closed then we give every closed etablissements
							openedEtablissements = etablissementsApi;
						}
						return openedEtablissements.map((eApi) =>
							etablissementApiToFunctionalEntity(eApi, {
								type: Keys.KEY_SIREN,
								status: {
									type: Keys.KEY_OWNER_OCCUPANT,
									data: { without_siret: false, condominium: undefined, siret: eApi.siret },
								},
								secondaryOwner: undefined,
							}),
						);
					}),
					switchMap((functionalDeclarations) =>
						this.addEntitiesToEmptyDeclaration$(declaration, functionalDeclarations),
					),
					catchError((error) => {
						this.declarationState.saving$.next(false);
						throw error;
					}),
				)
				.subscribe(() => this.declarationState.saving$.next(false));

			return;
		}

		this.rnaToFunctionalDeclaration$(structure.rna, {
			type: Keys.KEY_RNA,
			status: { type: Keys.KEY_OWNER_OCCUPANT, condominium: undefined },
			secondaryOwner: undefined,
		})
			.pipe(
				switchMap((functionalDeclaration) => this.addEntityToDeclaration$(declaration, functionalDeclaration)),
				catchError((error) => {
					this.declarationState.saving$.next(false);
					throw error;
				}),
			)
			.subscribe(() => this.declarationState.saving$.next(false));
	}

	rnaToFunctionalDeclaration$(rna: Rna, efaStatus: EfaStatus): Observable<FunctionalDeclaration> {
		return this.rnaService.getAssociation$(rna).pipe(
			map((association) =>
				newFunctionalDeclaration(
					{
						address: associationToAddressInfo(association),
						name: association.title,
						description: association.title,
					},
					efaStatus,
				),
			),
		);
	}

	updateSearch(declaration: Immutable<Lazy<Declaration>>) {
		this.generateEtablissement(declaration);
		this.setPage(1);
	}

	setPage(page: number) {
		this.page = page;
	}

	generateEtablissement(declaration: Immutable<Lazy<Declaration>>) {
		const etablissements = new Array<Etablissement>();
		const searchString = this.search.toLowerCase();

		// CSV EFA 4.13
		// to avoid hiding all entities if they are all complete
		const allEntitiesAreGood =
			declaration.declarations_functional.filter(({ efa_status }) => areDatesInvalid(efa_status)).length === 0;

		const entities = declaration.declarations_functional.filter((fd) => {
			const { efa_status } = fd;

			if (!allEntitiesAreGood && this.onlyEntitiesWithProblem && !areDatesInvalid(efa_status)) {
				return false;
			}

			return (
				contains(fd[Keys.KEY_DESCRIPTION], searchString) ||
				contains(fd[Keys.KEY_NAME], searchString) ||
				contains(getIdentifierStringFromEfaStatus(efa_status), searchString) ||
				contains(AddressInfo.toString(fd[Keys.KEY_ADDRESS]), searchString)
			);
		});

		for (const functionalDeclaration of entities) {
			// We check if there is an etablissements which has the same siret/rna as
			// the etablissement's one
			const etablissement = Array.from(etablissements).find((etablissement) =>
				isEfaLinkedToEtablissement(functionalDeclaration, etablissement),
			);

			if (etablissement) {
				etablissement.functionalEntities.add(functionalDeclaration);
			} else {
				const functionalEntities = new Set<Immutable<Lazy<FunctionalDeclaration>>>();
				functionalEntities.add(functionalDeclaration);

				const { efa_status } = functionalDeclaration;
				const identifier = getIdentifierFromEfaStatus(efa_status);

				const newEtablissement: Etablissement = {
					identifier: identifier ? { ...identifier } : undefined,
					address: functionalDeclaration.address,
					functionalEntities: new Set([functionalDeclaration]),
				};
				etablissements.push(newEtablissement);
			}
		}
		// we sort the etablissements to have a better UX (the etablissements won't change order after an edit)
		etablissements.sort((a, b) =>
			getLabelEtablissement(a).toUpperCase() > getLabelEtablissement(b).toUpperCase() ? 1 : -1,
		);
		this.etablissements = new Set(etablissements);
		this.maxPage = Math.ceil(this.etablissements.size / this.AMOUNT_PER_PAGE);
	}

	/**
	 * Remove every function declaration from the declaration which are contained in the
	 * establishment. Emits.
	 * @param declaration
	 * @param etablissement The etablishment to remove
	 */
	removeEtablissement(declaration: Immutable<Lazy<Declaration>>, etablissement: Etablissement) {
		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Suppression de plusieurs entités",
				description:
					"Vous êtes sur le point de supprimer des entités fonctionnelles. " +
					"Une fois supprimées les données liées à ces " +
					"entités ne pourront pas être récupérées, " +
					"êtes-vous sûr de vouloir continuer ?",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.pipe(
				switchMap((wantToDelete) => {
					if (wantToDelete) {
						const idsToDelete: FunctionalDeclarationId[] = [];

						for (const functionalDeclaration of etablissement.functionalEntities) {
							idsToDelete.push(unwrap(functionalDeclaration.declaration_functional_id));
						}

						const cloneDeclaration = cloneLazyDeclaration(declaration);
						cloneDeclaration.declarations_functional = cloneDeclaration.declarations_functional.filter(
							(functionalDeclaration) => !idsToDelete.includes(unwrap(functionalDeclaration.declaration_functional_id)),
						);

						return this.declarationState.updateSync$(cloneDeclaration);
					}
					return EMPTY;
				}),
			)
			.subscribe();
	}

	searchAddress(searchQuery: string) {
		if (searchQuery) {
			this.searchSubject.next(searchQuery);
		}
	}

	deleteFunctionalEntity(
		declaration: Immutable<Lazy<Declaration>>,
		functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>,
	) {
		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Suppression d'une entité fonctionnelle",
				description:
					"Vous êtes sur le point de supprimer une entité fonctionnelle. " +
					"Une fois supprimées les données liées à cette " +
					"entité fonctionnelle ne pourront pas être récupérées, " +
					"êtes-vous sûr de vouloir continuer ?",
				descriptionTwice: functionalDeclaration.is_token_used
					? "Vous avez dépensé un crédit pour cette entité " +
						"et vous ne serez pas remboursé en cas de suppression. " +
						"Il est encore temps de changer d'avis."
					: undefined,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.pipe(
				switchMap((confirm) => {
					const id = functionalDeclaration.declaration_functional_id;

					if (!(confirm && id)) {
						return EMPTY;
					}

					const cloneDeclaration = cloneLazyDeclaration(declaration);
					cloneDeclaration.declarations_functional = cloneDeclaration.declarations_functional.filter(
						(functionalDeclaration) => functionalDeclaration.declaration_functional_id !== id,
					);
					return this.declarationState.updateSync$(cloneDeclaration);
				}),
			)
			.subscribe();
	}

	formSubmit(id: FunctionalDeclarationId) {
		this.router.navigate(RouteDealer.qualification(id));
	}

	// faster than addEntities for one functionalDeclaration
	addEntityToDeclaration$(
		declaration: Immutable<Lazy<Declaration>>,
		functionalDeclaration: FunctionalDeclaration,
	): Observable<unknown> {
		return toObservable(() => {
			functionalDeclaration.declaration_id = declaration.declaration_id;
			return functionalDeclaration;
		}).pipe(
			map(FunctionalDeclaration.toApi),
			switchMap((forApi) => this.functionalService.create$(forApi)),
			map(FunctionalDeclaration.fromApi),
			switchMap((created) => {
				const newDeclaration = produce(declaration, (draft) => {
					draft.declarations_functional.push(created);
				});
				return this.declarationState.update$(newDeclaration);
			}),
		);
	}

	addEntitiesToEmptyDeclaration$(
		declaration: Immutable<Lazy<Declaration>>,
		functionalDeclarations: FunctionalDeclaration[],
	): Observable<unknown> {
		if (declaration.declarations_functional.length > 0) {
			throw new Error("Don't use this function with a declaration which has entities.");
		}
		return toObservable(() => {
			for (const functionalDeclaration of functionalDeclarations) {
				functionalDeclaration.declaration_id = declaration.declaration_id;
			}
			return { functionalDeclarations, declaration };
		}).pipe(
			map(({ functionalDeclarations, declaration }) => ({
				functionalDeclarations,
				declaration: { ...declaration, declarations_functional: new Array<FunctionalDeclaration>() },
			})),
			map(({ functionalDeclarations, declaration }) =>
				produce(declaration, (draft) => {
					// the lint is broken, I don't know why it needs castDraft
					draft.declarations_functional.push(...castDraft(functionalDeclarations));
				}),
			),
			map(Declaration.toApi),
			switchMap((forApi) => this.declarationService.save$(forApi)),
			map(Declaration.fromLazyApi),
			switchMap((saved) => this.declarationState.update$(saved)),
		);
	}

	addNewEntity(declaration: Immutable<Lazy<Declaration>>) {
		dialogOpen(
			this.dialog,
			AddFunctionalEntityModalComponent,
			{
				structure: declaration.structure,
				addressService: this.addressApi,
				entityAndCanDelete: undefined,
				adding: true,
				operatReference: undefined,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.pipe(
				tap(() => this.declarationState.saving$.next(true)),
				switchMap((statusAndAddress) => {
					if (statusAndAddress && statusAndAddress.type === AddFunctionalEntityModalComponent.OutputType.Save) {
						const { addressInfo, efaStatus, name } = statusAndAddress;

						if (addressInfo) {
							// addressInfo is defined if building empty or if without siret
							return zip(of(newFunctionalDeclaration({ address: addressInfo, name }, efaStatus)), of(statusAndAddress));
						}

						if (efaStatus.type === "siren") {
							const { status } = efaStatus;

							if (
								(status.type === Keys.KEY_CONDOMINIUM || status.type === Keys.KEY_LANDLORD) &&
								!status.data.tenant_without_siret &&
								status.data.tenant
							) {
								return zip(
									this.tenantToFunctionalDeclaration$(status.data.tenant, efaStatus).pipe(
										tap((entity) => (entity.name = name)),
									),
									of(statusAndAddress),
								);
							}

							if (
								(status.type === Keys.KEY_OWNER_OCCUPANT || status.type === Keys.KEY_TENANT) &&
								!status.data.without_siret
							) {
								return zip(
									this.sirenService.getEtablissement$(status.data.siret).pipe(
										map((eApi) => etablissementApiToFunctionalEntity(eApi, efaStatus)),
										tap((entity) => (entity.name = name)),
									),
									of(statusAndAddress),
								);
							}
						} else {
							const { status } = efaStatus;

							if ((status.type === Keys.KEY_CONDOMINIUM || status.type === Keys.KEY_LANDLORD) && status.tenant) {
								return zip(
									this.tenantToFunctionalDeclaration$(status.tenant, efaStatus).pipe(
										tap((entity) => (entity.name = name)),
									),
									of(statusAndAddress),
								);
							}

							return zip(of(newFunctionalDeclaration({ name }, efaStatus)), of(statusAndAddress));
						}
					}
					this.declarationState.saving$.next(false);
					return EMPTY;
				}),
				map(([newEntity, statusAndAddress]) => {
					newEntity[KEY_LINKED_EFA_1] = statusAndAddress.linkedEfas[0];
					newEntity[KEY_LINKED_EFA_2] = statusAndAddress.linkedEfas[1];
					newEntity[KEY_LINKED_EFA_3] = statusAndAddress.linkedEfas[2];
					newEntity.first_year_declaration = statusAndAddress.firstYearDeclaration;
					return newEntity;
				}),
				switchMap((newEntity) => this.addEntityToDeclaration$(declaration, newEntity)),
				catchError((error) => {
					this.declarationState.saving$.next(false);
					throw error;
				}),
			)
			.subscribe(() => this.declarationState.saving$.next(false));
	}

	editEfaStatus(
		declaration: Immutable<Lazy<Declaration>>,
		functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>,
	) {
		dialogOpen(
			this.dialog,
			AddFunctionalEntityModalComponent,
			{
				structure: declaration.structure,
				addressService: this.addressApi,
				entityAndCanDelete: { entity: functionalDeclaration, canDelete: true },
				adding: false,
				operatReference: entityCanAccessPremiumFeatures(functionalDeclaration)
					? getOperatReference(functionalDeclaration, declaration)
					: undefined,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.pipe(
				tap(() => this.declarationState.saving$.next(true)),
				switchMap((efaStatusAndAddress) =>
					efaStatusAndAddress
						? of(efaStatusAndAddress)
						: of(undefined).pipe(
								tap(() => this.declarationState.saving$.next(false)),
								switchMap(() => EMPTY),
							),
				),
				switchMap((efaStatusAndAddress) => {
					if (efaStatusAndAddress.type === AddFunctionalEntityModalComponent.OutputType.Delete) {
						return this.functionalService.delete$(unwrap(functionalDeclaration.declaration_functional_id)).pipe(
							switchMap(() =>
								this.declarationState.update$(
									produce(declaration, (draft) => {
										draft.declarations_functional = draft.declarations_functional.filter(
											(fd) => fd.declaration_functional_id !== functionalDeclaration.declaration_functional_id,
										);
									}),
								),
							),
						);
					}

					const { name, efaStatus, addressInfo, linkedEfas, firstYearDeclaration } = efaStatusAndAddress;
					const functionalIdentifier = getIdentifierFromEfaStatus(efaStatus);
					const siret =
						functionalIdentifier && "siret" in functionalIdentifier ? functionalIdentifier.siret : undefined;
					const rna =
						functionalIdentifier && "rna" in functionalIdentifier
							? functionalIdentifier.rna
							: "rna" in declaration.structure
								? declaration.structure.rna
								: undefined;

					const newAddressInfo$ = addressInfo
						? of(addressInfo)
						: siret
							? this.sirenService
									.getEtablissement$(siret)
									.pipe(map(({ adresseEtablissement }) => apiAddressToAddressInfo(adresseEtablissement)))
							: this.rnaService.getAssociation$(unwrap(rna)).pipe(map(associationToAddressInfo));

					return zip(
						newAddressInfo$.pipe(
							catchError(() => {
								this.snackBar.open(
									"Le SIRET renseigné est introuvable. Vos autres modifications ont cependant bien été sauvegardées.",
									"OK",
								);
								return of(undefined);
							}),
						),
						this.functionalService.get$(unwrap(functionalDeclaration.declaration_functional_id)),
					).pipe(
						map(([address, fd]) => {
							// if !address -> bad functionalIdentifier
							if (address) {
								fd.address = address;
							}

							fd.efa_status = efaStatus;
							fd.name = name;
							fd[KEY_LINKED_EFA_1] = linkedEfas[0];
							fd[KEY_LINKED_EFA_2] = linkedEfas[1];
							fd[KEY_LINKED_EFA_3] = linkedEfas[2];
							return fd;
						}),
						map((fd: FunctionalDeclaration.Api) => {
							if (!fd[Keys.KEY_IS_TOKEN_USED] && firstYearDeclaration) {
								fd[Keys.KEY_FIRST_YEAR_DECLARATION] = firstYearDeclaration;
							}
							return fd;
						}),
						switchMap((fd) => this.functionalService.update$(fd)),
						map(FunctionalDeclaration.fromApi),
						map((savedEntity) =>
							produce(declaration, (draft) => {
								const index = draft.declarations_functional.findIndex(
									(fd) => fd.declaration_functional_id === savedEntity.declaration_functional_id,
								);
								draft.declarations_functional[index] = savedEntity;
							}),
						),
						switchMap((updatedDeclaration) => this.declarationState.update$(updatedDeclaration)),
						catchError((error) => {
							this.declarationState.saving$.next(false);
							throw error;
						}),
					);
				}),
			)
			.subscribe(() => this.declarationState.saving$.next(false));
	}

	tenantToFunctionalDeclaration$(tenant: Tenant, efaStatus: EfaStatus): Observable<FunctionalDeclaration> {
		if ("siret" in tenant) {
			return this.sirenService
				.getEtablissement$(tenant.siret)
				.pipe(map((eApi) => etablissementApiToFunctionalEntity(eApi, efaStatus)));
		}

		if ("rna" in tenant) {
			return this.rnaToFunctionalDeclaration$(tenant.rna, efaStatus);
		}

		return of(newFunctionalDeclaration({}, efaStatus));
	}

	toggleIsMandated(declaration: Immutable<Lazy<Declaration>>) {
		this.declarationState
			.updateSync$(
				produce(declaration, (draft) => {
					draft.is_mandated = !draft.is_mandated;
				}),
			)
			.subscribe();
	}

	openDescriptionEditModal(declaration: Immutable<Lazy<Declaration>>, etablissement: Immutable<Etablissement>) {
		dialogOpen(this.dialog, DescriptionEditModalComponent, { declaration, etablissement }, { panelClass: "p-0" })
			.afterClosed()
			.pipe(
				switchMap((output) => (output ? of(output) : EMPTY)),
				switchMap(({ affectedIds, description }) => {
					return zip(myForkjoin(affectedIds.map((id) => this.functionalService.get$(id))), of(description));
				}),
				switchMap(([apiEntities, description]) => {
					apiEntities.forEach((entity) => (entity.description = description));
					return myForkjoin(apiEntities.map((entity) => this.functionalService.update$(entity)));
				}),
				switchMap((updatedEntities) => {
					const entities = updatedEntities.map(FunctionalDeclaration.fromApi);
					const entitiesById = new Map<Nullable<FunctionalDeclarationId>, Immutable<Lazy<FunctionalDeclaration>>>();
					// we will replace the previous entities with the updated ones if they have the same id
					for (const entity of declaration.declarations_functional) {
						entitiesById.set(entity.declaration_functional_id, entity);
					}
					for (const updatedEntity of entities) {
						entitiesById.set(updatedEntity.declaration_functional_id, updatedEntity);
					}
					const updatedDeclaration = produce(declaration, (draft) => {
						draft.declarations_functional = castDraft(Array.from(entitiesById.values()));
					});

					// no need to use updateSync because the functional entities are already saved server-side.
					return this.declarationState.update$(updatedDeclaration);
				}),
			)
			.subscribe();
	}

	toggleOnlyEntitiesWithProblem(declaration: Immutable<Lazy<Declaration>>) {
		this.onlyEntitiesWithProblem = !this.onlyEntitiesWithProblem;
		this.generateEtablissement(declaration);
	}

	readonly areDatesInvalid = areDatesInvalid;
	readonly countInvalidDatesEntity = countInvalidDatesEntity;

	importEntity(declaration: Immutable<Lazy<Declaration>>, group: Nullable<Immutable<Lazy<DeclarationGroup>>>) {
		const dialog = dialogOpen(
			this.dialog,
			ImportEfaModalComponent,
			{ declaration, addressSearch: this.addressApi, group },
			{ panelClass: "p-0" },
		);
		let importErrors: string[] = [];
		dialog
			.afterClosed()
			.pipe(
				switchMap((importData) => (importData ? of(importData) : EMPTY)),
				tap(() => this.declarationState.saving$.next(true)),
				switchMap((importData) => {
					switch (importData.type) {
						case ImportTypeEnum.CSV:
							if (importData.data) {
								return this.declarationService.importEFAByCSV(unwrap(declaration.declaration_id), importData.data);
							}
							break;
						case ImportTypeEnum.API:
							return this.declarationState
								.updateSync$(
									produce(declaration, (draft) => {
										draft.operat_user_key = importData.data.userKey;
									}),
								)
								.pipe(
									switchMap(() =>
										dialogOpen(
											this.dialog,
											OperatImportLoadingComponent,
											{
												declarationService: this.declarationService,
												declarationFunctionalService: this.functionalService,
												addressSearch: this.addressApi,
												operatService: this.operatApi,
												dataToImport: importData.data,
												declaration: declaration,
											},
											{ panelClass: "p-0", disableClose: true },
										).afterClosed(),
									),
									switchMap((output) =>
										output
											? of(output).pipe(
													tap(({ errors }) => {
														importErrors = errors;
													}),
													map(({ declaration }) => declaration),
												)
											: of(undefined).pipe(
													tap(() => (importErrors = importData.data.references.map((refData) => refData.refOperat))),
													switchMap(() => EMPTY),
												),
									),
								);
					}
					this.declarationState.saving$.next(false);
					return EMPTY;
				}),
				map(Declaration.fromApi),
				switchMap((declaration) => this.declarationState.update$(declaration)),
				catchError((error) => {
					this.declarationState.saving$.next(false);
					throw error;
				}),
			)
			.subscribe(() => {
				this.declarationState.saving$.next(false);
				if (importErrors.length > 0) {
					dialogOpen(this.dialog, ImportReferencesErrorComponent, { references: importErrors }, { panelClass: "p-0" });
				}
			});
	}
}

export interface StreetInfo {
	houseNumber: string;
	streetName: string;
	details: string;
	streetType: StreetType;
}

enum ChoiceAddEstablishment {
	Siret = "Avec SIRET",
	Custom = "Sans SIRET",
}

const VIA_CHOICES = [ChoiceAddEstablishment.Siret, ChoiceAddEstablishment.Custom];

export function isEfaLinkedToEtablissement(
	functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>,
	{ identifier: etablissementIdentifier, address }: Immutable<Etablissement>,
) {
	const { efa_status, address: functionalAddress } = functionalDeclaration;
	const functionalIdentifier = getIdentifierFromEfaStatus(efa_status);

	return (
		(!(etablissementIdentifier || functionalIdentifier) && AddressInfo.equals(address, functionalAddress)) ||
		(etablissementIdentifier &&
			functionalIdentifier &&
			(("siret" in functionalIdentifier &&
				"siret" in etablissementIdentifier &&
				functionalIdentifier.siret === etablissementIdentifier.siret) ||
				("denomination" in functionalIdentifier &&
					"denomination" in etablissementIdentifier &&
					functionalIdentifier.denomination === etablissementIdentifier.denomination) ||
				("rna" in functionalIdentifier &&
					"rna" in etablissementIdentifier &&
					functionalIdentifier.rna === etablissementIdentifier.rna)))
	);
}

export function areDatesInvalid(efaStatus: Immutable<EfaStatus>): boolean {
	if (!efaStatus.ownerStartDate) {
		return true;
	}

	if (!efaStatus.secondaryOwner) {
		return false;
	}

	return !efaStatus.secondaryOwner.startDate;
}

export function countInvalidDatesEntity(functionalDeclarations: Immutable<Lazy<FunctionalDeclaration>[]>): number {
	return functionalDeclarations.filter(({ efa_status }) => areDatesInvalid(efa_status)).length;
}
