import { Pipe, PipeTransform } from "@angular/core";
import { getEnergyCategoriesToAdd } from "../models/energyCategory";
import { EnergyType } from "../models/energyType";
import { Consumptions } from "../models/functionalDeclaration";

@Pipe({
	name: "maxEndForEnergyType",
})
export class MaxEndForEnergyTypePipe implements PipeTransform {
	transform(consumptions: Consumptions, et: EnergyType, multiOccupation: boolean): number | undefined {
		let end: number | undefined = 0;
		getEnergyCategoriesToAdd(multiOccupation).forEach((cat) => {
			if (consumptions[cat]) {
				Object.keys(consumptions[cat]).forEach((subCat) => {
					Object.keys(consumptions[cat][subCat].values).forEach((energyType) => {
						if (
							energyType === et &&
							end !== undefined &&
							consumptions[cat][subCat].values[energyType].values.length !== 0
						) {
							if (consumptions[cat][subCat].values[energyType].end === undefined) {
								end = undefined;
							} else {
								if (consumptions[cat][subCat].values[energyType].end! > end) {
									end = consumptions[cat][subCat].values[energyType].end;
								}
							}
						}
					});
				});
			}
		});
		if (end === undefined || end === 0) {
			return undefined;
		}
		return end;
	}
}
