import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Immutable } from "immer";
import { Declaration } from "../../../../../../models/declaration";

@Component({
	selector: "app-activity-category-modal",
	templateUrl: "./electric-car-dock-modal.component.html",
	styleUrls: ["./electric-car-dock-modal.component.scss"],
})
export class ElectricCarDockModalComponent {
	electricCarDockEntry: { start: number; end?: number; count: number };
	initialActivityCategoryEntry: Immutable<{ start: number; end?: number; count: number }>;
	adding: boolean;
	readonly START = new Date(Declaration.MINIMUM_DATE_TIME * 1000);
	readonly TODAY = new Date();

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			electricCarDockEntry: Immutable<{ start: number; end?: number; count: number }>;
			adding: boolean;
		},
	) {
		this.initialActivityCategoryEntry = data.electricCarDockEntry;
		this.adding = data.adding;
		this.electricCarDockEntry = { ...data.electricCarDockEntry };
	}

	startDateChange(date: string) {
		this.electricCarDockEntry.start = new Date(date).getTime() / 1000;
	}

	endDateChange(date: string) {
		if (date === "") {
			this.electricCarDockEntry.end = undefined;
			return;
		}
		this.electricCarDockEntry.end = new Date(date).getTime() / 1000;
	}
}
