<div class="close-icon-wrapper">
	<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
</div>
<div class="first-connection">
	<div class="modal-steps" [style]="{ width: totalSteps * 100 + '%' }">
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/1.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Supervisez votre patrimoine</div>
				<div class="modal-step-description">
					Le tableau de bord vous permet de naviguer rapidement entre vos déclarations et leurs différentes étapes.
					Collaborez en toute simplicité !
				</div>
			</div>
		</div>
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/2.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Étudiez votre assujettissement</div>
				<div class="modal-step-description">
					Vérifiez en quelques minutes si vos établissements sont bels et bien concernés par le Décret Tertiaire.
				</div>
			</div>
		</div>
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/3.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Déclarez votre patrimoine</div>
				<div class="modal-step-description">
					Déclarez l'ensemble de vos entités fonctionnelles assujetties et décrivez votre activité.
				</div>
			</div>
		</div>
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/4.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Déclarez vos consommations</div>
				<div class="modal-step-description">
					Récupérez automatiquement l'historique de consommation de vos points de livraison Enedis et GRDF et/ou
					complétez manuellement l'ensemble de vos données.
				</div>
			</div>
		</div>
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/5.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Calculez votre année de référence</div>
				<div class="modal-step-description">
					Le Configurateur s'occupe de tout ! Visualisez votre année de référence et vos objectifs à atteindre, calculés
					automatiquement sur la plateforme.
				</div>
			</div>
		</div>
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/6.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Téléchargez vos fichiers récapitulatifs</div>
				<div class="modal-step-description">
					Le Configurateur génère tous les fichiers nécessaires à vos déclarations, déposez-les simplement sur la
					plateforme OPERAT.
				</div>
			</div>
		</div>
		<div class="modal-step" [style]="{ transform: 'translateX(' + -currentStep * 100 + '%)' }">
			<div class="modal-step-image">
				<img src="assets/img/first-connection/7.svg" />
			</div>
			<div class="modal-step-content">
				<div class="modal-step-title">Ressources et guide</div>
				<div class="modal-step-description">
					Un guide interactif et une FAQ sont disponibles à tout moment sur la plateforme. Si vous êtes perdu, n'hésitez
					pas à vous en servir !
				</div>
			</div>
		</div>
	</div>
	<div class="modal-step-actions">
		<div class="modal-step-actions-previous" *ngIf="currentStep > 0">
			<button class="modal-step-actions-button" (click)="previousStep()">Précédent</button>
		</div>
		<div class="modal-step-actions-next">
			<button class="modal-step-actions-button" (click)="nextStep()">
				{{ currentStep === totalSteps - 1 ? "Démarrer le guide interactif" : "Suivant" }}
			</button>
		</div>
	</div>
</div>
