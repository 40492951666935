import { Pipe, PipeTransform } from "@angular/core";
import { EnergyType, energyTypeUnit } from "../models/energyType";

@Pipe({
	name: "energyTypeUnit",
})
export class EnergyTypeUnitPipe implements PipeTransform {
	transform(et: EnergyType): string {
		return energyTypeUnit(et);
	}
}
