import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { Immutable } from "immer";
import { of, Subject, zip } from "rxjs";
import { switchMap } from "rxjs/operators";
import { unwrap } from "../../../helpers/unwrap";
import { BaseComponent } from "../../../models/base-component.directive";
import { Lazy } from "../../../models/lazy";
import { cloneLazyRepresentative, Representative } from "../../../models/representative";
import { ResourceType } from "../../../models/resource";
import { User } from "../../../models/user";
import { AuthenticationService } from "../../../services/authentication.service";
import { RepresentativeStateService } from "../../../services/representative-state.service";
import { RepresentativeService } from "../../../services/representative.service";
import { UserStateService } from "../../../services/user-state.service";
import { ImageInputComponent } from "../../image-input/image-input.component";

@Component({
	selector: "app-representative-settings",
	templateUrl: "./representative-settings.component.html",
	styleUrls: ["./representative-settings.component.scss"],
})
export class RepresentativeSettingsComponent extends BaseComponent {
	@ViewChild("logo") logo: ImageInputComponent | undefined = undefined;
	@ViewChild("smallLogo") smallLogo: ImageInputComponent | undefined = undefined;
	@ViewChild("icon") icon: ImageInputComponent | undefined = undefined;
	@ViewChild("applicationNameInput") applicationNameInput: ElementRef<HTMLInputElement> | undefined;
	color = "#ff0000";

	representative: Lazy<Representative> | undefined = undefined;
	originalRepresentative: Immutable<Lazy<Representative>> | undefined = undefined;

	editionSubject = new Subject<Lazy<Representative> | undefined>();

	saving = false;

	users: User[] = [];
	currentUser: User | undefined;

	toUpdate = false;

	constructor(
		private representativeState: RepresentativeStateService,
		private authentication: AuthenticationService,
		private representativeService: RepresentativeService,
		userState: UserStateService,
	) {
		super();
		this.currentUser = userState.user;
		this.sub(this.representativeState.get$, (representative) => {
			if (representative) {
				this.representative = cloneLazyRepresentative(representative.value);
				this.originalRepresentative = cloneLazyRepresentative(representative.value);

				this.sub(
					authentication.getInvitations$(this.representative.representative_id, ResourceType.Representative),
					(users) => {
						this.users = users;
					},
				);

				// If it's empty the API returns an empty object, which gets transformed to array in typescript, so we have to check if it is an array too
				if (!this.representative.params || Array.isArray(this.representative.params)) {
					this.representative.params = {
						theme: {},
					};
				}

				if (Object.keys(this.representative.params.theme).length === 0) {
					this.representative.params.theme = {
						primary: "#365CC1",
						primary_light: "#1A83F8",
						accent: "#81DA98",
						accent2: "#f2d16a",
						accent3: "#FB4181",
						"text-secondary": "#1a5261",
						danger: "#D15881",
						warning: "#ff9c07",
					};
				}
			}
		});
	}

	save() {
		if (this.representative) {
			this.saving = true;
			const logoObservable$ =
				this.logo &&
				this.logo.imageInput.nativeElement.files !== null &&
				this.logo.imageInput.nativeElement.files.length > 0
					? this.representativeService.updateImage$(
							unwrap(this.representative.representative_id),
							"logo",
							this.logo.imageInput.nativeElement.files[0],
						)
					: of(null);

			const logoSmallObservable$ =
				this.smallLogo &&
				this.smallLogo.imageInput.nativeElement.files !== null &&
				this.smallLogo.imageInput.nativeElement.files.length > 0
					? this.representativeService.updateImage$(
							unwrap(this.representative.representative_id),
							"logo_small",
							this.smallLogo.imageInput.nativeElement.files[0],
						)
					: of(null);

			const iconObservable$ =
				this.icon &&
				this.icon.imageInput.nativeElement.files !== null &&
				this.icon.imageInput.nativeElement.files.length > 0
					? this.representativeService.updateImage$(
							unwrap(this.representative.representative_id),
							"favicon",
							this.icon.imageInput.nativeElement.files[0],
						)
					: of(null);

			this.representativeState
				.updateSync$(this.representative)
				.pipe(
					switchMap(() => {
						return zip(logoObservable$, logoSmallObservable$, iconObservable$);
					}),
				)
				.subscribe(
					() => {
						this.saving = false;
						location.reload();
					},
					(error: unknown) => {
						if (
							error instanceof HttpErrorResponse &&
							error.error.error === "Application name already used." &&
							this.applicationNameInput
						) {
							this.applicationNameInput.nativeElement.setCustomValidity("Nom d'application déjà utilisé ou réservé.");
						}
						this.saving = false;
					},
				);
		}
	}

	setTheme() {
		if (this.representative) {
			Object.keys(this.representative.params.theme).forEach((variableName) => {
				if (this.representative) {
					document.documentElement.style.setProperty(
						`--${variableName.replace("_", "-")}`,
						this.representative.params.theme[variableName],
					);
				}
			});
			this.update();
		}
	}

	update() {
		let toUpdate = false;
		if (
			this.logo?.imageInput.nativeElement instanceof HTMLInputElement &&
			this.smallLogo?.imageInput.nativeElement instanceof HTMLInputElement &&
			this.icon?.imageInput.nativeElement instanceof HTMLInputElement
		) {
			toUpdate =
				(this.logo?.imageInput.nativeElement.files !== null && this.logo?.imageInput.nativeElement.files.length > 0) ||
				(this.smallLogo?.imageInput.nativeElement.files !== null &&
					this.smallLogo?.imageInput.nativeElement.files.length > 0) ||
				(this.icon?.imageInput.nativeElement.files !== null && this.icon?.imageInput.nativeElement.files.length > 0);
		}
		if (this.representative) {
			this.representative = { ...this.representative };
		}

		this.toUpdate = toUpdate || JSON.stringify(this.representative) !== JSON.stringify(this.originalRepresentative);
	}

	removeUser(user: User) {
		if (user.user_id && this.representative) {
			this.authentication
				.deleteInvitation$(user.user_id, this.representative.representative_id, ResourceType.Representative)
				.subscribe(() => {
					this.users = this.users?.filter((usr) => usr !== user);
				});
		}
	}
}
