import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Immutable } from "immer";
import { renewalAskedRecently } from "../../../../../../helpers/agreements-helpers";
import { AgreementAsk } from "../../../../../../models/agreementAsk";
import { BaseComponent } from "../../../../../../models/base-component.directive";
import { HolderType } from "../../../../../../models/HolderType";

@Component({
	selector: "app-agreement",
	templateUrl: "./agreement.component.html",
	styleUrls: ["./agreement.component.scss"],
})
export class AgreementComponent extends BaseComponent {
	@Input() agreement!: Immutable<AgreementAsk>;
	@Input() color: string = "primary";
	@Input() canEdit = false;
	@Input() canGoAssetDeclaration: boolean = false;
	@Input() showStatus: boolean = false;

	@Output() goAssetDeclaration = new EventEmitter<void>();
	@Output() editAction = new EventEmitter<Immutable<AgreementAsk>>();
	@Output() resendAction = new EventEmitter<Immutable<AgreementAsk>>();
	@Output() renewalAction = new EventEmitter<Immutable<AgreementAsk>>();

	HolderType = HolderType;

	constructor(private dialog: MatDialog) {
		super();
	}

	@ViewChild("modalRenewal") modalRenewal!: TemplateRef<MatDialog>;

	get canRenewAgreement(): boolean {
		return this.agreement?.is_treated;
	}

	isLessThan6Months(): boolean {
		return renewalAskedRecently(this.agreement);
	}

	openConfirmDialog(): void {
		this.dialog.open(this.modalRenewal, { panelClass: "p-0" });
	}
}
