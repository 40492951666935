import { Component } from "@angular/core";
import { Immutable } from "immer";
import { groupCsvRefToObject, OperatCsvData } from "../../../../../helpers/import-csv-helper";
import { Nullable } from "../../../../../helpers/nullable";
import { Declaration } from "../../../../../models/declaration";
import { DeclarationGroup } from "../../../../../models/declarationGroup";
import { getOperatReference } from "../../../../../models/efaStatus";
import { Lazy } from "../../../../../models/lazy";
import { Modal } from "../../../../../models/modal";
import { AddressSearchService } from "../../../../../services/address-search.service";
import { HelpSubject } from "../../../../help/help.component";
import { ImportTypeEnum } from "../../../user-info-form/operat-search-form/ImportTypeEnum";
import { OperatApiImportData } from "../../../user-info-form/operat-search-form/operat-search-form.component";
import getIdentifier = Declaration.getIdentifier;

type Input = {
	declaration: Immutable<Lazy<Declaration>>;
	addressSearch: AddressSearchService;
	group: Nullable<Immutable<Lazy<DeclarationGroup>>>;
};
type State = {
	declaration: Immutable<Lazy<Declaration>>;
	addressSearch: AddressSearchService;
	canEditUserKey: boolean;
};
type Output =
	| { type: ImportTypeEnum.CSV; data: Nullable<OperatCsvData> }
	| { type: ImportTypeEnum.API; data: OperatApiImportData };

@Component({
	selector: "app-import-efa-modal",
	templateUrl: "./import-efa-modal.component.html",
	styleUrls: ["./import-efa-modal.component.scss"],
})
export class ImportEfaModalComponent extends Modal<Input, State, Output> {
	csvFile: Nullable<File>;
	importIndex = 0;
	apiImportData: OperatApiImportData = { references: [], userKey: "", structId: "" };

	inputToState(input: Input): State {
		return {
			declaration: input.declaration,
			addressSearch: input.addressSearch,
			canEditUserKey: !!input.group,
		};
	}

	save() {
		switch (this.importIndex) {
			case ImportTypeEnum.CSV:
				this.csvFile?.text().then((csv) => {
					const importData$ = groupCsvRefToObject(csv, this.state.addressSearch);
					importData$.subscribe((data) => {
						// Remove duplicate
						for (const functionalDeclaration of this.state.declaration.declarations_functional) {
							const functionalDeclarationRef = getOperatReference(functionalDeclaration, this.state.declaration);
							// If EFA already exists, delete
							delete data[functionalDeclarationRef];
						}
						this.close({ type: ImportTypeEnum.CSV, data: Object.keys(data).length > 0 ? data : null });
					});
				});
				break;
			case ImportTypeEnum.API:
				this.close({ type: ImportTypeEnum.API, data: this.apiImportData });
		}
	}

	getDeclarationEfaReferences(declaration: Immutable<Lazy<Declaration>>): { refOperat: string; name: string }[] {
		return declaration.declarations_functional.map((efa) => ({
			refOperat: getOperatReference(efa, declaration),
			name: efa.name ?? "Entité sans nom",
		}));
	}

	protected readonly HelpSubject = HelpSubject;
	protected readonly Declaration = Declaration;
	protected readonly getIdentifier = getIdentifier;
	protected readonly ImportTypeEnum = ImportTypeEnum;
}
