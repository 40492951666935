<ng-container *ngIf="this.declarationsFunctional.length > 0">
	<div class="input-icon" style="margin-left: 8px">
		<input
			[(ngModel)]="search"
			class="custom-input-light"
			style="min-width: 370px; display: block"
			placeholder="Rechercher une entité fonctionnelle"
			(ngModelChange)="updateSearch()"
		/>
		<span class="input-suffix">
			{{ filteredDeclarationsFunctional.length }}
			<mat-icon>domain</mat-icon>
		</span>
	</div>
	<mat-dialog-content>
		<ng-container>
			<table class="w-100">
				<thead>
					<tr class="table-header">
						<th></th>
						<th *ngFor="let page of FUNCTIONAL_PAGES">{{ page.label }}</th>
					</tr>
				</thead>
				<tbody>
					<tr class="table-row" *ngFor="let declarationFunctional of declarationsFunctionalToShow">
						<td style="padding-left: 10px">
							<div class="declaration-title" style="justify-content: left">
								<div style="display: inline; text-align: left; justify-content: left">
									<p class="primary" style="margin: 0">
										<span style="font-weight: bold">{{ declarationFunctional.name }}</span> -
										<i>{{ declarationFunctional.efa_status | getLabelFromEfaStatus }}</i>
									</p>
									<p class="primary-light" style="margin: 0; font-size: 15px">
										{{ declarationFunctional | functionalDeclarationSubtitle }}
									</p>
									<p style="margin: 0; font-size: 12px; color: gray">
										{{ declarationFunctional.address | addressToString }}
									</p>
								</div>
							</div>
						</td>
						<ng-container *ngFor="let page of FUNCTIONAL_PAGES">
							<td (click)="selectFunctionalStep(declarationFunctional, page)">
								<div class="d-flex" style="justify-content: center">
									<app-advancement-chip
										[percent]="(page | hasNotDoneStep: declarationFunctional) === null ? 100 : 0"
									></app-advancement-chip>
								</div>
							</td>
						</ng-container>
						<td>
							<ng-container *ngIf="declarationFunctional | checkFunctionalDeclarationState as errorList">
								<mat-icon
									*ngIf="errorList.length > 0"
									[matTooltip]="errorList | checkErrorsEntityFormat"
									class="danger"
									matTooltipClass="tooltip-linebreak"
								>
									warning
								</mat-icon>
							</ng-container>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
	</mat-dialog-content>
	<app-pagination [currentPage]="page" [maxPage]="maxPage" (pageChange)="setPage($event)"></app-pagination>
</ng-container>
