import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BaseComponent } from "../../../../models/base-component.directive";
import { cloneUser, User } from "../../../../models/user";
import { UserStateService } from "../../../../services/user-state.service";

@Component({
	selector: "app-address-form",
	templateUrl: "./address-form.component.html",
	styleUrls: ["./address-form.component.scss"],
})
export class AddressFormComponent extends BaseComponent implements AfterViewInit {
	@Input() loading = false;
	@Input() buttonLabel = "Enregistrer";
	@Input() showButton = true;
	@Input() showName = false;
	@Output() continue = new EventEmitter<User>();

	@ViewChild("addressForm") form: ElementRef | null = null;

	formValid = false;

	@Input() user: User | undefined = undefined;

	constructor(userState: UserStateService) {
		super();

		if (!this.user && userState.user) {
			this.user = cloneUser(userState.user);
		}
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		setTimeout(() => {
			this.checkFormValidity();
		});
	}

	checkFormValidity() {
		this.formValid = this.form?.nativeElement.checkValidity() ?? false;
	}
}
