import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AddressInfo } from "../models/address";
import { Etablissement } from "../models/siren-api.types";

@Pipe({
	name: "getFromEstablishmentAddress",
})
export class GetFromEstablishmentAddressPipe implements PipeTransform {
	transform(value: Immutable<Etablissement>): Immutable<AddressInfo> {
		return value.address;
	}
}
