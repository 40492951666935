import { now } from "@amcharts/amcharts5/.internal/core/util/Time";
import { Component, ElementRef, Inject, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GreenKeys } from "@grs/greenkeys";
import FileSaver from "file-saver";
import html2canvas from "html2canvas";
import { Immutable } from "immer";
import JSZip from "jszip";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { Content } from "pdfmake/interfaces";
import { forkJoin, from, Observable, of, ReplaySubject, Subscription, zip } from "rxjs";
import { delay, first, map, switchMap, tap } from "rxjs/operators";
import { FunctionalDeclarationId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { BenchMarkData, ChartData } from "../../../helpers/benchmark-data";
import { unwrap } from "../../../helpers/unwrap";
import { Declaration } from "../../../models/declaration";
import { getCondominiumIfExists, getOperatReference, getOwnerIfExists } from "../../../models/efaStatus";
import { entityCanAccessPremiumFeatures, FunctionalDeclaration } from "../../../models/functionalDeclaration";
import { ResourceType } from "../../../models/resource";
import { DeclarationFunctionalService, Djus } from "../../../services/declaration-functional.service";
import { DeclarationService } from "../../../services/declaration.service";
import { ConfirmationModalComponent } from "../../confirmation-modal/confirmation-modal.component";
import { Stats } from "../my-declarations/stats/stats.component";
import { ActivityCategoryTableComponent } from "../stepper/functional-entity-stepper/asset-declaration/activity-category-table/activity-category-table.component";
import { ConsumptionTableExportComponent } from "../stepper/functional-entity-stepper/declaration-conso/consumption-table/consumption-table-export/consumption-table-export.component";
import { YearReferenceDataComponent } from "../stepper/functional-entity-stepper/year-reference/year-reference-data/year-reference-data.component";
import { ReferenceYearSummaryTableComponent } from "../stepper/reference-year-summary/reference-year-summary-table/reference-year-summary-table.component";

// (pdfMake as typeof pdfMake).vfs = pdfFonts.pdfMake.vfs;

type BenchmarkYearData = {
	benchmarkData: ChartData[];
	minTotal: number;
	maxTotal: number;
	minPerSurface: number;
	maxPerSurface: number;
};

// a function that returns a function
const FILTER =
	(chosenEntities: Immutable<Set<FunctionalDeclarationId>>) =>
	(functionalDeclaration: Immutable<Lazy<FunctionalDeclaration>>) =>
		functionalDeclaration.declaration_functional_id &&
		entityCanAccessPremiumFeatures(functionalDeclaration) &&
		(chosenEntities.size === 0 || chosenEntities.has(functionalDeclaration.declaration_functional_id));

@Component({
	selector: "app-pdf-report",
	templateUrl: "./pdf-report.component.html",
	styleUrls: ["./pdf-report.component.scss"],
})
export class PdfReportComponent {
	ESTIMATE_TIME_PER_ENTITY = 30;
	estimateDate!: Date;
	activeEstimateDate: Date | undefined;
	stepTime = 0;
	startTime = 0;
	getOwnerIfExists = getOwnerIfExists;
	getCondominiumIfExists = getCondominiumIfExists;

	@ViewChildren("functionalEntityContent") functionalEntityContent!: QueryList<ElementRef>;
	@ViewChildren("referenceData") referenceData!: QueryList<YearReferenceDataComponent>;
	@ViewChild("categoryData") categoryData!: ActivityCategoryTableComponent;
	@ViewChild("consumptionData") consumptionData: ConsumptionTableExportComponent | undefined;
	@ViewChild("consumptionData2020") consumptionData2020: ConsumptionTableExportComponent | undefined;
	@ViewChild("consumptionData2021") consumptionData2021: ConsumptionTableExportComponent | undefined;
	@ViewChild("consumptionDataLastYear") consumptionDataLastYear: ConsumptionTableExportComponent | undefined;
	@ViewChild("summaryTableComponent") summaryTableComponent!: ReferenceYearSummaryTableComponent;
	@ViewChild("functionalEntitySummary") functionalEntitySummary: ElementRef | undefined;
	@ViewChild("benchmarkGraphLastYear") benchmarkGraphLastYear!: ElementRef;
	@ViewChild("benchmarkGraphYearBeforeLastYear") benchmarkGraphYearBeforeLastYear!: ElementRef;
	@ViewChild("statPage") statPage!: ElementRef;
	declaration: Declaration;

	chunk:
		| {
				declarationFunctional: FunctionalDeclaration;
				djus: { [year: string]: number };
				absoluteObjectives: { [year: string]: number };
		  }
		| undefined;

	djus: Djus = {};

	reportTitle = "";
	toTreat = -1;
	generated = 0;
	converted = 0;
	downloadSubscribe: Subscription | undefined;

	stats: Stats | undefined;

	data = new ReplaySubject<
		{
			declarationFunctional: FunctionalDeclaration;
			djus: { [year: string]: number };
			absoluteObjectives: { [year: string]: number };
		}[]
	>(1);
	functionalEntity: FunctionalDeclaration | undefined;

	headerImage = "";

	lastYear = now().getFullYear() - 1;
	lastYearData: BenchmarkYearData;
	yearBeforeLastYear = this.lastYear - 1;
	yearBeforeLastYearData: BenchmarkYearData;

	constructor(
		@Inject(MAT_DIALOG_DATA) declaration: Declaration,
		private dialogRef: MatDialogRef<PdfReportComponent>,
		private declarationFunctionalService: DeclarationFunctionalService,
		private dialog: MatDialog,
		private declarationService: DeclarationService,
	) {
		this.dialogRef.disableClose = true;
		this.declaration = declaration;
		this.estimateDate = new Date(
			declaration.declarations_functional.filter(FILTER(this.declaration.chosen_declaration_functional_ids_for_csv))
				.length *
				1000 *
				this.ESTIMATE_TIME_PER_ENTITY,
		);

		this.lastYearData = BenchMarkData.getData(this.declaration, this.djus, this.lastYear);
		this.yearBeforeLastYearData = BenchMarkData.getData(this.declaration, this.djus, this.yearBeforeLastYear);
	}

	cancelDownload() {
		if (this.downloadSubscribe) {
			this.dialog
				.open(ConfirmationModalComponent, {
					data: {
						title: "Voulez-vous vraiment annuler ?",
						description:
							"Êtes-vous sûr(e) de vouloir annuler la génération du rapport ? Si vous poursuivez, les progrès réalisés seront perdus.",
					},
					panelClass: "p-0",
				})
				.afterClosed()
				.subscribe((response) => {
					if (response) {
						this.downloadSubscribe?.unsubscribe();
						this.toTreat = -1;
					}
				});
		}
	}

	download() {
		this.startTime = new Date().getTime();
		this.activeEstimateDate = this.estimateDate;
		// Only generate report for chosen entities with token used
		const declarationsFunctional = this.declaration.declarations_functional.filter(
			FILTER(this.declaration.chosen_declaration_functional_ids_for_csv),
		);
		// We want to recalculate interval every seconds
		const interval = setInterval(() => {
			this.calculateEstimation();
		}, 1000);
		this.generated = 0;
		this.converted = 0;
		this.toTreat = 0;
		const ids = declarationsFunctional.map((declarationFunctional) =>
			unwrap(declarationFunctional[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID]),
		);
		const summaryContent: Content[] = [];
		// Add the report title to the summary page
		summaryContent.push({
			text: this.reportTitle,
			style: {
				fontSize: 22,
				bold: true,
			},
		});
		const zipFile = new JSZip();
		// Get the djus and absolute objectives for every entity that will have a report
		this.downloadSubscribe = zip(
			of(declarationsFunctional),
			this.declarationFunctionalService.getDjus$(ids),
			this.declarationFunctionalService.getAbsoluteObjectives$(ids),
		)
			.pipe(
				tap(([, djus]) => {
					this.djus = djus;
				}),
				map(([declarationsFunctional, djus, absoluteObjectives]) =>
					// map the data to have the entity and his djus and absolute objectives in the same object
					declarationsFunctional.map((declarationFunctional) => ({
						declarationFunctional: declarationFunctional,
						djus: djus[unwrap(declarationFunctional[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID])] ?? {},
						absoluteObjectives:
							absoluteObjectives[unwrap(declarationFunctional[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID])] ?? {},
					})),
				),
				switchMap((data) =>
					// load the table header for reference year summary and push to the summary content
					from(html2canvas(this.summaryTableComponent.headerElement.nativeElement)).pipe(
						tap((image) => {
							this.headerImage = image.toDataURL();
							summaryContent.push({ image: this.headerImage, width: 500 });
						}),
						switchMap(() => of(data)),
					),
				),
				// load the stats
				switchMap((data) =>
					this.declarationService
						.stats$({ id: unwrap(this.declaration.declaration_id), type: ResourceType.Declaration })
						.pipe(
							tap((stats) => {
								this.stats = stats;
							}),
							switchMap(() => of(data)),
						),
				),
				switchMap((data) => {
					this.toTreat = data.length;
					// Run the loop for every entity to generate content
					return this.chunkLoop$(data, 0, zipFile, summaryContent);
				}),
			)
			.subscribe(() => {
				clearInterval(interval);
				// When done, we generate the zip file and save it
				zipFile.generateAsync({ type: "blob" }).then((content) => {
					// see FileSaver.js
					FileSaver.saveAs(content, "rapport.zip");
					this.toTreat = -1;
					this.dialogRef.close();
				});
			});
	}

	calculateEstimation() {
		// we wait before having at least 2 generated in order to have a better visualisation on how much time it may take
		if (this.stepTime !== 0 && this.generated > 1) {
			this.activeEstimateDate = new Date(
				Math.max(
					((this.stepTime - this.startTime) * (this.toTreat - this.generated)) / this.generated -
						(new Date().getTime() - this.stepTime),
					0,
				),
			);
		} else {
			this.activeEstimateDate = new Date(
				this.ESTIMATE_TIME_PER_ENTITY * this.toTreat * 1000 - (new Date().getTime() - this.startTime),
			);
		}
	}

	chunkLoop$(
		data: {
			declarationFunctional: FunctionalDeclaration;
			djus: { [year: string]: number };
			absoluteObjectives: { [year: string]: number };
		}[],
		index: number,
		zipFile: JSZip,
		summaryContent: Content[] = [],
	): Observable<unknown> {
		// When an entity is done recalculate estimation
		this.calculateEstimation();
		// If we have done all the entities
		if (index >= data.length) {
			this.chunk = undefined;
			// if we only have one entity do nothing
			if (this.declaration.declarations_functional.length <= 1) {
				return of(undefined);
			}
			// otherwise generate the summary report
			return of(undefined).pipe(
				// Must add a delay otherwise we can have problem with generation
				delay(200),
				// add the benchmarks graphs to the summary
				switchMap(() =>
					this.lastYearData.benchmarkData.length > 0
						? from(html2canvas(this.benchmarkGraphLastYear.nativeElement))
						: of(undefined),
				),
				tap((res) => {
					if (res) {
						summaryContent.push({
							text: `Consommations énergétiques du patrimoine en ${this.lastYear} : nuage de points`,
							style: {
								fontSize: 22,
								bold: true,
							},
							pageOrientation: "landscape",
							pageBreak: "before",
						});
						summaryContent.push({
							image: res.toDataURL(),
							width: 750,
						});
					}
				}),
				switchMap(() =>
					this.yearBeforeLastYearData.benchmarkData.length > 0
						? from(html2canvas(this.benchmarkGraphYearBeforeLastYear.nativeElement))
						: of(undefined),
				),
				tap((res) => {
					if (res) {
						summaryContent.push({
							text: `Consommations énergétiques du patrimoine en ${this.yearBeforeLastYear} : nuage de points`,
							style: {
								fontSize: 22,
								bold: true,
							},
							pageOrientation: "landscape",
							pageBreak: "before",
						});
						summaryContent.push({
							image: res.toDataURL(),
							width: 750,
						});
					}
				}),
				// add the stats to the summary
				switchMap(() => from(html2canvas(this.statPage.nativeElement))),
				tap((res) => {
					summaryContent.push({
						text: "Statistiques",
						style: {
							fontSize: 22,
							bold: true,
						},
						pageOrientation: "portrait",
						pageBreak: "before",
					});
					summaryContent.push({
						image: res.toDataURL(),
						width: 750,
					});
				}),
				// Generate the general report and add it to the zip archive
				switchMap(() => {
					return new Observable((sub) => {
						pdfMake
							.createPdf(
								{
									pageSize: "A4",
									content: summaryContent,
								},
								undefined,
								undefined,
								pdfFonts.pdfMake.vfs,
							)
							.getBlob((blob) => {
								zipFile.file("rapport_general.pdf", blob, { base64: true });
								sub.next();
								sub.complete();
							});
					});
				}),
			);
		}

		// Add summary table to the page content
		const pageContent: Content = [];
		pageContent.push({
			image: this.headerImage,
			width: 500,
		});

		// Select the correct declaration
		this.chunk = data.slice(index, index + 1)[0];
		this.functionalEntity = this.chunk.declarationFunctional;

		return zip(
			this.referenceData.changes.pipe(
				first(),
				// Wait for year reference graph to be generated
				switchMap((data: QueryList<YearReferenceDataComponent>) =>
					forkJoin(data.toArray().map((component) => component.readySubject$)),
				),
				// generate the Activity Category table and add it to the page
				switchMap(() => from(html2canvas(this.functionalEntitySummary?.nativeElement))),
				tap((res) => {
					pageContent.push({ image: res.toDataURL(), width: 500 });
				}),
				switchMap(() =>
					forkJoin(this.categoryData.rowElements.map((element) => from(html2canvas(element.nativeElement)))).pipe(
						tap((res) => {
							pageContent.push({
								text: "Catégories d'activités",
								style: {
									fontSize: 22,
									bold: true,
								},
							});
							res.forEach((result) => {
								pageContent.push({
									image: result.toDataURL(),
									width: 500,
								});
							});
						}),
					),
				),
				// Add the table for year reference consumptions
				switchMap(() =>
					this.consumptionData
						? forkJoin(
								this.consumptionData.consumptionTables.map((element) => from(html2canvas(element.nativeElement))),
							).pipe(
								tap((res) => {
									if (res) {
										pageContent.push({
											text: "Consommations de l'année de référence",
											style: {
												fontSize: 22,
												bold: true,
											},
											pageOrientation: "portrait",
											pageBreak: "before",
											margin: [0, 30, 0, 0],
										});
										res.forEach((result) => {
											pageContent.push({
												image: result.toDataURL(),
												width: 500,
											});
										});
									}
								}),
							)
						: of([]),
				),
				// Add the table for last year's consumptions, if it's unlocked
				switchMap(() =>
					this.consumptionDataLastYear &&
					this.consumptionDataLastYear.functionalDeclaration[GreenKeys.KEY_YEARS_TOKEN_USED].includes(this.lastYear)
						? forkJoin(
								this.consumptionDataLastYear.consumptionTables.map((element) =>
									from(html2canvas(element.nativeElement)),
								),
							).pipe(
								tap((res) => {
									if (res) {
										pageContent.push({
											text: `Consommations de l'année ${this.lastYear}`,
											style: {
												fontSize: 22,
												bold: true,
											},
											pageOrientation: "portrait",
											pageBreak: "before",
											margin: [0, 30, 0, 0],
										});
										res.forEach((result) => {
											pageContent.push({
												image: result.toDataURL(),
												width: 500,
											});
										});
									}
								}),
							)
						: of([]),
				),
				// Add the table for year 2021 consumptions
				switchMap(() =>
					this.consumptionData2021
						? forkJoin(
								this.consumptionData2021.consumptionTables.map((element) => from(html2canvas(element.nativeElement))),
							).pipe(
								tap((res) => {
									if (res) {
										pageContent.push({
											text: "Consommations de l'année 2021",
											style: {
												fontSize: 22,
												bold: true,
											},
											pageOrientation: "portrait",
											pageBreak: "before",
											margin: [0, 30, 0, 0],
										});
										res.forEach((result) => {
											pageContent.push({
												image: result.toDataURL(),
												width: 500,
											});
										});
									}
								}),
							)
						: of([]),
				),
				// Add the table for year 2020 consumptions
				switchMap(() =>
					this.consumptionData2020 && this.chunk?.declarationFunctional.is_token_used
						? forkJoin(
								this.consumptionData2020.consumptionTables.map((element) => from(html2canvas(element.nativeElement))),
							).pipe(
								tap((res) => {
									if (res) {
										pageContent.push({
											text: "Consommations de l'année 2020",
											style: {
												fontSize: 22,
												bold: true,
											},
											pageOrientation: "portrait",
											pageBreak: "before",
											margin: [0, 30, 0, 0],
										});
										res.forEach((result) => {
											pageContent.push({
												image: result.toDataURL(),
												width: 500,
											});
										});
									}
								}),
							)
						: of([]),
				),
				// Add the graph for year reference selection summary
				switchMap(() =>
					this.chunk?.declarationFunctional.is_token_used
						? forkJoin(
								this.referenceData.map((element) =>
									from(html2canvas(element.chartDiv.nativeElement)).pipe(
										tap(() => {
											this.converted++;
										}),
									),
								),
							).pipe(
								tap((res) =>
									res.forEach((result) => {
										pageContent.push({
											text: "Récapitulatif des consommations par année et objectifs",
											style: {
												fontSize: 22,
												bold: true,
											},
											pageOrientation: "landscape",
											pageBreak: "before",
										});
										pageContent.push({
											image: result.toDataURL(),
											width: 750,
										});
									}),
								),
							)
						: of(undefined),
				),
				// Add the benchmark graph of last year for the declaration with the current entity highlighted, if there's data
				switchMap(() =>
					this.lastYearData.benchmarkData.length > 0
						? from(html2canvas(this.benchmarkGraphLastYear.nativeElement))
						: of(undefined),
				),
				tap((res) => {
					if (res) {
						pageContent.push({
							text: `Consommations énergétiques du patrimoine en ${this.lastYear} : nuage de points`,
							style: {
								fontSize: 22,
								bold: true,
							},
							pageOrientation: "landscape",
							pageBreak: "before",
						});
						pageContent.push({
							image: res.toDataURL(),
							width: 750,
						});
					}
				}),
				// Add the benchmark graph of year before last year for the declaration with the current entity highlighted, if there's data
				switchMap(() =>
					this.yearBeforeLastYearData.benchmarkData.length > 0
						? from(html2canvas(this.benchmarkGraphYearBeforeLastYear.nativeElement))
						: of(undefined),
				),
				tap((res) => {
					if (res) {
						pageContent.push({
							text: `Consommations énergétiques du patrimoine en ${this.yearBeforeLastYear} : nuage de points`,
							style: {
								fontSize: 22,
								bold: true,
							},
							pageOrientation: "landscape",
							pageBreak: "before",
						});
						pageContent.push({
							image: res.toDataURL(),
							width: 750,
						});
					}
				}),
				// Generate the file and add it to the zip archive
				switchMap(() => {
					return new Observable((sub) => {
						pdfMake
							.createPdf(
								{
									pageSize: "A4",
									content: pageContent,
								},
								undefined,
								undefined,
								pdfFonts.pdfMake.vfs,
							)
							.getBlob((blob) => {
								if (this.chunk) {
									zipFile.file(`${getOperatReference(this.chunk.declarationFunctional, this.declaration)}.pdf`, blob, {
										base64: true,
									});
									sub.next();
									sub.complete();
								}
							});
					});
				}),
				tap(() => {
					this.generated++;
					this.stepTime = new Date().getTime();
				}),
			),
			this.summaryTableComponent.entityRowElement.changes.pipe(
				first(),
				switchMap(() =>
					forkJoin(
						this.summaryTableComponent.entityRowElement.map((element) =>
							from(html2canvas(element.nativeElement)).pipe(map((image) => image.toDataURL())),
						),
					),
				),
				// Add the table entry in the general report
				tap(() => {
					this.functionalEntity = undefined;
				}),
				tap((res) =>
					res.forEach((result) => {
						pageContent.push({
							image: result,
							width: 500,
						});
						summaryContent.push({
							image: result,
							width: 500,
						});
					}),
				),
			),
		).pipe(switchMap(() => this.chunkLoop$(data, index + 1, zipFile, summaryContent)));
	}
}
