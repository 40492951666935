import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { BaseComponent } from "../../../models/base-component.directive";
import { Payment } from "../../../models/payment";
import { AuthorizationViewLoaderService } from "../../../services/authorization-view-loader.service";
import { DeclarationGroupStateService } from "../../../services/declaration-group-state.service";
import { PaymentService } from "../../../services/payment.service";
import { RepresentativeStateService } from "../../../services/representative-state.service";

@Component({
	selector: "app-buy-token",
	templateUrl: "./buy-token.component.html",
	styleUrls: ["./buy-token.component.scss"],
	animations: [
		trigger("histoMenu", [
			state("open", style({ width: "100%" })),
			state("close", style({ width: 0, display: "none" })),
			transition("open => close", [animate(500)]),
			transition("close => open", [style({ display: "block" }), animate(500)]),
		]),
		trigger("histoButton", [
			state("open", style({ transform: "rotateZ(0)" })),
			state("close", style({ transform: "rotateZ(180deg)" })),
			transition("open => close", [animate(100)]),
			transition("close => open", [animate(100)]),
		]),
		trigger("histoButtonText", [
			state("open", style({ width: 0 })),
			state("close", style({ width: "*" })),
			transition("open => close", [animate(500)]),
			transition("close => open", [animate(500)]),
		]),
	],
})
export class BuyTokenComponent extends BaseComponent implements AfterViewInit {
	payments: Payment[] = [];
	GreenKeys = GreenKeys;
	visibleDrawer = false;

	@ViewChild("drawer") drawer!: ElementRef;

	constructor(
		private authorizationViewLoaderService: AuthorizationViewLoaderService,
		private representativeState: RepresentativeStateService,
		private declarationGroupState: DeclarationGroupStateService,
		private activatedRoute: ActivatedRoute,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private paymentService: PaymentService,
	) {
		super();

		this.init();
		this.sub(this.router.events, (e) => {
			if (e instanceof NavigationEnd) {
				this.init();
				this.ngAfterViewInit();
			}
		});
	}

	init() {
		this.sub(this.paymentService.getPayments$(), (payments) => {
			this.payments = payments;
		});
	}

	changeDrawerState() {
		this.visibleDrawer = !this.visibleDrawer;
	}

	get drawerState() {
		return this.visibleDrawer ? "open" : "close";
	}
}
