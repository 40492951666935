/* eslint-disable @typescript-eslint/no-namespace */
import { Immutable } from "immer";

export interface OptionalBuildingData {
	table5: Table5.Data;
	table6: Table6.Data;
}

export function cloneOptionalBuildingData({ table5, table6 }: Immutable<OptionalBuildingData>): OptionalBuildingData {
	return {
		table5: {
			...table5,
		},
		table6: {
			...table6,
			typePrincipalDEmetteurDeChauffage:
				typeof table6.typePrincipalDEmetteurDeChauffage === "object"
					? { ...table6.typePrincipalDEmetteurDeChauffage }
					: table6.typePrincipalDEmetteurDeChauffage,
			typePrincipalDeVentilation:
				typeof table6.typePrincipalDeVentilation === "object"
					? { ...table6.typePrincipalDeVentilation }
					: table6.typePrincipalDeVentilation,
			autresEquipementsImmobiliers: table6.autresEquipementsImmobiliers.map((e) => ({ ...e })),
		},
	};
}

export namespace Table5 {
	export interface Data {
		epoqueDeConstruction?: EpoqueDeConstruction;
		typeDePatrimoine?: TypeDePatrimoine;
		formeEmpriseAuSol?: FormeEmpriseAusol;
		hauteurMoyenneDesNiveaux?: HauteurMoyenneDesNiveaux;
		mitoyennete?: Mitoyennete;
		coActiviteNonTertiaireDansLeBatiment?: boolean;
		facade?: Facade;
		contraintesSurFacade?: ContraintesSurFacade;
		isolationThermiqueDesFacades?: IsolationThermique;
		isolationThermiqueDesPignons?: IsolationThermique;
		modeDIsolationThermiquePrincipal?: IsolationThermiquePrincipal;
		traitementThermiqueDesMenuiseries?: IsolationThermiqueDesMenuiseries;
		toiture?: Toiture;
		traitementThermiqueDeLaToiture?: IsolationThermique2;
		plancherBas?: PlancherBas;
		isolationThermiqueDuPlancherBas?: IsolationThermique2;
	}

	export enum EpoqueDeConstruction {
		Before1400,
		From1400To1700,
		From1700To1800,
		From1800To1899,
		From1900To1947,
		From1948To1979,
		From1980To2000,
		From2001To2012,
		From2013To2021,
		After2021,
	}

	export namespace EpoqueDeConstruction {
		export const ALL = [
			EpoqueDeConstruction.Before1400,
			EpoqueDeConstruction.From1400To1700,
			EpoqueDeConstruction.From1700To1800,
			EpoqueDeConstruction.From1800To1899,
			EpoqueDeConstruction.From1900To1947,
			EpoqueDeConstruction.From1948To1979,
			EpoqueDeConstruction.From1980To2000,
			EpoqueDeConstruction.From2001To2012,
			EpoqueDeConstruction.From2013To2021,
			EpoqueDeConstruction.After2021,
		];

		export function toLabel(epoqueDeConstruction: EpoqueDeConstruction): string {
			switch (epoqueDeConstruction) {
				case EpoqueDeConstruction.Before1400:
					return "Avant 1400 - Moyen âge et antiquité";
				case EpoqueDeConstruction.From1400To1700:
					return "1400 à 1700 - Renaissance";
				case EpoqueDeConstruction.From1700To1800:
					return "1700 à 1800 - Baroque";
				case EpoqueDeConstruction.From1800To1899:
					return "1800 à 1899 - Néoclassique, Haussmannien";
				case EpoqueDeConstruction.From1900To1947:
					return "1900 à 1947 - Moderne (Pré-rationalisme, Bauhaus, Style international)";
				case EpoqueDeConstruction.From1948To1979:
					return "1948 à 1979 - Post Moderne (Béton, charpentes métalliques, etc...)";
				case EpoqueDeConstruction.From1980To2000:
					return "1980 à 2000 - RT 1978 non contraignante";
				case EpoqueDeConstruction.From2001To2012:
					return "2001-2012 - RT 2000 et 2005 ou référence équivalente";
				case EpoqueDeConstruction.From2013To2021:
					return "2013-2021 - RT 2012 ou référence équivalente";
				case EpoqueDeConstruction.After2021:
					return "A partir de 2021 - RE2020 ou référence équivalente";
			}
		}
	}

	export enum TypeDePatrimoine {
		ClasseHistorique,
		InscritHistorique,
		SitueDansAbordsMonumentsHitoriques,
		SitueSitePatrimoineRemarquable,
		LabelArchitectureContemporaineRemarquable,
		ProtegeOuSoumisPrescriptionPLU,
		SitueDansSiteCodeEnvironnement,
		ConstructionCourante,
		ConstructionAtypique,
		ModeleIndustriel,
	}

	export namespace TypeDePatrimoine {
		export const ALL = [
			TypeDePatrimoine.ClasseHistorique,
			TypeDePatrimoine.InscritHistorique,
			TypeDePatrimoine.SitueDansAbordsMonumentsHitoriques,
			TypeDePatrimoine.SitueSitePatrimoineRemarquable,
			TypeDePatrimoine.LabelArchitectureContemporaineRemarquable,
			TypeDePatrimoine.ProtegeOuSoumisPrescriptionPLU,
			TypeDePatrimoine.SitueDansSiteCodeEnvironnement,
			TypeDePatrimoine.ConstructionCourante,
			TypeDePatrimoine.ConstructionAtypique,
			TypeDePatrimoine.ModeleIndustriel,
		];

		export function toLabel(typeDePatrimoine: TypeDePatrimoine): string {
			switch (typeDePatrimoine) {
				case TypeDePatrimoine.ClasseHistorique:
					return "Bâtiment classé au titre des monuments historiques";
				case TypeDePatrimoine.InscritHistorique:
					return "Bâtiment inscrit au titre des monuments historiques";
				case TypeDePatrimoine.SitueDansAbordsMonumentsHitoriques:
					return "Bâtiment situé dans les abords de monuments historiques (à moins de 500 m et en covisibilité, ou périmètre délimité des abords)";
				case TypeDePatrimoine.SitueSitePatrimoineRemarquable:
					return "Bâtiment situé en site patrimonial remarquable";
				case TypeDePatrimoine.LabelArchitectureContemporaineRemarquable:
					return "Bâtiment bénéficiant du label « Architecture contemporaine remarquable »";
				case TypeDePatrimoine.ProtegeOuSoumisPrescriptionPLU:
					return "Bâtiment protégé ou soumis à prescription par le règlement du PLU (articles L151-18 et L151-19 du code de l’urbanisme)";
				case TypeDePatrimoine.SitueDansSiteCodeEnvironnement:
					return "Bâtiment situé dans un site classé ou inscrit au titre du code de l’environnement";
				case TypeDePatrimoine.ConstructionCourante:
					return "Construction courante";
				case TypeDePatrimoine.ConstructionAtypique:
					return "Construction atypique";
				case TypeDePatrimoine.ModeleIndustriel:
					return "Modèle industriel";
			}
		}
	}

	export enum FormeEmpriseAusol {
		RectangulaireOuCarre,
		RectangulaireAvecDecroches,
		LOuU,
		FormeComplexe,
	}

	export namespace FormeEmpriseAusol {
		export const ALL = [
			FormeEmpriseAusol.RectangulaireOuCarre,
			FormeEmpriseAusol.RectangulaireAvecDecroches,
			FormeEmpriseAusol.LOuU,
			FormeEmpriseAusol.FormeComplexe,
		];

		export function toLabel(formeEmpriseAuSol: FormeEmpriseAusol): string {
			switch (formeEmpriseAuSol) {
				case FormeEmpriseAusol.RectangulaireOuCarre:
					return "Rectangulaire ou carré";
				case FormeEmpriseAusol.RectangulaireAvecDecroches:
					return "Rectangulaire avec décrochés";
				case FormeEmpriseAusol.LOuU:
					return "L ou U";
				case FormeEmpriseAusol.FormeComplexe:
					return "Forme complexe";
			}
		}
	}

	export enum HauteurMoyenneDesNiveaux {
		LowerThan250cm,
		From250To300cm,
		From300To400cm,
		MoreThan400cm,
	}

	export namespace HauteurMoyenneDesNiveaux {
		export const ALL = [
			HauteurMoyenneDesNiveaux.LowerThan250cm,
			HauteurMoyenneDesNiveaux.From250To300cm,
			HauteurMoyenneDesNiveaux.From300To400cm,
			HauteurMoyenneDesNiveaux.MoreThan400cm,
		];

		export function toLabel(hauteurMoyenne: HauteurMoyenneDesNiveaux): string {
			switch (hauteurMoyenne) {
				case HauteurMoyenneDesNiveaux.LowerThan250cm:
					return "Moins de 2,50 m";
				case HauteurMoyenneDesNiveaux.From250To300cm:
					return "Entre 2,50 et 3,00 m";
				case HauteurMoyenneDesNiveaux.From300To400cm:
					return "Entre 3,00 et 4,00 m";
				case HauteurMoyenneDesNiveaux.MoreThan400cm:
					return "Plus de 4,00 m";
			}
		}
	}

	export enum Mitoyennete {
		AbsenceDeParoi,
		LimiteeAUnPignon,
		LimiteeADeuxPignons,
	}

	export namespace Mitoyennete {
		export const ALL = [Mitoyennete.AbsenceDeParoi, Mitoyennete.LimiteeAUnPignon, Mitoyennete.LimiteeADeuxPignons];

		export function toLabel(mitoyennete: Mitoyennete): string {
			switch (mitoyennete) {
				case Mitoyennete.AbsenceDeParoi:
					return "Absence de paroi mitoyenne";
				case Mitoyennete.LimiteeAUnPignon:
					return "Mitoyenneté limitée à un pignon";
				case Mitoyennete.LimiteeADeuxPignons:
					return "Mitoyenneté limitée à deux pignons";
			}
		}
	}

	export enum Facade {
		MurPorteurMaconnePierre,
		MurPorteurMaconneBrique,
		BriquesPleinesAutoporteuses,
		MurBlocAlveoleBeton,
		PoteauBAEtRemplissageBriqueCreuse,
		PoteauBAEtRemplissageBriquePleine,
		PoteauBAEtRemplissageBlocAlveoleBeton,
		PoteauBAEtRemplissageMaconneAutre,
		MurVoilePorteurBetonArme,
		PanneauxBetonPrefabriques,
		StructureBoisPorteuseEtRemplissage,
		PanneauxOssaturesBois,
		PanneauxLegerSimplePeau,
		PanneauxLegerDoublePeau,
		FacadeRideauVitreeSimplePeau,
		FacadeRideauVitreeDoublePeau,
	}

	export namespace Facade {
		export const ALL = [
			Facade.MurPorteurMaconnePierre,
			Facade.MurPorteurMaconneBrique,
			Facade.BriquesPleinesAutoporteuses,
			Facade.MurBlocAlveoleBeton,
			Facade.PoteauBAEtRemplissageBriqueCreuse,
			Facade.PoteauBAEtRemplissageBriquePleine,
			Facade.PoteauBAEtRemplissageBlocAlveoleBeton,
			Facade.PoteauBAEtRemplissageMaconneAutre,
			Facade.MurVoilePorteurBetonArme,
			Facade.PanneauxBetonPrefabriques,
			Facade.StructureBoisPorteuseEtRemplissage,
			Facade.PanneauxOssaturesBois,
			Facade.PanneauxLegerSimplePeau,
			Facade.PanneauxLegerDoublePeau,
			Facade.FacadeRideauVitreeSimplePeau,
			Facade.FacadeRideauVitreeDoublePeau,
		];

		export function toLabel(facade: Facade): string {
			switch (facade) {
				case Facade.MurPorteurMaconnePierre:
					return "Mur porteur maçonné pierre";
				case Facade.MurPorteurMaconneBrique:
					return "Mur porteur maçonné brique";
				case Facade.BriquesPleinesAutoporteuses:
					return "Briques pleines autoporteuses (monomur)";
				case Facade.MurBlocAlveoleBeton:
					return "Mur bloc alvéolé béton";
				case Facade.PoteauBAEtRemplissageBriqueCreuse:
					return "Structure poteau BA et remplissage brique creuse";
				case Facade.PoteauBAEtRemplissageBriquePleine:
					return "Structure poteau BA et remplissage brique pleine";
				case Facade.PoteauBAEtRemplissageBlocAlveoleBeton:
					return "Structure poteau BA et remplissage bloc alvéolé béton";
				case Facade.PoteauBAEtRemplissageMaconneAutre:
					return "Structure poteau BA et remplissage maçonné autre";
				case Facade.MurVoilePorteurBetonArme:
					return "Mur voile porteur Béton Armé";
				case Facade.PanneauxBetonPrefabriques:
					return "Panneaux béton préfabriqués";
				case Facade.StructureBoisPorteuseEtRemplissage:
					return "Structure bois porteuse et remplissage (Colombage)";
				case Facade.PanneauxOssaturesBois:
					return "Panneaux ossatures bois";
				case Facade.PanneauxLegerSimplePeau:
					return "Panneaux léger simple peau (structure métallique, BA, bois lamellé collé ou lamellé croisé)";
				case Facade.PanneauxLegerDoublePeau:
					return "Panneaux léger double peau (structure métallique, BA, bois lamellé collé ou lamellé croisé)";
				case Facade.FacadeRideauVitreeSimplePeau:
					return "Façade rideau vitrée simple peau";
				case Facade.FacadeRideauVitreeDoublePeau:
					return "Façade rideau vitrée double peau";
			}
		}
	}

	export enum ContraintesSurFacade {
		FacadeClasse,
		DecorAValeurPatrimoniale,
	}

	export namespace ContraintesSurFacade {
		export const ALL = [ContraintesSurFacade.FacadeClasse, ContraintesSurFacade.DecorAValeurPatrimoniale];

		export function toLabel(contraintesSurFacade: ContraintesSurFacade): string {
			switch (contraintesSurFacade) {
				case ContraintesSurFacade.FacadeClasse:
					return "Façade classée";
				case ContraintesSurFacade.DecorAValeurPatrimoniale:
					return "Décor à valeur patrimoniale (modénature)";
			}
		}
	}

	export enum IsolationThermique {
		Absence,
		Partielle,
		Complete,
	}

	export namespace IsolationThermique {
		export const ALL = [IsolationThermique.Absence, IsolationThermique.Partielle, IsolationThermique.Complete];

		export function toLabel(isolationThermique: IsolationThermique): string {
			switch (isolationThermique) {
				case IsolationThermique.Absence:
					return "Absence d’isolation";
				case IsolationThermique.Partielle:
					return "Isolation partielle";
				case IsolationThermique.Complete:
					return "Isolation complète";
			}
		}
	}

	export enum IsolationThermiquePrincipal {
		ContreCloisonAvecVide,
		IsolationParLInterieurInferieureA5cm,
		IsolationParLInterieurSuperieureA6cm,
		IsolationParLExterieur,
		IsolationIntegreeDansLaParoi,
	}

	export namespace IsolationThermiquePrincipal {
		export const ALL = [
			IsolationThermiquePrincipal.ContreCloisonAvecVide,
			IsolationThermiquePrincipal.IsolationParLInterieurInferieureA5cm,
			IsolationThermiquePrincipal.IsolationParLInterieurSuperieureA6cm,
			IsolationThermiquePrincipal.IsolationParLExterieur,
			IsolationThermiquePrincipal.IsolationIntegreeDansLaParoi,
		];

		export function toLabel(isolation: IsolationThermiquePrincipal): string {
			switch (isolation) {
				case IsolationThermiquePrincipal.ContreCloisonAvecVide:
					return "Contre cloison avec vide";
				case IsolationThermiquePrincipal.IsolationParLInterieurInferieureA5cm:
					return "Isolation par l’intérieur – épaisseur inférieure ou égale à 5 cm";
				case IsolationThermiquePrincipal.IsolationParLInterieurSuperieureA6cm:
					return "Isolation par l’intérieur – épaisseur supérieure à 6 cm";
				case IsolationThermiquePrincipal.IsolationParLExterieur:
					return "Isolation par l’extérieure";
				case IsolationThermiquePrincipal.IsolationIntegreeDansLaParoi:
					return "Isolation intégrée dans la paroi";
			}
		}
	}

	export enum IsolationThermiqueDesMenuiseries {
		SimpleVitrage,
		DoubleVitrageAvant1995,
		DoubleVitrageApres1995,
		TripleVitrage,
	}

	export namespace IsolationThermiqueDesMenuiseries {
		export const ALL = [
			IsolationThermiqueDesMenuiseries.SimpleVitrage,
			IsolationThermiqueDesMenuiseries.DoubleVitrageAvant1995,
			IsolationThermiqueDesMenuiseries.DoubleVitrageApres1995,
			IsolationThermiqueDesMenuiseries.TripleVitrage,
		];

		export function toLabel(isolation: IsolationThermiqueDesMenuiseries): string {
			switch (isolation) {
				case IsolationThermiqueDesMenuiseries.SimpleVitrage:
					return "Simple vitrage";
				case IsolationThermiqueDesMenuiseries.DoubleVitrageAvant1995:
					return "Double vitrage avant 1995";
				case IsolationThermiqueDesMenuiseries.DoubleVitrageApres1995:
					return "Double vitrage après 1995";
				case IsolationThermiqueDesMenuiseries.TripleVitrage:
					return "Triple vitrage";
			}
		}
	}

	export enum Toiture {
		LocalNonContiguALaToiture,
		PlancherSousComblesPerdus,
		ToitureTerrasseSurPlancherHautLourd,
		ToitureBacAcier,
		ToitureFaiblePenteMoins30Degres,
		ToitureFaiblePente45DegresOuProche,
	}

	export namespace Toiture {
		export const ALL = [
			Toiture.LocalNonContiguALaToiture,
			Toiture.PlancherSousComblesPerdus,
			Toiture.ToitureTerrasseSurPlancherHautLourd,
			Toiture.ToitureBacAcier,
			Toiture.ToitureFaiblePenteMoins30Degres,
			Toiture.ToitureFaiblePente45DegresOuProche,
		];

		export function toLabel(toiture: Toiture): string {
			switch (toiture) {
				case Toiture.LocalNonContiguALaToiture:
					return "Local non contigu à la toiture (dernier niveau non tertiaire)";
				case Toiture.PlancherSousComblesPerdus:
					return "Plancher sous combles perdus";
				case Toiture.ToitureTerrasseSurPlancherHautLourd:
					return "Toiture terrasse sur plancher haut lourd";
				case Toiture.ToitureBacAcier:
					return "Toiture bac acier – éléments métalliques";
				case Toiture.ToitureFaiblePenteMoins30Degres:
					return "Toiture faible pente < 30 °";
				case Toiture.ToitureFaiblePente45DegresOuProche:
					return "Toiture faible pente 45 ° ou proche";
			}
		}
	}

	export enum IsolationThermique2 {
		Absence,
		Moins5cm,
		Entre5et10cm,
		Entre10et20cm,
		SuperieurA20cm,
	}

	export namespace IsolationThermique2 {
		export const ALL = [
			IsolationThermique2.Absence,
			IsolationThermique2.Moins5cm,
			IsolationThermique2.Entre5et10cm,
			IsolationThermique2.Entre10et20cm,
			IsolationThermique2.SuperieurA20cm,
		];

		export function toLabel(isolation: IsolationThermique2): string {
			switch (isolation) {
				case IsolationThermique2.Absence:
					return "Absence d’isolation";
				case IsolationThermique2.Moins5cm:
					return "Isolation – épaisseur < 5 cm";
				case IsolationThermique2.Entre5et10cm:
					return "Isolation – épaisseur comprise entre 5 et 10 cm";
				case IsolationThermique2.Entre10et20cm:
					return "Isolation – épaisseur comprise entre 10 et 20 cm";
				case IsolationThermique2.SuperieurA20cm:
					return "Isolation – épaisseur supérieure à 20 cm";
			}
		}
	}

	export enum PlancherBas {
		LocalNonContiguAuPlancherBas,
		DalleSurTerrePlein,
		VideSanitaire,
		SousSol,
	}

	export namespace PlancherBas {
		export const ALL = [
			PlancherBas.LocalNonContiguAuPlancherBas,
			PlancherBas.DalleSurTerrePlein,
			PlancherBas.VideSanitaire,
			PlancherBas.SousSol,
		];

		export function toLabel(plancher: PlancherBas) {
			switch (plancher) {
				case PlancherBas.LocalNonContiguAuPlancherBas:
					return "Local non contigu au plancher bas (niveau inférieur non tertiaire)";
				case PlancherBas.DalleSurTerrePlein:
					return "Dalle sur terre-plein";
				case PlancherBas.VideSanitaire:
					return "Vide sanitaire";
				case PlancherBas.SousSol:
					return "Sous-Sol";
			}
		}
	}
}

export namespace Table6 {
	export interface Data {
		generateurPrincipalDeChauffage?: GenerateurPrincipalDeChauffage;
		typePrincipalDEmetteurDeChauffage?: TypePrincipalDEmetteurDeChauffage | { autre: string };
		generateurPrincipalDeRafraichissement?: GenerateurPrincipalDeRafraichissement;
		typePrincipalDeVentilation?: TypePrincipalDeVentilation | { autre: string };
		typeDeProductionDEauChaudeSanitaire?: TypeDeProductionDEauChaudeSanitaire;
		eclairageNaturelDesLocaux?: EclairageNaturelDesLocaux | EclairageZenithal;
		eclairageNaturelDesCirculations?: EclairageNaturelDesCirculations | EclairageZenithal;
		eclairageArtificielDesLocaux?: EclairageArtificielDesLocaux;
		autresEquipementsImmobiliers: (
			| {
					type: AutresEquipementsImmobiliers.Ascenceurs;
					nombreDeNiveauDesservis: number;
					nombre: number;
					capaciteIndividuelle: number;
			  }
			| {
					type: AutresEquipementsImmobiliers.EscalierMecanique | AutresEquipementsImmobiliers.TrottoirMecanique;
					fonctionnementContinu: number;
					asserviALaDetection: number;
			  }
		)[];
		systemeDAutomatisationEtDeControleDesSystemesTechniques?: SystemeDAutomatisationEtDeControlDesSystemesTechniques;
	}

	export enum GenerateurPrincipalDeChauffage {
		AbsenceDeChauffageDuLocal,
		GazIndividuelle,
		GazCollective,
		FioulDomestique,
		BiomasseIndividuelle,
		BiomasseCollective,
		PacCollective,
		PacIndividuelle,
		PacIndividuelleClimatiseurReversible,
		ReseauDeChaleur,
		Cogeneration,
	}

	export namespace GenerateurPrincipalDeChauffage {
		export const ALL = [
			GenerateurPrincipalDeChauffage.AbsenceDeChauffageDuLocal,
			GenerateurPrincipalDeChauffage.GazIndividuelle,
			GenerateurPrincipalDeChauffage.GazCollective,
			GenerateurPrincipalDeChauffage.FioulDomestique,
			GenerateurPrincipalDeChauffage.BiomasseIndividuelle,
			GenerateurPrincipalDeChauffage.BiomasseCollective,
			GenerateurPrincipalDeChauffage.PacCollective,
			GenerateurPrincipalDeChauffage.PacIndividuelle,
			GenerateurPrincipalDeChauffage.PacIndividuelleClimatiseurReversible,
			GenerateurPrincipalDeChauffage.ReseauDeChaleur,
			GenerateurPrincipalDeChauffage.Cogeneration,
		];

		export function toLabel(generateur: GenerateurPrincipalDeChauffage): string {
			switch (generateur) {
				case GenerateurPrincipalDeChauffage.AbsenceDeChauffageDuLocal:
					return "Absence de chauffage du local";
				case GenerateurPrincipalDeChauffage.GazIndividuelle:
					return "Chaudière Gaz individuelle";
				case GenerateurPrincipalDeChauffage.GazCollective:
					return "Chaudière Gaz collective";
				case GenerateurPrincipalDeChauffage.FioulDomestique:
					return "Chaudière fioul domestique";
				case GenerateurPrincipalDeChauffage.BiomasseIndividuelle:
					return "Chaudière Biomasse individuelle";
				case GenerateurPrincipalDeChauffage.BiomasseCollective:
					return "Chaudière Biomasse collective";
				case GenerateurPrincipalDeChauffage.PacCollective:
					return "PAC collective";
				case GenerateurPrincipalDeChauffage.PacIndividuelle:
					return "PAC individuelle";
				case GenerateurPrincipalDeChauffage.PacIndividuelleClimatiseurReversible:
					return "PAC individuelle-Climatiseur réversible";
				case GenerateurPrincipalDeChauffage.ReseauDeChaleur:
					return "Réseau de chaleur (sous-station)";
				case GenerateurPrincipalDeChauffage.Cogeneration:
					return "Cogénération";
			}
		}
	}

	export enum TypePrincipalDEmetteurDeChauffage {
		AbsenceDEmetteur,
		RadiateurAEau,
		ConvecteurCassette,
		RadiateurAPanneauxRayonnants,
		RadiateurAEnergieMaitriseeSec,
		RadiateurAEnergieMaitriseeFluide,
		PlancherChauffantElectrique,
		PlafondRayonnantElectrique,
		PlancherChauffantBoucleDEauChaude,
		CentraleDeTraitementDeLAir,
		MurChauffantHydraulique,
		VitrageChauffant,
	}

	export namespace TypePrincipalDEmetteurDeChauffage {
		export const ALL = [
			TypePrincipalDEmetteurDeChauffage.AbsenceDEmetteur,
			TypePrincipalDEmetteurDeChauffage.RadiateurAEau,
			TypePrincipalDEmetteurDeChauffage.ConvecteurCassette,
			TypePrincipalDEmetteurDeChauffage.RadiateurAPanneauxRayonnants,
			TypePrincipalDEmetteurDeChauffage.RadiateurAEnergieMaitriseeSec,
			TypePrincipalDEmetteurDeChauffage.RadiateurAEnergieMaitriseeFluide,
			TypePrincipalDEmetteurDeChauffage.PlancherChauffantElectrique,
			TypePrincipalDEmetteurDeChauffage.PlafondRayonnantElectrique,
			TypePrincipalDEmetteurDeChauffage.PlancherChauffantBoucleDEauChaude,
			TypePrincipalDEmetteurDeChauffage.CentraleDeTraitementDeLAir,
			TypePrincipalDEmetteurDeChauffage.MurChauffantHydraulique,
			TypePrincipalDEmetteurDeChauffage.VitrageChauffant,
		];

		export function toLabel(type: TypePrincipalDEmetteurDeChauffage): string {
			switch (type) {
				case TypePrincipalDEmetteurDeChauffage.AbsenceDEmetteur:
					return "Absence d’émetteur";
				case TypePrincipalDEmetteurDeChauffage.RadiateurAEau:
					return "Radiateur à eau";
				case TypePrincipalDEmetteurDeChauffage.ConvecteurCassette:
					return "Convecteur-cassette";
				case TypePrincipalDEmetteurDeChauffage.RadiateurAPanneauxRayonnants:
					return "Radiateur à panneaux rayonnants";
				case TypePrincipalDEmetteurDeChauffage.RadiateurAEnergieMaitriseeSec:
					return "Radiateur à énergie maîtrisée sec (céramique –fonte)";
				case TypePrincipalDEmetteurDeChauffage.RadiateurAEnergieMaitriseeFluide:
					return "Radiateur à énergie maîtrisée fluide (liquide caloporteur)";
				case TypePrincipalDEmetteurDeChauffage.PlancherChauffantElectrique:
					return "Plancher chauffant électrique";
				case TypePrincipalDEmetteurDeChauffage.PlafondRayonnantElectrique:
					return "Plafond rayonnant électrique";
				case TypePrincipalDEmetteurDeChauffage.PlancherChauffantBoucleDEauChaude:
					return "Plancher chauffant boucle d’eau chaude";
				case TypePrincipalDEmetteurDeChauffage.CentraleDeTraitementDeLAir:
					return "Centrale de traitement de l’air";
				case TypePrincipalDEmetteurDeChauffage.MurChauffantHydraulique:
					return "Mur chauffant hydraulique";
				case TypePrincipalDEmetteurDeChauffage.VitrageChauffant:
					return "Vitrage chauffant";
			}
		}
	}

	export enum GenerateurPrincipalDeRafraichissement {
		AbsenceDeRafraichissement,
		Climatiseur,
		PacReversibleSurAir,
		GroupeEauGlacee,
		TourAerorefrigerante,
		FreeCooling,
		GeoCooling,
		ChaudiereBiomasseCollective,
		ReseauDeFroid,
		BrassageDeLAir,
	}

	export namespace GenerateurPrincipalDeRafraichissement {
		export const ALL = [
			GenerateurPrincipalDeRafraichissement.AbsenceDeRafraichissement,
			GenerateurPrincipalDeRafraichissement.Climatiseur,
			GenerateurPrincipalDeRafraichissement.PacReversibleSurAir,
			GenerateurPrincipalDeRafraichissement.GroupeEauGlacee,
			GenerateurPrincipalDeRafraichissement.TourAerorefrigerante,
			GenerateurPrincipalDeRafraichissement.FreeCooling,
			GenerateurPrincipalDeRafraichissement.GeoCooling,
			GenerateurPrincipalDeRafraichissement.ChaudiereBiomasseCollective,
			GenerateurPrincipalDeRafraichissement.ReseauDeFroid,
			GenerateurPrincipalDeRafraichissement.BrassageDeLAir,
		];

		export function toLabel(generateur: GenerateurPrincipalDeRafraichissement): string {
			switch (generateur) {
				case GenerateurPrincipalDeRafraichissement.AbsenceDeRafraichissement:
					return "Absence de rafraichissement";
				case GenerateurPrincipalDeRafraichissement.Climatiseur:
					return "Climatiseur";
				case GenerateurPrincipalDeRafraichissement.PacReversibleSurAir:
					return "PAC réversible sur air";
				case GenerateurPrincipalDeRafraichissement.GroupeEauGlacee:
					return "Groupe eau glacée";
				case GenerateurPrincipalDeRafraichissement.TourAerorefrigerante:
					return "Tour aéroréfrigérante";
				case GenerateurPrincipalDeRafraichissement.FreeCooling:
					return "Free Cooling (Surventilation nocturne)";
				case GenerateurPrincipalDeRafraichissement.GeoCooling:
					return "Geo Cooling – Puits Canadiens";
				case GenerateurPrincipalDeRafraichissement.ChaudiereBiomasseCollective:
					return "Chaudière Biomasse collective";
				case GenerateurPrincipalDeRafraichissement.ReseauDeFroid:
					return "Réseau de froid (sous-station)";
				case GenerateurPrincipalDeRafraichissement.BrassageDeLAir:
					return "Brassage de l’air";
			}
		}
	}

	export enum TypePrincipalDeVentilation {
		Absence,
		NaturelleParConduitsDAeration,
		MecaniqueSimpleFluxDansLesPiecesHumides,
		MecaniqueDoubleFlux,
		CentraleDeTraitementDeLAir,
	}

	export namespace TypePrincipalDeVentilation {
		export const ALL = [
			TypePrincipalDeVentilation.Absence,
			TypePrincipalDeVentilation.NaturelleParConduitsDAeration,
			TypePrincipalDeVentilation.MecaniqueSimpleFluxDansLesPiecesHumides,
			TypePrincipalDeVentilation.MecaniqueDoubleFlux,
			TypePrincipalDeVentilation.CentraleDeTraitementDeLAir,
		];

		export function toLabel(type: TypePrincipalDeVentilation): string {
			switch (type) {
				case TypePrincipalDeVentilation.Absence:
					return "Absence de dispositif de ventilation";
				case TypePrincipalDeVentilation.NaturelleParConduitsDAeration:
					return "Ventilation naturelle par conduits d’aération";
				case TypePrincipalDeVentilation.MecaniqueSimpleFluxDansLesPiecesHumides:
					return "Ventilation mécanique simple flux dans les pièces humides";
				case TypePrincipalDeVentilation.MecaniqueDoubleFlux:
					return "Ventilation mécanique double flux";
				case TypePrincipalDeVentilation.CentraleDeTraitementDeLAir:
					return "Centrale de traitement de l’air";
			}
		}
	}

	export enum TypeDeProductionDEauChaudeSanitaire {
		Absence,
		ChaudiereGazIndividuelle,
		ChaudiereGazCollective,
		ChaudiereFioulDomestique,
		ChaudiereBiomasseIndividuelle,
		ChaudiereBiomasseCollective,
		BallonElectrique,
		ChauffeEauThermodynamique,
		PacCollective,
		SolaireThermique,
		ReseauDeChaleur,
		Cogeneration,
	}

	export namespace TypeDeProductionDEauChaudeSanitaire {
		export const ALL = [
			TypeDeProductionDEauChaudeSanitaire.Absence,
			TypeDeProductionDEauChaudeSanitaire.ChaudiereGazIndividuelle,
			TypeDeProductionDEauChaudeSanitaire.ChaudiereGazCollective,
			TypeDeProductionDEauChaudeSanitaire.ChaudiereFioulDomestique,
			TypeDeProductionDEauChaudeSanitaire.ChaudiereBiomasseIndividuelle,
			TypeDeProductionDEauChaudeSanitaire.ChaudiereBiomasseCollective,
			TypeDeProductionDEauChaudeSanitaire.BallonElectrique,
			TypeDeProductionDEauChaudeSanitaire.ChauffeEauThermodynamique,
			TypeDeProductionDEauChaudeSanitaire.PacCollective,
			TypeDeProductionDEauChaudeSanitaire.SolaireThermique,
			TypeDeProductionDEauChaudeSanitaire.ReseauDeChaleur,
			TypeDeProductionDEauChaudeSanitaire.Cogeneration,
		];

		export function toLabel(type: TypeDeProductionDEauChaudeSanitaire): string {
			switch (type) {
				case TypeDeProductionDEauChaudeSanitaire.Absence:
					return "Absence de production d’eau chaude sanitaire";
				case TypeDeProductionDEauChaudeSanitaire.ChaudiereGazIndividuelle:
					return "Chaudière Gaz individuelle";
				case TypeDeProductionDEauChaudeSanitaire.ChaudiereGazCollective:
					return "Chaudière Gaz collective";
				case TypeDeProductionDEauChaudeSanitaire.ChaudiereFioulDomestique:
					return "Chaudière fioul domestique";
				case TypeDeProductionDEauChaudeSanitaire.ChaudiereBiomasseIndividuelle:
					return "Chaudière Biomasse individuelle";
				case TypeDeProductionDEauChaudeSanitaire.ChaudiereBiomasseCollective:
					return "Chaudière Biomasse collective";
				case TypeDeProductionDEauChaudeSanitaire.BallonElectrique:
					return "Ballon électrique";
				case TypeDeProductionDEauChaudeSanitaire.ChauffeEauThermodynamique:
					return "Chauffe-eau thermodynamique";
				case TypeDeProductionDEauChaudeSanitaire.PacCollective:
					return "PAC collective";
				case TypeDeProductionDEauChaudeSanitaire.SolaireThermique:
					return "Solaire thermique";
				case TypeDeProductionDEauChaudeSanitaire.ReseauDeChaleur:
					return "Réseau de chaleur (sous-station)";
				case TypeDeProductionDEauChaudeSanitaire.Cogeneration:
					return "Cogénération";
			}
		}
	}

	export enum EclairageZenithal {
		Verriere,
		Lanterneau,
		ShedOrienteNord,
	}

	export namespace EclairageZenithal {
		export const ALL = [EclairageZenithal.Verriere, EclairageZenithal.Lanterneau, EclairageZenithal.ShedOrienteNord];

		export function toLabel(eclairage: EclairageZenithal): string {
			switch (eclairage) {
				case EclairageZenithal.Verriere:
					return "Verrière";
				case EclairageZenithal.Lanterneau:
					return "Lanterneau";
				case EclairageZenithal.ShedOrienteNord:
					return "Shed orienté Nord";
			}
		}
	}

	export enum EclairageNaturelDesLocaux {
		AbsenceDEclairageNaturel,
	}

	export namespace EclairageNaturelDesLocaux {
		export const ALL = [EclairageNaturelDesLocaux.AbsenceDEclairageNaturel];

		export function toLabel(eclairage: EclairageNaturelDesLocaux): string {
			switch (eclairage) {
				case EclairageNaturelDesLocaux.AbsenceDEclairageNaturel:
					return "Absence d'éclairage naturel";
			}
		}
	}

	export enum EclairageNaturelDesCirculations {
		NonConcerne,
		Absence,
		PresenceDeSecondJour,
	}

	export namespace EclairageNaturelDesCirculations {
		export const ALL = [
			EclairageNaturelDesCirculations.NonConcerne,
			EclairageNaturelDesCirculations.Absence,
			EclairageNaturelDesCirculations.PresenceDeSecondJour,
		];

		export function toLabel(eclairage: EclairageNaturelDesCirculations): string {
			switch (eclairage) {
				case EclairageNaturelDesCirculations.NonConcerne:
					return "Non concerné";
				case EclairageNaturelDesCirculations.Absence:
					return "Absence d’éclairage naturel des circulations";
				case EclairageNaturelDesCirculations.PresenceDeSecondJour:
					return "Présence de second jour";
			}
		}
	}

	export enum EclairageArtificielDesLocaux {
		Lampes,
		Tubes,
		LED,
		OLED,
	}

	export namespace EclairageArtificielDesLocaux {
		export const ALL = [
			EclairageArtificielDesLocaux.Lampes,
			EclairageArtificielDesLocaux.Tubes,
			EclairageArtificielDesLocaux.LED,
			EclairageArtificielDesLocaux.OLED,
		];

		export function toLabel(eclairage: EclairageArtificielDesLocaux): string {
			switch (eclairage) {
				case EclairageArtificielDesLocaux.Lampes:
					return "Lampes";
				case EclairageArtificielDesLocaux.Tubes:
					return "Tubes";
				case EclairageArtificielDesLocaux.LED:
					return "LED";
				case EclairageArtificielDesLocaux.OLED:
					return "OLED";
			}
		}
	}

	export enum SystemeDAutomatisationEtDeControlDesSystemesTechniques {
		A,
		B,
		C,
		D,
	}

	export namespace SystemeDAutomatisationEtDeControlDesSystemesTechniques {
		export const ALL = [
			SystemeDAutomatisationEtDeControlDesSystemesTechniques.A,
			SystemeDAutomatisationEtDeControlDesSystemesTechniques.B,
			SystemeDAutomatisationEtDeControlDesSystemesTechniques.C,
			SystemeDAutomatisationEtDeControlDesSystemesTechniques.D,
		];

		export function toLabel(systeme: SystemeDAutomatisationEtDeControlDesSystemesTechniques): string {
			switch (systeme) {
				case SystemeDAutomatisationEtDeControlDesSystemesTechniques.A:
					return "Niveau de régulation A (très évolué)";
				case SystemeDAutomatisationEtDeControlDesSystemesTechniques.B:
					return "Niveau de régulation B (évolué)";
				case SystemeDAutomatisationEtDeControlDesSystemesTechniques.C:
					return "Niveau de régulation C (standard)";
				case SystemeDAutomatisationEtDeControlDesSystemesTechniques.D:
					return "Niveau de régulation D (absence de régulation)";
			}
		}
	}

	export enum AutresEquipementsImmobiliers {
		Ascenceurs,
		EscalierMecanique,
		TrottoirMecanique,
	}

	export namespace AutresEquipementsImmobiliers {
		export const ALL = [
			AutresEquipementsImmobiliers.Ascenceurs,
			AutresEquipementsImmobiliers.EscalierMecanique,
			AutresEquipementsImmobiliers.TrottoirMecanique,
		];

		export function getChoices() {
			return [
				{
					type: AutresEquipementsImmobiliers.Ascenceurs,
					nombreDeNiveauDesservis: 1,
					nombre: 1,
					capaciteIndividuelle: 1,
				},
				{
					type: AutresEquipementsImmobiliers.TrottoirMecanique,
					fonctionnementContinu: 0,
					asserviALaDetection: 0,
				},
				{
					type: AutresEquipementsImmobiliers.EscalierMecanique,
					fonctionnementContinu: 0,
					asserviALaDetection: 0,
				},
			];
		}

		export function toLabel(autresEquipementsImmobiliers: AutresEquipementsImmobiliers) {
			switch (autresEquipementsImmobiliers) {
				case AutresEquipementsImmobiliers.Ascenceurs:
					return "Ascenseurs";
				case AutresEquipementsImmobiliers.EscalierMecanique:
					return "Escalier mécanique";
				case AutresEquipementsImmobiliers.TrottoirMecanique:
					return "Trottoir mécanique";
			}
		}
	}
}
