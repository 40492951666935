import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId, RepresentativeId } from "../models/ids";
import { ResourceInfo, ResourceType } from "../models/resource";
import { DeclarationGroupIsolationService } from "./declaration-group-isolation.service";
import { DeclarationGroupStateService } from "./declaration-group-state.service";
import { DeclarationIsolationService } from "./declaration-isolation.service";
import { DeclarationStateService } from "./declaration-state.service";
import { FunctionalDeclarationIsolationService } from "./functional-declaration-isolation.service";
import { FunctionalDeclarationStateService } from "./functional-declaration-state.service";
import { IsolationStateService } from "./isolation-state.service";
import { RepresentativeIsolationService } from "./representative-isolation.service";
import { RepresentativeStateService } from "./representative-state.service";

@Injectable({
	providedIn: "root",
})
export class InitStateService {
	constructor(
		private functionalIsolation: FunctionalDeclarationIsolationService,
		private functionalState: FunctionalDeclarationStateService,
		private declarationIsolation: DeclarationIsolationService,
		private declarationState: DeclarationStateService,
		private groupIsolation: DeclarationGroupIsolationService,
		private groupState: DeclarationGroupStateService,
		private representativeIsolation: RepresentativeIsolationService,
		private representativeState: RepresentativeStateService,
		private isolationState: IsolationStateService,
	) {}

	initFunctionalDeclaration$(id: FunctionalDeclarationId): Observable<unknown> {
		this.representativeState.clear();
		this.groupState.clear();
		this.declarationState.clear();
		this.functionalState.setOwner(this.functionalIsolation);
		return this.functionalState.select$(id).pipe(
			tap(() => {
				const info: ResourceInfo = { type: ResourceType.FunctionalDeclaration, id };
				IsolationStateService.setIsolationToStorage(info);
				this.isolationState.setIsolation(info);
			}),
		);
	}

	initDeclaration$(id: DeclarationId): Observable<unknown> {
		this.representativeState.clear();
		this.groupState.clear();
		this.declarationState.setOwner(this.declarationIsolation);
		return this.declarationState.select$(id).pipe(
			tap(() => {
				const info: ResourceInfo = { type: ResourceType.Declaration, id };
				IsolationStateService.setIsolationToStorage(info);
				this.isolationState.setIsolation(info);
			}),
		);
	}

	initGroup$(id: DeclarationGroupId): Observable<unknown> {
		this.representativeState.clear();
		this.groupState.setOwner(this.groupIsolation);
		return this.groupState.select$(id).pipe(
			tap(() => {
				const info: ResourceInfo = { type: ResourceType.DeclarationGroup, id };
				IsolationStateService.setIsolationToStorage(info);
				this.isolationState.setIsolation(info);
			}),
		);
	}

	initRepresentative$(id: RepresentativeId): Observable<unknown> {
		this.representativeState.setOwner(this.representativeIsolation);
		return this.representativeState.select$(id).pipe(
			tap(() => {
				const info: ResourceInfo = { type: ResourceType.Representative, id };
				IsolationStateService.setIsolationToStorage(info);
				this.isolationState.setIsolation(info);
			}),
		);
	}
}
