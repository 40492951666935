import * as am5 from "@amcharts/amcharts5";
import { color } from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5plugins_sliceGrouper from "@amcharts/amcharts5/plugins/sliceGrouper";
import { AfterViewInit, Component, Input } from "@angular/core";
import { getCategories } from "../../../../../../../assets/data/categories";

@Component({
	selector: "app-stat-chart-per-category",
	templateUrl: "./stat-chart-per-category.component.html",
	styleUrls: ["./stat-chart-per-category.component.scss"],
})
export class StatChartPerCategoryComponent implements AfterViewInit {
	@Input() mainActivityCount!: { [categoryCode: string]: number };

	ngAfterViewInit() {
		const root = am5.Root.new("perCategory-chart");

		const chart = root.container.children.push(
			am5percent.PieChart.new(root, {
				endAngle: 270,
			}),
		);
		const series = chart.series.push(
			am5percent.PieSeries.new(root, {
				valueField: "value",
				categoryField: "category",
				endAngle: 270,
			}),
		);

		series.slices.template.adapters.add("fill", (value, target) => {
			const context = target.dataItem?.dataContext;
			if (context && typeof context === "object" && "category" in context && context.category === "Autre") {
				return color(0x808080);
			}
			return value;
		});

		series.labels.template.setAll({
			maxWidth: 150,
			oversizedBehavior: "wrap", // to truncate labels, use "truncate"
		});

		am5plugins_sliceGrouper.SliceGrouper.new(root, {
			series: series,
			threshold: 2,
			limit: 5,
			groupName: "Autre",
			clickBehavior: "zoom",
		});

		series.states.create("hidden", {
			endAngle: -90,
		});

		series.data.setAll(
			Object.keys(this.mainActivityCount)
				.map((categoryCode) => {
					return {
						category: getCategories().find((c) => c.code === categoryCode)?.libelle,
						value: this.mainActivityCount[categoryCode],
					};
				})
				.sort((cat1, cat2) => cat2.value - cat1.value),
		);

		series.appear();
	}
}
