<div class="stats-container">
	<h2 class="primary-light mb-0">
		Statistiques<ng-container *ngIf="name"> : {{ name }}</ng-container>
	</h2>
	<ng-container *ngIf="!stats">
		Chargement des statistiques ... <br />
		Le chargement de cette page peut prendre du temps selon le nombre de déclarations concernées.
	</ng-container>
	<ng-container *ngIf="stats">
		<div class="d-flex" style="gap: 10px">
			<div class="d-flex-col w-0" style="gap: 10px; flex: 1 1 0px">
				<div class="d-flex" style="gap: 10px">
					<div class="w-100 stat-card primary" style="flex-direction: row">
						<div class="w-50">
							<h2 class="counter m-0">
								<span [countUp]="stats.assujettiSurface" [options]="{ decimal: ',', separator: ' ' }">0</span>m²
							</h2>
							<h3 class="m-0">renseignés*</h3>
							<h4 class="m-0">
								<span [countUp]="stats.assujettiSurface / stats.siteCount" [options]="{ decimal: ',', separator: ' ' }"
									>0</span
								>m²/EFA en moyenne
							</h4>
						</div>
						<ng-select
							[items]="possibleYears"
							[(ngModel)]="currentYear"
							(change)="loadSurfaceAndEnergySum()"
							[clearable]="false"
						>
						</ng-select>
						<div class="w-50" *ngIf="stats.energySum | numberUnitObject as energySumFormat">
							<h2 class="counter m-0">
								<span [countUp]="energySumFormat.value" [options]="{ decimal: ',', separator: ' ', decimalPlaces: 1 }"
									>0</span
								>{{ energySumFormat.symbol }}Wh
							</h2>
							<h3 class="m-0">Consommés en {{ currentYear }}**</h3>
							<h4 *ngIf="stats.energySum / stats.siteCount | numberUnitObject as energySumAvgFormat" class="m-0">
								<span
									[countUp]="energySumAvgFormat.value"
									[options]="{ decimal: ',', separator: ' ', decimalPlaces: 1 }"
									>0</span
								>{{ energySumAvgFormat.symbol }}Wh/EFA en moyenne
							</h4>
						</div>
					</div>
				</div>
				<div class="d-flex" style="gap: 10px">
					<div class="w-50 stat-card primary">
						<h2 class="counter m-0">
							<span [countUp]="stats.siteCount" [options]="{ decimal: ',', separator: ' ' }">0</span>
						</h2>
						<h3 class="m-0">Entités Fonctionnelles Assujetties (EFA)</h3>
					</div>
					<div class="w-50 stat-card primary-light">
						<h2 class="counter m-0">
							<span [countUp]="stats.irveCount" [options]="{ decimal: ',', separator: ' ' }">0</span>
						</h2>
						<h3 class="m-0">borne{{ stats.irveCount > 1 ? "s" : "" }} IRVE*</h3>
					</div>
				</div>
				<div class="d-flex" style="gap: 10px">
					<div class="w-50 stat-card accent" *ngFor="let count of [stats.agreementReceivedCount.PRM]">
						<h2 class="counter m-0">
							<mat-icon>bolt</mat-icon>
							<span [countUp]="count" [options]="{ decimal: ',', separator: ' ' }">0</span>
						</h2>
						<h3 class="m-0">compteur{{ count > 1 ? "s" : "" }} Enedis</h3>
						<h4 class="m-0">avec collecte automatisée</h4>
					</div>
					<div class="w-50 stat-card accent2" *ngFor="let count of [stats.agreementReceivedCount.PCE]">
						<h2 class="counter m-0">
							<mat-icon>local_fire_department</mat-icon>
							<span [countUp]="count" [options]="{ decimal: ',', separator: ' ' }">0</span>
						</h2>
						<h3 class="m-0">compteur{{ count > 1 ? "s" : "" }} GRDF</h3>
						<h4 class="m-0">avec collecte automatisée</h4>
					</div>
				</div>
				<div class="stat-card h-100">
					<h3>Répartition géographique</h3>
					<div class="w-100" style="height: 510px">
						<app-stat-chart-map [perCounty]="stats.perCounty" [points]="stats.points"></app-stat-chart-map>
					</div>
				</div>
			</div>

			<div class="d-flex-col w-0" style="gap: 10px; flex: 1 1 0px">
				<div class="stat-card">
					<h3 class="mb-0">Nombre d'EFA par type d'énergie</h3>
					<span class="text-gray">Une EFA peut avoir plusieurs types d'énergie</span>
					<div class="w-100" style="height: 440px">
						<app-stat-chart-per-energy [perEnergyCount]="stats.perEnergyCount"></app-stat-chart-per-energy>
					</div>
				</div>
				<div class="stat-card h-100">
					<h3>Nombre d'entités par catégorie d'activité principale</h3>
					<div class="w-100">
						<app-stat-chart-per-category [mainActivityCount]="stats.mainActivityCount"></app-stat-chart-per-category>
					</div>
				</div>
			</div>
		</div>
		<div class="text-gray">
			* Agrégation des données renseignées dans le Configurateur à la date du 31 décembre 2021.
		</div>
		<div class="text-gray">
			** Agrégation des données dans le Configurateur sur l'ensemble de l'année 2021. En effet, malgré l'impact de la
			crise COVID sur la consommation, c'est l'année pour laquelle le plus de données sont disponibles sur l'ensemble
			des EFA. L'énergie du gaz naturel est exprimée en kWh PCI. Pour rappel, les données fournies par GRDF et
			généralement les données de facturation sont exprimées en kWh PCS.
		</div>
	</ng-container>
</div>
