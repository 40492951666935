<div class="changelog-page">
	<div class="changelog-nav">
		<div class="logo">
			<img [src]="LOGO_URL" />
		</div>
		<div class="changelog-nav-list">
			<div
				class="changelog-nav-item"
				*ngFor="let changelog of changelogs"
				(click)="currentChangelog = changelog"
				[class.active]="changelog === currentChangelog"
			>
				<h3>Version {{ changelog.version }}</h3>
				<span class="text-gray">{{ changelog.date | timestampToDate }}</span>
			</div>
		</div>
		<a class="primary link-home pointer" (click)="goHome()">
			<mat-icon class="mr-2">home</mat-icon>
			Retourner sur la page d'accueil
		</a>
	</div>
	<div class="changelog-details-container">
		<div class="changelog-details" *ngIf="currentChangelog">
			<h2>Version {{ currentChangelog.version }}</h2>
			<markdown [data]="currentChangelog.texts['fr_FR.UTF-8']"></markdown>
		</div>
	</div>
</div>
