<div class="pane">
	<div class="subpane">
		<div class="d-flex">
			<div class="d-flex-col">
				<div
					class="text-secondary pointer bold m-0"
					style="font-size: 25px"
					*ngIf="!editingTitle"
					(click)="editTitle()"
				>
					{{ group.name }}
					<span style="font-size: 20px; vertical-align: bottom"><mat-icon [inline]="true">edit</mat-icon></span>
				</div>
				<div class="text-secondary" style="font-weight: bold; font-size: 25px; margin: 0" *ngIf="editingTitle">
					<input
						[(ngModel)]="newTitle"
						class="custom-input-light h-100"
						style="min-width: 300px; border-radius: 4px 0 0 4px"
						(keydown.enter)="saveTitle()"
					/>
					<button
						mat-stroked-button
						class="btn-stroked-primary h-100"
						style="border-radius: 0 4px 4px 0; border-width: 2px"
						(click)="saveTitle()"
					>
						<mat-icon>save</mat-icon>
					</button>
				</div>
				<span class="text-gray">
					<ng-container *ngFor="let count of [group | countDeclarations]"
						>{{ count }} déclaration{{ count > 1 ? "s" : "" }}</ng-container
					>
					|
					<ng-container *ngFor="let count of [group | countEntities]"
						>{{ count }} entité{{ count > 1 ? "s" : "" }} fonctionnelle{{ count > 1 ? "s" : "" }}</ng-container
					>
				</span>
			</div>
			<div style="margin-left: auto; margin-right: 5px" *ngIf="group.is_archived; else notArchived">
				<button
					mat-stroked-button
					class="btn-stroked-primary-light btn-icon-animate"
					(click)="setArchived()"
					[class.btn-loading]="archivedLoading"
				>
					<mat-icon class="btn-icon-animate-icon">inventory</mat-icon>
					<span class="btn-icon-animate-text">Désarchiver</span>
				</button>
			</div>
			<ng-template #notArchived>
				<div class="d-flex ml-auto gap-2">
					<div>
						<button class="btn-primary-light btn-icon-animate" (click)="openGreenExport(group)">
							<img src="assets/img/white-tsi-logo.png" class="btn-icon-animate-image" />
							<span class="btn-icon-animate-text">Exporter vers la Green Solution</span>
						</button>
					</div>
					<div>
						<button class="btn-primary-light btn-icon-animate" (click)="goToStats(group.declaration_group_id)">
							<mat-icon class="btn-icon-animate-icon">bar_chart</mat-icon>
							<span class="btn-icon-animate-text">Statistiques</span>
						</button>
					</div>
					<div>
						<button
							class="btn-primary btn-icon-animate"
							*ngIf="group.declaration_group_id && user.is_temporary === false"
							(click)="openNewDeclarationDialog()"
						>
							<mat-icon class="btn-icon-animate-icon">add</mat-icon>
							<span class="btn-icon-animate-text">Ajouter des déclarations</span>
						</button>
					</div>
					<div>
						<button
							class="btn-primary btn-icon-animate"
							*ngIf="group.declaration_group_id && user.is_temporary === false"
							(click)="openImportDeclarationDialog()"
						>
							<mat-icon class="btn-icon-animate-icon">download</mat-icon>
							<span class="btn-icon-animate-text">Importer des déclarations</span>
						</button>
					</div>
					<div>
						<button
							class="btn-accent btn-icon-animate"
							*ngIf="group.declaration_group_id && group.owner_id && user.is_temporary === false"
							(click)="openDeclarationGroupAuthorizationListDialog(group, user.user_id ?? '')"
						>
							<mat-icon class="btn-icon-animate-icon">share</mat-icon>
							<span class="btn-icon-animate-text">Inviter un utilisateur</span>
						</button>
					</div>
					<div>
						<button
							class="btn-stroked-primary-light btn-icon-animate"
							(click)="setArchived()"
							[class.btn-loading]="archivedLoading"
						>
							<mat-icon class="btn-icon-animate-icon">inventory</mat-icon>
							<span class="btn-icon-animate-text">Archiver</span>
						</button>
					</div>
					<div
						[matTooltip]="
							(group | groupHasTokenUsed) ? 'Vous avez dépensé du crédit pour ce groupe de déclaration.' : ''
						"
					>
						<button
							*ngIf="representative"
							class="btn-danger btn-icon-animate"
							[disabled]="group | groupHasTokenUsed"
							(click)="openDeleteGroupDialog(group, representative)"
						>
							<mat-icon class="btn-icon-animate-icon">delete</mat-icon>
							<span class="btn-icon-animate-text">Supprimer ce groupe</span>
						</button>
					</div>
				</div>
			</ng-template>
		</div>
		<div style="margin-bottom: 15px"></div>
		<table>
			<thead>
				<tr class="table-header">
					<th></th>
					<th>Définition du périmètre</th>
					<th>Qualification des sites</th>
					<th>Déclaration patrimoniale</th>
					<th>Déclaration des consommations</th>
					<th>Recherche de l'année de référence</th>
				</tr>
			</thead>
			<app-declaration-row
				*ngFor="let declaration of group.declarations | apply: sortDeclarations"
				[declaration]="declaration"
				[group]="group"
				[representative]="representative"
				[user]="user"
				(stepActionEmitter)="stepActionEmitter.emit($event)"
				[developAll]="developAll"
				(deleteDeclaration)="deleteDeclaration(group, declaration)"
			></app-declaration-row>
		</table>
	</div>
</div>
