import { Component } from "@angular/core";
import { Modal } from "../../../../../models/modal";
type Input = {
	references: string[];
};

@Component({
	selector: "app-import-references-error",
	templateUrl: "./import-references-error.component.html",
	styleUrls: ["./import-references-error.component.scss"],
})
export class ImportReferencesErrorComponent extends Modal<Input, Input, undefined> {
	inputToState(input: Input): Input {
		return input;
	}
}
