<div class="dialog-container">
	<div class="dialog-content">
		<h2 class="mt-0 mb-0">Importation en cours</h2>
		Veuillez ne pas recharger la page
		<div class="w-100 h-100 d-flex" style="justify-content: center">
			<div class="d-flex-col" style="justify-content: center">
				<app-progress-percentage
					[value]="(currentImportIndex | async) ?? 0"
					[max]="state.dataToImport.references.length"
					color="primary"
				></app-progress-percentage>
			</div>
		</div>
	</div>
</div>
