import { DeclarationGroupId, DeclarationId, FunctionalDeclarationId, RepresentativeId } from "./ids";
import { getResourceTypeFromString, ResourceInfo, ResourceType } from "./resource";

export enum Route {
	MyDeclaration = "my-declarations",
	BuyToken = "buy-token",
	RepresentativeSettings = "representative-settings",
	Logout = "logout",
	Changelog = "changelog",
	Login = "login",
	ForgotPassword = "forgot-password",
	Addresses = "addresses",
	ConsoList = "conso-list",
	ConsoTable = "conso-table",
	Qualification = "qualification",
	AssetDeclaration = "asset-declaration",
	Reference = "reference",
	ReferenceEntity = "reference-functionalDeclaration",
	Agreement = "agreement",
	Csv = "csv",
	Register = "register",
	Authorizations = "authorizations",
	MyProfile = "my-profile",
	Stats = "stats",
}

const ALL_ROUTES = [
	Route.MyDeclaration,
	Route.Logout,
	Route.Login,
	Route.Addresses,
	Route.ConsoList,
	Route.ConsoTable,
	Route.Qualification,
	Route.AssetDeclaration,
	Route.Reference,
	Route.ReferenceEntity,
	Route.Agreement,
	Route.Csv,
	Route.Register,
	Route.Authorizations,
	Route.Stats,
];

export function stringToRoute(route: string): Route | undefined {
	for (const variant of ALL_ROUTES) {
		if (variant === route) {
			return variant;
		}
	}

	return undefined;
}

/**
 * If the return is "isolation" then it means that the type is equal to the isolation type.
 * @param route
 * @param arg possible argument for stats page
 * @returns
 */
export function routeToResourceType(route: Route, arg?: string): ResourceType | undefined | "isolation" {
	switch (route) {
		case Route.MyDeclaration:
			return "isolation";
		case Route.Addresses:
		case Route.ConsoList:
		case Route.Reference:
		case Route.Csv:
			return ResourceType.Declaration;
		case Route.Qualification:
		case Route.AssetDeclaration:
		case Route.ConsoTable:
		case Route.ReferenceEntity:
			return ResourceType.FunctionalDeclaration;
		case Route.Stats:
			return getResourceTypeFromString(arg);
		default:
			return undefined;
	}
}

export class RouteDealer {
	/**
	 * Gives the path to MyDeclaration. Accepts all resources except the functional declaration.
	 * @param id The resource's id
	 * @returns the route
	 */
	static myDeclaration(id: RepresentativeId | DeclarationGroupId | DeclarationId) {
		return ["/", id, Route.MyDeclaration];
	}

	static representativeSettings(id: RepresentativeId) {
		return [id, Route.RepresentativeSettings];
	}

	static buyToken(id: RepresentativeId | DeclarationGroupId) {
		return ["/", id, Route.BuyToken];
	}

	static addresses(id: DeclarationId) {
		return ["/", id, Route.Addresses];
	}

	static consoList(id: DeclarationId) {
		return ["/", id, Route.ConsoList];
	}

	static reference(id: DeclarationId) {
		return ["/", id, Route.Reference];
	}

	static csv(id: DeclarationId) {
		return ["/", id, Route.Csv];
	}

	static qualification(id: FunctionalDeclarationId) {
		return ["/", id, Route.Qualification];
	}

	static assetDeclaration(id: FunctionalDeclarationId) {
		return ["/", id, Route.AssetDeclaration];
	}

	static consoTable(id: FunctionalDeclarationId) {
		return ["/", id, Route.ConsoTable];
	}

	static referenceEntity(id: FunctionalDeclarationId) {
		return ["/", id, Route.ReferenceEntity];
	}

	static login() {
		return ["/"];
	}

	static logout() {
		return ["/", Route.Logout];
	}

	static authorizations() {
		return ["/", Route.Authorizations];
	}

	static stats({ id, type }: ResourceInfo | { id: "all"; type: ResourceType }) {
		return ["/", id, type, Route.Stats];
	}
}
