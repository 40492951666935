<div class="d-flex" style="flex-direction: column">
	<mat-checkbox [checked]="isMandated.value" (change)="isMandated.next(!isMandated.value)">
		Cette déclaration est mandatée
	</mat-checkbox>

	<div class="d-flex" style="align-items: center">
		<label> Je suis propriétaire et je veux déclarer mon/mes locataire(s) </label>
		<app-help [subject]="HelpSubject.OwnerStatus"></app-help>
		<mat-radio-group
			aria-label="Je suis propriétaire"
			class="d-inline-flex gap-2"
			[value]="choice | async | choiceIsOwner"
			(change)="toggleIsOwner($event.value)"
		>
			<mat-radio-button [value]="true">Oui</mat-radio-button>
			<mat-radio-button [value]="false">Non</mat-radio-button>
		</mat-radio-group>
	</div>

	<ng-container *ngIf="choice | async as choice">
		<div class="candy-form" *ngIf="choice.isOwner">
			<label>Je suis un propriétaire particulier (personne physique)</label>
			<mat-radio-group
				aria-label="Je suis un propriétaire particulier (personne physique)"
				class="ml-2 d-inline-flex gap-2"
				(change)="toggleNotACompany(choice)"
				[ngModel]="(choice.owner | asOther)?.label === 'PP'"
			>
				<mat-radio-button [value]="true">Oui</mat-radio-button>
				<mat-radio-button [value]="false">Non</mat-radio-button>
			</mat-radio-group>
		</div>
		<ng-container *ngIf="!choice.isOwner && choice.isOther">
			<div>
				<label for="labelOther">Libellé de l'identifiant</label>
				<input
					placeholder="Description de votre identifiant personnalisé"
					id="labelOther"
					type="text"
					#labelOther
					class="custom-input w-100"
					[value]="choice.idOther.label"
					(input)="editOtherLabel(labelOther.value, choice)"
				/>
			</div>
			<div>
				<label for="idOther">Identifiant</label>
				<input
					id="idOther"
					#idOther
					type="text"
					class="custom-input w-100"
					placeholder="Votre identifiant personnalisé"
					[value]="choice.idOther.id"
					(input)="editOtherId(idOther.value, choice)"
				/>
			</div>
		</ng-container>

		<ng-container *ngIf="choice.isOwner">
			<ng-container *ngIf="choice.owner | asOther as other; else notOther">
				<div *ngIf="other.label === 'PP'" class="custom-input-group">
					<div class="w-100 d-flex" style="justify-content: center">
						<div class="message-warning" style="width: 35em">
							<mat-icon>warning</mat-icon>
							<span>{{ OPERAT_PARTICULIER }}</span>
						</div>
					</div>
					<label for="otherEmail">Adresse email du compte OPERAT</label>
					<input
						#otherEmail
						id="otherEmail"
						placeholder="exemple@exemple.com"
						class="custom-input"
						type="email"
						email
						class="custom-input w-100"
						[value]="other.id"
						(input)="editOwnerId(choice, { id: otherEmail.value, label: other.label })"
					/>
					<span class="custom-input-error">{{ otherEmail.validationMessage }}</span>
				</div>
				<ng-container *ngIf="other.label !== 'PP'">
					<label>Identifiant du propriétaire</label>
					<app-identifier-view
						[identifier]="choice.owner"
						(editClick)="editOwnerIdentifier(choice)"
					></app-identifier-view>
				</ng-container>
			</ng-container>
			<ng-template #notOther>
				<label>Identifiant du propriétaire</label>
				<div class="d-flex" style="align-items: center">
					<app-identifier-view
						[identifier]="choice.owner"
						(editClick)="editOwnerIdentifier(choice)"
					></app-identifier-view>
					<ng-container *ngIf="errorInOwnerSiren$ | async">
						<span class="danger ml-1"> SIREN inconnu </span>
						<app-help [subject]="HelpSubject.MaybeSirenDoesntExist"></app-help>
					</ng-container>
				</div>
			</ng-template>
		</ng-container>

		<ng-container *ngIf="choice.isOwner || !choice.isOther">
			<div class="custom-input-group">
				<label *ngIf="!choice.isOwner" class="d-flex" style="align-items: center">
					Renseignez votre/vos SIRET/SIREN/RNA
					<app-help [subject]="HelpSubject.RNA"></app-help>
				</label>
				<label *ngIf="choice.isOwner">Renseignez le/les SIRET du/des locataire(s)</label>
				<div class="custom-input-action">
					<input
						#listInput
						class="custom-input"
						[placeholder]="choice.isOwner ? 'Liste de SIRET' : 'Liste de SIRET/SIREN/RNA'"
						(keyup.enter)="import(choice, listInput)"
					/>
					<button class="btn-primary" (click)="import(choice, listInput)">Traiter</button>
				</div>
				<span class="text-gray"
					>Vous pouvez les écrire, ou simplement copier-coller une liste provenant d'Excel ou Calc.</span
				>
			</div>
		</ng-container>

		<span class="danger" *ngIf="badFormatErrors.value.size > 0">
			Les identifiants suivant n'ont pas le format requis:
			<ng-container *ngFor="let badFormatError of badFormatErrors.value; index as i">
				<ng-container *ngIf="i === badFormatErrors.value.size - 1">{{ badFormatError }}.</ng-container>
				<ng-container *ngIf="i !== badFormatErrors.value.size - 1">{{ badFormatError }}, </ng-container>
			</ng-container>
		</span>

		<div class="mt-3 siret-list" *ngIf="choice.isOwner || !choice.isOther">
			<ng-container *ngIf="!choice.isOwner">
				<ng-container *ngFor="let siren of choice.sirens">
					<ng-container *ngFor="let isError of [errors.value | inSet: siren]">
						<div class="custom-input-group">
							<div class="custom-input-action">
								<input
									class="custom-input"
									[class.custom-input-on-error]="isError"
									disabled
									value="{{ siren }} (SIREN)"
								/>
								<button (click)="deleteSiren(choice, siren)" class="btn-danger">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							<span class="is-error" *ngIf="isError"
								>Non reconnu<app-help [subject]="HelpSubject.MaybeSirenDoesntExist"></app-help
							></span>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngFor="let siret of choice.sirets">
					<ng-container *ngFor="let isError of [errors.value | inSet: siret]">
						<div class="custom-input-group">
							<div class="custom-input-action">
								<input
									class="custom-input"
									[class.custom-input-on-error]="isError"
									disabled
									value="{{ siret }} (SIRET)"
								/>
								<button (click)="deleteSiret(choice, siret)" class="btn-danger">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							<span class="is-error" *ngIf="isError"
								>Non reconnu<app-help [subject]="HelpSubject.MaybeSirenDoesntExist"></app-help
							></span>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngFor="let rna of choice.rnas">
					<ng-container *ngFor="let isError of [errors.value | inSet: rna]">
						<div class="custom-input-group">
							<div class="custom-input-action">
								<input class="custom-input" [class.custom-input-on-error]="isError" disabled value="{{ rna }} (RNA)" />
								<button (click)="deleteRna(choice, rna)" class="btn-danger">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							<span class="is-error" *ngIf="isError">Non reconnu</span>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="choice.isOwner">
				<ng-container *ngFor="let siret of choice.tenantSirets">
					<ng-container *ngFor="let isError of [errors.value | inSet: siret]">
						<div class="custom-input-group">
							<div class="custom-input-action">
								<input
									class="custom-input"
									[class.custom-input-on-error]="isError"
									disabled
									value="{{ siret }} (SIRET)"
								/>
								<button (click)="deleteSiretFromTenant(choice, siret)" class="btn-danger">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							<span class="is-error" *ngIf="isError"
								>Non reconnu<app-help [subject]="HelpSubject.MaybeSirenDoesntExist"></app-help
							></span>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>
</div>
