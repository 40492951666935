<div class="dialog-container">
	<div class="dialog-content">
		<h2 class="mt-0 mb-0">Erreurs</h2>
		<div class="dialog-small-text">
			Une erreur est survenue lors de l'import de ces entités :
			<ul>
				<li *ngFor="let ref of state.references">{{ ref }}</li>
			</ul>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-primary-reverse" [mat-dialog-close]="undefined">Ok</button>
	</div>
</div>
