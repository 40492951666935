import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "dateToTimestamp",
})
export class DateToTimestampPipe implements PipeTransform {
	transform = dateToTimestamp;
}

export function dateToTimestamp(date: string): number {
	const [year, month, day] = date.split("-").map(Number);
	return Date.UTC(year, month - 1, day) / 1000;
}
