<div style="max-width: 600px" class="dialog-container">
	<div style="overflow: hidden; position: absolute">
		<div style="position: absolute; z-index: -1; opacity: 0; overflow: auto; width: 1200px; font-size: 1.3rem">
			<div #benchmarkGraphLastYear>
				<app-benchmark-graph
					[declaration]="declaration"
					[animate]="true"
					[djus]="djus"
					[focusId]="chunk?.declarationFunctional?.declaration_functional_id"
					[data]="lastYearData.benchmarkData"
					[minTotal]="lastYearData.minTotal"
					[maxTotal]="lastYearData.maxTotal"
					[minPerSurface]="lastYearData.minPerSurface"
					[maxPerSurface]="lastYearData.maxPerSurface"
					[year]="lastYear"
				></app-benchmark-graph>
			</div>
			<div #benchmarkGraphYearBeforeLastYear>
				<app-benchmark-graph
					[declaration]="declaration"
					[animate]="true"
					[djus]="djus"
					[focusId]="chunk?.declarationFunctional?.declaration_functional_id"
					[data]="yearBeforeLastYearData.benchmarkData"
					[minTotal]="yearBeforeLastYearData.minTotal"
					[maxTotal]="yearBeforeLastYearData.maxTotal"
					[minPerSurface]="yearBeforeLastYearData.minPerSurface"
					[maxPerSurface]="yearBeforeLastYearData.maxPerSurface"
					[year]="yearBeforeLastYear"
				></app-benchmark-graph>
			</div>
			<div #statPage *ngIf="stats">
				<div class="d-flex-col gap-3" style="width: 70%">
					<div class="stat-card bg-primary">
						<h2 class="counter m-0">
							<span [countUp]="stats.assujettiSurface" [options]="{ decimal: ',', separator: ' ' }">0</span>m²
						</h2>
						<h3 class="m-0">renseignés</h3>
					</div>
					<div class="d-flex gap-3">
						<div class="w-50 stat-card bg-primary">
							<h2 class="counter m-0">
								<span [countUp]="stats.siteCount" [options]="{ decimal: ',', separator: ' ' }">0</span>
							</h2>
							<h3 class="m-0">entités fonctionnelles assujettis</h3>
						</div>
						<div class="w-50 stat-card bg-primary-light">
							<h2 class="counter m-0">
								<span [countUp]="stats.irveCount" [options]="{ decimal: ',', separator: ' ' }">0</span>
							</h2>
							<h3 class="m-0">borne{{ stats.irveCount > 1 ? "s" : "" }} IRVE</h3>
						</div>
					</div>
					<div class="d-flex gap-3">
						<div class="w-50 stat-card bg-accent" *ngFor="let count of [stats.agreementReceivedCount.PRM]">
							<h2 class="counter m-0">
								<mat-icon>bolt</mat-icon>
								<span [countUp]="count" [options]="{ decimal: ',', separator: ' ' }">0</span>
							</h2>
							<h3 class="m-0">compteur{{ count > 1 ? "s" : "" }} Enedis reçu{{ count > 1 ? "s" : "" }}</h3>
						</div>
						<div class="w-50 stat-card bg-accent2" *ngFor="let count of [stats.agreementReceivedCount.PCE]">
							<h2 class="counter m-0">
								<mat-icon>local_fire_department</mat-icon>
								<span [countUp]="count" [options]="{ decimal: ',', separator: ' ' }">0</span>
							</h2>
							<h3 class="m-0">compteur{{ count > 1 ? "s" : "" }} GRDF reçu{{ count > 1 ? "s" : "" }}</h3>
						</div>
					</div>
				</div>
				<div style="height: 500px; width: 70%">
					<app-stat-chart-per-energy [perEnergyCount]="stats.perEnergyCount"></app-stat-chart-per-energy>
				</div>
			</div>
			<app-reference-year-summary-table
				[fullWidth]="true"
				[functionalDeclarations]="functionalEntity ? [functionalEntity] : []"
				#summaryTableComponent
			>
			</app-reference-year-summary-table>
			<div *ngFor="let dataEntry of chunk ? [chunk] : []">
				<div #functionalEntityContent>
					<div #functionalEntitySummary class="d-flex-col">
						<span *ngIf="dataEntry.declarationFunctional.efa_status | apply: getOwnerIfExists as owner">
							<ng-container *ngIf="owner | asSiren as ownerSiren"
								>SIREN du propriétaire : {{ ownerSiren.siren }}</ng-container
							>
							<ng-container *ngIf="owner | asRna as ownerRna">RNA du propriétaire : {{ ownerRna.rna }}</ng-container>
							<ng-container *ngIf="owner | asOther as ownerOther"
								>Identifiant du propriétaire : {{ ownerOther.id }}</ng-container
							>
							<ng-container *ngIf="owner | asRefEtatLess as ownerRefEtat"
								>Référence État du propriétaire : {{ ownerRefEtat.refEtatLess }}</ng-container
							>
						</span>
						<span *ngIf="dataEntry.declarationFunctional.efa_status | apply: getCondominiumIfExists as condominium">
							<ng-container *ngIf="condominium | asSiret as condominiumSiret"
								>SIRET de la copropriété : {{ condominiumSiret.siret }}</ng-container
							>
							<ng-container *ngIf="condominium | asRna as condominiumRna"
								>RNA de la copropriété : {{ condominiumRna.rna }}</ng-container
							>
							<ng-container *ngIf="condominium | asOther as condominiumOther"
								>Identifiant de la copropriété : {{ condominiumOther.id }}</ng-container
							>
							<ng-container *ngIf="condominium | asRefEtat as condominiumRefEtat">
								Référence état de la copropriété : {{ condominiumRefEtat.refEtat }}
							</ng-container>
						</span>
						<span class="mb-2"
							>Cas d'assujettissement : {{ dataEntry.declarationFunctional | assujettisementCase }}</span
						>
						<ng-container
							*ngIf="
								dataEntry.declarationFunctional.infos.asset.mainParcel
									| parcelFromName: dataEntry.declarationFunctional.infos.parcelles as parcel
							"
						>
							<span class="mb-2"> Parcelle cadastrale principale : {{ parcel | parcelName }} </span>
							<div *ngIf="dataEntry.declarationFunctional.infos.parcelles.length > 1">
								Autre(s) parcelle(s) cadastrale(s):
								<ul>
									<ng-container *ngFor="let parcelle of dataEntry.declarationFunctional.infos.parcelles">
										<li *ngIf="parcelle !== parcel">{{ parcelle | parcelName }}</li>
									</ng-container>
								</ul>
							</div>
						</ng-container>
						<span
							style="margin-bottom: 50px"
							*ngIf="dataEntry.declarationFunctional.infos.asset.station | stationFromId as stationData"
						>
							Station météo retenue : {{ stationData.station.code }} - {{ stationData.station.name }} ({{
								stationData.county.name
							}})
						</span>
					</div>
					<div>
						<app-activity-category-table
							#categoryData
							[functionalDeclaration]="dataEntry.declarationFunctional"
						></app-activity-category-table>
					</div>
					<div style="position: absolute; width: 2200px">
						<app-consumption-table-export
							*ngIf="dataEntry.declarationFunctional.infos.referenceYear"
							#consumptionData
							[functionalDeclaration]="dataEntry.declarationFunctional"
							[year]="dataEntry.declarationFunctional.infos.referenceYear"
						></app-consumption-table-export>
						<app-consumption-table-export
							#consumptionDataLastYear
							[functionalDeclaration]="dataEntry.declarationFunctional"
							[year]="lastYear"
						></app-consumption-table-export>
						<app-consumption-table-export
							#consumptionData2021
							[functionalDeclaration]="dataEntry.declarationFunctional"
							[year]="2021"
						></app-consumption-table-export>
						<app-consumption-table-export
							#consumptionData2020
							[functionalDeclaration]="dataEntry.declarationFunctional"
							[year]="2020"
						></app-consumption-table-export>
					</div>
					<div style="position: absolute; width: 1485px; height: 1050px">
						<app-year-reference-data
							#referenceData
							[animate]="false"
							[functionalDeclaration]="dataEntry.declarationFunctional"
							[djus]="dataEntry.djus"
							[chartHeight]="750"
							[absoluteObjectives]="dataEntry.absoluteObjectives"
						></app-year-reference-data>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="toTreat === -1">
		<div class="dialog-content">
			<div class="custom-input-group mb-2" *ngIf="declaration.declarations_functional.length > 1">
				<label>Titre du rapport</label>
				<input class="custom-input" [(ngModel)]="reportTitle" />
			</div>
			Ce rapport vous fournira une archive au format ZIP comprenant :
			<ul class="mt-1">
				<li>
					Un résumé des années de référence et des objectifs de réduction de consommations pour l'ensemble des EFA, au
					format PDF.
				</li>
				<li>
					Un résumé au format PDF, pour chaque EFA, comprenant les informations principales de cette dernière, la liste
					de ses catégories d'activités, le détail de l'ensemble de ses consommations sur la période 2010-2021, ainsi
					que son année de référence et ses objectifs.
				</li>
			</ul>
			<div class="message-warning d-flex mb-2 gap-2" style="align-items: center">
				<mat-icon style="min-width: 24px">warning</mat-icon>
				Durant cette opération, vous ne devrez pas quitter cette page, au risque que votre document ne soit pas
				généré.<br />
				Nous estimons le temps de téléchargement à environ {{ ESTIMATE_TIME_PER_ENTITY }} secondes par entité, soit
				<ng-container *ngIf="estimateDate.getUTCHours() > 0"> {{ estimateDate.getUTCHours() }} heures, </ng-container>
				<ng-container *ngIf="estimateDate.getUTCMinutes() > 0 || estimateDate.getUTCHours() > 0">
					{{ estimateDate.getUTCMinutes() }} minutes et
				</ng-container>
				<ng-container
					*ngIf="estimateDate.getUTCSeconds() > 0 || estimateDate.getUTCMinutes() > 0 || estimateDate.getUTCHours() > 0"
				>
					{{ estimateDate.getUTCSeconds() }} secondes
				</ng-container>
				pour votre déclaration.
			</div>
		</div>
		<div class="dialog-actions d-flex gap-2" style="justify-content: center">
			<button class="btn-stroked-text-gray" mat-dialog-close="">Annuler</button>
			<button class="btn-stroked-primary" (click)="download()" [disabled]="toTreat !== -1">
				<ng-container *ngIf="toTreat === -1"> Télécharger le rapport pdf </ng-container>
				<ng-container *ngIf="toTreat !== -1 && generated === 0"> Initialisation ... </ng-container>
				<ng-container *ngIf="toTreat !== -1 && toTreat !== generated">
					Génération des composants ({{ generated }}/{{ toTreat }}) ...
				</ng-container>
				<ng-container *ngIf="toTreat !== -1 && toTreat === generated && toTreat !== converted">
					Conversion ({{ converted }}/{{ toTreat }}) ...
				</ng-container>
			</button>
		</div>
	</div>
	<div *ngIf="toTreat !== -1">
		<div class="dialog-content d-flex-col" style="justify-content: center">
			<h4 class="m-0">Téléchargement en cours...</h4>
			<span class="text-secondary">
				Nous vous conseillons durant la génération du rapport de rester sur cette page afin que la génération se fasse
				plus vite, si vous quitter la page, il y a un risque que le chargement s'arrête.
			</span>
			<div class="d-flex mb-1" style="justify-content: center">
				<app-progress-percentage [value]="generated" [max]="toTreat" color="primary"></app-progress-percentage>
			</div>
			<span class="text-gray" *ngIf="activeEstimateDate && activeEstimateDate.getTime() > 0">
				Estimation du temps restant:
				<ng-container *ngIf="activeEstimateDate.getUTCHours() > 0">
					{{ activeEstimateDate.getUTCHours() }} heures,
				</ng-container>
				<ng-container *ngIf="activeEstimateDate.getUTCMinutes() > 0 || activeEstimateDate.getUTCHours() > 0">
					{{ activeEstimateDate.getUTCMinutes() }} minutes et
				</ng-container>
				<ng-container
					*ngIf="
						activeEstimateDate.getUTCSeconds() > 0 ||
						activeEstimateDate.getUTCMinutes() > 0 ||
						activeEstimateDate.getUTCHours() > 0
					"
				>
					{{ activeEstimateDate.getUTCSeconds() }} secondes
				</ng-container>
			</span>
		</div>
		<div class="dialog-actions d-flex" style="justify-content: center">
			<button class="btn-stroked-text-gray" (click)="cancelDownload()">Annuler</button>
		</div>
	</div>
</div>
