import { formatDate } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "timestampToDate",
})
export class TimestampToDatePipe implements PipeTransform {
	transform(timestamp: number, format = "yyyy-MM-dd"): string {
		if (isNaN(timestamp)) {
			return "";
		}
		return formatDate(timestamp * 1000, format, "fr-FR");
	}
}
