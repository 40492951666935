<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content">
		<div style="max-width: 700px">
			<h3 class="m-0 mb-2">Modifier les livraisons</h3>
			<span class="text-gray">
				Les consommations par livraisons sont lissées selon des règles particulières des textes officiels. Les données
				affichées seront donc différentes des données saisies dans l'interface. (Les volumes de livraison sont lissées
				sur une période regroupant l'année considérée et les suivantes (max 4 ans au total). Il est donc normal qu'une
				livraison soit répartie sur les années précédentes.
			</span>
			<app-custom-form #form>
				<div tourAnchor="consumption-add-delivery-average">
					<mat-label>Nombres d'années à moyenner</mat-label>
					<ng-select
						name="yearAverage"
						appearance="outline"
						style="margin-bottom: 15px"
						[(ngModel)]="this.consumptionTable.yearAverage"
						[clearable]="false"
					>
						<ng-option *ngFor="let i of [1, 2, 3, 4]" [value]="i">{{ i }} ans</ng-option>
					</ng-select>
				</div>
				<mat-checkbox
					name="hasRepartitionKey"
					[(ngModel)]="this.consumptionTable.hasRepartitionKey"
					color="primary"
					(change)="initRepartitionKeys()"
				>
					Les consommations sont réparties aux tantièmes
					<app-help [subject]="HelpSubject.Tantieme"></app-help>
				</mat-checkbox>
				<div *ngIf="this.consumptionTable.hasRepartitionKey">
					<h4 class="mb-2">Tantièmes</h4>
					<div *ngFor="let repartitionKey of this.consumptionTable.repartitionKeys; index as i">
						<div class="d-flex gap-2 mb-1">
							<div class="w-25 custom-input-group">
								<label>Date début</label>
								<input
									required
									class="custom-input"
									name="date_start{{ i }}"
									type="date"
									[value]="repartitionKey.start | timestampToDate"
									disabled
								/>
							</div>
							<div class="w-25 custom-input-group">
								<label>Date fin</label>
								<input
									class="custom-input"
									name="date_end{{ i }}"
									type="date"
									#endInput
									[min]="repartitionKey.start | timestampToDate | followingDay"
									[max]="TODAY | date: 'y-MM-dd'"
									[value]="repartitionKey.end ? (repartitionKey.end | timestampToDate) : undefined"
									(change)="
										repartitionKeyEndDateChange(repartitionKey, endInput.value);
										checkError(repartitionKey.start, repartitionKey.end, endInput)
									"
								/>
								<span class="custom-input-error">{{ endInput.validationMessage }}</span>
							</div>
							<div style="flex: 1 1 0" class="w-0">
								<label>Tantième</label>
								<div class="d-flex gap-1">
									<div class="custom-input-group w-0" style="flex: 1 1 0">
										<input
											type="number"
											class="custom-input"
											#partUmpteenthInput
											[(ngModel)]="repartitionKey.partUmpteenth"
											(ngModelChange)="cdRef.detectChanges()"
											min="0"
											[max]="repartitionKey.totalUmpteenth"
											required
											name="partUmpteenth{{ i }}"
										/>
										<span class="custom-input-error">{{ partUmpteenthInput.validationMessage }}</span>
									</div>
									<div style="font-size: 30px">/</div>
									<div class="custom-input-group w-0" style="flex: 1 1 0">
										<input
											type="number"
											class="custom-input"
											#totalUmpteenthInput
											[(ngModel)]="repartitionKey.totalUmpteenth"
											(ngModelChange)="cdRef.detectChanges()"
											min="0"
											required
											name="totalUmpteenth{{ i }}"
										/>
										<span class="custom-input-error">{{ totalUmpteenthInput.validationMessage }}</span>
									</div>
								</div>
							</div>
							<div style="width: 24px">
								<mat-icon *ngIf="i !== 0" class="danger pointer mt-5" (click)="deleteRepartitionKey(repartitionKey)">
									delete
								</mat-icon>
							</div>
						</div>
					</div>
					<button
						mat-flat-button
						color="primary"
						[disabled]="
							this.consumptionTable.repartitionKeys[this.consumptionTable.repartitionKeys.length - 1].end === undefined
						"
						(click)="addRepartitionKey()"
					>
						<mat-icon class="mr-2">add</mat-icon>
						Ajouter un tantième
					</button>
				</div>
				<div>
					<h4 class="mb-2">Livraison</h4>
					<div *ngFor="let invoice of this.consumptionTable.invoiceData; index as i">
						<div class="d-flex mb-1 gap-2" style="align-items: center" [id]="'invoice-data-' + i">
							<div class="w-100 custom-input-group" [tourAnchor]="i === 0 ? 'consumption-add-delivery-date' : ''">
								<label>Date</label>
								<input
									required
									name="date_start_invoice{{ i }}"
									type="date"
									class="custom-input"
									(focusout)="invoiceDateChange(invoice, dateInput.value)"
									#dateInput
									[value]="invoice.date | timestampToDate"
									[min]="START | date: 'y-MM-dd'"
									[max]="TODAY | date: 'y-MM-dd'"
								/>
								<span class="custom-input-error">{{ dateInput.validationMessage }}</span>
							</div>
							<div class="w-100 custom-input-group" [tourAnchor]="i === 0 ? 'consumption-add-delivery-value' : ''">
								<label>Montant livré en {{ energy | energyTypeUnit }}</label>
								<div class="input-icon w-100">
									<input
										required
										name="value{{ i }}"
										class="custom-input pr-4 w-100"
										(input)="onValueChange(valueInput.value, invoice)"
										#valueInput
										value="{{ invoice.value | energyTypeValueDisplay: this.energy }}"
										type="number"
										appInputRestriction="integer"
										[min]="0"
									/>
									<span class="input-suffix">{{ energy | energyTypeUnit }}</span>
								</div>
							</div>
							<mat-icon
								*ngIf="this.consumptionTable.invoiceData.length > 1"
								class="danger pointer"
								style="min-width: 24px"
								(click)="deleteInvoice(invoice)"
							>
								delete
							</mat-icon>
						</div>
					</div>
					<button mat-flat-button color="primary" (click)="addInvoice()" tourAnchor="consumption-add-delivery-row">
						<mat-icon class="mr-2">add</mat-icon>
						Ajouter une livraison
					</button>
				</div>
			</app-custom-form>
		</div>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [matDialogClose]="this.initialConsumptionTable">Fermer</button>
		<button class="btn-stroked-primary-light" (click)="openImportCSVModal()">Importer un fichier CSV</button>
		<button
			type="submit"
			class="btn-stroked-primary"
			[matDialogClose]="this.consumptionTable"
			tourAnchor="consumption-add-delivery-save"
			[disabled]="!form.formValid"
		>
			Modifier
		</button>
	</div>
</div>
