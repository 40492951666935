import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys as Keys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { EfaStatus } from "../models/efaStatus";

@Pipe({
	name: "getLabelFromEfaStatus",
})
export class GetLabelFromEfaStatusPipe implements PipeTransform {
	transform(value: Immutable<EfaStatus>): string {
		const { status } = value;
		if (status.type === Keys.KEY_LANDLORD) {
			if ("data" in status) {
				return status.data.tenant ? "Mon locataire" : "Local vacant";
			}
			return status.tenant ? "Mon locataire" : "Local vacant";
		}

		switch (status.type) {
			case Keys.KEY_CONDOMINIUM:
				return "Copropriété";
			case Keys.KEY_OWNER_OCCUPANT:
				return "Propriétaire occupant";
			case Keys.KEY_TENANT:
				return "Preneur à bail dans une autre structure";
		}
	}
}
