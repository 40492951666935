import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { range } from "../../../../../helpers/array";

@Component({
	selector: "app-year-slider",
	templateUrl: "./year-slider.component.html",
	styleUrls: ["./year-slider.component.scss"],
})
export class YearSliderComponent {
	@Input() minYear = 2021;
	@Input() maxYear = 2022;
	@Input() currentYear = 2021;
	@Output() currentYearChange = new EventEmitter<number>();

	@ViewChild("timeline") timeline!: ElementRef;

	isDragging = false;

	constructor() {}

	selectYear(year: number) {
		this.currentYear = year;
		this.currentYearChange.emit(year);
	}

	selectYearOnTimeline(mouseX: number) {
		const width = this.timeline.nativeElement.clientWidth;
		const availableYears = range(this.minYear, this.maxYear + 1);
		const step = width / (availableYears.length - 1);

		const rect = this.timeline.nativeElement.getBoundingClientRect();
		const posX = mouseX - rect.left;

		const sortedYears = availableYears
			.map((year, index) => {
				return { year, position: Math.abs(posX - step * index) }; // return year and position relative to cursor
			})
			.sort((a, b) => a.position - b.position); // sort by closest

		this.selectYear(sortedYears[0].year);
	}

	mouseDragged(mouseX: number) {
		if (!this.isDragging) {
			return;
		}

		this.selectYearOnTimeline(mouseX);
	}

	protected readonly range = range;
}
