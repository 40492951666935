import { Pipe, PipeTransform } from "@angular/core";
import { County, getCounties, Station } from "../../assets/data/stations-meteo";

@Pipe({
	name: "stationFromId",
})
export class StationFromIdPipe implements PipeTransform {
	transform(id: number | undefined): { county: County; station: Station } | undefined {
		if (!id) {
			return undefined;
		}
		for (const county of getCounties()) {
			const foundStation = county.stations.find((station) => station.code === id);
			if (foundStation) {
				return { county, station: foundStation };
			}
		}

		return undefined;
	}
}
