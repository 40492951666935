<div class="pane buy-token">
	<div class="d-flex" style="position: relative">
		<app-buy-token-form
			[registration]="false"
			class="w-100"
			(changeDrawerState)="visibleDrawer = $event"
		></app-buy-token-form>
		<div *ngIf="payments.length > 0" class="h-100 d-flex-col ml-5" style="justify-content: flex-start; margin-top: 2px">
			<button
				style="justify-content: center"
				class="btn-stroked-primary-light btn-icon-animate"
				(click)="changeDrawerState()"
			>
				<mat-icon class="btn-icon-animate-icon" [@histoButton]="drawerState">start</mat-icon>
				<span [@histoButtonText]="drawerState" class="btn-icon-animate-text">Historique d'achat</span>
			</button>
		</div>
		<div *ngIf="payments.length > 0" class="pl-2 bl-1px ml-5" style="overflow: hidden" [@histoMenu]="drawerState">
			<table class="w-100 text-left">
				<tbody>
					<ng-container *ngFor="let payment of payments">
						<tr *ngIf="payment[GreenKeys.KEY_IS_PAYMENT_SENT] && payment[GreenKeys.KEY_REPRESENTATIVE]">
							<th class="payment-list-status">
								<mat-icon *ngIf="!payment[GreenKeys.KEY_IS_ACCEPTED]" class="waiting"> schedule </mat-icon>
								<mat-icon *ngIf="payment[GreenKeys.KEY_IS_ACCEPTED] === true" class="accepted"> done </mat-icon>
								<mat-icon *ngIf="payment[GreenKeys.KEY_IS_ACCEPTED] === false" class="refused"> close </mat-icon>
							</th>
							<th class="payment-list-text">
								<span class="payment-list-text-main">
									Achat de {{ payment[GreenKeys.KEY_QUANTITY] }} crédit{{
										payment[GreenKeys.KEY_QUANTITY] > 1 ? "s" : ""
									}}
									{{
										payment["token_type"] === 0
											? "initia" + (payment[GreenKeys.KEY_QUANTITY] > 1 ? "ux" : "l")
											: "annuel" + (payment[GreenKeys.KEY_QUANTITY] > 1 ? "s" : "")
									}}
									pour {{ payment[GreenKeys.KEY_REPRESENTATIVE]?.name }}
								</span>
								<span class="payment-list-text-date">
									{{ payment[GreenKeys.KEY_CREATION_DATE] | timestampToDate: "dd/MM/YYYY HH:mm" }}
								</span>
							</th>
							<th>
								<div class="payment-list-amount">
									<span class="payment-amount">{{ payment[GreenKeys.KEY_EURO_AMOUNT] * 1.2 }}€</span>
									<span
										>Numéro de transaction :
										<span class="payment-ref">#{{ payment[GreenKeys.KEY_PAYMENT_ID] }}</span></span
									>
								</div>
							</th>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</div>
</div>
