import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { SURFACE_FOR_ASSUJETTI } from "../helpers/consts";
import { EntityInfo } from "../models/functionalDeclaration";

@Pipe({
	name: "isOwnedAssujetti",
})
export class IsOwnedAssujettiPipe implements PipeTransform {
	transform(info: Immutable<EntityInfo>): boolean {
		return EntityInfo.assujettiArea(info).owned >= SURFACE_FOR_ASSUJETTI;
	}
}
