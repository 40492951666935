<span class="d-inline-flex gap-1 w-100">
	<div class="custom-input-group w-100">
		<input
			#input
			class="custom-input w-100"
			[placeholder]="placeholder"
			[maxLength]="9"
			[minlength]="9"
			[(ngModel)]="siren"
			(ngModelChange)="
				sirenChange.emit($event); siren.length === 9 ? checkSiren(input, siren) : resetCustomValidity(input)
			"
			required
		/>
		<span class="custom-input-error">{{ input.validationMessage }}</span>
	</div>

	<button
		[disabled]="!(siren | apply: Siren.isValid) || tasksCount > 0 || input.validationMessage"
		[ngClass]="{
			'btn-squared': true,
			'btn-primary': !validSirens.has(siren),
			'btn-accent': validSirens.get(siren) === true,
			'btn-danger': validSirens.get(siren) === false,
		}"
		class="btn-primary btn-squared"
		style="width: 40px; height: 40px"
		(click)="validSirens.has(siren) ? undefined : checkSiren(input, siren)"
		[matTooltip]="
			!validSirens.has(siren)
				? (siren | apply: Siren.isValid)
					? 'Vérifier le SIREN en ligne'
					: 'Votre SIREN n\'a pas un bon format (9 chiffres)'
				: validSirens.get(siren)
					? 'Votre SIREN existe'
					: 'Votre SIREN n\'existe pas'
		"
	>
		<mat-icon>
			{{ !validSirens.has(siren) ? "search" : validSirens.get(siren) ? "check" : "close" }}
		</mat-icon>
	</button>
</span>
<ng-container *ngIf="validSirens.get(siren) === false">
	Mon SIREN n'est pas détecté correctement <app-help [subject]="HelpSubject.MaybeSirenDoesntExist"></app-help>
</ng-container>
