<div class="message-warning mb-2" *ngIf="(surface.categorySurfaceAreas | apply: exemptedSum) > surface.tertiaireArea">
	<mat-icon>warning</mat-icon>
	<span>
		La somme des surfaces exemptées ({{ surface.categorySurfaceAreas | apply: exemptedSum }} m²) est strictement
		supérieure à la surface de plancher tertiaire totale ({{ surface.tertiaireArea }} m²).
	</span>
</div>
<div class="candy-form">
	<div>
		<label for="tertiaireInput"
			>Surface de plancher tertiaire totale (m²) (hors stationnement et locaux techniques)</label
		>
	</div>
	<input
		id="tertiaireInput"
		#tertiaireInput
		[value]="surface.tertiaireArea"
		(input)="updateTertiaireArea(tertiaireInput.value)"
		class="custom-input"
		appInputRestriction="integer"
	/>
</div>

<h4 class="d-flex">
	<mat-icon class="mr-1">warning</mat-icon>
	Si vous êtes concernés par une ou plusieurs catégories d'activité exemptées suivantes, renseignez chacune des surfaces
	associées en m² :
</h4>
<div class="candy-form">
	<app-category-list
		[categorySurfaceAreas]="surface.categorySurfaceAreas"
		(updateField)="updateField($event.key, $event.subKey, $event.value)"
	>
	</app-category-list>
</div>
<h4 class="d-flex" style="align-items: center">
	<span class="text-icon">
		<mat-icon>crop</mat-icon>
		Surface accessoire non tertiaire à une activité du tertiaire
	</span>
	<app-help [subject]="HelpSubject.AccessoryArea"></app-help>
</h4>

<div class="candy-form">
	<div>
		<label for="accessoryInput"> Surface de plancher accessoire (m²) </label>
	</div>
	<input
		id="accessoryInput"
		#accessoryInput
		[value]="surface.accessoryArea"
		(input)="updateAccessoryArea(accessoryInput.value)"
		[min]="0"
		type="number"
		appInputRestriction="integer"
		class="custom-input"
	/>
</div>
