import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { getHoleInConsumption } from "../helpers/conso";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { dateToTimestamp } from "./date-to-timestamp.pipe";
import { periodRange } from "./period-range.pipe";

@Pipe({
	name: "checkFunctionalDeclarationState",
})
export class CheckFunctionalDeclarationStatePipe implements PipeTransform {
	transform(entity: Immutable<FunctionalDeclaration>): string[] {
		const ret = [];
		const range2020 = periodRange(2020, entity.infos.favoritePeriod);
		const range2021 = periodRange(2021, entity.infos.favoritePeriod);
		if (getHoleInConsumption(entity, dateToTimestamp(range2020.start), dateToTimestamp(range2020.end))) {
			ret.push("Consommations 2020 incomplètes.");
		}
		if (getHoleInConsumption(entity, dateToTimestamp(range2021.start), dateToTimestamp(range2021.end))) {
			ret.push("Consommations 2021 incomplètes.");
		}
		for (let year = 2010; year <= 2019; year++) {
			const yearPeriod = periodRange(year, entity.infos.favoritePeriod);
			if (!getHoleInConsumption(entity, dateToTimestamp(yearPeriod.start), dateToTimestamp(yearPeriod.end))) {
				return ret;
			}
		}
		ret.push("Aucune année de référence identifiable.");
		return ret;
	}
}
