import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { HeatModifierType } from "../models/heatModifierType";

@Pipe({
	name: "hasLogisticCooling",
})
export class HasLogisticCoolingPipe implements PipeTransform {
	transform(modifiers: Immutable<HeatModifierType[]>): boolean {
		return modifiers.includes(HeatModifierType.LOGISTIC_COOL);
	}
}
