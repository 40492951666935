import { HeaderEnum } from "./HeaderEnum";
import { Indicator } from "./Indicator";
const MAX_NUMBER = 10;
export class IndicatorGroup {
  indicators;
  constructor(responseObject) {
    this.indicators = [];
    for (let i = 1; i <= MAX_NUMBER; i++) {
      if (responseObject[`${HeaderEnum.NOM_INDICATEUR}${i}`] !== undefined && responseObject[`${HeaderEnum.NOM_INDICATEUR}${i}`] !== null && responseObject[`${HeaderEnum.NOM_INDICATEUR}${i}`] !== "" && responseObject[`${HeaderEnum.VALEUR_INDICATEUR}${i}`] !== undefined && responseObject[`${HeaderEnum.VALEUR_INDICATEUR}${i}`] !== null && responseObject[`${HeaderEnum.VALEUR_INDICATEUR}${i}`] !== "") {
        this.indicators.push(new Indicator(responseObject[`${HeaderEnum.NOM_INDICATEUR}${i}`], responseObject[`${HeaderEnum.VALEUR_INDICATEUR}${i}`]));
      }
    }
  }
  getIndicator(index) {
    return this.indicators[index];
  }
  serialize() {
    let result = {};
    for (let i = 0; i < MAX_NUMBER; i++) {
      if (this.indicators[i] !== null && this.indicators[i] !== undefined) {
        result = {
          ...result,
          ...this.indicators[i].serialize(i + 1)
        };
      } else {
        result[`${HeaderEnum.NOM_INDICATEUR}${i + 1}`] = "";
        result[`${HeaderEnum.VALEUR_INDICATEUR}${i + 1}`] = "";
      }
    }
    return result;
  }
}
