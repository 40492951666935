import { Component } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { Declaration } from "../../../../../../models/declaration";
import { AddressInfo } from "../../../../../../models/address";
import { FunctionalDeclaration } from "../../../../../../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../../../../../../models/ids";
import { Modal } from "../../../../../../models/modal";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

type Input = {
	functionalDeclarations: Immutable<FunctionalDeclaration[]>;
	year: number | null;
	csv: { ids: FunctionalDeclarationId[]; file: SafeUrl; filename: string };
};

type State = {
	functionalDeclarations: Immutable<FunctionalDeclaration[]>;
	year: number | null;
	csv: { ids: FunctionalDeclarationId[]; file: SafeUrl; filename: string };
};
@Component({
	selector: "app-partial-csv-download",
	templateUrl: "./partial-csv-download.component.html",
	styleUrls: ["./partial-csv-download.component.scss"],
})
export class PartialCsvDownloadComponent extends Modal<Input, State, undefined> {
	reachedBottom = false;

	get unlockedFd(): Immutable<FunctionalDeclaration[]> {
		return this.state.functionalDeclarations.filter((fd) =>
			this.state.year && this.state.year >= ANNUAL_DECLARATION_STARTING_YEAR
				? fd[GreenKeys.KEY_YEARS_TOKEN_USED].includes(this.state.year)
				: fd[GreenKeys.KEY_IS_TOKEN_USED],
		);
	}

	inputToState(input: Input): State {
		return {
			functionalDeclarations: input.functionalDeclarations,
			year: input.year,
			csv: input.csv,
		};
	}

	addressToString(address: Immutable<AddressInfo>) {
		return AddressInfo.toString(address);
	}

	checkOverflow(div: HTMLElement) {
		return div.offsetHeight < div.scrollHeight;
	}

	onScroll({ target }: Event) {
		const div = target as HTMLElement;
		// visible height + pixel scrolled >= total height
		this.reachedBottom = div.offsetHeight + div.scrollTop >= div.scrollHeight - 50;
	}

	scrollToBottom(div: HTMLElement) {
		div.scrollTop = div.scrollHeight;
	}

	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
}
