<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0" style="max-width: 450px">
		<h3 class="mt-0 mb-2">Modifier les tantièmes</h3>
		<div class="message-warning">
			<mat-icon>warning</mat-icon>
			<span>
				Cette action va remplacer les tantièmes pour toutes les valeurs saisies pour cette ligne de consommation. Si des
				tantièmes sont déjà renseignés (manuellement ou lors de la création du PDL), ils seront écrasés.<br />
				Ceci ne permet cependant pas de définir des tantièmes par défaut pour la ligne de consommation, cela n'affecte
				que les valeurs déjà présentes.
			</span>
		</div>
		<app-custom-form #form>
			<div class="d-flex gap-2 mt-2" style="align-items: end; justify-content: center">
				<div class="custom-input-group w-100">
					<label>Tantième</label>
					<input
						type="number"
						min="0"
						[max]="row.totalUmpteenth"
						class="custom-input"
						required
						[(ngModel)]="row.partUmpteenth"
					/>
				</div>
				<div style="font-size: 30px">/</div>
				<div class="custom-input-group w-100">
					<input type="number" class="custom-input" required [(ngModel)]="row.totalUmpteenth" min="0" />
				</div>
			</div>
		</app-custom-form>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray mr-auto" mat-dialog-close>Annuler</button>
		<button class="btn-stroked-primary" (click)="save()" [disabled]="!form.formValid">Valider</button>
	</div>
</div>
