<div style="max-width: 600px" class="dialog-container">
	<div *ngIf="toTreat === -1">
		<div class="dialog-content">
			<p>
				Vous vous apprêtez à télécharger les données de consommations annuelles pour l'ensemble des EFA.<br />
				Pour chacune d'entre elles, vous disposerez des données de consommations brutes et corrigées des DJUs (en kWh
				ainsi qu'en kWh/m²), sous format CSV.<br />
				Le tout sera disponible dans une archive au format ZIP, comprenant un fichier CSV par EFA.
			</p>

			<div class="message-warning d-flex mb-2 gap-2" style="align-items: center" *ngIf="!!estimateDate">
				<mat-icon style="min-width: 24px">warning</mat-icon>
				Attention, selon le volume d'EFA, le téléchargement peut prendre un certain temps.<br />
				Durant l'opération, merci de ne pas quitter cette page, au risque que le document ne soit pas généré.<br />
				Nous estimons le temps de téléchargement à environ {{ ESTIMATE_TIME_PER_ENTITY }} secondes par entité, soit
				<ng-container *ngIf="estimateDate.getUTCHours() > 0"> {{ estimateDate.getUTCHours() }} heures, </ng-container>
				<ng-container *ngIf="estimateDate.getUTCMinutes() > 0 || estimateDate.getUTCHours() > 0">
					{{ estimateDate.getUTCMinutes() }} minutes et
				</ng-container>
				<ng-container
					*ngIf="estimateDate.getUTCSeconds() > 0 || estimateDate.getUTCMinutes() > 0 || estimateDate.getUTCHours() > 0"
				>
					{{ estimateDate.getUTCSeconds() }} secondes
				</ng-container>
				pour votre déclaration.
			</div>
		</div>
		<div class="d-flex gap-2 dialog-actions" style="justify-content: space-between">
			<button class="btn-stroked-text-gray" mat-dialog-close="">Annuler</button>
			<button class="btn-stroked-primary" (click)="download()" [disabled]="toTreat !== -1">
				<ng-container *ngIf="toTreat === -1">
					<ng-container *ngIf="!isLoading"> Exporter les données de consommation </ng-container>
					<ng-container *ngIf="isLoading">
						<div class="d-flex load-status" style="justify-content: center">
							<mat-spinner [diameter]="30" color="warn"></mat-spinner>
						</div>
					</ng-container>
				</ng-container>
			</button>
		</div>
	</div>
	<div *ngIf="toTreat !== -1">
		<div class="d-flex-col" style="justify-content: center">
			<div class="dialog-content">
				<h4 class="m-0">Téléchargement en cours...</h4>
				<span class="text-secondary">
					Nous vous conseillons durant la génération du document de rester sur cette page afin que la génération se
					fasse plus vite, si vous quitter la page, il y a un risque que le chargement s'arrête.
				</span>
				<div class="d-flex mb-1" style="justify-content: center">
					<app-progress-percentage [value]="progress" [max]="toTreat" color="primary"></app-progress-percentage>
				</div>
				<span class="text-gray" *ngIf="activeEstimateDate && activeEstimateDate.getTime() > 0">
					Estimation du temps restant:
					<ng-container *ngIf="activeEstimateDate.getUTCHours() > 0">
						{{ activeEstimateDate.getUTCHours() }} heures,
					</ng-container>
					<ng-container *ngIf="activeEstimateDate.getUTCMinutes() > 0 || activeEstimateDate.getUTCHours() > 0">
						{{ activeEstimateDate.getUTCMinutes() }} minutes et
					</ng-container>
					<ng-container
						*ngIf="
							activeEstimateDate.getUTCSeconds() > 0 ||
							activeEstimateDate.getUTCMinutes() > 0 ||
							activeEstimateDate.getUTCHours() > 0
						"
					>
						{{ activeEstimateDate.getUTCSeconds() }} secondes
					</ng-container>
				</span>
			</div>
			<div class="d-flex dialog-actions" style="justify-content: center">
				<button class="btn-stroked-text-gray" (click)="cancelDownload()">Annuler</button>
			</div>
		</div>
	</div>
</div>
