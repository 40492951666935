import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { ActivityCategoryEntry, SubCategoryCode } from "../models/asset";

@Pipe({
	name: "uniqueSubCategoriesPerName",
})
export class UniqueSubCategoriesPerNamePipe implements PipeTransform {
	transform(aces: Immutable<ActivityCategoryEntry[]>): {
		subCategoryCode: SubCategoryCode;
		entries: { name: string; activityCategoryEntries: Immutable<ActivityCategoryEntry>[] }[];
	}[] {
		const ret: Record<string, Record<string, Immutable<ActivityCategoryEntry>[]>> = {};
		aces.forEach((ace) => {
			if (ret[ace.subCategoryCode] === undefined) {
				ret[ace.subCategoryCode] = {};
			}
			if (ret[ace.subCategoryCode][ace.name] === undefined) {
				ret[ace.subCategoryCode][ace.name] = [];
			}
			ret[ace.subCategoryCode][ace.name].push(ace);
		});
		return Object.keys(ret)
			.map((key) => key as SubCategoryCode)
			.map((key) => ({
				subCategoryCode: key,
				entries: Object.keys(ret[key]).map((keyName) => ({
					name: keyName,
					activityCategoryEntries: ret[key][keyName],
				})),
			}));
	}
}
