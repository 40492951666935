import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as Papa from "papaparse";
import { EnergyCategory } from "../../../../../../../models/energyCategory";
import { EnergyType, energyTypeUnitToDatabaseStorage } from "../../../../../../../models/energyType";
import { ConsumptionEntry } from "../../../../../../../models/functionalDeclaration";
import { HelpSubject } from "../../../../../../help/help.component";
import { CsvHelpModalComponent } from "../csv-help-modal/csv-help-modal.component";

@Component({
	selector: "app-import-csv-modal",
	templateUrl: "./import-csv-modal.component.html",
	styleUrls: ["./import-csv-modal.component.scss"],
})
export class ImportCsvModalComponent {
	toAddValues: ConsumptionEntry[] = [];
	energy: EnergyType;
	category: EnergyCategory;
	hasRepartitionKey: boolean = false;
	partUmpteenth: number = 100;
	totalUmpteenth: number = 100;
	HelpSubject = HelpSubject;
	EnergyCategory = EnergyCategory;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			consumptionTable: ConsumptionEntry[];
			energy: EnergyType;
			category: EnergyCategory;
		},
		private dialogRef: MatDialogRef<ImportCsvModalComponent>,
		public dialog: MatDialog,
	) {
		this.category = data.category;
		this.energy = data.energy;
	}

	private static excelDateToDate(date: string): Date {
		if (date.match("^[0-9]+/[0-9]+/[0-9]+$")) {
			const dateExp = date.split("/");
			if (dateExp[2].length === 2) {
				dateExp[2] = `20${dateExp[2]}`;
			}
			date = `${dateExp[2]}-${dateExp[1]}-${dateExp[0]}`;
		}
		return new Date(date);
	}

	downloadFile(): void {
		const link = document.createElement("a");
		link.download = "declaration_consommation.csv";
		link.href = "assets/data/declaration_consommation.csv";
		link.click();
	}

	public parseCSV(files: FileList | null): void {
		if (files) {
			Papa.parse(files[0], {
				header: true,
				skipEmptyLines: true,
				complete: (result) => {
					for (const value of result.data) {
						const csvEntry = <CSVEntry>value;
						if (
							csvEntry.date_start !== undefined &&
							csvEntry.date_start !== "" &&
							csvEntry.date_end !== undefined &&
							csvEntry.date_end !== "" &&
							csvEntry.value !== undefined &&
							csvEntry.value !== ""
						) {
							const value = Number(csvEntry.value.replace(",", ".").replace(" ", ""));
							this.toAddValues.push(
								new ConsumptionEntry({
									date_start: ImportCsvModalComponent.excelDateToDate(csvEntry.date_start).getTime() / 1000,
									date_end: ImportCsvModalComponent.excelDateToDate(csvEntry.date_end).getTime() / 1000,
									value: energyTypeUnitToDatabaseStorage(this.energy, value),
									source: "csv",
									hasRepartitionKey: this.hasRepartitionKey,
									partUmpteenth: this.partUmpteenth,
									totalUmpteenth: this.totalUmpteenth,
								}),
							);
						}
					}
				},
			});
		}
	}

	saveCsvImport(): void {
		this.dialogRef.close(this.toAddValues);
	}

	public openImportTutorial(): void {
		this.dialog.open(CsvHelpModalComponent, { data: this.downloadFile });
	}
}

interface CSVEntry {
	date_start: string | undefined;
	date_end: string | undefined;
	value: string | undefined;
}
