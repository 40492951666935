import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

/**
 * Like forkJoin but wait for the observables sequentially in the same order as in the array.
 * @param observables The observables to complete.
 * @returns An observable that emits all values.
 */
export function sequentialJoin<T>(observables: Observable<T>[]): Observable<T[]> {
	return loop(observables, []);
}

function loop<T>(observables: Observable<T>[], results: T[]): Observable<T[]> {
	if (observables.length === 0) {
		return of(results);
	}

	const [head, ...tail] = observables;

	return head.pipe(switchMap((result) => loop(tail, [...results, result])));
}
