import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "assujettisementCase",
})
export class AssujettisementCasePipe implements PipeTransform {
	transform(declarationFunctional: FunctionalDeclaration): string {
		const infos = declarationFunctional[GreenKeys.KEY_INFOS];
		let everyBuildingAreTertiaryOnly = true;
		let anyIsMultiOccupied = false;
		infos.buildingInfos.forEach((building) => {
			if (!building.isOnlyTertiary) {
				everyBuildingAreTertiaryOnly = false;
			}
			if (building.notOwnedSurfaces.length === 0) {
				anyIsMultiOccupied = true;
			}
		});

		if (infos.otherBuildingOnSameSite) {
			return "3 - Ensemble de bâtiments situés sur une même unité foncière ou sur un même site hébergeant des activités foncières – Site";
		}
		if (
			infos.buildingInfos.length === 1 &&
			everyBuildingAreTertiaryOnly &&
			infos.buildingInfos[0].notOwnedSurfaces.length === 0
		) {
			return "1A - Bâtiment hébergeant exclusivement des activités tertiaires (avec ou sans activités accessoires) - Bâtiment totalement tertiaire - Une seule entité fonctionnelle (propriétaire occupant unique ou mono locataire)";
		}
		if (infos.buildingInfos.length === 1 && everyBuildingAreTertiaryOnly && anyIsMultiOccupied) {
			return "1B - Bâtiments hébergeants exclusivement des activités tertiaires (avec ou sans activités accessoires) - Bâtiment totalement tertiaire - Plusieurs entités fonctionnelles (multi occupations totalement tertiaire)";
		}
		if (infos.buildingInfos.length === 1 && !everyBuildingAreTertiaryOnly) {
			return "2 - Partie(s) de bâtiments à usage mixte hébergeant des activités tertiaires - Lot(s)";
		}
		return "3 - Ensemble de bâtiments situés sur une même unité foncière ou sur un même site hébergeant des activités foncières – Site";
	}
}
