import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;
import MAXIMUM_YEAR_INITIAL_DECLARATION = Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION;

@Pipe({
	name: "lockedFunctionalDeclarationsForYear",
})
export class LockedFunctionalDeclarationsForYearPipe implements PipeTransform {
	transform(
		functionalDeclarations: Immutable<FunctionalDeclaration[]>,
		year: number,
	): Immutable<FunctionalDeclaration[]> {
		const lockedFunctionalDeclarations: Immutable<FunctionalDeclaration>[] = [];

		if (year < ANNUAL_DECLARATION_STARTING_YEAR && year > MAXIMUM_YEAR_INITIAL_DECLARATION) {
			return lockedFunctionalDeclarations;
		}

		// An annual declaration is not considered locked if it's before the start of the entity's liability
		for (const functionalDeclaration of functionalDeclarations) {
			if (
				(year >= ANNUAL_DECLARATION_STARTING_YEAR &&
					!functionalDeclaration.years_token_used.includes(year) &&
					(!functionalDeclaration.first_year_declaration || year >= functionalDeclaration.first_year_declaration)) ||
				(year <= MAXIMUM_YEAR_INITIAL_DECLARATION && !functionalDeclaration.is_token_used)
			) {
				lockedFunctionalDeclarations.push(functionalDeclaration);
			}
		}

		return lockedFunctionalDeclarations;
	}
}
