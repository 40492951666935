<form id="payment-form">
	<div class="hidden danger" id="payment-message"></div>
	<div id="payment-element"></div>
	<div style="position: relative">
		<div [class.d-none]="!pageLoading" class="loader" style="width: 64px; height: 64px"></div>
	</div>
	<button
		(click)="sendPayment()"
		*ngIf="displayBuyButton"
		[class.btn-loading]="loading"
		class="btn-primary mt-1"
		id="submit"
		mat-flat-button
	>
		<span id="button-text">Acheter</span>
	</button>
</form>
