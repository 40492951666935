import { Immutable } from "immer";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { GreenKeys } from "@grs/greenkeys";
import { Nullable } from "./nullable";

type Filterable = Pick<FunctionalDeclaration, GreenKeys.KEY_NAME | GreenKeys.KEY_ADDRESS>;

export function compareStrings(a: Nullable<string>, b: Nullable<string>) {
	a = (a ?? "").trim().toUpperCase();
	b = (b ?? "").trim().toUpperCase();
	return a < b ? -1 : a > b ? 1 : 0;
}

export function compareFunctionalDeclaration(a: Immutable<Filterable>, b: Immutable<Filterable>) {
	let result = compareStrings(a.name, b.name);
	if (result) {
		return result;
	}
	result = compareStrings(a.address.cityName, b.address.cityName);
	if (result) {
		return result;
	}
	result = compareStrings(
		`${a.address.streetType} ${a.address.streetName}`,
		`${b.address.streetType} ${b.address.streetName}`,
	);
	if (result) {
		return result;
	}
	return compareStrings(a.address.houseNumber.toString(), b.address.houseNumber.toString());
}

export function compareByName(a: Immutable<{ name?: Nullable<string> }>, b: Immutable<{ name?: Nullable<string> }>) {
	return compareStrings(a.name, b.name);
}
