import { Pipe, PipeTransform } from "@angular/core";
import { ResourceType } from "../models/resource";
import { routeToResourceType, stringToRoute } from "../models/routes";

@Pipe({
	name: "isSegmentFunctional",
})
export class IsSegmentFunctionalPipe implements PipeTransform {
	transform(segment: string): boolean {
		const route = stringToRoute(segment);
		if (!route) {
			return false;
		}
		return routeToResourceType(route) === ResourceType.FunctionalDeclaration;
	}
}
