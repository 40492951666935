import { Immutable } from "immer";

export interface Range {
	start: number;
	end: number;
}

/**
 * This class exists to merge multiple ranges. For example, we want
 * to merge the ranges [1, 4] and [3, 7]. The two ranges overlap so this class
 * will optimize the union and give the range [1, 7]. If it can't merge two ranges,
 * it will keep both in memory and will try to optimize the ranges each time a new one is added.
 */
export class RangeUnion {
	/**
	 * All range inside are disjoint and sorted.
	 */
	private ranges: Range[] = [];

	addRange(rangeToAdd: Immutable<Range>) {
		if (rangeToAdd.start >= rangeToAdd.end) {
			return;
		}

		if (this.ranges.length === 0) {
			this.ranges.push({ ...rangeToAdd });
			return;
		}

		const firstRange = this.ranges[0];
		const lastRange = this.ranges[this.ranges.length - 1];

		if (rangeToAdd.end < firstRange.start) {
			this.ranges = [{ ...rangeToAdd }, ...this.ranges];
			return;
		}

		if (rangeToAdd.start > lastRange.end) {
			this.ranges.push({ ...rangeToAdd });
			return;
		}

		// If the range to add's start is earlier than our start, then we modify our start. It will be easier for the algorithm
		if (rangeToAdd.start < this.ranges[0].start) {
			this.ranges[0].start = rangeToAdd.start;
		}

		// Same but we check if the range to add's end is later than our end
		if (rangeToAdd.end > this.ranges[this.ranges.length - 1].end) {
			this.ranges[this.ranges.length - 1].end = rangeToAdd.end;
		}

		let rangeWhichContainsStart: number | null = this.ranges.findIndex(
			(range) => range.start <= rangeToAdd.start && rangeToAdd.start <= range.end,
		);
		// to make the compiler help the dev
		rangeWhichContainsStart = rangeWhichContainsStart === -1 ? null : rangeWhichContainsStart;
		let rangeWhichContainsEnd: number | null = this.ranges.findIndex(
			(range) => range.start <= rangeToAdd.end && rangeToAdd.end <= range.end,
		);
		rangeWhichContainsEnd = rangeWhichContainsEnd === -1 ? null : rangeWhichContainsEnd;

		// If there is a range A that contains the lower bound and a range B that contains the upper bound then we can merge
		// all the ranges between range A and range B including range A and B.
		if (rangeWhichContainsStart !== null && rangeWhichContainsEnd !== null) {
			const mergedRanged = {
				start: this.ranges[rangeWhichContainsStart].start,
				end: this.ranges[rangeWhichContainsEnd].end,
			};
			const firstRanges = this.ranges.slice(0, rangeWhichContainsStart);
			const endRanges = this.ranges.slice(rangeWhichContainsEnd + 1);
			this.ranges = [...firstRanges, mergedRanged, ...endRanges];
			return;
		}

		// If there is a range A that contains the lower bound, then we have to find a range next to the upper bound (indexRangeJustBeforeTheEndOfTheOneToAdd).
		// Then we can merge every ranges between range A and the range to add.
		if (rangeWhichContainsStart !== null) {
			const mergedRanged: Range = { start: this.ranges[rangeWhichContainsStart].start, end: rangeToAdd.end };
			const firstRanges = this.ranges.slice(0, rangeWhichContainsStart);

			const endRanges = this.ranges.slice(this.findIndexRangeJustBeforeTheEnd(rangeToAdd.end) + 1);
			this.ranges = [...firstRanges, mergedRanged, ...endRanges];
			return;
		}

		// If there is a range A that contains the upper bound, then we have to find a range next to the lower bound (indexRangeJustAfterTheStartOfTheOneToAdd).
		// Then we can merge every ranges between range A and the range to add.
		if (rangeWhichContainsEnd !== null) {
			const mergedRanged: Range = { start: rangeToAdd.start, end: this.ranges[rangeWhichContainsEnd].end };

			const firstRanges = this.ranges.slice(0, this.findIndexRangeJustAfterTheStart(rangeToAdd.start));
			const endRanges = this.ranges.slice(rangeWhichContainsEnd + 1);
			this.ranges = [...firstRanges, mergedRanged, ...endRanges];
			return;
		}

		// If no range contains the lower or upper bound, then we can ignore all the ranges that
		// are included in the range to add
		this.ranges = [
			...this.ranges.slice(0, this.findIndexRangeJustAfterTheStart(rangeToAdd.start)),
			{ ...rangeToAdd },
			...this.ranges.slice(this.findIndexRangeJustBeforeTheEnd(rangeToAdd.end) + 1),
		];
	}

	getRanges(): Immutable<Range[]> {
		return this.ranges;
	}

	/**
	 * Checks if the RangeUnion includes the provided range.
	 */
	includeRange({ start, end }: Range): boolean {
		for (const range of this.ranges) {
			if (end <= range.end) {
				if (range.start <= start) {
					return true;
				}

				return false;
			}
		}
		return false;
	}

	/**
	 * Must be use when `ranges` is not empty
	 */
	private findIndexRangeJustAfterTheStart(start: number): number {
		let indexRangeJustAfterTheStartOfTheOneToAdd = this.ranges.length - 1;
		for (let i = this.ranges.length - 1; i >= 0; --i) {
			const range = this.ranges[i];
			if (range.start < start) {
				break;
			}
			if (range.start < this.ranges[indexRangeJustAfterTheStartOfTheOneToAdd].start) {
				indexRangeJustAfterTheStartOfTheOneToAdd = i;
			}
		}
		return indexRangeJustAfterTheStartOfTheOneToAdd;
	}

	/**
	 * Must be use when `ranges` is not empty
	 */
	private findIndexRangeJustBeforeTheEnd(end: number): number {
		let indexRangeJustBeforeTheEndOfTheOneToAdd = 0;
		for (let i = 0; i < this.ranges.length; ++i) {
			const range = this.ranges[i];
			if (range.end > end) {
				break;
			}
			if (range.end > this.ranges[indexRangeJustBeforeTheEndOfTheOneToAdd].end) {
				indexRangeJustBeforeTheEndOfTheOneToAdd = i;
			}
		}
		return indexRangeJustBeforeTheEndOfTheOneToAdd;
	}
}
