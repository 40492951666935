import { AfterViewInit, Component } from "@angular/core";
import { Immutable } from "immer";
import { BehaviorSubject, of, zip } from "rxjs";
import { catchError, delay, switchMap, tap } from "rxjs/operators";
import { getAddressForEntitiesWithoutSiret } from "../../../../helpers/import-csv-helper";
import { sequentialJoin } from "../../../../helpers/sequentialJoin";
import { unwrap } from "../../../../helpers/unwrap";
import { Declaration } from "../../../../models/declaration";
import { Lazy } from "../../../../models/lazy";
import { Modal } from "../../../../models/modal";
import { AddressSearchService } from "../../../../services/address-search.service";
import { OperatApiService } from "../../../../services/operat-api.service";
import { OperatApiImportData } from "../operat-search-form/operat-search-form.component";
import { DeclarationService } from "../../../../services/declaration.service";
import { FunctionalDeclaration } from "../../../../models/functionalDeclaration";
import { GreenKeys } from "@grs/greenkeys";
import { DeclarationFunctionalService } from "../../../../services/declaration-functional.service";
import { getBestGoalReportFromCorrectedConsoAndYearList, getCorrectionDataList } from "../../../../helpers/conso";

type Input = {
	declarationService: DeclarationService;
	declarationFunctionalService: DeclarationFunctionalService;
	addressSearch: AddressSearchService;
	operatService: OperatApiService;
	dataToImport: OperatApiImportData;
	declaration: Immutable<Lazy<Declaration>>;
};

@Component({
	selector: "app-operat-import-loading",
	templateUrl: "./operat-import-loading.component.html",
	styleUrls: ["./operat-import-loading.component.scss"],
})
export class OperatImportLoadingComponent
	extends Modal<Input, Input, { declaration: Declaration.Api; errors: string[] }>
	implements AfterViewInit
{
	refErrors: string[] = [];
	currentImportIndex = new BehaviorSubject<number>(0);

	inputToState(input: Input): Input {
		return input;
	}

	handleReload(event: BeforeUnloadEvent) {
		event.preventDefault();
	}

	ngAfterViewInit(): void {
		window.addEventListener("beforeunload", this.handleReload);
	}

	close(output: { declaration: Declaration.Api; errors: string[] }) {
		window.removeEventListener("beforeunload", this.handleReload);
		super.close(output);
	}

	afterStateInit() {
		super.afterStateInit();
		const import$ = this.state.dataToImport.references.map((data) =>
			getAddressForEntitiesWithoutSiret(this.state.addressSearch, data).pipe(
				switchMap((importData) =>
					this.state.operatService.importEFAByAPI(unwrap(this.state.declaration.declaration_id), importData).pipe(
						catchError(() => {
							this.refErrors.push(data.refOperat);
							return of(undefined);
						}),
						switchMap((result) => {
							if (result) {
								const functionalDeclaration = FunctionalDeclaration.fromApi(result);
								if (functionalDeclaration[GreenKeys.KEY_INFOS].referenceYear) {
									return zip(
										of(functionalDeclaration),
										this.state.declarationFunctionalService
											.getDju$(unwrap(functionalDeclaration[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID]))
											.pipe(catchError(() => of({}))),
										this.state.declarationFunctionalService
											.getAbsoluteObjective$(unwrap(functionalDeclaration[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID]))
											.pipe(catchError(() => of({}))),
									).pipe(
										switchMap(([functionalDeclaration, dju, absoluteObjective]) => {
											if (!functionalDeclaration.infos.referenceYear) {
												return of(undefined);
											}

											const dataListForReferenceYear = getCorrectionDataList(
												functionalDeclaration,
												dju,
												absoluteObjective,
											).filter((report) => report.year === functionalDeclaration.infos.referenceYear);

											// Get report for the reference year given by OPERAT
											const report = getBestGoalReportFromCorrectedConsoAndYearList(
												dataListForReferenceYear,
												absoluteObjective,
												functionalDeclaration.first_year_declaration,
											);

											if (!report) {
												return of(undefined);
											}

											functionalDeclaration.infos = { ...functionalDeclaration.infos, ...report };

											return this.state.declarationFunctionalService.update$(
												FunctionalDeclaration.toApi(functionalDeclaration),
											);
										}),
									);
								}
							}

							return of(undefined);
						}),
					),
				),
				tap(() => this.currentImportIndex.next(this.currentImportIndex.value + 1)),
			),
		);
		sequentialJoin(import$)
			.pipe(
				delay(500),
				switchMap(() => this.state.declarationService.getNested$(unwrap(this.state.declaration.declaration_id))),
			)
			.subscribe((declaration) => {
				this.close({ declaration, errors: this.refErrors });
			});
	}
}
