import { Pipe, PipeTransform } from "@angular/core";
import { Identifier, IdRefEtatLess } from "../models/identifier";

@Pipe({
	name: "asRefEtatLess",
})
export class AsRefEtatLessPipe implements PipeTransform {
	transform(id: Identifier): IdRefEtatLess | undefined {
		return "refEtatLess" in id ? id : undefined;
	}
}
