import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgreementsComponent } from "./components/agreements/agreements.component";
import { AuthorizationViewComponent } from "./components/authorization-view/authorization-view.component";
import { ChangelogComponent } from "./components/changelog/changelog.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { BuyTokenComponent } from "./components/navigation/buy-token/buy-token.component";
import { MyDeclarationsComponent } from "./components/navigation/my-declarations/my-declarations.component";
import { StatsComponent } from "./components/navigation/my-declarations/stats/stats.component";
import { MyProfileComponent } from "./components/navigation/my-profile/my-profile.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { RepresentativeSettingsComponent } from "./components/navigation/representative-settings/representative-settings.component";
import { CsvComponent } from "./components/navigation/stepper/csv/csv.component";
import { AssetDeclarationComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/asset-declaration.component";
import { ConsoListComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/conso-list/conso-list.component";
import { ConsumptionTableComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/consumption-table.component";
import { FunctionalEntityStepperComponent } from "./components/navigation/stepper/functional-entity-stepper/functional-entity-stepper.component";
import { QualificationFormMapComponent } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form-map.component";
import { YearReferenceComponent } from "./components/navigation/stepper/functional-entity-stepper/year-reference/year-reference.component";
import { ReferenceYearSummaryComponent } from "./components/navigation/stepper/reference-year-summary/reference-year-summary.component";
import { SirenFormComponent } from "./components/navigation/stepper/siren-form/siren-form.component";
import { StepperComponent } from "./components/navigation/stepper/stepper.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RegisterComponent } from "./components/register/register.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { CanLoadNavigation } from "./helpers/can-load-navigation";
import { Route } from "./models/routes";

const routes: Routes = [
	{ path: `${Route.Login}/:token/:userId`, component: ResetPasswordComponent },
	{ path: Route.ForgotPassword, component: ForgotPasswordComponent },
	{ path: "", component: LoginComponent },
	{ path: Route.Register, component: RegisterComponent },
	{ path: Route.Changelog, component: ChangelogComponent },
	{ path: Route.Logout, component: LogoutComponent },
	{ path: Route.Authorizations, component: AuthorizationViewComponent },
	{ path: `:declarationId/${Route.Agreement}`, component: AgreementsComponent },
	{ path: "404", component: NotFoundComponent },
	{
		path: ":id",
		component: NavigationComponent,
		canActivate: [CanLoadNavigation],
		runGuardsAndResolvers: "always",
		children: [
			// all
			{ path: `:type/${Route.Stats}`, component: StatsComponent },
			// representative
			{ path: Route.MyDeclaration, component: MyDeclarationsComponent },
			{ path: Route.MyProfile, component: MyProfileComponent },
			{ path: Route.BuyToken, component: BuyTokenComponent },
			{ path: Route.RepresentativeSettings, component: RepresentativeSettingsComponent },
			{ path: "404", component: NotFoundComponent },
			// declaration
			{
				path: "",
				component: StepperComponent,
				children: [
					{ path: Route.Addresses, component: SirenFormComponent },
					{ path: Route.ConsoList, component: ConsoListComponent },
					{ path: Route.Reference, component: ReferenceYearSummaryComponent },
					{ path: Route.Csv, component: CsvComponent },
					// entity
					{
						path: "",
						component: FunctionalEntityStepperComponent,
						children: [
							{ path: Route.Qualification, component: QualificationFormMapComponent },
							{ path: Route.AssetDeclaration, component: AssetDeclarationComponent },
							{ path: Route.ConsoTable, component: ConsumptionTableComponent },
							{ path: Route.ReferenceEntity, component: YearReferenceComponent },
						],
					},
				],
			},
		],
	},
	{ path: "**", component: NotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
	providers: [CanLoadNavigation],
	exports: [RouterModule],
})
export class AppRoutingModule {}
