/* eslint-disable @typescript-eslint/no-namespace */
import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { produce, Immutable } from "immer";
import { BehaviorSubject, EMPTY, Observable, of, zip } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { myForkjoin, toObservable } from "src/app/helpers/to-observable";
import {
	IdOther,
	MarkerToTuple,
	newIdOther,
	newIdRna,
	newIdSiren,
	OwnerWithoutRefEtat,
	OwnerWithoutRefEtatMarker,
	Rna,
	Siren,
	Siret,
	wrapCode,
} from "src/app/models/identifier";
import { dialogOpen } from "src/app/models/modal";
import { Route } from "src/app/models/routes";
import { RnaService } from "src/app/services/rna.service";
import { SirenService } from "src/app/services/siren.service";
import { OPERAT_PARTICULIER } from "src/assets/text";
import { Nullable } from "../../helpers/nullable";
import { HelpSubject } from "../help/help.component";
import { IdentifierModalComponent } from "../identifier-modal/identifier-modal.component";

export namespace DeclarationsCreationComponent {
	export interface OwnerChoice {
		isOwner: true;
		owner: OwnerWithoutRefEtat;
		tenantSirets: Set<Siret>;
	}
	export interface OtherChoice {
		isOwner: false;
		isOther: true;
		idOther: IdOther;
	}
	export interface BasicChoice {
		isOwner: false;
		isOther: false;
		sirens: Set<Siren>;
		sirets: Set<Siret>;
		rnas: Set<Rna>;
	}
	export type AllChoice = OwnerChoice | OtherChoice | BasicChoice;
}

type OwnerChoice = DeclarationsCreationComponent.OwnerChoice;
type OtherChoice = DeclarationsCreationComponent.OtherChoice;
type BasicChoice = DeclarationsCreationComponent.BasicChoice;
type AllChoice = DeclarationsCreationComponent.AllChoice;

@Component({
	selector: "app-declarations-creation",
	templateUrl: "./declarations-creation.component.html",
	styleUrls: ["./declarations-creation.component.scss"],
})
export class DeclarationsCreationComponent implements OnDestroy {
	private ownerChoice: Immutable<OwnerChoice> = { isOwner: true, owner: newIdSiren(), tenantSirets: new Set() };
	private otherChoice: Immutable<OtherChoice> = { isOwner: false, isOther: true, idOther: { id: "", label: "" } };
	private basicChoice: Immutable<BasicChoice> = {
		isOwner: false,
		isOther: false,
		rnas: new Set(),
		sirens: new Set(),
		sirets: new Set(),
	};

	@Output() choiceChange = new EventEmitter<Immutable<AllChoice>>();
	@Output() isMandatedChange = new EventEmitter<boolean>();
	@Output() errorsChange = new EventEmitter<Immutable<Set<Siret | Rna | Siren>>>();

	readonly isMandated = new BehaviorSubject(false);
	readonly choice = new BehaviorSubject<Nullable<Immutable<AllChoice>>>(null);
	readonly errors = new BehaviorSubject<Immutable<Set<Siren | Siret | Rna>>>(new Set());
	// behaviorsubjet instead of observable to avoid ExpressionChangedAfterItHasBeenCheckedError
	readonly errorInOwnerSiren$ = new BehaviorSubject(false);

	private readonly subEmit = [
		this.choice.subscribe((choice) => {
			if (choice) {
				this.choiceChange.emit(choice);
			}
		}),
		this.isMandated.subscribe((isMandated) => {
			this.isMandatedChange.emit(isMandated);
		}),
		this.errors.subscribe((errors) => {
			this.errorsChange.emit(errors);
		}),
	] as const;

	toggleIsOwner(value: boolean) {
		this.choice.next(value ? this.ownerChoice : this.basicChoice);
	}

	toggleIsOther(choice: Immutable<OtherChoice | BasicChoice>) {
		this.choice.next(choice.isOther ? this.basicChoice : this.otherChoice);
	}

	editOwnerIdentifier(choice: Immutable<OwnerChoice>) {
		const { owner } = choice;

		const choices: MarkerToTuple<OwnerWithoutRefEtatMarker> = [
			"siren" in owner ? owner : newIdSiren(),
			"rna" in owner ? owner : newIdRna(),
			"id" in owner ? owner : newIdOther(),
		];

		dialogOpen(
			this.dialog,
			IdentifierModalComponent<OwnerWithoutRefEtatMarker>,
			{ choices, choice: "siren" in owner ? 0 : "rna" in owner ? 1 : 2 },
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((identifier) => {
				if (identifier) {
					this.choice.next(
						produce(choice, (choice) => {
							choice.owner = identifier;
						}),
					);
				}
			});
	}

	Route = Route;
	HelpSubject = HelpSubject;
	badFormatErrors = new BehaviorSubject<Immutable<Set<string>>>(new Set());

	constructor(
		private sirenService: SirenService,
		private rnaService: RnaService,
		private dialog: MatDialog,
	) {
		this.choice
			.pipe(
				switchMap((choice) =>
					choice && choice.isOwner && "siren" in choice.owner && choice.owner.siren !== ""
						? this.sirenService.doesSirenExist$(choice.owner.siren)
						: of(true),
				),
				map((noError) => !noError),
			)
			.subscribe(this.errorInOwnerSiren$);
	}

	ngOnDestroy(): void {
		this.subEmit.forEach((sub) => sub.unsubscribe());
	}

	toggleNotACompany(choice: Immutable<OwnerChoice>) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.owner =
					"id" in choice.owner && choice.owner.label === "PP" ? { siren: "" as Siren } : { id: "", label: "PP" };
			}),
		);
	}

	editOtherLabel(label: string, choice: Immutable<OtherChoice>) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.idOther.label = label;
			}),
		);
	}

	editOtherId(id: string, choice: Immutable<OtherChoice>) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.idOther.id = id;
			}),
		);
	}

	editOwnerId(choice: Immutable<OwnerChoice>, owner: IdOther) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.owner = owner;
			}),
		);
	}

	deleteSiren(choice: Immutable<BasicChoice>, siren: Siren) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.sirens.delete(siren);
			}),
		);
		this.errors.next(
			produce(this.errors.value, (errors) => {
				errors.delete(siren);
			}),
		);
	}

	deleteSiret(choice: Immutable<BasicChoice>, siret: Siret) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.sirets.delete(siret);
			}),
		);
		this.errors.next(
			produce(this.errors.value, (errors) => {
				errors.delete(siret);
			}),
		);
	}

	deleteRna(choice: Immutable<BasicChoice>, rna: Rna) {
		this.choice.next(
			produce(choice, (choice) => {
				choice.rnas.delete(rna);
			}),
		);
		this.errors.next(
			produce(this.errors.value, (errors) => {
				errors.delete(rna);
			}),
		);
	}

	checkErrors$({
		rnas,
		sirens,
		sirets,
	}: Immutable<{ rnas: Set<Rna>; sirens: Set<Siren>; sirets: Set<Siret> }>): Observable<undefined> {
		const errors = new Set<Siret | Siren | Rna>();

		const feedErrors = (code: Siren | Siret | Rna) => catchError(() => toObservable(() => errors.add(code)));

		const checkSirens$ =
			sirens.size > 0
				? this.sirenService.getMultipleUniteLegale$(Array.from(sirens)).pipe(
						tap((uniteLegales) => {
							Array.from(sirens).forEach((siren) => {
								if (!uniteLegales.find((uniteLegale) => uniteLegale.siren === siren)) {
									errors.add(siren);
								}
							});
						}),
					)
				: of(undefined);

		const checkSirets$ =
			sirets.size > 0
				? this.sirenService.getMultipleEtablissement$(Array.from(sirets)).pipe(
						tap((establishments) => {
							Array.from(sirets).forEach((siret) => {
								if (!establishments.find((establishment) => establishment.siret === siret)) {
									errors.add(siret);
								}
							});
						}),
					)
				: of(undefined);

		const checkRnas$ = myForkjoin(
			Array.from(rnas).map((rna) => this.rnaService.getAssociation$(rna).pipe(feedErrors(rna))),
		);

		return zip(checkSirens$, checkSirets$, checkRnas$).pipe(
			switchMap(() => {
				this.errors.next(errors);

				if (errors.size > 0) {
					return EMPTY;
				}

				return of(undefined);
			}),
		);
	}

	importList(choice: Immutable<BasicChoice>, list: ClipboardEvent | string) {
		const codes = splitCodes(list instanceof ClipboardEvent ? (list.clipboardData?.getData("text") ?? "") : list);

		const badFormatErrors = new Set<string>();

		const newChoice = produce(choice, (choice) => {
			for (let code of codes) {
				code = code.replace(" ", "").trim();
				switch (code.length) {
					case Siren.LENGTH:
						choice.sirens.add(wrapCode(code));
						break;
					case Siret.LENGTH:
						choice.sirets.add(wrapCode(code));
						break;
					case Rna.LENGTH:
						choice.rnas.add(wrapCode(code));
						break;
					default:
						badFormatErrors.add(code);
				}
			}
		});

		this.choice.next(newChoice);

		this.badFormatErrors.next(badFormatErrors);

		this.checkErrors$(newChoice).subscribe();
	}

	importListLandOwner(choice: Immutable<OwnerChoice>, list: ClipboardEvent | string) {
		const codes = splitCodes(list instanceof ClipboardEvent ? (list.clipboardData?.getData("text") ?? "") : list);

		const badFormatErrors = new Set<string>();

		const newChoice = produce(choice, (choice) => {
			for (const code of codes) {
				if (code.length === Siret.LENGTH) {
					choice.tenantSirets.add(wrapCode(code));
				} else {
					badFormatErrors.add(code);
				}
			}
		});

		this.choice.next(newChoice);

		this.badFormatErrors.next(badFormatErrors);

		this.checkErrors$({ rnas: new Set(), sirens: new Set(), sirets: newChoice.tenantSirets }).subscribe();
	}

	import(choice: Immutable<OwnerChoice | BasicChoice>, listInput: HTMLInputElement) {
		if (choice.isOwner) {
			this.importListLandOwner(choice, listInput.value);
		} else {
			this.importList(choice, listInput.value);
		}
		listInput.value = "";
	}

	deleteSiretFromTenant(choice: Immutable<OwnerChoice>, siret: Siret) {
		this.errors.next(
			produce(this.errors.value, (draft) => {
				draft.delete(siret);
			}),
		);
		this.choice.next(
			produce(choice, (choice) => {
				choice.tenantSirets.delete(siret);
			}),
		);
	}

	readonly OPERAT_PARTICULIER = OPERAT_PARTICULIER;

	static isChoiceValid(choice: Nullable<Immutable<DeclarationsCreationComponent.AllChoice>>) {
		if (!choice) {
			return false;
		}

		if (choice.isOwner) {
			const { tenantSirets, owner } = choice;
			const ownerValid =
				"siren" in owner
					? Siren.isValid(owner.siren)
					: "rna" in owner
						? Rna.isValid(owner.rna)
						: owner.id.length > 1 && owner.label.length > 1;
			return ownerValid && tenantSirets.size > 0;
		}

		if (choice.isOther) {
			return choice.idOther.id.length > 0 && choice.idOther.label.length > 0;
		}

		const { sirens, rnas, sirets } = choice;

		return sirens.size + rnas.size + sirets.size > 0;
	}
}

const SEPARATORS = [" ", ",", ";", ".", ":", "\n", "\r"];

function splitCodes(list: string): string[] {
	let codes = list.split("\t").filter((text) => text.length > 0);

	for (const separator of SEPARATORS) {
		codes = codes
			.map((text) => text.split(separator))
			.flat()
			.filter((text) => text.length > 0);
	}

	return codes;
}
