import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk } from "../../../../../models/agreementAsk";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { HolderType } from "../../../../../models/HolderType";

@Component({
	selector: "app-agreement-container",
	templateUrl: "./agreement-container.component.html",
	styleUrls: ["./agreement-container.component.scss"],
})
export class AgreementContainerComponent extends BaseComponent {
	@Input() color: string = "primary";
	@Input() icon: string = "";
	@Input() listTitle: string = "Agreements";
	@Input() agreements: Immutable<AgreementAsk[]> = [];
	@Input() canEdit: boolean = false;
	@Input() canAdd: boolean = false;
	@Input() showStatus: boolean = false;
	@Input() canGoAssetDeclaration: boolean = false;

	@Output() addAction = new EventEmitter<void>();
	@Output() editAction = new EventEmitter<{ agreement: Immutable<AgreementAsk>; index: number }>();
	@Output() goAssetDeclaration = new EventEmitter<void>();
	@Output() resendAction = new EventEmitter<Immutable<AgreementAsk>>();
	@Output() renewalAction = new EventEmitter<Immutable<AgreementAsk>>();

	readonly HolderType = HolderType;
}
