import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { EnergyCategory, energyCategoryLabel } from "../models/energyCategory";
import { EnergyConsumptions, FunctionalDeclaration, isMultiOccupation } from "../models/functionalDeclaration";
import { dateToTimestamp } from "../pipes/date-to-timestamp.pipe";
import { periodRange } from "../pipes/period-range.pipe";
import { sortConsumptions } from "../pipes/sort-consumptions.pipe";
import { sumCategorySurfacePerYear } from "../pipes/sum-category-surface-per-year.pipe";
import { YearForDateAndPeriodPipe } from "../pipes/year-for-date-and-period.pipe";
import { maxByKey, range } from "./array";
import * as immObject from "./immutable";
import { Nullable } from "./nullable";
import { ObjectivesHelper } from "./objectives";
import { RangeUnion } from "./RangeUnion";

const SAFETY_MARGIN = 60 * 60 * 48;
const yearForDateAndPeriod = new YearForDateAndPeriodPipe();

function ignoreConsumptionCategory(isSharingBuilding: boolean, category: string): boolean {
	return (
		category === EnergyCategory.USAGE ||
		(!isSharingBuilding && (category === EnergyCategory.DISTRIBUTED || category === EnergyCategory.COMMON))
	);
}

/**
 * Check if at least one consumption category is included
 * @param startPeriod
 * @param endPeriod
 * @param functionalDeclaration
 */
export function noConsumptionCategoryForPeriod(
	startPeriod: number,
	endPeriod: number,
	functionalDeclaration: Immutable<FunctionalDeclaration>,
): boolean {
	const minStart = Math.min(
		...Object.keys(functionalDeclaration.consumptions).flatMap((key) => {
			if (ignoreConsumptionCategory(isMultiOccupation(functionalDeclaration), key)) {
				return [];
			}
			return Object.values(functionalDeclaration.consumptions[key]).flatMap((entry) =>
				Object.values(entry.values).map((value) => value.start),
			);
		}),
	);

	let maxEnd = Math.max(
		...Object.keys(functionalDeclaration.consumptions).flatMap((key) => {
			if (ignoreConsumptionCategory(isMultiOccupation(functionalDeclaration), key)) {
				return [];
			}
			return Object.values(functionalDeclaration.consumptions[key]).flatMap((entry) =>
				Object.values(entry.values).map((value) => value.end ?? Infinity),
			);
		}),
	);

	// end value can´t be less than 0, so if maxEnd < 0 -> maxEnd === -Infinity -> no end value given to Math.max()
	if (maxEnd < 0) {
		// If no end value, use Infinity
		maxEnd = Infinity;
	}

	return endPeriod <= minStart || startPeriod >= maxEnd;
}

export function isHoleInConsumption(
	functionalDeclaration: Immutable<FunctionalDeclaration>,
	start: number,
	end: number,
): boolean {
	return getHoleInConsumption(functionalDeclaration, start, end) !== undefined;
}

export function getHoleInConsumption(
	functionalDeclaration: Immutable<FunctionalDeclaration>,
	start: number,
	end: number,
): string | undefined {
	const consumptions = functionalDeclaration.consumptions;
	for (const key of immObject.keys(consumptions)) {
		// If no buildingInfos (should only happen with imported EFA), ignores no category
		if (ignoreConsumptionCategory(isMultiOccupation(functionalDeclaration), key)) {
			continue;
		}
		const layer = consumptions[key];
		for (const secondLayer of immObject.values(layer)) {
			const secondLayerValues = immObject.values(secondLayer.values);
			if (secondLayerValues.length === 0) {
				return secondLayer.label;
			}
			for (const value of secondLayerValues) {
				if (hasHoleInConsumptionRow(value, start, end)) {
					return secondLayer.label;
				}
			}
		}
	}

	// If every conso category empty
	if (
		immObject
			.keys(consumptions)
			.filter((key) => !ignoreConsumptionCategory(isMultiOccupation(functionalDeclaration), key))
			.every((key) => {
				const layer = consumptions[key];
				return layer === undefined || Object.keys(layer).length === 0;
			})
	) {
		return `Consommations ${energyCategoryLabel(EnergyCategory.INDIVIDUAL)}`;
	}

	return undefined;
}

export function hasHoleInConsumptionRow(value: Immutable<EnergyConsumptions>, start: number, end: number): boolean {
	// if disjoint
	if (start >= (value.end ?? Infinity) || end <= value.start) {
		return false;
	}

	const actualStart = Math.max(value.start, start);
	const actualEnd = Math.min(value.end ?? Infinity, end);

	const union = new RangeUnion();

	// we merge all the energy ranges to check if there is a hole
	for (const energyRange of value.values.map(({ date_start, date_end }) => ({
		// we add the half of the safety margin at each bound
		// two ranges that have a space less or equal to the safe margin between them will merge
		start: date_start - SAFETY_MARGIN / 2,
		end: date_end + SAFETY_MARGIN / 2,
	}))) {
		union.addRange(energyRange);
	}

	// we remove a half of the safety margin at each bound for the safety margin to work with
	// the edges of the year
	return !union.includeRange({ start: actualStart + SAFETY_MARGIN / 2, end: actualEnd - SAFETY_MARGIN / 2 });
}

export const CURRENT_COMPLETE_YEAR = new Date().getFullYear() - 1;
const YEARS = range(Declaration.MINIMUM_YEAR + 1, new Date().getFullYear() + 1);

export interface GoalReport {
	absoluteObjective: number;
	referenceYear: number;
	referenceYearConsumption: number;
	relativeObjective: number;
}

export function getCorrectionDataList(
	functionalDeclaration: Immutable<FunctionalDeclaration>,
	dju: Immutable<{ [year: string]: number }>,
	absoluteObjectivePerYear: Immutable<{ [year: string]: number }>,
): {
	correctedConsumption: number;
	year: number;
	total: number;
	correction: number;
	isIncomplete: boolean;
	surface: number;
}[] {
	const surfacesPerYear = sumCategorySurfacePerYear(functionalDeclaration);
	const favoritePeriod = functionalDeclaration.infos.favoritePeriod;

	return YEARS.filter(
		(year) => !functionalDeclaration.first_year_declaration || year >= functionalDeclaration.first_year_declaration,
	).map((year) => {
		const period = periodRange(year, functionalDeclaration.infos.favoritePeriod);
		const consumption = sortConsumptions(functionalDeclaration, dju, year, favoritePeriod);
		const isIncomplete =
			isHoleInConsumption(functionalDeclaration, dateToTimestamp(period.start), dateToTimestamp(period.end)) ||
			noCategoryForYear(functionalDeclaration, year) ||
			surfacesPerYear[year] === 0;

		if (consumption) {
			let correctedConsumption =
				(consumption.total - consumption.deduct + consumption.dju) / Math.max(surfacesPerYear[year], 1);
			let correction = consumption.dju;
			if (
				absoluteObjectivePerYear &&
				absoluteObjectivePerYear[year] &&
				absoluteObjectivePerYear[CURRENT_COMPLETE_YEAR]
			) {
				const addedCorrection =
					correctedConsumption * (absoluteObjectivePerYear[CURRENT_COMPLETE_YEAR] / absoluteObjectivePerYear[year]) -
					correctedConsumption;
				correctedConsumption += addedCorrection;
				correction += addedCorrection * Math.max(surfacesPerYear[year], 1);
			}
			return {
				correctedConsumption,
				year,
				total: consumption.total - consumption.deduct,
				correction,
				isIncomplete,
				surface: surfacesPerYear[year],
			};
		}

		return {
			correctedConsumption: 0,
			year,
			total: 0,
			correction: 0,
			isIncomplete: true,
			surface: surfacesPerYear[year],
		};
	});
}

export function getBestGoalReportFromCorrectedConsoAndYearList(
	list: Immutable<
		{
			correctedConsumption: number;
			year: number;
			isIncomplete: boolean;
		}[]
	>,
	absoluteObjectivePerYear: Immutable<{ [year: string]: number }> = {},
	declarationStartingYear: Nullable<number>,
): GoalReport | undefined {
	let highestConsoAndYear;

	if (declarationStartingYear) {
		const filteredConso = list
			.filter(({ year, isIncomplete }) => year >= declarationStartingYear && !isIncomplete)
			.sort((a, b) => a.year - b.year);
		highestConsoAndYear = filteredConso.length > 0 ? filteredConso[0] : undefined;
	} else {
		highestConsoAndYear = maxByKey(
			list.filter(({ year, isIncomplete }) => year < 2020 && !isIncomplete),
			({ correctedConsumption }) => correctedConsumption,
		);
	}

	return highestConsoAndYear !== undefined && highestConsoAndYear.correctedConsumption > 0
		? {
				absoluteObjective: ObjectivesHelper.getCurrentAbsoluteObjective(
					absoluteObjectivePerYear,
					CURRENT_COMPLETE_YEAR,
				),
				referenceYear: highestConsoAndYear.year,
				referenceYearConsumption: highestConsoAndYear.correctedConsumption,
				relativeObjective: highestConsoAndYear.correctedConsumption * 0.6,
			}
		: undefined;
}

export function getBestGoalReportFromFunctionalDeclaration(
	functionalDeclaration: Immutable<FunctionalDeclaration>,
	dju: Immutable<{ [year: string]: number }> = {},
	absoluteObjectivePerYear: Immutable<{ [year: string]: number }> = {},
): GoalReport | undefined {
	return getBestGoalReportFromCorrectedConsoAndYearList(
		getCorrectionDataList(functionalDeclaration, dju, absoluteObjectivePerYear),
		absoluteObjectivePerYear,
		functionalDeclaration.first_year_declaration,
	);
}

/**
 * Check if there's at least one asset category concerning given year or period
 * @param functionalDeclaration
 * @param year
 */
export function noCategoryForYear(
	functionalDeclaration: Immutable<FunctionalDeclaration>,
	year: number | "REFERENCE",
): boolean {
	let usedYear = -1;
	if (year === "REFERENCE") {
		// If REFERENCE, check for EFA reference year
		if (!functionalDeclaration.infos.referenceYear) {
			return true;
		}
		usedYear = functionalDeclaration.infos.referenceYear;
	} else {
		usedYear = year;
	}

	// Check if all values are true (no category available for this year)
	if (functionalDeclaration.infos.asset.categories.length === 0) {
		return true;
	}

	const allowedTimehole = 48 * 3600;
	const mergeDatesCategories: { start: number; end: number }[] = [];
	functionalDeclaration.infos.asset.categories.forEach((category) => {
		let added = false;
		const categoryEnd = category.end ? category.end : Infinity;
		mergeDatesCategories.forEach((dateGroup, index) => {
			// If dateGroup and category overlap or touch, merge their dates
			if (
				(dateGroup.start <= category.start && dateGroup.end >= category.start) ||
				(category.start <= dateGroup.start && categoryEnd >= dateGroup.end) ||
				Math.abs(dateGroup.start - categoryEnd) < allowedTimehole ||
				Math.abs(dateGroup.end - category.start) < allowedTimehole
			) {
				mergeDatesCategories[index] = {
					start: Math.min(dateGroup.start, category.start),
					end: Math.max(dateGroup.end, categoryEnd),
				};
				added = true;
				return;
			}
		});

		if (!added) {
			mergeDatesCategories.push({ start: category.start, end: categoryEnd });
		}
	});

	return mergeDatesCategories
		.map(({ start, end }) => ({ start, end: end === Infinity ? null : end }))
		.every((categorie) => {
			const startYear = yearForDateAndPeriod.transform(
				categorie.start,
				functionalDeclaration.infos.favoritePeriod ?? 0,
			);
			const endYear = categorie.end
				? yearForDateAndPeriod.transform(categorie.end, functionalDeclaration.infos.favoritePeriod ?? 0)
				: null;

			const startYearPeriod = periodRange(startYear, functionalDeclaration.infos.favoritePeriod ?? 0);
			const endYearPeriod = endYear ? periodRange(endYear, functionalDeclaration.infos.favoritePeriod ?? 0) : null;

			return (
				usedYear < startYear || // Categ start after year
				(startYear === usedYear && categorie.start > dateToTimestamp(startYearPeriod.start) + allowedTimehole) || // Categ not complete for year
				(endYear &&
					endYearPeriod &&
					categorie.end && // if categorie.end !== null, endYear and endYearPeriod also !== null
					(usedYear > endYear || // categ end before year
						(endYear === usedYear && categorie.end < dateToTimestamp(endYearPeriod.end) - allowedTimehole))) // categ not complete for year
			);
		});
}
