import { Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { EMPTY, of, Subscription } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { DialogComponent, HelpSubject } from "src/app/components/help/help.component";
import { contains } from "src/app/helpers/string";
import { unwrap } from "src/app/helpers/unwrap";
import { AddressInfo } from "src/app/models/address";

import { BaseComponent } from "src/app/models/base-component.directive";
import { Declaration } from "src/app/models/declaration";
import { getIdentifierStringFromEfaStatus } from "src/app/models/efaStatus";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { HolderType } from "src/app/models/HolderType";
import { FunctionalDeclarationId } from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { dialogOpen } from "src/app/models/modal";
import { RouteDealer } from "src/app/models/routes";
import { AgreementService } from "src/app/services/agreement.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { DeclarationService } from "src/app/services/declaration.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { canAskForAgreementRenewal } from "../../../../../../helpers/agreements-helpers";
import { AgreementAsk } from "../../../../../../models/agreementAsk";
import { OnCoolodwnPipe } from "../../../../../../pipes/on-coolodwn.pipe";

@Component({
	selector: "app-conso-list",
	templateUrl: "./conso-list.component.html",
	styleUrls: ["./conso-list.component.scss"],
})
export class ConsoListComponent extends BaseComponent implements OnDestroy {
	readonly AMOUNT_PER_PAGE = 10;
	page = 1;
	maxPage = 1;
	search = "";

	today = new Date();
	// we have to add one to the month because somehow january is 0
	todayString = this.today.getFullYear() + "-" + (this.today.getUTCMonth() + 1) + "-" + this.today.getUTCDate();
	grdfMaintenanceEnd = "2022-11-30";

	declarationsFunctional: Immutable<FunctionalDeclaration[]> = [];
	filteredDeclarationsFunctional: Immutable<FunctionalDeclaration[]> = [];
	declarationsFunctionalToShow: Immutable<FunctionalDeclaration[]> = [];

	hasPreferedPeriod = false;

	HolderTypeEnum = HolderType;

	loading = true;

	subscribe: Subscription | undefined = undefined;

	canSendOne: boolean = false;

	functionalEntities: FunctionalDeclaration[] = [];
	readonly ENERGY_TYPES = ["FIOUL", "BRIQUETTES"];

	RouteDealer = RouteDealer;

	@ViewChild("modalRenewal") modalRenewal!: TemplateRef<MatDialog>;

	constructor(
		public router: Router,
		private agreementSaver: AgreementService,
		private declarationService: DeclarationService,
		public declarationState: DeclarationStateService,
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
	) {
		super();
		functionalDeclarationState.clear();
		this.sub(
			declarationState.get$.pipe(switchMap((declaration) => (declaration ? of(declaration.value) : EMPTY))),
			(declaration) => this.refresh(declaration),
		);
	}

	refresh(declaration: Immutable<Lazy<Declaration>>) {
		if (declaration.declaration_id) {
			this.canSendOne = false;
			this.subscribe = this.declarationService
				.getNested$(declaration.declaration_id)
				.pipe(map(Declaration.fromApi))
				.subscribe((declaration) => {
					if (declaration.declarations_functional.length === 0) {
						this.router.navigate(RouteDealer.addresses(unwrap(declaration.declaration_id)));
						return;
					}
					this.declarationsFunctional = declaration.declarations_functional.filter((functionalDeclaration) => {
						return (
							functionalDeclaration[GreenKeys.KEY_AGREEMENT_ASKS].filter(
								(ask) => ask.type === "PCE" || ask.type === "PRM",
							).length > 0
						);
					});
					const onCooldownPipe = new OnCoolodwnPipe();
					this.declarationsFunctional.forEach((declarationFunctional) => {
						declarationFunctional.agreement_asks.forEach((agreementAsk) => {
							if (
								!agreementAsk.is_accepted &&
								(!agreementAsk.email_sent_at || !onCooldownPipe.transform(agreementAsk))
							) {
								this.canSendOne = true;
							}
						});
					});
					this.loading = false;
					if (this.declarationsFunctional.length === 0) {
						this.goToTable(declaration);
					}
					this.filteredDeclarationsFunctional = this.declarationsFunctional;
					this.maxPage = Math.ceil(this.declarationsFunctional.length / this.AMOUNT_PER_PAGE);
					this.setPage(1);
				});
		}
	}

	setPage(page: number) {
		this.page = page;
		this.declarationsFunctionalToShow = this.filteredDeclarationsFunctional.slice(
			(page - 1) * this.AMOUNT_PER_PAGE,
			page * this.AMOUNT_PER_PAGE,
		);
	}

	updateSearch() {
		const searchString = this.search.toLowerCase();
		this.filteredDeclarationsFunctional = this.declarationsFunctional.filter((declarationFunctional) => {
			const { efa_status } = declarationFunctional;
			return (
				contains(declarationFunctional[GreenKeys.KEY_NAME], searchString) ||
				contains(getIdentifierStringFromEfaStatus(efa_status), searchString) ||
				contains(AddressInfo.toString(declarationFunctional[GreenKeys.KEY_ADDRESS]), searchString)
			);
		});
		this.maxPage = Math.ceil(this.filteredDeclarationsFunctional.length / this.AMOUNT_PER_PAGE);
		this.setPage(1);
	}

	openHistoryModal() {
		dialogOpen(this.dialog, DialogComponent, { subject: HelpSubject.LongHistory, argument: "" }, { panelClass: "p-0" });
	}

	goToTable(declaration: Immutable<Lazy<Declaration>>) {
		this.activatedRoute.queryParamMap
			.pipe(
				first(),
				map((params) => {
					const from = params.get("from");
					// I do this in case the user has deleted the entity whose id is equal to from
					return declaration.declarations_functional.find(
						({ declaration_functional_id }) => declaration_functional_id === from,
					)?.declaration_functional_id;
				}),
			)
			.subscribe((from) => {
				const id = unwrap(declaration.declarations_functional[0].declaration_functional_id);

				if (from) {
					this.router.navigate(RouteDealer.consoTable(from as FunctionalDeclarationId));
				} else {
					this.router.navigate(RouteDealer.consoTable(id));
				}
			});
	}

	public sendAgreementInvite(declaration: Immutable<Lazy<Declaration>>, id?: string) {
		this.functionalDeclarationState.clear();
		const body = id ? [id] : [];
		const declarationId = declaration.declaration_id;
		if (declarationId) {
			this.agreementSaver.sendAgreementsInvites(declarationId, body).subscribe(() => {
				this.refresh(declaration);
			});
		}
	}

	public sendRenewalAgreement(declaration: Immutable<Lazy<Declaration>>, ...ids: string[]) {
		this.functionalDeclarationState.clear();
		const declarationId = declaration.declaration_id;
		if (declarationId) {
			this.agreementSaver.sendRenewal$(declarationId, ids).subscribe(() => {
				this.refresh(declaration);
			});
		}
	}

	openRenewalModal() {
		this.dialog.open(this.modalRenewal, { panelClass: "p-0" });
	}

	getAvailableAgreementsForRenewal(type: string = "PRM"): Immutable<AgreementAsk>[] {
		return this.filteredDeclarationsFunctional
			.flatMap((fd) => fd.agreement_asks)
			.filter((agreement) => agreement.type === type && canAskForAgreementRenewal(agreement));
	}

	sendAvailableRenewals() {
		const availableAgreements = this.getAvailableAgreementsForRenewal().flatMap((agreement) =>
			agreement.agreement_ask_id ? [agreement.agreement_ask_id] : [],
		);
		availableAgreements.push(
			...this.getAvailableAgreementsForRenewal("PCE").flatMap((agreement) =>
				agreement.agreement_ask_id ? [agreement.agreement_ask_id] : [],
			),
		);

		this.declarationState.get$.subscribe((declaration) => {
			if (declaration) {
				this.sendRenewalAgreement(declaration.value, ...availableAgreements);
			}
		});
	}
}
