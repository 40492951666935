import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { AddressInfo } from "../models/address";
import { FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "addressList",
})
export class AddressListPipe implements PipeTransform {
	transform(entities: FunctionalDeclaration[]): string {
		return entities
			.map((entity) => entity.name + " - " + AddressInfo.toString(entity[GreenKeys.KEY_ADDRESS]))
			.join("\n");
	}
}
