import { Pipe, PipeTransform } from "@angular/core";
import { Identifier, IdSiret } from "../models/identifier";

@Pipe({
	name: "asSiret",
})
export class AsSiretPipe implements PipeTransform {
	transform(id: Identifier): IdSiret | undefined {
		return "siret" in id ? id : undefined;
	}
}
