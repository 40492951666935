import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import * as turf from "@turf/turf";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { produce, castDraft, Immutable } from "immer";
import maplibregl from "maplibre-gl";
import { BehaviorSubject, defer, EMPTY, Observable, of, zip } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { HelpSubject } from "src/app/components/help/help.component";
import { sequentialJoin } from "src/app/helpers/sequentialJoin";
import { mapJumpTo$, toObservable } from "src/app/helpers/to-observable";
import { unwrap } from "src/app/helpers/unwrap";
import { BaseComponent } from "src/app/models/base-component.directive";
import {
	BuildingFeature,
	BuildingInfo,
	cloneFunctionalDeclaration,
	EntityInfo,
	FunctionalDeclaration,
	SurfaceTertiaire,
	toBuildingFeature,
} from "src/app/models/functionalDeclaration";
import { FunctionalDeclarationId, ParcelId } from "src/app/models/ids";
import { dialogOpen } from "src/app/models/modal";
import {
	customParcelFeature,
	getOldParcelId,
	getParcelId,
	ParcelFeature,
	toParcelFeature,
} from "src/app/models/Parcel";
import { BuildingId } from "src/app/models/parcelAndBuilding";
import { RouteDealer } from "src/app/models/routes";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import * as uuid from "uuid";
import { intToReason, Reason } from "src/app/helpers/hasDoneStep";
import { DeclarationFunctionalService } from "src/app/services/declaration-functional.service";
import { TourStateService } from "src/app/services/tour-state.service";
import { BuildingModalComponent } from "../building-modal/building-modal.component";
import { ParcelModalComponent } from "../parcel-modal/parcel-modal.component";
import { SurfaceModalComponent } from "../surface-modal/surface-modal.component";
import { restoreId$ } from "./restore";
import { StandaloneProgressModalComponent } from "src/app/components/progress-modal/progress-modal.component";
import { isAssujetti } from "src/app/pipes/is-assujetti.pipe";

@Component({
	selector: "app-qualification-form-map",
	templateUrl: "./qualification-form-map.component.html",
	styleUrls: ["./qualification-form-map.component.scss"],
})
export class QualificationFormMapComponent extends BaseComponent implements OnDestroy {
	mapHidden = false;

	Reason = Reason;

	updating: boolean = false;

	isSelectingBuilding = false;

	HelpSubject = HelpSubject;

	/**
	 * If we don't use this we keep replacing the object with the one given by the server
	 * and we don't need to do that.
	 */
	skipUpdateFunctionalDeclaration: Immutable<FunctionalDeclaration> | null = null;
	freezeHover = false;
	parcelError?: Reason;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public functionalDeclarationState: FunctionalDeclarationStateService,
		public functionalDeclarationService: DeclarationFunctionalService,
		private snackBar: MatSnackBar,
		private tourState: TourStateService,
	) {
		super();
		this.tourState.initialize([
			{
				anchorId: "map",
				content: "Cliquez sur la carte interactive afin de sélectionner une parcelle cadastrale.",
				title: "Sélectionnez vos parcelles",
				nextOnAnchorClick: true,
			},
			{
				anchorId: "select-building",
				content: "Cliquez ici pour changer le mode de sélection.",
				title: "Passez en mode sélection de bâtiment",
				nextOnAnchorClick: true,
			},
			{
				anchorId: "map",
				content: "Cliquez sur la carte interactive afin de sélectionner un bâtiment.",
				title: "Sélectionnez votre bâtiment",
			},
		]);
		this.sub(this.activatedRoute.queryParams, (params) => {
			this.parcelError = intToReason(params.error);

			if (this.parcelError !== undefined || this.parcelError !== null) {
				setTimeout(() => {
					const parcelErrorDiv = document.getElementById("stepErrorDiv");
					if (parcelErrorDiv) {
						parcelErrorDiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
						parcelErrorDiv.classList.add("zoom");
						setTimeout(() => {
							parcelErrorDiv.classList.remove("zoom");
						}, 2000);
					}
				});
			}
		});
		this.sub(
			functionalDeclarationState.get$.pipe(
				switchMap((nullable) => (nullable ? of(nullable) : EMPTY)),
				switchMap(({ value, origin }) =>
					origin === "refresh" ||
					value.declaration_functional_id !== this.skipUpdateFunctionalDeclaration?.declaration_functional_id
						? of(value)
						: EMPTY,
				),
				map(cloneFunctionalDeclaration),
			),
			(functionalDeclaration) => {
				this.skipUpdateFunctionalDeclaration = functionalDeclaration;
			},
		);

		this.sub(this.tourState.start$, () => {
			this.isSelectingBuilding = false;
		});
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		// force reset the tour
		this.tourState.resetTour();
	}

	fireUpdate(clone: Immutable<FunctionalDeclaration>) {
		if (isAssujetti(clone)) {
			clone = produce(clone, (draft) => {
				draft.not_in_csv = false;
			});
		}
		this.skipUpdateFunctionalDeclaration = clone;
		this.functionalDeclarationService.update(clone);
	}

	removeBuilding(functionalDeclaration: Immutable<FunctionalDeclaration>, id: BuildingId) {
		const clone = produce(functionalDeclaration, (draft) => {
			const info = draft.infos;
			info.buildingInfos = info.buildingInfos.filter(
				({ building }) => (building.type === "Feature" ? building.id : building.features[0]?.id) !== id,
			);
		});

		this.fireUpdate(clone);
	}

	addBuilding(functionalDeclaration: Immutable<FunctionalDeclaration>, building?: BuildingFeature) {
		const buildingInfo = toBuildingInfo(building);
		this.tourState.end();

		const { notOwned, owned } = EntityInfo.assujettiArea(functionalDeclaration.infos);

		// I use defer to execute dialogOpen when we subscribe to the observable
		const defaultModal$ = defer(() =>
			dialogOpen(
				this.dialog,
				BuildingModalComponent,
				{
					building: buildingInfo,
					surfaceAssujettiOwned: owned,
					surfaceAssujettiNotOwned: notOwned,
					adding: true,
				},
				{ panelClass: "p-0" },
			)
				.afterClosed()
				.pipe(
					tap((output) => {
						if (!output) {
							return;
						}

						switch (output.type) {
							case BuildingModalComponent.OutputType.Save: {
								const clone = produce(functionalDeclaration, (draft) => {
									draft.infos.buildingInfos.push(output.info);
								});

								this.fireUpdate(clone);
								break;
							}
							default:
						}
					}),
				),
		);

		// we try to detect an invisible building
		// if we detect an invisible building, we give the user the option to restore the building
		// if they do not want to restore the building, we open the default modal
		// if no invisible building is detected, we open the default modal
		if (building) {
			const doesExist = functionalDeclaration.infos.buildingInfos
				.flatMap(({ building }) => (building.type === "Feature" ? [building] : []))
				.some(({ id }) => id === building.id);

			if (!doesExist) {
				const similarBuildingIndex = functionalDeclaration.infos.buildingInfos.findIndex((buildingInfo) => {
					if (buildingInfo.building.type === "FeatureCollection" || buildingInfo.building.geometry.type === "Point") {
						return false;
					}

					const intersection = turf.intersect(
						turf.helpers.featureCollection([
							turf.helpers.feature(buildingInfo.building.geometry as Polygon | MultiPolygon),
							turf.helpers.feature(building.geometry as Polygon | MultiPolygon),
						]),
					);

					if (intersection === null) {
						return false;
					}

					return turf.area(intersection) / turf.area(building.geometry) > 0.9;
				});

				if (similarBuildingIndex >= 0) {
					dialogOpen(
						this.dialog,
						ConfirmationModalComponent,
						{
							title: "Bâtiment invisible détecté",
							description:
								"Il semble que vous avez cliqué sur un bâtiment invisible. Avez-vous cliqué sur " +
								functionalDeclaration.infos.buildingInfos[similarBuildingIndex].label[0] +
								" ?",
						},
						{ panelClass: "p-0" },
					)
						.afterClosed()
						.pipe(
							switchMap((accept) =>
								accept === undefined ? EMPTY : accept ? of(undefined) : defaultModal$.pipe(switchMap(() => EMPTY)),
							),
						)
						.subscribe(() => {
							this.fireUpdate(
								produce(functionalDeclaration, (draft) => {
									const { building: similarBuilding } = draft.infos.buildingInfos[similarBuildingIndex];
									if (similarBuilding.type === "FeatureCollection") {
										throw new Error("addBuilding :: Impossible");
									}
									similarBuilding.id = building.id;
								}),
							);
						});
					return;
				}
			}
		}

		defaultModal$.subscribe();
	}

	restoreAll(functionalDeclaration: Immutable<FunctionalDeclaration>, cadastreMap: maplibregl.Map) {
		const parcels = functionalDeclaration.infos.parcelles.flatMap((parcel, index) => {
			if (parcel.type === "FeatureCollection") {
				return [];
			}

			const { geometry } = parcel;

			if (geometry.type === "Point") {
				return [];
			}

			return [{ parcel: { ...parcel, geometry }, index }];
		});

		const buildings = functionalDeclaration.infos.buildingInfos.flatMap(({ building }, index) => {
			if (building.type === "FeatureCollection") {
				return [];
			}

			const { geometry } = building;

			if (geometry.type === "Point") {
				return [];
			}

			return [{ building: { ...building, geometry }, index }];
		});

		const progressSubject = new BehaviorSubject(0);

		const hijackRefresh = (event: BeforeUnloadEvent) => {
			event.returnValue = " ";
		};

		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Restauration des bâtiments/parcelles invisibles",
				description:
					"Le configurateur utilise les données cadastrales ouvertes du gouvernement. " +
					"Cependant, quand ces données sont mises à jour, les contours de vos bâtiments et parcelles peuvent disparaître. " +
					"Si vos sélections ne sont plus affichées, le configurateur peut les restaurer. " +
					"Cela prend un court instant. Voulez-vous continuer ?",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.pipe(
				filter((accept) => accept === true),
				tap(() => {
					dialogOpen(
						this.dialog,
						StandaloneProgressModalComponent,
						{
							maxValue: parcels.length + buildings.length,
							value$: progressSubject,
						},
						{ disableClose: true },
					);
				}),
				tap(() => {
					window.addEventListener("beforeunload", hijackRefresh);
					freezeMap(cadastreMap);
					this.freezeHover = true;
				}),
				switchMap(() =>
					sequentialJoin(
						parcels.map((parcelAndIndex) =>
							zip(
								of(parcelAndIndex),
								restoreId$(cadastreMap, parcelAndIndex.parcel, "parcelles-fill").pipe(
									tap(() => progressSubject.next(progressSubject.value + 1)),
								),
							),
						),
					),
				),
				// I use a zip(of()...) to execute sequentially the two observables
				switchMap((restoredParcels) =>
					zip(
						of(restoredParcels),
						sequentialJoin(
							buildings.map((buildingAndIndex) =>
								zip(
									of(buildingAndIndex),
									restoreId$(cadastreMap, buildingAndIndex.building, "batiments-fill").pipe(
										tap(() => progressSubject.next(progressSubject.value + 1)),
									),
								),
							),
						),
					),
				),
			)
			.subscribe(([restoredParcels, restoredBuildings]) => {
				unfreezeMap(cadastreMap);
				this.freezeHover = false;
				window.removeEventListener("beforeunload", hijackRefresh);

				this.fireUpdate(
					produce(functionalDeclaration, (draft) => {
						const { infos } = draft;

						for (const [{ parcel, index }, restoredId] of restoredParcels) {
							infos.parcelles[index] = castDraft({ ...parcel, id: restoredId });
						}

						for (const [{ building, index }, restoredId] of restoredBuildings) {
							infos.buildingInfos[index].building = castDraft({ ...building, id: restoredId });
						}
					}),
				);
			});
	}

	editBuilding(functionalDeclaration: Immutable<FunctionalDeclaration>, buildingIndex: number) {
		const { notOwned, owned } = EntityInfo.assujettiArea(functionalDeclaration.infos);
		dialogOpen(
			this.dialog,
			BuildingModalComponent,
			{
				building: functionalDeclaration.infos.buildingInfos[buildingIndex],
				canDelete: true,
				surfaceAssujettiOwned: owned,
				surfaceAssujettiNotOwned: notOwned,
				adding: false,
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (!output) {
					return;
				}

				if (output.type === BuildingModalComponent.OutputType.Save) {
					const clone = produce(functionalDeclaration, (draft) => {
						draft.infos.buildingInfos[buildingIndex] = output.info;
					});

					this.fireUpdate(clone);
					return;
				}

				if (output.type === BuildingModalComponent.OutputType.Delete) {
					const clone = produce(functionalDeclaration, (draft) => {
						draft.infos.buildingInfos.splice(buildingIndex, 1);
					});

					this.fireUpdate(clone);
				}
			});
	}

	removeParcelle(functionalDeclaration: Immutable<FunctionalDeclaration>, id: string, snackbar = false) {
		const clone = produce(functionalDeclaration, (draft) => {
			const info = draft.infos;

			// backward compatibility
			const parcelToRemove = info.parcelles.find((parcel) => {
				if (parcel.type === "FeatureCollection") {
					return getOldParcelId(parcel) === id;
				} else {
					return parcel.properties.id === id;
				}
			});

			if (parcelToRemove !== undefined) {
				if (parcelToRemove.type === "FeatureCollection") {
					if (parcelToRemove.features[0].id === info.asset.mainParcel) {
						info.asset.mainParcel = undefined;
					}
				} else if (parcelToRemove.properties.id === info.asset.mainParcel) {
					info.asset.mainParcel = undefined;
				}
			}

			info.parcelles = info.parcelles.filter((parcelle) => parcelle !== parcelToRemove);
			// If entity bought, mainParcel unset and some parcel are still selected, select first parcelle as main
			if (draft.is_token_used && !info.asset.mainParcel && info.parcelles.length > 0) {
				info.asset.mainParcel = getParcelId(info.parcelles[0]);
			}
		});

		if (snackbar) {
			this.snackBar.open("Parcelle supprimée !", undefined, { verticalPosition: "top", duration: 1000 });
		}

		this.fireUpdate(clone);
	}

	removeParcelleWithoutId(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		parcel: Immutable<ParcelFeature | FeatureCollection>,
	) {
		const id = parcel.type === "FeatureCollection" ? getOldParcelId(parcel) : parcel.properties.id;

		this.removeParcelle(functionalDeclaration, id);
	}

	removeParcelleByIndex(functionalDeclaration: Immutable<FunctionalDeclaration>, index: number) {
		const clone = produce(functionalDeclaration, (draft) => {
			const info = draft.infos;

			// backward compatibility
			const parcel = info.parcelles[index];
			if (parcel.type === "FeatureCollection") {
				if (parcel.features[0].id === info.asset.mainParcel) {
					info.asset.mainParcel = undefined;
				}
			} else if (parcel.properties.id === info.asset.mainParcel) {
				info.asset.mainParcel = undefined;
			}

			info.parcelles.splice(index, 1);
		});

		this.fireUpdate(clone);
	}

	addCustomParcelle(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		dialogOpen(
			this.dialog,
			ParcelModalComponent,
			{ insee: functionalDeclaration.address.cityCode },
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (!output || output.type !== ParcelModalComponent.OutputType.Save) {
					return;
				}

				const clone = produce(functionalDeclaration, (draft) => {
					draft.infos.parcelles.push(output.parcel);
				});

				this.fireUpdate(clone);
			});
	}

	addParcelle(functionalDeclaration: Immutable<FunctionalDeclaration>, parcel: ParcelFeature) {
		parcel = formatParcel(parcel);

		const invisibleParcelIndex = functionalDeclaration.infos.parcelles.findIndex((existingParcel) => {
			if (existingParcel.type === "FeatureCollection") {
				return false;
			}

			return getParcelId(existingParcel) === getParcelId(parcel);
		});

		// if we detect an invisible parcel we fix it silently
		// some parcels are glitched in the new map, thanks to that
		// the user won't delete their parcel because of a glitch
		if (invisibleParcelIndex >= 0) {
			this.fireUpdate(
				produce(functionalDeclaration, (draft) => {
					const parcelToModify = draft.infos.parcelles[invisibleParcelIndex];
					if (parcelToModify.type === "FeatureCollection") {
						throw new Error("addParcelle :: Impossible");
					}
					parcelToModify.id = parcel.id;
				}),
			);
			return;
		}

		const clone = produce(functionalDeclaration, (draft) => {
			draft.infos.parcelles.push(parcel);
			// If entity already bought and mainParcel not set, set it with new parcel
			if (draft.is_token_used && !draft.infos.asset.mainParcel) {
				draft.infos.asset.mainParcel = getParcelId(parcel);
			}
		});

		this.snackBar.open("Parcelle ajoutée !", undefined, { verticalPosition: "top", duration: 1000 });

		this.fireUpdate(clone);
	}

	skip(id: FunctionalDeclarationId) {
		this.router.navigate(RouteDealer.assetDeclaration(id));
	}

	map?: maplibregl.Map;

	onMapLoad(map: maplibregl.Map) {
		this.map = map;

		this.sub(
			this.functionalDeclarationState.get$.pipe(
				switchMap((nullable) => (nullable !== null ? of(nullable.value) : EMPTY)),
				switchMap((functionalDeclaration) =>
					functionalDeclaration.infos.parcelles.some((parcel) => parcel.type === "FeatureCollection") ||
					functionalDeclaration.infos.buildingInfos.some(({ building }) => building.type === "FeatureCollection")
						? of(functionalDeclaration)
						: EMPTY,
				),
				tap(() => {
					this.snackBar.open("Mise à jour des anciens formats...", "OK", { verticalPosition: "top" });

					this.freezeHover = true;
					freezeMap(map);
				}),
				switchMap((functionalDeclaration) =>
					zip(
						getParcelsToUpdate$(map, functionalDeclaration.infos.parcelles),
						getBuildingsToUpdate$(map, functionalDeclaration.infos.buildingInfos),
					).pipe(
						tap(([parcelsToUpdate, buildingsToUpdate]) => {
							const clone = produce(functionalDeclaration, (draft) => {
								for (const { index, parcel } of parcelsToUpdate) {
									const oldParcel = draft.infos.parcelles[index];

									if (oldParcel.type === "Feature") {
										continue; //impossible
									}

									if (draft.infos.asset.mainParcel === oldParcel.features[0].id) {
										draft.infos.asset.mainParcel = getParcelId(parcel);
									}

									draft.infos.parcelles[index] = castDraft(parcel);
								}

								for (const { index, building } of buildingsToUpdate) {
									draft.infos.buildingInfos[index].building = castDraft(building);
								}
							});
							this.freezeHover = false;
							unfreezeMap(map);

							this.snackBar.open("Mise à jour terminée !", "OK", { verticalPosition: "top" });

							this.fireUpdate(clone);
						}),
					),
				),
			),
		);
	}

	toggleOtherBuildingOnSameSite(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		const clone = produce(functionalDeclaration, (draft) => {
			const info = draft.infos;
			info.otherBuildingOnSameSite = info.otherBuildingOnSameSite
				? undefined
				: { ...new SurfaceTertiaire(), tertiaireArea: 1 };
		});

		this.fireUpdate(clone);
	}

	editSurfaceBuildingOnSameSite(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		surface?: Immutable<SurfaceTertiaire>,
	) {
		dialogOpen(
			this.dialog,
			SurfaceModalComponent,
			{
				surface: surface ?? new SurfaceTertiaire(),
				title: "Surface tertiaire indépendante de mon entité fonctionnelle",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((output) => {
				if (!output) {
					return;
				}

				if (output.type === SurfaceModalComponent.OutputType.Save) {
					const clone = produce(functionalDeclaration, (draft) => {
						draft.infos.otherBuildingOnSameSite = output.surface;
					});

					this.fireUpdate(clone);
					return;
				}

				if (output.type === SurfaceModalComponent.OutputType.Delete) {
					const clone = produce(functionalDeclaration, (draft) => {
						draft.infos.otherBuildingOnSameSite = undefined;
					});

					this.fireUpdate(clone);
				}
			});
	}
}

type ParcelToUpdate = { index: number; parcel: ParcelFeature };

export function formatParcel(parcel: ParcelFeature): ParcelFeature {
	const parcelCommuneNumber = Number(parcel.properties.commune);
	if (75101 <= parcelCommuneNumber && parcelCommuneNumber <= 75120) {
		parcel.properties.prefixe = parcelCommuneNumber.toString().substring(2);
		parcel.properties.commune = "75056";
		parcel.properties.id = (parcel.properties.commune +
			parcel.properties.prefixe +
			parcel.properties.section +
			parcel.properties.numero.padStart(4, "0")) as ParcelId;
	}
	if (69381 <= parcelCommuneNumber && parcelCommuneNumber <= 69381) {
		parcel.properties.prefixe = parcelCommuneNumber.toString().substring(2);
		parcel.properties.commune = "69123";
		parcel.properties.id = (parcel.properties.commune +
			parcel.properties.prefixe +
			parcel.properties.section +
			parcel.properties.numero.padStart(4, "0")) as ParcelId;
	}
	if (13201 <= parcelCommuneNumber && parcelCommuneNumber <= 13216) {
		parcel.properties.commune = "13055";
		parcel.properties.id = (parcel.properties.commune +
			parcel.properties.prefixe +
			parcel.properties.section +
			parcel.properties.numero.padStart(4, "0")) as ParcelId;
	}
	return parcel;
}

function getParcelsToUpdate$(
	map: maplibregl.Map,
	parcels: Immutable<(FeatureCollection | ParcelFeature)[]>,
): Observable<Immutable<ParcelToUpdate[]>> {
	if (parcels.length === 0) {
		return of([]);
	}

	return getParcelsToUpdateLoop$(map, [], parcels, 0);
}

function getParcelsToUpdateLoop$(
	map: maplibregl.Map,
	toUpdate: Immutable<ParcelToUpdate[]>,
	parcels: Immutable<(FeatureCollection | ParcelFeature)[]>,
	index: number,
): Observable<Immutable<ParcelToUpdate[]>> {
	const parcel = parcels[index];

	if (parcel.type === "Feature") {
		return index + 1 < parcels.length ? getParcelsToUpdateLoop$(map, toUpdate, parcels, index + 1) : of(toUpdate);
	}

	// If custom parcel
	if (parcel.features[0].geometry.type === "Point") {
		const { code_dep, code_com, numero, code_arr, com_abs, section } = unwrap(parcel.features[0].properties);

		const parcelFeature = customParcelFeature(code_dep + code_com, numero, com_abs, code_arr, section);
		const newToUpdate: Immutable<ParcelToUpdate[]> = [...toUpdate, { index, parcel: parcelFeature }];
		return index + 1 < parcels.length ? getParcelsToUpdateLoop$(map, newToUpdate, parcels, index + 1) : of(newToUpdate);
	}

	const center = turf.center(parcel as FeatureCollection);
	return mapJumpTo$(map, { center: center.geometry.coordinates as [number, number] }).pipe(
		switchMap(() => {
			const features = map.queryRenderedFeatures(undefined, { layers: ["parcelles-fill"] });
			const parcelId = getParcelId(parcel);
			const feature = features.find((rendered) => {
				const parcelFeature = toParcelFeature(rendered);

				if (parcelFeature === null) {
					return false;
				}

				const updatedParcelId = getParcelId(formatParcel(parcelFeature));

				return updatedParcelId === parcelId;
			});

			if (feature === undefined) {
				return index + 1 < parcels.length ? getParcelsToUpdateLoop$(map, toUpdate, parcels, index + 1) : of(toUpdate);
			}

			const parcelFeature = formatParcel(unwrap(toParcelFeature(feature)));

			const newToUpdate: Immutable<ParcelToUpdate[]> = [...toUpdate, { index, parcel: parcelFeature }];

			return index + 1 < parcels.length
				? getParcelsToUpdateLoop$(map, newToUpdate, parcels, index + 1)
				: of(newToUpdate);
		}),
	);
}

type BuildingToUpdate = { index: number; building: BuildingFeature };

function getBuildingsToUpdate$(
	map: maplibregl.Map,
	buildingInfos: Immutable<BuildingInfo[]>,
): Observable<Immutable<BuildingToUpdate[]>> {
	if (buildingInfos.length === 0) {
		return of([]);
	}

	return getBuildingsToUpdateLoop$(map, [], buildingInfos, 0);
}

function getBuildingsToUpdateLoop$(
	map: maplibregl.Map,
	toUpdateList: Immutable<BuildingToUpdate[]>,
	buildingInfos: Immutable<BuildingInfo[]>,
	index: number,
): Observable<Immutable<BuildingToUpdate[]>> {
	const { building } = buildingInfos[index];

	if (building.type === "Feature") {
		return index + 1 < buildingInfos.length
			? getBuildingsToUpdateLoop$(map, toUpdateList, buildingInfos, index + 1)
			: of(toUpdateList);
	}

	const feature = building.features[0];
	const geometry = feature.geometry;

	// If custom building
	if (geometry.type === "Point") {
		const customBuilding: Immutable<BuildingFeature> = { ...feature, geometry };

		const newToUpdateList: Immutable<BuildingToUpdate[]> = [...toUpdateList, { index, building: customBuilding }];

		return index + 1 < buildingInfos.length
			? getBuildingsToUpdateLoop$(map, newToUpdateList, buildingInfos, index + 1)
			: of(newToUpdateList);
	}

	return toObservable(() => {
		return turf.center(building as FeatureCollection);
	}).pipe(
		switchMap((center) => mapJumpTo$(map, { center: center.geometry.coordinates as [number, number] })),
		switchMap(() => {
			const features = map.queryRenderedFeatures(undefined, { layers: ["batiments-fill"] });

			const feature = features.find((rendered) => {
				const geometry = rendered.geometry;
				if (geometry.type === "Point") {
					return false;
				}
				const intersection = turf.intersect(
					turf.helpers.featureCollection([
						turf.helpers.feature(geometry as Polygon | MultiPolygon),
						turf.helpers.feature(building.features[0].geometry as Polygon | MultiPolygon),
					]),
				);

				return (
					intersection !== null &&
					Math.abs(turf.area(intersection) - turf.area(building as FeatureCollection)) <
						turf.area(building as FeatureCollection) * 0.3
				);
			});

			if (feature === undefined) {
				return index + 1 < buildingInfos.length
					? getBuildingsToUpdateLoop$(map, toUpdateList, buildingInfos, index + 1)
					: of(toUpdateList);
			}

			const buildingFeature = unwrap(toBuildingFeature(feature));

			const newToUpdateList: Immutable<BuildingToUpdate[]> = [...toUpdateList, { index, building: buildingFeature }];

			return index + 1 < buildingInfos.length
				? getBuildingsToUpdateLoop$(map, newToUpdateList, buildingInfos, index + 1)
				: of(newToUpdateList);
		}),
	);
}

function toBuildingInfo(feature?: BuildingFeature): BuildingInfo {
	const building: BuildingFeature = feature ?? {
		geometry: { type: "Point", coordinates: [0, 0] },
		type: "Feature",
		properties: {},
		id: uuid.v1(),
	};
	return {
		building,
		label: ["", feature ? turf.centerOfMass(feature).geometry.coordinates : undefined],
		internalCode: "",
		ownedSurface: new SurfaceTertiaire(),
		notOwnedSurfaces: [],
		isOnlyTertiary: false,
		lotNumbers: [],
	};
}

function unfreezeMap(map: maplibregl.Map) {
	map.dragPan.enable();
	map.scrollZoom.enable();
	map.boxZoom.enable();
	map.touchZoomRotate.enable();
	map.dragRotate.enable();
	map.doubleClickZoom.enable();
}

function freezeMap(map: maplibregl.Map) {
	map.dragPan.disable();
	map.scrollZoom.disable();
	map.boxZoom.disable();
	map.touchZoomRotate.disable();
	map.dragRotate.disable();
	map.doubleClickZoom.disable();
}
