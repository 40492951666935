import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "contains",
})
export class ContainsPipe implements PipeTransform {
	transform(value: string, include: string): boolean {
		return value.toLowerCase().includes(include.toLowerCase());
	}
}
