import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CATEGORIES, CategorySurfaceTertiaire } from "src/app/models/functionalDeclaration";

@Component({
	selector: "app-category-list",
	templateUrl: "./category-list.component.html",
	styleUrls: ["./category-list.component.scss"],
})
export class CategoryListComponent implements OnInit {
	categories: { title: string; itemsAndHidden?: { items: string[]; hidden: boolean } }[] = CATEGORIES.map(
		(category) => ({
			title: category.title,
			itemsAndHidden: category.items
				? { items: category.items, hidden: true }
				: { items: [category.title], hidden: true },
		}),
	);

	@Input() categorySurfaceAreas: CategorySurfaceTertiaire = {};
	// Key path and value
	@Output() updateField = new EventEmitter<{ key: string; subKey?: string; value: number }>();
	parseInt = parseInt;

	toggleField(currentValue: number, key: string, subKey?: string) {
		if (currentValue === 0) {
			this.updateField.emit({ key, subKey, value: 1 });
		} else {
			this.updateField.emit({ key, subKey, value: 0 });
		}
	}

	setField(valueText: string, key: string, subKey?: string) {
		const value = parseFloat(valueText);
		if (value >= 0) {
			this.updateField.emit({ key, subKey, value });
		}
	}

	ngOnInit() {
		for (const { title, itemsAndHidden } of this.categories) {
			const layer = this.categorySurfaceAreas[title];
			if (itemsAndHidden && typeof layer === "number") {
				itemsAndHidden.hidden = false;
			}
			if (itemsAndHidden && layer && typeof layer !== "number") {
				for (const item of itemsAndHidden.items) {
					if (layer[item] > 0) {
						itemsAndHidden.hidden = false;
					}
				}
			}
		}
	}
}
