import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { RepresentativeId } from "./ids";
import { Lazy } from "./lazy";
import { Representative } from "./representative";
import { TokenType } from "./TokenType";
import { User } from "./user";

export class Payment {
	[GreenKeys.KEY_PAYMENT_ID]?: string;
	[GreenKeys.KEY_CLIENT_SECRET]?: string;
	[GreenKeys.KEY_EURO_AMOUNT] = 0;
	[GreenKeys.KEY_QUANTITY] = 1;
	[GreenKeys.KEY_REPRESENTATIVE_ID]?: RepresentativeId;
	[GreenKeys.KEY_REPRESENTATIVE]: Immutable<Lazy<Representative>> | null = null;
	[GreenKeys.KEY_CREATION_DATE] = 0;
	[GreenKeys.KEY_END_DATE] = 0;
	[GreenKeys.KEY_IS_PAYMENT_SENT] = false;
	[GreenKeys.KEY_IS_ACCEPTED]?: boolean;
	[GreenKeys.KEY_BUYER_ID]?: string;
	[GreenKeys.KEY_BUYER]?: User;
	[GreenKeys.KEY_TOKEN_TYPE]: TokenType = TokenType.INITIAL_TOKEN;
}
