import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DeclarationGroup } from "../models/declarationGroup";
import { DeclarationGroupId } from "../models/ids";
import { Isolation } from "../models/isolation";
import { Lazy } from "../models/lazy";
import { DeclarationGroupService } from "./declaration-group.service";

@Injectable({
	providedIn: "root",
})
export class DeclarationGroupIsolationService extends Isolation<Lazy<DeclarationGroup>> {
	constructor(private group: DeclarationGroupService) {
		super();
	}

	getIdFromResource(resource: Immutable<Lazy<DeclarationGroup>>): DeclarationGroupId {
		const id = resource.declaration_group_id;
		if (id) {
			return id;
		}

		throw new Error("Resource without ID.");
	}

	fetchResource$(id: DeclarationGroupId): Observable<Immutable<Lazy<DeclarationGroup>>> {
		return this.group.get$(id).pipe(map(DeclarationGroup.fromLazyApi));
	}

	saveResourceToServer$(resource: Immutable<Lazy<DeclarationGroup>>): Observable<Immutable<Lazy<DeclarationGroup>>> {
		return this.group.save$(DeclarationGroup.toLazyApi(resource)).pipe(map(DeclarationGroup.fromLazyApi));
	}
}
