import { Immutable } from "immer";
import { Category, getCategories } from "src/assets/data/categories";
import { SubCategoryCode } from "../models/asset";

/**
 * To avoid instantiating categories each time.
 */
export const IMMUTABLE_CATEGORIES: Immutable<Category[]> = getCategories();

export function getCategoryFromSubCategoryCode(subCategoryCode: SubCategoryCode): Immutable<Category> {
	for (const category of IMMUTABLE_CATEGORIES) {
		for (const subCategory of category.subCategories) {
			if (subCategory.code === subCategoryCode) {
				return category;
			}
		}
	}

	throw new Error("This subcategory has no category");
}
