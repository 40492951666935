import { Pipe, PipeTransform } from "@angular/core";
import { CategorySurfaceTertiaire } from "../models/functionalDeclaration";

@Pipe({
	name: "displayFieldCategory",
})
export class DisplayFieldCategoryPipe implements PipeTransform {
	transform(category: CategorySurfaceTertiaire, key: string, subKey?: string): number | undefined {
		const firstLayer = category[key];

		if (firstLayer === undefined) {
			return undefined;
		}

		if (!subKey) {
			if (typeof firstLayer === "number") {
				return firstLayer;
			}
			return undefined;
		}

		if (typeof firstLayer === "number") {
			return undefined;
		}

		return firstLayer[subKey];
	}
}
