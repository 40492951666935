import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Irve } from "../models/asset";
import { AddBlock } from "./sort-activity-categories-per-start.pipe";

@Pipe({
	name: "isIrve",
})
export class IsIrvePipe implements PipeTransform {
	transform(toTest: Immutable<Irve | AddBlock>): Immutable<Irve> | undefined {
		return "count" in toTest ? toTest : undefined;
	}
}
