<div class="pane profile" *ngIf="user">
	<h2>Modifier les informations de mon compte</h2>
	<div class="d-flex gap-4">
		<div class="w-100 br-1px-text-gray pr-4">
			<h4>Mes informations</h4>
			<form #infoForm class="d-flex-col gap-2">
				<div class="d-flex gap-2">
					<div class="custom-input-group w-50">
						<label class="login-p" required>Civilité</label>
						<select
							#civilityInput
							[(ngModel)]="user.civility"
							(ngModelChange)="checkInfoFormValidity()"
							class="custom-input w-100"
							name="civility"
							required
							type="text"
						>
							<option [value]="0">Monsieur</option>
							<option [value]="1">Madame</option>
						</select>
						<span class="custom-input-error">{{ civilityInput.validationMessage }}</span>
					</div>
					<div class="custom-input-group w-100">
						<label class="login-p" required>Prénom</label>
						<input
							#firstNameInput
							[(ngModel)]="user.first_name"
							(ngModelChange)="checkInfoFormValidity()"
							[placeholder]="'Entrez votre prénom'"
							class="custom-input w-100"
							name="firstName"
							required
							type="text"
						/>
						<span class="custom-input-error">{{ firstNameInput.validationMessage }}</span>
					</div>

					<div class="custom-input-group w-100">
						<label class="login-p" required>Nom</label>
						<input
							#nameInput
							[(ngModel)]="user.name"
							(ngModelChange)="checkInfoFormValidity()"
							[placeholder]="'Entrez votre nom'"
							class="custom-input w-100"
							name="name"
							required
							type="text"
						/>
						<span class="custom-input-error">{{ nameInput.validationMessage }}</span>
					</div>
				</div>

				<div class="d-flex gap-2">
					<div class="custom-input-group w-100">
						<label class="login-p" required>Email</label>
						<input
							#emailInput
							[value]="user.email"
							[disabled]="true"
							[placeholder]="'Entrez votre email'"
							class="custom-input w-100"
							name="email"
							required
							type="email"
						/>
						<span class="custom-input-error">{{ emailInput.validationMessage }}</span>
					</div>

					<div class="custom-input-group w-100">
						<label class="login-p">Téléphone</label>
						<input
							#phoneInput
							[(ngModel)]="user.phone_number"
							(ngModelChange)="checkInfoFormValidity()"
							[placeholder]="'Entrez votre numéro de téléphone'"
							class="custom-input w-100"
							name="phone"
							type="tel"
						/>
						<span class="custom-input-error">{{ phoneInput.validationMessage }}</span>
					</div>
				</div>

				<div class="custom-input-group w-100" *ngIf="user.color">
					<label class="login-p">Couleur</label>
					<app-color-picker
						[(value)]="user.color"
						(valueChange)="checkInfoFormValidity()"
						class="w-100"
					></app-color-picker>
				</div>

				<div class="d-flex" style="justify-content: flex-end">
					<button class="btn-primary" [disabled]="loading || !infoFormValid" (click)="save(user)">Enregistrer</button>
				</div>
			</form>

			<h4>Mon adresse de facturation</h4>
			<app-address-form [loading]="loading" (continue)="save($event)"></app-address-form>
		</div>

		<div class="w-100">
			<h4>Modifier mon mot de passe</h4>
			<form #passwordForm class="d-flex-col gap-2">
				<div class="custom-input-group">
					<label class="login-p" required>Mot de passe actuel</label>
					<input
						#actualPasswordInput
						[(ngModel)]="actualPassword"
						(ngModelChange)="checkPasswordFormValidity()"
						[placeholder]="'Entrez votre mot de passe actuel'"
						class="custom-input w-100"
						name="actualPassword"
						required
						type="password"
					/>
					<span class="custom-input-error">{{ actualPasswordInput.validationMessage }}</span>
				</div>
				<div class="d-flex gap-2">
					<div class="custom-input-group w-100">
						<label class="login-p" required>Nouveau mot de passe</label>
						<input
							#newPasswordInput
							[(ngModel)]="newPassword"
							(ngModelChange)="checkPasswordFormValidity()"
							[placeholder]="'Entrez votre nouveau mot de passe'"
							class="custom-input w-100"
							name="newPassword"
							required
							type="password"
							minlength="6"
						/>
						<span class="custom-input-error">{{ newPasswordInput.validationMessage }}</span>
					</div>

					<div class="custom-input-group w-100">
						<label class="login-p" required>Confirmez votre nouveau mot de passe</label>
						<input
							#newPasswordConfirmInput
							[(ngModel)]="newPasswordConfirm"
							(ngModelChange)="checkPasswordFormValidity()"
							[placeholder]="'Confirmez votre nouveau mot de passe'"
							class="custom-input w-100"
							name="newPasswordConfirm"
							required
							type="password"
						/>
						<span class="custom-input-error">{{ newPasswordConfirmInput.validationMessage }}</span>
					</div>
				</div>

				<div class="d-flex" style="justify-content: flex-end">
					<button class="btn-primary" (click)="savePassword()" [disabled]="loading || !passwordFormValid">
						Enregistrer
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
