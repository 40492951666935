import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { produce, Immutable } from "immer";
import { EMPTY, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { sequentialJoin } from "src/app/helpers/sequentialJoin";
import { FileEntry } from "src/app/models/functionalDeclaration";
import { FunctionalDeclarationId } from "src/app/models/ids";
import { dialogOpen, Modal } from "src/app/models/modal";
import { environment } from "src/environments/environment";

type Input = {
	files: Immutable<FileEntry[]>;
	functionalDeclarationId: FunctionalDeclarationId;
	http: HttpClient;
};
type State = Input;
type Output = Immutable<FileEntry[]>;

@Component({
	selector: "app-proof-files-modals",
	templateUrl: "./proof-files-modals.component.html",
	styleUrls: ["./proof-files-modals.component.scss"],
})
export class ProofFilesModalsComponent extends Modal<Input, State, Output> {
	isLoading = false;
	filesToUpload: FileList | null = null;

	inputToState(input: Input): State {
		return input;
	}

	upload() {
		const files = this.filesToUpload;

		if (!files || files.length === 0) {
			return;
		}

		this.isLoading = true;

		sequentialJoin(
			Array.from(files).map((file) => {
				const data = new FormData();
				data.append("file", file);
				return this.state.http
					.post<FileEntry>(
						`${environment.baseApiUrl}/declaration_functional/${this.state.functionalDeclarationId}/file`,
						data,
					)
					.pipe(
						tap((entry) => {
							this.state.files = produce(this.state.files, (draft) => {
								draft.push(entry);
							});
						}),
						catchError((err: unknown) => {
							if (err instanceof HttpErrorResponse) {
								const {
									status,
									error: { error },
								} = err;

								if (status === 507 || status === 413) {
									this.snackBar.open(`Vous ne pouvez pas dépasser la limite de ${error} Mo !`, "OK", {
										verticalPosition: "top",
									});
									return of(undefined);
								}
							}

							throw err;
						}),
					);
			}),
		).subscribe(() => {
			this.isLoading = false;
		});
	}

	closeModal() {
		this.close(this.state.files);
	}

	deleteFile({ uuid, name }: FileEntry) {
		if (this.isLoading) {
			return;
		}

		dialogOpen(this.dialog, ConfirmationModalComponent, {
			title: "Suppression d'un fichier",
			description: `Êtes-vous sûr de supprimer ${name} ?`,
		})
			.afterClosed()
			.pipe(
				switchMap((wantToDelete) => (wantToDelete ? of(undefined) : EMPTY)),
				tap(() => {
					this.isLoading = true;
				}),
				switchMap(() =>
					this.state.http.delete(
						`${environment.baseApiUrl}/declaration_functional/${this.state.functionalDeclarationId}/file/${uuid}`,
					),
				),
			)
			.subscribe(() => {
				this.state.files = this.state.files.filter((entry) => entry.uuid !== uuid);
				this.isLoading = false;
			});
	}

	downloadFile({ uuid, name }: FileEntry) {
		this.state.http
			.get(`${environment.baseApiUrl}/declaration_functional/${this.state.functionalDeclarationId}/file/${uuid}`, {
				responseType: "blob",
			})
			.subscribe((blob) => {
				const a = document.createElement("a");
				const url = URL.createObjectURL(blob);
				a.href = url;
				a.download = name;
				a.click();
				URL.revokeObjectURL(url);
			});
	}
}
