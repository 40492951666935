import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { produce, Immutable } from "immer";
import { EMPTY, from, Observable, of, zip } from "rxjs";
import { delay, map, switchMap, tap } from "rxjs/operators";
import { otherToDeclaration$, rnaToDeclaration$, sirenToDeclaration$ } from "src/app/helpers/declaration-from-code";
import { Lazy } from "src/app/models/lazy";
import { dialogOpen } from "src/app/models/modal";
import { Representative } from "src/app/models/representative";
import { RnaService } from "src/app/services/rna.service";
import { SirenService } from "src/app/services/siren.service";
import { groupCsvRefToObject } from "../../../../helpers/import-csv-helper";
import { Nullable } from "../../../../helpers/nullable";
import { unwrap } from "../../../../helpers/unwrap";
import { Declaration } from "../../../../models/declaration";
import { DeclarationGroup } from "../../../../models/declarationGroup";
import { Rna, Siren } from "../../../../models/identifier";
import { DeclarationGroupId } from "../../../../models/ids";
import { ResourceType } from "../../../../models/resource";
import { RouteDealer } from "../../../../models/routes";
import { User } from "../../../../models/user";
import { AddressSearchService } from "../../../../services/address-search.service";
import { DeclarationService } from "../../../../services/declaration.service";
import { HiddenRouteParamsService } from "../../../../services/hidden-route-params.service";
import { OperatApiService } from "../../../../services/operat-api.service";
import { AuthorizationListModalComponent } from "../../authorization-list-modal/authorization-list-modal.component";
import { ExportCsvModalComponent } from "../../export-csv-modal/export-csv-modal.component";
import { Page } from "../../stepper/stepper.component";
import { OperatImportLoadingComponent } from "../../user-info-form/operat-import-loading/operat-import-loading.component";
import { OperatApiImportData } from "../../user-info-form/operat-search-form/operat-search-form.component";
import {
	declarationFromOwnerChoice$,
	declarationsFromBasicChoice$,
} from "../../user-info-form/user-info-form.component";
import { DeleteGroupConfirmModalComponent } from "../delete-group-confirm-modal/delete-group-confirm-modal.component";
import {
	CSVImportData,
	ImportDeclarationModalComponent,
} from "../import-declaration-modal/import-declaration-modal.component";
import { NewDeclarationModalComponent } from "../new-declaration-modal/new-declaration-modal.component";
import { compareByName } from "src/app/helpers/sort-by-name";
import { DeclarationFunctionalService } from "../../../../services/declaration-functional.service";

@Component({
	selector: "app-group-pane",
	templateUrl: "./group-pane.component.html",
	styleUrls: ["./group-pane.component.scss"],
})
export class GroupPaneComponent {
	@Input() group!: Immutable<DeclarationGroup>;
	@Input() user!: User;
	@Input() developAll = false;
	@Input() representative?: Immutable<Lazy<Representative>>; // If defined then we can delete the
	// group

	@Output() stepActionEmitter = new EventEmitter<{ declaration: Immutable<Declaration>; page: Page }>();
	@Output() updateGroup = new EventEmitter<Immutable<DeclarationGroup>>();
	@Output() newDeclaration = new EventEmitter<string>(); // string is a siren or siret etc...
	@Output() deleteGroup = new EventEmitter<Immutable<Lazy<Representative>>>();

	archivedLoading = false;

	editingTitle: boolean = false;
	newTitle: string = "";

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private sirenService: SirenService,
		private rnaService: RnaService,
		private declarationService: DeclarationService,
		private declarationFunctionalService: DeclarationFunctionalService,
		private addressSearch: AddressSearchService,
		private operatApi: OperatApiService,
		private hiddenRouteParams: HiddenRouteParamsService<string>,
	) {}

	editTitle() {
		this.editingTitle = true;
		this.newTitle = this.group.name ?? "";
	}

	saveTitle() {
		const newGroup = produce(this.group, (draft) => {
			draft[GreenKeys.KEY_NAME] = this.newTitle;
		});
		this.editingTitle = false;
		this.updateGroup.emit(newGroup);
	}

	setArchived() {
		const newGroup = produce(this.group, (draft) => {
			draft[GreenKeys.KEY_IS_ARCHIVED] = !draft[GreenKeys.KEY_IS_ARCHIVED];
		});
		this.archivedLoading = true;
		this.updateGroup.emit(newGroup);
	}

	openDeclarationGroupAuthorizationListDialog(declarationGroup: Immutable<DeclarationGroup>, userId: string): void {
		if (declarationGroup.declaration_group_id && declarationGroup.owner_id) {
			this.dialog.open(AuthorizationListModalComponent, {
				data: {
					resourceId: declarationGroup.declaration_group_id,
					resourceType: ResourceType.DeclarationGroup,
					ownerId: declarationGroup.owner_id,
					userId,
					parentResourceOwnerId:
						!!declarationGroup.representative_id &&
						declarationGroup.representative_id === this.representative?.representative_id
							? this.representative.owner_id
							: undefined,
				},
				panelClass: "p-0",
			});
		}
	}

	openNewDeclarationDialog(): void {
		dialogOpen(this.dialog, NewDeclarationModalComponent, {}, { panelClass: "p-0" })
			.afterClosed()
			.pipe(
				switchMap((output) => (output ? of(output) : EMPTY)),
				switchMap(({ choice, isMandated, firstYearDeclaration }) =>
					choice.isOwner
						? zip(
								declarationFromOwnerChoice$(choice, isMandated, this.sirenService, this.rnaService),
								of(firstYearDeclaration),
							)
						: choice.isOther
							? zip(otherToDeclaration$(choice.idOther, isMandated), of(firstYearDeclaration))
							: zip(
									declarationsFromBasicChoice$(choice, isMandated, this.sirenService, this.rnaService),
									of(firstYearDeclaration),
								),
				),

				map(([maybeArray, firstYearDeclaration]) =>
					maybeArray instanceof Array
						? { declarations: maybeArray, firstYearDeclaration }
						: { declarations: [maybeArray], firstYearDeclaration },
				),
				map(
					({
						declarations,
						firstYearDeclaration,
					}: {
						declarations: Declaration[];
						firstYearDeclaration: Nullable<number>;
					}) => {
						if (firstYearDeclaration) {
							for (const declaration of declarations) {
								for (const functionalDeclaration of declaration.declarations_functional) {
									functionalDeclaration.first_year_declaration = firstYearDeclaration;
								}
							}
						}
						return declarations;
					},
				),
			)
			.subscribe((declarations) => {
				this.updateGroup.emit(
					produce(this.group, (draft) => {
						draft.declarations.push(...declarations);
					}),
				);
			});
	}

	openImportDeclarationDialog(): void {
		dialogOpen(this.dialog, ImportDeclarationModalComponent, {}, { panelClass: "p-0" })
			.afterClosed()
			.pipe(
				switchMap((output) => (output ? of(output) : EMPTY)),
				switchMap((output) => ("siren" in output ? this.importFromCSV(output) : this.importFromAPI(output))),
				map((declaration) => {
					this.updateGroup.emit(
						produce(this.group, (draft) => {
							draft.declarations.push(declaration);
						}),
					);
					return declaration.declaration_id;
				}),
				delay(500), // Delay to wait for group state update
			)
			.subscribe((idDeclaration) => {
				this.router.navigate(RouteDealer.addresses(unwrap(idDeclaration)));
				this.dialog.closeAll();
			});
	}

	private importFromCSV(data: CSVImportData): Observable<Declaration> {
		return zip(from(data.csvFile.text()), of(data.siren)).pipe(
			switchMap(([csv, siren]) => {
				const importData$ = groupCsvRefToObject(csv, this.addressSearch);
				return sirenToDeclaration$(this.sirenService, siren, new Set(), true, false, true).pipe(
					switchMap((declaration) => (declaration ? of(declaration) : EMPTY)),
					map((declaration) => {
						declaration.declaration_group_id = this.group.declaration_group_id;
						return Declaration.toApi(declaration);
					}),
					switchMap((declaration) => {
						return this.declarationService.create$(declaration);
					}),
					switchMap((declaration) => {
						return importData$.pipe(
							switchMap((data) =>
								this.declarationService
									.importEFAByCSV(unwrap(declaration.declaration_id), data)
									.pipe(map(Declaration.fromApi)),
							),
						);
					}),
				);
			}),
		);
	}

	private importFromAPI(data: OperatApiImportData): Observable<Declaration> {
		let newDecla$: Observable<Declaration>;
		let importErrors: string[] = [];
		if (Rna.isValid(data.structId as Rna)) {
			newDecla$ = rnaToDeclaration$(this.rnaService, data.structId as Rna, false, undefined);
		} else {
			newDecla$ = sirenToDeclaration$(this.sirenService, data.structId as Siren, new Set(), true, false, true);
		}
		return newDecla$.pipe(
			switchMap((declaration) => (declaration ? of(declaration) : EMPTY)),
			map((declaration) => {
				declaration.operat_user_key = data.userKey;
				declaration.declaration_group_id = this.group.declaration_group_id;
				return Declaration.toApi(declaration);
			}),
			switchMap((declaration) => {
				return this.declarationService.create$(declaration);
			}),
			map(Declaration.fromLazyApi),
			switchMap((declaration) =>
				dialogOpen(
					this.dialog,
					OperatImportLoadingComponent,
					{
						declarationService: this.declarationService,
						declarationFunctionalService: this.declarationFunctionalService,
						addressSearch: this.addressSearch,
						operatService: this.operatApi,
						dataToImport: data,
						declaration: declaration,
					},
					{ panelClass: "p-0", disableClose: true },
				)
					.afterClosed()
					.pipe(
						switchMap((output) =>
							output
								? of(output).pipe(
										tap(({ errors }) => {
											importErrors = errors;
										}),
										map(({ declaration }) => declaration),
									)
								: this.declarationService
										.getNested$(unwrap(declaration.declaration_id))
										.pipe(tap(() => (importErrors = data.references.map((refData) => refData.refOperat)))),
						),
					),
			),
			map(Declaration.fromApi),
			tap(() => {
				this.hiddenRouteParams.push(...importErrors);
			}),
		);
	}

	openDeleteGroupDialog(
		declarationGroup: Immutable<DeclarationGroup>,
		representative: Immutable<Lazy<Representative>>,
	): void {
		this.dialog
			.open(DeleteGroupConfirmModalComponent, { data: { declarationGroup } })
			.afterClosed()
			.subscribe((wantToDelete) => {
				if (wantToDelete) {
					this.deleteGroup.emit(representative);
				}
			});
	}

	deleteDeclaration(group: Immutable<DeclarationGroup>, declaration: Immutable<Lazy<Declaration>>) {
		const newGroup = produce(group, (draft) => {
			draft.declarations = draft.declarations.filter((decla) => decla.declaration_id !== declaration.declaration_id);
		});

		this.updateGroup.emit(newGroup);
	}

	goToStats(id: DeclarationGroupId | undefined) {
		this.router.navigate(RouteDealer.stats({ id: unwrap(id), type: ResourceType.DeclarationGroup }));
	}

	openGreenExport(resource: Immutable<DeclarationGroup | Declaration>) {
		dialogOpen(
			this.dialog,
			ExportCsvModalComponent,
			{ resource: resource, declarationService: this.declarationService },
			{ panelClass: "p-0" },
		);
	}

	readonly sortDeclarations = (declarations: Immutable<Declaration[]>) => [...declarations].sort(compareByName);
}
