/* eslint-disable @typescript-eslint/no-namespace */
import { GreenKeys as Keys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { cloneDeclaration, cloneLazyDeclaration, Declaration } from "./declaration";
import { DeclarationGroupId, DeclarationId, RepresentativeId, UserId } from "./ids";
import { Lazy } from "./lazy";

export function newDeclarationGroup(
	data: Partial<DeclarationGroup> & { [Keys.KEY_REPRESENTATIVE_ID]: RepresentativeId },
): DeclarationGroup {
	return {
		[Keys.KEY_DECLARATION_GROUP_ID]: data[Keys.KEY_DECLARATION_GROUP_ID],
		[Keys.KEY_NAME]: data[Keys.KEY_NAME],
		[Keys.KEY_DECLARATIONS]: data[Keys.KEY_DECLARATIONS] ?? [],
		[Keys.KEY_OWNER_ID]: data[Keys.KEY_OWNER_ID],
		[Keys.KEY_REFERENT_NAME]: data[Keys.KEY_REFERENT_NAME],
		[Keys.KEY_REFERENT_EMAIL]: data[Keys.KEY_REFERENT_EMAIL],
		[Keys.KEY_REPRESENTATIVE_ID]: data[Keys.KEY_REPRESENTATIVE_ID],
		[Keys.KEY_TOKENS]: data[Keys.KEY_TOKENS] ?? 0,
		[Keys.KEY_USED_TOKENS]: data[Keys.KEY_USED_TOKENS] ?? 0,
		[Keys.KEY_ANNUAL_TOKENS]: data[Keys.KEY_ANNUAL_TOKENS] ?? 0,
		[Keys.KEY_USED_ANNUAL_TOKENS]: data[Keys.KEY_USED_ANNUAL_TOKENS] ?? 0,
		[Keys.KEY_IS_ARCHIVED]: data[Keys.KEY_IS_ARCHIVED] ?? false,
	};
}

export interface DeclarationGroup {
	[Keys.KEY_DECLARATION_GROUP_ID]?: DeclarationGroupId;
	[Keys.KEY_NAME]?: string;
	[Keys.KEY_DECLARATIONS]: Declaration[];
	[Keys.KEY_OWNER_ID]?: UserId;
	[Keys.KEY_REFERENT_NAME]?: string;
	[Keys.KEY_REFERENT_EMAIL]?: string;
	[Keys.KEY_REPRESENTATIVE_ID]: RepresentativeId;
	[Keys.KEY_TOKENS]: number;
	[Keys.KEY_USED_TOKENS]: number;
	[Keys.KEY_ANNUAL_TOKENS]: number;
	[Keys.KEY_USED_ANNUAL_TOKENS]: number;
	[Keys.KEY_IS_ARCHIVED]: boolean;
}

export namespace DeclarationGroup {
	/**
	 * Why an API interface? Because sometimes the server gives us garbage we don't care about.
	 * However, this garbage can be important. For example, if `declaration_functional_ids` (in the Declaration interface)
	 * is not correct then the server will delete entities. So to be less error prone we will hide
	 * this "feature" thanks to this interface.
	 * Besides, this makes us able to do changes without modifying the server.
	 */
	export interface Api {
		readonly DeclarationGroupApi: unique symbol;

		[Keys.KEY_DECLARATION_GROUP_ID]?: DeclarationGroupId;
		[Keys.KEY_NAME]?: string;
		[Keys.KEY_DECLARATIONS]: Declaration.Api[];
		[Keys.KEY_DECLARATION_IDS]: DeclarationId[];
		[Keys.KEY_OWNER_ID]?: UserId;
		[Keys.KEY_REFERENT_NAME]?: string;
		[Keys.KEY_REFERENT_EMAIL]?: string;
		[Keys.KEY_REPRESENTATIVE_ID]: RepresentativeId;
		[Keys.KEY_TOKENS]: number;
		[Keys.KEY_ANNUAL_TOKENS]: number;
		[Keys.KEY_USED_TOKENS]: number;
		[Keys.KEY_USED_ANNUAL_TOKENS]: number;
		[Keys.KEY_IS_ARCHIVED]: boolean;
	}

	export type SearchApi = Omit<DeclarationGroup.Api, "declarations"> & { declarations: Declaration.SearchApi[] };
	export type SearchDeclarationGroup = Omit<DeclarationGroup, "declarations"> & {
		declarations: Declaration.SearchDeclaration[];
	};
	export type DeclarationGroupFromApiType<T extends SearchApi | Api> = T extends Api
		? DeclarationGroup
		: SearchDeclarationGroup;

	export function fromApi<T extends SearchApi | Api>(api: T): DeclarationGroupFromApiType<T>;
	export function fromApi(api: Api | SearchApi): DeclarationGroupFromApiType<typeof api> {
		return {
			declarations: api.declarations.map(Declaration.fromApi),
			is_archived: api.is_archived,
			representative_id: api.representative_id,
			tokens: api.tokens,
			used_tokens: api.used_tokens,
			annual_tokens: api.annual_tokens,
			used_annual_tokens: api.used_annual_tokens,
			declaration_group_id: api[Keys.KEY_DECLARATION_GROUP_ID],
			name: api.name,
			owner_id: api.owner_id,
			referent_email: api.referent_email,
			referent_name: api.referent_name,
		};
	}

	export function fromLazyApi(api: Lazy<Api>): Lazy<DeclarationGroup> {
		return {
			declarations: api.declarations.map(Declaration.fromLazyApi),
			is_archived: api.is_archived,
			representative_id: api.representative_id,
			tokens: api.tokens,
			used_tokens: api.used_tokens,
			annual_tokens: api.annual_tokens,
			used_annual_tokens: api.used_annual_tokens,
			declaration_group_id: api[Keys.KEY_DECLARATION_GROUP_ID],
			name: api.name,
			owner_id: api.owner_id,
			referent_email: api.referent_email,
			referent_name: api.referent_name,
		};
	}

	export function toApi(group: Immutable<DeclarationGroup>): Immutable<Api> {
		const declarationIds: DeclarationId[] = [];
		for (const { declaration_id } of group.declarations) {
			if (declaration_id) {
				declarationIds.push(declaration_id);
			}
		}
		const api: Omit<Immutable<Api>, "DeclarationGroupApi"> = {
			declarations: group.declarations.map(Declaration.toApi),
			is_archived: group.is_archived,
			representative_id: group.representative_id,
			tokens: group.tokens,
			used_tokens: group.used_tokens,
			annual_tokens: group.annual_tokens,
			used_annual_tokens: group.used_annual_tokens,
			declaration_group_id: group.declaration_group_id,
			name: group.name,
			owner_id: group.owner_id,
			referent_email: group.referent_email,
			referent_name: group.referent_name,
			declaration_ids: declarationIds,
		};

		return api as Immutable<Api>;
	}

	export function toLazyApi(group: Immutable<Lazy<DeclarationGroup>>): Immutable<Lazy<Api>> {
		const declarationIds: DeclarationId[] = [];
		for (const { declaration_id } of group.declarations) {
			if (declaration_id) {
				declarationIds.push(declaration_id);
			}
		}
		const api: Omit<Immutable<Lazy<Api>>, "DeclarationGroupApi"> = {
			declarations: group.declarations.map(Declaration.toLazyApi),
			is_archived: group.is_archived,
			representative_id: group.representative_id,
			tokens: group.tokens,
			used_tokens: group.used_tokens,
			annual_tokens: group.annual_tokens,
			used_annual_tokens: group.used_annual_tokens,
			declaration_group_id: group.declaration_group_id,
			name: group.name,
			owner_id: group.owner_id,
			referent_email: group.referent_email,
			referent_name: group.referent_name,
			declaration_ids: declarationIds,
		};

		return api as Immutable<Lazy<Api>>;
	}
}

export function cloneDeclarationGroup(group: Immutable<DeclarationGroup>): DeclarationGroup {
	return {
		...group,
		[Keys.KEY_DECLARATIONS]: group[Keys.KEY_DECLARATIONS].map(cloneDeclaration),
	};
}

export function cloneLazyDeclarationGroup(group: Immutable<Lazy<DeclarationGroup>>): Lazy<DeclarationGroup> {
	return {
		...group,
		[Keys.KEY_DECLARATIONS]: group[Keys.KEY_DECLARATIONS].map(cloneLazyDeclaration),
	};
}
