<div class="color-picker-container custom-input-group">
	<input
		type="color"
		class="selected-color"
		[style]="{ 'background-color': value }"
		[(ngModel)]="value"
		(ngModelChange)="valueSubject.next(value)"
	/>
	<input
		#colorCodeInput
		class="custom-input color-code"
		pattern="^#([a-fA-F0-9]{6})$"
		[(ngModel)]="value"
		(ngModelChange)="valueSubject.next(value)"
	/>
	<span class="custom-input-error">{{ colorCodeInput.validationMessage }}</span>
</div>
