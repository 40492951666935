import { Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable, produce } from "immer";
import { EMPTY, forkJoin, Observable, of, Subscription, zip } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import {
	fullSirenToDeclaration$,
	otherToDeclaration$,
	rnaToDeclaration$,
	sirenToDeclaration$,
} from "src/app/helpers/declaration-from-code";
import { myForkjoin } from "src/app/helpers/to-observable";
import { unwrap } from "src/app/helpers/unwrap";
import { snackError } from "src/app/models/CustomError";
import { Declaration } from "src/app/models/declaration";
import { DeclarationGroup, newDeclarationGroup } from "src/app/models/declarationGroup";
import { IdRna, Rna, Siren, Siret } from "src/app/models/identifier";
import { Lazy } from "src/app/models/lazy";
import { Representative } from "src/app/models/representative";
import { Route, RouteDealer } from "src/app/models/routes";
import { DeclarationGroupService } from "src/app/services/declaration-group.service";
import { InitialLoadingService } from "src/app/services/initial-loading.service";
import { RepresentativeStateService } from "src/app/services/representative-state.service";
import { RnaService } from "src/app/services/rna.service";
import { SirenService } from "src/app/services/siren.service";
import { UserStateService } from "src/app/services/user-state.service";
import { groupCsvRefToObject } from "../../../helpers/import-csv-helper";
import { z, ZodNullable, ZodNumber, ZodOptional } from "zod";
import { Nullable } from "../../../helpers/nullable";
import { AddressSearchService } from "../../../services/address-search.service";
import { DeclarationService } from "../../../services/declaration.service";
import { dialogOpen } from "../../../models/modal";
import { OperatApiService } from "../../../services/operat-api.service";
import { DeclarationsCreationComponent } from "../../declarations-creation/declarations-creation.component";
import { HelpSubject } from "../../help/help.component";
import { ConfirmNewlyLiableComponent } from "./confirm-newly-liable/confirm-newly-liable.component";
import { OperatImportLoadingComponent } from "./operat-import-loading/operat-import-loading.component";
import { ImportTypeEnum } from "./operat-search-form/ImportTypeEnum";
import { OperatApiImportData } from "./operat-search-form/operat-search-form.component";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;
import { HiddenRouteParamsService } from "../../../services/hidden-route-params.service";
import { DeclarationFunctionalService } from "../../../services/declaration-functional.service";

type OwnerChoice = DeclarationsCreationComponent.OwnerChoice;
type BasicChoice = DeclarationsCreationComponent.BasicChoice;
type AllChoice = DeclarationsCreationComponent.AllChoice;

const TODAY = new Date().getFullYear();

interface HasSiren {
	siren: Siren;
	sirets: Set<Siret>;
}

@Component({
	selector: "app-user-info-form",
	templateUrl: "./user-info-form.component.html",
	styleUrls: ["./user-info-form.component.scss"],
})
export class UserInfoFormComponent implements OnDestroy {
	choice?: Immutable<AllChoice>;
	errors: Immutable<Set<Siret | Rna | Siren>> = new Set();
	isMandated = false;
	declarationGroupName = "";
	initialLoading = InitialLoadingService;
	Route = Route;
	HelpSubject = HelpSubject;
	csvFile: Nullable<File>;
	private toUnsub: Subscription[] = [];
	importIdentifier: Siren = "" as Siren;
	isNewlyLiable = false;
	firstYearDeclaration: Nullable<number>;
	zodCheckFirstYear: ZodOptional<ZodNullable<ZodNumber>>;
	apiImportData: OperatApiImportData = { references: [], userKey: "", structId: "" };
	importTabIndex = 0;

	@ViewChild("fileInput") fileInput!: ElementRef;

	constructor(
		private router: Router,
		private sirenService: SirenService,
		private rnaService: RnaService,
		public representativeState: RepresentativeStateService,
		private groupService: DeclarationGroupService,
		userState: UserStateService,
		private declarationService: DeclarationService,
		private declarationFunctionalService: DeclarationFunctionalService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private addressSearch: AddressSearchService,
		private operatApi: OperatApiService,
		private hiddenRouteParams: HiddenRouteParamsService<string>,
		@Inject(MAT_DIALOG_DATA) public data: { isImporting: boolean },
	) {
		this.toUnsub.push(
			representativeState.get$
				.pipe(switchMap((repr) => (repr ? of(repr) : EMPTY)))
				.subscribe(({ value: { representative_id } }) => {
					if (userState.user?.is_temporary === true) {
						router.navigate(RouteDealer.myDeclaration(representative_id));
						return;
					}
				}),
		);

		this.zodCheckFirstYear = z
			.number()
			.min(ANNUAL_DECLARATION_STARTING_YEAR, {
				message: `Vous devez choisir une année supérieur a ${ANNUAL_DECLARATION_STARTING_YEAR}`,
			})
			.max(TODAY, { message: "Vous ne pouvez pas être assujetti dans le futur !" })
			.nullish();
	}

	ngOnDestroy() {
		this.toUnsub.forEach((sub) => sub.unsubscribe());
	}

	submit(choice: Immutable<AllChoice>, representative: Immutable<Lazy<Representative>>) {
		if (!this.isNewlyLiable) {
			this.saveDeclaration(choice, representative);
			return;
		}

		dialogOpen(this.dialog, ConfirmNewlyLiableComponent, { type: "déclaration" }, { panelClass: "p-0" })
			.afterClosed()
			.subscribe((confirm) => {
				if (confirm) {
					this.saveDeclaration(choice, representative);
				}
			});
	}

	private saveDeclaration(choice: Immutable<AllChoice>, representative: Immutable<Lazy<Representative>>) {
		(choice.isOwner
			? declarationFromOwnerChoice$(choice, this.isMandated, this.sirenService, this.rnaService).pipe(
					map((declaration) => [declaration]),
				)
			: choice.isOther
				? otherToDeclaration$(choice.idOther, this.isMandated).pipe(map((declaration) => [declaration]))
				: declarationsFromBasicChoice$(choice, this.isMandated, this.sirenService, this.rnaService)
		)
			.pipe(
				map((maybeArray) => (maybeArray instanceof Array ? maybeArray : [maybeArray])),
				map((declarations: Declaration[]) => {
					if (this.isNewlyLiable && this.firstYearDeclaration) {
						for (const declaration of declarations) {
							for (const functionalDeclaration of declaration.declarations_functional) {
								functionalDeclaration[GreenKeys.KEY_FIRST_YEAR_DECLARATION] = this.firstYearDeclaration;
							}
						}
					}

					return declarations;
				}),
				map((declarations) =>
					newDeclarationGroup({
						declarations,
						name: this.declarationGroupName,
						representative_id: representative.representative_id,
					}),
				),
				map(DeclarationGroup.toApi),
				switchMap((newGroup) => this.groupService.create$(newGroup)),
				map(DeclarationGroup.fromLazyApi),
				switchMap((savedGroup) =>
					this.representativeState
						.update$(
							produce(representative, (draft) => {
								draft.declaration_groups.push(savedGroup);
							}),
						)
						.pipe(map(() => unwrap(savedGroup.declarations[0].declaration_id))),
				),
			)
			.subscribe({
				next: (idDeclaration) => {
					this.router.navigate(RouteDealer.addresses(unwrap(idDeclaration)));
					this.dialog.closeAll();
				},
				error: (error: unknown) => {
					snackError(this.snackBar, error);
				},
			});
	}

	readonly isChoiceValid = DeclarationsCreationComponent.isChoiceValid;
	protected readonly Declaration = Declaration;
	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
	protected readonly TODAY = TODAY;

	importEFAByCSV(representative: Immutable<Lazy<Representative>>) {
		if (!this.csvFile) {
			return;
		}

		this.csvFile.text().then((csv) => {
			const importData$ = groupCsvRefToObject(csv, this.addressSearch);

			sirenToDeclaration$(this.sirenService, this.importIdentifier, new Set(), true, false, true)
				.pipe(
					map((declaration) => [declaration]),
					map((declarations) =>
						newDeclarationGroup({
							declarations,
							name: this.declarationGroupName,
							representative_id: representative.representative_id,
						}),
					),
					map(DeclarationGroup.toApi),
					switchMap((newGroup) => this.groupService.create$(newGroup)),
					map(DeclarationGroup.fromLazyApi),
					switchMap((group) => {
						return importData$.pipe(
							switchMap((data) =>
								this.declarationService.importEFAByCSV(unwrap(group.declarations[0].declaration_id), data),
							),
							map(Declaration.fromApi),
							map((declaration) => ({ declaration, group })),
						);
					}),
					map(({ declaration, group }) => {
						return produce(group, (draft) => {
							draft.declarations = [declaration];
						});
					}),
					switchMap((savedGroup) =>
						this.representativeState
							.update$(
								produce(representative, (draft) => {
									draft.declaration_groups.push(savedGroup);
								}),
							)
							.pipe(map(() => unwrap(savedGroup.declarations[0].declaration_id))),
					),
					catchError((error: unknown) => {
						snackError(this.snackBar, error);
						throw error;
					}),
				)
				.subscribe((idDeclaration) => {
					this.router.navigate(RouteDealer.addresses(unwrap(idDeclaration)));
					this.dialog.closeAll();
				});
		});
	}

	importEFAByAPI(representative: Immutable<Lazy<Representative>>) {
		let importErrors: string[] = [];
		let newDecla$: Observable<Declaration>;
		if (Rna.isValid(this.apiImportData.structId as Rna)) {
			newDecla$ = rnaToDeclaration$(this.rnaService, this.apiImportData.structId as Rna, false, undefined);
		} else {
			newDecla$ = sirenToDeclaration$(
				this.sirenService,
				this.apiImportData.structId as Siren,
				new Set(),
				true,
				false,
				true,
			);
		}
		newDecla$
			.pipe(
				map((declaration) => {
					declaration.operat_user_key = this.apiImportData.userKey;
					return [declaration];
				}),
				map((declarations) =>
					newDeclarationGroup({
						declarations,
						name: this.declarationGroupName,
						representative_id: representative.representative_id,
					}),
				),
				map(DeclarationGroup.toApi),
				switchMap((newGroup) => this.groupService.create$(newGroup)),
				map(DeclarationGroup.fromLazyApi),
				switchMap((group) => {
					return dialogOpen(
						this.dialog,
						OperatImportLoadingComponent,
						{
							declarationService: this.declarationService,
							declarationFunctionalService: this.declarationFunctionalService,
							addressSearch: this.addressSearch,
							operatService: this.operatApi,
							dataToImport: this.apiImportData,
							declaration: group.declarations[0],
						},
						{ panelClass: "p-0", disableClose: true },
					)
						.afterClosed()
						.pipe(
							switchMap((output) =>
								output
									? of(output).pipe(
											tap(({ errors }) => {
												importErrors = errors;
											}),
											map(({ declaration }) => declaration),
										)
									: this.declarationService
											.getNested$(unwrap(group.declarations[0].declaration_id))
											.pipe(
												tap(() => (importErrors = this.apiImportData.references.map((refData) => refData.refOperat))),
											),
							),
							map(Declaration.fromApi),
							map((declaration) => ({ declaration, group })),
						);
				}),
				map(({ declaration, group }) => {
					return produce(group, (draft) => {
						draft.declarations = [declaration];
					});
				}),
				switchMap((savedGroup) =>
					this.representativeState
						.update$(
							produce(representative, (draft) => {
								draft.declaration_groups.push(savedGroup);
							}),
						)
						.pipe(map(() => unwrap(savedGroup.declarations[0].declaration_id))),
				),
			)
			.subscribe({
				next: (idDeclaration) => {
					this.hiddenRouteParams.push(...importErrors);
					this.router.navigate(RouteDealer.addresses(unwrap(idDeclaration)));
					this.dialog.closeAll();
				},
				error: (error: unknown) => {
					snackError(this.snackBar, error);
				},
			});
	}

	protected readonly Siren = Siren;
	protected readonly ImportTypeEnum = ImportTypeEnum;
}

export function declarationFromOwnerChoice$(
	{ tenantSirets, owner }: Immutable<OwnerChoice>,
	isMandated: boolean,
	sirenService: SirenService,
	rnaService: RnaService,
): Observable<Declaration> {
	return "siren" in owner
		? sirenToDeclaration$(sirenService, owner.siren, tenantSirets, true, isMandated)
		: "rna" in owner
			? rnaToDeclaration$(rnaService, owner.rna, isMandated, {
					sirenService: sirenService,
					sirets: tenantSirets,
				})
			: otherToDeclaration$(owner, isMandated, { sirenService: sirenService, sirets: tenantSirets });
}

export function declarationsFromBasicChoice$(
	{ rnas, sirens, sirets }: Immutable<BasicChoice>,
	isMandated: boolean,
	sirenService: SirenService,
	rnaService: RnaService,
): Observable<Declaration[]> {
	return myForkjoin(Array.from(sirens).map((siren) => sirenService.getUniteLegale$(siren))).pipe(
		switchMap((legalUnits) => {
			const bySiren = new Map<Siren, HasSiren>();
			const byRna = new Map<Rna, HasSiren>();

			// We fetch rnas from siren if possible and create organizations
			for (const legalUnit of legalUnits) {
				const { identifiantAssociationUniteLegale: rna } = legalUnit;
				const { siren } = legalUnit;

				const orga = { siren, sirets: new Set<Siret>() };

				if (rna) {
					byRna.set(rna, orga);
				}
				bySiren.set(siren, orga);
			}

			// We move sirets in an organization with the same siren if the orga exists
			for (const siret of sirets) {
				const siren = Siret.toSiren(siret);
				const orga = bySiren.get(siren);
				if (orga) {
					orga.sirets.add(siret);
				}
			}

			const newSirets = produce(sirets, (draft) => {
				// We delete sirets that was moved
				for (const hasSiren of bySiren.values()) {
					for (const siret of hasSiren.sirets) {
						draft.delete(siret);
					}
				}
			});

			// We create new sirens from the remaining sirets
			const sirens = Array.from(newSirets).map(Siret.toSiren);

			const legalUnits$ = myForkjoin(sirens.map((siren) => sirenService.getUniteLegale$(siren)));

			return zip(legalUnits$, of(newSirets), of(byRna), of(bySiren));
		}),
		map(([legalUnits, sirets, byRna, bySiren]) => {
			// We fetch rnas from siren if possible and create organizations
			for (const legalUnit of legalUnits) {
				const rna = legalUnit.identifiantAssociationUniteLegale;
				const siren = legalUnit.siren;

				const orga = { siren, sirets: new Set<Siret>() };
				if (rna) {
					byRna.set(rna, orga);
				}
				bySiren.set(siren, orga);
			}

			// We move sirets in an organization with the same siren
			// In this case, the organization always exists
			for (const siret of sirets) {
				bySiren.get(Siret.toSiren(siret))?.sirets.add(siret);
			}

			// We create the organizations from the RNAs that are not yet used
			const allOrganizations: (IdRna | HasSiren)[] = [];

			for (const rna of rnas) {
				if (byRna.get(rna) === undefined) {
					allOrganizations.push({ rna });
				}
			}

			return allOrganizations.concat(...bySiren.values());
		}),
		switchMap((allOrganizations) =>
			declarationsFromOrganizations$(allOrganizations, isMandated, rnaService, sirenService),
		),
	);
}

function declarationsFromOrganizations$(
	allOrganizations: (IdRna | HasSiren)[],
	isMandated: boolean,
	rnaService: RnaService,
	sirenService: SirenService,
): Observable<Declaration[]> {
	return forkJoin(
		allOrganizations.map((orga) =>
			"rna" in orga
				? rnaToDeclaration$(rnaService, orga.rna, isMandated)
				: orga.sirets.size > 0
					? sirenToDeclaration$(sirenService, orga.siren, orga.sirets, false, isMandated)
					: fullSirenToDeclaration$(sirenService, orga.siren, isMandated, false),
		),
	);
}
