import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { FunctionalDeclaration, isMultiOccupation } from "../models/functionalDeclaration";

@Pipe({
	name: "isMultiOccupation",
})
export class IsMultiOccupationPipe implements PipeTransform {
	transform(functionalDeclaration: Immutable<FunctionalDeclaration>): boolean {
		return isMultiOccupation(functionalDeclaration);
	}
}
