<div class="dialog-container">
	<div class="dialog-content">
		<h2 class="primary mb-0">Nouvelle version du configurateur</h2>
		<h3 class="primary-light mt-0">Version {{ changelog.version }}</h3>
		<markdown [data]="changelog.texts['fr_FR.UTF-8']"></markdown>
	</div>
	<div class="dialog-actions">
		<button class="btn-primary-reverse" type="submit" mat-dialog-close="">Continuer</button>
	</div>
</div>
