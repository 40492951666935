import { Component, Input } from "@angular/core";
import { LOGO_URL } from "../../helpers/consts";
import { BaseComponent } from "../../models/base-component.directive";
import { DeclarationService } from "../../services/declaration.service";

@Component({
	selector: "app-welcome",
	templateUrl: "./welcome.component.html",
	styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent extends BaseComponent {
	@Input() showPrice = false;
	LOGO_URL = LOGO_URL;
	surface = 0;

	constructor(declarationService: DeclarationService) {
		super();

		this.sub(declarationService.publicStats$(), (stats) => {
			this.surface = stats.assujettiSurface;
		});
	}
}
