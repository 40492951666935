import { Component } from "@angular/core";
import { RouteDealer } from "../../models/routes";
import { BaseComponent } from "../../models/base-component.directive";
import { IsolationStateService } from "../../services/isolation-state.service";
import { Router } from "@angular/router";
import { ResourceInfo, ResourceType } from "../../models/resource";

@Component({
	selector: "app-not-found",
	templateUrl: "./not-found.component.html",
	styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent extends BaseComponent {
	route: string;

	constructor(
		public isolation: IsolationStateService,
		public router: Router,
	) {
		super();
		this.route = "/";
	}

	goToDashboard(isolation: ResourceInfo) {
		if (isolation.type === ResourceType.FunctionalDeclaration) {
			this.router.navigate(RouteDealer.qualification(isolation.id));
		} else {
			this.router.navigate(RouteDealer.myDeclaration(isolation.id));
		}
	}
}
