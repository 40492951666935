import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserId } from "src/app/models/ids";
import { Route, RouteDealer } from "src/app/models/routes";
import { BaseComponent } from "../../models/base-component.directive";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
	selector: "app-reset-password",
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent extends BaseComponent {
	// Form with email and password field
	public newPassword: string = "";
	public confirmPassword: string = "";
	public loading: boolean = false;
	public error: string | null = null;
	Route = Route;
	private token: string | null = null;
	private userId: UserId | null = null;

	constructor(
		private authentication: AuthenticationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		super();
		this.authentication.logout();
		this.activatedRoute.paramMap.subscribe((params) => {
			this.token = params.get("token");
			this.userId = <UserId>params.get("userId");
		});
	}

	public testCredentials(): void {
		this.error = null;
		if (this.newPassword.length < 6) {
			this.error = "Votre mot de passe doit contenir au moins 6 caractères.";
		}
		if (this.newPassword !== this.confirmPassword) {
			this.error = "Les 2 mots de passes doivent correspondre.";
		}
		if (!this.error) {
			this.formSubmit();
		}
	}

	public resetError(): void {
		this.error = null;
	}

	formSubmit(): void {
		if (!this.error && this.token && this.userId) {
			this.loading = true;
			this.authentication.resetPassword$(this.newPassword, this.confirmPassword, this.token, this.userId).subscribe(
				() => {
					this.router.navigate(RouteDealer.login());
					localStorage.removeItem("lastUrl");
				},
				(error: unknown) => {
					this.error = error instanceof HttpErrorResponse ? error.error.error : null;
					this.loading = false;
					localStorage.removeItem("lastUrl");
				},
			);
		}
	}
}
