import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../models/ids";
import { Lazy } from "../models/lazy";

@Pipe({
	name: "findFunctionalDeclarationInList",
})
export class FindFunctionalDeclarationInListPipe implements PipeTransform {
	transform(
		id: FunctionalDeclarationId,
		list: Immutable<Lazy<FunctionalDeclaration>[]>,
	): Immutable<Lazy<FunctionalDeclaration>> | undefined {
		return list.find(({ declaration_functional_id }) => id === declaration_functional_id);
	}
}
