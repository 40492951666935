import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys as Keys } from "@grs/greenkeys";

@Pipe({
	name: "getEfaStatusDescription",
})
export class GetEfaStatusDescriptionPipe implements PipeTransform {
	transform(
		value: Keys.KEY_CONDOMINIUM | Keys.KEY_OWNER_OCCUPANT | Keys.KEY_LANDLORD | Keys.KEY_TENANT | "empty",
	): string {
		switch (value) {
			case Keys.KEY_CONDOMINIUM: {
				return "Je suis une copropriété";
			}
			case Keys.KEY_LANDLORD: {
				return "Je déclare un locataire";
			}
			case Keys.KEY_OWNER_OCCUPANT: {
				return "Je suis propriétaire occupant";
			}
			case Keys.KEY_TENANT: {
				return "Je me déclare locataire d'une autre structure";
			}
			case "empty":
				return "Je déclare un local vacant";
		}
	}
}
