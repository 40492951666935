import { Immutable } from "immer";
import { cloneDeclarationGroup, cloneLazyDeclarationGroup, DeclarationGroup } from "./declarationGroup";
import { DeclarationGroupId, RepresentativeId, UserId } from "./ids";
import { Lazy } from "./lazy";

export interface Representative {
	representative_id: RepresentativeId;
	name: string;
	siret: string;
	owner_id: UserId;
	used_tokens: number;
	tokens: number;
	annual_tokens: number;
	used_annual_tokens: number;
	declaration_groups: DeclarationGroup[];
	params: {
		theme: { [key: string]: string };
	};
	application_name: string;
	can_customize: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Representative {
	/**
	 * Why an API interface? Because sometimes the server gives us garbage we don't care about.
	 * However, this garbage can be important. For example, if `declaration_functional_ids` (in the Declaration interface)
	 * is not correct then the server will delete entities. So to be less error prone we will hide
	 * this "feature" thanks to this interface.
	 * Besides, this makes us able to do changes without modifying the server.
	 */
	export interface Api {
		readonly RepresentativeApi: unique symbol;

		representative_id: RepresentativeId;
		name: string;
		siret: string;
		owner_id: UserId;
		used_tokens: number;
		tokens: number;
		annual_tokens: number;
		used_annual_tokens: number;
		declaration_groups: DeclarationGroup.Api[];
		declaration_group_ids: DeclarationGroupId[];
		params: {
			theme: { [key: string]: string };
		};
		application_name: string;
		can_customize: boolean;
	}

	export type SearchApi = Omit<Representative.Api, "declaration_groups"> & {
		declaration_groups: DeclarationGroup.SearchApi[];
	};
	export type SearchRepresentative = Omit<Representative, "declaration_groups"> & {
		declaration_groups: DeclarationGroup.SearchDeclarationGroup[];
	};
	export type RepresentativeFromApiType<T extends SearchApi | Api> = T extends Api
		? Representative
		: SearchRepresentative;

	export function fromApi<T extends SearchApi | Api>(api: T): RepresentativeFromApiType<T>;
	export function fromApi(api: Api | SearchApi): RepresentativeFromApiType<typeof api> {
		return {
			declaration_groups: api.declaration_groups.map(DeclarationGroup.fromApi),
			name: api.name,
			owner_id: api.owner_id,
			representative_id: api.representative_id,
			siret: api.siret,
			tokens: api.tokens,
			used_tokens: api.used_tokens,
			annual_tokens: api.annual_tokens,
			used_annual_tokens: api.used_annual_tokens,
			params: api.params,
			application_name: api.application_name,
			can_customize: api.can_customize,
		};
	}

	export function fromLazyApi(api: Lazy<Api>): Lazy<Representative> {
		return {
			declaration_groups: api.declaration_groups.map(DeclarationGroup.fromLazyApi),
			name: api.name,
			owner_id: api.owner_id,
			representative_id: api.representative_id,
			siret: api.siret,
			tokens: api.tokens,
			used_tokens: api.used_tokens,
			annual_tokens: api.annual_tokens,
			used_annual_tokens: api.used_annual_tokens,
			params: api.params,
			application_name: api.application_name,
			can_customize: api.can_customize,
		};
	}

	export function toApi(representative: Immutable<Representative>): Immutable<Api> {
		const declarationGroupIds: DeclarationGroupId[] = [];
		for (const { declaration_group_id } of representative.declaration_groups) {
			if (declaration_group_id) {
				declarationGroupIds.push(declaration_group_id);
			}
		}
		const api: Immutable<Omit<Api, "RepresentativeApi">> = {
			declaration_groups: representative.declaration_groups.map(DeclarationGroup.toApi),
			name: representative.name,
			owner_id: representative.owner_id,
			representative_id: representative.representative_id,
			siret: representative.siret,
			tokens: representative.tokens,
			used_tokens: representative.used_tokens,
			annual_tokens: representative.annual_tokens,
			used_annual_tokens: representative.used_annual_tokens,
			declaration_group_ids: declarationGroupIds,
			params: representative.params,
			application_name: representative.application_name,
			can_customize: representative.can_customize,
		};

		return api as Immutable<Api>;
	}

	export function toLazyApi(representative: Immutable<Lazy<Representative>>): Immutable<Lazy<Api>> {
		const declarationGroupIds: DeclarationGroupId[] = [];
		for (const { declaration_group_id } of representative.declaration_groups) {
			if (declaration_group_id) {
				declarationGroupIds.push(declaration_group_id);
			}
		}

		const api: Immutable<Omit<Lazy<Api>, "RepresentativeApi">> = {
			declaration_groups: representative.declaration_groups.map(DeclarationGroup.toLazyApi),
			name: representative.name,
			owner_id: representative.owner_id,
			representative_id: representative.representative_id,
			siret: representative.siret,
			tokens: representative.tokens,
			used_tokens: representative.used_tokens,
			annual_tokens: representative.annual_tokens,
			used_annual_tokens: representative.used_annual_tokens,
			params: representative.params,
			application_name: representative.application_name,
			can_customize: representative.can_customize,
			declaration_group_ids: declarationGroupIds,
		};

		return api as Immutable<Lazy<Api>>;
	}
}

export function cloneRepresentative(representative: Immutable<Representative>): Representative {
	const clone: Representative = {
		...representative,
		declaration_groups: representative.declaration_groups.map(cloneDeclarationGroup),
		params: { ...representative.params, theme: { ...representative.params?.theme } },
	};

	return clone;
}

export function cloneLazyRepresentative(representative: Immutable<Lazy<Representative>>): Lazy<Representative> {
	return {
		...representative,
		declaration_groups: representative.declaration_groups.map(cloneLazyDeclarationGroup),
		params: { ...representative.params, theme: { ...representative.params?.theme } },
	};
}

export type RepresentativeWithOnlyIds = Omit<Representative, "declaration_groups"> & {
	declaration_group_ids: DeclarationGroupId[];
};

export type RepresentativeCounts = {
	entity: RepresentativeWithOnlyIds;
	groupCount: number;
	declarationCount: number;
	entityCount: number;
	tokenConsumedCount: number;
	annualTokenConsumedCount: number;
};
