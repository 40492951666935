<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2 text-icon">
			<mat-icon>domain</mat-icon>
			{{ state.name ? state.name : "Nouvelle entité fonctionnelle" }}
		</h3>
		<div *ngIf="state.operatReference" class="text-secondary mb-2" style="font-style: italic">
			Référence OPERAT : {{ state.operatReference }}
		</div>
		<app-custom-form #form>
			<div class="d-flex-col gap-2">
				<div class="custom-input-group">
					<label required>Nom de l'entité</label>
					<input class="custom-input" [(ngModel)]="state.name" appInputRestriction="alphanumeric" required />
				</div>
				<div class="custom-input-group">
					<label>Date de début de propriété (propriétaire principal)</label>
					<app-start-date-edit
						[date]="state.efaStatus.ownerStartDate ?? ''"
						(changeDate)="state.efaStatus.ownerStartDate = $event"
					></app-start-date-edit>
				</div>
				<app-newly-liable-input
					#newlyLiableInput
					[hidden]="state.hasUsedToken && !state.firstYearDeclaration"
					[(isNewlyLiable)]="isNewlyLiable"
					[(firstYearDeclaration)]="state.firstYearDeclaration"
					[disabled]="state.hasUsedToken"
				></app-newly-liable-input>
				<mat-slide-toggle
					color="primary"
					[checked]="!!state.efaStatus.secondaryOwner"
					(change)="toggleSecondaryOwner()"
				>
					Cette entité a un propriétaire secondaire
				</mat-slide-toggle>
				<ng-container *ngIf="state.efaStatus.secondaryOwner">
					Propriétaire secondaire
					<app-identifier-view
						[identifier]="state.efaStatus.secondaryOwner.owner"
						(editClick)="editOwner(state.efaStatus)"
						(valueChanged)="form.updateFormValidity()"
					></app-identifier-view>
					<div class="custom-input-group">
						<label>Date de début de propriété (propriétaire secondaire)</label>
						<app-start-date-edit
							[date]="state.efaStatus.secondaryOwner.startDate ?? ''"
							(changeDate)="state.efaStatus.secondaryOwner.startDate = $event"
						></app-start-date-edit>
					</div>
				</ng-container>
				<ng-container *ngIf="state.efaStatus.type === 'siren'">
					<mat-form-field appearance="outline">
						<mat-label>Mon cas</mat-label>
						<mat-select
							[value]="
								state.efaStatus.status.type === Keys.KEY_LANDLORD && !state.efaStatus.status.data.tenant
									? 'empty'
									: state.efaStatus.status.type
							"
							(selectionChange)="changeStatusType(state.efaStatus, $event)"
							required
						>
							<mat-select-trigger>
								<span class="d-flex" style="align-items: center">
									<mat-icon style="margin-right: 16px">
										{{
											(state.efaStatus.status.type === Keys.KEY_LANDLORD && !state.efaStatus.status.data.tenant
												? "empty"
												: state.efaStatus.status.type
											) | getIconFromEfaStatus
										}}
									</mat-icon>
									{{
										(state.efaStatus.status.type === Keys.KEY_LANDLORD && !state.efaStatus.status.data.tenant
											? "empty"
											: state.efaStatus.status.type
										) | getEfaStatusDescription
									}}
								</span>
							</mat-select-trigger>
							<mat-option *ngFor="let statusType of STATUS_TYPES" [value]="statusType">
								<mat-icon>{{ statusType | getIconFromEfaStatus }}</mat-icon>
								{{ statusType | getEfaStatusDescription }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<ng-container *ngIf="state.efaStatus.status.type === Keys.KEY_OWNER_OCCUPANT">
						<mat-slide-toggle
							color="primary"
							[checked]="state.efaStatus.status.data.without_siret"
							(change)="changeOwnerOccupantWithoutSiret(state.efaStatus.status)"
						>
							Cette entité est une collectivité ou un opérateur de l'état sans SIRET
						</mat-slide-toggle>
						<mat-slide-toggle
							color="primary"
							[checked]="
								state.efaStatus.status.data.condominium !== null &&
								state.efaStatus.status.data.condominium !== undefined
							"
							(change)="toggleCondominium(state.efaStatus.status.data)"
						>
							Cette entité dépend d'un syndicat de copropriété
						</mat-slide-toggle>
						<ng-container *ngIf="state.efaStatus.status.data.without_siret">
							<div class="custom-input-group">
								<label required>Dénomination de l'entité</label>
								<input
									class="custom-input"
									[(ngModel)]="state.efaStatus.status.data.denomination"
									appInputRestriction="alphanumeric"
									required
								/>
							</div>
						</ng-container>
						<ng-container *ngIf="!state.efaStatus.status.data.without_siret">
							<div class="custom-input-group">
								<label required>SIRET de l'entité</label>
								<app-siret-input
									(valueChange)="form.updateFormValidity()"
									*ngFor="let idSiren of [state.structure | asSiren]"
									[(siret)]="state.efaStatus.status.data.siret"
									(siretChange)="setMainSiret($event)"
									[placeholder]="idSiren ? idSiren.siren + 'XXXXX' : ''"
								></app-siret-input>
							</div>
						</ng-container>
						<ng-container *ngIf="state.efaStatus.status.data.condominium as condominium">
							Syndicat de copropriété
							<app-identifier-view
								[identifier]="condominium"
								(editClick)="editCondominium(state.efaStatus.status.data)"
								(valueChanged)="form.updateFormValidity()"
							></app-identifier-view>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="state.efaStatus.status.type === Keys.KEY_LANDLORD">
						<mat-slide-toggle
							*ngIf="state.efaStatus.status.data.tenant"
							color="primary"
							[checked]="state.efaStatus.status.data.tenant_without_siret"
							(change)="changeOwnerWithoutSiret(state.efaStatus.status)"
						>
							Cette entité héberge une collectivité ou un opérateur de l'état sans SIRET
						</mat-slide-toggle>
						<mat-slide-toggle
							color="primary"
							[checked]="
								state.efaStatus.status.data.condominium !== null &&
								state.efaStatus.status.data.condominium !== undefined
							"
							(change)="toggleCondominium(state.efaStatus.status.data)"
						>
							Cette entité dépend d'un syndicat de copropriété
						</mat-slide-toggle>
						<ng-container *ngIf="!state.efaStatus.status.data.tenant_without_siret">
							<ng-container *ngIf="state.efaStatus.status.data.tenant as tenant">
								Occupant
								<app-identifier-view
									(valueChanged)="form.updateFormValidity()"
									[identifier]="tenant"
									(editClick)="editTenant(state.efaStatus.status.data)"
								></app-identifier-view>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="state.efaStatus.status.data.tenant_without_siret">
							<div class="custom-input-group">
								<label required>SIREN de l'occupant</label>
								<input
									#sirenInput
									class="custom-input"
									[(ngModel)]="state.efaStatus.status.data.tenant.siren"
									minlength="9"
									maxlength="9"
									required
								/>
								<span class="custom-input-error">{{ sirenInput.validationMessage }}</span>
							</div>
							<div class="custom-input-group">
								<label required>Dénomination de l'occupant</label>
								<input
									class="custom-input"
									[(ngModel)]="state.efaStatus.status.data.denomination"
									appInputRestriction="alphanumeric"
									required
								/>
							</div>
						</ng-container>
						<ng-container *ngIf="state.efaStatus.status.data.condominium as condominium">
							Syndicat de copropriété
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="condominium"
								(editClick)="editCondominium(state.efaStatus.status.data)"
							></app-identifier-view>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="state.efaStatus.status.type === 'tenant'">
						<mat-slide-toggle
							color="primary"
							[checked]="state.efaStatus.status.data.without_siret"
							(change)="changeTenantWithoutSiret(state.efaStatus.status)"
						>
							Cette entité est une collectivité ou un opérateur de l'état sans SIRET
						</mat-slide-toggle>
						<mat-slide-toggle
							color="primary"
							[checked]="
								state.efaStatus.status.data.condominium !== null &&
								state.efaStatus.status.data.condominium !== undefined
							"
							(change)="toggleCondominium(state.efaStatus.status.data)"
						>
							Cette entité dépend d'un syndicat de copropriété
						</mat-slide-toggle>
						<ng-container *ngIf="state.efaStatus.status.data.without_siret">
							<div class="custom-input-group">
								<label required>Dénomination de l'entité</label>
								<input
									class="custom-input"
									[(ngModel)]="state.efaStatus.status.data.denomination"
									appInputRestriction="alphanumeric"
									required
								/>
							</div>
						</ng-container>
						<ng-container *ngIf="!state.efaStatus.status.data.without_siret">
							<div class="custom-input-group">
								<label>SIRET de l'entité</label>
								<app-siret-input
									*ngFor="let idSiren of [state.structure | asSiren]"
									[(siret)]="state.efaStatus.status.data.siret"
									(siretChange)="setMainSiret($event)"
									[placeholder]="idSiren ? idSiren.siren + 'XXXXX' : ''"
								>
								</app-siret-input>
							</div>
						</ng-container>
						Propriétaire
						<app-identifier-view
							(valueChanged)="form.updateFormValidity()"
							[identifier]="state.efaStatus.status.data.owner"
							(editClick)="editOwner(state.efaStatus.status.data)"
						></app-identifier-view>
						<ng-container *ngIf="state.efaStatus.status.data.condominium as condominium">
							Syndicat de copropriété
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="condominium"
								(editClick)="editCondominium(state.efaStatus.status.data)"
							></app-identifier-view>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="state.efaStatus.status.type === 'condominium'">
						<mat-slide-toggle
							color="primary"
							[checked]="state.efaStatus.status.data.tenant_without_siret"
							(change)="changeCondominiumWithoutSiret(state.efaStatus.status)"
						>
							L'occupant de cette entité est une collectivité ou un opérateur de l'état sans SIRET
						</mat-slide-toggle>
						<ng-container *ngIf="!state.efaStatus.status.data.tenant_without_siret">
							<mat-slide-toggle
								color="primary"
								[checked]="
									state.efaStatus.status.data.tenant !== null && state.efaStatus.status.data.tenant !== undefined
								"
								(change)="toggleTenant(state.efaStatus.status.data)"
							>
								Cette entité a un occupant
							</mat-slide-toggle>
							<ng-container *ngIf="state.efaStatus.status.data.tenant as tenant">
								Occupant
								<app-identifier-view
									(valueChanged)="form.updateFormValidity()"
									[identifier]="tenant"
									(editClick)="editTenant(state.efaStatus.status.data)"
								></app-identifier-view>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="state.efaStatus.status.data.tenant_without_siret">
							<div class="custom-input-group">
								<label required>Dénomination de l'occupant</label>
								<input
									class="custom-input"
									[(ngModel)]="state.efaStatus.status.data.denomination"
									appInputRestriction="alphanumeric"
									required
								/>
							</div>
							<div class="custom-input-group">
								<label required>SIREN de l'occupant</label>
								<input
									#sirenInput
									class="custom-input"
									[(ngModel)]="state.efaStatus.status.data.tenant.siren"
									minlength="9"
									maxlength="9"
									required
								/>
								<span class="custom-input-error">{{ sirenInput.validationMessage }}</span>
							</div>
						</ng-container>
						Propriétaire
						<app-identifier-view
							(valueChanged)="form.updateFormValidity()"
							[identifier]="state.efaStatus.status.data.owner"
							(editClick)="editOwner(state.efaStatus.status.data)"
						></app-identifier-view>
						<div class="custom-input-group">
							<label>SIRET du syndicat de copropriété</label>
							<app-siret-input
								*ngFor="let idSiren of [state.structure | asSiren]"
								[(siret)]="state.efaStatus.status.data.siret"
								(siretChange)="setMainSiret($event)"
								[placeholder]="idSiren ? idSiren.siren + 'XXXXX' : ''"
							>
							</app-siret-input>
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="state.efaStatus.type === Keys.KEY_RNA">
					<mat-form-field appearance="outline">
						<mat-label>Mon cas</mat-label>
						<mat-select
							[value]="
								state.efaStatus.status.type === Keys.KEY_LANDLORD && !state.efaStatus.status.tenant
									? 'empty'
									: state.efaStatus.status.type
							"
							(selectionChange)="changeStatusRnaOrOtherType(state.efaStatus, $event, state.structure)"
						>
							<mat-select-trigger>
								<span class="d-flex" style="align-items: center">
									<mat-icon style="margin-right: 16px">
										{{
											(state.efaStatus.status.type === Keys.KEY_LANDLORD && !state.efaStatus.status.tenant
												? "empty"
												: state.efaStatus.status.type
											) | getIconFromEfaStatus
										}}
									</mat-icon>
									{{
										(state.efaStatus.status.type === Keys.KEY_LANDLORD && !state.efaStatus.status.tenant
											? "empty"
											: state.efaStatus.status.type
										) | getEfaStatusDescription
									}}
								</span>
							</mat-select-trigger>
							<ng-container *ngIf="state.structure | asRna; else optionIsOther">
								<mat-option *ngFor="let statusType of STATUS_TYPES" [value]="statusType">
									<mat-icon>{{ statusType | getIconFromEfaStatus }}</mat-icon>
									{{ statusType | getEfaStatusDescription }}
								</mat-option>
							</ng-container>
							<ng-template #optionIsOther>
								<mat-option *ngFor="let statusType of STATUS_TYPES_FOR_OTHER" [value]="statusType">
									<mat-icon>{{ statusType | getIconFromEfaStatus }}</mat-icon>
									{{ statusType | getEfaStatusDescription }}
								</mat-option>
							</ng-template>
						</mat-select>
					</mat-form-field>

					<ng-container *ngIf="state.efaStatus.status.type === Keys.KEY_OWNER_OCCUPANT">
						<mat-slide-toggle
							color="primary"
							[checked]="
								state.efaStatus.status.condominium !== null && state.efaStatus.status.condominium !== undefined
							"
							(change)="toggleCondominium(state.efaStatus.status)"
						>
							Cette entité dépend d'un syndicat de copropriété
						</mat-slide-toggle>
						<ng-container *ngIf="state.efaStatus.status.condominium as condominium">
							Syndicat de copropriété
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="condominium"
								(editClick)="editCondominium(state.efaStatus.status)"
							></app-identifier-view>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="state.efaStatus.status.type === Keys.KEY_LANDLORD">
						<mat-slide-toggle
							color="primary"
							[checked]="
								state.efaStatus.status.condominium !== null && state.efaStatus.status.condominium !== undefined
							"
							(change)="toggleCondominium(state.efaStatus.status)"
						>
							Cette entité dépend d'un syndicat de copropriété
						</mat-slide-toggle>
						<ng-container *ngIf="state.efaStatus.status.tenant as tenant">
							Occupant
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="tenant"
								(editClick)="editTenant(state.efaStatus.status)"
							></app-identifier-view>
						</ng-container>
						<ng-container *ngIf="state.efaStatus.status.condominium as condominium">
							Syndicat de copropriété
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="condominium"
								(editClick)="editCondominium(state.efaStatus.status)"
							></app-identifier-view>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="state.efaStatus.status.type === 'tenant'">
						<mat-slide-toggle
							color="primary"
							[checked]="
								state.efaStatus.status.condominium !== null && state.efaStatus.status.condominium !== undefined
							"
							(change)="toggleCondominium(state.efaStatus.status)"
						>
							Cette entité dépend d'un syndicat de copropriété
						</mat-slide-toggle>
						Propriétaire
						<app-identifier-view
							(valueChanged)="form.updateFormValidity()"
							[identifier]="state.efaStatus.status.owner"
							(editClick)="editOwner(state.efaStatus.status)"
						></app-identifier-view>
						<ng-container *ngIf="state.efaStatus.status.condominium as condominium">
							Syndicat de copropriété
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="condominium"
								(editClick)="editCondominium(state.efaStatus.status)"
							></app-identifier-view>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="state.efaStatus.status.type === 'condominium'">
						<mat-slide-toggle
							color="primary"
							[checked]="state.efaStatus.status.tenant !== null && state.efaStatus.status.tenant !== undefined"
							(change)="toggleTenant(state.efaStatus.status)"
						>
							Cette entité a un occupant
						</mat-slide-toggle>
						<ng-container *ngIf="state.efaStatus.status.tenant as tenant">
							Occupant
							<app-identifier-view
								(valueChanged)="form.updateFormValidity()"
								[identifier]="tenant"
								(editClick)="editTenant(state.efaStatus.status)"
							></app-identifier-view>
						</ng-container>
						Propriétaire
						<app-identifier-view
							(valueChanged)="form.updateFormValidity()"
							[identifier]="state.efaStatus.status.owner"
							(editClick)="editOwner(state.efaStatus.status)"
						></app-identifier-view>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="needsAddress">
					<div class="custom-input-group" *ngIf="state.selectedAddress === undefined">
						<label required>Adresse de l'entité</label>
						<div class="d-flex gap-2">
							<input
								class="custom-input w-100"
								#addressInput
								placeholder="Adresse"
								type="search"
								required
								[matAutocomplete]="customComplete"
								(input)="searchAddress(addressInput.value)"
							/>
							<button *ngIf="!state.selectedAddress" class="btn-stroked-primary" (click)="openAddressModal()">
								Saisir manuellement
							</button>
						</div>
						<mat-autocomplete #customComplete (optionSelected)="selectAddress($event.option.value)">
							<mat-option *ngFor="let option of state.addressInfoStream$ | async" [value]="option">
								{{ option | addressToString }}
							</mat-option>
						</mat-autocomplete>
					</div>
					<button
						*ngIf="state.selectedAddress"
						mat-flat-button
						class="btn-stroked-primary w-100"
						(click)="state.selectedAddress = undefined"
					>
						<span class="text-icon">
							<mat-icon>replay</mat-icon>
							{{ state.selectedAddress | addressToString }}
						</span>
					</button>
				</ng-container>
				<ng-container>
					<div class="collapse-container" [class.disabled]="!hasConfirmedAddEfa">
						<div class="collapse-header" (click)="switchCollapseState()">
							<span class="d-flex" style="justify-content: flex-start">
								<div class="d-flex-col" style="justify-content: center">Mon EFA est liée à d'autres EFA</div>
								<div class="d-flex-col" style="justify-content: center">
									<app-help (click)="$event.stopPropagation()" [subject]="HelpSubject.LinkedEfa"></app-help>
								</div>
							</span>
							<div class="d-flex-col" style="justify-content: center">
								<mat-icon>{{ collapseOpen ? "expand_less" : "expand_more" }}</mat-icon>
							</div>
						</div>
						<div class="collapse-body" [@collapse]="collapseState">
							<ul style="margin-bottom: 0">
								<ng-container *ngFor="let linkedEfa of state.linkedEfas; let index = index">
									<li
										*ngIf="index < linkedEfaIndex && !!linkedEfa"
										class="d-flex"
										style="justify-content: space-between; text-align: center"
									>
										<div class="d-flex-col" style="justify-content: center">EFA {{ index + 1 }}</div>
										<div class="custom-input-group" style="padding: 5px">
											<!--Inputs only required if one of the field is not empty-->
											<input
												class="custom-input"
												placeholder="Référence OPERAT"
												[(ngModel)]="linkedEfa.refOperat"
												appInputRestriction="alphanumeric"
												required="{{ !!linkedEfa.surfaceEfa }}"
											/>
											<span class="custom-input-error">Veuillez completer ce champ</span>
										</div>
										<div class="custom-input-group" style="padding: 5px">
											<input
												class="custom-input"
												placeholder="Surface liée"
												[(ngModel)]="linkedEfa.surfaceEfa"
												type="number"
												required="{{ !!linkedEfa.refOperat }}"
												[min]="1"
											/>
											<span class="custom-input-error">La surface ne peut pas être nulle</span>
										</div>
										<div class="d-flex-col" style="justify-content: center">
											<button mat-icon-button aria-label="Supprimer l'EFA liée" (click)="removeLinkedEfa(index)">
												<mat-icon color="warn">delete</mat-icon>
											</button>
										</div>
									</li>
								</ng-container>
							</ul>
							<button
								mat-icon-button
								aria-label="Ajouter une EFA liée"
								style="position: relative; left: 2%"
								(click)="addLinkedEfa()"
								*ngIf="linkedEfaIndex < this.state.linkedEfas.length"
							>
								<mat-icon color="primary">add_circle</mat-icon>
							</button>
						</div>
					</div>
				</ng-container>
			</div>
		</app-custom-form>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" (click)="exit()">Annuler</button>
		<button *ngIf="state.canDelete" class="btn-stroked-danger" (click)="delete()">Supprimer</button>
		<button
			[disabled]="
				!form.formValid || (needsAddress && state.selectedAddress === undefined) || !newlyLiableInput.isValid()
			"
			class="btn-stroked-primary"
			(click)="save()"
		>
			{{ state.adding ? "Ajouter" : "Modifier" }}
		</button>
	</div>
</div>

<ng-template #confirmAddLinkedEfa>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="mt-0 mb-2 text-icon">Avertissement</h3>
			<div>Souhaitez-vous réellement utiliser la fonctionnalité d'EFA liées pour cette entité ?</div>
			<div>
				Les données des EFA liées seront importées, les données de consommations, l'année de référence et les objectifs
				associés seront écrasés.
			</div>
			<div class="dialog-small-text mt-5">Voulez-vous tout de même continuer ?</div>
			<div class="d-flex mt-5" style="justify-content: space-between">
				<button mat-flat-button [mat-dialog-close]="undefined">Annuler</button>
				<button mat-flat-button color="warn" [mat-dialog-close]="true">Confirmer</button>
			</div>
		</div>
	</div>
</ng-template>
