import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { getIdentifierStringFromEfaStatus } from "../models/efaStatus";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { Lazy } from "../models/lazy";

@Pipe({
	name: "functionalDeclarationSubtitle",
})
export class FunctionalDeclarationSubtitlePipe implements PipeTransform {
	transform({ efa_status }: Immutable<Lazy<FunctionalDeclaration>>): string {
		return getIdentifierStringFromEfaStatus(efa_status);
	}
}
