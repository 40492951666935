<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content d-flex gap-2 pt-0" style="flex-direction: column">
		<h2 class="primary">Accès a la structure via API</h2>
		<div>L'accès à cette structure API est impossible car l'autorisation d'accès n'y a pas encore été donnée.</div>
		<div>
			Merci de renseigner Trace Software comme tiers autorisé sur le compte OPERAT de cette structure, en vous rendant
			dans l'onglet "Paramétrage - Echanges par API".
		</div>
	</div>

	<div class="dialog-actions">
		<button class="btn-danger-reverse ml-2" type="submit" [mat-dialog-close]="undefined">Ok</button>
	</div>
</div>
