import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { EnergyCategory } from "../../../../../../../models/energyCategory";
import { FunctionalDeclaration } from "../../../../../../../models/functionalDeclaration";
import { HelpSubject } from "../../../../../../help/help.component";

@Component({
	selector: "app-consumption-table-export",
	templateUrl: "./consumption-table-export.component.html",
	styleUrls: ["../consumption-table.component.scss"],
})
export class ConsumptionTableExportComponent {
	@Input() year!: number;
	@Input() functionalDeclaration!: FunctionalDeclaration;

	@ViewChildren("consumptionTable") consumptionTables!: QueryList<ElementRef>;

	HelpSubject = HelpSubject;
	EnergyCategory = EnergyCategory;

	HEADERS_MONTHLY = ["Jan.", "Fév.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];
}
