<div class="setting-page-container">
	<div *ngIf="representative" class="setting-page">
		<h2 class="primary-light mb-0">Paramétrage</h2>
		<form #form>
			<div class="d-flex">
				<div class="w-50 pr-4 d-flex-col gap-2" [class.br-1px-text-secondary]="representative.can_customize">
					<h3 class="text-secondary mb-0">Vos informations</h3>
					<div>
						<label required>Nom de l'entreprise</label>
						<input
							class="custom-input w-50"
							required
							[(ngModel)]="representative.name"
							name="name"
							(ngModelChange)="update()"
						/>
					</div>
					<div *ngIf="representative.can_customize">
						<label required>Nom de l'application</label>
						<div class="custom-input w-50">
							<input
								pattern="[a-z0-9]+"
								#applicationNameInput
								required
								[(ngModel)]="representative.application_name"
								name="application_name"
								(ngModelChange)="update()"
							/>
							<span class="input-suffix mr-1">.dt.greensystemes.com</span>
						</div>
						<span class="text-gray"
							>Détermine le chemin d'accès à votre version, exemple: xxxxx.dt.greensystemes.com</span
						>
						<span class="custom-input-error" [class.d-block]="applicationNameInput.validationMessage">{{
							applicationNameInput.validationMessage
						}}</span>
					</div>
					<h3 class="text-secondary mb-0">Membres</h3>
					<ul class="user-list">
						<li *ngFor="let user of users" class="user">
							<div style="max-width: 32px">
								<app-avatar [user]="user"></app-avatar>
							</div>
							<span>{{ user.first_name }} {{ user.name }}</span>
							<div
								class="ml-auto"
								*ngIf="
									currentUser && user.user_id !== currentUser.user_id && currentUser.user_id === representative.owner_id
								"
							>
								<mat-icon class="danger pointer" (click)="removeUser(user)">delete</mat-icon>
							</div>
						</li>
					</ul>
				</div>
				<div class="w-50 pl-4 d-flex-col gap-2" *ngIf="representative.can_customize">
					<h3 class="text-secondary mb-0">Thème</h3>
					<div class="d-flex gap-2">
						<div class="w-100">
							<label>Couleur principale</label>
							<app-color-picker
								[(value)]="representative.params.theme.primary"
								(valueChange)="setTheme()"
							></app-color-picker>
						</div>

						<div class="w-100">
							<label>Couleur primaire légère</label>
							<app-color-picker
								[(value)]="representative.params.theme.primary_light"
								(valueChange)="setTheme()"
							></app-color-picker>
						</div>
					</div>
					<div class="d-flex gap-2">
						<div class="w-100">
							<label>Couleur secondaire 1</label>
							<app-color-picker
								[(value)]="representative.params.theme.accent"
								(valueChange)="setTheme()"
							></app-color-picker>
						</div>

						<div class="w-100">
							<label>Couleur secondaire 2</label>
							<app-color-picker
								[(value)]="representative.params.theme.accent2"
								(valueChange)="setTheme()"
							></app-color-picker>
						</div>
					</div>
					<div class="d-flex gap-2">
						<div class="w-100">
							<label>Couleur secondaire 3</label>
							<app-color-picker
								[(value)]="representative.params.theme.accent3"
								(valueChange)="setTheme()"
							></app-color-picker>
						</div>

						<div class="w-100">
							<label>Couleur texte secondaire</label>
							<app-color-picker
								[(value)]="representative.params.theme['text-secondary']"
								(valueChange)="setTheme()"
							></app-color-picker>
						</div>
					</div>
					<div class="d-flex gap-2">
						<div class="w-100">
							<label>Couleur d'avertissement</label>
							<app-color-picker
								[(value)]="representative.params.theme.warning"
								(change)="setTheme()"
							></app-color-picker>
						</div>

						<div class="w-100">
							<label>Couleur d'erreur</label>
							<app-color-picker [(value)]="representative.params.theme.danger" (change)="setTheme()"></app-color-picker>
						</div>
					</div>
					<div class="d-flex-col gap-3">
						<div>
							<app-image-input #logo name="logo" label="Logo" (fileUploaded)="update()"></app-image-input>
							<span class="text-gray">Logo principal.</span>
						</div>
						<div>
							<app-image-input
								#smallLogo
								name="logo_small"
								label="Petit logo"
								(fileUploaded)="update()"
							></app-image-input>
							<span class="text-gray"
								>Logo en petit (carré de préférence), utilisé lorsque le menu dépliant de gauche est replié.</span
							>
						</div>
						<div>
							<app-image-input
								#icon
								name="icon"
								label="Icone"
								(fileUploaded)="update()"
								placeholder="Choisir une image (ICO) ..."
								accept=".ico,image/x-icon"
							></app-image-input>
							<span class="text-gray">Icône utilisée pour l'onglet dans le navigateur.</span>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="save-button" [class.show]="toUpdate">
			<button mat-button class="btn-primary" [class.btn-loading]="saving" (click)="save()">
				<mat-icon>save</mat-icon>
				Sauvegarder
			</button>
		</div>
	</div>
</div>
