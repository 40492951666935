import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";

@Pipe({
	name: "inArray",
})
export class InArrayPipe implements PipeTransform {
	transform<T>(value: T, array: Immutable<T[]>): boolean {
		return array.find((val) => val === value) !== undefined;
	}
}
