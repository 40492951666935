import { Immutable } from "immer";
import {
	ASSET_PAGE,
	CONSO_PAGE,
	FILE_PAGE,
	Page,
	QUALIFICATION_PAGE,
	SEARCH_PAGE,
} from "../components/navigation/stepper/stepper.component";
import { EnergyCategory } from "../models/energyCategory";
import {
	entityCanAccessPremiumFeatures,
	FunctionalDeclaration,
	isMultiOccupation,
} from "../models/functionalDeclaration";
import { keys } from "./immutable";

export enum Reason {
	NoParcel,
	NoMainParcel,
	NoConsumption,
	NoStation,
	NoCategories,
	NoReferenceYear,
	OldFormat,
	CsvFile,
	NoBuilding,
	NoLocalArea,
}

export function intToReason(int: string): Reason | undefined {
	switch (int) {
		case "0":
			return Reason.NoParcel;
		case "1":
			return Reason.NoMainParcel;
		case "2":
			return Reason.NoConsumption;
		case "3":
			return Reason.NoStation;
		case "4":
			return Reason.NoCategories;
		case "5":
			return Reason.NoReferenceYear;
		case "6":
			return Reason.OldFormat;
		case "7":
			return Reason.CsvFile;
		case "8":
			return Reason.NoBuilding;
		case "9":
			return Reason.NoLocalArea;
	}
	return undefined;
}

export function reasonToText(reason: Reason) {
	switch (reason) {
		case Reason.NoParcel:
			return "Vous n'avez pas choisi de parcelles cadastrales.";
		case Reason.NoMainParcel:
			return "Vous n'avez pas choisi une parcelle principale.";
		case Reason.NoConsumption:
			return "Vous n'avez pas renseigné de consommations.";
		case Reason.NoStation:
			return "Vous n'avez pas choisi une station météo.";
		case Reason.NoCategories:
			return "Vous n'avez pas renseigné de surfaces.";
		case Reason.NoReferenceYear:
			return "Cette entité fonctionnelle n'a pas une année de référence pour ses consommations.";
		case Reason.OldFormat:
			return "Vous avez des parcelles ou des bâtiments avec un ancien format.";
		case Reason.CsvFile:
			return "Vous n'avez pas télécharger le fichier CSV.";
		case Reason.NoBuilding:
			return "Vous n'avez pas choisi de bâtiments.";
		case Reason.NoLocalArea:
			return "Vous n'avez pas renseigné la surface des locaux techniques.";
	}
}

/**
 * Will check if the step is done. If not, will return a text that tells why.
 * @param page The step to check
 * @param functionalDeclaration the functional declaration to check
 * @returns A string if the step is not done, null if the step is done
 */
export function hasNotDoneStep(page: Page, functionalDeclaration: Immutable<FunctionalDeclaration>): Reason | null {
	let beforeResult;
	switch (page) {
		case QUALIFICATION_PAGE:
			if (functionalDeclaration.is_imported_from_operat) {
				return null;
			}

			if (functionalDeclaration.infos.parcelles.length === 0) {
				return Reason.NoParcel;
			}

			if (functionalDeclaration.infos.buildingInfos.length === 0) {
				return Reason.NoBuilding;
			}

			if (
				functionalDeclaration.infos.parcelles.some((parcel) => parcel.type === "FeatureCollection") ||
				functionalDeclaration.infos.buildingInfos.some(({ building }) => building.type === "FeatureCollection")
			) {
				return Reason.OldFormat;
			}
			return null;
		case ASSET_PAGE:
			beforeResult = hasNotDoneStep(QUALIFICATION_PAGE, functionalDeclaration);
			if (beforeResult) {
				return beforeResult;
			}
			switch (true) {
				case !functionalDeclaration.infos.asset.mainParcel && !functionalDeclaration.is_imported_from_operat:
					return Reason.NoMainParcel;
				case functionalDeclaration.infos.asset.station === undefined && !functionalDeclaration.is_imported_from_operat:
					return Reason.NoStation;
				case typeof functionalDeclaration.infos.asset.localArea !== "number" &&
					!functionalDeclaration.is_imported_from_operat:
					return Reason.NoLocalArea;
				case functionalDeclaration.infos.asset.categories.length === 0:
					return Reason.NoCategories;
				default:
					return null;
			}
		case CONSO_PAGE:
			beforeResult = hasNotDoneStep(ASSET_PAGE, functionalDeclaration);
			if (beforeResult) {
				return beforeResult;
			}
			return !entityCanAccessPremiumFeatures(functionalDeclaration) ||
				(keys(functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL] ?? []).length < 1 &&
					(!isMultiOccupation(functionalDeclaration) ||
						(keys(functionalDeclaration.consumptions[EnergyCategory.COMMON] ?? []).length < 1 &&
							keys(functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED] ?? []).length < 1)))
				? Reason.NoConsumption
				: null;
		case SEARCH_PAGE:
			beforeResult = hasNotDoneStep(CONSO_PAGE, functionalDeclaration);
			if (beforeResult) {
				return beforeResult;
			}
			return functionalDeclaration.infos.referenceYear === undefined && !functionalDeclaration.is_imported_from_operat
				? Reason.NoReferenceYear
				: null;
		case FILE_PAGE:
			beforeResult = hasNotDoneStep(SEARCH_PAGE, functionalDeclaration);
			if (beforeResult) {
				return beforeResult;
			}
			return Reason.CsvFile;
	}

	return null;
}
