<div class="dialog-container" style="min-width: 650px">
	<div class="dialog-close">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
	</div>
	<div class="dialog-content">
		<div class="d-flex gap-3">
			<div class="w-50">
				<h3 class="mt-0">Utilisateurs autorisés</h3>
				<div class="d-flex-col gap-2">
					<div class="d-flex gap-2" style="align-items: center" *ngFor="let user of users">
						<div style="width: 32px; height: 32px">
							<app-avatar [user]="user"></app-avatar>
						</div>
						<span
							>{{ user.first_name }} {{ user.name }}
							<span *ngIf="user.user_id === currentUserId" class="text-gray">(vous)</span></span
						>
						<mat-icon
							*ngIf="ownerId !== user.user_id && (ownerId === currentUserId || parentResourceOwnerId === currentUserId)"
							class="danger pointer ml-auto"
							(click)="removeUser(user)"
						>
							delete
						</mat-icon>
					</div>
				</div>
			</div>
			<div class="w-50 bl-1px-text-gray pl-3">
				<div>
					<h3 class="mt-0">Inviter un utilisateur</h3>
					<div class="message-accent mb-2" *ngIf="this.invitationMessage !== undefined">
						{{ this.invitationMessage }}
					</div>
					<div class="message-danger mb-2" *ngIf="this.invitationError !== undefined">{{ this.invitationError }}</div>
					<form #invitationForm="ngForm" (ngSubmit)="invite()">
						<div class="d-flex-col gap-2">
							<div class="custom-input-group">
								<label>Email</label>
								<input
									type="text"
									class="custom-input"
									required
									[(ngModel)]="invitationModel.email"
									(ngModelChange)="resetValidation()"
									name="email"
								/>
							</div>
							<div class="custom-input-group">
								<label>Name</label>
								<input
									type="text"
									class="custom-input"
									required
									[(ngModel)]="invitationModel.name"
									(ngModelChange)="resetValidation()"
									name="name"
									minlength="1"
								/>
							</div>
							<mat-checkbox
								color="primary"
								*ngIf="!this.forceTemporary"
								[(ngModel)]="invitationModel.isTemporary"
								name="isTemporary"
							>
								Invitation temporaire
							</mat-checkbox>
							<button
								class="btn-primary"
								style="justify-content: center"
								[disabled]="!invitationForm.valid || this.invitationSending"
							>
								Envoyer
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-primary" [mat-dialog-close]="undefined">Fermer</button>
	</div>
</div>
