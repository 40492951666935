import { Component } from "@angular/core";
import { Immutable } from "immer";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { HelpSubject } from "src/app/components/help/help.component";
import { TupleToUnion } from "src/app/helpers/tupleToUnion";
import { AgreementAsk, cloneAgreementAsk } from "src/app/models/agreementAsk";
import { EnergyCategory, getEnergyCategoriesToAdd } from "src/app/models/energyCategory";
import { FunctionalDeclaration, isMultiOccupation } from "src/app/models/functionalDeclaration";
import { HolderType } from "src/app/models/HolderType";
import { dialogOpen, Modal } from "src/app/models/modal";
import { getSiretCompatibleWithStructureIfExists } from "../../../../../../models/efaStatus";
import { CantGetAutomaticallyReasonPipe } from "../../../../../../pipes/cant-get-automatically-reason.pipe";
import { AgreementService } from "../../../../../../services/agreement.service";

type Input = {
	agreement: Immutable<AgreementAsk>;
	canDelete?: boolean;
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	adding: boolean;
	agreementService: AgreementService;
};
type State = {
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	energyCategoriesAddable: Set<EnergyCategory>;
	agreement: AgreementAsk;
	canDelete: boolean;
	adding: boolean;
	canEdit: boolean;
};
// AgreementAsk to update
// true to delete
// undefined to ignore
type Output = AgreementAsk | true | undefined;

@Component({
	selector: "app-agreements-modal",
	templateUrl: "./agreement-modal.component.html",
	styleUrls: ["./agreement-modal.component.scss"],
})
export class AgreementModalComponent extends Modal<Input, State, Output> {
	readonly HolderTypeEnum = HolderType;
	readonly HelpSubject = HelpSubject;

	prmConfirmed: boolean | undefined;
	prmAddress: string | false | undefined;
	private agreementService!: AgreementService;

	inputToState({ functionalDeclaration, agreement, canDelete, adding, agreementService }: Input): State {
		this.agreementService = agreementService;
		const myAgreement = cloneAgreementAsk(agreement);
		if (myAgreement.postal_code == undefined) {
			myAgreement.postal_code = functionalDeclaration.address.postalCode;
		}
		if (myAgreement.siret_override == undefined) {
			myAgreement.siret_override = getSiretCompatibleWithStructureIfExists(functionalDeclaration.efa_status);
		}
		return {
			functionalDeclaration,
			energyCategoriesAddable: getEnergyCategoriesToAdd(isMultiOccupation(functionalDeclaration)),
			agreement: myAgreement,
			canDelete: canDelete ?? false,
			adding,
			canEdit: !agreement.short_data_received && !agreement.year_data_received,
		};
	}

	reloadAgreement(input: HTMLInputElement) {
		this.prmAddress = false;
		this.prmConfirmed = undefined;
		const cantGetAutomaticallyReasonPipe = new CantGetAutomaticallyReasonPipe();
		this.state.agreement = { ...this.state.agreement };
		input.setCustomValidity(cantGetAutomaticallyReasonPipe.transform(this.state.agreement) ?? "");
		if (input.validationMessage === "" && this.state.agreement.type === "PRM") {
			this.prmConfirmed = false;
			this.prmAddress = undefined;
			this.agreementService.checkPrm$(this.state.agreement.reference).subscribe(
				(res) => {
					this.prmAddress = res.data.address.numeroEtNomVoie + " " + res.data.address.commune.libelle;
				},
				() => {
					this.prmAddress = false;
				},
			);
		}
	}

	confirm(address: string | false) {
		if (address === false) {
			this.state.agreement.year_data_received = true;
			this.state.agreement.short_data_received = true;
			this.state.agreement.is_accepted = true;
		}
		this.prmConfirmed = true;
	}

	delete() {
		dialogOpen(
			this.dialog,
			ConfirmationModalComponent,
			{
				title: "Voulez-vous supprimer votre compteur ?",
				description:
					"Attention, supprimer ce compteur entraînera la suppression définitive des données de consommations associées ainsi que la perte des accords pour accéder à ces données.",
			},
			{ panelClass: "p-0" },
		)
			.afterClosed()
			.subscribe((wantToDelete) => {
				if (wantToDelete) {
					super.close(true);
				}
			});
	}

	close() {
		const agreement = this.state.agreement;

		if (agreement.email !== null) {
			const domain = agreement.email.split("@")[1];
			const badDomain = WARNING_LIST.find((badDomain) => domain.split(".").some((split) => split === badDomain));
			if (badDomain) {
				dialogOpen(
					this.dialog,
					ConfirmationModalComponent,
					{
						title: "Fournisseur détecté",
						description: `Attention, il semble que l'email renseigné appartient à un fournisseur d'énergie (${get_company_name(
							badDomain,
						)}). Êtes-vous vraiment en train de faire la déclaration pour le compte de ce fournisseur dans le cadre du Décret Tertiaire ? Sinon, merci de cliquer sur Annuler et de renseigner le mail du titulaire du contrat.`,
					},
					{ panelClass: "p-0" },
				)
					.afterClosed()
					.subscribe((wantToContinue) => {
						if (wantToContinue) {
							super.close(agreement);
						}
					});
				return;
			}
		}

		super.close(agreement);
	}
}

const WARNING_LIST = [
	"alpiq",
	"alterna-energie",
	"antargaz",
	"axpo",
	"butagaz",
	"edsb-lagence",
	"ekwateur",
	"elecocite",
	"electriciteprovence",
	"electricitedesavoie",
	"enercoop",
	"energem",
	"energiedici",
	"energiesdusanterre",
	"leclerc",
	"engie",
	"eni",
	"enovos",
	"gazelenergie",
	"gazdebordeaux",
	"gazena",
	"geg",
	"greenyellow",
	"hydronext",
	"iberdrola",
	"ilek",
	"lucia-energie",
	"mega-energie",
	"mint-energie",
	"ohm-energie",
	"planete-oui",
	"plum",
	"proxelia",
	"selia-energies",
	"selfee",
	"solvay-energy",
	"sowee",
	"totalenergies",
	"urbansolarenergy",
	"valoris-energie",
	"volterres",
	"vattenfall",
	"wekiwi",
	"edf",
	"gedia-energies",
	"primeo-energie",
	"dyneff-gaz",
	"endesa",
	"gaz-europeen",
	"sefe-energy",
	"gazprom-energy",
	"natgas",
	"naturgy",
	"picoty",
	"save-energies",
	"redeo",
	"es",
] as const;

function get_company_name(domain: TupleToUnion<typeof WARNING_LIST>) {
	switch (domain) {
		case "alpiq":
			return "Alpiq";
		case "alterna-energie":
			return "Alterna";
		case "antargaz":
			return "Antargaz";
		case "axpo":
			return "Axpo";
		case "butagaz":
			return "Butagaz";
		case "edsb-lagence":
			return "EDSB L'Agence";
		case "ekwateur":
			return "ekWateur";
		case "elecocite":
			return "élecocité";
		case "electriciteprovence":
			return "Électricité de Provence";
		case "electricitedesavoie":
			return "Électricité de Savoie";
		case "enercoop":
			return "Enercoop";
		case "energem":
			return "énergem";
		case "energiedici":
			return "Énergie d'ici";
		case "energiesdusanterre":
			return "Energies du Santerre";
		case "leclerc":
			return "énergies E.Leclerc";
		case "engie":
			return "ENGIE";
		case "eni":
			return "ENI";
		case "enovos":
			return "Enovos";
		case "gazelenergie":
			return "GazelEnergie";
		case "gazdebordeaux":
			return "Gaz de Bordeaux";
		case "gazena":
			return "Gazena";
		case "geg":
			return "GEG";
		case "greenyellow":
			return "GreenYellow";
		case "hydronext":
			return "Hydronext";
		case "iberdrola":
			return "Iberdrola";
		case "ilek":
			return "Ilek";
		case "lucia-energie":
			return "Lucia Energie";
		case "mega-energie":
			return "Mega Énergie";
		case "mint-energie":
			return "Mint Energie";
		case "ohm-energie":
			return "Ohm énergie";
		case "planete-oui":
			return "Planète OUI";
		case "plum":
			return "Plüm énergie";
		case "proxelia":
			return "Proxelia";
		case "selia-energies":
			return "SÉLIA";
		case "selfee":
			return "Selfee";
		case "solvay-energy":
			return "Solvay Energy";
		case "sowee":
			return "Sowee";
		case "totalenergies":
			return "TotalEnergies";
		case "urbansolarenergy":
			return "Urban Solar Energy";
		case "valoris-energie":
			return "Valoris Energie";
		case "volterres":
			return "Volterres";
		case "vattenfall":
			return "Vattenfall";
		case "wekiwi":
			return "Wekiwi";
		case "edf":
			return "EDF";
		case "gedia-energies":
			return "Gedia";
		case "primeo-energie":
			return "Primeo Energie";
		case "dyneff-gaz":
			return "Dyneff Gaz";
		case "endesa":
			return "Endesa";
		case "gaz-europeen":
			return "Gaz Européen";
		case "sefe-energy":
			return "SEFE Energy";
		case "gazprom-energy":
			return "Gazprom Energy";
		case "natgas":
			return "natGAS";
		case "naturgy":
			return "Naturgy";
		case "picoty":
			return "Picoty";
		case "save-energies":
			return "Save Énergies";
		case "redeo":
			return "Redéo";
		case "es":
			return "ÉS";
	}
}
