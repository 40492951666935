<ng-container *ngIf="versionService.IsBackAndFrontCompatible$ | async; else elseBlock">
	<app-loading></app-loading>
	<router-outlet *ngIf="!this.loading"></router-outlet>
	<tour-step-template></tour-step-template>
</ng-container>
<ng-template #elseBlock>
	<div class="not-found">
		<h3>
			Une nouvelle version est disponible
			<span>Veuillez rafraîchir la page. Si cela ne suffit pas, supprimez le cache de votre navigateur.</span>
		</h3>
	</div>
</ng-template>
