import { Root } from "@amcharts/amcharts5";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { Immutable } from "immer";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { getCorrectionDataList } from "./conso";

export const consumptionDataCsvHeaders = [
	"Ann\u{00E9}e",
	"Consommation brute (kWh)",
	"Consommation brute surfacique (kWh/m\u{00B2})",
	"Consommation corrig\u{00E9}e (kWh)",
	"Consommation corrig\u{00E9}e surfacique (kWh/m\u{00B2})",
];

/**
 * Get the correction data of a functional declaration for every years, and format it
 * @param fd
 * @param djus
 * @param absoluteObjectives
 * @returns an array of objects, where every object represents a year with its total consumption, surface consumption, corrected consumption and surface corrected consumption
 */
export function formatDataForCsv(
	fd: Immutable<FunctionalDeclaration>,
	djus: { [p: string]: number },
	absoluteObjectives: { [p: string]: number },
): { year: number; total: number; total_surface: number; corrected: number; corrected_surface: number }[] {
	/**
	 * Round a number to 2 decimal points
	 * toFixed to round it to 2 decimals, and parseFloat to remove unnecessary trailing 0s
	 */
	const numberTo2Decimals = (num: number) => parseFloat(num.toFixed(2));

	const dataList = getCorrectionDataList(fd, djus, absoluteObjectives);
	return dataList.map(({ year, correctedConsumption, correction, total, surface }) => {
		// All values are in MWh, so we divide by 1000 to convert kWh
		const totalInKWh = total / 1000;

		// If some values are NaN or undefined, replace with 0
		const totalSurface = !surface ? 0 : totalInKWh / surface;
		const correctedTotal = !surface ? 0 : (total + correction) / 1000;
		const correctedSurface = isNaN(correctedConsumption) ? 0 : correctedConsumption / 1000;

		return {
			year: year,
			total: numberTo2Decimals(totalInKWh),
			total_surface: numberTo2Decimals(totalSurface),
			corrected: numberTo2Decimals(correctedTotal),
			corrected_surface: numberTo2Decimals(correctedSurface),
		};
	});
}

export function generateCsvFromGraphData(
	data: { year: number; total: number; total_surface: number; corrected: number; corrected_surface: number }[],
): string {
	const csvData = data.map((row) =>
		[row.year, row.total, row.total_surface, row.corrected, row.corrected_surface].join(","),
	);
	csvData.unshift(consumptionDataCsvHeaders.join(","));
	return csvData.join("\r\n");
}

export function getAmChartsExportSetting(root: Root, data: object) {
	return {
		menu: am5plugins_exporting.ExportingMenu.new(root, {}),
		dataSource: data,
		pngOptions: {
			disabled: true,
		},
		jpgOptions: {
			disabled: true,
		},
		pdfOptions: {
			disabled: true,
		},
		jsonOptions: {
			disabled: true,
		},
		xlsxOptions: {
			disabled: true,
		},
		htmlOptions: {
			disabled: true,
		},
		pdfdataOptions: {
			disabled: true,
		},
		printOptions: {
			disabled: true,
		},
	};
}
