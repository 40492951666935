import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AgreementAsk } from "src/app/models/agreementAsk";
import { EnergyType } from "src/app/models/energyType";
import { ConsumptionEntry, EnergyConsumptions } from "src/app/models/functionalDeclaration";
import { dialogOpen } from "src/app/models/modal";
import { BaseComponent } from "../../../../../../../models/base-component.directive";
import { Declaration } from "../../../../../../../models/declaration";
import { EnergyCategory } from "../../../../../../../models/energyCategory";
import { TourStateService } from "../../../../../../../services/tour-state.service";
import { ConfirmationModalComponent } from "../../../../../../confirmation-modal/confirmation-modal.component";
import { AddDeliveryValuesModalComponent } from "../../add-delivery-values-modal/add-delivery-values-modal.component";
import { AddValueModalComponent } from "../../add-value-modal/add-value-modal.component";
import MAXIMUM_REFERENCE_YEAR = Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION;
const MINIMAL_LOCKED_YEAR = 2022;

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: "tbody[app-consumption-table-row]",
	templateUrl: "./consumption-table-row.component.html",
	styleUrls: ["./consumption-table-row.component.scss"],
})
export class ConsumptionTableRowComponent extends BaseComponent implements OnInit {
	@Input() unlockedYears: number[] = [];
	@Input() isTokenConsumed: boolean = false;
	@Input() start: number = 0;
	@Input() end: number = 0;
	@Input() colSpan: number = 12;
	@Input() byYear: boolean = false;
	@Input() colorTimeline: string = "";
	@Input() consumptionTable: {
		label: string;
		values: {
			[key: string]: EnergyConsumptions;
		};
	} = { label: "", values: {} };
	@Input() agreement: AgreementAsk | undefined = undefined;
	@Input() favoritePeriod = 0;
	@Input() category!: EnergyCategory;
	@Input() startingYear: number = Declaration.MINIMUM_YEAR;
	@Output() update = new EventEmitter<void>();
	@Output() updateInvoice = new EventEmitter<void>();
	@Output() goToDetail = new EventEmitter<number>();
	@Output() editRow = new EventEmitter<{
		label: string;
		values: {
			[key: string]: EnergyConsumptions;
		};
	}>();
	@Output() saveWithTour = new EventEmitter<void>();
	public rowspanLength = 0;

	constructor(
		private dialog: MatDialog,
		private tourState: TourStateService,
	) {
		super();
		const agent = window.navigator.userAgent.toLowerCase();

		// rowspan 0 doesnt work on Safari so we use the maximum amount of row possible as rowspan. The render is then the same as Chrome and Firefox.
		if (agent.indexOf("safari") > -1) {
			this.rowspanLength = Object.keys(EnergyType).length;
		}
	}

	/**
	 * Opens the main modal that creates, modifies or deletes the values.
	 * @param value current value
	 * @param energy
	 */
	public openAddValueModal(value: ConsumptionEntry | undefined = undefined, energy: EnergyType): void {
		let category = this.category;
		if (category === EnergyCategory.DEDUCT) {
			category = this.consumptionTable.values[energy.valueOf()].deductTo ?? EnergyCategory.INDIVIDUAL;
		}

		if (this.consumptionTable.values[energy.valueOf()].byInvoice) {
			const dialogRef = this.dialog.open(AddDeliveryValuesModalComponent, {
				data: {
					consumptionTable: this.consumptionTable.values[energy.valueOf()],
					energy,
					category,
				},
				panelClass: "p-0",
			});
			dialogRef.beforeClosed().subscribe(() => {
				if (this.tourState.onTour) {
					this.saveWithTour.emit();
				}
			});
			dialogRef.afterClosed().subscribe((consumptionTable) => {
				if (consumptionTable) {
					this.consumptionTable.values[energy.valueOf()] = consumptionTable;
				}

				this.updateInvoice.emit();
			});
		} else {
			let adding = false;
			let index: number | undefined = undefined;

			if (value) {
				index = this.consumptionTable.values[energy.valueOf()].values.indexOf(value);
			} else {
				// to guess the umpteenths
				const entries = ConsumptionEntry.getEntriesTouchingRange(
					this.consumptionTable.values[energy.valueOf()].values,
					this.start,
					this.end,
				);
				const entry = entries.length > 0 ? entries[0] : undefined;

				value = new ConsumptionEntry({
					source: "manual",
					partUmpteenth: entry?.partUmpteenth,
					totalUmpteenth: entry?.totalUmpteenth,
					hasRepartitionKey: entry?.hasRepartitionKey,
					date_start: this.start,
					date_end: this.end,
				});
				adding = true;
			}

			const dialogRef = dialogOpen(
				this.dialog,
				AddValueModalComponent,
				{
					consumptionEntry: value,
					consumptionTable: this.consumptionTable.values[energy.valueOf()].values,
					adding,
					energy,
					category,
					tourState: this.tourState,
				},
				{ panelClass: "p-0" },
			);
			dialogRef.beforeClosed().subscribe(() => {
				if (this.tourState.onTour) {
					this.saveWithTour.emit();
				}
			});
			dialogRef.afterClosed().subscribe((returnValue) => {
				if (returnValue === undefined) {
					return;
				}

				if (returnValue === null) {
					if (index === undefined) {
						return;
					}
					this.consumptionTable.values[energy.valueOf()].values.splice(index, 1);
					this.update.emit();
					return;
				}

				if (returnValue instanceof Array) {
					returnValue.forEach((consumptionEntry: ConsumptionEntry) => {
						this.consumptionTable.values[energy.valueOf()].values.push(consumptionEntry);
					});
					this.update.emit();
					return;
				}

				if (index !== undefined) {
					this.consumptionTable.values[energy.valueOf()].values[index] = { ...returnValue };
					this.update.emit();
					return;
				}

				this.consumptionTable.values[energy.valueOf()].values.push({ ...returnValue });

				this.update.emit();
			});
		}
	}

	goToYear(year: number) {
		this.goToDetail.emit(year);
	}

	skipWaitData() {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: "Voulez-vous vraiment continuer ?",
					description:
						"Êtes-vous sûr(e) de vouloir poursuivre manuellement la saisie ? Cela interrompra la récupération automatique des données.",
				},
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((response) => {
				if (this.agreement && response) {
					this.agreement.year_data_received = true;
					this.agreement.short_data_received = true;

					this.update.emit();
				}
			});
	}

	ngOnInit() {
		if (
			this.agreement &&
			this.agreement.type === "PRM" &&
			this.consumptionTable.values[EnergyType.ELECTRICITY_KWH]?.values.length === 0 &&
			(this.agreement.original_consumptions ?? []).length > 0
		) {
			this.consumptionTable.values[EnergyType.ELECTRICITY_KWH].values = this.agreement.original_consumptions ?? [];
			this.update.emit();
		}
		if (
			this.agreement &&
			this.agreement.type === "PCE" &&
			this.consumptionTable.values[EnergyType.GAZ_NAT_NETWORK_KWH].values.length === 0 &&
			(this.agreement.original_consumptions ?? []).length > 0
		) {
			this.consumptionTable.values[EnergyType.GAZ_NAT_NETWORK_KWH].values = this.agreement.original_consumptions ?? [];
			this.update.emit();
		}
	}

	isLocked = (year: number): boolean => {
		return (
			(year <= MAXIMUM_REFERENCE_YEAR && !this.isTokenConsumed) ||
			(year >= MINIMAL_LOCKED_YEAR && !this.unlockedYears.includes(year))
		);
	};
}
