// This is called a 'newtype'
// (https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes-func.html#type-aliases)
// This protects us from doing mistakes with IDs. For example we can use a declaration id instead
// of a representative id if we are not careful. Now, we can't make mistakes. Those types are still
// considered as strings. "The newtype idiom gives compile time guarantees that the right type of
// value is supplied to a program."

import { FunctionalDeclaration } from "./functionalDeclaration";
import { Declaration } from "./declaration";
import { DeclarationGroup } from "./declarationGroup";
import { Representative } from "./representative";
import { Lazy } from "./lazy";
import { z } from "zod";

export const UserIdZod = z.string().brand("UserId");
export type UserId = z.infer<typeof UserIdZod>;
export type FunctionalDeclarationId = string & { readonly FunctionalDeclarationId: unique symbol };
export type DeclarationId = string & { readonly DeclarationId: unique symbol };
export type DeclarationGroupId = string & { readonly DeclarationGroupId: unique symbol };
export type RepresentativeId = string & { readonly RepresentativeId: unique symbol };
export type ResourceId = FunctionalDeclarationId | DeclarationId | DeclarationGroupId | RepresentativeId;

export type ParcelId = string & { readonly ParcelId: unique symbol };

/**
 * Function that casts an id to an Id type. Safer than `<MyId>` because it only accepts strings.
 * Still a little dangerous to use. Use only on pure string.
 * @param id The id to cast
 * @returns The good type
 */
export function wrapId<T extends ResourceId>(id: string): T {
	return <T>id;
}

/*

 Doesn't compile:

 let lol: DeclarationId = wrapId("");
 let lil: RepresentativeId = lol

 */

export type LazyResource = Lazy<FunctionalDeclaration | Declaration | DeclarationGroup | Representative>;

/**
 * This is a conditional type that gives the resource's id type.
 */
export type IdFromResource<T extends LazyResource> =
	T extends Lazy<FunctionalDeclaration>
		? FunctionalDeclarationId
		: T extends Lazy<Declaration>
			? DeclarationId
			: T extends Lazy<DeclarationGroup>
				? DeclarationGroupId
				: RepresentativeId;
