import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Payment } from "../models/payment";

@Injectable({
	providedIn: "root",
})
export class PaymentService {
	constructor(private http: HttpClient) {}

	createPayment$(payment: Payment): Observable<Payment> {
		const newPayment = { ...payment };
		newPayment[GreenKeys.KEY_REPRESENTATIVE_ID] = newPayment[GreenKeys.KEY_REPRESENTATIVE]?.representative_id;
		newPayment[GreenKeys.KEY_REPRESENTATIVE] = null;
		return this.http.post<Payment>(`${environment.baseApiUrl}/payment`, newPayment);
	}

	validatePayment$(clientSecret: string): Observable<Payment> {
		return this.http.post<Payment>(`${environment.baseApiUrl}/payment/validate/${clientSecret}`, {});
	}

	getPayments$(): Observable<Payment[]> {
		return this.http.get<Payment[]>(`${environment.baseApiUrl}/payment`);
	}
}
