import * as am5 from "@amcharts/amcharts5";
import am5map_france from "@amcharts/amcharts5-geodata/franceDepartments2High";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AfterViewInit, Component, Input } from "@angular/core";

@Component({
	selector: "app-stat-chart-map",
	templateUrl: "./stat-chart-map.component.html",
	styleUrls: ["./stat-chart-map.component.scss"],
})
export class StatChartMapComponent implements AfterViewInit {
	// this data has been retrieved using a program retrieving the max and min longitude and latitude using a GeoJson file.
	// With the link below, I managed to get the GeoJson file for DOM-TOM with the real coordinates
	// I then parsed the amcharts map in order to get the coordinates on the map in order to map the real coordinates to our French map with DOM-TOM
	// We don't have to do this with France Metropolitan because the coordinates on the amcharts map are correct
	// https://gadm.org/download_country.html
	readonly MARTINIQUE_RANGE: Range = {
		boundaries: { latitude: { min: 14.3882, max: 14.879 }, longitude: { min: -61.229, max: -60.8087 } },
		map: { latitude: { min: 38.5568, max: 39.6184 }, longitude: { min: 0.7869, max: 1.7344 } },
	};
	readonly GUADELOUPE_RANGE: Range = {
		boundaries: { latitude: { min: 15.8315, max: 16.5149 }, longitude: { min: -61.8101, max: -61.0001 } },
		map: { latitude: { min: 38.4997, max: 39.6534 }, longitude: { min: -1.8783, max: -0.4751 } },
	};
	readonly FRENCH_GUINEA_RANGE: Range = {
		boundaries: { latitude: { min: 2.1287, max: 5.751 }, longitude: { min: -54.5418, max: -51.6059 } },
		map: { latitude: { min: 38.4108, max: 39.7114 }, longitude: { min: -4.1718, max: -3.1295 } },
	};
	readonly MAYOTTE_RANGE: Range = {
		boundaries: { latitude: { min: -13.0063, max: -12.6362 }, longitude: { min: 45.0179, max: 45.3001 } },
		map: { latitude: { min: 38.6362, max: 39.5998 }, longitude: { min: 3.1155, max: 5.8153 } },
	};
	readonly REUNION_RANGE: Range = {
		boundaries: { latitude: { min: -21.3899, max: -20.8718 }, longitude: { min: 55.2163, max: 55.8374 } },
		map: { latitude: { min: 38.7621, max: 39.4136 }, longitude: { min: 5.0276, max: 5.833 } },
	};
	readonly ranges: Range[] = [
		this.MARTINIQUE_RANGE,
		this.GUADELOUPE_RANGE,
		this.FRENCH_GUINEA_RANGE,
		this.MAYOTTE_RANGE,
		this.REUNION_RANGE,
	];

	@Input() points!: { latitude: number; longitude: number; label: string }[];
	@Input() perCounty!: { [county: string]: number };

	ngAfterViewInit() {
		const root = am5.Root.new("map-chart");

		root.setThemes([am5themes_Animated.new(root)]);

		const chart = root.container.children.push(
			am5map.MapChart.new(root, {
				panX: "translateX",
				panY: "translateY",
				projection: am5map.geoMercator(),
				layout: root.horizontalLayout,
			}),
		);

		const map = am5map_france;
		map.features.map((feature) => {
			if (feature.id === "FR-973" && feature.properties) {
				feature.properties.name = "Guyane"; // rename French Guinea to Guyanne
			}
		});
		const polygonSeries = chart.series.push(
			am5map.MapPolygonSeries.new(root, {
				geoJSON: map,
				valueField: "value",
				calculateAggregates: true,
				exclude: ["FR-PF"], // French Polynesia are not included in the Decret Tertiaire
			}),
		);

		polygonSeries.mapPolygons.template.set("tooltipText", "{name}");
		polygonSeries.mapPolygons.template.adapters.add("tooltipText", (text, target) => {
			const context = target.dataItem?.dataContext;
			if (context && typeof context === "object" && "value" in context) {
				return text + " : " + context.value;
			}
			return text;
		});

		polygonSeries.set("heatRules", [
			{
				target: polygonSeries.mapPolygons.template,
				dataField: "value",
				min: am5.color(0x1a83f8),
				max: am5.color(0xff0000),
				key: "fill",
			},
		]);

		polygonSeries.data.setAll(
			Object.keys(this.perCounty).map((county) => ({
				id: "FR-" + county,
				value: this.perCounty[county],
			})),
		);

		const heatLegend = chart.children.push(
			am5.HeatLegend.new(root, {
				orientation: "vertical",
				startColor: am5.color(0x1a83f8),
				endColor: am5.color(0xff0000),
			}),
		);

		heatLegend.startLabel.setAll({
			fontSize: 12,
			fill: heatLegend.get("startColor"),
		});

		heatLegend.endLabel.setAll({
			fontSize: 12,
			fill: heatLegend.get("endColor"),
		});

		polygonSeries.events.on("datavalidated", function () {
			heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
			heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
		});

		const pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

		pointSeries.bullets.push(() => {
			const circle = am5.Circle.new(root, {
				radius: 3,
				tooltipText: "{name}",
				cursorOverStyle: "pointer",
				tooltipY: 0,
				fill: am5.color(0xffba00),
				stroke: root.interfaceColors.get("background"),
				strokeWidth: 0,
			});

			return am5.Bullet.new(root, {
				sprite: circle,
			});
		});

		pointSeries.data.setAll(
			this.points.map((point) => {
				this.mapRange(point);
				return {
					geometry: {
						type: "Point",
						coordinates: [point.longitude, point.latitude],
					},
					name: point.label,
				};
			}),
		);
	}

	// Edit the point in order to get the corrected longitude and latitude for the amcharts map
	private mapRange(point: { longitude: number; latitude: number }) {
		this.ranges.forEach((range) => {
			if (
				range.boundaries.latitude.min <= point.latitude &&
				point.latitude <= range.boundaries.latitude.max &&
				range.boundaries.longitude.min <= point.longitude &&
				point.longitude <= range.boundaries.longitude.max
			) {
				point.latitude = this.map(point.latitude, range.boundaries.latitude, range.map.latitude);
				point.longitude = this.map(point.longitude, range.boundaries.longitude, range.map.longitude);
				return;
			}
		});
	}

	// Map the value from a range to an another
	private map(
		value: number,
		range_start: { min: number; max: number },
		range_end: { min: number; max: number },
	): number {
		return (
			range_end.min +
			((range_end.max - range_end.min) / (range_start.max - range_start.min)) * (value - range_start.min)
		);
	}
}

type Range = {
	boundaries: {
		longitude: { min: number; max: number };
		latitude: { min: number; max: number };
	};
	map: {
		longitude: { min: number; max: number };
		latitude: { min: number; max: number };
	};
};
