import { ErrorHandler, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomError } from "../models/CustomError";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private snackBar: MatSnackBar) {}

	handleError(error: unknown) {
		if (error !== undefined) {
			if (error instanceof CustomError) {
				this.snackBar.open(error.message, "OK");
				return;
			}
			this.snackBar.open("Une erreur est survenue.", "OK", { duration: 3000 });
			throw error;
		}
	}
}
