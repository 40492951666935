import { Injectable } from "@angular/core";

const CACHE_NAME = "displayYearIndexWarning";

@Injectable({
	providedIn: "root",
})
export class YearIndexWarningStorageService {
	constructor() {}

	setDisplay(remindLater: boolean = true) {
		localStorage.setItem(CACHE_NAME, `${remindLater ? 1 : 0}`);
	}

	/**
	 * @return true by default
	 */
	getDisplay(): boolean {
		return Boolean(parseInt(localStorage.getItem(CACHE_NAME) ?? "1"));
	}
}
