<ng-container *ngIf="userState.user as user">
	<div
		*ngFor="let representativeAndCounts of [representativeAndCounts$ | async]"
		class="d-flex-col"
		style="padding-top: 40px; padding-right: 40px; overflow-y: auto"
	>
		<div class="d-flex" *ngIf="representativeAndCounts">
			<div>
				<p class="text-secondary" style="font-weight: bold; font-size: 25px; margin: 0">
					Récapitulatif de l’ensemble de vos démarches
				</p>
				<p class="text-secondary">Cliquez sur une étape en cours ou réalisée pour y accéder</p>
				<div
					*ngIf="representativeAndCounts"
					class="text-secondary d-flex gap-2 mt-0 mb-2"
					style="font-weight: bold; font-size: 20px; align-items: center"
				>
					{{ representativeAndCounts.counts.entity.name }}
					<button
						class="btn-accent btn-squared"
						(click)="
							openRepresentativeAuthorizationListDialog(representativeAndCounts.representative, user.user_id ?? '')
						"
						tourAnchor="my_declarations.share"
						matTooltip="Partager à d'autres utilisateurs"
					>
						<mat-icon>share</mat-icon>
					</button>
					<a
						class="btn-warning btn-squared"
						(click)="navigateToRepresentativeSettings(representativeAndCounts.representative.representative_id)"
						*ngIf="user.user_id === representativeAndCounts.representative.owner_id"
						matTooltip="Paramétrage"
					>
						<mat-icon>edit</mat-icon>
					</a>
					<button
						class="btn-primary btn-squared"
						(click)="startTour()"
						matTooltip="Guide interactif"
						tourAnchor="my_declarations.guide"
					>
						<mat-icon>swipe_up</mat-icon>
					</button>
					<button
						(click)="goToStats(representativeAndCounts.representative.representative_id)"
						class="btn-primary-light btn-squared"
						matTooltip="Statistiques"
					>
						<mat-icon>bar_chart</mat-icon>
					</button>
					<button
						class="btn-accent3 btn-squared"
						(click)="goToStats('all')"
						matTooltip="Statistiques globales"
						*ngIf="representativeAndCounts.representative.representative_id === MASTER_ID"
					>
						<mat-icon>insights</mat-icon>
					</button>
				</div>
				<div style="margin-bottom: 10px">
					<div class="input-icon">
						<input
							[(ngModel)]="search"
							class="custom-input-light"
							style="min-width: 370px; display: block"
							placeholder="Rechercher une déclaration"
							(ngModelChange)="searchInput()"
						/>
						<span class="input-suffix" *ngIf="loadingSearch">
							<mat-icon class="rotating">hourglass_empty</mat-icon>
						</span>
					</div>
					<mat-slide-toggle [(ngModel)]="developAll" color="primary">Tout développer</mat-slide-toggle>
				</div>
			</div>
			<div *ngIf="representativeAndCounts" class="d-flex stat-boxes">
				<div *ngFor="let count of [representativeAndCounts.counts.groupCount]" class="stat-box">
					<div class="stat-box-number">
						{{ count }}
					</div>
					<div class="stat-box-description">Groupe{{ count > 1 ? "s" : "" }} de déclaration</div>
				</div>
				<div *ngFor="let count of [representativeAndCounts.counts.declarationCount]" class="stat-box">
					<div class="stat-box-number">
						{{ count }}
					</div>
					<div class="stat-box-description">Déclaration{{ count > 1 ? "s" : "" }}</div>
				</div>
				<div *ngFor="let count of [representativeAndCounts.counts.entityCount]" class="stat-box">
					<div class="stat-box-number">
						{{ count }}
					</div>
					<div class="stat-box-description">
						Entité{{ count > 1 ? "s" : "" }} fonctionnelle{{ count > 1 ? "s" : "" }}<br />
						<span *ngFor="let count of [representativeAndCounts.counts.tokenConsumedCount]">
							(<span class="bold">{{ count }}</span> crédit{{ count > 1 ? "s" : "" }} initia{{
								count > 1 ? "ux" : "l"
							}}
							consommé{{ count > 1 ? "s" : "" }})
						</span>
						<br />
						<span *ngFor="let count of [representativeAndCounts.counts.annualTokenConsumedCount]">
							(<span class="bold">{{ count }}</span> crédit{{ count > 1 ? "s" : "" }} annuel{{
								count > 1 ? "s" : ""
							}}
							consommé{{ count > 1 ? "s" : "" }})
						</span>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="isolation.isolationInfo?.type === ResourceType.Declaration; else notDeclaration">
			<ng-container *ngIf="isolationDeclaration$ | async as declaration">
				<div class="subpane" *ngIf="declaration.name ?? '' | contains: search">
					<div style="margin-bottom: 15px"></div>
					<table>
						<thead>
							<tr class="table-header">
								<th></th>
								<th>Définition du périmètre</th>
								<th>Qualification des sites</th>
								<th>Déclaration patrimoniale</th>
								<th>Déclaration des consommations</th>
								<th>Recherche de l'année de référence</th>
							</tr>
						</thead>
						<tbody>
							<tr class="table-row">
								<td style="padding-left: 10px">
									<div class="declaration-title" style="justify-content: left">
										<div style="display: inline; text-align: left; justify-content: left">
											<p class="primary" style="margin: 0; font-weight: bold">{{ declaration.name }}</p>
											<p class="primary-light" style="margin: 0; font-size: 15px">
												<ng-container *ngIf="declaration.structure | asSirenStructure as structure">
													{{ structure.siren }}{{ structure.maybeRna ? "/" + structure.maybeRna : "" }}
												</ng-container>
												<ng-container *ngIf="declaration.structure | asRnaStructure as structure">
													{{ structure.rna }}
												</ng-container>
												<ng-container *ngIf="declaration.structure | asOtherStructure as structure">
													{{ structure.id }}
												</ng-container>
											</p>
										</div>
									</div>
								</td>
								<ng-container *ngFor="let page of PROGRESS_PAGES">
									<td (click)="stepAction(declaration, page)">
										<div>
											<app-advancement-chip [percent]="declaration | stepTodoPercentage: page"></app-advancement-chip>
										</div>
									</td>
								</ng-container>
							</tr>
						</tbody>
					</table>
				</div>
			</ng-container>
		</ng-container>
		<ng-template #notDeclaration>
			<ng-container *ngFor="let group of groups">
				<ng-container *ngIf="group.declaration_group_id">
					<app-group-pane
						*ngIf="isolation.isolationInfo as isolationInfo"
						[developAll]="developAll"
						[group]="group"
						[user]="user"
						[representative]="representativeAndCounts?.representative"
						(stepActionEmitter)="stepAction($event.declaration, $event.page)"
						(updateGroup)="updateGroup($event, isolationInfo)"
						(deleteGroup)="deleteGroup(group.declaration_group_id, $event)"
						(newDeclaration)="newDeclaration(group, $event, isolationInfo)"
					></app-group-pane>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="archivedGroups.length > 0">
				<p class="text-secondary" style="font-weight: bold; font-size: 40px; margin: 20px">Archives</p>
				<ng-container *ngFor="let group of archivedGroups">
					<ng-container *ngIf="group.name">
						<app-group-pane
							*ngIf="isolation.isolationInfo as isolationInfo"
							[developAll]="developAll"
							[group]="group"
							[user]="user"
							(stepActionEmitter)="stepAction($event.declaration, $event.page)"
							(updateGroup)="updateGroup($event, isolationInfo)"
						></app-group-pane>
					</ng-container>
				</ng-container>
			</ng-container>

			<mat-spinner
				class="mx-auto my-3"
				*ngIf="loading && this.groups.length + this.archivedGroups.length === 0"
			></mat-spinner>
			<div
				class="mx-auto my-3 message-danger"
				*ngIf="!loading && this.groups.length + this.archivedGroups.length === 0 && search !== ''"
			>
				Aucune déclaration ne correspond à votre recherche.
			</div>
		</ng-template>
	</div>
</ng-container>
