import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { DeclarationGroup } from "../models/declarationGroup";
import { Lazy } from "../models/lazy";

@Pipe({
	name: "countDeclarations",
})
export class CountDeclarationsPipe implements PipeTransform {
	transform(resource: Immutable<Lazy<DeclarationGroup>>): number {
		const groups = [resource];
		return groups.map((group) => group.declarations.length).reduce((sum, number) => sum + number, 0);
	}
}
