import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DeclarationGroup } from "src/app/models/declarationGroup";
import { Lazy } from "src/app/models/lazy";

@Component({
	selector: "app-delete-group-confirm-modal",
	templateUrl: "./delete-group-confirm-modal.component.html",
	styleUrls: ["./delete-group-confirm-modal.component.scss"],
})
export class DeleteGroupConfirmModalComponent {
	public group: Lazy<DeclarationGroup>;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: { declarationGroup: Lazy<DeclarationGroup> },
		public dialog: MatDialog,
	) {
		this.group = data.declarationGroup;
	}
}
