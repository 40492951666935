<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<div class="dialog-small-text d-flex-col gap-2">
			<h3 class="m-0">Ajout de bornes de recharge de véhicules électriques</h3>
			<span class="text-gray">
				Une borne IRVE correspond à un point de recharge pour un véhicule électrique : si vous avez par exemple une
				borne double, cela correspondra alors à 2 bornes IRVE.
			</span>
			<app-custom-form #form>
				<div class="form gap-2">
					<div class="custom-input-group">
						<label>Nombre de bornes</label>
						<input
							name="surface"
							appInputRestriction="integer"
							[min]="0"
							style="width: 100%"
							class="custom-input"
							type="number"
							[(ngModel)]="electricCarDockEntry.count"
						/>
					</div>
					<div class="dates">
						<div class="custom-input-group">
							<label>Date de début (incluse)</label>
							<input
								(change)="startDateChange(start.value)"
								#start
								name="start"
								required
								class="custom-input"
								type="date"
								[min]="START | date: 'y-MM-dd'"
								[max]="TODAY | date: 'y-MM-dd'"
								[value]="electricCarDockEntry.start | timestampToDate"
							/>
							<span class="custom-input-error">{{ start.validationMessage }}</span>
						</div>
						<div class="custom-input-group">
							<label>Date de fin (exclue)(vide pour aujourd'hui)</label>
							<input
								(change)="endDateChange(end.value)"
								#end
								name="end"
								class="custom-input"
								type="date"
								[min]="start.value | followingDay"
								[max]="TODAY | date: 'y-MM-dd'"
								[value]="electricCarDockEntry.end ? (electricCarDockEntry.end | timestampToDate) : null"
							/>
							<span class="custom-input-error">{{ end.validationMessage }}</span>
						</div>
					</div>
				</div>
			</app-custom-form>
		</div>
	</div>
	<div class="dialog-actions gap-1">
		<button
			class="btn-stroked-text-gray mr-auto"
			[mat-dialog-close]="adding ? null : this.initialActivityCategoryEntry"
		>
			Annuler
		</button>
		<button class="btn-stroked-danger" [mat-dialog-close]="null">Supprimer</button>
		<button class="btn-stroked-primary" [disabled]="!form.formValid" [mat-dialog-close]="this.electricCarDockEntry">
			{{ adding ? "Ajouter" : "Modifier" }}
		</button>
	</div>
</div>
