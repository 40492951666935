import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
	DeclarationGroupId,
	DeclarationId,
	FunctionalDeclarationId,
	RepresentativeId,
	ResourceId,
	UserId,
	wrapId,
} from "../models/ids";
import { ResourceInfo, ResourceType } from "../models/resource";
import { InitStateService } from "./init-state.service";

interface UserInResponse {
	id: UserId;
	name: string;
	email: string;
	last_connection: string;
	is_temporary: true;
}

type AuthorizationResponse = {
	authorization_id: string;
	user_id: UserId;
	user: UserInResponse;
	scopes: string[];
} & (
	| { resource_type: ResourceType.FunctionalDeclaration; resource_id: FunctionalDeclarationId }
	| { resource_type: ResourceType.Declaration; resource_id: DeclarationId }
	| { resource_type: ResourceType.DeclarationGroup; resource_id: DeclarationGroupId }
	| { resource_type: ResourceType.Representative; resource_id: RepresentativeId }
);

export interface Authorization {
	userId: UserId;
	resourceInfo: ResourceInfo;
	user: UserInResponse;
	authorizationId: string;
	scopes: string[];
}

@Injectable({
	providedIn: "root",
})
export class AuthorizationService {
	constructor(
		private http: HttpClient,
		private initStateService: InitStateService,
	) {}

	get$(): Observable<Authorization[]> {
		return this.http.get<AuthorizationResponse[]>(`${environment.baseApiUrl}/authorization`).pipe(
			map((responses) =>
				responses.map((response) => {
					const id = response.resource_id;
					let resourceInfo: ResourceInfo;

					switch (response.resource_type) {
						case ResourceType.FunctionalDeclaration:
							resourceInfo = { type: ResourceType.FunctionalDeclaration, id: wrapId(id) };
							break;
						case ResourceType.Declaration:
							resourceInfo = { type: ResourceType.Declaration, id: wrapId(id) };
							break;
						case ResourceType.DeclarationGroup:
							resourceInfo = { type: ResourceType.DeclarationGroup, id: wrapId(id) };
							break;
						case ResourceType.Representative:
							resourceInfo = { type: ResourceType.Representative, id: wrapId(id) };
							break;
					}

					return {
						userId: response.user_id,
						resourceInfo: resourceInfo,
						user: response.user,
						scopes: response.scopes,
						authorizationId: response.authorization_id,
					};
				}),
			),
		);
	}

	getById$(id: string): Observable<AuthorizationResponse> {
		return this.http.get<AuthorizationResponse>(`${environment.baseApiUrl}/authorization/${id}`);
	}

	delete$(type: ResourceType, resourceId: ResourceId, userId: UserId): Observable<void> {
		return this.http.get<void>(`${environment.baseApiUrl}/${type}/${resourceId}/authorization/${userId}`);
	}
}
