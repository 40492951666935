<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="my-0">Vous êtes sur le point de consommer un crédit initial</h3>
		<div class="dialog-small-text">
			<div class="d-flex-col gap-3">
				<ng-container *ngIf="state.messageType === ConsumeTokenMessageType.INITIAL_MESSAGE">
					<div>
						En confirmant cette action, un crédit initial sera consommé. Chaque entité fonctionnelle nécessite
						uniquement un seul jeton.
					</div>

					<div class="d-flex-col gap-2 text-gray">
						<div>
							<h4 class="primary my-0">{{state.functionalDeclaration.name}}</h4>
							<span class="text-gray">{{state.functionalDeclaration.address | addressToString }}</span>
						</div>
						<div>
							<label>Parcelles :</label>
							<ul class="m-0">
								<li *ngFor="let parcel of state.functionalDeclaration.infos.parcelles">{{parcel|parcelName}}</li>
							</ul>
						</div>
						<div>
							<label>Bâtiments :</label>
							<ul class="m-0">
								<li *ngFor="let building of state.functionalDeclaration.infos.buildingInfos">
									{{building.label[0] === "" ? "Bâtiment sans nom" : building.label[0]}}
									<ng-container *ngIf="building | buildingAssujetti as assujetti"
										>({{assujetti.owned + assujetti.notOwned|number}} m²)</ng-container
									>
								</li>
							</ul>
						</div>

						<div class="mt-2">
							<div class="message-success" *ngIf="state.functionalDeclaration | isAssujetti; else elseBlock">
								Cet établissement est assujetti.
							</div>
							<ng-template #elseBlock>
								<div class="message-danger">
									<mat-icon>warning</mat-icon>
									Cet établissement n'est pas assujetti.
								</div>
							</ng-template>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="state.messageType === ConsumeTokenMessageType.LOCKED_CONSUMPTION">
					<div>
						Afin d'accéder aux années 2010 - 2020 et à la fonctionnalité de recherche de l'année de référence, il est
						nécessaire de dépenser un crédit initial pour cette entité fonctionnelle.<br />
					</div>
					<div>En confirmant cette action, un crédit initial sera définitivement consommé pour cette entité.</div>
					<div>Voulez-vraiment débloquer ces fonctionnalités ?</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="dialog-actions">
		<button (click)="closeDialog(false)" class="btn-stroked-text-gray mr-auto">Annuler</button>
		<button
			(click)="closeDialog(true)"
			class="btn-stroked-primary"
			[disabled]="!state.functionalDeclaration.name"
			[matTooltip]="state.functionalDeclaration.name ? '' : NO_NAME_MESSAGE"
		>
			Continuer
		</button>
	</div>
</div>
