<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2 text-icon">
			<mat-icon>domain</mat-icon>
			Importer des entités fonctionnelles
		</h3>
		<h4 class="message-primary-light">
			Les entités déjà présentes dans le Configurateur ne seront pas réimportées. Aucune donnée ne sera écrasée.
		</h4>
		<mat-tab-group (selectedIndexChange)="importIndex = $event">
			<mat-tab label="API">
				<app-operat-search-form
					[existingEfaRef]="getDeclarationEfaReferences(state.declaration)"
					[editableUserKey]="state.canEditUserKey"
					[editableInseeId]="false"
					[inseeId]="getIdentifier(state.declaration)"
					[userKey]="state.declaration.operat_user_key ?? ''"
					[(importData)]="apiImportData"
				></app-operat-search-form>
			</mat-tab>
			<mat-tab label="CSV">
				<ng-container *ngIf="state.declaration.structure | asSirenStructure as structure">
					<app-csv-drop-zone
						[(csvFile)]="csvFile"
						[identifierToCheck]="structure.siren"
						sirenErrorMessage="Le SIREN du fichier ne correspond pas à celui de la déclaration"
					></app-csv-drop-zone>
				</ng-container>
				<ng-container *ngIf="state.declaration.structure | asRnaStructure as structure">
					<app-csv-drop-zone
						[(csvFile)]="csvFile"
						[identifierToCheck]="structure.rna"
						sirenErrorMessage="Le RNA du fichier ne correspond pas à celui de la déclaration"
					></app-csv-drop-zone>
				</ng-container>
				<ng-container *ngIf="state.declaration.structure | asOtherStructure as structure">
					<app-csv-drop-zone
						[(csvFile)]="csvFile"
						[identifierToCheck]="structure.id"
						sirenErrorMessage="L'identifiant de structure du fichier ne correspond pas à celui de la déclaration"
					></app-csv-drop-zone>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [mat-dialog-close]="undefined">Annuler</button>
		<button
			*ngIf="importIndex === ImportTypeEnum.CSV"
			[disabled]="!csvFile"
			class="btn-stroked-primary"
			(click)="save()"
		>
			Importer
		</button>
		<button
			*ngIf="importIndex === ImportTypeEnum.API"
			[disabled]="apiImportData.references.length === 0 || !apiImportData.userKey || !apiImportData.structId"
			class="btn-stroked-primary"
			(click)="save()"
		>
			Importer
		</button>
	</div>
</div>
