import { Component } from "@angular/core";
import { Modal } from "../../../../models/modal";

type Input = {
	type: "déclaration" | "entité functionnelle";
};

type State = {
	type: string;
};

@Component({
	selector: "app-confirm-newly-liable",
	templateUrl: "./confirm-newly-liable.component.html",
	styleUrls: ["./confirm-newly-liable.component.scss"],
})
export class ConfirmNewlyLiableComponent extends Modal<Input, State, boolean | undefined> {
	inputToState(input: Input): State {
		return { type: input.type };
	}
}
