import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserStateService } from "../services/user-state.service";

@Injectable()
export class CanLoadNavigation {
	constructor(
		private userState: UserStateService,
		private router: Router,
	) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.userState.user?.user_id !== undefined) {
			return true;
		}

		this.router.navigate(["/404"]);
		return false;
	}
}
