import { Component } from "@angular/core";
import {
	IdOther,
	Marker,
	MarkerToTuple,
	MarkerToUnion,
	RefEtat,
	RefEtatLess,
	Rna,
	Siren,
	Siret,
} from "src/app/models/identifier";
import { Modal } from "src/app/models/modal";
import { OPERAT_PARTICULIER } from "src/assets/text";
import { HelpSubject } from "../help/help.component";
import { SirenInputComponent } from "../siren-input/siren-input.component";
import { SiretInputComponent } from "../siret-input/siret-input.component";

type Input<T extends Marker> = {
	choice: number;
	choices: MarkerToTuple<T>;
};

type State<T extends Marker> = {
	choice: number;
	choices: MarkerToUnion<T>[];
};

type Output<T extends Marker> = MarkerToUnion<T> | undefined;

@Component({
	selector: "app-identifier-modal",
	templateUrl: "./identifier-modal.component.html",
	styleUrls: ["./identifier-modal.component.scss"],
})
export class IdentifierModalComponent<T extends Marker> extends Modal<Input<T>, State<T>, Output<T>> {
	readonly OPERAT_PARTICULIER = OPERAT_PARTICULIER;

	inputToState({ choice, choices }: Input<T>): State<T> {
		return {
			choice,
			choices: choices.map((choice) => ({ ...choice })),
		};
	}

	save() {
		const { choices, choice } = this.state;

		this.close(choices[choice]);
	}

	exit() {
		this.close(undefined);
	}

	isOtherLabelInputDisabled = false;

	toggleParticulier(choice: IdOther) {
		choice.label = choice.label === "PP" ? "" : "PP";

		if (choice.label === "PP") {
			this.isOtherLabelInputDisabled = true;
		} else {
			this.isOtherLabelInputDisabled = false;
		}
	}

	HelpSubject = HelpSubject;
	// Yeah I use a function inside the template
	// because we modify values with ngModel so it's hard to detect changes.
	// This function is light and isolated inside the modal so there shouldn't be a problem
	isValid() {
		const choice = this.state.choices[this.state.choice];

		if ("rna" in choice) {
			return Rna.isValid(choice.rna);
		}

		if ("siren" in choice) {
			return Siren.isValid(choice.siren) && SirenInputComponent.validSirens.get(choice.siren); // hotfix
		}

		if ("siret" in choice) {
			return Siret.isValid(choice.siret) && SiretInputComponent.validSirets.get(choice.siret);
		}

		if ("refEtat" in choice) {
			return RefEtat.isValid(choice.refEtat);
		}

		if ("refEtatLess" in choice) {
			return RefEtatLess.isValid(choice.refEtatLess);
		}

		return choice.id.length > 0 && choice.label.length > 0;
	}
}
