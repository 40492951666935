import { Pipe, PipeTransform } from "@angular/core";
import { AgreementAsk } from "../models/agreementAsk";

@Pipe({
	name: "agreementForSubCategory",
})
export class AgreementForSubCategoryPipe implements PipeTransform {
	transform(category: string, agreementAsks: AgreementAsk[]): AgreementAsk | undefined {
		let ret: AgreementAsk | undefined = undefined;

		if (category.startsWith("gaz_") || category.startsWith("elec_")) {
			agreementAsks.forEach((agreementAsk) => {
				if (
					(category === "gaz_" + agreementAsk.reference && agreementAsk.type === "PCE") ||
					(category === "elec_" + agreementAsk.reference && agreementAsk.type === "PRM")
				) {
					ret = agreementAsk;
				}
			});
		}

		return ret;
	}
}
