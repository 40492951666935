import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-csv-help-modal",
	templateUrl: "./csv-help-modal.component.html",
	styleUrls: ["./csv-help-modal.component.scss"],
})
export class CsvHelpModalComponent {
	downloadCallback: () => void;

	constructor(@Inject(MAT_DIALOG_DATA) data: () => void) {
		this.downloadCallback = data;
	}
}
