import { formatDate } from "@angular/common";
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Immutable } from "immer";
import { BehaviorSubject, EMPTY, Observable, of } from "rxjs";
import { debounceTime, map, switchMap } from "rxjs/operators";
import { ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { HelpSubject } from "src/app/components/help/help.component";
import { fc, ToFormControls } from "src/app/helpers/forms";
import { EnergyType, energyTypeUnitToDatabaseStorage } from "src/app/models/energyType";
import { ConsumptionEntry } from "src/app/models/functionalDeclaration";
import { Modal } from "src/app/models/modal";
import { dateToTimestamp } from "src/app/pipes/date-to-timestamp.pipe";
import { energyTypeValueDisplay } from "src/app/pipes/energy-type-value-display.pipe";
import { Declaration } from "../../../../../../models/declaration";
import { EnergyCategory } from "../../../../../../models/energyCategory";
import { TourStateService } from "../../../../../../services/tour-state.service";
import { ImportCsvModalComponent } from "./import-csv-modal/import-csv-modal.component";

// For the form we will use the dates as string. Before we were converting between string and number each time a date was modified.
type ConsumptionEntryForm = FormGroup<
	ToFormControls<Omit<ConsumptionEntry, "date_end" | "date_start"> & { date_end: string; date_start: string }>
>;

type Input = {
	adding: boolean;
	consumptionEntry: Immutable<ConsumptionEntry>;
	consumptionTable: Immutable<ConsumptionEntry[]>;
	energy: EnergyType;
	category: EnergyCategory;
	tourState: TourStateService;
};
type State = {
	initialConsumptionEntry: Immutable<ConsumptionEntry>;
	formGroup: ConsumptionEntryForm;
	adding: boolean;
	energy: EnergyType;
	category: EnergyCategory;
	consumptionTable: Immutable<ConsumptionEntry[]>;
	isFormValid$: Observable<boolean>;
	timestamps$: Observable<{ date_start: number; date_end: number }>;
	correctedValue$: BehaviorSubject<number>;
};
type Output = ConsumptionEntry[] | null | Immutable<ConsumptionEntry> | undefined;

@Component({
	selector: "app-add-value-modal",
	templateUrl: "./add-value-modal.component.html",
	styleUrls: ["./add-value-modal.component.scss"],
})
export class AddValueModalComponent extends Modal<Input, State, Output> {
	readonly START = new Date(Declaration.MINIMUM_DATE_TIME * 1000);
	readonly TODAY = new Date();
	toAddValues: ConsumptionEntry[] = [];
	readonly EnergyType = EnergyType;
	readonly HelpSubject = HelpSubject;
	readonly EnergyCategory = EnergyCategory;

	inputToState({ adding, category, consumptionEntry, consumptionTable, energy, tourState }: Input): State {
		if (tourState.onTour) {
			tourState.end();
			tourState.start([
				{
					anchorId: "consumption-add-start",
					content: "Entrez la date de début de la période de consommation",
					title: "Entrez la date de début",
				},
				{
					anchorId: "consumption-add-end",
					content: "Entrez la date de fin de la période de consommation",
					title: "Entrez la date de fin",
				},
				{
					anchorId: "consumption-add-value",
					content: "Entrez la valeur de la consommation",
					title: "Entrez la valeur",
				},
				{
					anchorId: "consumption-add-commentary",
					content:
						"Les commentaires permettront de garder une trace de l'origine de cette valeur. En cas de contrôle, il sera donc plus facile de retrouver le justificatif correspondant.",
					title: "Entrez un commentaire (facultatif)",
				},
				{
					anchorId: "consumption-add-save",
					content: "Il ne vous reste plus qu'à sauvegarder et réitérer pour toutes vos valeurs",
					title: "Sauvegardez",
					nextOnAnchorClick: true,
				},
			]);
		}

		const formGroup = new FormGroup({
			commentary: fc(consumptionEntry.commentary),
			hasRepartitionKey: fc(consumptionEntry.hasRepartitionKey),
			partUmpteenth: fc(consumptionEntry.partUmpteenth),
			source: fc(consumptionEntry.source),
			totalUmpteenth: fc(consumptionEntry.totalUmpteenth),
			value: fc(energyTypeValueDisplay(consumptionEntry.value, energy)),
			date_end: fc(formatDate(consumptionEntry.date_end * 1000, "yyyy-MM-dd", "fr-FR")),
			date_start: fc(formatDate(consumptionEntry.date_start * 1000, "yyyy-MM-dd", "fr-FR")),
		});

		// We are not adding => the consumption already exists => the form is valid
		const isFormValid$ = new BehaviorSubject(!adding);
		formGroup.valueChanges
			.pipe(
				map(() => formGroup.valid),
				debounceTime(10), // to avoid ExpressionChangedAfterItHasBeenCheckedError
			)
			.subscribe(isFormValid$);

		const timestamps$ = new BehaviorSubject({
			date_start: consumptionEntry.date_start,
			date_end: consumptionEntry.date_end,
		});
		formGroup.valueChanges
			.pipe(
				switchMap(({ date_start, date_end }) => (date_start && date_end ? of({ date_start, date_end }) : EMPTY)),
				map(({ date_start, date_end }) => ({
					date_start: dateToTimestamp(date_start),
					date_end: dateToTimestamp(date_end),
				})),
			)
			.subscribe(timestamps$);
		const correctedValue$ = new BehaviorSubject(
			energyTypeValueDisplay(ConsumptionEntry.getCorrectedValue(consumptionEntry), energy),
		);

		formGroup.valueChanges
			.pipe(
				switchMap(({ partUmpteenth, totalUmpteenth, value }) =>
					value !== undefined &&
					value !== null &&
					partUmpteenth !== undefined &&
					partUmpteenth !== null &&
					totalUmpteenth !== undefined &&
					totalUmpteenth !== null
						? of({ partUmpteenth, totalUmpteenth, value })
						: EMPTY,
				),
				map(({ partUmpteenth, totalUmpteenth, value }) => (value * partUmpteenth) / totalUmpteenth),
			)
			.subscribe(correctedValue$);

		return {
			adding,
			category,
			formGroup,
			consumptionTable,
			energy,
			initialConsumptionEntry: consumptionEntry,
			isFormValid$,
			timestamps$,
			correctedValue$,
		};
	}

	saveCsvImport(): void {
		this.close(this.toAddValues);
	}

	public openImportCSVModal(): void {
		this.dialog
			.open(ImportCsvModalComponent, {
				data: {
					consumptionTable: this.state.consumptionTable,
					energy: this.state.energy,
					category: this.state.category,
				},
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((consumptionTable) => {
				if (consumptionTable) {
					this.toAddValues = consumptionTable;
					this.saveCsvImport();
				}
			});
	}

	public delete(): void {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: "Supprimer une consommation",
					description:
						"Vous êtes sur le point de supprimer une donnée de consommation. Une fois supprimée cette donnée ne pourra pas être récupérée, êtes vous sûr de vouloir continuer ?",
				},
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((confirmDelete) => {
				if (confirmDelete) {
					this.close(null);
				}
			});
	}

	returnConsumptionEntry() {
		const { initialConsumptionEntry: initial } = this.state;
		const { commentary, date_end, date_start, hasRepartitionKey, partUmpteenth, source, totalUmpteenth, value } =
			this.state.formGroup.controls;

		this.close(
			new ConsumptionEntry({
				commentary: commentary.value ?? initial.commentary,
				date_end: date_end.value ? dateToTimestamp(date_end.value) : initial.date_end,
				date_start: date_start.value ? dateToTimestamp(date_start.value) : initial.date_start,
				hasRepartitionKey: hasRepartitionKey.value ?? initial.hasRepartitionKey,
				partUmpteenth: partUmpteenth.value ?? initial.partUmpteenth,
				source: source.value ?? initial.source,
				totalUmpteenth: totalUmpteenth.value ?? initial.totalUmpteenth,
				value: value.value ? energyTypeUnitToDatabaseStorage(this.state.energy, Number(value.value)) : initial.value,
			}),
		);
	}
}
