import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { range } from "../helpers/array";
import { ConsumptionEntry } from "../models/functionalDeclaration";
import { DateToTimestampPipe } from "./date-to-timestamp.pipe";
import { DisplayableConsumptionEntryPipe } from "./displayable-consumption-entry.pipe";
import { PeriodRangePipe } from "./period-range.pipe";

const dateToTimestampPipe = new DateToTimestampPipe();
const periodRangePipe = new PeriodRangePipe();
const displayableConsumptionEntryPipe = new DisplayableConsumptionEntryPipe();

@Pipe({
	name: "consumptionEntryByYear",
})
export class ConsumptionEntryByYearPipe implements PipeTransform {
	transform(
		consumptionEntries: Immutable<ConsumptionEntry[]>,
		start: number,
		end: number,
		favoritePeriod: number,
	): { year: number; value: ConsumptionEntry }[] {
		const startDate = new Date(start * 1000);
		const endDate = new Date(end * 1000);

		// We use flatMap because we don't always return a value. It's like a filterMap.
		return range(startDate.getFullYear(), endDate.getFullYear() + 1).flatMap((year) => {
			const period = periodRangePipe.transform(year, favoritePeriod);

			// we will cut down the conso's range with this range
			const latestStart = Math.max(dateToTimestampPipe.transform(period.start), start);
			const soonestEnd = Math.min(dateToTimestampPipe.transform(period.end), end);

			const displayableConsumptions = consumptionEntries
				.map((conso) => displayableConsumptionEntryPipe.transform(conso, latestStart, soonestEnd))
				.flatMap((conso) => (conso ? [conso] : []));

			const [first, ...rest] = displayableConsumptions;

			if (first === undefined) {
				// if no displayable consumptions
				return [];
			}

			// We want to narrow the range of the consumptions of the year.
			// for example: We have a conso that spans across March and August and another conso
			// that spans across September and November.
			// Then the conso that will be displayed in the summary will span across March and November (we ignore holes)
			// note: minStart > latestStart and maxStart < soonestEnd
			let minStart = first.date_start;
			let maxEnd = first.date_end;
			let displayedValue = ConsumptionEntry.getCorrectedValue(first);

			for (const displayableConsumption of rest) {
				displayedValue += ConsumptionEntry.getCorrectedValue(displayableConsumption);

				if (minStart > displayableConsumption.date_start) {
					minStart = displayableConsumption.date_start;
				}
				if (maxEnd < displayableConsumption.date_end) {
					maxEnd = displayableConsumption.date_end;
				}
			}

			return [{ year, value: new ConsumptionEntry({ date_start: minStart, date_end: maxEnd, value: displayedValue }) }];
		});
	}
}
