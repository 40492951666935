import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../models/ids";
import { Isolation } from "../models/isolation";
import { DeclarationFunctionalService } from "./declaration-functional.service";

@Injectable({
	providedIn: "root",
})
export class FunctionalDeclarationIsolationService extends Isolation<FunctionalDeclaration> {
	constructor(private functionalService: DeclarationFunctionalService) {
		super();
	}

	getIdFromResource(resource: Immutable<FunctionalDeclaration>): FunctionalDeclarationId {
		const id = resource.declaration_functional_id;

		if (id) {
			return id;
		}

		throw new Error("Resource without ID.");
	}

	fetchResource$(id: FunctionalDeclarationId): Observable<Immutable<FunctionalDeclaration>> {
		return this.functionalService.get$(id).pipe(map(FunctionalDeclaration.fromApi));
	}

	saveResourceToServer$(resource: Immutable<FunctionalDeclaration>): Observable<Immutable<FunctionalDeclaration>> {
		return this.functionalService
			.update$(FunctionalDeclaration.toApi(resource))
			.pipe(map(FunctionalDeclaration.fromApi));
	}
}
