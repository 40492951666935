<span class="d-flex gap-2" style="justify-content: center; align-items: center" *ngIf="files">
	{{ files.length === 1 ? files.item(0)?.name : files.length + " fichiers" }}
	<button class="btn-primary" (click)="upload.emit()" [disabled]="disabled">
		<mat-icon>file_upload</mat-icon>
		Envoyer
	</button>
	<label for="file-input" class="btn-primary btn-squared">
		<mat-icon>folder_open</mat-icon>
	</label>
</span>
<div [hidden]="files">
	<label class="btn-primary d-flex gap-1" style="font-weight: bold; justify-content: center" for="file-input">
		<mat-icon>folder_open</mat-icon>
		{{ label }}
	</label>
	<input
		#inputFile
		multiple
		style="display: none"
		id="file-input"
		type="file"
		(change)="inputFile.files && inputFile.files.length > 0 ? selectFiles.emit(inputFile.files) : undefined"
	/>
</div>
