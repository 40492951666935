import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { GreenKeys } from "@grs/greenkeys";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BenchMarkData } from "../../../../helpers/benchmark-data";
import { Declaration } from "../../../../models/declaration";
import { FunctionalDeclarationId } from "../../../../models/ids";
import { dialogOpen } from "../../../../models/modal";
import { Djus } from "../../../../services/declaration-functional.service";
import { DialogComponent, HelpSubject } from "../../../help/help.component";
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

@Component({
	selector: "app-benchmark-modal",
	templateUrl: "./benchmark-modal.component.html",
	styleUrls: ["./benchmark-modal.component.scss"],
})
export class BenchmarkModalComponent {
	declaration: Declaration;
	djus: Djus;
	exportFile: { file: SafeUrl; filename: string } | undefined;

	minYear = 2021;
	// Last full year
	maxYear = new Date().getFullYear() - 1;

	year: number = this.maxYear;

	benchmarkData: ChartData[] = [];
	minTotal = Infinity;
	maxTotal = -Infinity;
	minPerSurface = Infinity;
	maxPerSurface = -Infinity;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: { declaration: Declaration; djus: Djus },
		private dialog: MatDialog,
		private sanitizer: DomSanitizer,
	) {
		this.declaration = data.declaration;
		this.djus = data.djus;
		this.getData();
	}

	openHelpModal() {
		dialogOpen(this.dialog, DialogComponent, { subject: HelpSubject.Benchmark, argument: "" }, { panelClass: "p-0" });
	}

	get isYearLocked() {
		if (this.year < ANNUAL_DECLARATION_STARTING_YEAR) return false;

		for (const fd of this.declaration.declarations_functional) {
			if (fd[GreenKeys.KEY_YEARS_TOKEN_USED].includes(this.year)) return false;
		}

		return true;
	}

	yearChanged(year: number) {
		this.year = year;
		this.getData();
	}

	private getData() {
		const { benchmarkData, minTotal, maxTotal, minPerSurface, maxPerSurface } = BenchMarkData.getData(
			this.declaration,
			this.djus,
			this.year,
		);
		this.benchmarkData = benchmarkData;
		this.minTotal = minTotal;
		this.maxTotal = maxTotal;
		this.minPerSurface = minPerSurface;
		this.maxPerSurface = maxPerSurface;

		const exportData = BenchMarkData.generateExport(this.declaration, this.benchmarkData, this.year);
		if (exportData) {
			this.exportFile = {
				file: this.sanitizer.bypassSecurityTrustUrl(
					URL.createObjectURL(new Blob([exportData.csvData], { type: "text/csv;charset=utf-8" })),
				),
				filename: exportData.filename,
			};
		} else {
			this.exportFile = undefined;
		}
	}
}

interface ChartData {
	name: string;
	id: FunctionalDeclarationId;
	address: string;
	totalConsumption: number;
	consumptionPerSurface: number;
}
