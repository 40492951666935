import { animate, state, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY, Observable, of, ReplaySubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { UserInfoFormComponent } from "src/app/components/navigation/user-info-form/user-info-form.component";
import { Nullable } from "src/app/helpers/nullable";
import { unwrap } from "src/app/helpers/unwrap";
import { Declaration } from "src/app/models/declaration";
import { DeclarationGroup } from "src/app/models/declarationGroup";
import { FunctionalDeclaration } from "src/app/models/functionalDeclaration";
import { HasResourceFromOwner } from "src/app/models/HasResourceFromOwner";
import {
	DeclarationGroupId,
	DeclarationId,
	FunctionalDeclarationId,
	IdFromResource,
	LazyResource,
	RepresentativeId,
	wrapId,
} from "src/app/models/ids";
import { Lazy } from "src/app/models/lazy";
import { ResourceInfo, ResourceType } from "src/app/models/resource";
import { ResourceOwner } from "src/app/models/ResourceOwner";
import { Route, RouteDealer, routeToResourceType, stringToRoute } from "src/app/models/routes";
import { DeclarationFunctionalService } from "src/app/services/declaration-functional.service";
import { DeclarationGroupStateService } from "src/app/services/declaration-group-state.service";
import { DeclarationGroupService } from "src/app/services/declaration-group.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { DeclarationService } from "src/app/services/declaration.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { IsolationStateService } from "src/app/services/isolation-state.service";
import { RepresentativeStateService } from "src/app/services/representative-state.service";
import { UserStateService } from "src/app/services/user-state.service";
import { LOGO_SMALL_URL, LOGO_URL } from "../../helpers/consts";
import { BaseComponent } from "../../models/base-component.directive";
import { CURRENT_VERSION } from "../../models/version";
import { AuthorizationViewLoaderService } from "../../services/authorization-view-loader.service";
import { NavbarService } from "../../services/navbar.service";
import { RepresentativeService } from "../../services/representative.service";
import { Immutable } from "immer";
import { compareByName } from "src/app/helpers/sort-by-name";
import { YearIndexWarningStorageService } from "../../services/year-index-warning-storage.service";

const NEW_DECLA_MENU_ANIMATION_TIME = "200ms ease-in-out";

@Component({
	selector: "app-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.scss"],
	animations: [
		trigger("collapse", [
			state("open", style({ width: "*" })),
			state("close", style({ width: 30 })),
			transition("open => close", [style({ width: "*" }), animate(100, style({ width: 30 }))]),
			transition("close => open", [style({ width: 0 }), animate(100, style({ width: "*" }))]),
		]),
		trigger("newDeclaMenu", [
			state("open", style({ transform: "translateY(calc( -100% * 2/3 - 4px)", height: "calc( 300% + 16px )" })),
			state("close", style({ transform: "translateY(0)", display: "hidden" })),
			transition("open => close", [animate(NEW_DECLA_MENU_ANIMATION_TIME)]),
			transition("close => open", [animate(NEW_DECLA_MENU_ANIMATION_TIME)]),
		]),
		trigger("newDeclaIcon", [
			state("open", style({ marginLeft: "50%", transform: "translateX(-50%) rotateZ(45deg)" })),
			state("close", style({ transform: "rotate(0)" })),
			transition("open => close", [animate(NEW_DECLA_MENU_ANIMATION_TIME)]),
			transition("close => open", [animate(NEW_DECLA_MENU_ANIMATION_TIME)]),
		]),
		trigger("newDeclaText", [
			state("open", style({ display: "none" })),
			state("close", style({})),
			transition("open => close", [style({ display: "none" }), animate(NEW_DECLA_MENU_ANIMATION_TIME)]),
			transition("close => open", [style({ display: "none" }), animate(NEW_DECLA_MENU_ANIMATION_TIME)]),
		]),
	],
})
export class NavigationComponent extends BaseComponent implements OnDestroy, AfterContentChecked {
	LOGO_URL = LOGO_URL;
	LOGO_SMALL_URL = LOGO_SMALL_URL;
	isDataReloading: boolean = false;
	sideBarShrink: boolean = false;

	canDisplayYearIndexWarning$ = new ReplaySubject<boolean>(1);

	newDeclaMenuOpen = false;

	isOpen: boolean | undefined = true;
	ResourceType = ResourceType;
	focusGroupId: Nullable<DeclarationGroupId>;
	Route = Route;

	profileActive = false;

	version = CURRENT_VERSION;

	groups$: Observable<Immutable<DeclarationGroup[]> | undefined>;

	constructor(
		private router: Router,
		public representativeState: RepresentativeStateService,
		public groupState: DeclarationGroupStateService,
		public declarationState: DeclarationStateService,
		private functionalDeclarationState: FunctionalDeclarationStateService,
		private groupService: DeclarationGroupService,
		private declarationService: DeclarationService,
		private functionalService: DeclarationFunctionalService,
		activatedRoute: ActivatedRoute,
		public userState: UserStateService,
		location: Location,
		public navBarService: NavbarService,
		public isolation: IsolationStateService,
		private dialog: MatDialog,
		private authorizationViewLoader: AuthorizationViewLoaderService,
		private representativeService: RepresentativeService,
		private cdref: ChangeDetectorRef,
		private yearIndexWarningState: YearIndexWarningStorageService,
	) {
		super();

		this.canDisplayYearIndexWarning$.next(this.yearIndexWarningState.getDisplay());

		this.groups$ = representativeState.get$.pipe(
			switchMap((representative) =>
				representative?.value.representative_id
					? this.representativeService.getNavigation$(representative.value.representative_id)
					: of([]),
			),
			tap((groups) => groups.sort(compareByName).forEach((group) => group.declarations.sort(compareByName))),
		);

		if (
			!representativeState.getId() &&
			!groupState.getId() &&
			!declarationState.getId() &&
			!functionalDeclarationState.getId()
		) {
			this.authorizationViewLoader.loadModal(true);
			return;
		}

		this.sub(declarationState.get$, (declaration) => {
			this.focusGroupId = declaration?.value.declaration_group_id;
		});

		if (this.navBarService.getNavState() === null) {
			this.isOpen = true;
		} else {
			this.isOpen = /true/i.test(String(this.navBarService.getNavState()));
		}
		// This tries to avoid fetching. However, it can not avoid all fetching by its own.
		// If possible, we can set the parent before navigating to avoid fetching. But this is optional.
		activatedRoute.paramMap
			.pipe(
				switchMap((paramMap) => {
					const id = paramMap.get("id");
					const path = location.path().split("/");
					return id === null || id === "all" || path.length < 2
						? EMPTY
						: of({ id, segment: location.path().split("?")[0].split("/") });
				}),
				switchMap(({ id, segment }) => {
					const route = stringToRoute(segment.pop() ?? "");

					if (!route) {
						return EMPTY;
					}

					const type = routeToResourceType(route, segment.pop());

					if (!type) {
						return EMPTY;
					}

					switch (type) {
						case "isolation":
							return of(unwrap(this.isolation.isolationInfo));
						case ResourceType.FunctionalDeclaration:
							return of({ id: wrapId<FunctionalDeclarationId>(id), type });
						case ResourceType.Declaration:
							return of({ id: wrapId<DeclarationId>(id), type });
						case ResourceType.DeclarationGroup:
							return of({ id: wrapId<DeclarationGroupId>(id), type });
						case ResourceType.Representative:
							return of({ id: wrapId<RepresentativeId>(id), type });
					}
				}),
				switchMap((resourceInfoAndIsolation: ResourceInfo) => this.generateAncestryFromType$(resourceInfoAndIsolation)),
			)
			.subscribe();
	}

	get collapseState() {
		return this.isOpen ? "open" : "close";
	}

	get newDeclaMenuState() {
		return this.newDeclaMenuOpen ? "open" : "close";
	}

	selectFocus(declarationGroupId: DeclarationGroupId | undefined) {
		const div = document.getElementById("declaration-group-" + declarationGroupId);
		const contentDiv = document.querySelector("#declaration-group-" + declarationGroupId + " .expandable-content");
		if (div) {
			if (declarationGroupId === this.focusGroupId) {
				this.focusGroupId = undefined;
				div.style.maxHeight = "0";
				return;
			}
			this.focusGroupId = declarationGroupId;
			// Client size + 100 px in case it's resized
			div.style.maxHeight = (contentDiv?.clientHeight ?? 0) + 100 + "px";
			setTimeout(() => {
				if (contentDiv) {
					contentDiv.scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "center",
					});
				}
			}, 100);
		}
	}

	// I return unkown because I don't care about the return type. I just want to know if this is an
	// Isolation when we want to use the type in the isolation state.
	generateAncestryFromType$(info: ResourceInfo): Observable<unknown> {
		// Generates only if state hasn't been fed (determined by get id)
		switch (info.type) {
			case ResourceType.Representative:
				return this.representativeState.getId() !== info.id ? this.generateAncestryFromRepresentative$(info.id) : EMPTY;
			case ResourceType.DeclarationGroup:
				return this.groupState.getId() !== info.id ? this.generateAncestryFromGroup$(info.id) : EMPTY;
			case ResourceType.Declaration:
				return this.declarationState.getId() !== info.id ? this.generateAncestryFromDeclaration$(info.id) : EMPTY;
			case ResourceType.FunctionalDeclaration:
				return this.functionalDeclarationState.getId() !== info.id ? this.generateAncestryFromEntity$(info.id) : EMPTY;
		}
	}

	// observable or not. unknown is not like any, we can't do anything dangerous with it.
	generateAncestryFromRepresentative$(id: RepresentativeId): Observable<unknown> {
		return this.representativeState.select$(id);
	}

	generateAncestryFromGroup$(id: DeclarationGroupId): Observable<unknown> {
		return this.generateAncestry$<Lazy<DeclarationGroup>>(
			id,
			this.groupState,
			(id) => this.groupService.get$(id).pipe(map(DeclarationGroup.fromLazyApi)),
			(child) => this.generateAncestryFromRepresentative$(child.representative_id),
			this.representativeState,
		);
	}

	generateAncestryFromDeclaration$(id: DeclarationId): Observable<unknown> {
		return this.generateAncestry$<Lazy<Declaration>>(
			id,
			this.declarationState,
			(id) => this.declarationService.get$(id).pipe(map(Declaration.fromLazyApi)),
			(child) => this.generateAncestryFromGroup$(unwrap(child.declaration_group_id)),
			this.groupState,
		);
	}

	generateAncestryFromEntity$(id: FunctionalDeclarationId): Observable<unknown> {
		return this.generateAncestry$(
			id,
			this.functionalDeclarationState as HasResourceFromOwner<FunctionalDeclaration>, // the compiler has a bug
			(id) => this.functionalService.get$(id).pipe(map(FunctionalDeclaration.fromApi)),
			(child) => this.generateAncestryFromDeclaration$(unwrap(child.declaration_id)),
			this.declarationState,
		);
	}

	generateAncestry$<T extends LazyResource>(
		id: IdFromResource<T>,
		hasResource: HasResourceFromOwner<T>,
		fetchResource$: (id: IdFromResource<T>) => Observable<T>,
		// Child as a parameter because we get the parent's id from it.
		generateAncestryFromParent$: (child: T) => Observable<unknown>,
		parentState: ResourceOwner<T>,
	): Observable<unknown> {
		return hasResource.select$(id).pipe(
			switchMap((nullable) =>
				nullable
					? of(void 0)
					: fetchResource$(id).pipe(
							switchMap(generateAncestryFromParent$),
							tap(() => hasResource.setOwner(parentState)),
							switchMap(() => hasResource.select$(id)),
						),
			),
		);
	}

	selectDeclaration(declarationId: DeclarationId, groupId?: DeclarationGroupId) {
		// no need to set the declaration state, the activated route will do it on its own
		if (groupId) {
			this.groupState.select$(groupId).subscribe(() => {
				this.router.navigate(RouteDealer.addresses(declarationId));
			});
		} else {
			this.router.navigate(RouteDealer.addresses(declarationId));
		}
	}

	public shrink(): void {
		this.navBarService.setNav((!this.isOpen).toString());
		this.isOpen = /true/i.test(String(this.navBarService.getNavState()));
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 151);
	}

	goToDashboard(id: RepresentativeId | DeclarationGroupId | DeclarationId) {
		this.router.navigate(RouteDealer.myDeclaration(id));
	}

	goToNewDeclarationGroup() {
		const newDeclarationGroup = this.dialog.open(UserInfoFormComponent, {
			panelClass: "first-connection-dialog",
			data: { isImporting: false },
		});
		newDeclarationGroup.afterClosed().subscribe(() => {
			this.changeDeclarationMenuState();
		});
	}

	changeAuthorization() {
		this.authorizationViewLoader.loadModal(false);
	}

	goToBuyToken(id: RepresentativeId | DeclarationGroupId) {
		this.router.navigate(RouteDealer.buyToken(id));
	}

	changeDeclarationMenuState() {
		this.newDeclaMenuOpen = !this.newDeclaMenuOpen;
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}

	importDeclarationGroup() {
		const newDeclarationGroup = this.dialog.open(UserInfoFormComponent, {
			panelClass: "first-connection-dialog",
			data: { isImporting: true },
		});
		newDeclarationGroup.afterClosed().subscribe(() => {
			this.changeDeclarationMenuState();
		});
	}

	closeYearIndexWarning(remindLater: boolean) {
		this.canDisplayYearIndexWarning$.next(false);
		this.yearIndexWarningState.setDisplay(remindLater);
	}
}
