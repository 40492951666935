import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { BaseComponent } from "../../../../../models/base-component.directive";
import { Lazy } from "../../../../../models/lazy";
import { Payment } from "../../../../../models/payment";
import { Representative } from "../../../../../models/representative";
import { TokenType } from "../../../../../models/TokenType";
import { User } from "../../../../../models/user";
import { AuthenticationService } from "../../../../../services/authentication.service";
import { DeclarationGroupStateService } from "../../../../../services/declaration-group-state.service";
import { PaymentService } from "../../../../../services/payment.service";
import { RepresentativeStateService } from "../../../../../services/representative-state.service";
import { UserStateService } from "../../../../../services/user-state.service";
import { PaymentComponent } from "../../../../payment/payment.component";

@Component({
	selector: "app-buy-token-type-form",
	templateUrl: "./buy-token-type-form.component.html",
	styleUrls: ["./buy-token-type-form.component.scss"],
})
export class BuyTokenTypeFormComponent extends BaseComponent implements AfterViewInit {
	@Input() registration: boolean | undefined = undefined;
	@Input() authRepresentatives: Lazy<Representative>[] = [];
	@Input() success: boolean = false;
	@Input() unitPrice: number = 200;
	@Input() tokenType: TokenType = TokenType.INITIAL_TOKEN;
	@Input() title: string = "";
	@Input() description: string = "";
	@Input() colorClass: string = "";
	@Input() functionDisplayRemainingToken: (representative: Immutable<Lazy<Representative>>) => string = (): string =>
		"";

	STEP_SELECTION = 0;
	STEP_ACCOUNT = -1;
	STEP_INFORMATIONS = 1;
	STEP_PAYMENT = 2;
	STEP_RESULT = 3;

	@ViewChild("STEP_SELECTION_DIV") stepSelectionDiv!: ElementRef;
	@ViewChild("STEP_ACCOUNT_DIV") stepAccountDiv!: ElementRef;
	@ViewChild("STEP_INFORMATION_DIV") stepInformationDiv!: ElementRef;
	@ViewChild("STEP_PAYMENT_DIV") stepPaymentDiv!: ElementRef;

	@ViewChild(PaymentComponent) paymentComponent: PaymentComponent | undefined;
	payment = new Payment();
	GreenKeys = GreenKeys;
	currentStep = this.STEP_SELECTION;
	loadingAddress = false;
	addressValid = false;
	user = User.parse({});

	constructor(
		public representativeState: RepresentativeStateService,
		private declarationGroupState: DeclarationGroupStateService,
		private activatedRoute: ActivatedRoute,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private paymentService: PaymentService,
		private authenticationService: AuthenticationService,
		private userState: UserStateService,
	) {
		super();

		this.user = userState.user ?? User.parse({});

		this.sub(this.router.events, (e) => {
			if (e instanceof NavigationEnd) {
				this.ngAfterViewInit();
			}
		});
	}

	init() {
		if (this.registration === true) {
			this.STEP_ACCOUNT = 1;
			this.STEP_INFORMATIONS = 2;
			this.STEP_PAYMENT = 3;
			this.STEP_RESULT = 4;
		}

		this.payment = new Payment();
		this.payment[GreenKeys.KEY_TOKEN_TYPE] = this.tokenType;
		this.currentStep = this.STEP_SELECTION;
		if (this.success) {
			this.selectStep(this.STEP_RESULT);
		}

		if (!this.registration) {
			this.sub(this.representativeState.get$, (representative) => {
				if (!this.payment[GreenKeys.KEY_REPRESENTATIVE] && representative) {
					this.payment[GreenKeys.KEY_REPRESENTATIVE] = representative.value;
				}
			});
		}
	}

	ngAfterViewInit() {
		this.selectStep(this.success ? this.STEP_RESULT : this.STEP_SELECTION);
		this.init();
		this.cdRef.detectChanges();
	}

	selectStep(step: number) {
		if (step === this.STEP_SELECTION && this.success) {
			return;
		}

		if (step === this.STEP_ACCOUNT && this.success) {
			return;
		}

		if (
			step === this.STEP_INFORMATIONS &&
			(this.payment[GreenKeys.KEY_QUANTITY] < 0 ||
				this.payment[GreenKeys.KEY_QUANTITY] > 10 ||
				this.payment[GreenKeys.KEY_REPRESENTATIVE] === undefined ||
				this.success ||
				!this.user)
		) {
			return;
		}

		if (step === this.STEP_PAYMENT && !this.addressValid) {
			return;
		}

		if (step === this.STEP_RESULT && !this.success) {
			return;
		}

		this.currentStep = step;
		let stepElement = null;
		switch (step) {
			case this.STEP_SELECTION:
				stepElement = this.stepSelectionDiv?.nativeElement;
				break;
			case this.STEP_INFORMATIONS:
				stepElement = this.stepInformationDiv?.nativeElement;
				break;
			case this.STEP_PAYMENT:
				stepElement = this.stepPaymentDiv?.nativeElement;
				break;
			case this.STEP_ACCOUNT:
				stepElement = this.stepAccountDiv?.nativeElement;
		}
		if (stepElement) {
			const wrapper = stepElement.getElementsByClassName("step-content-wrapper").item(0) as HTMLElement | null;
			if (wrapper) {
				const content = wrapper.getElementsByClassName("step-content").item(0);
				if (content) {
					wrapper.style.height = content.clientHeight + "px";
					setTimeout(() => {
						wrapper.style.height = "auto";
					}, 300);
				}
			}
		}
	}

	resourceChange() {
		const representative = this.payment[GreenKeys.KEY_REPRESENTATIVE];
		if (representative !== null) {
			this.payment[GreenKeys.KEY_REPRESENTATIVE_ID] = representative.representative_id;
		}
	}

	saveAddress(user: User) {
		this.loadingAddress = true;
		this.authenticationService.updateUser$(user).subscribe(() => {
			this.loadingAddress = false;
			this.addressValid = true;
			this.selectStep(this.STEP_PAYMENT);
		});
	}

	register(data: { user: User; representative: Lazy<Representative> }) {
		this.user = data.user;
		this.payment[GreenKeys.KEY_REPRESENTATIVE] = data.representative;
		this.selectStep(this.STEP_INFORMATIONS);
	}
}
