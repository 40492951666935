<div class="dialog-container" style="min-height: 500px; min-width: 700px">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="mt-0 mb-2">Identifiant</h3>
		<div class="custom-input-group mb-2">
			<label>Type d'identifiant</label>
			<ng-select [(ngModel)]="state.choice" [clearable]="false">
				<ng-option *ngFor="let choice of state.choices; index as i" [value]="i">
					{{ choice | labelFromIdentifier }}
				</ng-option>
			</ng-select>
		</div>
		<div *ngIf="state.choices[state.choice] | asSiret as choice">
			<div class="custom-input-group">
				<label>{{ choice | labelFromIdentifier }}</label>
				<app-siret-input [(siret)]="choice.siret" appInputRestriction="alphanumeric"></app-siret-input>
			</div>
		</div>
		<div *ngIf="state.choices[state.choice] | asSiren as choice">
			<div class="custom-input-group">
				<label>{{ choice | labelFromIdentifier }}</label>
				<app-siren-input [(siren)]="choice.siren" appInputRestriction="alphanumeric"></app-siren-input>
			</div>
		</div>
		<div *ngIf="state.choices[state.choice] | asRna as choice">
			<div class="custom-input-group">
				<label>{{ choice | labelFromIdentifier }}</label>
				<input
					class="custom-input"
					[(ngModel)]="choice.rna"
					pattern="^W\d{9}$"
					placeholder="W123456789"
					appInputRestriction="alphanumeric"
					[maxLength]="10"
				/>
			</div>
		</div>
		<div *ngIf="state.choices[state.choice] | asRefEtat as choice">
			<div class="custom-input-group">
				<label>{{ choice | labelFromIdentifier }}</label>
				<input
					class="custom-input"
					placeholder="ETAT_AAA_AAA_111111"
					[(ngModel)]="choice.refEtat"
					appInputRestriction="alphanumeric"
				/>
			</div>
		</div>
		<div *ngIf="state.choices[state.choice] | asRefEtatLess as choice">
			<div class="custom-input-group">
				<label>{{ choice | labelFromIdentifier }}</label>
				<input
					class="custom-input"
					placeholder="ETAT_AAA_AAA"
					[(ngModel)]="choice.refEtatLess"
					appInputRestriction="alphanumeric"
				/>
			</div>
		</div>
		<div *ngIf="state.choices[state.choice] | asOther as choice">
			<div class="flex" style="align-items: center">
				<mat-checkbox [checked]="choice.label === 'PP'" (change)="toggleParticulier(choice)"
					>Identifiant d'un particulier (personne physique)</mat-checkbox
				>
				<app-help [subject]="HelpSubject.PrivateIndividual"></app-help>
			</div>
			<div class="w-100 d-flex" style="justify-content: center" *ngIf="choice.label === 'PP'">
				<div class="message-warning" style="width: 35em">
					<mat-icon>warning</mat-icon>
					<span>{{ OPERAT_PARTICULIER }}</span>
				</div>
			</div>
			<div>
				<div class="custom-input-group">
					<label>Libellé de l'identifiant</label>
					<input
						[disabled]="isOtherLabelInputDisabled"
						class="custom-input"
						[(ngModel)]="choice.label"
						appInputRestriction="alphanumeric"
					/>
				</div>
			</div>
			<div>
				<div class="custom-input-group">
					<label>{{ choice.label === "PP" ? "Adresse email du compte OPERAT" : "Identifiant" }}</label>
					<input class="custom-input" [(ngModel)]="choice.id" [type]="choice.label === 'PP' ? 'email' : ''" />
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-text-gray mr-auto" (click)="exit()">Annuler</button>
		<button [disabled]="!isValid()" class="btn-stroked-primary" (click)="save()">Sauvegarder</button>
	</div>
</div>
