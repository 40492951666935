<app-welcome>
	<div>
		<h1 class="text-secondary" style="font-size: 37px; margin-bottom: 15px">Réinitialiser mon mot de passe</h1>

		<p class="text-gray" style="margin-top: 5px; font-weight: bold">
			Merci d'entrer votre adresse mail, une fois saisi un email vous sera envoyé avec un lien de réinitialisation de
			votre mot de passe.
		</p>
		<p *ngIf="sent" class="text-accent">
			Si vous possédez un compte avec cette addresse, vous allez recevoir un email vous permettant de réinitialiser
			votre mot de passe.
		</p>
		<p class="text-gray" style="font-weight: bold">Email</p>
		<input
			class="custom-input"
			style="width: 100%"
			type="text"
			(ngModelChange)="resetError()"
			[placeholder]="'Entrez votre adresse email'"
			[(ngModel)]="email"
		/>
		<p *ngIf="errorEmail" class="danger">Vérifiez votre adresse mail.</p>
		<div style="margin-top: 10px">
			<a [routerLink]="''">Déjà un compte ?</a>
		</div>
		<button
			mat-flat-button
			[class]="{
				'btn-accent2': true,
				'btn-loading': this.loading,
			}"
			style="width: 100%; margin-top: 25px"
			type="submit"
			(click)="testCredentials()"
			[disabled]="sent"
		>
			RECEVOIR UN EMAIL DE REINITIALISATION
		</button>
	</div>
</app-welcome>
