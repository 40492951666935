import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "timestampPercentage",
})
export class TimestampPercentagePipe implements PipeTransform {
	transform = timestampPercentage;
}

export function timestampPercentage(timestamp: number, start: number, end: number): number {
	return ((timestamp - start) * 100) / (end - start);
}
