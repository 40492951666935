<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" (click)="dialog.closeAll()">close</mat-icon>
	</div>
	<div class="dialog-content pt-0">
		<h3 class="my-0">Confirmez-vous la suppression ?</h3>
		<div class="dialog-small-text">
			<div class="d-flex-col gap-3">
				<div>Si vous confirmez vous allez supprimer les déclarations suivantes:</div>
				<div>
					<ul>
						<li *ngFor="let declaration of group.declarations">{{ declaration.name }}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-actions d-flex" style="justify-content: space-between">
		<button class="btn-stroked-text-gray" [mat-dialog-close]="undefined">Annuler</button>
		<button
			*ngIf="group.declaration_group_id !== undefined"
			mat-flat-button
			class="btn-danger"
			[mat-dialog-close]="true"
			type="submit"
		>
			Confirmer
		</button>
	</div>
</div>
