<ng-container *ngIf="range(minYear, maxYear + 1) as availableYears">
	<div class="slider-container">
		<div class="date-container">
			<ng-container *ngFor="let year of availableYears">
				<button *ngIf="year === currentYear" mat-flat-button color="selected" class="date">
					{{ year }}
				</button>
				<button *ngIf="year !== currentYear" mat-flat-button class="date" (click)="selectYear(year)">
					{{ year }}
				</button>
			</ng-container>
		</div>
		<div
			class="timeline"
			(mousedown)="isDragging = true"
			(mouseup)="isDragging = false"
			(mouseleave)="isDragging = false"
			(mousemove)="mouseDragged($event.clientX)"
			(click)="selectYearOnTimeline($event.clientX)"
		>
			<div class="thumb-container">
				<div
					class="thumb"
					[ngClass]="{ selected: year === currentYear }"
					*ngFor="let year of availableYears"
					(click)="selectYear(year)"
				></div>
				<div #timeline class="timeline-bar"></div>
			</div>
		</div>
	</div>
</ng-container>
