import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { unwrap } from "../helpers/unwrap";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { HasResourceFromOwner } from "../models/HasResourceFromOwner";
import { IdFromResource } from "../models/ids";

@Injectable({
	providedIn: "root",
})
export class FunctionalDeclarationStateService extends HasResourceFromOwner<FunctionalDeclaration> {
	/**
	 * Update the state and the remote state. Prevent the automatic GET following the local state
	 * update. This is useful only for the functional declaration state because its owner will always
	 * fetch new objects when we change the functional entity.
	 * @param resource The resource
	 * @returns An observable that emits when the operation is done.
	 */
	updateSync$(resource: Immutable<FunctionalDeclaration>): Observable<unknown> {
		return this.getOwner().updateOwnedSync$(resource);
	}

	getIdFromResource(resource: Immutable<FunctionalDeclaration>): IdFromResource<FunctionalDeclaration> {
		return unwrap(resource.declaration_functional_id);
	}
}
