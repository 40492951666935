<div class="d-flex mb-2 gap-2" style="justify-content: space-between">
	<p class="title m-0 mr-auto d-flex gap-3" style="justify-content: center">
		6. Génération des fichiers
		<button
			class="btn-primary-light d-flex gap-2"
			*ngIf="declaration$ | async as declaration"
			(click)="openChoiceEfaModal(declaration)"
		>
			<mat-icon>checklist</mat-icon>
			{{
				declaration.chosen_declaration_functional_ids_for_csv.size === 0
					? "Choix des déclarations à générer"
					: declaration.chosen_declaration_functional_ids_for_csv.size === 1
						? "1 déclaration sur " +
							(declaration.declarations_functional.length - (declaration | apply: getIgnoredEntitiesCount)) +
							" choisie"
						: declaration.chosen_declaration_functional_ids_for_csv.size +
							" déclarations sur " +
							(declaration.declarations_functional.length - (declaration | apply: getIgnoredEntitiesCount)) +
							" choisies"
			}}
		</button>
	</p>

	<button class="btn-primary" (click)="startTour()">
		<mat-icon class="mr-1">swipe_up</mat-icon>
		Guide interactif
	</button>
</div>
<div class="message-warning mb-2 d-flex gap-2" style="align-items: center">
	<mat-icon style="min-width: 24px">warning</mat-icon>
	Le nom des fichiers CSV suit une syntaxe précise, contenant notamment la date du jour. Il ne faut donc pas renommer
	ces derniers, sauf si la date du dépôt est différente de celle du téléchargement.
</div>
<ng-container *ngIf="declaration$ | async as declaration">
	<div
		*ngIf="declaration | apply: getIgnoredEntitiesCount as count"
		class="message-accent mb-2 d-flex gap-2"
		style="align-items: center"
	>
		<mat-icon style="min-width: 24px">priority_high</mat-icon>
		Vous avez exclu {{ count === 1 ? "une entité fonctionnelle" : count + " entités fonctionelles" }} de vos fichiers
		CSV (case à cocher dans déclaration patrimoniale).
	</div>
</ng-container>

<div>
	<h2 class="mb-0">Rapport PDF</h2>

	<button
		class="btn-stroked-primary-light d-flex gap-2"
		*ngIf="declaration$ | async as declaration"
		(click)="openPdfReportModal(declaration)"
	>
		<mat-icon>download</mat-icon>
		Télécharger le rapport pdf<ng-container *ngIf="declaration.chosen_declaration_functional_ids_for_csv.size !== 0">
			({{ declaration.chosen_declaration_functional_ids_for_csv.size }}/{{
				declaration.declarations_functional.length - (declaration | apply: getIgnoredEntitiesCount)
			}})</ng-container
		>
	</button>
</div>
<div tourAnchor="csv-efa" *ngIf="declaration$ | async as declaration">
	<div class="message-primary mt-2">
		Les fichiers doivent être importés sur OPERAT dans l'ordre où ils sont présentés. Ils sont traités par OPERAT en une
		quinzaine de minutes.
	</div>
	<h2 class="mb-0">Patrimoine (EFA)</h2>
	<div
		*ngIf="declaration.declarations_functional | apply: countInvalidDatesEntity as count"
		class="message-danger mb-2 d-flex gap-2"
		style="align-items: center"
	>
		<mat-icon style="min-width: 24px">warning</mat-icon>
		<span>
			Depuis la version 4.13 du fichier EFA, vous devez renseigner des informations supplémentaires dans la partie
			<a
				*ngIf="declaration.declaration_id"
				[routerLink]="declaration.declaration_id | apply: RouteDealer.addresses"
				style="color: white"
				>Définition du périmètre</a
			>. Il y a {{ count }} {{ count > 1 ? "entités" : "entité" }} à modifier. Les entités incomplètes ne seront pas
			incluses dans le fichier EFA.
		</span>
	</div>
	<ng-container *ngIf="efa$ | async as csv" class="mb-2">
		<a
			*ngIf="csv.file; else noEfa"
			class="btn-stroked-primary"
			style="width: fit-content"
			[href]="csv.file"
			download="{{ csv.filename }}.csv"
		>
			<span
				class="text-icon"
				matTooltip="Les EFA importées depuis la plateforme OPERAT ne seront pas incluses dans le fichier CSV EFA."
			>
				<mat-icon>download</mat-icon>
				CSV EFA
			</span>
		</a>
		<ng-template #noEfa>Votre fichier EFA ne contient aucune ligne.</ng-template>
	</ng-container>
	<a href="#" class="danger" (click)="openFileRejectedModal(); $event.preventDefault()"
		>Mon fichier est rejeté par OPERAT ?</a
	>
</div>
<div
	tourAnchor="csv-consumption"
	class="d-flex-col"
	style="justify-content: center"
	*ngIf="declaration$ | async as declaration"
>
	<h2 class="mb-0">Consommations</h2>
	<div
		*ngIf="declaration | apply: declarationHasNewlyLiableEntities"
		class="message-warning mb-2 d-flex gap-2"
		style="align-items: center"
	>
		<mat-icon style="min-width: 24px">warning</mat-icon>
		<span
			>Les entités ne seront pas prises en compte dans les déclarations antérieures à leur année d'entrée dans le
			dispositif.</span
		>
	</div>
	<mat-tab-group class="custom-tabs" [dynamicHeight]="true" [disableRipple]="true">
		<mat-tab label="Transfert automatique vers OPERAT">
			<app-operat-api-interactions
				[declaration]="declaration"
				(updateDeclaration)="updateDeclaration($event)"
				(openLockedModal)="openLockedYearDialog($event, 'api')"
			></app-operat-api-interactions>
		</mat-tab>
		<mat-tab label="Fichiers CSV">
			<div class="mb-2 pt-2">
				<app-consumptions-csv
					[declaration$]="declaration$"
					(openLockedModal)="openLockedYearDialog($event)"
				></app-consumptions-csv>
			</div>
		</mat-tab>
	</mat-tab-group>
	<div class="mt-2">
		<ng-container *ngFor="let year of annualDeclarationYears">
			<ng-container
				*ngIf="declaration.declarations_functional | apply: getIncompleteFilterFunction(year) as functionalDeclarations"
			>
				<div
					[id]="year + 'incomplete'"
					*ngIf="
						functionalDeclarations.length > 0 &&
						(functionalDeclarations | lockedFunctionalDeclarationsForYear: year).length < functionalDeclarations.length
					"
				>
					<span class="text-icon"
						><mat-icon>error</mat-icon> Les entités suivantes ont leur consommation {{ year }} incomplète:</span
					>
					<ul>
						<ng-container *ngFor="let functionalDeclaration of functionalDeclarations">
							<li *ngIf="functionalDeclaration.years_token_used.includes(year)">
								<a
									*ngIf="functionalDeclaration.declaration_functional_id as id"
									[routerLink]="id | apply: RouteDealer.referenceEntity"
									class="text-secondary"
								>
									{{ functionalDeclaration.name }}
								</a>
								<i class="text-gray">&nbsp;{{ functionalDeclaration.address | addressToString }}</i>
							</li>
						</ng-container>
					</ul>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="declaration.declarations_functional | apply: filterIsIncomplete2021 as functionalDeclarations">
			<div id="2021incomplete" *ngIf="functionalDeclarations.length > 0">
				<span class="text-icon"
					><mat-icon>error</mat-icon> Les entités suivantes ont leur consommation 2021 incomplète:</span
				>

				<ul>
					<li *ngFor="let functionalDeclaration of functionalDeclarations">
						<a
							*ngIf="functionalDeclaration.declaration_functional_id as id"
							[routerLink]="id | apply: RouteDealer.referenceEntity"
							class="text-secondary"
						>
							{{ functionalDeclaration.name }}
						</a>
						<i class="text-gray">&nbsp;{{ functionalDeclaration.address | addressToString }}</i>
					</li>
				</ul>
			</div>
		</ng-container>
		<ng-container *ngIf="declaration.declarations_functional | apply: filterIsIncomplete2020 as functionalDeclarations">
			<div id="2020incomplete" *ngIf="functionalDeclarations.length > 0">
				<span class="text-icon"
					><mat-icon>error</mat-icon> Les entités suivantes ont leur consommation 2020 incomplète:</span
				>

				<ul>
					<li *ngFor="let functionalDeclaration of functionalDeclarations">
						<a
							*ngIf="functionalDeclaration.declaration_functional_id as id"
							[routerLink]="id | apply: RouteDealer.referenceEntity"
							class="text-secondary"
						>
							{{ functionalDeclaration.name }}
						</a>
						<i class="text-gray">&nbsp;{{ functionalDeclaration.address | addressToString }}</i>
					</li>
				</ul>
			</div>
		</ng-container>
		<ng-container
			*ngIf="declaration.declarations_functional | apply: getEntitiesWithoutReferenceYear as functionalDeclarations"
		>
			<div
				*ngIf="
					functionalDeclarations.length > 0 &&
					(functionalDeclarations | entitiesNotConcernedByYear: ANNUAL_DECLARATION_STARTING_YEAR - 1) <
						functionalDeclarations.length
				"
			>
				<span class="text-icon"><mat-icon>error</mat-icon> Les entités suivantes n'ont pas d'année de référence:</span>
				<div class="message-warning mb-2 d-flex gap-2" style="align-items: center">
					<mat-icon style="min-width: 24px">warning</mat-icon>
					L'année de référence doit être comprise entre 2010 et 2019. Aucune de vos années n'est complète sur cette
					période (au niveau des données de consommation), donc aucune année de référence ne peut être sélectionnée. Si
					aucune année de référence n'est déclarée, 2022 sera sélectionnée par défaut.
				</div>
				<ul>
					<li *ngFor="let functionalDeclaration of functionalDeclarations">
						<a
							*ngIf="functionalDeclaration.declaration_functional_id as id"
							[routerLink]="id | apply: RouteDealer.referenceEntity"
							class="text-secondary"
						>
							{{ functionalDeclaration.name }}
						</a>
						<i class="text-gray">&nbsp;{{ functionalDeclaration.address | addressToString }}</i>
					</li>
				</ul>
			</div>
		</ng-container>
		<div class="message-primary mt-2">
			Pour confirmer définitivement vos déclarations de consommations sur OPERAT, utilisez la fonction "Valider" sur la
			page "Recherche de déclarations de consommations" de la plateforme de l'ADEME.
		</div>
	</div>
</div>

<ng-template #unavailableCsv let-data>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="my-0">{{ data.from === "csv" ? "Fichier CSV" : "Transfert vers OPERAT" }} indisponible</h3>
			<div class="dialog-small-text">
				<div class="d-flex-col gap-3">
					<div>
						Attention, aucune déclaration {{ data.isInitialDeclaration ? "initiale" : "annuelle" }} n’a été effectuée
						pour les EFA étudiées. Par conséquent, il est actuellement impossible de
						{{ data.from === "csv" ? "télécharger ce fichier CSV" : "transférer ces déclarations vers OPERAT" }}.
					</div>
					<div>
						Vous pouvez débuter vos déclarations au sein de la
						<a style="cursor: pointer" class="link-primary" (click)="redirectToConsommation()"
							>Déclaration des consommations</a
						>, en utilisant des crédits initiaux{{ data.isInitialDeclaration ? "" : " ou annuels" }}.
					</div>
				</div>
			</div>
		</div>
		<div class="dialog-actions d-flex" style="justify-content: right">
			<button class="btn-stroked-text-gray" [mat-dialog-close]="undefined">Ok</button>
		</div>
	</div>
</ng-template>
