<app-welcome [showPrice]="true">
	<div>
		<h1 class="text-secondary">Commencer ma déclaration gratuitement</h1>
		<app-register-form (continue)="login()"></app-register-form>
	</div>

	<div class="text-right">
		<a class="mt-3 text-gray" [routerLink]="'/'">Déjà un compte ? Se connecter ></a>
	</div>
</app-welcome>
