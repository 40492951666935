/* eslint-disable @typescript-eslint/no-namespace */
export namespace Siret {
	export const LENGTH = 14;
	const REGEX = /^\d{14}$/;

	export function toSiren(siret: Siret): Siren {
		return wrapCode(siret.slice(0, 9));
	}
	export function isValid(siret: Siret): boolean {
		return REGEX.test(siret);
	}
}

export namespace Siren {
	export const LENGTH = 9;
	const REGEX = /^\d{9}$/;

	export function isValid(siren: Siren): boolean {
		return REGEX.test(siren);
	}
}

export namespace Rna {
	export const LENGTH = 10;
	const REGEX = /^W\d{9}$/;

	export function isValid(rna: Rna): boolean {
		return REGEX.test(rna);
	}
}

export namespace RefEtat {
	const REGEX = /^ETAT_[A-Z]{3}_[A-Z]{2,3}_\d{6}$/;

	export function isValid(refEtat: RefEtat): boolean {
		return REGEX.test(refEtat);
	}
}

export namespace RefEtatLess {
	const REGEX = /^ETAT_[A-Z]{3}_[A-Z]{2,3}$/;

	export function isValid(refEtat: RefEtatLess): boolean {
		return REGEX.test(refEtat);
	}
}

export type Siret = string & { readonly Siret: unique symbol };
export type Siren = string & { readonly Siren: unique symbol };
export type Rna = string & { readonly Rna: unique symbol };
export type RefEtat = string & { readonly RefEtat: unique symbol };
export type RefEtatLess = string & { readonly RefEtatLess: unique symbol }; // useful only for the owner

type Code = Siret | Siren | Rna | RefEtat | RefEtatLess;

export function wrapCode<T extends Code>(code: string): T {
	return code as T;
}

export interface IdSiret {
	siret: Siret;
}

export interface IdSiren {
	siren: Siren;
}

export interface IdRna {
	rna: Rna;
}

export interface IdOther {
	label: string;
	id: string;
}

export interface IdRefEtat {
	refEtat: RefEtat;
}

export interface IdRefEtatLess {
	refEtatLess: RefEtatLess;
}

export function newIdSiret(): IdSiret {
	return { siret: wrapCode("") };
}

export function newIdSiren(): IdSiren {
	return { siren: wrapCode("") };
}

export function newIdRna(): IdRna {
	return { rna: wrapCode("") };
}

export function newIdOther(): IdOther {
	return { label: "", id: "" };
}

export function newRefEtat(): IdRefEtat {
	return { refEtat: wrapCode("") };
}

export function newRefEtatLess(): IdRefEtatLess {
	return { refEtatLess: wrapCode("") };
}

export function toSiren(siret: Siret): Siren {
	return wrapCode(siret.slice(0, 9));
}

export type Identifier = IdSiret | IdSiren | IdRna | IdOther | IdRefEtat | IdRefEtatLess;
export namespace Identifier {
	export function isValid(identifier: Identifier): boolean {
		return "siret" in identifier
			? Siret.isValid(identifier.siret)
			: "rna" in identifier
				? Rna.isValid(identifier.rna)
				: "siren" in identifier
					? Siren.isValid(identifier.siren)
					: "refEtat" in identifier
						? RefEtat.isValid(identifier.refEtat)
						: "refEtatLess" in identifier
							? RefEtatLess.isValid(identifier.refEtatLess)
							: identifier.id !== "" && identifier.label !== "";
	}

	export function equals(a: Identifier, b: Identifier) {
		return (
			("siret" in a && "siret" in b && a.siret === b.siret) ||
			("rna" in a && "rna" in b && a.rna === b.rna) ||
			("siren" in a && "siren" in b && a.siren === b.siren) ||
			("refEtat" in a && "refEtat" in b && a.refEtat === b.refEtat) ||
			("refEtatLess" in a && "refEtatLess" in b && a.refEtatLess === b.refEtatLess) ||
			("id" in a && "id" in b && a.id === b.id && a.label === b.label)
		);
	}
}

// From those markers, we can deduce other types
export type Marker =
	| TenantMarker
	| OwnerMarker
	| CondominiumMarker
	| TenantWithoutSiretMarker
	| OwnerWithoutRefEtatMarker;

export type TenantMarker = "tenant";
export type TenantWithoutSiretMarker = "tenantWithoutSiret";
export type OwnerMarker = "owner";
export type CondominiumMarker = "condominium";
export type OwnerWithoutRefEtatMarker = "ownerWithoutRefEtat";

/*

To know more about conditional types https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
Conditional typing is a powerful feature that makes Typescript able to know a lot of things from a type.
For example if we say that we are an Owner, we use the OwnerMarker type. This type is empty, it becomes useful if we use the conditional types below.
So from OwnerMarker, we know that we accept a Siren | Rna | Other union as an identifier (so the Owner type) thanks to MarkerToUnion.
To force the dev to provide a correct list of the possibilities, we use MarkerToTuple. In other languages we can "link" real values/method to types
but we can't in Typescript. That's why MarkerToTuple exists. And no we can't use static methods from a class because we are not manipulating classes
but only interfaces AND if we were using classes we would always provide an instance to use the static method, even if the class is empty.
Thanks to those types, the compiler checks for us if we handle the right codes with Owner, Tenant or Condominium.
You can check the IdentifierModal component to see more.

*/

export type MarkerToUnion<T extends Marker> = T extends TenantMarker
	? Tenant
	: T extends OwnerMarker
		? Owner
		: T extends TenantWithoutSiretMarker
			? TenantWithoutSiret
			: T extends CondominiumMarker
				? Condominium
				: T extends OwnerWithoutRefEtatMarker
					? OwnerWithoutRefEtat
					: never;

export type MarkerToTuple<T extends Marker> = (T extends TenantMarker
	? [IdSiret, IdRna]
	: T extends OwnerMarker
		? [IdSiren, IdRna, IdRefEtatLess, IdOther]
		: T extends TenantWithoutSiretMarker
			? [IdSiren]
			: T extends CondominiumMarker
				? [IdSiret, IdRna, IdRefEtat, IdOther]
				: T extends OwnerWithoutRefEtatMarker
					? [IdSiren, IdRna, IdOther]
					: never) &
	MarkerToUnion<T>[]; // we have to do an intersection because
// Typescript doesn't know that MarkerToTuple<T> always extends MarkerToUnion<T>[];

export type Tenant = IdSiret | IdRna;
export type Owner = IdSiren | IdRna | IdOther | IdRefEtatLess;
export type OwnerWithoutRefEtat = IdSiren | IdRna | IdOther;
export type Condominium = IdSiret | IdRna | IdOther | IdRefEtat;
export type TenantWithoutSiret = IdSiren;
