import { Pipe, PipeTransform } from "@angular/core";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { Declaration } from "../models/declaration";
import { DateToTimestampPipe } from "./date-to-timestamp.pipe";
import { PeriodRangePipe } from "./period-range.pipe";
import { SumCategoryPeriodPipe } from "./sum-category-period.pipe";
import { Immutable } from "immer";

@Pipe({
	name: "sumCategorySurfacePerYear",
})
export class SumCategorySurfacePerYearPipe implements PipeTransform {
	transform = sumCategorySurfacePerYear;
}

export function sumCategorySurfacePerYear(declarationFunctional: Immutable<FunctionalDeclaration>): {
	[year: number]: number;
} {
	const surfaces: { [year: number]: number } = {};

	const periodRangePipe = new PeriodRangePipe();
	const dateToTimestamp = new DateToTimestampPipe();
	const sumCategoryPeriodPipe = new SumCategoryPeriodPipe();

	for (let year = Declaration.MINIMUM_YEAR; year <= new Date().getFullYear(); year++) {
		const period = periodRangePipe.transform(year, declarationFunctional.infos.favoritePeriod);
		const periodStart = dateToTimestamp.transform(period.start);
		const periodEnd = dateToTimestamp.transform(period.end);

		surfaces[year] = sumCategoryPeriodPipe.transform(declarationFunctional, periodStart, periodEnd);
	}

	return surfaces;
}
