import { Nullable } from "./nullable";

/**
 * If the value is null then it throws an error. It is useful when we
 * are "sure" that the value is not null.
 * @param nullable The value to unwrap
 * @returns The non null value
 */
export function unwrap<T>(nullable: Nullable<T>): T {
	if (nullable === null || nullable === undefined) {
		throw new Error("Trying to unwrap a null value.");
	}

	return nullable;
}
