import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";

@Pipe({
	name: "inSet",
})
export class InSetPipe implements PipeTransform {
	transform<T>(set: Immutable<Set<T>>, value: Immutable<T>): boolean {
		return set.has(value);
	}
}
