import { Immutable } from "immer";

export function entries<K extends string | number | symbol, V>(
	object: Immutable<Record<K, V>>,
): [string, Immutable<V>][] {
	// I have to disable the lint. This file will do that a lot. The casting with the generic types
	// is
	// not really safe but let's say it's not important (it can be false because we can freely put
	// garbage inside an object).
	return Object.entries(object);
}

export function keys<K extends string | number | symbol>(object: Immutable<Record<K, unknown>>): string[] {
	return Object.keys(object);
}

export function values<T>(object: Immutable<Record<string | number | symbol, T>>): Immutable<T>[] {
	return Object.values(object);
}
