<ng-container
	*ngFor="
		let startTimestamp of [(year | periodRange: functionalDeclaration.infos.favoritePeriod).start | dateToTimestamp]
	"
>
	<ng-container
		*ngFor="
			let endTimestamp of [(year | periodRange: functionalDeclaration.infos.favoritePeriod).end | dateToTimestamp]
		"
	>
		<div class="custom-accordion-green" #consumptionTable>
			<div>
				<h3>Consommations générales</h3>
			</div>
			<ng-container *ngIf="functionalDeclaration | isMultiOccupation; else elseBlock">
				<h2>Consommation individuelle</h2>
				<table class="custom-table-consumption">
					<tr>
						<th class="corner"></th>
						<th>Énergie</th>
						<th *ngFor="let header of HEADERS_MONTHLY; index as i">
							{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
							{{
								(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
									((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
							}}
						</th>
					</tr>
					<tbody
						app-consumption-table-row
						[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
						[start]="startTimestamp"
						[end]="endTimestamp"
						[colorTimeline]="'green'"
						[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][category]"
						class="bigline"
						*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL] | objectKeys"
						[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
						[category]="EnergyCategory.INDIVIDUAL"
					></tbody>
				</table>
				<h2>Consommation commune</h2>
				<table class="custom-table-consumption">
					<tr>
						<th class="corner"></th>
						<th>Énergie</th>
						<th *ngFor="let header of HEADERS_MONTHLY; index as i">
							{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
							{{
								(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
									((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
							}}
						</th>
					</tr>
					<tbody
						app-consumption-table-row
						[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
						[start]="startTimestamp"
						[end]="endTimestamp"
						[colorTimeline]="'green'"
						[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.COMMON][category]"
						class="bigline"
						*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.COMMON] | objectKeys"
						[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
						[category]="EnergyCategory.COMMON"
					></tbody>
				</table>
				<h2>Consommation répartie</h2>
				<table class="custom-table-consumption">
					<tr>
						<th class="corner"></th>
						<th>Énergie</th>
						<th *ngFor="let header of HEADERS_MONTHLY; index as i">
							{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
							{{
								(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
									((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
							}}
						</th>
					</tr>
					<tbody
						app-consumption-table-row
						[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
						[start]="startTimestamp"
						[end]="endTimestamp"
						[colorTimeline]="'green'"
						[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED][category]"
						class="bigline"
						*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED] | objectKeys"
						[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
						[category]="EnergyCategory.DISTRIBUTED"
					></tbody>
				</table>
			</ng-container>
			<ng-template #elseBlock>
				<table class="custom-table-consumption">
					<tr>
						<th class="corner"></th>
						<th>Énergie</th>
						<th *ngFor="let header of HEADERS_MONTHLY; index as i">
							{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
							{{
								(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
									((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
							}}
						</th>
					</tr>
					<tbody
						app-consumption-table-row
						[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
						[start]="startTimestamp"
						[end]="endTimestamp"
						[colorTimeline]="'green'"
						[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][category]"
						class="bigline"
						*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL] | objectKeys"
						[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
						[category]="EnergyCategory.INDIVIDUAL"
					></tbody>
				</table>
			</ng-template>
		</div>

		<div class="custom-accordion-red" #consumptionTable style="margin-bottom: 50px">
			<div>
				<h3>Consommations à déduire</h3>
			</div>
			<table class="custom-table-consumption">
				<tr>
					<th class="corner"></th>
					<th>Énergie</th>
					<th *ngFor="let header of HEADERS_MONTHLY; index as i">
						{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
						{{
							(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
								((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
						}}
					</th>
				</tr>
				<tbody
					app-consumption-table-row
					[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
					[start]="startTimestamp"
					[end]="endTimestamp"
					[colorTimeline]="'red'"
					[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.DEDUCT][category]"
					class="bigline"
					*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.DEDUCT] | objectKeys"
					[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
					[category]="EnergyCategory.DEDUCT"
				></tbody>
			</table>
		</div>
	</ng-container>
</ng-container>
