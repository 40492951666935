import { Pipe, PipeTransform } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { EfaStatus, getIdentifierStringFromEfaStatus } from "../models/efaStatus";
import { Etablissement } from "../models/siren-api.types";

@Pipe({
	name: "getLabelEstablishment",
})
export class GetLabelEstablishmentPipe implements PipeTransform {
	transform(value: Immutable<Etablissement>): string {
		return getLabelEtablissement(value);
	}
}

export function getLabelEtablissement(value: Immutable<Etablissement>): string {
	const [functionalDeclaration] = value.functionalEntities;

	const { description, efa_status } = functionalDeclaration;
	const identifierFromQualite = getIdentifierStringFromEfaStatus(efa_status);

	return description
		? description
		: identifierFromQualite === ""
			? isOwnerWithoutSiret(efa_status)
				? "Entité(s) sans SIRET"
				: "Entité(s) sans occupant"
			: identifierFromQualite;
}

function isOwnerWithoutSiret(efaStatus: EfaStatus) {
	return (
		efaStatus.type === GreenKeys.KEY_SIREN &&
		efaStatus.status.type === GreenKeys.KEY_OWNER_OCCUPANT &&
		efaStatus.status.data.without_siret
	);
}
