import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { Route, RouteDealer } from "src/app/models/routes";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserStateService } from "src/app/services/user-state.service";
import { BaseComponent } from "../../models/base-component.directive";
import { ResourceType } from "../../models/resource";
import { AuthorizationViewLoaderService } from "../../services/authorization-view-loader.service";
import { AuthorizationService } from "../../services/authorization.service";
import { InitStateService } from "../../services/init-state.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseComponent {
	// // Form with email and password field
	// formGroup = new FormGroup({
	//   email: new FormControl(undefined, Validators.required),
	//   password: new FormControl(undefined, Validators.required),
	// });
	public loading: boolean = false;
	public error: string | null = null;
	public email: string = "";
	public errorEmail: boolean = false;
	public password: string = "";
	public errorPassword: boolean = false;
	Route = Route;
	nextAuthorization: string | undefined;

	constructor(
		private authentication: AuthenticationService,
		private router: Router,
		private userState: UserStateService,
		private dialog: MatDialog,
		private authorizationViewLoader: AuthorizationViewLoaderService,
		private authorizationService: AuthorizationService,
		private initStateService: InitStateService,
	) {
		super();

		const url = new URL(window.location.href);
		const nextAuthorization = url.searchParams.get("next");
		if (nextAuthorization) {
			this.nextAuthorization = nextAuthorization;
		}

		if (userState.user) {
			this.goNext();
		}
	}

	goNext() {
		if (this.nextAuthorization) {
			this.authorizationService
				.getById$(this.nextAuthorization)
				.pipe(
					switchMap((authorization) => {
						const { resource_id, resource_type } = authorization;

						switch (resource_type) {
							case ResourceType.FunctionalDeclaration:
								return this.initStateService
									.initFunctionalDeclaration$(resource_id)
									.pipe(map(() => RouteDealer.qualification(resource_id)));
							case ResourceType.Declaration:
								return this.initStateService
									.initDeclaration$(resource_id)
									.pipe(map(() => RouteDealer.addresses(resource_id)));
							case ResourceType.DeclarationGroup:
								return this.initStateService
									.initGroup$(resource_id)
									.pipe(map(() => RouteDealer.myDeclaration(resource_id)));
							case ResourceType.Representative:
								return this.initStateService
									.initRepresentative$(resource_id)
									.pipe(map(() => RouteDealer.myDeclaration(resource_id)));
						}
					}),
				)
				.subscribe((route) => this.router.navigate(route));
		} else {
			const url = localStorage.getItem("lastUrl");
			localStorage.removeItem("lastUrl");
			localStorage.setItem("user", JSON.stringify(this.userState.user));
			if (url) {
				this.router.navigate([url]);
			} else {
				this.authorizationViewLoader.loadModal(true, true);
			}
		}
	}

	public resetError(): void {
		this.errorEmail = false;
		this.errorPassword = false;
		this.error = null;
	}

	public testCredentials(): void {
		this.errorEmail = false;
		this.errorPassword = false;
		if (this.email.length === 0) {
			this.errorEmail = true;
		}
		if (this.password.length === 0) {
			this.errorPassword = true;
		}
		if (!this.errorEmail && !this.errorPassword) {
			this.formSubmit();
		}
	}

	formSubmit(): void {
		this.loading = true;
		this.authentication.login$(this.email, this.password).subscribe(
			(user) => {
				this.authentication.logout();
				this.userState.user = user;
				this.goNext();
			},
			(error: unknown) => {
				this.loading = false;
				if (error instanceof HttpErrorResponse && error.status === 400) {
					this.error = "Identifiants non valides.";
					return;
				}
				throw error;
			},
		);
	}
}
