import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { AgreementAsk } from "../models/agreementAsk";

@Pipe({
	name: "onCoolodwn",
})
export class OnCoolodwnPipe implements PipeTransform {
	transform(agreement: Immutable<AgreementAsk>): boolean {
		if (!agreement.email_sent_at) {
			return false;
		}
		return Math.floor(Date.now() / 1000) - agreement.email_sent_at < 60 * 60 * 24;
	}
}
