import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { getCorrectionDataList } from "../helpers/conso";
import { FunctionalDeclaration } from "../models/functionalDeclaration";

@Pipe({
	name: "getReport",
})
export class GetReportPipe implements PipeTransform {
	transform(
		functionalDeclaration: Immutable<FunctionalDeclaration>,
		dju: Immutable<{ [year: string]: number }>,
		absoluteObjectivePerYear: Immutable<{ [year: string]: number }>,
		referenceYear: number | undefined,
	) {
		return getCorrectionDataList(functionalDeclaration, dju, absoluteObjectivePerYear).find(
			({ year }) => year === referenceYear,
		);
	}
}
