import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { DeclarationGroup } from "../models/declarationGroup";
import { Lazy } from "../models/lazy";

@Pipe({
	name: "groupHasTokenUsed",
})
export class GroupHasTokenUsedPipe implements PipeTransform {
	transform(group: Immutable<Lazy<DeclarationGroup>>): boolean {
		for (const declaration of group.declarations) {
			for (const functionalDeclaration of declaration.declarations_functional) {
				if (functionalDeclaration.is_token_used) {
					return true;
				}
			}
		}
		return false;
	}
}
