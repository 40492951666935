<div class="dialog-container tall" style="min-width: 650px">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content">
		<h3 class="mt-0 mb-2">{{ adding ? "Ajouter une ligne" : "Modifier une ligne" }}</h3>
		<app-custom-form #form>
			<div class="d-flex" style="flex-direction: column">
				<div tourAnchor="consumption-add-row-modal-name" *ngIf="energyTypeAvailable.length > 0" class="d-flex-col">
					<mat-label>Nom de la catégorie</mat-label>
					<input
						class="custom-input"
						style="margin-bottom: 15px"
						name="label"
						type="text"
						[(ngModel)]="row.label"
						required
					/>
				</div>
				<div tourAnchor="consumption-add-row-modal-energy">
					<label>Energies</label>
					<ng-select
						appearance="outline"
						style="margin-bottom: 15px"
						[clearable]="false"
						[multiple]="true"
						[closeOnSelect]="true"
						[(ngModel)]="energyTypes"
						(change)="updateEnergyInRow()"
						[disabled]="agreement !== undefined"
					>
						<ng-option *ngFor="let type of energyTypeAvailable" [value]="type">
							{{ type | energyTypeTitle }}
						</ng-option>
					</ng-select>
				</div>
				<div tourAnchor="consumption-add-row-modal-activity">
					<div *ngFor="let energyType of energyTypes" style="margin-bottom: 25px">
						<div class="d-flex gap-1">
							<div class="custom-input-group w-100">
								<label>Energie</label>
								<input class="custom-input" name="label" type="text" [value]="energyType | energyTypeTitle" disabled />
							</div>
							<div *ngIf="isDeduct && sharedBuilding" class="w-100">
								<label>Déduire des consommations</label>
								<ng-select [(ngModel)]="row.values[energyType].deductTo">
									<ng-option *ngFor="let deductable of deductableCategories" [value]="deductable">{{
										deductable | energyCategoriesLabel
									}}</ng-option>
								</ng-select>
							</div>
							<ng-container *ngIf="row.values[energyType].byInvoice === false">
								<div class="custom-input-group w-100">
									<label>Date mise en activité (incluse)</label>
									<input
										class="custom-input"
										name="date_start"
										type="date"
										[min]="functionalDeclarationStartingDate ?? START | date: 'y-MM-dd'"
										[max]="TODAY | date: 'y-MM-dd'"
										required
										#startValue
										(change)="onStartDateChange(startValue.value, row.values[energyType])"
										[value]="row.values[energyType].start | timestampToDate"
									/>
									<span class="input-error">{{ startErrorMessage }}</span>
								</div>
								<div class="custom-input-group w-100">
									<label>Date fin d'activité (exclue)</label>
									<input
										class="custom-input"
										name="date_end"
										type="date"
										[min]="startValue.value | followingDay"
										[max]="TODAY | date: 'y-MM-dd'"
										#endValue
										(change)="onEndDateChange(endValue.value, row.values[energyType])"
										[value]="
											row.values[energyType].end !== undefined
												? (row.values[energyType].end! | timestampToDate)
												: undefined
										"
									/>
									<div class="custom-input-error">{{ endValue.validationMessage }}</div>
								</div>
								<button
									(click)="updateUmpteenth(row.values[energyType], energyType)"
									class="btn-squared btn-warning mt-auto"
									matTooltip="Modifier les tantièmes pour cette ligne"
								>
									<mat-icon>percent</mat-icon>
								</button>
							</ng-container>
							<ng-container *ngIf="row.values[energyType].byInvoice">
								<div class="custom-input-group w-100">
									<label>Année en activité</label>
									<input
										class="custom-input"
										name="date_start"
										type="number"
										[min]="functionalDeclarationStartingDate ? functionalDeclarationStartingDate.getFullYear() : '2009'"
										[max]="TODAY.getFullYear()"
										required
										#startYearValue
										(change)="onStartYearChange(startYearValue.value, row.values[energyType])"
										[value]="row.values[energyType].start | timestampToDate: 'yyyy'"
									/>
									<div class="custom-input-error">{{ startErrorMessage }}</div>
								</div>
								<div class="custom-input-group w-100">
									<label>Année fin d'activité</label>
									<input
										class="custom-input"
										name="date_end"
										type="number"
										[min]="functionalDeclarationStartingDate ? functionalDeclarationStartingDate.getFullYear() : '2009'"
										[max]="TODAY.getFullYear()"
										#endYearValue
										(change)="onEndYearChange(endYearValue.value, row.values[energyType])"
										[value]="
											row.values[energyType].end !== undefined
												? (row.values[energyType].end! | timestampToDate: 'yyyy')
												: undefined
										"
									/>
									<div class="custom-input-error">{{ endYearValue.validationMessage }}</div>
								</div>
							</ng-container>
						</div>
						<mat-checkbox
							[(ngModel)]="row.values[energyType].byInvoice"
							color="primary"
							*ngIf="energyType | inArray: DELIVERABLE"
							(ngModelChange)="onDeliveryChange(row.values[energyType])"
						>
							Saisie par livraison
							<app-help [subject]="HelpSubject.ByDelivery"></app-help>
						</mat-checkbox>
					</div>
				</div>
			</div>
		</app-custom-form>
	</div>
	<div class="dialog-actions gap-1">
		<button class="btn-stroked-text-gray mr-auto" [matDialogClose]="adding ? undefined : this.initialRow">
			Fermer
		</button>
		<button
			class="btn-stroked-danger"
			type="submit"
			(click)="delete()"
			[disabled]="agreement !== undefined && energyCategory === agreement.energy_category"
			[matTooltip]="
				agreement !== undefined && energyCategory === agreement.energy_category
					? 'Afin de supprimer un compteur d\'énergie, merci de vous rendre dans la déclaration patrimoniale'
					: ''
			"
		>
			Supprimer cette valeur
		</button>
		<button
			*ngIf="
				agreement && agreement.short_data_received && agreement.year_data_received && agreement.original_consumptions
			"
			class="btn-stroked-warning"
			type="submit"
			(click)="reloadData()"
			matTooltip="Cette action provoquera le remplacement des données par celles transmises par le distributeur"
			matTooltipPosition="above"
		>
			Recharger les données
		</button>
		<button
			class="btn-stroked-primary"
			[disabled]="!form.formValid || energyTypes.length === 0 || startErrorMessage !== ''"
			type="submit"
			[matDialogClose]="this.row"
			tourAnchor="consumption-add-row-modal-save"
		>
			{{ adding ? "Ajouter" : "Modifier" }}
		</button>
	</div>
</div>
