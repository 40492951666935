import { Pipe, PipeTransform } from "@angular/core";
import { Identifier, IdSiren } from "../models/identifier";

@Pipe({
	name: "asSiren",
})
export class AsSirenPipe implements PipeTransform {
	transform(id: Identifier): IdSiren | undefined {
		return "siren" in id ? id : undefined;
	}
}
