<div class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content" [ngSwitch]="state.subject">
		<div *ngSwitchCase="HelpSubject.FloorArea">
			<p>
				La Surface De Plancher (SDP) correspond à la somme des surfaces de tous les niveaux construits, clos et couvert,
				dont la hauteur de plafond est supérieure à 1,80 m. Elle se mesure au nu intérieur des murs de façades de la
				construction, et ne prend donc pas en compte l’épaisseur des murs extérieurs.
			</p>
			<p>La surface de plancher s'obtient après déduction des surfaces suivantes :</p>
			<ul>
				<li>
					Surfaces correspondant à l'épaisseur des murs entourant les embrasures des portes et fenêtres donnant sur
					l'extérieur
				</li>
				<li>Vides et trémies (qui correspondent aux escaliers et ascenseurs)</li>
				<li>Surfaces de plancher d'une hauteur sous plafond inférieure ou égale à 1,80 m</li>
				<li>
					Surfaces de plancher aménagées pour le stationnement des véhicules motorisés ou non, y compris les rampes
					d'accès et les aires de manœuvres
				</li>
				<li>
					Surfaces de plancher des combles non aménageables pour l'habitation ou pour des activités à caractère
					professionnel, artisanal, industriel ou commercial (par exemple, des combles avec un encombrement de la
					charpente important ou un plancher qui ne peut pas supporter des charges
				</li>
			</ul>
			<p>
				La Surface Utile Brute (SUB) ainsi que la Surface Commerciale Utile (SCU ou GLA) constituent également des
				références suffisantes si vous ne disposez pas de la Surface de Plancher.
			</p>
			<p>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.formulaires.service-public.fr/gf/getAnnexe.do?cerfaAnnexe=2&cerfaFormulaire=88065"
					>Formulaire de calcul
				</a>
				.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.Site">
			<h4 class="pb-1 mb-0">Site:</h4>
			<p class="mt-0">
				La notion de site s’apprécie au niveau de l’entité d’exploitation (lien fonctionnel), qu’il faut prendre en
				considération pour apprécier l’assujettissement. Un site peut s’étendre sur plusieurs unités foncières.
			</p>
			<p>A titre d’exemple, un site peut correspondre à :</p>
			<ul>
				<li>
					Bureaux ou centre administratif (Hôtel de Région, Hôtel de département, Mairie et services municipaux, cité
					administrative, site d’une entreprise ou société…)
				</li>
				<li>Etablissement d’enseignement (groupe scolaire, collège, lycée, campus universitaire)</li>
				<li>Etablissement hospitalier</li>
				<li>Complexe sportif regroupant plusieurs équipements sportifs</li>
				<li>Site culturel</li>
				<li>Site de production du secteur primaire ou secondaire</li>
			</ul>
			<h4 class="pb-1 mb-0">Unité foncière:</h4>
			<p class="mt-0">
				Selon le Conseil d’État une unité foncière est un « îlot d'un seul tenant composé d'une ou plusieurs parcelles
				appartenant à un même propriétaire ou à la même indivision ». Il s’agit donc d’un îlot de propriété d’un seul
				tenant. Le propriétaire peut être une personne morale de droit privé ou de droit public ou une personne
				physique. Par ailleurs, la continuité foncière ne doit pas être interrompue. Par exemple, si une voie sépare
				deux parcelles, ces deux parcelles forment une unité foncière si et seulement si le propriétaire de la voie est
				le même que le propriétaire des parcelles.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.FunctionalEntityTodo" class="dialog-small-text">
			<p>
				Si une entité est grisée, cela signifie que vous avez besoin de vous occuper des étapes précédentes pour accéder
				à celle-ci !
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.AccessoryArea" class="dialog-small-text">
			<p>
				Cette catégorie s'applique uniquement aux cas très particuliers des logements de fonction intimement liés à une
				activité tertiaire.
			</p>
			<p>
				Sont concernés le logement d'un préfet dans une préfecture ainsi que les logements des gardiens d'équipements
				sportifs.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.FavoritePeriod" class="dialog-small-text">
			<p>Par défaut, la déclaration des consommations se base sur une année calendaire.</p>
			<p>
				Il est cependant possible de choisir une période différente, pour s’accorder par exemple sur votre année
				comptable ou sur vos rapports RSE.
			</p>
		</div>
		<div
			*ngSwitchCase="state.subject === HelpSubject.IndividualConso || state.subject === HelpSubject.ConsumptionType ? state : ''"
		>
			<p>
				Les <b>consommations individuelles</b> sont celles liées aux équipements dont l’exploitant du local tertiaire a
				la gestion et pleinement la maîtrise (compteur général dédié par exemple).
			</p>
		</div>
		<div
			*ngSwitchCase="state.subject === HelpSubject.SharedConso || state.subject === HelpSubject.ConsumptionType ? state.subject : ''"
		>
			<p>
				Les <b>consommations communes</b> sont celles liées à l’exploitation des parties communes dont l’exploitation
				est gérée par le propriétaire ou par le Syndicat de copropriété (ascenseurs, couloirs, hall, etc&#8230;).
			</p>
		</div>
		<div
			*ngSwitchCase="state.subject === HelpSubject.DistributedConso || state.subject === HelpSubject.ConsumptionType ? state.subject : ''"
		>
			<p>
				Les <b>consommations réparties</b> sont celles liées aux équipements dont l’exploitation est gérée par le
				propriétaire ou le Syndicat de copropriété et dont le local tertiaire bénéficie directement (chauffage ou
				refroidissement collectif, etc&#8230;).
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.LotNumber" class="dialog-small-text">
			<p>
				Le numéro de lot permet de distinguer les différents lots d’une même société au sein d’un bâtiment en
				multi-occupation. Il figure en général sur les contrats de bail. Si rien n’est renseigné, la valeur du numéro de
				lot sera 0 par défaut.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.ContractHolder" class="dialog-small-text">
			<p>Le titulaire est la personne morale ou physique figurant sur le contrat d'énergie.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.Referent" class="dialog-small-text">
			<p>
				Le référent est la personne disposée à donner l'autorisation d'accéder aux données de consommations de ce
				PRM/PCE.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.DeductConso" class="dialog-small-text">
			<p>
				Ce tableau reprend les consommations qui peuvent être déduites de votre consommation totale. Vous pouvez y
				renseigner les consommations des
				<b>locaux non tertiaires (ou exemptés)</b> qui seraient rattachées au compteur principal, dès lors qu’un
				<b>sous-comptage</b> existe.<br />
				De même, les consommations des <b>bornes IRVE</b>, ainsi que de l’<b>éclairage extérieur par candélabre</b>
				rattachées à votre compteur principal peuvent aussi être déduites (sous condition d’un sous-comptage également).
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.Tantieme" class="dialog-small-text">
			<p>
				Les tantièmes correspondent à la part des consommations communes/réparties qui vous est attribuée. Ils sont
				exprimés sous forme de rapport (exemple : 50/1000 )
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.ByDelivery" class="dialog-small-text">
			<p>
				Si vous ne disposez pas de comptage précis, vous pouvez directement saisir l'historique de vos livraisons de
				combustibles stockables. Les consommations seront alors estimées à partir d'une moyenne de ces livraisons sur
				plusieurs années. Merci de choisir la période sur laquelle effectuer cette moyenne
			</p>
			<p>
				Si vous avez déjà renseigné des données de consommations pour cette ligne, ce mode de saisie les écrasera. Si
				vous souhaitez combiner les deux modes de saisie, veuillez créer deux lignes de consommations distinctes.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.RNA" class="dialog-small-text">
			<p>
				Le RNA est analogue au SIREN, mais pour les associations. Si votre association dispose d'un SIREN/SIRET et d'un
				RNA, privilégiez le SIREN/SIRET
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.Qualification" class="dialog-small-text">
			<p>Nous allons désormais étudier l’assujettissement de vos bâtiments, adresse par adresse.</p>
			<p>
				Définissez les emplacements de votre ou vos bâtiment(s)<span style="font-weight: bold">
					en cliquant sur le bouton "Bâtiment"</span
				>
				puis <span style="font-weight: bold">sélectionnez sur la carte</span> votre ou vos établissements. Faites de
				même avec votre ou vos parcelles.
			</p>
			<p>Renseignez enfin les différentes surfaces pour chacun d'entre eux.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.Addresses">
			<p>La première étape est de définir le patrimoine que vous souhaitez étudier.</p>
			<p>
				Vous trouverez ci-dessous la liste des établissements associés au SIRET/SIREN renseigné. Libre à vous de
				supprimer ou d’ajouter des établissements. Vous pouvez ajouter plusieurs
				<span class="text-icon">
					<b>Entités Fonctionnelles</b>
					<mat-icon>domain</mat-icon>
				</span>
				par adresse. Vous pouvez également renommer ou supprimer vos
				<span class="text-icon">
					<b>Entités Fonctionnelles</b>
					<mat-icon>domain</mat-icon> </span
				>.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.CustomLabel" class="dialog-small-text">
			<p>
				La création d'un établissement sans SIRET est autorisée uniquement pour les "Collectivités territoriales" et les
				"Opérateurs de l'Etat".
			</p>
			<p>L'établissement ainsi créé sera rattaché au SIREN de votre structure.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.LongHistory">
			<p>
				Le recueil d'historique long auprès de GRDF ne peut actuellement pas être automatisé. Si l'historique court est
				insuffisant pour vous, il est possible de faire une demande manuelle, en remplissant le mandat et le fichier
				Excel mis à disposition ci-dessous. Il vous suffira d'envoyer les documents complétés à l'adresse électronique
				suivante :
				<a href="mailto: data-factory@grdf.fr">data-factory&#64;grdf.fr</a>
				. (les demandes sont traitées sous plusieurs semaines) Attention : la demande peut concerner plusieurs PCE à la
				fois.
			</p>
			<div class="d-flex gap-2" style="flex-direction: row-reverse">
				<a class="btn-primary" href="assets/Demande_historique_GRDF.xlsx">
					<mat-icon>download</mat-icon>
					Télécharger l'Excel type
				</a>
				<a class="btn-primary" href="assets/Mandat.docx">
					<mat-icon>download</mat-icon>
					Télécharger le mandat
				</a>
			</div>
		</div>
		<div *ngSwitchCase="HelpSubject.ProofFiles" class="dialog-small-text">
			<p>
				Vous pouvez déposer ici vos fichiers justificatifs. Vous pouvez librement les télécharger en cas de contrôle sur
				les données de consommation que vous avez renseignées.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.Benchmark">
			<p>Chaque point sur ce graphique représente une Entité Fonctionnelle Assujettie.</p>
			<p>
				L’abscisse du point correspond à la consommation totale du site considérée dans le cadre du décret tertiaire.
				Donc plus le point est à droite, plus le site est consommateur.
			</p>
			<p>
				L’ordonnée du point représente sa consommation considérée dans le cadre du décret tertiaire divisée par sa
				surface considérée dans le cadre du décret tertiaire. Plus le point est bas, plus le site est performant, et
				plus le point est haut, moins le site est efficient.
			</p>
			<p class="danger">
				<b>Attention</b>, les échelles du graphe sont adaptées aux performances de votre patrimoine : la comparaison est
				donc relative. Ainsi, si tout votre patrimoine est performant, certains sites apparaîtront tout de même dans des
				zones de faible performance. Néanmoins, ce sera quand même sur ces sites que les mesures d’économie d’énergie
				seront le plus pertinentes.
			</p>
			<p>
				<b>
					Ainsi, les points en haut à droite correspondent au site qui consomment beaucoup et qui sont peu performants,
					donc ce sont certainement les sites sur lesquels il faut prioriser la mise en place de mesures d’économies
					d’énergie.
				</b>
			</p>
			<p>
				Les points en bas à droite sont performants mais ils consomment beaucoup : ce sont vraisemblablement de grands
				sites bien suivis. Il est intéressant d’aller chercher des économies supplémentaires, mais ce sera compliqué.
			</p>
			<p>
				Les point en haut à gauche ne sont pas performants mais consomment peu dans l’absolu : il s’agit probablement de
				petits sites. Ils ont de la marge de progression, mais la quantité d’énergie économisée sera assez faible.
			</p>
			<p>
				Les points en bas à gauche consomment peu et sont performants, donc il n’y a pas beaucoup d’économies à aller
				chercher sur ces sites. Il faut cependant veiller à maintenir leur performance. Ils peuvent servir d’exemple
				pour faire des améliorations sur les autres sites.
			</p>
			<img src="assets/img/benchmark_explain.png" alt="benchmark explain" class="w-100" />
			<p>
				Les valeurs ont volontairement été retirées sur les axes de cet exemple, parce que c’est une comparaison entre
				sites, et que les valeurs seuils peuvent dépendre des activités exercées.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.EnergyTypes" class="dialog-small-text">
			Si l'établissement possède un groupe électrogène ou un groupe de secours, n'oubliez pas de renseigner les
			consommations des types d'énergie nécessaires au fonctionnement de ces derniers (fioul ou gaz par exemple).
		</div>
		<div *ngSwitchCase="HelpSubject.WeatherStation">
			<p>
				La station météo la plus appropriée correspond par défaut à celle la plus proche. En revanche, dans certaines
				configurations (établissement en altitude, sur le littoral, dans une vallée, etc.), une autre station plus
				éloignée peut être plus représentative de la réalité. Vous pouvez donc choisir la plus adaptée parmi la liste
				des stations des départements limitrophes.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.PrivateIndividual" class="dialog-small-text">
			<p>Un particulier (personne physique) est représenté par un identifiant "Autre" avec un libellé "PP".</p>
		</div>
		<div *ngSwitchCase="HelpSubject.EfaInCsv" class="dialog-small-text">
			<p>Votre entité n'est pas assujettie, cocher cette case l'intégrera quand même à vos déclarations.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.MultipleLotNumbers" class="dialog-small-text">
			<p class="d-inline-flex gap-1" style="align-items: center">
				Si vous voulez ajouter plusieurs numéros de lot, renseignez d'abord un numéro dans le champ puis cliquer sur le
				bouton qui ressemble à celui-ci :
				<button class="btn-accent btn-squared">
					<mat-icon>arrow_forward</mat-icon>
				</button>
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.MaybeSirenDoesntExist">
			<p>
				Si un de vos SIRET ou SIREN est refusé, soit il est erroné, soit il est inscrit au répertoire SIRENE dans la
				catégorie "non diffusible". Vous pouvez faire
				<a href="https://www.indy.fr/blog/rendre-entreprise-visible-insee-utiliser-indy/">les démarches</a>
				pour rendre votre SIREN diffusible. Cependant, il ne sera pas utilisable avant le mois prochain. Si vous
				souhaitez tout de même utiliser des codes non diffusés, vous pouvez déclarer un propriétaire avec un identifiant
				"Autre". Cependant, vous n'allez pas pouvoir déclarer des entités fonctionnelles occupantes.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.FileRejectedOperat" style="max-width: 800px">
			<p>
				Si l'un de vos fichiers CSV est rejeté par la plateforme OPERAT, vous pouvez télécharger un détail des raisons
				de ce rejet, sur la page d'import. La plupart des rejets proviennent d'incohérences de saisie sur le
				configurateur, il vous sera donc souvent possible de corriger ces erreurs vous-mêmes.
			</p>
			<p>
				En revanche, certains motifs de rejet sont particuliers, et peuvent être liés à des traitements spécifiques ou
				non fonctionnels de la plateforme OPERAT. Dans ce cas, vous pouvez directement contacter votre interlocuteur en
				lui transmettant le fichier de rejet afin qu'il étudie précisément le problème.
			</p>
			<p>
				Par ailleurs, lorsqu'un fichier CSV est rejeté partiellement par OPERAT, les lignes convenables sont quand même
				traitées. Si vous corrigez les informations du configurateur et réimportez le fichier mis à jour, il est alors
				possible que des doublons apparaissent sur OPERAT. Il est conseillé de supprimer les déclarations traitées lors
				de la première tentative d'import (sur OPERAT, pas dans le Configurateur) avant la seconde.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.LinkedEfa" style="max-width: 800px">
			<p>
				Le système d'EFA liées permet de récupérer les informations déclaratives des anciens occupants des locaux que
				vous emménagez (année de référence, objectifs, etc.). Cette fonction ne doit être utilisée que si vous
				investissez des locaux pour lesquels des déclarations ont été effectuées avant votre arrivée.
			</p>
			<p>La référence des EFA peut être obtenue sur la plateforme OPERAT de l'ADEME.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.EntitiesDeclaredAfterYear" style="max-width: 800px">
			<p>
				Le fichier CSV {{state.argument}} ne comprend pas les entités qui n'ont pas accès à cette déclaration de
				consommation.
			</p>
			<p>
				Les EFA suivantes ne seront donc pas incluses, car leur assujettissement est ultérieur à {{state.argument}}.
			</p>
		</div>
		<div *ngSwitchCase="HelpSubject.NewlyLiable" style="max-width: 800px">
			<p>
				L'année d'entrée dans l'assujettissement correspond à l'année depuis laquelle l'entité est concernée par le
				décret tertiaire : elle doit être supérieure à 2022.
			</p>
			<p>Dans le cas contraire l'option "Nouvellement assujetti" ne vous concerne pas.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.CsvOperat" style="max-width: 800px">
			<p>
				Afin de poursuivre vos déclarations depuis OPERAT, il vous est nécessaire d'importer le fichier de consommations
				de la dernière année déclarée.
			</p>
			<p>
				Le fichier d'import pourra être téléchargé dans l'onglet "Recherche de déclaration de consommations" sur la
				plateforme OPERAT. Il vous suffira de cocher les déclarations concernées, puis de cliquer sur "Exporter".
			</p>
			<p>
				Attention, le fichier doit uniquement contenir des entités de la structure (SIREN) que vous avez renseignée.
				Dans le cas contraire, des lignes pourraient être rejetées.
			</p>
			<p>Les EFA déjà existantes sur le Configurateur seront ignorées lors de l'import.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.OwnerStatus" style="max-width: 800px">
			<p>Cette information influe sur la construction de vos déclarations :</p>
			<ul>
				<li>
					<p>
						En sélectionnant "Oui", la déclaration sera construite du point de vue du propriétaire, et tous les
						SIRET/SIREN seront rattachés au SIREN principal du propriétaire.<br />
						Les fichiers CSV devront être déposés sur le compte OPERAT du propriétaire.
					</p>
				</li>
				<li>
					<p>
						En sélectionnant "Non", les déclarations seront construites du point de vue des occupants, et les SIREN
						différents seront dissociés (un ensemble de déclarations par SIREN). Les SIRET associés au même SIREN seront
						regroupés ensemble.<br />
						Les fichiers CSV devront être déposés sur les comptes respectifs de chaque structure (SIREN).
					</p>
				</li>
			</ul>
			<p>Attention, cette action n'est pas modifiable par la suite.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.OperatUserKey" style="max-width: 800px">
			<p>
				Pour finaliser le transfert des déclarations, il est également nécessaire de renseigner votre "clé utilisateur".
			</p>
			<p>
				Celle-ci est accessible dans l'onglet "Mes données personnelles" accessibles en haut à droite de la plateforme
				OPERAT.
			</p>
			<p>Votre clé utilisateur sera affichée une fois l'étape précédente réalisée.</p>
		</div>
		<div *ngSwitchCase="HelpSubject.OperatQuota" style="max-width: 800px">
			<p>OPERAT impose une limite de communication quotidienne à tous les services tiers qui y sont interfacés.</p>
			<p>
				Cette limite, fixée à 2000 déclarations, est partagée entre tous les utilisateurs du Configurateur Décret
				Tertiaire.
			</p>
			<p>Lorsqu'elle est dépassée, les déclarations par API deviennent indisponibles jusqu'au jour suivant.</p>
			<p>En revanche, les déclarations par import de fichiers CSV seront toujours fonctionnelles.</p>
		</div>
	</div>
	<div class="dialog-actions">
		<button class="btn-stroked-primary" mat-dialog-close>OK</button>
	</div>
</div>
