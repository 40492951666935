import { Immutable } from "immer";
import { Observable, of, Subject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { toObservable } from "../helpers/to-observable";
import { Origin } from "./HasResourceFromOwner";
import { IdFromResource, LazyResource } from "./ids";
import { FeedableObservable, ResourceOwner } from "./ResourceOwner";

export abstract class Isolation<T extends LazyResource> implements ResourceOwner<T> {
	private cachedResource?: { id: string; resource: Immutable<T> };

	getFeedableObservable(): FeedableObservable<T> {
		const subject = new Subject<{ id: IdFromResource<T>; origin: Origin }>();
		return {
			next: (id, origin) => subject.next({ id, origin }),
			observable: subject.pipe(
				switchMap(({ id, origin }) =>
					this.cachedResource && this.cachedResource.id === id
						? of({ value: this.cachedResource.resource, origin })
						: this.fetchResource$(id).pipe(
								tap((resource) => (this.cachedResource = { id, resource })),
								map((value) => ({ value, origin })),
							),
				),
			),
		};
	}

	updateOwned$(resource: Immutable<T>): Observable<unknown> {
		return toObservable(() => {
			if (this.cachedResource?.id === this.getIdFromResource(resource)) {
				this.cachedResource.resource = resource;
			}
		});
	}

	updateOwnedSync$(resource: Immutable<T>): Observable<Immutable<T>> {
		return this.saveResourceToServer$(resource).pipe(
			tap((resource) => {
				if (this.cachedResource?.id === this.getIdFromResource(resource)) {
					this.cachedResource.resource = resource;
				}
			}),
		);
	}

	clearCache() {
		this.cachedResource = undefined;
	}

	/**
	 * Gets the ID of the resource.
	 * @param resource The resource
	 */
	protected abstract getIdFromResource(resource: Immutable<T>): IdFromResource<T>;

	/**
	 * Fetches the resource thanks to a service.
	 * @param id The resource to fetch 's id
	 */
	protected abstract fetchResource$(id: IdFromResource<T>): Observable<Immutable<T>>;

	/**
	 * Saves the resource thanks to a service and returns the server's result.
	 * @param resource The resource to save.
	 */
	protected abstract saveResourceToServer$(resource: Immutable<T>): Observable<Immutable<T>>;
}
