import { Pipe, PipeTransform } from "@angular/core";
import { Page } from "../components/navigation/stepper/stepper.component";

@Pipe({
	name: "collapsableState",
})
export class CollapsableStatePipe implements PipeTransform {
	transform(page: Page, segment: string): string {
		return segment === page.goToSegment || page.segmentsForStepper.some((route) => route === segment)
			? "open"
			: "close";
	}
}
