<form #addressForm class="d-flex-col gap-2" *ngIf="user">
	<div class="d-flex gap-2" *ngIf="showName">
		<div class="custom-input-group w-50">
			<label class="login-p" required>Civilité</label>
			<select
				#civilityInput
				[(ngModel)]="user.civility"
				(ngModelChange)="checkFormValidity()"
				class="custom-input w-100"
				name="civility"
				required
				type="text"
			>
				<option [value]="0">Monsieur</option>
				<option [value]="1">Madame</option>
			</select>
			<span class="custom-input-error">{{ civilityInput.validationMessage }}</span>
		</div>
		<div class="custom-input-group w-100">
			<label class="login-p" required>Prénom</label>
			<input
				#firstNameInput
				[(ngModel)]="user.first_name"
				(ngModelChange)="checkFormValidity()"
				[placeholder]="'Entrez votre prénom'"
				class="custom-input w-100"
				name="firstName"
				required
				type="text"
			/>
			<span class="custom-input-error">{{ firstNameInput.validationMessage }}</span>
		</div>

		<div class="custom-input-group w-100">
			<label class="login-p" required>Nom</label>
			<input
				#nameInput
				[(ngModel)]="user.name"
				(ngModelChange)="checkFormValidity()"
				[placeholder]="'Entrez votre nom'"
				class="custom-input w-100"
				name="name"
				required
				type="text"
			/>
			<span class="custom-input-error">{{ nameInput.validationMessage }}</span>
		</div>
	</div>

	<div class="d-flex gap-2">
		<div class="custom-input-group w-25">
			<label class="login-p" required>Numéro de rue</label>
			<input
				#houseNumberInput
				[(ngModel)]="user.address.houseNumber"
				(ngModelChange)="checkFormValidity()"
				class="custom-input w-100"
				name="houseNumber"
				required
				type="text"
			/>
			<span class="custom-input-error">{{ houseNumberInput.validationMessage }}</span>
		</div>

		<div class="custom-input-group w-100">
			<label class="login-p" required>Adresse</label>
			<input
				#addressInput
				[(ngModel)]="user.address.address"
				(ngModelChange)="checkFormValidity()"
				[placeholder]="'Entrez votre adresse'"
				class="custom-input w-100"
				name="address"
				required
				type="text"
			/>
			<span class="custom-input-error">{{ addressInput.validationMessage }}</span>
		</div>
	</div>

	<div class="custom-input-group w-100">
		<label class="login-p">Complément d'adresse</label>
		<input
			#address2Input
			[(ngModel)]="user.address.address2"
			(ngModelChange)="checkFormValidity()"
			[placeholder]="'Appartement / Entreprise ...'"
			class="custom-input w-100"
			name="address2"
			type="text"
		/>
		<span class="custom-input-error">{{ address2Input.validationMessage }}</span>
	</div>

	<div class="d-flex gap-2">
		<div class="custom-input-group w-100">
			<label class="login-p" required>Code postal</label>
			<input
				#postalCodeInput
				[(ngModel)]="user.address.postalCode"
				(ngModelChange)="checkFormValidity()"
				[placeholder]="'Entrez votre code postal'"
				class="custom-input w-100"
				name="postalCode"
				required
				type="text"
			/>
			<span class="custom-input-error">{{ postalCodeInput.validationMessage }}</span>
		</div>

		<div class="custom-input-group w-100">
			<label class="login-p" required>Commune</label>
			<input
				#cityInput
				[(ngModel)]="user.address.city"
				(ngModelChange)="checkFormValidity()"
				[placeholder]="'Entrez votre ville'"
				class="custom-input w-100"
				name="city"
				required
				type="text"
			/>
			<span class="custom-input-error">{{ cityInput.validationMessage }}</span>
		</div>
	</div>

	<div class="custom-input-group w-100">
		<label class="login-p" required>Numéro de TVA</label>
		<input
			#vatNumberInput
			[(ngModel)]="user.address.vatNumber"
			(ngModelChange)="checkFormValidity()"
			[placeholder]="'Entrez votre numéro de TVA'"
			class="custom-input w-100"
			name="vatNumber"
			required
			type="text"
			pattern="(FR)?[0-9A-Z]{2}[0-9]{9}"
		/>
		<span class="custom-input-error">{{ vatNumberInput.validationMessage }}</span>
	</div>

	<div class="d-flex" style="justify-content: flex-end" *ngIf="showButton">
		<button class="btn-primary" [disabled]="loading || !formValid" (click)="continue.emit(user)">
			{{ buttonLabel }}
		</button>
	</div>
</form>
