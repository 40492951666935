import { Directive, ElementRef, HostListener, Input } from "@angular/core";

// eslint-disable-next-line no-useless-escape
const ALPHANUMERIC_REGEX = /^[À-ža-zA-Z0-9\[\] \-\_.:\,\/\\]*$/;

@Directive({
	selector: "[appInputRestriction]",
})
export class InputRestrictionDirective {
	inputElement: ElementRef;

	@Input() appInputRestriction!: "integer" | "alphanumeric" | "negative_integer";

	constructor(el: ElementRef) {
		this.inputElement = el;
	}

	@HostListener("keypress", ["$event"]) onKeyPress(keyboardEvent: KeyboardEvent) {
		switch (this.appInputRestriction) {
			case "integer":
				this.integerOnly(keyboardEvent);
				break;
			case "negative_integer":
				this.integerOnly(keyboardEvent, true);
				break;
			case "alphanumeric":
				this.alphaNumeric(keyboardEvent);
				break;
		}
	}

	integerOnly(event: KeyboardEvent, allowNegative = false) {
		const { code, key, ctrlKey } = event;
		if (key === "Tab" || key === "TAB") {
			return;
		}
		if (
			(code === "KeyA" && ctrlKey) ||
			(code === "KeyC" && ctrlKey) ||
			(code === "KeyV" && ctrlKey) ||
			(code === "KeyX" && ctrlKey)
		) {
			return;
		}

		// allow a minus if negative values are allowed
		if (allowNegative && key === "-") {
			return;
		}

		if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."].indexOf(key) === -1) {
			event.preventDefault();
		}
	}

	alphaNumeric(event: KeyboardEvent) {
		if (!event.key.match(ALPHANUMERIC_REGEX)) {
			event.preventDefault();
		}
	}

	@HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent) {
		if (this.appInputRestriction === "integer" || this.appInputRestriction === "negative_integer") {
			const regex: RegExp = this.appInputRestriction === "negative_integer" ? /^-?\d*/gm : /\d*/gm;
			if (event.clipboardData) {
				const pasteData = event.clipboardData.getData("text/plain").replace(",", ".");
				const m = regex.exec(pasteData);
				if (m !== null && m[0].length > 0) {
					return;
				} else {
					event.preventDefault();
				}
			}
		} else {
			const clipboardData = event.clipboardData?.getData("text/plain").replace(",", ".");
			if (!clipboardData?.match(ALPHANUMERIC_REGEX)) {
				event.preventDefault();
			}
		}
	}
}
