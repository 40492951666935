<div
	*ngIf="(declarationState.get$ | async)?.value as declaration"
	class="d-flex"
	style="flex-direction: column; gap: 1em"
>
	<div>
		<h1 class="title mb-0">
			1. Définition du périmètre
			<app-help [subject]="HelpSubject.Addresses"></app-help>
		</h1>
		<h3 class="primary mt-0">
			{{ declaration.name }}

			<ng-container *ngIf="declaration.structure | asSirenStructure as structure">
				(SIREN principal: {{ structure.siren }}{{ structure.maybeRna ? "/" + structure.maybeRna : "" }})
			</ng-container>
			<ng-container *ngIf="declaration.structure | asRnaStructure as structure">
				(RNA principal: {{ structure.rna }})
			</ng-container>
			<ng-container *ngIf="declaration.structure | asOtherStructure as structure">
				(Identifiant principal: {{ structure.id }})
			</ng-container>
		</h3>
	</div>

	<div class="alert-danger mt-1" style="text-align: justify">
		Cliquez sur le nom d'une entité fonctionnelle pour ouvrir sa fiche détaillée. Vous accéderez notamment à la fonction
		d'EFA liées, qui permet de récupérer les informations déclaratives des anciens occupants des locaux.
	</div>

	<div class="alert-warning mt-1" style="text-align: justify">
		Attention, si vous importer des EFA sans SIRET, elles seront par défaut définies en situation de "Propriétaire
		occupant". Si ce n'est pas le cas, attention à bien vérifier les champs correspondants.
	</div>

	<div class="d-flex gap-2" style="align-items: center">
		<button
			*ngIf="declaration.declarations_functional[0]?.declaration_functional_id as functionalDeclarationId"
			[disabled]="etablissements.size === 0"
			mat-flat-button
			class="btn-accent3"
			type="submit"
			(click)="formSubmit(functionalDeclarationId)"
		>
			Valider
		</button>
		<button mat-button class="btn-primary" (click)="addNewEntity(declaration)" style="margin-right: 5px">
			<mat-icon>domain_add</mat-icon>
			Ajouter une entité
		</button>
		<div
			*ngIf="(declaration.structure | asRnaStructure) !== undefined; else importTemplate"
			matTooltip="La fonctionnalité d'import d'EFA est bloquée dans le cas des déclarations construites avec un RNA."
		>
			<button mat-button class="btn-primary" style="margin-right: 5px" [disabled]="true">
				<mat-icon class="mb-auto mt-auto">input</mat-icon>
				Importer des EFA
			</button>
		</div>
		<ng-template #importTemplate>
			<ng-container *ngFor="let group of [groupState.get$ | async]">
				<button
					mat-button
					class="btn-primary"
					(click)="importEntity(declaration, group?.value)"
					style="margin-right: 5px"
				>
					<mat-icon class="mb-auto mt-auto">input</mat-icon>
					Importer des EFA
				</button>
			</ng-container>
		</ng-template>
		<mat-checkbox [checked]="declaration.is_mandated" (change)="toggleIsMandated(declaration)">
			Cette déclaration est mandatée
		</mat-checkbox>
		<div class="input-icon ml-auto">
			<input
				[(ngModel)]="search"
				class="custom-input-light"
				style="min-width: 370px; display: block"
				placeholder="Rechercher une entité fonctionnelle"
				(ngModelChange)="updateSearch(declaration)"
			/>
			<span class="input-suffix">
				{{ etablissements.size }}
				<mat-icon>domain</mat-icon>
			</span>
		</div>
	</div>
	<div
		*ngIf="declaration.declarations_functional | apply: countInvalidDatesEntity as count"
		class="message-danger mb-2 d-flex gap-2"
		style="align-items: center"
	>
		<mat-icon style="min-width: 24px">warning</mat-icon>
		<span>
			Depuis la version 4.13 du fichier EFA, vous devez renseigner des informations supplémentaires à propos de vos
			entités. Il y a {{ count }} {{ count > 1 ? "entités" : "entité" }} à modifier. Les entités incomplètes ne seront
			pas incluses dans le fichier EFA.
		</span>
	</div>
	<div
		*ngIf="(declaration.declarations_functional | apply: countInvalidDatesEntity) > 0"
		class="d-flex gap-2"
		style="align-items: center"
	>
		<mat-checkbox [checked]="onlyEntitiesWithProblem" (change)="toggleOnlyEntitiesWithProblem(declaration)">
			Afficher seulement les entités avec des dates manquantes
		</mat-checkbox>
	</div>
	<div *ngIf="declaration.declarations_functional.length > 0; else noEntity" class="columns">
		<div class="siret-list">
			<mat-card appearance="outlined" *ngFor="let etablissement of etablissementSlice" class="card-border pl-2">
				<div class="card-header">
					<mat-card-header>
						<mat-card-title class="d-flex gap-2" style="align-items: center">
							<span class="text-icon description" (click)="openDescriptionEditModal(declaration, etablissement)">
								{{ etablissement | getLabelEstablishment | titlecase }}
								<mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="edit"></mat-icon>
							</span>
							<span style="font-size: 14px" class="text-gray">{{ etablissement | etablissementSubtitle }}</span>
						</mat-card-title>
						<mat-card-subtitle>
							{{ etablissement | getFromEstablishmentAddress | addressToString }}
						</mat-card-subtitle>
					</mat-card-header>
					<mat-card-header>
						<div
							[matTooltip]="
								(etablissement | etablissementHasTokenUsed)
									? 'Vous avez dépensé des déclarations pour cet établissement.'
									: ''
							"
						>
							<button
								[disabled]="etablissement | etablissementHasTokenUsed"
								(click)="removeEtablissement(declaration, etablissement)"
								class="btn-squared d-flex-col btn-danger"
								style="justify-content: center"
							>
								<mat-icon>delete</mat-icon>
							</button>
						</div>
					</mat-card-header>
				</div>
				<div
					class="d-flex gap-1"
					style="margin: 0 16px; flex-direction: column; padding-top: 20px; padding-bottom: 20px"
				>
					<ng-container *ngFor="let functionalDeclaration of etablissement.functionalEntities">
						<div
							class="d-flex entity-line"
							style="align-items: center; justify-content: space-between"
							(click)="editEfaStatus(declaration, functionalDeclaration)"
						>
							<span class="d-flex gap-2" style="align-items: center">
								<ng-container *ngIf="functionalDeclaration.name; else noName">
									<mat-icon>{{
										(functionalDeclaration.efa_status.type === Keys.KEY_SIREN &&
										functionalDeclaration.efa_status.status.type === Keys.KEY_LANDLORD &&
										!functionalDeclaration.efa_status.status.data.tenant
											? "empty"
											: functionalDeclaration.efa_status.status.type
										) | getIconFromEfaStatus
									}}</mat-icon>
									<b class="primary">{{ functionalDeclaration.name }}</b>
								</ng-container>
								<ng-template #noName>
									<mat-icon>warning</mat-icon>
									<b class="primary">Entité sans nom</b>
								</ng-template>
							</span>
							<span class="d-inline-flex gap-2" style="align-items: center">
								<i class="text-gray">{{ functionalDeclaration.efa_status | getLabelFromEfaStatus }}</i>
								<mat-icon [matTooltip]="TEXT_TOKEN_NOT_USED" *ngIf="!functionalDeclaration.is_token_used">
									{{ ICON_TOKEN_NOT_USED }}
								</mat-icon>
								<mat-icon
									class="danger"
									*ngIf="functionalDeclaration.efa_status | apply: areDatesInvalid"
									[matTooltip]="TEXT_INVALID_DATES"
								>
									{{ ICON_INVALID_DATES }}
								</mat-icon>
							</span>
						</div>
					</ng-container>
				</div>
			</mat-card>
		</div>
	</div>
	<ng-template #noEntity>
		<ng-container *ngIf="!(declaration.structure | asOther)">
			<div class="message-accent">
				<mat-icon>priority_high</mat-icon>
				Votre déclaration n'a pas d'entités fonctionnelles. Vous pouvez retrouver tous les établissements de votre
				structure en cliquant sur le bouton ci-dessous. Si vous souhaitez supprimer la déclaration, passez par le
				tableau de bord.
			</div>
			<div>
				<button mat-button class="btn-primary" (click)="generateDefaultEntities(declaration)">
					<mat-icon>replay</mat-icon>
					Générer les entités par défaut
				</button>
			</div>
		</ng-container>
	</ng-template>

	<app-pagination
		*ngIf="maxPage > 1"
		[currentPage]="page"
		[maxPage]="maxPage"
		(pageChange)="setPage($event)"
	></app-pagination>

	<div>
		<button
			*ngIf="declaration.declarations_functional[0]?.declaration_functional_id as functionalDeclarationId"
			[disabled]="etablissements.size === 0"
			mat-flat-button
			class="btn-accent3"
			type="submit"
			(click)="formSubmit(functionalDeclarationId)"
		>
			Valider mon patrimoine
		</button>
	</div>
</div>
