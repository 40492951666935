import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { TODAY } from "../navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/add-row-modal/add-row-modal.component";

const CURRENT_YEAR = TODAY.getFullYear();

@Component({
	selector: "app-start-date-edit",
	templateUrl: "./start-date-edit.component.html",
	styleUrls: ["./start-date-edit.component.scss"],
})
export class StartDateEditComponent implements OnChanges {
	ngOnChanges(): void {
		const split = this.date.split("/").map((n) => parseInt(n));

		switch (split.length) {
			case 1: {
				if (!isNaN(split[0])) {
					this.year = split[0];
				}
				break;
			}
			case 2: {
				if (!isNaN(split[0])) {
					this.month = split[0];
				}
				if (!isNaN(split[1])) {
					this.year = split[1];
				}
				break;
			}
			case 3: {
				if (!isNaN(split[0])) {
					this.day = split[0];
				}
				if (!isNaN(split[1])) {
					this.month = split[1];
				}
				if (!isNaN(split[2])) {
					this.year = split[2];
				}
				break;
			}
		}
	}

	readonly CURRENT_YEAR = CURRENT_YEAR;

	@Input() date = "";
	@Output() changeDate = new EventEmitter<string | null>();

	year?: number;
	month?: number;
	day?: number;

	getDateText(): string | null {
		if (!this.checkDate()) {
			return null;
		}
		const { day, month, year } = this;

		const dayStr = day ? `${day}`.padStart(2, "0") : undefined;
		const monthStr = month ? `${month}`.padStart(2, "0") : undefined;
		const yearStr = year ? `${year}`.padStart(4, "0") : undefined;

		if (dayStr && monthStr) {
			return `${dayStr}/${monthStr}/${yearStr}`;
		}
		if (monthStr) {
			return `${monthStr}/${yearStr}`;
		}
		return `${yearStr}`;
	}

	// from https://stackoverflow.com/a/19341352
	checkDate() {
		if (!this.year) {
			return false;
		}
		const year = this.year;
		const day = this.day ? this.day : 1;
		const month = this.month ? this.month - 1 : 0;
		try {
			const date = new Date(year, month, day, 0, 0, 0, 0);
			return date.getMonth() == month && date.getFullYear() == year && date.getDate() == day;
		} catch {
			return false;
		}
	}
}
