import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Immutable } from "immer";
import { Nullable } from "../../../../../../helpers/nullable";
import { Rna, Siren } from "../../../../../../models/identifier";
import { HelpSubject } from "../../../../../help/help.component";

const FILENAME_EXPECTED_LENGTH = 3;
const INDEX_SIREN = 0;
const INDEX_CONSO = 1;

@Component({
	selector: "app-csv-drop-zone",
	templateUrl: "./csv-drop-zone.component.html",
	styleUrls: ["./csv-drop-zone.component.scss"],
})
export class CsvDropZoneComponent {
	@Input() identifierToCheck: Immutable<Nullable<Siren | Rna | string>>;
	@Input() sirenErrorMessage: string = "";
	@Input() isInvalid: boolean = false;
	@Input() csvFile: Nullable<File>;
	@Output() csvFileChange = new EventEmitter<File>();

	constructor(private snackBar: MatSnackBar) {}

	protected readonly HelpSubject = HelpSubject;

	setCsvFile(file: Nullable<File>) {
		if (file) {
			if (file.type === "text/csv") {
				if (this.checkSiren(file.name)) {
					if (this.checkFilename(file.name)) {
						this.csvFile = file;
						this.csvFileChange.emit(file);
					} else {
						this.snackBar.open("Vous devez renseigner un fichier de consommation d'OPERAT", "OK", {
							verticalPosition: "bottom",
						});
					}
				} else {
					this.snackBar.open(this.sirenErrorMessage, "OK", { verticalPosition: "bottom" });
				}
			} else {
				this.snackBar.open("Le fichier doit être au format CSV", "OK", { verticalPosition: "bottom" });
			}
		}
	}

	checkFilename(name: string): boolean {
		const splitName = name.split("_");
		return splitName.length === FILENAME_EXPECTED_LENGTH && splitName[INDEX_CONSO] === "CONSOMMATIONS";
	}

	checkSiren(name: string): boolean {
		const siren = name.split("_")[INDEX_SIREN];
		if (!siren || siren.length !== Siren.LENGTH) {
			return false;
		}

		if (this.identifierToCheck) {
			return this.identifierToCheck.toString() === siren;
		}

		// if there's no sirenToCheck, then we consider it valid (it will be checked later)
		return true;
	}

	handleCsvDrop(event: DragEvent) {
		event.preventDefault();

		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			const droppedFile = event.dataTransfer.items[0].getAsFile();
			this.setCsvFile(droppedFile);
		}
	}

	handleFileInput(files: Nullable<FileList>) {
		this.setCsvFile(files?.item(0));
	}
}
