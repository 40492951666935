import { Immutable } from "immer";
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { first, skip, switchMap, tap } from "rxjs/operators";
import { toObservable } from "../helpers/to-observable";
import { IdFromResource, LazyResource } from "./ids";
import { ResourceOwner } from "./ResourceOwner";

export type Origin = "refresh" | "basic";

export abstract class HasResourceFromOwner<T extends LazyResource> {
	/**
	 * Nullable because we wan't to be able to clean it.
	 */
	protected resource$ = new BehaviorSubject<Immutable<{ value: T; origin: Origin }> | null>(null);
	private resourceData?: { owner: ResourceOwner<T>; next: (id: IdFromResource<T>, origin: Origin) => void };
	private id?: IdFromResource<T>;
	private sub = new Subscription();

	/**
	 * Warning: if you want to use once this Observable, use first().
	 */
	get get$(): Observable<Immutable<{ value: T; origin: Origin }> | null> {
		return this.resource$;
	}

	getId(): IdFromResource<T> | undefined {
		return this.id;
	}

	getOwner(): ResourceOwner<T> {
		if (!this.resourceData) {
			throw new Error("Trying to set an id without setting its owner beforehand.");
		}
		return this.resourceData.owner;
	}

	setOwner(owner: ResourceOwner<T>): void {
		const { next, observable } = owner.getFeedableObservable();
		this.resourceData = { owner, next };
		this.sub.unsubscribe();
		// To prevent the client to see a resource while the owner is being changed
		this.resource$.next(null);
		this.sub = observable.subscribe(this.resource$);
	}

	/**
	 * Sets a new resource.
	 * @param id The id of the new resource.
	 * @returns An observable that emits when the resource has been set.
	 * Emits null if there is no owner or if the resource doesn't exist within the parent.
	 */
	select$(id: IdFromResource<T>, origin: Origin = "basic"): Observable<{ value: Immutable<T>; origin: Origin } | null> {
		return toObservable(() => {
			const data = this.resourceData;

			if (!data) {
				return of(null);
			}
			const isSet$ = new ReplaySubject<{ value: Immutable<T>; origin: Origin } | null>(1);
			this.get$
				.pipe(
					skip(1),
					first(),
					tap(() => (this.id = id)),
				)
				.subscribe(isSet$);
			data.next(id, origin);
			return isSet$;
		}).pipe(
			switchMap((isSet$) => isSet$),
			first(),
		);
	}

	/**
	 * A shortcut to `this.getOwner().updateOwnedSync$(resource)`. Reloads the state.
	 * @param resource The updated resource
	 * @returns An observable that tells when the operation is finished.
	 */
	updateSync$(resource: Immutable<T>): Observable<unknown> {
		const id = this.getIdFromResource(resource);
		return this.get$.pipe(
			first(),
			switchMap((entity) =>
				this.getOwner()
					.updateOwnedSync$(resource)
					.pipe(switchMap(() => (entity && id === this.getIdFromResource(entity.value) ? this.select$(id) : EMPTY))),
			),
		);
	}

	/**
	 * A shortcut to `this.getOwner().updateOwned$(resource)`. Reloads the state. Doesn't emit if the
	 * state resource's id is different than the one which is updating.
	 * @param resource The updated resource
	 * @returns An observable that tells when the operation is finished.
	 */
	update$(resource: Immutable<T>): Observable<unknown> {
		const id = this.getIdFromResource(resource);
		return this.get$.pipe(
			first(),
			switchMap((entity) =>
				this.getOwner()
					.updateOwned$(resource)
					.pipe(switchMap(() => (entity && id === this.getIdFromResource(entity.value) ? this.select$(id) : EMPTY))),
			),
		);
	}

	abstract getIdFromResource(resource: Immutable<T>): IdFromResource<T>;

	clear(clearOwner = true) {
		this.resourceData?.owner.clearCache();

		if (clearOwner) {
			this.resourceData = undefined;
		}

		this.id = undefined;
		this.resource$.next(null);
	}
}
