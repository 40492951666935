import { Injectable } from "@angular/core";
import { Immutable } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RepresentativeId } from "../models/ids";
import { Isolation } from "../models/isolation";
import { Lazy } from "../models/lazy";
import { Representative } from "../models/representative";
import { RepresentativeService } from "./representative.service";

@Injectable({
	providedIn: "root",
})
export class RepresentativeIsolationService extends Isolation<Lazy<Representative>> {
	constructor(private representative: RepresentativeService) {
		super();
	}

	getIdFromResource(resource: Immutable<Lazy<Representative>>): RepresentativeId {
		return resource.representative_id;
	}

	fetchResource$(id: RepresentativeId): Observable<Immutable<Lazy<Representative>>> {
		return this.representative.get$(id).pipe(map(Representative.fromLazyApi));
	}

	saveResourceToServer$(resource: Immutable<Lazy<Representative>>): Observable<Immutable<Lazy<Representative>>> {
		return this.representative.save$(Representative.toLazyApi(resource)).pipe(map(Representative.fromLazyApi));
	}
}
