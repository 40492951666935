import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CustomError } from "../models/CustomError";
import { Siren, Siret } from "../models/identifier";
import { EtablissementApi, UniteLegaleSirenRoute } from "../models/siren-api.types";

@Injectable({
	providedIn: "root",
})
export class SirenService {
	private cachedEtablissementsBySiren: Record<Siren, EtablissementApi[]> = {};
	private cachedUniteLegaleBySiren: Record<Siren, UniteLegaleSirenRoute> = {};
	private cachedEtablissementBySiret: Record<Siret, EtablissementApi> = {};

	constructor(private http: HttpClient) {}

	getEtablissements$(siren: Siren): Observable<EtablissementApi[]> {
		const cachedEtablissements = this.cachedEtablissementsBySiren[siren];

		if (cachedEtablissements) {
			return of(cachedEtablissements);
		}

		return this.http
			.get<{ etablissements: EtablissementApi[] }>(`${environment.baseApiUrl}/insee/siren/${siren}/siret`)
			.pipe(
				map((res) => res.etablissements.map((etablissement) => new EtablissementApi(etablissement))),
				tap((etablissements) => {
					this.cachedEtablissementsBySiren[siren] = etablissements;
					etablissements.forEach(
						(etablissement) => (this.cachedEtablissementBySiret[etablissement.siret] = etablissement),
					);
				}),
				catchError((error: unknown) => {
					if (error instanceof HttpErrorResponse && error.status === 404) {
						throw new CustomError(`SIREN ${siren} introuvable.`);
					}
					throw error;
				}),
			);
	}

	getUniteLegale$(siren: Siren): Observable<UniteLegaleSirenRoute> {
		const cachedUniteLegale = this.cachedUniteLegaleBySiren[siren];

		if (cachedUniteLegale) {
			return of(cachedUniteLegale);
		}

		return this.http.get<{ uniteLegale: UniteLegaleSirenRoute }>(`${environment.baseApiUrl}/insee/siren/${siren}`).pipe(
			map((res) => res.uniteLegale),
			tap((uniteLegale) => (this.cachedUniteLegaleBySiren[siren] = uniteLegale)),
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse && error.status === 404) {
					throw new CustomError(`SIREN ${siren} introuvable.`);
				}
				throw error;
			}),
		);
	}

	getMultipleUniteLegale$(sirens: Siren[]): Observable<UniteLegaleSirenRoute[]> {
		return this.http
			.post<{ uniteLegale: UniteLegaleSirenRoute }[]>(`${environment.baseApiUrl}/insee/siren`, sirens)
			.pipe(
				map((res) => res.map((uniteLegale) => uniteLegale.uniteLegale)),
				tap((res) =>
					res.forEach((uniteLegale) => {
						this.cachedUniteLegaleBySiren[uniteLegale.siren] = uniteLegale;
					}),
				),
			);
	}

	getEtablissement$(siret: Siret): Observable<EtablissementApi> {
		const cachedEtablissement = this.cachedEtablissementBySiret[siret];

		if (cachedEtablissement) {
			return of(cachedEtablissement);
		}

		return this.http.get<{ etablissement: EtablissementApi }>(`${environment.baseApiUrl}/insee/siret/${siret}`).pipe(
			map((res) => res.etablissement),
			tap((etablissement) => (this.cachedEtablissementBySiret[siret] = etablissement)),
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse && error.status === 404) {
					throw new CustomError(`SIRET ${siret} introuvable.`);
				}
				throw error;
			}),
		);
	}

	doesSiretExist$(siret: Siret): Observable<boolean> {
		if (this.cachedEtablissementBySiret[siret]) {
			return of(true);
		}

		return this.http.get(`${environment.baseApiUrl}/insee/siret/${siret}`).pipe(
			map(() => true),
			catchError((err: unknown) => {
				if (err instanceof HttpErrorResponse && err.status === 404) {
					return of(false);
				}

				throw err;
			}),
		);
	}

	doesSirenExist$(siren: Siren): Observable<boolean> {
		const cachedUniteLegale = this.cachedUniteLegaleBySiren[siren];

		if (cachedUniteLegale) {
			return of(true);
		}

		return this.http.get(`${environment.baseApiUrl}/insee/siren/${siren}`).pipe(
			map(() => true),
			catchError((err: unknown) => {
				if (err instanceof HttpErrorResponse && err.status === 404) {
					return of(false);
				}

				throw err;
			}),
		);
	}

	getMultipleEtablissement$(sirets: Siret[]): Observable<EtablissementApi[]> {
		return this.http.post<{ etablissement: EtablissementApi }[]>(`${environment.baseApiUrl}/insee/siret`, sirets).pipe(
			map((res) => res.map((etablissement) => etablissement.etablissement)),
			tap((res) =>
				res.forEach((etablissement) => {
					this.cachedEtablissementBySiret[etablissement.siret] = etablissement;
				}),
			),
		);
	}
}
