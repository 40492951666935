import { Pipe, PipeTransform } from "@angular/core";
import { FeatureCollection } from "geojson";
import { Immutable } from "immer";
import { ParcelFeature } from "../models/Parcel";

@Pipe({
	name: "parcelName",
})
export class ParcelNamePipe implements PipeTransform {
	transform(parcel: Immutable<FeatureCollection | ParcelFeature>): string {
		if (parcel.type === "Feature") {
			const properties = parcel.properties;
			return `${properties.commune} ${properties.prefixe} ${properties.section} ${properties.numero}`;
		}

		const properties = parcel.features[0]?.properties;

		if (properties === null || properties === undefined) {
			return "";
		}

		const prefixe = properties["code_arr"] !== "000" ? properties["code_arr"] : properties["com_abs"];

		// parseInt to remove leading zeros
		return `${properties["code_dep"]}${properties["code_com"]} ${prefixe} ${properties["section"]} ${parseInt(properties["numero"])}`;
	}
}
