import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component } from "@angular/core";
import { produce, Immutable } from "immer";
import { EMPTY, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { z } from "zod";
import { Nullable } from "../../../../../helpers/nullable";
import { unwrap } from "../../../../../helpers/unwrap";
import { Declaration } from "../../../../../models/declaration";
import { FunctionalDeclaration } from "../../../../../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../../../../../models/ids";
import { dialogOpen, Modal } from "../../../../../models/modal";
import { OperatApiErrorType } from "../../../../../models/OperatApiErrorType";
import { DeclarationStateService } from "../../../../../services/declaration-state.service";
import { OperatApiService } from "../../../../../services/operat-api.service";
import { HelpSubject } from "../../../../help/help.component";
import { ApiAccessErrorModalComponent } from "../../../user-info-form/operat-search-form/api-access-error-modal/api-access-error-modal.component";
import { OperatApiBody } from "@grs/operat-micro-service-lib-ts";

type Input = {
	declaration: Immutable<Declaration>;
	functionalDeclaration: Immutable<FunctionalDeclaration>;
	operatApi: OperatApiService;
	declarationService: DeclarationStateService;
};

type State = {
	functionalDeclarationId: FunctionalDeclarationId;
	userKey: Nullable<string>;
	structureId: string;
	operatApi: OperatApiService;
	declaration: Immutable<Declaration>;
	declarationService: DeclarationStateService;
};

type Output = { year: number; categories: boolean; consumptions: boolean; data: OperatApiBody }[];

@Component({
	selector: "app-complete-functional-declaration-from-api",
	templateUrl: "./complete-functional-declaration-from-api.component.html",
	styleUrls: ["./complete-functional-declaration-from-api.component.scss"],
})
export class CompleteFunctionalDeclarationFromApiComponent extends Modal<Input, State, Output | undefined> {
	errorUserKey = false;
	dataToImport: Output = [];
	inputUserKey: string = "";
	loading = true;
	referenceYear?: number = undefined;

	inputToState(input: Input): State {
		return {
			functionalDeclarationId: unwrap(input.functionalDeclaration.declaration_functional_id),
			declaration: input.declaration,
			userKey: input.declaration.operat_user_key,
			operatApi: input.operatApi,
			structureId:
				"rna" in input.declaration.structure
					? input.declaration.structure["rna"]
					: "siren" in input.declaration.structure
						? input.declaration.structure["siren"]
						: input.declaration.structure["id"],
			declarationService: input.declarationService,
		};
	}

	afterStateInit() {
		super.afterStateInit();
		if (this.state.userKey) this.searchEntities();
	}

	searchEntities() {
		this.state.operatApi
			.searchConsumptionDeclarationsForEntity(this.state.functionalDeclarationId)
			.pipe(
				catchError((error) => {
					if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest) {
						switch (JSON.parse(error.error.error)[0]) {
							case OperatApiErrorType.INVALID_CLIENT:
								this.inputUserKey = this.state.userKey ?? "";
								this.state.userKey = "";
								this.errorUserKey = true;
								break;
							case OperatApiErrorType.NOT_AUTHORIZED:
								dialogOpen(this.dialog, ApiAccessErrorModalComponent, undefined, { panelClass: "p-0" });
								break;
							default:
								throw error;
						}
						return EMPTY;
					} else if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
						return of({});
					}
					throw error;
				}),
			)
			.subscribe((consumptionDeclarations: Record<string, OperatApiBody>) => {
				const years = Object.keys(consumptionDeclarations).map((year) => parseInt(year));
				this.dataToImport = years.map((year) => ({
					year: year,
					consumptions: false,
					categories: false,
					data: consumptionDeclarations[`${year}`],
				}));
				this.referenceYear = years.find(
					(year) =>
						consumptionDeclarations[`${year}`].dateDebutConsoReference !== undefined &&
						consumptionDeclarations[`${year}`].dateDebutConsoReference !== null &&
						consumptionDeclarations[`${year}`].dateDebutConsoReference !== "",
				);
				this.loading = false;
			});
	}

	checkUserKey() {
		this.errorUserKey = false;
		const check = z.string().uuid();
		if (this.inputUserKey && check.safeParse(this.inputUserKey).success) {
			this.state.userKey = this.inputUserKey;
			this.state.declarationService
				.updateSync$(
					produce(this.state.declaration, (draft) => {
						draft.operat_user_key = this.state.userKey;
					}),
				)
				.subscribe(() => {
					// Check value again, otherwise code lint doesn´t like
					if (this.state.userKey) {
						this.searchEntities();
					}
				});
		} else {
			this.errorUserKey = true;
		}
	}

	protected readonly HelpSubject = HelpSubject;
}
