<div *ngIf="representativeState.get$ | async as representative" class="dialog-container">
	<div class="dialog-close-icon">
		<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
	</div>
	<div class="dialog-content d-flex gap-2 pt-0" style="flex-direction: column">
		<h2 class="primary">Créer une nouvelle déclaration</h2>
		<div class="custom-input-group">
			<label required>Nom de la déclaration</label>
			<input
				placeholder="Nom de la declaration"
				class="custom-input"
				[(ngModel)]="declarationGroupName"
				minlength="1"
				required
			/>
			<span class="text-gray">Le nom de votre déclaration vous aidera à vous organiser dans votre tableau de bord</span>
		</div>
		<app-newly-liable-input
			#newlyLiableInput
			[hidden]="data.isImporting"
			[(isNewlyLiable)]="isNewlyLiable"
			[(firstYearDeclaration)]="firstYearDeclaration"
		>
		</app-newly-liable-input>
		<app-declarations-creation
			*ngIf="!data.isImporting"
			(choiceChange)="choice = $event"
			(isMandatedChange)="isMandated = $event"
			(errorsChange)="errors = $event"
		></app-declarations-creation>
		<ng-container *ngIf="data.isImporting">
			<mat-tab-group (selectedIndexChange)="importTabIndex = $event">
				<mat-tab label="API">
					<app-operat-search-form [(importData)]="apiImportData"></app-operat-search-form>
				</mat-tab>
				<mat-tab label="CSV">
					<div class="custom-input-group">
						<label>SIREN de la structure</label>
						<app-siren-input [(siren)]="importIdentifier" appInputRestriction="alphanumeric"></app-siren-input>
					</div>
					<app-csv-drop-zone
						[(csvFile)]="csvFile"
						[isInvalid]="!!csvFile && !(csvFile | checkSirenForCsv: importIdentifier)"
					></app-csv-drop-zone>
					<span
						class="custom-input-error"
						[ngStyle]="{ display: !!csvFile && !(csvFile | checkSirenForCsv: importIdentifier) ? 'block' : 'none' }"
						>Le SIREN du fichier ne correspond pas à celui renseigné</span
					>
				</mat-tab>
			</mat-tab-group>
		</ng-container>
	</div>

	<div class="dialog-actions">
		<ng-container *ngIf="data.isImporting">
			<button
				*ngIf="importTabIndex === ImportTypeEnum.API"
				class="btn-primary-reverse"
				[disabled]="
					!apiImportData ||
					apiImportData.references.length === 0 ||
					apiImportData.structId === '' ||
					apiImportData.userKey === '' ||
					declarationGroupName === ''
				"
				[class.btn-loading]="initialLoading.isLoading()"
				type="submit"
				(click)="importEFAByAPI(representative.value)"
			>
				Valider
			</button>
			<button
				*ngIf="importTabIndex === ImportTypeEnum.CSV"
				class="btn-primary-reverse"
				[disabled]="
					!csvFile ||
					!(importIdentifier | apply: Siren.isValid) ||
					!(csvFile | checkSirenForCsv: importIdentifier) ||
					declarationGroupName === ''
				"
				[class.btn-loading]="initialLoading.isLoading()"
				type="submit"
				(click)="importEFAByCSV(representative.value)"
			>
				Valider
			</button>
		</ng-container>
		<button
			*ngIf="choice"
			class="btn-primary-reverse"
			[disabled]="
				!(choice | apply: isChoiceValid) ||
				declarationGroupName === '' ||
				errors.size > 0 ||
				!newlyLiableInput.isValid()
			"
			[class.btn-loading]="initialLoading.isLoading()"
			type="submit"
			(click)="submit(choice, representative.value)"
		>
			Valider
		</button>
		<button
			*ngIf="!choice && !data.isImporting"
			class="btn-primary-reverse"
			[disabled]="true"
			[class.btn-loading]="initialLoading.isLoading()"
			type="submit"
		>
			Valider
		</button>
	</div>
</div>
