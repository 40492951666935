import { AfterViewInit, ChangeDetectorRef, Component, Input } from "@angular/core";
import { User } from "../../models/user";
import { UserStateService } from "../../services/user-state.service";
import { UserService } from "../../services/user.service";
import { Nullable } from "src/app/helpers/nullable";

@Component({
	selector: "app-avatar",
	templateUrl: "./avatar.component.html",
	styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements AfterViewInit {
	@Input() user: User | undefined;
	firstname: Nullable<string>;
	lastname: Nullable<string>;
	show = false;

	colorPossible = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];

	constructor(
		private userService: UserService,
		private userState: UserStateService,
		private cdRef: ChangeDetectorRef,
	) {}

	ngAfterViewInit(): void {
		if (this.user) {
			this.firstname = this.user.first_name;
			this.lastname = this.user.name;
			if (!this.user.color) {
				this.user.color = "#";
				for (let i = 0; i < 6; i++) {
					this.user.color += this.colorPossible[Math.floor(Math.random() * 16)];
				}
				if (this.user.user_id === this.userState.user?.user_id) {
					this.updateColor();
				}
			}
		}
		this.cdRef.detectChanges();
	}

	updateColor() {
		if (this.user && this.user.user_id === this.userState.user?.user_id) {
			this.userService.put$(this.user).subscribe((user) => {
				this.userState.user = user;
			});
		}
	}
}
