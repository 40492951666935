import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { Rna } from "../models/identifier";

@Pipe({
	name: "asRnaStructure",
})
export class AsRnaStructurePipe implements PipeTransform {
	transform(structure: Immutable<Declaration["structure"]>): Immutable<{ rna: Rna }> | undefined {
		return "rna" in structure ? structure : undefined;
	}
}
