import { Injectable } from "@angular/core";
import { IStepOption, TourService } from "ngx-ui-tour-md-menu";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class TourStateService {
	hasTour$ = new BehaviorSubject<boolean>(false);
	onTour$ = new BehaviorSubject<boolean>(false);
	private readonly MAIN_SECTION_CSS_SELECTORS = [".content", ".pane"];
	private readonly NO_SCROLL_CSS_CLASS = "no-scroll";

	constructor(private tourService: TourService) {
		this.tourService.disableHotkeys();
		this.tourService.initialize$.subscribe((steps) => {
			this.hasTour$.next(steps.length > 0);
		});

		this.tourService.start$.subscribe(() => {
			this.setIsScrollable(false);
			this.onTour$.next(true);
		});

		this.tourService.end$.subscribe(() => {
			this.setIsScrollable(true);
			this.onTour$.next(false);
		});
	}

	get hasTour(): boolean {
		return this.hasTour$.value;
	}

	get onTour(): boolean {
		return this.onTour$.value;
	}

	get start$(): Subject<void> {
		return this.tourService.start$;
	}

	get service(): TourService {
		return this.tourService;
	}

	private static preventTouchMove(e: Event) {
		e.preventDefault();
	}

	start(steps: IStepOption[]) {
		this.initialize(steps);
		this.startInitialized();
	}

	end() {
		this.tourService.end();
	}

	startInitialized() {
		this.tourService.start();
	}

	initialize(steps: IStepOption[]) {
		this.tourService.initialize(steps, {
			enableBackdrop: true,
			backdropConfig: {
				offset: 5,
			},
			centerAnchorOnScroll: false,
			nextBtnTitle: "Suivant",
			prevBtnTitle: "Précédent",
			endBtnTitle: "Terminer",
			closeOnOutsideClick: false,
		});
	}

	resetTour() {
		this.tourService.initialize$.next([]);
	}

	private setIsScrollable(isScrollable: boolean) {
		this.MAIN_SECTION_CSS_SELECTORS.forEach((selector) => {
			const body = document.body,
				mainSection = document.querySelector(selector),
				addOrRemove = isScrollable ? "remove" : "add";

			if (mainSection) {
				mainSection.classList[addOrRemove](this.NO_SCROLL_CSS_CLASS);
				// You can also optionally disable iOS Safari bounce effect
				if (addOrRemove === "add") {
					body.addEventListener("touchmove", TourStateService.preventTouchMove, { passive: false });
				} else {
					body.removeEventListener("touchmove", TourStateService.preventTouchMove);
				}
			}
		});
	}
}
