import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-first-connection-modal",
	templateUrl: "./first-connection-modal.component.html",
	styleUrls: ["./first-connection-modal.component.scss"],
})
export class FirstConnectionModalComponent {
	currentStep = 0;
	totalSteps = 7;

	constructor(private dialogRef: MatDialogRef<FirstConnectionModalComponent>) {}

	nextStep() {
		if (this.currentStep >= this.totalSteps - 1) {
			this.dialogRef.close(true);
		}
		this.currentStep++;
	}

	previousStep() {
		this.currentStep = this.currentStep <= 0 ? 0 : this.currentStep - 1;
	}
}
