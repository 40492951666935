import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { ChangeLog, CHANGELOG_LANGUAGE } from "../models/changelog";
import { CURRENT_VERSION } from "../models/version";
import { Nullable } from "../helpers/nullable";

@Injectable({
	providedIn: "root",
})
export class VersionService {
	versionSubject$ = new BehaviorSubject<string | null>(null);
	readonly IsBackAndFrontCompatible$ = this.versionSubject$.pipe(
		distinctUntilChanged(),
		map((backVersion) => {
			if (backVersion === null) {
				return true;
			}
			if (areCompatible(CURRENT_VERSION, backVersion)) {
				localStorage.removeItem("hasRefreshed");
				return true;
			}

			console.error(`Server ${backVersion} not compatible with app ${CURRENT_VERSION}`);

			if (!localStorage.getItem("hasRefreshed")) {
				this.router.navigate(["/"]).then(() => {
					localStorage.setItem("hasRefreshed", "true");
					window.location.reload();
				});
			}

			return false;
		}),
	);

	constructor(
		private router: Router,
		private httpClient: HttpClient,
	) {}

	get version() {
		return this.versionSubject$.value;
	}

	setVersion(version: string | null) {
		this.versionSubject$.next(version);
	}

	getChangelogs$(date: Nullable<number> = undefined): Observable<ChangeLog[]> {
		const params: Record<string, number | string> = {
			project: "DECRET_TERTIAIRE",
			language: CHANGELOG_LANGUAGE,
		};
		if (date) {
			params["date"] = date;
		}
		return this.httpClient
			.get<{ data: ChangeLog[] }>("https://api.greensolution.greensystemes.com/backoffice/changelogs", {
				params,
			})
			.pipe(map((response) => response.data.sort((a, b) => b.date - a.date)));
	}
}

function areCompatible(v1: string, v2: string): boolean {
	const splitV1 = v1.split(".");
	const splitV2 = v2.split(".");

	if (v1 === "DEV") {
		return true;
	}

	if (splitV1.length !== 3 || splitV2.length !== 3) {
		return v1 === v2;
	}

	// We check only the two first numbers
	// https://semver.org/
	for (let i = 0; i < 2; ++i) {
		if (splitV1[i] !== splitV2[i]) {
			return false;
		}
	}

	return true;
}
