import { Pipe, PipeTransform } from "@angular/core";
import { Immutable } from "immer";
import { Declaration } from "../models/declaration";
import { DeclarationGroup } from "../models/declarationGroup";
import { FunctionalDeclaration } from "../models/functionalDeclaration";
import { Lazy } from "../models/lazy";

@Pipe({
	name: "countEntities",
})
export class CountEntitiesPipe implements PipeTransform {
	transform(resource: Immutable<Lazy<DeclarationGroup | Declaration | FunctionalDeclaration>>): number {
		let entities: Immutable<Lazy<FunctionalDeclaration>[]> = [];

		if ("declarations" in resource) {
			entities = resource.declarations.flatMap((declaration) => declaration.declarations_functional);
		} else if ("declarations_functional" in resource) {
			entities = resource.declarations_functional;
		} else {
			entities = [resource];
		}

		return entities.length;
	}
}
