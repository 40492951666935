import { Component, ViewChild } from "@angular/core";
import { Immutable } from "immer";
import { ReplaySubject } from "rxjs";
import { getCategoryFromSubCategoryCode, IMMUTABLE_CATEGORIES } from "src/app/helpers/category-helpers";
import {
	ActivityCategoryEntry,
	CategoryCode,
	cloneActivityCategoryEntry,
	isSubCategoryCodeDefault,
	SubCategoryCode,
} from "src/app/models/asset";
import { Declaration } from "src/app/models/declaration";
import { HeatModifierType } from "src/app/models/heatModifierType";
import { Modal } from "src/app/models/modal";
import { Nullable } from "../../../../../../helpers/nullable";
import { DeclarationFunctionalService } from "../../../../../../services/declaration-functional.service";
import { CustomFormComponent } from "../../../../../custom-form/custom-form.component";
import { subCategoryFromCode } from "src/app/pipes/sub-category-from-code.pipe";

type Input = { functionalDeclarationService: DeclarationFunctionalService } & Immutable<
	{ altitude?: number; zipCode?: string } & (
		| {
				adding: true;
				useDefaultCategory: boolean;
				categoryCode: CategoryCode | undefined;
				bounds: Immutable<{ start: number; end: number | undefined }>;
		  }
		| { adding: boolean; useDefaultCategory: boolean; activityCategoryEntry: Immutable<ActivityCategoryEntry> }
	)
>;

/**
 * The first two steps have a field for the entry's name because the entry doesn't exist yet.
 */
type State = (
	| ((
			| { step: "category"; categoryCode: CategoryCode | undefined }
			| { step: "subCategory"; categoryCode: CategoryCode; subCategoryCode: SubCategoryCode | undefined }
	  ) & { entryName: string })
	| { step: "entry"; categoryCode: CategoryCode; subCategoryCode: SubCategoryCode; entry: ActivityCategoryEntry }
) & {
	adding: boolean;
	useDefaultCategory: boolean;
	bounds: { start: number; end: number | undefined };
	functionalDeclarationService: DeclarationFunctionalService;
};

enum OutputType {
	Save,
	Delete,
}

type Output =
	| { type: OutputType.Save; activityCategoryEntry: ActivityCategoryEntry }
	| { type: OutputType.Delete }
	| undefined;

@Component({
	selector: "app-activity-category-modal",
	templateUrl: "./activity-category-modal.component.html",
	styleUrls: ["./activity-category-modal.component.scss"],
})
export class ActivityCategoryModalComponent extends Modal<Input, State, Output> {
	static readonly OutputType = OutputType;
	readonly CATEGORIES = IMMUTABLE_CATEGORIES;
	readonly START = new Date(Declaration.MINIMUM_DATE_TIME * 1000);
	readonly TODAY = new Date();
	absoluteObjective$ = new ReplaySubject<Nullable<number>>(1);

	altitude?: number;
	zipCode?: string;

	@ViewChild("form") form!: CustomFormComponent;

	inputToState(input: Input): State {
		this.altitude = input.altitude;
		this.zipCode = input.zipCode;

		if ("activityCategoryEntry" in input) {
			const entry = cloneActivityCategoryEntry(input.activityCategoryEntry);

			// fix if there are new indicators
			if (entry.indicators.length === 0) {
				entry.indicators = subCategoryFromCode(entry.subCategoryCode).indicators.map(({ code, defaultValue }) => ({
					code,
					value: defaultValue,
				}));
			}

			input.functionalDeclarationService
				.getDefaultAbsoluteObjective(entry.subCategoryCode, this.altitude, this.zipCode)
				.subscribe((value) => this.absoluteObjective$.next(value));

			return {
				entry,
				adding: input.adding,
				step: "entry",
				bounds: { start: entry.start, end: entry.end },
				categoryCode: getCategoryFromSubCategoryCode(entry.subCategoryCode).code,
				subCategoryCode: entry.subCategoryCode,
				useDefaultCategory: input.useDefaultCategory,
				functionalDeclarationService: input.functionalDeclarationService,
			};
		}

		const { categoryCode, bounds } = input;

		return {
			entryName: "",
			...(categoryCode
				? { step: "subCategory", categoryCode, subCategoryCode: undefined }
				: { step: "category", categoryCode: undefined }),
			adding: true,
			bounds: { ...bounds },
			useDefaultCategory: input.useDefaultCategory,
			functionalDeclarationService: input.functionalDeclarationService,
		};
	}

	save(entry: ActivityCategoryEntry) {
		if (!entry.temperatureModifiers.includes(HeatModifierType.LOGISTIC_COOL)) {
			entry.logisticCooledHeight = 0;
		}

		this.close({ type: OutputType.Save, activityCategoryEntry: entry });
	}

	delete() {
		this.close({ type: OutputType.Delete });
	}

	exit() {
		this.close(undefined);
	}

	onCategoryChange(categoryCode: CategoryCode, entryName: string) {
		this.state = {
			step: "subCategory",
			adding: this.state.adding,
			entryName,
			categoryCode,
			subCategoryCode: undefined,
			bounds: this.state.bounds,
			useDefaultCategory: this.state.useDefaultCategory,
			functionalDeclarationService: this.state.functionalDeclarationService,
		};
	}

	onSubCategoryChange(
		state:
			| { step: "subCategory"; categoryCode: CategoryCode; adding: boolean; entryName: string }
			| {
					step: "entry";
					entry: ActivityCategoryEntry;
			  },
		subCategoryCode: SubCategoryCode,
	) {
		this.state.functionalDeclarationService
			.getDefaultAbsoluteObjective(subCategoryCode, this.altitude, this.zipCode)
			.subscribe((value) => this.absoluteObjective$.next(value));

		const indicators = subCategoryFromCode(subCategoryCode).indicators.map(({ code, defaultValue }) => ({
			code,
			value: defaultValue,
		}));
		if (state.step === "entry") {
			const { entry } = state;
			entry.subCategoryCode = subCategoryCode;
			entry.indicators = indicators;
			entry.temperatureModifiers = [];
		} else {
			const { categoryCode, adding, entryName } = state;
			const { start, end } = this.state.bounds;
			this.state = {
				step: "entry",
				categoryCode,
				adding,
				entry: new ActivityCategoryEntry({ name: entryName, subCategoryCode, indicators, start, end }),
				subCategoryCode,
				bounds: this.state.bounds,
				useDefaultCategory: this.state.useDefaultCategory,
				functionalDeclarationService: this.state.functionalDeclarationService,
			};
		}

		// After adding or removing input fields, force the form to check its validity
		this.form.updateFormValidity();
	}

	startDateChange(entry: ActivityCategoryEntry, date: string) {
		entry.start = new Date(date).getTime() / 1000;
	}

	endDateChange(entry: ActivityCategoryEntry, date: string) {
		if (date === "") {
			entry.end = undefined;
			return;
		}
		entry.end = new Date(date).getTime() / 1000;
	}

	updateModifiers(entry: ActivityCategoryEntry) {
		entry.temperatureModifiers = [...entry.temperatureModifiers];
	}

	readonly isSubCategoryCodeDefault = isSubCategoryCodeDefault;
}
